import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPost, apiGet } from "@shared/queryHooks/api";

const add = async ({ jobId, organizationUserId }) => {
  return await apiPost({
    path: `/jobs/${jobId}/hiring_team_memberships/add`,
    variables: { organizationUserId },
  });
};

const remove = async ({ jobId, organizationUserId }) => {
  return await apiPost({
    path: `/jobs/${jobId}/hiring_team_memberships/remove`,
    variables: { organizationUserId },
  });
};

const getHiringTeamMemberships = async (jobId) => {
  return await apiGet({ path: `/jobs/${jobId}/hiring_team_memberships` });
};

const createHiringTeamInvite = async ({ jobId, inviteId }) => {
  return await apiPost({
    path: `/jobs/${jobId}/hiring_team_memberships/invite`,
    variables: { inviteId },
  });
};

const getHiringTeamInvites = async (jobId) => {
  return await apiGet({ path: `/jobs/${jobId}/hiring_team_memberships/invites` });
};

/* Hooks
--===================================================-- */
function useAddHiringTeamMembership() {
  const queryClient = useQueryClient();
  return useMutation(add, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringTeamMembership] useAddHiringTeamMembership onSettled",
        "color: #1976D2",
        { data, mutationVariables },
      );
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useRemoveHiringTeamMembership() {
  const queryClient = useQueryClient();
  return useMutation(remove, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringTeamMembership] useRemoveHiringTeamMembership onSettled",
        "color: #1976D2",
        { data, mutationVariables },
      );
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useHiringTeamMemberships(
  jobId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
} {
  return useQuery(["hiringTeamMemberships", jobId], () => getHiringTeamMemberships(jobId));
}

function useCreateHiringTeamInvite() {
  const queryClient = useQueryClient();
  return useMutation(createHiringTeamInvite, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringTeamMembership] useAddHiringTeamMembership onSettled",
        "color: #1976D2",
        { data, mutationVariables },
      );
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

export {
  useAddHiringTeamMembership,
  useRemoveHiringTeamMembership,
  useHiringTeamMemberships,
  useCreateHiringTeamInvite,
};

import { useMutation } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createJobCandidatesCsvExport = async ({ jobId }) => {
  if (jobId == undefined) {
    return;
  }
  return await apiPost({ path: `/jobs/${jobId}/job_csv_export`, variables: {} });
};

function useCreateJobCandidatesCsvExport() {
  return useMutation(createJobCandidatesCsvExport, {});
}

export { useCreateJobCandidatesCsvExport };

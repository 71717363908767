import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useCreateHiringStage, useUpdateHiringStage } from "@shared/queryHooks/useHiringStage";

import { validateHiringStage } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  isEditing: boolean;
  hiringStage: { id; name; jobApplicationsCount };
  jobId: number;
};

function HiringStageModal(props: Props) {
  const { isEditing, onCancel, hiringStage, jobId } = props;
  const {
    mutate: createHiringStage,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateHiringStage();
  const {
    mutate: updateHiringStage,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateHiringStage();
  const [hiringStageName, setHiringStageName] = useState(hiringStage.name || "");
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeHiringStage = (_, value) => {
    setHiringStageName(value);
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[HiringStageModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateHiringStage({ hiringStageName });

    if (isValid) {
      isEditing ? handleUpdate() : handleCreate();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCreate = async () => {
    try {
      await createHiringStage({ name: hiringStageName, jobId });
      // jobHiringStage was successfully created
      onCancel(); // this closes the modal
    } catch (error) {
      // Uh oh, something went wrong
      // window.logger("%c[HiringStageModal] handle error when CREATE Job HiringStage", "color: #FF7602", {
      //   error: error.response.data,
      // });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleUpdate = async () => {
    try {
      await updateHiringStage({ id: hiringStage.id, name: hiringStageName, jobId });
      // jobHiringStage was successfully updated
      onCancel(); // this closes the modal
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[HiringStageModal] handle error when updating Job HiringStage",
        "color: #FF7602",
        {
          error,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate || isLoadingUpdate}
      >
        {isEditing ? "Update stage" : "Add stage"}
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const editMessage = null;
  // Number(hiringStage.jobApplicationsCount) > 0
  //   ? `This Stage name will be updated for the ${hiringStage.jobApplicationsCount} active job${
  //       Number(hiringStage.jobApplicationsCount) === 1 ? " that is" : "s that are"
  //     } currently using it.`
  //   : `There are no active jobs that currently use this Stage.`;

  return (
    <CenterModal
      headerTitleText={isEditing ? "Edit stage" : "Add additional stage"}
      onCancel={onCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        {isEditing ? editMessage : "Enter a name for the new stage."}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormInput
          onChange={handleChangeHiringStage}
          name="hiringStage"
          placeholder=""
          label="Stage name"
          value={hiringStageName}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default HiringStageModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isEmpty from "lodash/isEmpty";

import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";

import Icon from "@ats/src/components/shared/Icon";
import Resume from "@ats/src/views/jobApplications/Resume";
import ProseMirrorEditor from "@shared/ProseMirror/Editor";
import Tooltip from "@ats/src/components/shared/Tooltip";
import textStyles from "@ats/styles/text";
import CollapsibleQuestionResponses from "@ats/src/views/reviews/CollapsibleQuestionResponses";

import { useUpdateComment } from "@shared/queryHooks/useComment";
import {
  useCandidatePrivateNote,
  // useUpdateCandidatePrivateNote,
} from "@shared/queryHooks/useCandidatePrivateNote";
import { useToastContext } from "@shared/context/ToastContext";
import { validateComment } from "@shared/lib/validateWithYup";
import { formatPhoneNumber } from "@shared/lib/utils";

import { reviewRatingOptions } from "@ats/src/lib/lookups";

function ReviewKit({
  jobApplication,
  review: passedReview,
  isDraft,
  history,
  returnPathname,
  orgAdminJobsListUrl,
  role,
}) {
  const { candidate } = jobApplication;
  const candidateId = jobApplication.candidateId;
  const addToast = useToastContext();
  // const {
  //   mutate: createComment,
  //   error: errorOnCreate,
  //   isLoading: isLoadingCreate,
  // } = useCreateComment();
  const {
    mutate: updateComment,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateComment();

  // This is used by autosave so that when we click out of the document it does not trigger the loading indicator on the button for autosave
  const {
    mutate: updateCommentSilently,
    error: errorOnUpdateSilently,
    isLoading: isLoadingUpdateSilently,
  } = useUpdateComment();

  const { data: candidatePrivateNote, isLoading: isLoadingPrivateNote } = useCandidatePrivateNote({
    candidateId,
    enabled: true,
  });

  const [review, setReview] = React.useState(passedReview || {});
  const { body, kind } = review;
  const [rating, setRating] = React.useState(review.rating);

  const editorRef = React.useRef(undefined);
  const [errors, setErrors] = React.useState([]);
  const [isDirty, setIsDirty] = React.useState(false);

  window.logger("%c[ReviewKit] ", "color: #1976D2", {
    passedReview,
    review,
    returnPathname,
    jobApplication,
  });

  const handleEditorChange = () => {
    if (isDirty === false) {
      setIsDirty(true);
    }
    if (errors.length > 0) {
      setErrors([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateComment({
      body: editorRef.current.serializedState(),
    });

    if (isValid) {
      // isDraft ? handleUpdate() : handleCreate();
      handlePublish();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleAutoSave = React.useCallback(async () => {
    window.logger("%c[ReviewModal] handleUpdate", "color: #1976D2", {
      jobApplicationId: jobApplication.id,
      body: editorRef.current.serializedState(),
      rating,
      kind,
    });

    try {
      await updateCommentSilently(
        {
          jobApplicationId: jobApplication.id,
          id: review.id,
          body: editorRef.current.serializedState(),
          kind: "review",
          rating,
          // Do not pass "status" as this is only updating the current active comment, not changing its status, that is handled by handlePublish
        },
        {
          onSuccess: (data) => {
            setIsDirty(false);
          },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[ReviewModal] handle error when updating Job Review", "color: #FF7602", {
        error,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  }, [jobApplication, kind, rating, review, updateCommentSilently]);

  const handlePublish = async () => {
    window.logger("%c[ReviewModal] handlePublish", "color: #1976D2", {
      jobApplicationId: jobApplication.id,
      body: editorRef.current.serializedState(),
      rating,
      kind,
    });

    try {
      await updateComment(
        {
          jobApplicationId: jobApplication.id,
          id: review.id,
          body: editorRef.current.serializedState(),
          rating,
          kind: "review",
          status: "published",
        },
        {
          onSuccess: (data) => {
            // TODO
            if (role === "org_interviewer") {
              history.push({
                pathname: "/interviewer",
                search: "?message=success",
              });
            } else {
              addToast({
                title: "You successfully submitted your review.",
                kind: "success",
                delay: 5000,
              });
              history.push({
                pathname: returnPathname,
                state: { orgAdminJobsListUrl: orgAdminJobsListUrl }, //allows value to be pushed back to originating job on review publish/update
              });
            }
          },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[ReviewModal] handle error when publishing Job Review", "color: #FF7602", {
        error,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleChangeSelectRating = (name, value) => {
    // setIsDirty(true);
    setRating(value);
  };

  React.useEffect(() => {
    window.logger("%c[ReviewModal] useEffect from rating", "color: #1976D2", {
      rating,
    });
    handleAutoSave();
  }, [rating, handleAutoSave]);

  const submitButton = (
    <Button
      name="createOrEditButton"
      type="submit"
      onClick={handleSubmit}
      loading={isLoadingUpdate && !isDirty}
    >
      {isDraft ? "Post your review" : "Update review"}
    </Button>
  );

  return (
    <>
      <Styled.ResumeColumn>
        <Styled.Profile>
          <h2>
            {candidate.fullName} <span>as {jobApplication?.job.title}</span>
          </h2>
          <div>
            {!candidate.phone && !candidate.email && (
              <Styled.Node>
                <Icon name="slash" />
                <span>No email address or phone number</span>
              </Styled.Node>
            )}
            {candidate.email && candidate.hasValidEmail && (
              <Styled.Node href={`mailto:${candidate.email}`} target="_blank">
                <Icon name="mail" />
                {candidate.email}
              </Styled.Node>
            )}
            {candidate.phone && (
              <Styled.Node href={`tel:${candidate.phone}`}>
                <Icon name="phone" />
                {formatPhoneNumber(candidate.phone)}
              </Styled.Node>
            )}
            {candidate.linkedinUrl && (
              <Tooltip label="LinkedIn">
                <Styled.Node
                  href={`https://linkedin.com/in/${candidate.linkedinUrl}`}
                  target="_blank"
                  icon
                >
                  <Icon name="linkedin" />
                </Styled.Node>
              </Tooltip>
            )}
            {candidate.twitterUrl && (
              <Tooltip label="Twitter">
                <Styled.Node
                  href={`https://twitter.com/${candidate.twitterUrl}`}
                  target="_blank"
                  icon
                >
                  <Icon name="twitter" />
                </Styled.Node>
              </Tooltip>
            )}
            {candidate.githubUrl && (
              <Tooltip label="GitHub">
                <Styled.Node
                  href={`https://github.com/${candidate.githubUrl}`}
                  target="_blank"
                  icon
                >
                  <Icon name="GitHub" />
                </Styled.Node>
              </Tooltip>
            )}
            {candidate.dribbbleUrl && (
              <Tooltip label="Dribbble">
                <Styled.Node
                  href={`https://dribbble.com/${candidate.dribbbleUrl}`}
                  target="_blank"
                  icon
                >
                  <Icon name="dribbble" />
                </Styled.Node>
              </Tooltip>
            )}
            {candidate.websiteUrl && (
              <Tooltip label="Website">
                <Styled.Node href={`https://${candidate.websiteUrl}`} target="_blank" icon>
                  <Icon name="globe" />
                </Styled.Node>
              </Tooltip>
            )}
          </div>
        </Styled.Profile>
        <CollapsibleQuestionResponses jobApplication={jobApplication} />
        <h3>Resume</h3>
        <Resume jobApplication={jobApplication} />

        {!isEmpty(jobApplication?.sharedDocument) &&
        jobApplication?.sharedDocument !== "<p></p>" ? (
          <>
            <h3>Hiring document</h3>
            <Styled.Notes>
              <div dangerouslySetInnerHTML={{ __html: jobApplication.sharedDocument }} />
            </Styled.Notes>
          </>
        ) : null}

        {candidatePrivateNote != undefined &&
          candidatePrivateNote.body != undefined &&
          candidatePrivateNote.body != "" &&
          candidatePrivateNote.body != "<p></p>" ? (
          <>
            <h3>Notes</h3>
            <Styled.Notes>
              <div dangerouslySetInnerHTML={{ __html: candidatePrivateNote.body }} />
            </Styled.Notes>
          </>
        ) : null}
      </Styled.ResumeColumn>
      <Styled.ReviewColumn>
        <Styled.ReviewInput errors={errors}>
          <ProseMirrorEditor
            ref={editorRef}
            editorId="reviews-editor"
            placeholder="Write a candidate review to share with the hiring team..."
            enableStaticMenuBar
            enableCheckboxes={false}
            enableInsertMenuItems={false}
            enableInsertReviewTemplates
            onChange={handleEditorChange}
            autoSave={handleAutoSave}
            defaultValue={body || ""}
          />

          <Styled.BottomBar>
            <FormSelect
              onChange={handleChangeSelectRating}
              name="reviewRating"
              placeholder="Select your overall rating"
              className=""
              menuPlacement="auto"
              value={rating}
              options={reviewRatingOptions}
              // errors={errors}
              isClearable
            />
            <Styled.Actions>
              {submitButton}
              {isDirty ? <span>Changes not yet saved</span> : <span>No unsaved changes</span>}
            </Styled.Actions>
          </Styled.BottomBar>
        </Styled.ReviewInput>
      </Styled.ReviewColumn>
    </>
  );
}

ReviewKit.defaultProps = {};

export default ReviewKit;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ResumeColumn = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_ResumeColumn;
    ${[t.p(4)]};
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 90vw;
    flex-shrink: 0;

    > h3 {
      ${[t.mt(6), t.mb(3), t.text.h3]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }

    ${t.mq["lg"]} {
      width: 50%;
      flex-shrink: 1;
    }
  `;
});

Styled.ReviewColumn = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_ReviewColumn;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 50%;
      flex-shrink: 1;
    }
  `;
});

Styled.Notes = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_Notes;
    ${[textStyles, t.p(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    flex-grow: 1;
    overflow-y: auto;
    ${t.dark &&
    `
      color: ${t.color.gray[200]};
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}
  `;
});

Styled.ReviewInput = styled(FormContainer)((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_ReviewInput;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  `;
});

Styled.BottomBar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_BottomBar;
    ${t.p(4)}
    border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_Actions;
    display: flex;
    align-items: center;

    > span {
      ${[t.text.xs, t.mx(4)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.Profile = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_Profile;

    h2 {
      ${[t.text.h1, t.mb(1)]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};

      span {
        color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
        ${[t.text.h3, t.text.normal]}
      }
    }

    > div {
      display: flex;
    }
  `;
});

Styled.Node = styled.a((props: any) => {
  const t: any = props.theme;
  return css`
    label: ReviewKit_ProfileNode;
    ${[t.mt(2), t.mr(2), t.h(8), t.px(2), t.text.sm, t.rounded.sm]}
    display: inline-flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    &:link,
    &:visited {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    transition: border-color 0.2s ease, color 0.2s ease;

    &:hover {
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[400]};
    }

    svg {
      font-size: 0.8125rem;
      ${t.mr(2)}
    }

    ${props.icon &&
    css`
      ${[t.w(8), t.px(0)]}
      justify-content: center;

      svg {
        ${t.mr(0)}
      }
    `}
  `;
});

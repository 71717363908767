import React, { useState } from "react";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";

import Icon from "@ats/src/components/shared/Icon";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { string } from "prop-types";
import { useSpring, animated, config } from "react-spring";

function JobListFilter(props) {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);

  const isAuthorized = useAuthorization();
  const authorized = isAuthorized({ adminOnly: true });

  const ref = React.useRef(null);

  useOnClickOutside(ref, () => {
    handleClickOutside();
  });

  const handleClickOutside = () => {
    if (dropdownIsVisible) {
      setDropdownIsVisible(false);
    }
  };

  const toggleDropdownVisibility = (e) => {
    e.preventDefault();
    setDropdownIsVisible(!dropdownIsVisible);
  };

  const closeDropdown = (e) => {
    e.stopPropagation();
    handleClickOutside();
  };

  const yourJobsLink = (
    <Link to={"/jobs"} onClick={(e) => e.stopPropagation()}>
      View your assigned jobs
    </Link>
  );

  const allJobsLink = (
    <Link to={"/jobs/all"} onClick={(e) => e.stopPropagation()}>
      View all active jobs
    </Link>
  );

  const archivedJobsLink = (
    <Link to={"/jobs/archived"} onClick={(e) => e.stopPropagation()}>
      View archived jobs
    </Link>
  );

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.95)",
      pointerEvents: "none",
    },
    to: {
      opacity: dropdownIsVisible ? 1 : 0,
      transform: dropdownIsVisible ? "scale(1)" : "scale(0.95)",
      pointerEvents: dropdownIsVisible ? "all" : "none",
    },
    config: {
      tension: 480,
      clamp: true,
    },
  });

  return authorized ? (
    <div ref={ref}>
      <Styled.Title onClick={toggleDropdownVisibility}>
        {props.title}
        <Icon name={dropdownIsVisible ? "chevron-up" : "chevron-down"} />
      </Styled.Title>

      <Styled.Dropdown style={spring} onClick={closeDropdown}>
        <Styled.DropdownContent>
          {props.path !== "/jobs" && yourJobsLink}
          {props.path !== "/jobs/all" && allJobsLink}
          {props.path !== "/jobs/archived" && archivedJobsLink}
        </Styled.DropdownContent>
      </Styled.Dropdown>
    </div>
  ) : (
    <>Active jobs</>
  );
}

JobListFilter.propTypes = {
  title: string,
  path: string,
};

JobListFilter.defaultProps = {};

export default JobListFilter;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Title = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListFilter_Title;
    ${[t.text.lg, t.text.bold, t.h(10), t.px(2), t.ml(-1)]};
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    ${t.mq["md"]} {
      ${[t.h(10), t.px(3), t.rounded.sm]};
    }

    ${t.mq["lg"]} {
      ${[t.ml(-3)]};
    }

    svg {
      ${t.text.sm}
      stroke-width: 2px;
      ${t.ml(1)}
    }

    &:hover {
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.Dropdown = styled(animated.div)((props) => {
  const t: any = props.theme;
  const modeStyles = t.dark
    ? css`
        background-color: ${t.color.gray[700]};
        border: 1px solid ${t.color.gray[600]};
        color: ${t.color.gray[200]};
      `
    : css`
        background-color: ${t.color.white};
        border: 1px solid ${t.color.gray[400]};
      `;

  return css`
    label: JobListFilter_Dropdown;
    ${[t.p(1), t.mt(-1), t.l(2), t.text.sm, t.rounded.sm]}
    padding-bottom: 2px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 3rem;
    overflow: hidden;
    z-index: 20;
    transform-origin: top left;
    ${modeStyles}
  `;
});

Styled.DropdownContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListFilter_DropdownContent;
    display: flex;
    flex-direction: column;
    ${t.text.normal}

    a {
      ${[t.pr(6), t.text.sm, t.rounded.xs]}
      margin-bottom: 2px;
      padding-left: 0.625rem;
      height: 28px;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: background-color 0.2s ease;
    }
    a:hover,
    a:focus {
      background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]};
      color: ${t.dark ? t.color.white : t.color.black};
      outline: none;
    }
  `;
});

import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { timeAgoInWordsShort } from "@shared/lib/time";
import Icon from "@ats/src/components/shared/Icon";

import {
  useDeleteNotification,
  useMarkNotificationAsRead,
  useMarkNotificationAsUnread,
} from "@shared/queryHooks/useNotification";

import Button from "@ats/src/components/shared/Button";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

const NotificationListItem = React.forwardRef(
  (
    props: {
      history: any;
      style: any;
      // kind: string;
      // unreadCount: number;
      activity: {
        id: number;
        key: string;
        unread?: boolean;
        createdAtTimestamp: number;
        // parameters: {
        //   candidate?: any;
        //   user?: any;
        //   jobTitle?: string;
        //   relativeUrl?: string;
        //   jobApplicationUrl?: string;
        //   createdAtTimestamp: number;
        //   updatedAtTimestamp: number;
        //   candidateFullName?: string;
        // };
        parameters: any;
      };
    },
    ref,
  ) => {
    const { history, activity } = props;
    const { id, unread, parameters, createdAtTimestamp } = activity;

    const [isDismissing, setIsDismissing] = React.useState(false);

    // window.logger(
    //   "%c[NotificationListItem] render",
    //   "color: #18FF23; background-color: #663234",
    //   props,
    // );

    const {
      mutate: markNotificationAsRead,
      error: errorOnMarkRead,
      isLoading: isLoadingMarkRead,
    } = useMarkNotificationAsRead();

    const {
      mutate: markNotificationAsUnread,
      error: errorOnMarkUnread,
      isLoading: isLoadingMarkUnread,
    } = useMarkNotificationAsUnread();

    const {
      mutate: deleteNotification,
      error: errorOnDelete,
      isLoading: isLoadingDelete,
    } = useDeleteNotification();

    React.useEffect(() => {
      if (isLoadingDelete) {
        setIsDismissing(true);
      }
    }, [isLoadingDelete]);

    const onClickMarkAsRead = (event) => {
      event.preventDefault();

      window.logger("%c[NotificationListItem] onClickMarkAsRead", "color: #1976D2", { id });

      markNotificationAsRead({ id });
    };

    const onClickMarkAsUnread = (event) => {
      event.preventDefault();
      markNotificationAsUnread({ id });
    };

    const markAsReadAndNavigate = (url) => {
      markNotificationAsRead(
        { id },
        {
          onSuccess: () => {
            history.push(url);
          },
        },
      );
    };

    const onClickDelete = (event) => {
      event.stopPropagation();
      event.preventDefault();

      window.logger("%c[NotificationListItem] onClickDelete", "color: #1976D2", { id });

      deleteNotification(
        { id },
        {
          onSuccess: (data) => {
            window.logger("%c[NotificationListItem] onSuccess onClickDelete", "color: #1976D2", {
              data,
            });
          },
          onError: () => {
            setIsDismissing(false);
          },
        },
      );
    };

    let navigationUrl, timestamp, content;
    switch (activity.key) {
      case "new_job_application":
        // timestamp = timeAgoInWordsShort(parameters.createdAtTimestamp);
        timestamp = timeAgoInWordsShort(createdAtTimestamp);
        navigationUrl = parameters.navigationUrl;
        content = (
          <>
            <Icon name="user" />
            <div>
              <h3>{parameters.candidateFullName}</h3>
              <Styled.Context>
                New candidate
                <Styled.Seperator>‧</Styled.Seperator>
                {parameters.jobTitle}
              </Styled.Context>
            </div>
          </>
        );
        break;
      case "new_comment":
        timestamp = timeAgoInWordsShort(createdAtTimestamp);
        navigationUrl = parameters.navigationUrl;
        content = (
          <>
            <Icon name="info" />
            <div>
              <h3>{parameters.candidateFullName}</h3>
              <Styled.Context>
                New comment
                <Styled.Seperator>‧</Styled.Seperator>
                {parameters.jobTitle}
              </Styled.Context>
            </div>
          </>
        );
        break;
      case "new_review":
        timestamp = timeAgoInWordsShort(createdAtTimestamp);
        navigationUrl = parameters.navigationUrl;
        content = (
          <>
            <Icon name="check-circle" />
            <div>
              <h3>{parameters.candidateFullName}</h3>
              <Styled.Context>
                New review
                <Styled.Seperator>‧</Styled.Seperator>
                {parameters.jobTitle}
              </Styled.Context>
            </div>
          </>
        );
        break;
      case "new_inbound_channel_message":
      case "new_outbound_channel_message":
        // if (parameters.candidate == undefined) {
        //   return null;
        // }
        // timestamp = timeAgoInWordsShort(parameters.createdAtTimestamp);
        timestamp = timeAgoInWordsShort(createdAtTimestamp);
        navigationUrl = parameters.navigationUrl;
        content = (
          <>
            <Icon name="message-square" />
            <div>
              <h3>{parameters.candidateFullName}</h3>
              <Styled.Context>
                {/* {unreadCount > 1 ? `${unreadCount} ` : ""}New message{unreadCount > 1 ? "s" : ""} */}
                New message
                <Styled.Seperator>‧</Styled.Seperator>
                {parameters.jobTitle}
              </Styled.Context>
            </div>
          </>
        );
        break;
      // case "channel_message.new.outbound":
      //   // if (parameters.candidate == undefined) {
      //   //   return null;
      //   // }
      //   timestamp = timeAgoInWordsShort(parameters.createdAtTimestamp);
      //   navigationUrl = parameters.messagesUrl;
      //   content = (
      //     <>
      //       <Icon name="message-square" />
      //       <div>
      //         <h3>{parameters.candidateFullName}</h3>
      //         <Styled.Context>
      //           {parameters.user.fullName} Sent message
      //           <Styled.Seperator>‧</Styled.Seperator>
      //           {parameters.jobTitle}
      //         </Styled.Context>
      //       </div>
      //     </>
      //   );
      //   break;
      default:
        return null;
    }

    return (
      <Styled.Container
        ref={ref}
        style={props.style}
        onClick={() => markAsReadAndNavigate(navigationUrl)}
      >
        <Styled.Info>
          <Styled.Badge unread={unread} />
          {content}
        </Styled.Info>
        <Styled.Actions>
          <Styled.Timestamp>{timestamp}</Styled.Timestamp>
          <Button styleType="secondary" onClick={onClickDelete} loading={isDismissing}>
            Dismiss
          </Button>
          <DropdownMenu>
            {unread ? (
              <button onClick={onClickMarkAsRead}>Mark as read</button>
            ) : (
              <button onClick={onClickMarkAsUnread}>Mark as unread</button>
            )}
          </DropdownMenu>
        </Styled.Actions>
      </Styled.Container>
    );
  },
);

NotificationListItem.displayName = "NotificationListItem";

NotificationListItem.defaultProps = {};

export default NotificationListItem;

/* Styled Components
======================================================= */

let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem;
    ${[t.pl(4), t.pr(3), t.py(3)]}
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  `;
});

Styled.Badge = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    ${[t.h(2), t.w(2), t.mr(2), t.mt(1), t.rounded.md]}
    background-color: ${props.unread
      ? t.color.red[500]
      : t.dark
        ? t.color.gray[700]
        : t.color.gray[200]};
    transition: background-color 0.2s ease;
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem_Info;
    display: flex;
    h3 {
      ${t.mb(1)}
      color: ${t.dark ? t.color.gray[200] : t.color.black}
    }
    > svg {
      ${t.mr(2)}
      color: ${t.dark ? t.color.gray[200] : t.color.black}
    }
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem_Seperator;
    ${t.mx(1)}
  `;
});

Styled.Timestamp = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem_Timestamp;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]}
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem_Actions;
    display: flex;
    align-items: center;
    transition: opacity 0.2s ease;
    > button {
      ${[t.mr(3), t.ml(6)]}
    }
  `;
});

Styled.Context = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationListItem_Context;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]}
  `;
});

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useToastContext } from "@shared/context/ToastContext";

import Button from "@ats/src/components/shared/Button";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Banner from "@ats/src/components/shared/Banner";

import Styled from "@ats/src/views/sessions/components/Styled";

import { useResendEmailConfirmation } from "@shared/queryHooks/useMe";
import { useGetMagicLinkStatusByEmailHash } from "@shared/queryHooks/useSession";

function VerifyEmail({ location }) {
  const addToast = useToastContext();
  const params = new URLSearchParams(location.search);
  const emailHash = params.get("e");
  const email = atob(emailHash);
  // window.logger("%c[VerifyEmail] RENDER", "color: #1976D2", {
  //   location,
  //   emailHash,
  //   email,
  // });

  /* Queries
  --===================================================-- */
  const { data: magicLinkStatusData, isLoading: isLoading } = useGetMagicLinkStatusByEmailHash(
    emailHash,
  );

  window.logger("%c[VerifyEmail] RENDER", "color: #1976D2", { magicLinkStatusData, email });
  console.log("%c[VerifyEmail] RENDER", "color: #1976D2", { magicLinkStatusData, email });

  return (
    <Styled.Container>
      <HeaderBar />
      {magicLinkStatusData?.isValid != undefined && !magicLinkStatusData?.isValid && (
        <Banner message="Link expired." />
      )}
      <Styled.UI>
        <Styled.Form>
          <Helmet title="Verify it is you" />
          <h2>Verify it is you</h2>
          <Styled.Directions>
            <p>We sent an email to {email}.</p>
            <p>
              In that email you'll find a link that will bring you back to the site and will log you
              in.
            </p>
            <p>
              If for some reason you do not receive the email, contact us at{" "}
              <a href="mailto:support@polymer.co">support@polymer.co</a>.
            </p>
          </Styled.Directions>
        </Styled.Form>
        <Styled.Links>
          <Link to="/logout">Log out</Link>
          {/* <Button styleType="text" onClick={sendEmailConfirmation}>
            Re-send email
          </Button> */}
        </Styled.Links>

        {magicLinkStatusData?.url != undefined && (
          <Styled.Directions>
            <h2>**** DEVELOPMENT ONLY WORKAROUND ****</h2>
            <a href={magicLinkStatusData?.url}>Click here to confirm email</a>
          </Styled.Directions>
        )}
      </Styled.UI>
    </Styled.Container>
  );
}

export default VerifyEmail;

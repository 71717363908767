import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import NewInviteForm from "@ats/src/views/accountAdmin/components/NewInviteForm";

function InviteModal(props) {
  const { onCancel } = props;
  const [isDirty, setIsDirty] = React.useState(false);

  return (
    <CenterModal headerTitleText="Invite team member" onCancel={onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Enter the information for the person you would like to invite into your hiring team.
      </Styled.Instructions>
      <NewInviteForm onCancel={onCancel} onFormUpdate={() => setIsDirty(true)} />
    </CenterModal>
  );
}

export default InviteModal;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: InviteModal_Instructions;
    ${[t.mb(5)]}
  `;
});

import keys from "lodash/keys";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";

export const errorTextViaYupOrRailsOrJoi = (props: { errors: any; name: string }) => {
  const { errors, name } = props;
  if (errors == undefined) {
    return null;
  }
  // console.log("%c[formHelpers] errorTextViaYupOrRailsOrJoi", "background-color: #65FF92", {
  //   errors,
  //   name,
  // });

  if (errors && isObject(errors) && errors[name]) {
    let error: { fieldError?: any } = errors[name];

    return isArray(error) && (error as string[]).length > 0
      ? (error as string[]).join(", ") // Rails generated error
      : isObject(error) // isObject returns true for Arrays so must be checked second
      ? error.fieldError // Yup generated error
      : null;
  }
};

// export const processErrors = (errors) => {
//   if (errors && isArray(errors) && !isEmpty(errors)) {
//     return handleErrorsArray(errors);
//   } else if (errors && isObject(errors) && !isEmpty(errors)) {
//     console.log(
//       "%c[formHelpers] ERRORS Object NOT HANDLED YET",
//       "background: red; color: white",
//       errors,
//     );
//     return handleErrorsObject(errors);
//   }
// };

// const handleErrorsArray = (errors) => {
//   return errors.reduce((accumulator, error) => {
//     const key = error.context ? error.context.key : error.key;
//     const keyRegex = error.context ? new RegExp(`"${key}" `, "gi") : new RegExp(`${key} `, "gi");
//     const message = error.message ? error.message.replace(keyRegex, "") : error;
//     accumulator[key] = message;
//     return accumulator;
//   }, {});
// };

// const handleErrorsObject = (errors) => {
//   return errors;
// };

export const prettyErrorsArray = (errors) => {
  return keys(errors)
    .map((key) => {
      console.log("%c[formHelpers] prettyErrorsArray", "background-color: #110378; color: white;", {
        errors,
        key,
      });
      const error = errors[key];
      if (key === "customQuestionsErrors") {
        return null;
      }

      if (isArray(error)) {
        // Rails Errors
        return key !== "general" ? `${upperFirst(lowerCase(key))} ${error[0]}` : error[0];
      } else if (error.groupError != undefined) {
        // Yup Errors
        return error.groupError;
      } else {
        return null;
      }
    })
    .filter((error) => error != undefined);
};

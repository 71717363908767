import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

function InterviewerPage(props) {

  const search = new URLSearchParams(window.location.search);
  const message = search?.get("message");
  window.logger("%c[InterviewerPage] ", "background: #EFDDEF; color: #1976D2", {
    props,
    message,
  });

  return (
    <Styled.Container>
      {message === "success" ? (
        <Styled.Component>
          <h1>You successfully submitted your review!</h1>
          <p>You can close this tab.</p>
        </Styled.Component>
      ) : (
        <Styled.Component>
          <h1>You do not have access to the page you are trying to view.</h1>
          <p>
            If you are trying to start or complete a requested review, please follow the link
            provided in the review request email.
          </p>
        </Styled.Component>
      )}
    </Styled.Container>
  );
}

export default InterviewerPage;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div(() => {
  return css`
    label: InterviewerPage_Container;
    display: flex;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  `;
});

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: InterviewerPage_Component;
    ${[t.py(24), t.px(4)]};
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      ${[t.mb(2), t.text.h2]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      line-height: 1.4;
    }

    p {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;
    }
  `;
});

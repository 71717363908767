import React from "react";

interface GlobalChannelContextState {
  updateAvailable: boolean;
  enableUpdateAvailable?: () => void;
  maintenanceComplete: boolean;
  enabledMaintenanceComplete?: () => void;
  disableMaintenanceComplete?: () => void;
}

interface GlobalChannelContextProps {}

const GlobalChannelContext = React.createContext<GlobalChannelContextState>({
  updateAvailable: false,
  maintenanceComplete: false,
});

function GlobalChannelContextProvider({
  children,
}: React.PropsWithChildren<GlobalChannelContextProps>) {
  const [updateAvailable, setUpdateAvailable] = React.useState<boolean>(false);
  const [maintenanceComplete, setMaintenanceComplete] = React.useState<boolean>(false);

  const enableUpdateAvailable = () => {
    setUpdateAvailable(true);
  };

  const enabledMaintenanceComplete = () => {
    setMaintenanceComplete(true);
  };

  const disableMaintenanceComplete = () => {
    setMaintenanceComplete(false);
  };

  return (
    <GlobalChannelContext.Provider
      value={{
        updateAvailable,
        enableUpdateAvailable,
        maintenanceComplete,
        enabledMaintenanceComplete,
        disableMaintenanceComplete,
      }}
    >
      {children}
    </GlobalChannelContext.Provider>
  );
}

function useGlobalChannelContext() {
  const context = React.useContext(GlobalChannelContext);
  if (context === undefined) {
    throw new Error("useGlobalChannelContext must be used within a GlobalChannelContextProvider");
  }
  return context;
}

export { GlobalChannelContextProvider, useGlobalChannelContext, GlobalChannelContext };

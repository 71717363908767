import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormSelect from "@ats/src/components/forms/FormSelect";

/* Styled Components
======================================================= */
// let Styled: { Field?: any; Label?: any; RequiredLabel?: any };
let Styled: any;
Styled = {};

Styled.Field = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormFieldMapper_Field;
    ${[t.h(12), t.pl(4), t.pr(2)]}
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    &:first-of-type {
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    > div {
      ${t.mb(0)}
      flex: 1;
    }
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormFieldMapper_Label;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.RequiredLabel = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: FormFieldMapper_RequiredLabel;
    ${[t.text.xs, t.text.normal, t.ml(1)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: block;
  `;
});

type Props = {
  field: string;
  label: string;
  mappingOptions: any[];
  selectedValue: string;
  onSelect: ({ name, value, selectedValue }) => void;
  isRequired: boolean;
  errors: {};
};

function FormFieldMapper(props: Props) {
  const { field, label, mappingOptions, selectedValue, onSelect, isRequired, errors } = props;

  const handleSelection = (name, value) => {
    window.logger("[FormFieldMapper] handleSelection", {
      name,
      value,
      // field,
      // label,
      // mappingOptions,
      // selectedValue,
    });
    onSelect({ name, value, selectedValue });
  };

  return (
    <Styled.Field>
      <Styled.Label>
        {label} {props.isRequired && <Styled.RequiredLabel>(required)</Styled.RequiredLabel>}
      </Styled.Label>

      <FormSelect
        onChange={handleSelection}
        name={field}
        placeholder="None"
        value={selectedValue}
        isClearable={true}
        options={mappingOptions}
        isRequired={isRequired}
        errors={errors}
      />
    </Styled.Field>
  );
}

FormFieldMapper.defaultProps = {};

export default FormFieldMapper;

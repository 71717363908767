import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import keys from "lodash/keys";
import Tooltip from "@ats/src/components/shared/Tooltip";
import FloatingToolbar from "@shared/ProseMirror/FloatingToolbar";

function SelectionToolbar({ viewRef, menu, state, dispatch, handleClickOnLinkMenuItem }) {
  const menuSection = menu["marks"];

  // console.log("%c[SelectionToolbar][ProseMirror] render", "color: #1976D2", {
  //   menu,
  //   state,
  //   dispatch,
  //   view: viewRef.current?.view,
  // });

  return (
    <FloatingToolbar viewRef={viewRef} state={state}>
      <Styled.SelectionToolbarMenuItems>
        {keys(menuSection).map((item, index) => (
          <Tooltip key={item} label={menuSection[item].title}>
            <button
              // key={item}
              type="button"
              tabIndex={-1}
              className={
                menuSection[item].active && menuSection[item].active(state) ? "active" : null
              }
              // title={menuSection[item].title}
              // disabled={menu[key].enable && !menu[key].enable(state)}
              onMouseDown={(event) => {
                event.preventDefault();
                viewRef.current.view.focus();

                if (item === "link") {
                  handleClickOnLinkMenuItem(state, dispatch);
                } else {
                  menuSection[item].run(state, dispatch);
                }
              }}
            >
              {menuSection[item].content}
            </button>
          </Tooltip>
        ))}
      </Styled.SelectionToolbarMenuItems>
    </FloatingToolbar>
  );
}

export default SelectionToolbar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.SelectionToolbarMenuItems = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;

    span {
      height: 100%;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        ${[t.h(4), t.w("px"), t.ml(2)]}
        background-color: ${t.color.gray[200]};
      }
      &:last-of-type:after {
        display: none;
      }
    }

    button {
      ${[t.h(8), t.w(8), t.rounded.sm]}
      margin: 0 0.125rem;
      color: ${t.dark ? t.color.gray[200] : t.color.gray[600]};
      background-color: transparent;
      transition: background-color 0.2s ease;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 1.25em;
        width: 1.25em;
        stroke-width: 2.25px;
      }
      &:focus {
        outline: none;
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &:active:focus {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &:hover {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &.active {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
    }
  `;
});

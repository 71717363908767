import { HiringStage } from "@ats/types/job";
import isEmpty from "lodash/isEmpty";

export const guid = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const isServer = () => !(typeof window !== "undefined" && (window as any).document);

export const startsWith = (string, searchValue) => string.lastIndexOf(searchValue, 0) === 0;

export const pluralize = (word, count) => `${word}${count !== 1 ? "s" : ""}`;

export const capitalizeSingleWord = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// const __DEV__ = process.env.NODE_ENV === "development";
// export const CareerRoot = __DEV__ ? "http://jobs.lvh.me:5007" : APP_JOBBOARD_ROOT_URL; // Do not have an SSL cert yet

/* nextLogical
--===================================================-- */
// Used to find the next logical Object (that has an id attribute) in a list.
// If there is only 1 item in the list, do nothing
// If there are items after this item, return the next item
// If we are at the end of the list return the previous item
export const nextLogical = ({ list, currentItem }) => {
  const length = list.length;
  const currentIndex = list.findIndex((item) => item.id === currentItem.id);

  if (currentIndex + 1 === length) {
    // last Item, check if is only one, if so do nothing.
    if (currentIndex === 0) {
      return null;
    }

    // return the Previous Item
    return list[currentIndex - 1];
  } else {
    // return the Next Item
    return list[currentIndex + 1];
  }
};

/* hiringStagesForJob
--===================================================-- */
export const hiringStagesForJob = (job): HiringStage[] => {
  if (isEmpty(job.hiringStages)) {
    return [];
  }

  const hiringStages = job.hiringStages;
  const inboxStage = hiringStages.find((stage) => stage.kind === "kind_inbox");
  const hiredStage = hiringStages.find((stage) => stage.kind === "kind_hired");
  const archivedStage = hiringStages.find((stage) => stage.kind === "kind_archived");
  const inProcessStages = hiringStages
    .filter((stage) => stage.kind === "kind_in_process")
    .sort((a, b) => a.position - b.position);

  const stages = [inboxStage, ...inProcessStages, hiredStage, archivedStage];
  return stages;
};

export interface HiringStageOption {
  value: number;
  label: string;
}

export const hiringStagesForJobOptions = (job): HiringStageOption[] => {
  return hiringStagesForJob(job).map((hiringStage) => ({
    value: hiringStage.id,
    label: hiringStage.name,
  }));
};

/* hiringStageAutomation
--===================================================-- */
export const activeHiringStageAutomation = (hiringStages, targetHiringStageId) => {
  const hiringStage = hiringStages.find((stage) => stage.id === targetHiringStageId);
  const targetStageAutomation = getActiveHiringStageAutomation(hiringStage)
  return targetStageAutomation;
}

export const getActiveHiringStageAutomation = (hiringStage) => {
  const hiringStageAutomation = hiringStage?.hiringStageAutomation && hiringStage?.hiringStageAutomation.enabled === true
    ? hiringStage.hiringStageAutomation
    : null;
  return hiringStageAutomation;
}

/* arrayToObject
--===================================================-- */
export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

export const prettyPlanName = (plan) => {
  if (plan.includes("gemini")) {
    return "Gemini";
  } else if (plan.includes("apollo")) {
    return "Apollo";
  } else if (plan.includes("plan_simple_ats_per_job")) {
    return "Simple ATS";
  } else if (plan.includes("plan_simple_ats_paid")) {
    return "Simple ATS";
  } else {
    return "Alpha";
  }
};

export const prettyBillingPrice = (price) => {
  window.logger("%c[prettyBillingPrice] ", "color: #1976D2", { price });
  let calculatedPrice =
    price.billingScheme === "tiered"
      ? price.tiers[0].unitAmount / 100.0
      : price.interval === "year" || price.recurring.interval === "year"
      ? price.unitAmount / 12.0 / 100.0
      : price.unitAmount / 100.0;

  if (calculatedPrice > 999.99) {
    return calculatedPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return Number.isInteger(calculatedPrice)
      ? calculatedPrice.toString()
      : calculatedPrice.toFixed(2);
  }
};

import React from "react";
import { Draggable } from "react-beautiful-dnd";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

/*
--===================================================-- */

// export default class HiringStageListItem extends Component {
export default function HiringStageListItem(props) {
  const { hiringStage, index, openEditHiringStageModal, deleteHiringStage } = props;
  const { name, jobApplicationsCount } = hiringStage;

  // window.logger("%c[HiringStageListItem] stage", "color: #1976D2", { hiringStage });

  const handleOpenHiringStageModal = (event) => {
    event.preventDefault();

    openEditHiringStageModal(hiringStage);
  };

  const handleDeleteHiringStage = (event) => {
    event.preventDefault();

    deleteHiringStage(hiringStage);
  };

  return (
    <Draggable
      draggableId={hiringStage.id} // required prop
      index={index} // required prop
    >
      {(provided) => {
        return (
          <Styled.Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div>
              <Styled.HiringStageText>{name}</Styled.HiringStageText>
              <Styled.HiringStageDetails>{`${Number(jobApplicationsCount) > 0 ? jobApplicationsCount : "No"
                } candidate${Number(jobApplicationsCount) === 1 ? "" : "s"
                }`}</Styled.HiringStageDetails>
            </div>
            <DropdownMenu label="Options">
              <a href="" onClick={handleOpenHiringStageModal}>
                Edit stage
              </a>
              <a href="" onClick={handleDeleteHiringStage}>
                Delete stage
              </a>
            </DropdownMenu>
          </Styled.Container>
        );
      }}
    </Draggable>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    transition: background-color 0.2s ease, border-color 0.2s ease;
    &:hover,
    &:active {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.HiringStageText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageListItem_HiringStageText;
    ${t.mb(1)}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.HiringStageDetails = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageListItem_HiringStageDetails;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

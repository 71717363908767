import React, { useEffect } from "react";

import { useToastContext } from "@shared/context/ToastContext";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSection from "@ats/src/components/forms/FormSection";

import {
  useCreateRegisteredWebhook,
  useRegisteredWebhooks,
  useUpdateRegisteredWebhook,
  useDeleteRegisteredWebhook,
} from "@shared/queryHooks/useRegisteredWebhooks";
import { RegisteredWebhook } from "@shared/types/registeredWebhooks";

function AccountIntegrationsPolymerWebhooks(props) {
  const addToast = useToastContext();
  const { history } = props;
  const { data: webhooksData, isLoading } = useRegisteredWebhooks();
  const [isDirty, setIsDirty] = React.useState(false);
  const [webhookData, setWebhookData] = React.useState<RegisteredWebhook>({ url: "" });

  useEffect(() => {
    setWebhookData(webhooksData?.items[0] || { url: "" });
  }, [isLoading]);

  const {
    mutate: createRegisteredWebhook,
    isLoading: isLoadingCreateRegisteredWebhook,
  } = useCreateRegisteredWebhook();

  const {
    mutate: updateRegisteredWebhook,
    isLoading: isLoadingUpdateRegisteredWebhook,
  } = useUpdateRegisteredWebhook();

  const {
    mutate: deleteRegisteredWebhook,
    isLoading: isLoadingDeleteRegisteredWebhook,
  } = useDeleteRegisteredWebhook();

  const updateRegisteredWebhookField = (name, value) => {
    setIsDirty(true);
    setWebhookData({ ...webhookData, url: value });
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    let updateMethod = createRegisteredWebhook;
    if (webhookData.id && webhookData.url) {
      updateMethod = updateRegisteredWebhook;
    } else if (webhookData.id && !webhookData.url) {
      updateMethod = deleteRegisteredWebhook;
    }

    updateMethod(webhookData, {
      onSuccess: () => {
        setIsDirty(false);
        addToast({
          title: `Webhook URL saved`,
          kind: "success",
        });
      },
      onError: (response: any) => {
        addToast({
          title: `Failed to save webhook URL`,
          kind: "danger",
        });
      },
    });
  };

  const buttonDisabled =
    isLoadingUpdateRegisteredWebhook ||
    isLoadingCreateRegisteredWebhook ||
    isLoadingDeleteRegisteredWebhook;

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[JobDistributionWeWorkRemotely] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={onClickSubmit}
        disabled={buttonDisabled}
        loading={buttonDisabled}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="Polymer Webhooks"
      description="Add a webhook url that we will POST to anytime a new job application is created."
      bottomBar={BottomBar}
      fullWidthForm={true}
    >
      <FormSection>
        <FormInput
          name="webhookUrl"
          onChange={updateRegisteredWebhookField}
          placeholder=""
          label="Webhook URL"
          description=""
          value={webhookData.url}
          errors={null}
        />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsPolymerWebhooks;

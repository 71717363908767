import { useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createInterviewerInvite = async ({ jobApplicationId, email }) => {
  return await apiPost({
    path: `/job_applications/${jobApplicationId}/interviewer_invites`,
    variables: { email },
  });
};

/* Hooks
--===================================================-- */

export function useCreateInterviewerInvite() {
  const queryClient = useQueryClient();
  return useMutation(createInterviewerInvite, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useInterviewerInvite] useCreateInterviewerInvite onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("jobApplicationActivities");
    },
    // throwOnError: true,
  });
}
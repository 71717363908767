import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useToastContext } from "@shared/context/ToastContext";
import { useTakeoverOrganization } from "@shared/queryHooks/admin/useOrganization";
import { validateTakeoverOrganization } from "@shared/lib/validateWithYup";

type Props = {
  organizationId: number;
  organizationName: string;
  onCancel: () => void;
};

function TakeoverOrganizationModal(props: Props) {
  const { organizationId, organizationName, onCancel } = props;
  const addToast = useToastContext();
  const [email, setEmail] = useState("");
  const [enteredOrganizationName, setEnteredOrganizationName] = useState("");
  const [errors, setErrors] = useState([]);

  const {
    mutate: takeoverOrganization,
    isLoading: isLoadingTakeoverOrganization,
  } = useTakeoverOrganization();

  const handleOnCancel = (event) => {
    if (event != undefined) {
      event.preventDefault();
    }
    onCancel();
  };

  const handleOnConfirm = async (event) => {
    event.preventDefault();
    const [isValid, validationErrors] = await validateTakeoverOrganization(
      { organizationName: enteredOrganizationName },
      organizationName,
    );
    if (isValid) {
      handleConfirmation();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleConfirmation = () => {
    window.logger(
      "%c[TakeoverOrganizationModal] handleConfirmation",
      "background-color: #197600; color: #FFF;",
      {
        organizationId,
        email,
      },
    );

    takeoverOrganization(
      { id: organizationId, email },
      {
        onSuccess: (data) => {
          window.logger("%c[TakeoverOrganizationModal] onSuccess", "color: #19d232", { data });

          addToast({
            title: `Organization takeover initiated successfully`,
            kind: "success",
            delay: 6000,
          });
          onCancel();
        },
        onError: (error: any) => {
          window.logger("%c[TakeoverOrganizationModal] onError", "color: #d28819", { error });

          let errorMessage = "An error occurred. Please try again.";

          if (error?.data && error?.data?.errors) {
            const generalErrors = error?.data?.errors?.general;
            if (generalErrors && generalErrors.length > 0) {
              errorMessage = generalErrors[0];
            }
          }
          setErrors([errorMessage]);
        },
      },
    );
  };

  const handleChangeEmail = (name, value) => {
    setEmail(value);
  };

  const handleChangeOrganizationName = (name, value) => {
    setEnteredOrganizationName(value);
  };

  const modalButtons = (
    <Styled.ButtonContainer>
      <Button
        className="submit-button"
        type="submit"
        size="small"
        onClick={handleOnConfirm}
        loading={isLoadingTakeoverOrganization}
        disabled={
          isLoadingTakeoverOrganization || !email || enteredOrganizationName !== organizationName
        }
        aria-label="Takeover Organization"
      >
        Takeover
      </Button>
      <Button styleType="secondary" onClick={handleOnCancel}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText={`Takeover Organization`} onCancel={handleOnCancel}>
      <Styled.Instructions>
        <p>
          You will be taking over the organization named <b>{organizationName}</b> and transferring
          ownership to the user with the email address you enter below.
        </p>
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={modalButtons} onSubmit={handleOnConfirm}>
        <FormInput
          onChange={handleChangeEmail}
          name="email"
          label="Email Address"
          description={null}
          className=""
          value={email}
          errors={errors}
          isClearable={false}
          autoFocus
        />
        <FormInput
          onChange={handleChangeOrganizationName}
          name="organizationName"
          label="Organization Name"
          description="Type the organization name to confirm"
          className=""
          value={enteredOrganizationName}
          errors={errors}
          isClearable={false}
        />
        <Styled.Instructions>
          <p>
            This action will:
            <ul>
              <li>• Transfer ownership</li>
              <li>• Deactivate all original members</li>
              <li>• Archive all jobs</li>
              <li>• Disable LinkedIn & X Hiring</li>
              <li>• Cancel Stripe subscription</li>
            </ul>
          </p>
        </Styled.Instructions>
      </FormContainer>
    </CenterModal>
  );
}

export default TakeoverOrganizationModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: TakeoverOrganizationModal_Instructions;
    ${[t.mb(5)]}
    p {
      color: ${t.dark ? t.color.gray[400] : t.color.black}
    }

    span {
      font-weight: bold;
    }

    ul {
      ${[t.ml(3), t.mt(2)]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TakeoverOrganizationModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

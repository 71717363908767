import React, { useState } from "react";
import { Helmet } from "react-helmet";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useModalContext } from "@shared/context/ModalContext";

import JobCategoryModal from "@ats/src/components/modals/JobCategoryModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import EmptyState from "@ats/src/components/shared/EmptyState";

import JobCategoryList from "@ats/src/views/accountAdmin/components/JobCategoryList";
import JobCategoryListItem from "@ats/src/views/accountAdmin/components/JobCategoryListItem";

import useJobCategories from "@shared/queryHooks/useJobCategories";
import { useUpdateJobCategory, useDeleteJobCategory } from "@shared/queryHooks/useJobCategory";
import { moveFromIndexToIndex } from "@shared/lib/utils";

export default function AccountJobCategories(props) {
  window.logger("%c[AccountJobCategories] render", "background: #EFDDEF; color: #1976D2", {
    props,
  });
  const { openModal, removeModal } = useModalContext();

  const { status, data, error, isFetching } = useJobCategories();
  const { mutate: updateJobCategory, isLoading: isLoadingUpdate } = useUpdateJobCategory();
  const { mutate: deleteJobCategory, isLoading: isLoadingDelete } = useDeleteJobCategory();

  const catItems = data != undefined ? data.items : [];
  const [categories, setCategories] = useState([catItems]);

  React.useEffect(() => {
    setCategories(catItems);
  }, [catItems]);

  const handleClickOnDeleteJobCategory = (jobCategory) => {
    const jobsCount = jobCategory.jobsCount;

    const deleteMessage =
      jobCategory.jobsCount > 0
        ? `The ${jobCategory.name} category will be removed from ${jobsCount} active job${
            Number(jobsCount) === 1 ? "" : "s"
          }.`
        : `You will no longer be able to set a job's category to ${jobCategory.name}.`;

    window.logger(jobCategory);
    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this category?"
        subcopy={deleteMessage}
        confirmText="Delete category"
        cancelText="Keep category"
        onConfirm={() => {
          removeModal();
          handleDeleteJobCategory(jobCategory);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const handleDeleteJobCategory = async (jobCategory) => {
    try {
      await deleteJobCategory(jobCategory);
      // jobCategory was successfully deleted
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[JobCategoryModal] handle error when deleting Job Category", {
        error,
      });
    }
  };

  /* JobCategoryModal
  --===================================================-- */
  const openNewJobCategoryModal = (event) => {
    event.preventDefault();
    openJobCategoryModal({}, false);
  };

  const openEditJobCategoryModal = (category) => {
    openJobCategoryModal(category, true);
  };

  const openJobCategoryModal = (category, isEditing) => {
    const modal = (
      <JobCategoryModal onCancel={removeModal} isEditing={isEditing} category={category} />
    );

    openModal(modal);
  };

  /* onDragEndJobCategory
  --===================================================-- */
  const onDragEndJobCategory = (result: { destination: any; source: any; draggableId: number }) => {
    window.logger("%c[AccountJobCategories] onDragEndJobCategory result", "color: #1976D2", result);
    // eslint-disable-next-line no-unused-vars
    const { destination, source, draggableId } = result;

    // If there is no destinatiom then do nothing
    if (!destination) return;

    // If the user drops the item back in its original spot then do nothing
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const jobCategory: { id: number } = data.items[source.index];
    const position: number = destination.index + 1;

    const newCategories = moveFromIndexToIndex(categories, source.index, destination.index);

    setCategories(newCategories);

    updateJobCategory({ ...jobCategory, position });
  };

  /* JobCategory nodes
  --===================================================-- */
  const jobCategoryNodes = () => {
    window.logger(
      "%c[JobSetupApplicationFormJobCategories] jobCategoryNodes RENDER CATEGORIES",
      "color: #19FFD2",
      { data },
    );

    if (categories.length === 0) {
      return <EmptyState message="No job categories have been added" />;
    } else {
      return categories.map((jobCategory: { name: string }, index: number) => {
        return (
          <JobCategoryListItem
            key={`${index}_${jobCategory.name}`}
            index={index}
            jobCategory={jobCategory}
            openEditJobCategoryModal={openEditJobCategoryModal}
            deleteJobCategory={handleClickOnDeleteJobCategory}
          />
        );
      });
    }
  };

  if (status === "loading") {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <Helmet title="Job categories" />
      <SettingsContainer
        title="Job categories"
        description="Here's where you can manage the job categories used at your company. You can also control how job categories are listed on your company job board by changing the order here."
      >
        <FormSection>
          <JobCategoryList onDragEndJobCategory={onDragEndJobCategory}>
            {jobCategoryNodes()}
          </JobCategoryList>

          <Button styleType="secondary" onClick={openNewJobCategoryModal}>
            Add a category
          </Button>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

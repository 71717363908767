import React from "react";

import { enabledOrDisabledOptions } from "@ats/src/lib/lookups";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSection from "@ats/src/components/forms/FormSection";

function AccountIntegrationsGoogleJobs(props) {
  const addToast = useToastContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const { currentOrganization } = useCurrentSession();
  const [settings, setSettings] = React.useState(currentOrganization.settings);

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  const updateOrganizationSettings = (name, value) => {
    setIsDirty(true);
    const updatedSettings = Object.assign({}, settings, { [name]: value });
    setSettings(updatedSettings);
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    updateOrganization(
      { id: currentOrganization.id, settings },
      {
        onSuccess: () => {
          setIsDirty(false);
          addToast({
            title: `Successfully updated`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          addToast({
            title: `Failed to update`,
            kind: "danger",
          });
        },
      },
    );
  };

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[JobDistributionWeWorkRemotely] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={onClickSubmit}
        loading={false}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="Google Jobs"
      description="Have your published job posts appear in Google search results as part of a specialized jobs widget."
      bottomBar={BottomBar}
      fullWidthForm={true}
    >
      <FormSection>
        <FormSelect
          onChange={updateOrganizationSettings}
          name="enableGoogleJobsStructuredData"
          label="Show jobs in Google Search"
          description="Google will typically add a job within 7-days of publishing."
          value={settings.enableGoogleJobsStructuredData}
          options={enabledOrDisabledOptions}
          errors={null}
        />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsGoogleJobs;

import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPut, apiGet, apiPost, apiDelete } from "@shared/queryHooks/api";

/* API
--===================================================-- */
const getInvite = async (inviteToken) => {
  if (inviteToken != undefined) {
    return await apiGet({ path: `/invites/${inviteToken}` });
  }
};

const getInvites = async () => {
  return await apiGet({ path: `/invites` });
};

const createInvite = async (variables) => {
  return await apiPost({ path: `/invites`, variables });
};

const updateInvite = async ({ id, ...variables }) => {
  return await apiPut({ path: `/invites/${id}`, variables });
};

const resendInvite = async ({ id }) => {
  return await apiPut({ path: `/invites/${id}/resend`, variables: {} });
};

const destroyInvite = async ({ id }) => {
  return await apiDelete({ path: `/invites/${id}`, variables: {} });
};

/* Hooks
--===================================================-- */
export function useGetInvite(
  inviteToken,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
} {
  return useQuery(["invite", inviteToken], () => getInvite(inviteToken), {
    onSuccess: () => { },
    onError: () => { },
  });
}

export function useAllInvites(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
} {
  return useQuery(["invites"], () => getInvites(), {
    onSuccess: () => { },
    onError: () => { },
  });
}

export function useCreateInvite() {
  const queryClient = useQueryClient();
  return useMutation(createInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("invites");
    },
    // throwOnError: true,
  });
}

export function useUpdateInvite() {
  const queryClient = useQueryClient();
  return useMutation(updateInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("invites");
    },
    // throwOnError: true,
  });
}

export function useResendInvite() {
  const queryClient = useQueryClient();
  return useMutation(resendInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("invites");
    },
    // throwOnError: true,
  });
}

export function useDestroyInvite() {
  const queryClient = useQueryClient();
  return useMutation(destroyInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("invites");
    },
    // throwOnError: true,
  });
}

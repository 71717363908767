import * as React from "react";
import { NavLink } from "react-router-dom";

export default function PathNavLink(props) {
  return (
    <NavLink
      isActive={(_, params) => {
        // window.logger("%c[PathNavLink]", "color: #1976D2", { props, params });
        return `${params.pathname}${params.search}` == props.to;
      }}
      {...props}
    />
  );
}

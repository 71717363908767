import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost, apiDelete } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const createQuestion = async ({ jobId, ...question }) => {
  window.logger("%c[useQuestion] createQuestion\n\n\n\n", "background-color: #FF76D2", {
    question,
  });
  return await apiPost({
    path: `/jobs/${jobId}/questions`,
    variables: { question },
  });
};

const updateQuestion = async ({ jobId, id, ...question }) => {
  window.logger("%c[useQuestion] updateQuestion\n\n\n\n", "background-color: #FF76D2", {
    question,
  });
  return await apiPut({
    path: `/jobs/${jobId}/questions/${id}`,
    variables: { question },
  });
};

const getQuestion = async ({ id, jobId }) => {
  // window.logger("%c[useQuestion] getQuestion", "color: #1976D2", { key, questionId });
  if (id != undefined) {
    return await apiGet({
      path: `/jobs/${jobId}/questions/${id}`,
    });
  }
};

const deleteQuestion = async ({ jobId, id, ...question }) => {
  return await apiDelete({ path: `/jobs/${jobId}/questions/${id}`, variables: {} });
};

/* Hooks
--===================================================-- */
function useCreateQuestion() {
  const queryClient = useQueryClient();
  return useMutation(createQuestion, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useQuestion] useCreateQuestion onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["jobs", data.jobId]);
      queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

function useDropQuestion() {
  const queryClient = useQueryClient();
  return useMutation(updateQuestion, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useQuestion] useDropQuestion onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries(["jobs", data.jobId]);
      queryClient.invalidateQueries(["questions", data.jobId]);
    },
    onError: (response: any, variables) => {
      window.logger("%c[useQuestion] useDropQuestion onError", "color: #FF7602", {
        variables,
        data: response?.data,
      });
      queryClient.invalidateQueries(["jobs", variables.jobId]);
    },
    // throwOnError: true,
  });
}

function useUpdateQuestion() {
  const queryClient = useQueryClient();
  return useMutation(updateQuestion, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useQuestion] useUpdateQuestion onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries(["jobs", data.jobId]);
      queryClient.invalidateQueries(["questions", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useUpdateQuestionOptimistic(question) {
  const queryClient = useQueryClient();
  return useMutation(updateQuestion, {
    // When mutate is called:
    onMutate: (newQuestion) => {
      window.logger("%c[useQuestion] useUpdateQuestionOptimistic onMutate", "color: #1976D2", {
        question,
        newQuestion,
      });
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["questions", newQuestion.jobId]);

      // Snapshot the previous value
      const previousQuestion = queryClient.getQueryData(["questions", newQuestion.jobId]);

      // Optimistically update to the new value (combining the full question with whatever the update is)
      queryClient.setQueryData(["questions", newQuestion.jobId], { ...question, ...newQuestion });

      // Return a rollback function
      return () => queryClient.setQueryData(["questions", newQuestion.jobId], previousQuestion);
    },

    // onSuccess: (data, mutationVariables) => {
    //   window.logger("%c[useQuestion] useUpdateQuestion onSuccess", "color: #1976D2", {
    //     data,
    //     mutationVariables,
    //   });
    //   queryClient.invalidateQueries(["questions", data.id]);
    // },

    // If the mutation fails, use the rollback function we returned above in onMutate
    onError: (err: any, newQuestion: any, rollback: any) => rollback(),
    // Always refetch after error or success:
    onSettled: (newQuestion) => {
      queryClient.invalidateQueries(["questions", newQuestion.jobId]);
    },

    // throwOnError: true,
  });
}

function useDeleteQuestion() {
  const queryClient = useQueryClient();
  return useMutation(deleteQuestion, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["jobs", data.jobId]);
      queryClient.refetchQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

function useQuestion({
  id,
  jobId,
  refetchOnWindowFocus = true,
}: {
  id: number;
  jobId: number;
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useQuestion] useQuestion", "color: #1976D2", {
    id,
    jobId,
    refetchOnWindowFocus,
  });
  return useQuery(["question", { id, jobId }], () => getQuestion({ id, jobId }), {
    refetchOnWindowFocus: false,
  });
}

export {
  useQuestion,
  useCreateQuestion,
  useUpdateQuestion,
  useDropQuestion,
  useUpdateQuestionOptimistic,
  useDeleteQuestion,
};

import React, { useState } from "react";
import { Helmet } from "react-helmet";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormFieldset from "@ats/src/components/forms/FormFieldset";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormCheckbox from "@ats/src/components/forms/FormCheckbox";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateSettings, useGetMe } from "@shared/queryHooks/useMe";
import { UserSettings } from "@shared/types/user";

function AccountPreferences(props) {
  const addToast = useToastContext();
  const { setIsDirty, currentUser } = props;

  const { mutate: updateSettings, isLoading: isLoadingUpdate } = useUpdateSettings();

  const { data, error, isLoading: isLoadingGet } = useGetMe({});

  const meResponse = data != undefined ? data : currentUser || {};
  const [settings, setSettings] = useState<UserSettings>({ ...meResponse.settings } || {});
  const [errors, setErrors] = useState(error != undefined ? [error] : null);
  const { emailCommentsNew, emailMessagesNew, emailJobApplicationsNew } = settings;

  // window.logger("%c[AccountNotificaitons] render", "border: 2px solid #1F06FF", {
  //   meResponse,
  //   data,
  //   settings,
  //   emailNotificationsGlobal,
  //   isLoadingUpdate,
  //   isIdle,
  //   isSuccess,
  //   isError,
  // });

  React.useEffect(() => {
    if (meResponse.settings != undefined) {
      setSettings(meResponse.settings);
    }
  }, [meResponse]);

  // const handleFormInputChange = (name, value) => {
  //   setErrors(null);
  //   setIsDirty(true);
  // };

  const onSuccess = (data) => {
    setErrors(null);
    setIsDirty(false);

    // window.logger("%c[AccountNotification] handleSubmitForm SUCCESS", "color: #1976D2", {
    //   data,
    // });

    addToast({
      title: `Saved`,
      kind: "success",
    });
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    try {
      updateSettings(
        { ...settings },
        {
          onSuccess,
        },
      );
    } catch (error) {
      // window.logger("%c[AccountNotification] handleSubmitForm FAILED", "color: #1976D2", {
      //   settings,
      // });
    }
  };

  const handleEmailPreferenceChange = (name, value) => {
    setErrors(null);
    setIsDirty(true);

    let updateEmailPreferences = Object.assign({}, settings, { [name]: value });
    setSettings(updateEmailPreferences);
  };

  if (isLoadingGet) {
    return <LoadingIndicator label="Loading..." />;
  }

  const bottomBarContent = (
    <Button
      className="submit-button"
      size="small"
      styleType="primary"
      form="preferences-form" // this ties it to the form it is outside of
      type="submit"
      loading={isLoadingUpdate}
    >
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="User preferences" />
      <SettingsContainer
        title="User preferences"
        description="Here's where you can configure your user preferences."
        bottomBar={bottomBarContent}
      >
        <FormContainer
          id="preferences-form" // connects the form to the Submit button outside the <form>
          errors={errors}
          onSubmit={handleSubmitForm}
        >
          <FormSection title="Notifications">
            <FormFieldset
              legend="Emails"
              description="For jobs that you are on the hiring team of, you will receive email notifications for the events selected below."
            >
              <FormCheckbox
                name="emailJobApplicationsNew"
                label="New candidate application"
                checked={emailJobApplicationsNew}
                onChange={handleEmailPreferenceChange}
              />
              <FormCheckbox
                name="emailCommentsNew"
                label="New comment or review"
                checked={emailCommentsNew}
                onChange={handleEmailPreferenceChange}
              />
              <FormCheckbox
                name="emailMessagesNew"
                label="New message from a candidate"
                checked={emailMessagesNew}
                onChange={handleEmailPreferenceChange}
              />
            </FormFieldset>
            {/* <FormSelect
              onChange={handleFormInputChangeSettings}
              name="emailNotificationsComments"
              label="Comments on Candidates"
              description="Sent when a comment or review is added to a candidate that you're assigned to."
              value={emailNotificationsComments}
              options={[
                { value: true, label: "Enabled" },
                { value: false, label: "Disabled" },
              ]}
              errors={errors}
            /> */}
          </FormSection>
        </FormContainer>
      </SettingsContainer>
    </>
  );
}

AccountPreferences.propTypes = {};

AccountPreferences.defaultProps = {};

export default AccountPreferences;

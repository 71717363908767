/* eslint-disable @getify/proper-arrows/return */

import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  useMarkAllNotificationsAsRead,
  useDismissAllNotifications,
} from "@shared/queryHooks/useNotification";
import { animated, useTransition } from "react-spring";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import NotificationListItem from "@ats/src/views/notifications/NotificationListItem";
import EmptyState from "@ats/src/components/shared/EmptyState";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import Button from "@ats/src/components/shared/Button";

function NotificationList(props: {
  history: any;
  fetchMoreNotifications: () => any;
  isLoadingInfiniteNotifications: boolean;
  isFetchingInfiniteNotifications: boolean;
  data: { pages: [{ meta: any; items: [] }]; pageParams: any[] };
  searchVariables: { filter?: string; job_id?: number };
  canFetchMore: boolean;
  isFetchingMore: string | boolean;
}) {
  const {
    history,
    data,
    searchVariables,
    isLoadingInfiniteNotifications,
    isFetchingInfiniteNotifications,
    fetchMoreNotifications,
  } = props;

  const {
    mutate: markAllNotificationsAsRead,
    error: errorOnMarkAllRead,
    isLoading: isLoadingMarkAllRead,
  } = useMarkAllNotificationsAsRead();
  const {
    mutate: dismissAllNotifications,
    error: errorOnDismissAll,
    isLoading: isLoadingDismissAll,
  } = useDismissAllNotifications();

  const [refMap] = useState(() => new WeakMap());

  const notificationData =
    data != undefined
      ? data.pages.reduce((accumulator, currentValue) => {
        return accumulator.concat(currentValue.items);
      }, [])
      : [];

  const totalCount = data != undefined ? data.pages[0].meta.count : 0;

  const hasNotificationData = notificationData.length > 0;

  const pageTitle =
    searchVariables.filter === "new_candidates"
      ? "New candidates"
      : searchVariables.filter === "new_messages"
        ? "New messages"
        : searchVariables.filter === "new_activity"
          ? "New activity"
          : searchVariables.job_id != undefined
            ? "Job notifications"
            : "All notifications";

  const transitions = useTransition<any, any>(notificationData, {
    from: (item) => async (next) => {
      return await next({
        opacity: 1,
        height: refMap.get(item) ? refMap.get(item).offsetHeight : 0,
        overflow: "visible",
        marginBottom: -1,
      });
    },
    enter: (item) => async (next) => {
      // window.logger("%c[NotificationList] useTransition enter", "color: #1976D2", { item });
      return await next({
        opacity: 1,
        height: refMap.get(item) ? refMap.get(item).offsetHeight : 0,
        overflow: "visible",
        marginBottom: -1,
      });
    },
    leave: {
      opacity: 0,
      height: 0,
      overflow: "hidden",
      marginBottom: 0,
    },
    config: {
      tension: 320,
      friction: 36,
    },
  });

  // window.logger("%c[NotificationList] render", "color: maroon;", {
  //   data,
  //   notificationData,
  //   transitions,
  // });

  const onClickMarkAllRead = (event) => {
    event.preventDefault();
    markAllNotificationsAsRead(searchVariables);
  };

  const onClickDismissAll = (event) => {
    event.preventDefault();
    dismissAllNotifications(searchVariables);
  };

  if (isLoadingInfiniteNotifications) {
    return (
      <>
        <LoadingIndicator label="Loading..." />
      </>
    );
  }

  const notifications =
    transitions.length === 0
      ? []
      : transitions((props, item) => {
        // window.logger("%c[NotificationList] notifications", "background-color: #1fF6D2", {
        //   item,
        //   props,
        //   key,
        //   index,
        // });

        return (
          <Styled.ListItemWrapper style={props}>
            <NotificationListItem
              {...item}
              activity={item}
              ref={(ref) => ref && refMap.set(item, ref)}
              history={history}
            />
          </Styled.ListItemWrapper>
        );
      });

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>{pageTitle}</Styled.Title>
        <Styled.Actions>
          {hasNotificationData && (
            <DropdownMenu label="Bulk edit options">
              <button onClick={onClickMarkAllRead}>Mark all as read</button>
              <button onClick={onClickDismissAll}>Dismiss all</button>
            </DropdownMenu>
          )}
        </Styled.Actions>
      </Styled.Header>
      {hasNotificationData ? (
        <>
          <Styled.List>{notifications}</Styled.List>

          {notificationData.length < totalCount && (
            <Styled.Pagination>
              {`Showing 1-${notificationData.length} of ${totalCount} notifications.`}
              <Button styleType="text" onClick={() => fetchMoreNotifications()}>
                Load more
              </Button>
            </Styled.Pagination>
          )}
        </>
      ) : (
        <EmptyState
          icon="check"
          title="No notifications to show"
          message="You're all caught up here."
        />
      )}
    </Styled.Container>
  );
}

NotificationList.propTypes = {};

NotificationList.defaultProps = {};

export default NotificationList;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList;
    ${[t.px(4), t.pt(4), t.pb(12)]}
    display: flex;
    flex-direction: column;
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_Title;
    ${[t.mb(4)]}
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `;
});

Styled.Title = styled.h2((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_Title;
    ${[t.text.h2]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.Actions = styled.h2((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_Title;
    ${[t.mr(3), t.pr("px")]}
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    button {
      ${t.mr(3)}
    }
  `;
});

Styled.List = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_List;
    ${[t.rounded.md]}
    list-style-type: none;
  `;
});

Styled.ListItemWrapper = styled(animated.li)((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_ListItemWrapper;
    ${t.mb("-px")}
    > div {
      position: relative;
      border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
      transition: border-color 0.2s ease, border-radius 0.2s ease, margin 0.2s ease;
      background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    }
    &:first-of-type > div {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type > div {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    &:hover > div,
    &:active > div {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.Pagination = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationList_Pagination;
    ${[t.mt(5), t.pr("px")]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    button {
      ${t.ml(2)};
    }
  `;
});

import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function BottomButtonBar(props) {
  return <Styled.Container static={props.static}>{props.children}</Styled.Container>;
}

BottomButtonBar.propTypes = {
  children: PropTypes.node.isRequired,
};

/* Styled Components
======================================================= */

const Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: BottomButtonBar;
    align-items: center;
    ${[t.p(4)]}
    position: ${props.static ? "static" : "sticky"};
    z-index: 18;
    bottom: 0;
    left: 0;
    right: 0;
    flex: 0;
    background: ${t.dark ? t.color.gray[900] : t.color.white};
    border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    display: flex;

    button {
      width: auto;
    }

    > span {
      ${[t.text.xs, t.mx(4)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

import { useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createBulkMessage = async ({
  jobId,
  hiringStageId,
  body,
  excludedJobApplicationIds = null,
  includedJobApplicationIds = null,
}) => {
    window.logger(
      "%c[useBulkMessage] createBulkMessage",
      "background-color: #19F6D2",
      {
        hiringStageId,
        body,
        includedJobApplicationIds,
        excludedJobApplicationIds,
      },
    );
  return await apiPost({
    path: `/jobs/${jobId}/bulk_channel_messages`,
    variables: {
      hiringStageId,
      body,
      includedJobApplicationIds,
      excludedJobApplicationIds,
    },
  });
};

export function useCreateBulkMessage() {
  const queryClient = useQueryClient();
  return useMutation(createBulkMessage, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // onSuccess: (data, variables) => {
    //   queryClient.setQueryData(["jobCategory", { id: variables.id }], data);
    // },
    // Error
    // onError: (data, variables) => {
    //   window.logger(
    //     "%c[useBulkMessage] handle error when creating Job Category",
    //     "background-color: #19F6D2",
    //     {
    //       data,
    //       variables,
    //     },
    //   );
    // },
    // After success or failure, refetch the jobCategories query
    onSettled: () => {
      queryClient.invalidateQueries("bulkMessage");
    },
    // throwOnError: true,
  });
}

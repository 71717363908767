import React from "react"; // eslint-disable-line
import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { useTheme } from "@emotion/react";
import { useTransition, animated } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import ModalBackdrop from "@ats/src/components/modals/ModalBackdrop";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  headerTitleText: string;
  onCancel: (event?: any) => void;
  children: any;
  isVisible?: boolean;
  hasUnsavedChanges?: boolean;
};

export default function CenterModal(props: Props) {
  // const [isVisible, setIsVisible] = React.useState(true);
  const { headerTitleText, children, onCancel, hasUnsavedChanges } = props;
  const { animationCompleteCallback, isVisible } = useModalContext();

  // const theme = useTheme();

  // window.logger("%c[CenterModal] RENDER", "background-color: #c45af5", { ...props });

  const checkUnsavedChanges = React.useCallback(() => {
    if (!hasUnsavedChanges) {
      onCancel();
    }
  }, [hasUnsavedChanges]);

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "esc",
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      checkUnsavedChanges();
      return false;
    },
    {},
    [hasUnsavedChanges],
  );

  const handleBackgroundClick = (event) => {
    event.stopPropagation();
    checkUnsavedChanges();
  };

  const handleAnimationDone = () => {
    // window.logger(
    //   "%c[CenterModal] handleAnimationDone",
    //   "background-color: #afcdeb; color: #a10b0b",
    //   {
    //     animationCompleteCallback,
    //   },
    // );
    if (animationCompleteCallback != undefined) {
      animationCompleteCallback();
    }
  };

  const transitions = useTransition(isVisible, {
    from: {
      opacity: 0,
      transform: "scale(1.05)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
    },
    leave: {
      opacity: 0,
      transform: "scale(1.05),",
    },
    config: {
      tension: 480,
      clamp: true,
    },
    onDestroyed: handleAnimationDone,
  });

  // window.logger("%c[CenterModal] RENDER", "background-color: #93bce5; color: red", {
  //   transitions,
  //   isVisible,
  // });

  return (
    <Styled.Container>
      <ModalBackdrop whenClicked={handleBackgroundClick} isVisible={isVisible} />

      {transitions(
        (props, item) =>
          item && (
            <Styled.Content style={props}>
              <Styled.Title>{headerTitleText}</Styled.Title>
              {children}
            </Styled.Content>
          ),
      )}
    </Styled.Container>
  );
}

CenterModal.defaultProps = {
  isVisible: true,
  hasUnsavedChanges: false,
};

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: CenterModal;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

Styled.Content = styled(animated.div)((props) => {
  const t: any = props.theme;
  return css`
    label: CenterModal_Content;
    ${[t.p(4)]}
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    width: 32rem;
    z-index: 20;
    max-height: 100%;
    overflow-y: auto;

    ${t.mq["md"]} {
      ${[t.rounded.md, t.p(6)]}
      overflow-y: visible;
      max-height: none;
    }

    > p {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};

      a {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
      }
    }
  `;
});

Styled.Title = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CenterModal_Title;
    ${[t.text.h2, t.mb(2), t.mt("-px")]}
    line-height: 1.4;
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useChannel } from "@shared/queryHooks/useChannel";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import ChannelMessageListItem from "@ats/src/views/jobApplications/channelMessages/ChannelMessageListItem";
import EmptyState from "@ats/src/components/shared/EmptyState";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useAddHiringTeamMembership } from "@shared/queryHooks/useHiringTeamMembership";

function ChannelMessageList(props: {
  channel: any;
  candidate: any;
  scrollToBottom: () => void;
  jobId: number;
  visibility: string;
}) {
  const { channel, candidate, scrollToBottom, jobId, visibility } = props;
  const { data, isFetching: isFetchingAllChannel, isSuccess, isLoading } = useChannel(channel);
  const { mutate: addTeamMember, isLoading: isLoadingAdd } = useAddHiringTeamMembership();

  const job = useJobStore(React.useCallback((state) => state.jobsById[jobId], [jobId])) as any;
  const { currentUser, currentOrganizationUser } = useCurrentSession();

  const currentUserNotOnHiringTeam =
    job.organizationUserIds.includes(currentOrganizationUser.id) === false;

  window.logger("[ChannelMessageList] render", {
    isFetchingAllChannel,
    isSuccess,
    isLoading,
    data,
  });

  scrollToBottom();

  // window.logger("%c[ChannelMessageList] render", "background-color: #19FFD2;", {
  //   data,
  //   isFetchingAllChannel,
  //   isLoading,
  //   isSuccess,
  //   job,
  //   currentUser,
  //   currentUserNotOnHiringTeam,
  // });

  React.useEffect(() => {
    scrollToBottom();
  }, [data, isSuccess, isLoading]); // left out scrollToBottom on purpose

  const adminJoinTeam = (event) => {
    event.preventDefault();

    addTeamMember(
      { jobId: job.id, organizationUserId: currentUser.currentOrganizationUserId },
      {
        onSuccess: (data) => {
          window.logger("%c[ChannelMessageList] addTeamMember SELF", "color: #1976D2", { data });
        },
      },
    );
  };

  if (isLoading) {
    return (
      <>
        <LoadingIndicator label="Loading..." />
      </>
    );
  }

  const messages =
    data && data.channelMessages != undefined
      ? data.channelMessages.map((message, index) => {
        // scrollToBottom();
        return <ChannelMessageListItem key={message.id} message={message} />;
      })
      : [];

  if (!data && visibility === "private" && !currentOrganizationUser.isAdmin) {
    return (
      <Styled.EmptyState>
        <EmptyState
          icon="message-square"
          title="This candidate message feed has been hidden"
          message="Access to view these messages is currently limited to admins within your organization"
        />
      </Styled.EmptyState>
    );
  }

  return (
    <>
      {data && data.channelMessages.length > 0 ? (
        <>
          <Styled.Container>
            <Styled.MessageHistory>
              <Styled.Icon>
                <Icon name="flag" />
              </Styled.Icon>
              This is the beginning of your message history with this candidate
            </Styled.MessageHistory>
            {messages}
          </Styled.Container>
          {currentUserNotOnHiringTeam && (
            <Styled.Warning>
              <Styled.WarningTitle>
                <Icon name="bell-off" />
                Join this job's hiring team to receive new message notifications.
              </Styled.WarningTitle>
              <Button styleType="text" onClick={adminJoinTeam}>
                Join team
              </Button>
            </Styled.Warning>
          )}
        </>
      ) : (
        <Styled.EmptyState>
          {candidate && candidate.email ? (
            <EmptyState
              icon="message-square"
              title="No messages have been sent to this candidate"
              message="Start the conversation by composing a message below to be sent as an email"
            />
          ) : (
            <EmptyState
              icon="message-square"
              title="This candidate does not have an email address"
              message="Add an email address to enable messaging"
            />
          )}
        </Styled.EmptyState>
      )}
    </>
  );
}

ChannelMessageList.propTypes = {};

ChannelMessageList.defaultProps = {};

export default ChannelMessageList;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageList;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
  `;
});

Styled.MessageHistory = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageList_MessageHistory;
    ${[t.ml(5), t.my(4), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
});

Styled.Icon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Icon;
    ${[t.ml(3), t.mr(2), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
  `;
});

Styled.EmptyState = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageList_EmptyState;
    ${[t.mx(4), t.mt("px")]}
  `;
});

Styled.Warning = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageList_Warning;
    ${[t.px(4), t.rounded.md, t.mb(4), t.mx(4)]}
    min-height: ${t.spacing[12]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
});

Styled.WarningTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageList_WarningTitle;
    ${[t.my(2), t.mr(4)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    line-height: 1.4;

    svg {
      ${[t.mr(4)]};
      flex-shrink: 0;
      color: ${t.dark ? t.color.red[500] : t.color.red[600]};
    }
  `;
});

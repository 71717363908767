import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPut, apiGet, apiDelete } from "@shared/queryHooks/api";
import { UserSettings } from "@shared/types/user";
import { CurrentUser } from "@shared/types/currentUser";

/* API
--===================================================-- */
const getMe = async () => {
  return await apiGet({ path: `/me` });
};

const getMyInvites = async () => {
  return await apiGet({ path: `/me/invites` });
};

const resendEmailConfirmation = async () => {
  return await apiPut({ path: `/me/resend_email_confirmation`, variables: {} });
};

const rejectInvite = async ({ inviteId }) => {
  return await apiPut({ path: `/me/reject_invite`, variables: { inviteId } });
};

const acceptInvite = async ({ inviteToken }) => {
  return await apiPut({ path: `/me/accept_invite`, variables: { inviteToken } });
};

const updateMe = async (variables) => {
  return await apiPut({ path: `/me`, variables });
};

const updateSettings = async (settings: UserSettings) => {
  const variables = { settings };
  return await apiPut({ path: `/me/update_settings`, variables });
};

const chooseOrganizationUser = async ({ organization_user_id }) => {
  return await apiPut({
    path: `/me/choose_organization_user`,
    variables: { organization_user_id },
  });
};

const deleteAvatar = async () => {
  return await apiPut({
    path: `/me/remove_avatar`,
    variables: {},
  });
};

const deleteMe = async () => {
  return await apiDelete({ path: `/me/delete_account`, variables: {} });
};

/* Hooks
--===================================================-- */
export function useGetMe({
  enabled = true,
  refetchOnWindowFocus = false,
}: {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
}): {
  status: any;
  data: CurrentUser;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
} {
  // window.logger("%c[useMe] useGetMe", "color: #1976D2", { enabled, refetchOnWindowFocus });
  return useQuery("me", getMe, {
    enabled,
    refetchOnWindowFocus,
    retry: (failureCount, error) => !error.message.includes(401),
    onSettled: () => {},
    onError: (error) => {
      const search = new URLSearchParams(window.location.search);
      const isAcceptingAnInvite = search?.has("invite_token");
      // If we are not accepting an invite then redirect to /logout
      if (!isAcceptingAnInvite) {
        window.logger("%c[useGetMe] ERROR - Redirecting to /logout", "color: #FF0000", {
          // error,
          pathname: window.location.pathname,
        });
        const noredirectPaths = ["/", "/jobs", "/interviewer"];
        const queryParams = !noredirectPaths.includes(window.location.pathname)
          ? `?path=${window.location.pathname}`
          : "";
        window.location.href = `${window.APP_ATS_ROOT_URL}/logout${queryParams}`;
      }
    },
  });
}

export function useMyInvites(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
} {
  return useQuery("meInvites", getMyInvites, {
    retry: (failureCount, error) => !error.message.includes(401),
    onSettled: () => {},
    onError: (error) => {
      // window.location.href = `${window.APP_ATS_ROOT_URL}/logout`;
    },
  });
}

export function useResendEmailConfirmation() {
  const queryClient = useQueryClient();
  return useMutation(resendEmailConfirmation, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useRejectInvite() {
  const queryClient = useQueryClient();
  return useMutation(rejectInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("meInvites");
    },
    // throwOnError: true,
  });
}

export function useAcceptInvite() {
  const queryClient = useQueryClient();
  return useMutation(acceptInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("meInvites");
    },
    // throwOnError: true,
  });
}

export function useUpdateMe() {
  const queryClient = useQueryClient();
  return useMutation(updateMe, {
    onSuccess: (data) => {
      queryClient.setQueryData("me", data);
      // queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useUpdateSettings() {
  const queryClient = useQueryClient();
  return useMutation(updateSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useUploadAvatar() {
  const queryClient = useQueryClient();
  return useMutation(updateMe, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useDeleteAvatar() {
  const queryClient = useQueryClient();
  return useMutation(deleteAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useChooseOrganizationUser() {
  const queryClient = useQueryClient();
  return useMutation(chooseOrganizationUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useDeleteMe() {
  return useMutation(deleteMe, {
    onSuccess: (data) => {
      window.logger("%c[useDeleteMe] SUCCESS deleting user account");
    },
    // throwOnError: true,
  });
}

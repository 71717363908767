import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function OrgLogo({
  name,
  logoMedium,
  hasLogo,
}: {
  name: string;
  logoMedium: string;
  hasLogo: boolean;
}) {
  return (
    <Styled.AvatarWrapper>
      {hasLogo ? (
        <Styled.ImageAvatar>
          <img src={logoMedium} />
        </Styled.ImageAvatar>
      ) : (
        <Styled.Avatar>{name?.charAt(0)}</Styled.Avatar>
      )}
    </Styled.AvatarWrapper>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgLogo;
    display: flex;
    align-items: center;
    max-width: 100%;
  `;
});

Styled.AvatarWrapper = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgLogo_AvatarWrapper;
    ${[t.h(10), t.w(10), t.rounded.sm]}
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
});

Styled.ImageAvatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgLogo_ImageAvatar;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    transition: opacity 0.2s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
});

Styled.Avatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgLogo_Avatar;
    ${[t.pt("px"), t.text.lg, t.text.bold]}
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${t.color.white};
    background-color: ${t.color.gray[600]};
    text-transform: uppercase;
    flex-shrink: 0;
    transition: opacity 0.2s ease;
  `;
});

Styled.Name = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgLogo_Name;
    ${[t.text.bold, t.text.lg, t.pt("px"), t.ml(3)]}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
});

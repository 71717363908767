import create, { SetState, GetState } from "zustand";
import produce from "immer";

type OrganizationStore = {
  organization: {};
  users: [];
  organizationUsers: [];
  usersById: {};
  organizationUsersById: {};
  set: (fn: any) => any;
};

const useOrganizationStore = create<OrganizationStore>(
  (set: SetState<OrganizationStore>, get: GetState<OrganizationStore>) => ({
    organization: {},
    users: [],
    organizationUsers: [],
    usersById: {},
    organizationUsersById: {},
    set: (fn) => {
      set(produce(fn));
    },
  }),
);

// const refetchJob = (jobId) => {
//  queryCache.refetchQueries(["jobs", data.jobId], { exact: true });
// }

export { useOrganizationStore };

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import Tooltip from "@ats/src/components/shared/Tooltip";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Icon from "@ats/src/components/shared/Icon";

import Styled from "@ats/src/views/sessions/components/Styled";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import EmptyState from "@ats/src/components/shared/EmptyState";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { useChooseOrganizationUser, useMyInvites, useRejectInvite } from "@shared/queryHooks/useMe";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useQueryClient } from "react-query";

function OrganizationSwitcher(props) {
  const queryClient = useQueryClient();
  const { history, isLoadingOrganization } = props;
  const addToast = useToastContext();
  const [showRejectedInvites, setShowRejectedInvites] = useState(false);

  const { currentUser, currentOrganization } = useCurrentSession();
  const { mutate: rejectInvite, isLoading: isLoadingRejectInvite } = useRejectInvite();

  const { data: invitesData } = useMyInvites();
  const pendingInvites =
    (invitesData && invitesData.items.filter((invite) => invite.status === "status_pending")) || [];
  const rejectedInvites =
    (invitesData && invitesData.items.filter((invite) => invite.status === "status_rejected")) ||
    [];

  const activeOrganizationUsers = currentUser.organizationUsers.filter(
    (organizationUser) => organizationUser.isActive,
  );

  window.logger(
    "%c[OrganizationSwitcher] render",
    "background: #EFDDEF; background-color: #1976D2",
    {
      currentUser,
      currentOrganization,
      invitesData,
      activeOrganizationUsers,
    },
  );

  /* Queries
  --===================================================-- */
  const {
    mutate: chooseOrganizationUser,
    isLoading: isLoadingChooseOrganizationUser,
  } = useChooseOrganizationUser();

  const handleOrganizationUserChoice = (organizationUser) => {
    chooseOrganizationUser(
      { organization_user_id: organizationUser.id },
      {
        onSuccess: (organizationUser) => {
          window.logger("%c[OrganizationSwitcher] handleOrganizationUserChoice", "color: #19d228", {
            organizationUser,
          });
          queryClient.clear();
          history.push("/jobs");
        },
      },
    );
  };

  const handleDeclineInvitation = (inviteId) => {
    rejectInvite({
      inviteId,
    });
  };

  if (isLoadingChooseOrganizationUser) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <Helmet title="Organization Switcher" />
      <Styled.Container>
        <HeaderBar />
        <Styled.UI>
          {isLoadingOrganization ? (
            <LoadingIndicator label="Logging in to organization..." />
          ) : (
            <>
              <Styled.List>
                <Styled.Header>
                  <h2>Your organizations</h2>
                  <Tooltip label="Create new organization">
                    <Styled.Link to="/organization/new">
                      <Icon name="plus" />
                    </Styled.Link>
                  </Tooltip>
                </Styled.Header>
                <p>
                  You can join other existing organizations by receiving an invitation from them.
                </p>
                {/* <div>
                  <p>Active Organization: {currentOrganization.name}</p>
                </div> */}

                {activeOrganizationUsers.length > 0 ? (
                  <>
                    <h3>Memberships</h3>
                    <ul>
                      {activeOrganizationUsers.map((organizationUser) => (
                        <Styled.OrgListItem key={organizationUser.id}>
                          <Styled.Org>
                            {organizationUser.organization.hasLogo ? (
                              <Styled.ImageAvatar>
                                <img src={organizationUser.organization.logoMedium} />
                              </Styled.ImageAvatar>
                            ) : (
                              <Styled.Avatar>
                                {organizationUser.organization.name.charAt(0)}
                              </Styled.Avatar>
                            )}
                            <Styled.OrgInfo>
                              <h4>{organizationUser.organization.name}</h4>
                              <span>
                                {organizationUser.organization.usersCount} member
                                {organizationUser.organization.usersCount === 1 ? "" : "s"}
                              </span>
                            </Styled.OrgInfo>
                          </Styled.Org>
                          <Button
                            styleType="secondary"
                            onClick={() => handleOrganizationUserChoice(organizationUser)}
                          >
                            View
                          </Button>
                        </Styled.OrgListItem>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Styled.EmptyState>
                    <EmptyState
                      roomy
                      icon="maximize"
                      title="No organizations to show"
                      message="You are not currently an active member in any organizations on Polymer."
                    />
                  </Styled.EmptyState>
                )}

                {pendingInvites.length > 0 || showRejectedInvites ? (
                  <>
                    <h3>Invitations</h3>
                    <ul>
                      {console.log(invitesData)}
                      {pendingInvites.map((invite) => (
                        <Styled.OrgListItem key={invite.id}>
                          <Styled.Org>
                            {invite.organization.hasLogo ? (
                              <Styled.ImageAvatar>
                                <img src={invite.organization.logoMedium} />
                              </Styled.ImageAvatar>
                            ) : (
                              <Styled.Avatar>{invite.organization.name.charAt(0)}</Styled.Avatar>
                            )}
                            <Styled.OrgInfo>
                              <h4>{invite.organization.name}</h4>
                              <span>
                                {invite.organization.usersCount} member
                                {invite.organization.usersCount === 1 ? "" : "s"}
                              </span>
                            </Styled.OrgInfo>
                          </Styled.Org>

                          <Styled.InviteActions>
                            <DropdownMenu>
                              <Button
                                styleType="text"
                                onClick={() => handleDeclineInvitation(invite.id)}
                                disabled={isLoadingRejectInvite}
                              >
                                Dismiss invitation
                              </Button>
                            </DropdownMenu>
                            <Button
                              styleType="secondary"
                              type="externalLink"
                              // onClick={() => handleOrganizationUserChoice(organizationUser)}
                              target="_self"
                              href={invite.inviteUrl}
                            >
                              Join
                            </Button>
                          </Styled.InviteActions>
                        </Styled.OrgListItem>
                      ))}

                      {rejectedInvites.length > 0 && showRejectedInvites
                        ? rejectedInvites.map((invite) => (
                          <Styled.OrgListItem key={invite.id}>
                            <Styled.Org>
                              {invite.organization.hasLogo ? (
                                <Styled.ImageAvatar>
                                  <img src={invite.organization.logoMedium} />
                                </Styled.ImageAvatar>
                              ) : (
                                <Styled.Avatar>
                                  {invite.organization.name.charAt(0)}
                                </Styled.Avatar>
                              )}
                              <Styled.OrgInfo>
                                <Styled.DismissedWrapper>
                                  <h4>{invite.organization.name}</h4>
                                  <Styled.Label>Dismissed</Styled.Label>
                                </Styled.DismissedWrapper>
                                <span>
                                  {invite.organization.usersCount} member
                                  {invite.organization.usersCount === 1 ? "" : "s"}
                                </span>
                              </Styled.OrgInfo>
                            </Styled.Org>

                            <DropdownMenu label="Options">
                              <Button
                                styleType="secondary"
                                type="externalLink"
                                target="_self"
                                href={invite.inviteUrl}
                              >
                                Join
                              </Button>
                            </DropdownMenu>
                          </Styled.OrgListItem>
                        ))
                        : null}
                    </ul>
                  </>
                ) : null}
              </Styled.List>

              <Styled.Links>
                <Link to="/logout">Log out</Link>
                {rejectedInvites.length > 0 ? (
                  showRejectedInvites ? (
                    <button onClick={() => setShowRejectedInvites(false)}>Hide dismissed</button>
                  ) : (
                    <button onClick={() => setShowRejectedInvites(true)}>Show all</button>
                  )
                ) : null}
              </Styled.Links>
            </>
          )}
        </Styled.UI>
      </Styled.Container>
    </>
  );
}

export default OrganizationSwitcher;

/* Styled Components
======================================================= */
// let Styled: { Form: any };
// Styled = {};

Styled.ImageAvatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrganizationSwitcher_ImageAvatar;
    ${[t.h(12), t.w(12), t.rounded.sm]}
    overflow: hidden;
    flex-shrink: 0;
    transition: opacity 0.2s ease;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
});

Styled.Avatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrganizationSwitcher_Avatar;
    ${[t.h(12), t.w(12), t.rounded.sm, t.pt("px"), t.text.lg, t.text.bold]}
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${t.color.white};
    background-color: ${t.color.gray[600]};
    text-transform: uppercase;
    flex-shrink: 0;
    transition: opacity 0.2s ease;
  `;
});

Styled.OrgListItem = styled.li((props: any) => {
  const t = props.theme;
  return css`
    ${[t.mb(6)]}
    label: OrganizationSwitcher_OrgListItem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    button {
      width: auto;
    }
  `;
});

Styled.Org = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrganizationSwitcher_Org;
    display: flex;
    align-items: center;
    overflow: hidden;
  `;
});

Styled.OrgInfo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OrganizationSwitcher_OrgInfo;
    ${t.ml(3)};
    overflow: hidden;

    h4 {
      ${[t.mb(1), t.mr(2), t.text.bold]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      ${[t.text.xs]}
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.List = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OrganizationSwitcher_List;
    ${[t.px(4), t.pt(6)]};
    width: 100%;
    border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};

    h2 {
      ${[t.text.h2, t.mt("-px")]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    h3 {
      ${[t.text.lg, t.text.bold, t.mt(6), t.mb(5)]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    > p {
      ${[t.py(1), t.mb(6)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    ul {
      ${[t.mb(8)]};
    }

    ${t.mq["lg"]} {
      ${[t.p(6), t.pb(0), t.rounded.md]}
      border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      width: 25rem;
    }
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session_Form;
    ${t.mb(1)}
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
});

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    ${[t.h(8), t.w(8), t.rounded.sm]}
    label: OrganizationSwitcher_Link;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;

    &:visited,
    &:link {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    &:hover {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    }
  `;
});

Styled.InviteActions = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrganizationSwitcher_InviteActions;
    display: flex;
    align-items: center;

    > div:first-of-type {
      ${t.mr(3)}
    }
  `;
});

Styled.DismissedWrapper = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: OrganizationSwitcher_DismissedWrapper;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OrganizationSwitcher_Label;
    ${[t.mb(1), t.mr(2), t.h(4), t.px(1), t.rounded.xs, t.text.normal]}
    font-size: 0.625rem;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1;
  `;
});

Styled.EmptyState = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OrganizationSwitcher_EmptyState;
    ${[t.mb(8)]}
  `;
});

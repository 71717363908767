import styled from "@emotion/styled";

interface Text {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl";
  weight?: "normal" | "medium" | "semibold" | "bold";
  italic?: boolean;
  align?: "start" | "end" | "center" | "justify";
}

const variants = {
  size: {
    xs: `
      font-size: 12px;
      line-height: 16px;
    `,
    sm: `
      font-size: 14px;
      line-height: 20px;
    `,
    md: `
      font-size: 16px;
      line-height: 24px;
    `,
    lg: `
      font-size: 18px;
      line-height: 28px;
    `,
    xl: `
      font-size: 20px;
      line-height: 28px;
    `,
    '2xl': `
      font-size: 24px;
      line-height: 32px;
    `,
    '3xl': `
      font-size: 30px;
      line-height: 36px;
    `,
    '4xl': `
      font-size: 36px;
      line-height: 40px;
    `,
    '5xl': `
      font-size: 48px;
    `,
    '6xl': `
      font-size: 60px;
    `,
    '7xl': `
      font-size: 72px;
    `,
    '8xl': `
      font-size: 96px;
    `,
    '9xl': `
      font-size: 128;
    `,
  },
  weight: {
    normal: `
      font-weight: 400;
    `,
    medium: `
      font-weight: 450;
    `,
    semibold: `
      font-weight: 500;
    `,
    bold: `
      font-weight: 600;
    `,
  },
  italic: `
    font-style: italic;
  `,
  align: {
    start: `
      text-align: start;
    `,
    end: `
      text-align: end;
    `,
    center: `
      text-align: center;
    `,
    justify: `
      text-align: justify;
    `,
  }
};

const Text = styled.span<Text>`
  line-height: 1;
  margin: 0;
  display: block;
  color: inherit;
  ${({ size }) => variants.size[size]};
  ${({ weight }) => variants.weight[weight]};
  ${({ italic }) => italic && variants.italic};
  ${({ align }) => variants.align[align]};
`;

export default Text;
/* HOW TO USE

useAuthorization
-----------------
useAuthorization is a custom hook that returns a boolean indicating if the currentUser is authorized based
on a minimum role "minimumRole" or by using the helper prop "adminOnly".

const isAuthorized = useAuthorization();
const authorized = isAuthorized({adminOnly: true});
OR
const authorized = isAuthorized({minimumRole: "org_admin"});

if (authorized) {
  // Show something only to Admins (includes owners)
}


IfAuthorized
-----------------
IfAuthorized is a Component that you wrap around something you want to conditionally show or not
based on a minimum user "role" or using the helper prop "adminOnly" which is the most common use case.

<IfAuthorized adminOnly={true}>Thing you only want Admins to see</IfAuthorized>

OR

<IfAuthorized minimumRole="org_owner">Thing you only want Owners to see</IfAuthorized>

--===================================================-- */

import React from "react";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function isValidRole(currentOrganizationUser, minimumRole) {
  const roles = {
    org_interviewer: 0,
    org_user: 1,
    org_admin: 2,
    org_owner: 3,
    god_admin: 99,
  };

  const userRoleValue = roles[currentOrganizationUser.role];
  const authorizedRoleValue = roles[minimumRole];

  return userRoleValue >= authorizedRoleValue || currentOrganizationUser.role === "god_admin";
}

function IfAuthorized({ children, minimumRole = "org_owner", adminOnly = undefined }) {
  const { currentUser, currentOrganization } = useCurrentSession();

  const authorized =
    adminOnly != undefined && adminOnly
      ? currentUser.currentOrganizationUser.isAdmin
      : isValidRole(currentUser.currentOrganizationUser, minimumRole);

  if (!authorized) {
    return null;
  }

  return <>{children}</>;
}

IfAuthorized.propTypes = {};

IfAuthorized.defaultProps = {};

function useAuthorization() {
  const { currentUser, currentOrganization } = useCurrentSession();
  return ({ minimumRole = "org_admin", adminOnly = undefined }) => {
    return adminOnly != undefined && adminOnly
      ? currentUser.currentOrganizationUser.isAdmin
      : isValidRole(currentUser.currentOrganizationUser, minimumRole);
  };
}

export { IfAuthorized, useAuthorization };

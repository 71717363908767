import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import Icon from "@ats/src/components/shared/Icon";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Button from "@ats/src/components/shared/Button";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";
import CopyField from "@ats/src/components/shared/CopyField";

import { useCareersPageCustomDomainStatus } from "@shared/queryHooks/useCareersPage";
import { useQueryClient } from "react-query";
import { useToastContext } from "@shared/context/ToastContext";

enum CloudflareStatuses {
  INITIALIZING = "initializing",
  PENDING = "pending",
  ACTIVE = "active",
}

const MAX_CLOUDFLARE_REFETCH_TRIES = 20;

function CustomDomainStatus({ careersPage }) {
  const addToast = useToastContext();
  // const [copied, setCopied] = React.useState(false);
  const queryClient = useQueryClient();
  const {
    data: customDomainInfo,
    isLoading: isLoadingCareersPageHerokuDomainInfo,
    isFetching: isFetchingCareersPageHerokuDomainInfo,
    isError: isErrorCareersPageHerokuDomainInfo,
    error: careersPageHerokuDomainInfoError,
    refetch: refetchCustomDomainInfo,
  } = useCareersPageCustomDomainStatus(careersPage?.id);

  const [cloudflareRefetchCount, setCloudflareRefetchCount] = useState(0);

  const careersPageHerokuDomainInfoData = customDomainInfo?.herokuInfo;
  const cloudflareDomainInfo = customDomainInfo?.cloudflareInfo;

  // this forces a refetch of the cloudflareInfo until the status is past initializing
  useEffect(() => {
    if (cloudflareRefetchCount >= MAX_CLOUDFLARE_REFETCH_TRIES) return;
    if (isFetchingCareersPageHerokuDomainInfo || isLoadingCareersPageHerokuDomainInfo) return;
    if (
      customDomainInfo?.cloudflareInfo &&
      customDomainInfo?.cloudflareInfo?.ssl?.status === CloudflareStatuses.INITIALIZING
    ) {
      setCloudflareRefetchCount((prev) => prev + 1);
      refetchCustomDomainInfo();
    }
  }, [
    customDomainInfo,
    cloudflareRefetchCount,
    isFetchingCareersPageHerokuDomainInfo,
    isLoadingCareersPageHerokuDomainInfo,
  ]);

  window.logger("%c[CustomDomainStatus] RENDER", "background-color: #e0d038", {
    careersPageHerokuDomainInfoData,
    isErrorCareersPageHerokuDomainInfo,
    careersPageHerokuDomainInfoError,
    isLoadingCareersPageHerokuDomainInfo,
    isFetchingCareersPageHerokuDomainInfo,
  });

  const handleClickOnRefreshDomain = (event) => {
    event.preventDefault();

    queryClient.refetchQueries("careersPageCustomDomainStatus").then(() => {
      addToast({ title: "Refresh successful", kind: "success" });
      queryClient.refetchQueries("currentOrganization");
    });
  };

  if (
    isLoadingCareersPageHerokuDomainInfo ||
    (customDomainInfo?.cloudflareInfo?.ssl?.status === CloudflareStatuses.INITIALIZING &&
      cloudflareRefetchCount >= MAX_CLOUDFLARE_REFETCH_TRIES)
  ) {
    return <LoadingIndicator subtle />;
  }

  if (isErrorCareersPageHerokuDomainInfo && !isEmpty(careersPage.customDomain)) {
    return (
      <FormConditionalFields>
        <Styled.Value>
          <span>Unable to load domain, please try refreshing.</span>
        </Styled.Value>
        <Button
          styleType="secondary"
          onClick={handleClickOnRefreshDomain}
          loading={isFetchingCareersPageHerokuDomainInfo}
        >
          Refresh
        </Button>
      </FormConditionalFields>
    );
  }

  if (
    careersPageHerokuDomainInfoData?.status == undefined &&
    careersPageHerokuDomainInfoData?.acmStatus == undefined
  ) {
    return null;
  }

  // user setup domain before cloudflare custom domain was setup
  if (!cloudflareDomainInfo) {
    return (
      <>
        <FormLabel label="CNAME" />
        <Styled.Description>
          Supply this to your DNS provider for the destination of CNAME or ALIAS records.
        </Styled.Description>
        <CopyField value={careersPageHerokuDomainInfoData.cname} label="Copy" />

        <FormConditionalFields>
          {/* status: failing, pending, succeeded */}
          {/* acmStatus: failing, pending,
        dns-verified, cert issued <--- These 2 don't have an acmStatusReason, "cert issued" is when we know it should be working
        */}

          {["pending", "failing"].includes(careersPageHerokuDomainInfoData.status) ? (
            <>
              <FormLabel label="Custom domain status" />
              <Styled.Value>
                <span>{careersPageHerokuDomainInfoData.status}</span>
              </Styled.Value>
            </>
          ) : careersPageHerokuDomainInfoData.acmStatus == undefined ? (
            <>
              <FormLabel label="Custom domain status" />
              <Styled.Value>
                <span>
                  Checking your domain's DNS records, refresh once you have updated them with the
                  above CNAME destination.
                </span>
              </Styled.Value>
            </>
          ) : null}

          {careersPageHerokuDomainInfoData.acmStatus != undefined ? (
            <>
              <FormLabel label="SSL certificate status" />
              <Styled.Value>
                {careersPageHerokuDomainInfoData.acmStatus === "cert issued" && (
                  <Icon name="check-circle" />
                )}
                <span>{careersPageHerokuDomainInfoData.acmStatus}</span>
              </Styled.Value>

              {careersPageHerokuDomainInfoData.acmStatusReason && (
                <>
                  <FormLabel label="Certificate status reason" />
                  <Styled.Value>
                    <span>{careersPageHerokuDomainInfoData.acmStatusReason}</span>
                  </Styled.Value>
                </>
              )}
            </>
          ) : null}

          <Button
            styleType="secondary"
            onClick={handleClickOnRefreshDomain}
            loading={isFetchingCareersPageHerokuDomainInfo}
          >
            Refresh
          </Button>
        </FormConditionalFields>
      </>
    );
  }

  const invalidCloudflareId =
    cloudflareDomainInfo.errors &&
    cloudflareDomainInfo.errors.some((error) => error.message.includes("hostname ID is invalid"));

  if (invalidCloudflareId) {
    return (
      <div>
        The custom domain was not validated within 30 days. Please delete your custom domain below,
        and try again.
      </div>
    );
  } else if (cloudflareDomainInfo.errors) {
    return (
      <>
        {cloudflareDomainInfo.errors.map((error) => (
          <div key={error.code}>{error.message}</div>
        ))}
      </>
    );
  }

  // User has cloudflare custom domain

  return (
    <>
      <FormLabel label="Step 1" />
      {cloudflareDomainInfo.status !== CloudflareStatuses.ACTIVE && (
        <>
          <FormLabel label="CNAME" />
          <Styled.Description>
            Supply this to your DNS provider for the destination of CNAME or ALIAS records.
          </Styled.Description>
          <CopyField value={window.CUSTOM_DOMAIN_CNAME_TARGET} label="Copy" />
        </>
      )}

      <FormConditionalFields>
        <>
          <FormLabel label="CNAME record status" />
          <Styled.Value>
            {cloudflareDomainInfo.status === CloudflareStatuses.ACTIVE && (
              <Icon name="check-circle" />
            )}
            <span>{startCase(cloudflareDomainInfo.status)}</span>
          </Styled.Value>
          {cloudflareDomainInfo.verificationErrors?.length > 0 &&
            cloudflareDomainInfo.status !== CloudflareStatuses.PENDING && (
              <>
                <FormLabel label="CNAME record status reason" />
                <Styled.Value>
                  {cloudflareDomainInfo.verificationErrors?.map((error, index) => (
                    <span key={index}>{error}</span>
                  ))}
                </Styled.Value>
              </>
            )}
        </>
      </FormConditionalFields>

      <FormLabel label="Step 2" />
      {cloudflareDomainInfo.ssl?.validationRecords && <FormLabel label="TXT" />}
      {!cloudflareDomainInfo.ssl?.validationRecords && (
        <FormConditionalFields>
          <>
            <FormLabel label="TXT record status" />
            <Styled.Value>
              <Icon name="check-circle" />
              <span>Active</span>
            </Styled.Value>
          </>
        </FormConditionalFields>
      )}
      {cloudflareDomainInfo.ssl?.validationRecords?.map((validationRecord, index) => {
        return (
          <div key={index}>
            <Styled.Description>
              Supply this TXT record to your DNS provider for SSL certifcate validation.
            </Styled.Description>
            <CopyField value={validationRecord.txtName} label="Copy" title="Name:" />
            <CopyField value={validationRecord.txtValue} label="Copy" title="Value:" />
            <FormConditionalFields>
              <>
                <FormLabel label="TXT record status" />
                <Styled.Value>
                  <span>{startCase(validationRecord.status)}</span>
                </Styled.Value>
              </>
            </FormConditionalFields>
          </div>
        );
      })}

      <FormLabel label="Step 3" />
      {cloudflareDomainInfo.ssl?.status !== CloudflareStatuses.ACTIVE && (
        <>
          <FormLabel label="SSL certificate" />
          <Styled.Value>
            This step will start automatically once step 1 and 2 are complete.
          </Styled.Value>
        </>
      )}

      <FormConditionalFields>
        <>
          <FormLabel label="SSL certificate status" />
          <Styled.Value>
            {cloudflareDomainInfo.ssl?.status === CloudflareStatuses.ACTIVE && (
              <Icon name="check-circle" />
            )}
            <span>{startCase(cloudflareDomainInfo.ssl?.status)}</span>
          </Styled.Value>
          {cloudflareDomainInfo.ssl?.status === CloudflareStatuses.INITIALIZING && (
            <Styled.Value>Refresh until status is pending.</Styled.Value>
          )}
        </>
      </FormConditionalFields>

      {(cloudflareDomainInfo.status !== CloudflareStatuses.ACTIVE ||
        cloudflareDomainInfo.ssl?.status !== CloudflareStatuses.ACTIVE) && (
        <Button
          styleType="secondary"
          onClick={handleClickOnRefreshDomain}
          loading={isFetchingCareersPageHerokuDomainInfo}
        >
          Refresh
        </Button>
      )}
    </>
  );
}

CustomDomainStatus.defaultProps = {};

export default CustomDomainStatus;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Value = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CustomDomainStatus_Value;
    ${[t.mb(5)]}
    line-height: 1.6;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[300] : t.color.black};

    svg {
      ${t.mr(2)}
    }

    span:first-letter {
      text-transform: capitalize;
    }
  `;
});

Styled.Description = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: CustomDomainStatus_Description;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    max-width: 32rem;
  `;
});

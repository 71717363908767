import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";
/*
--===================================================-- */

export default function TeamMemberInviteListItem(props) {
  const { destroyHiringTeamInvite, email, hiringTeamInviteId } = props;
  const authorized = useAuthorization();

  const handleDestroyHiringTeamInvite = (e) => {
    e.preventDefault();
    destroyHiringTeamInvite(hiringTeamInviteId);
  };

  return (
    <Styled.Container>
      <Styled.MemberInfo>
        <h3>{email}</h3>
        <span>Organization invite sent—not yet accepted</span>
      </Styled.MemberInfo>
      {authorized({ minimumRole: "org_admin" }) ? (
        <DropdownMenu label="Options">
          <a href="" onClick={handleDestroyHiringTeamInvite}>
            Remove from team
          </a>
        </DropdownMenu>
      ) : null}
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberInviteListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  `;
});

Styled.MemberInfo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberInviteListItem_MemberInfo;

    h3 {
      ${t.mb(1)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    span {
      ${[t.text.xs]}
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

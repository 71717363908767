/* eslint-disable @getify/proper-arrows/return */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import queryString from "query-string";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Icon from "@ats/src/components/shared/Icon";

import { useStripeCustomer, useBillingPrices } from "@shared/queryHooks/useBilling";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import AccountBillingNewPlanChoices from "@ats/src/views/accountAdmin/accountBilling/AccountBillingNewPlanChoices";
import AccountBillingCurrentPlan from "./accountBilling/AccountBillingCurrentPlan";

function AccountBilling(props) {
  const { currentOrganization, currentUser } = useCurrentSession(); // TODO: use the reactQuery Organization instead of the Redux organization
  const { publishedJobsCount } = currentOrganization;

  /* Stripe Customer
  --===================================================-- */
  const { data: stripeCustomerData, isFetching: isFetchingStripeCustomer } = useStripeCustomer({
    refetchOnWindowFocus: false,
  });
  const customer = stripeCustomerData ? stripeCustomerData.customer : null;

  /* Current Organization Subscription Status
  --===================================================-- */
  const hasActiveSubscription = currentOrganization.stripeSubscriptionInGoodStanding === true;
  const hadAnActiveSubscriptionInThePast =
    currentOrganization.stripeSubscriptionInGoodStanding &&
    currentOrganization.publishedJobsCount > 0;
  const canRenewSubscription =
    hadAnActiveSubscriptionInThePast && currentOrganization.stripeDefaultPaymentMethodOnFile;
  const hasInactiveSubscription =
    currentOrganization.stripeSubscriptionStatus !== null &&
    !currentOrganization.stripeSubscriptionInGoodStanding;

  /* Billing Prices
  --===================================================-- */
  const { data: billingPricesData, isFetching: isFetchingBillingPrices } = useBillingPrices({
    refetchOnWindowFocus: false,
  });

  const billingPrices = billingPricesData != undefined ? billingPricesData.data : [];

  window.logger("%c[AccountBilling] render", "background: #EFDDEF; color: #1976D2", {
    props,
    location,
    customer,
    currentOrganization,
    canRenewSubscription,
    publishedJobsCount,
  });

  React.useEffect(() => {
    const { checkout, session_id, redirect_url } = queryString.parse(props.location.search);

    window.logger("%c[AccountBilling] useEffect ", "color: #1976D2", {
      checkout,
      session_id,
      redirect_url,
    });

    // Used when adding a payment method using Checkout flow
    if (checkout === "success" && session_id != undefined) {
      // Track Conversion Event for Hire Plan Purchase
      // GTM will send to Google Analytics and to Google Ads
      window.dataLayer.push({
        event: "hirePlanPurchase",
        transactionId: Date.now().toString(),
        transactionValue: "119",
        userData: {
          email: currentUser.email,
        },
      });
    }
  }, []);

  // const manageBillingButton = (ctaCopy, mode = "checkoutSession") => (
  //   <Button
  //     styleType="secondary"
  //     onClick={
  //       mode == "checkoutSession"
  //         ? handleCreateCheckoutSetupSession // checkoutSession
  //         : handleCreateBillingPortalSession // billingPortal
  //     }
  //   >
  //     {ctaCopy}
  //   </Button>
  // );

  const viewLink = (
    <Button type="externalLink" link="https://polymer.co/pricing" styleType="text">
      View pricing page
      <Icon name="external-link" />
    </Button>
  );

  if (isFetchingStripeCustomer || isFetchingBillingPrices) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <Helmet title="Plan & billing" />
      <SettingsContainer
        title="Plan & billing"
        description={
          "Unlock the full potential of hiring with Polymer using one of our paid plans. You can choose to pay monthly or annually."
        }
        actions={viewLink}
      >
        {/* Subscription UI
        --===================================================-- */}

        {hasActiveSubscription ? (
          <AccountBillingCurrentPlan
            currentOrganization={currentOrganization}
            hasActiveSubscription={hasActiveSubscription}
            billingPrices={billingPrices}
          />
        ) : (
          <AccountBillingNewPlanChoices
            location={props.location}
            customer={customer}
            publishedJobsCount={publishedJobsCount}
            canRenewSubscription={canRenewSubscription}
            billingPrices={billingPrices}
            hasInactiveSubscription={hasInactiveSubscription}
          />
        )}
      </SettingsContainer>
    </>
  );
}

AccountBilling.propTypes = {};

AccountBilling.defaultProps = {};

export default AccountBilling;

/* Styled Components
======================================================= */
export let Styled: any;
Styled = {};

Styled.Pricing = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_Pricing;
    ${[t.rounded.md, t.py(4), t.mt(8), t.px(4)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
});

Styled.PricingPrice = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PricingPrice;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[200] : t.color.black};

    h3 {
      ${[t.text.bold, t.text.xxxl]}
      font-size: ${t.spacing[8]}
    }

    span {
      ${[t.text.xs, t.ml(2), t.mt("-px"), t.mb("px")]}
      line-height: 1.4;
    }
  `;
});

Styled.ButtonWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_ButtonWrapper;
    display: flex;
    align-items: center;

    button {
      ${t.ml(3)}
    }
  `;
});

Styled.Subscription = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_Subscription;
    ${[t.rounded.md, t.mt(4)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
  `;
});

Styled.SubscriptionRow = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscriptionRow;
    ${[t.p(4)]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      ${[t.mb(1)]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    > div > span {
      ${[t.text.xs]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.SubscripionActions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscripionActions;
    display: flex;
  `;
});

Styled.SubscriptionMenu = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscriptionMenu;
    ${[t.pr(3)]}
  `;
});

/* eslint-disable @getify/proper-arrows/return */
import React from "react";
import isEmpty from "lodash/isEmpty";
import { Route, Redirect, NavLink, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";

// import CandidateListContainer from "@ats/src/views/jobApplications/CandidateListContainer";
import JobApplicationListContainer from "@ats/src/views/jobApplications/JobApplicationListContainer";
import JobSetupContainer from "@ats/src/views/jobApplications/jobSetup/JobSetupContainer";
import JobDistributionContainer from "@ats/src/views/jobApplications/jobDistribution/JobDistributionContainer";
import JobMetricsContainer from "@ats/src/views/jobApplications/jobMetrics/JobMetricsContainer";
import NavItem from "@ats/src/components/shared/NavItem";

import { getInfiniteJobApplications } from "@shared/queryHooks/useJobApplication";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { hiringStagesForJob } from "@ats/src/lib/utils/helpers";
import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobStagesContainer(props) {
  // useWhyDidYouUpdate(" JobStagesContainer", props);
  const { match, job } = props;
  const { currentUser, currentOrganizationUser } = useCurrentSession();
  const spring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const hiringStages = React.useMemo(() => hiringStagesForJob(job), [job]);

  // window.logger("%c[JobStagesContainer] RENDER", "background: #e6a7e6; color: #007002", {
  //   props,
  //   hiringStages,
  //   job,
  //   match,
  // });

  const queryClient = useQueryClient();
  const prefetchInfiniteJobApplicationsForStage = async (jobId, hiringStageId) => {
    await queryClient.prefetchInfiniteQuery(
      ["jobApplicationsForStage", hiringStageId],
      ({ pageParam = 1 }) =>
        getInfiniteJobApplications({
          jobId,
          hiringStageId: Number(hiringStageId),
          page: pageParam,
        }),
    );
    // The results of this query will be cached like a normal query
  };

  if (isEmpty(hiringStages) || hiringStages[0] == undefined) {
    return null;
  }

  hiringStages.forEach((hiringStage) => {
    /* PREFETCH JOB APPLICATIONS
    --===================================================-- */
    const data = queryClient.getQueryData(["jobApplicationsForStage", hiringStage.id]);

    // window.logger(
    //   "%c[JobStagesContainer] prefetchJobApplications",
    //   "background: #c239c2; color: #1976D2",
    //   {
    //     hiringStage,
    //     data,
    //   },
    // );

    if (hiringStage.kind !== "kind_archived" && data == undefined) {
      prefetchInfiniteJobApplicationsForStage(job.id, hiringStage.id);
    }
  });

  // const handleOnUpdateJob = () => {
  //   refetchJob()
  // }

  const tabItems = hiringStages.map((stage, index) => {
    // window.logger("%c[JobStagesContainer] stage", "background: #EFDDEF; color: #1976D2", { stage });
    // Only show the first 5 tabs Inbox, Screen, Interview, Decide, Offer

    if (stage == undefined) {
      return;
    }

    const tabIcon = () => {
      switch (stage.kind) {
        case "kind_inbox":
          return "inbox";
        case "kind_archived":
          return "archive";
        case "kind_hired":
          return "check-square";
        default:
          return "folder";
      }
    };

    return (
      <NavItem
        key={index}
        to={`/jobs/${job?.id}/stages/${stage?.id}/applicants`}
        icon={tabIcon()}
        label={stage.name}
        count={stage.jobApplicationsCount}
      />
    );
  });

  return (
    <>
      <Helmet title="Candidates" />
      <Styled.Container style={spring}>
        <Styled.Sidebar>
          <Styled.List>{tabItems}</Styled.List>

          <Styled.Divider />
          <Styled.List>
            <NavItem to={`/jobs/${job?.id}/setup`} icon="settings" label="Job setup" chevron />

            <Styled.Settings>
              {currentOrganizationUser.isAdmin && (
                <NavItem
                  to={`/jobs/${job?.id}/distribution`}
                  icon="share2"
                  label="Distribution"
                  chevron
                />
              )}
            </Styled.Settings>
            <FeatureFlipper feature="MVP_REPORTING">
              <NavItem to={`/jobs/${job?.id}/metrics`} icon="bar-chart-2" label="Metrics" chevron />
            </FeatureFlipper>
          </Styled.List>
        </Styled.Sidebar>
        <Styled.Content>
          <Switch>
            {/* <Route
              path={`${match.path}/stages/:stageId/applicants`}
              render={(renderProps) => (
                <CandidateListContainer {...props} {...renderProps} hiringStages={hiringStages} />
              )}
            /> */}
            <Route
              path={`${match.path}/stages/:stageId/applicants`}
              render={(renderProps) => {
                // window.logger(
                //   "%c[JobStagesContainer] Route to JobApplicationListContainer",
                //   "color: #1976D2",
                //   { match, renderProps },
                // );

                return (
                  <JobApplicationListContainer
                    {...props}
                    {...renderProps}
                    hiringStages={hiringStages}
                  />
                );
              }}
            />
            <Route
              path={`${match.path}/setup`}
              render={(renderProps) => <JobSetupContainer {...props} {...renderProps} />}
            />
            <Route
              path={`${match.path}/distribution`}
              render={(renderProps) => <JobDistributionContainer {...props} {...renderProps} />}
            />
            <Route
              path={`${match.path}/metrics`}
              render={(renderProps) => <JobMetricsContainer {...props} {...renderProps} />}
            />
            {hiringStages[0] != undefined && (
              <Redirect to={`${match.url}/stages/${hiringStages[0].id}/applicants`} />
            )}
          </Switch>
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default JobStagesContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled(animated.div)`
  label: JobStagesContainer;
  display: flex;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    overflow-y: auto;
    flex-shrink: 0;
    width: 40vw;

    ${t.mq["lg"]} {
      width: 12%;
      flex-shrink: 1;
    }
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_Content;
    width: auto;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 88%;
    }
  `;
});

Styled.List = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_List;
    margin-top: 0.375rem;
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: none;
  `;
});

Styled.Settings = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_Settings;
    display: none;

    ${t.mq["lg"]} {
      display: block;
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Fallback
/***********************************/

export default function ErrorBoundaryFallback() {
  return (
    <Styled.Component>
      <h1>Something didn't work.</h1>
      <p>
        An error has occurred, please try refreshing or visiting your <a href="/">Polymer home</a>.
      </p>
    </Styled.Component>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ErrorBoundaryFallback;
    ${t.py(24)};
    height: 100%;
    width: 100%;
    text-align: center;

    h1 {
      ${[t.mb(2), t.text.h2]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }

    p {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    a {
      ${[t.text.medium]}
      color: ${t.dark ? t.color.gray[300] : t.color.black} !important;
      &:hover {
        text-decoration: underline;
      }
    }
  `;
});
import React from "react";
import PropTypes from "prop-types";

import CenterModal from "@ats/src/components/modals/CenterModal";

const CloningJobModal = (props) => (
  <CenterModal headerTitleText="Cloning job" onCancel={() => props.onCancel}>
    <p>One moment while we clone the job.</p>
  </CenterModal>
);

CloningJobModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default CloningJobModal;

import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPost, apiPut, apiGet } from "@shared/queryHooks/api";

const getCandidatePrivateNote = async ({ candidateId }) => {
  if (candidateId) {
    return await apiGet({
      path: `/candidates/${candidateId}/candidate_private_notes/note`,
    });
  }
};

const updateCandidatePrivateNote = async ({
  candidateId,
  id: candidatePrivateNoteId,
  body,
  rating,
  status,
}: {
  id: number;
  candidateId: number;
  body: string;
  kind?: string;
  rating?: string;
  status?: string;
}) => {
  if (candidateId != undefined && candidatePrivateNoteId != undefined) {
    return await apiPut({
      path: `/candidates/${candidateId}/candidate_private_notes/${candidatePrivateNoteId}`,
      variables: { body, rating, status },
    });
  }
};

/* HOOKS
--===================================================-- */
export function useCandidatePrivateNote({
  candidateId,
  enabled = false,
}: {
  candidateId?: number | string;
  enabled: boolean;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(
    ["candidatePrivateNote", candidateId],
    () => getCandidatePrivateNote({ candidateId }),
    {
      enabled,
    },
  );
}

export function useUpdateCandidatePrivateNote() {
  const queryClient = useQueryClient();
  return useMutation(updateCandidatePrivateNote, {
    // After success or failure, refetch the jobCategories query
    onSuccess: (
      data,
      mutationVariables: {
        candidateId: number | string;
        id: number | string;
        body: string;
        rating?: string;
        kind?: string;
        status?: string;
      },
    ) => {
      window.logger("[useCandidatePrivateNote] useUpdateCandidatePrivateNote", {
        data,
        mutationVariables,
        queryClient,
      });

      queryClient.invalidateQueries("candidatePrivateNote");
    },
    // throwOnError: true,
  });
}

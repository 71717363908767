import React from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { usePublishJob } from "@shared/queryHooks/useJob";
import { useSyncWithStripe } from "@shared/queryHooks/useBilling";
import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function JobStripeCheckoutRedirectHandler(props) {
  // window.logger("%c[JobStripeCheckoutRedirectHandler] render", "color: #1976D2", { props });
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const { mutate: publishJob, isLoading: isLoadingPublishJob } = usePublishJob();
  const { mutate: syncWithStripe } = useSyncWithStripe();
  const addToast = useToastContext();

  const { currentUser } = useCurrentSession();

  window.logger("%c[JobStripeCheckoutRedirectHandler] render", "background-color: #94c963", {
    props,
  });

  /* Run's once - Check if being redirected from successful stripe checkout for per job pricing
  --===================================================-- */
  React.useEffect(() => {
    const { checkout, job_id, session_id, redirect_url } = queryString.parse(props.location.search);

    window.logger("%c[JobStripeCheckoutRedirectHandler] useEffect", "background-color: #29c282", {
      checkout,
      job_id,
      session_id,
      redirect_url,
      // customerData,
      // isStripeCustomerSuccess,
    });

    if (checkout === "success" && session_id != undefined) {
      // Track Conversion Event for Hire Plan Purchase
      // GTM will send to Google Analytics and to Google Ads
      window.dataLayer.push({
        event: "hirePlanPurchase",
        transactionId: Date.now().toString(),
        transactionValue: "119",
        userData: {
          email: currentUser.email,
        },
      });
    }

    syncWithStripe(null, {
      onSuccess: (data) => {
        setRedirectUrl(redirect_url);

        if (checkout === "success" && job_id != undefined) {
          // Publish the Job
          handlePublishJob(job_id);
        }
      },
    });
  }, []);

  const handlePublishJob = (jobId) => {
    const jobData = { id: jobId, status: "status_published" };
    publishJob(jobData, {
      onSuccess: (data) => {
        window.logger(
          "%c[JobStripeCheckoutRedirectHandler] handlePublishJob onSuccess",
          "background-color: #29c282",
          {
            data,
            redirectUrl,
          },
        );
        addToast({
          title: `Published ${data.title}`,
          kind: "success",
          delay: 10000,
          externalLink: {
            href: data.jobPostUrl,
            text: "View post",
          },
        });
      },
      onError: (response) => {
        window.logger(
          "%c[JobStripeCheckoutRedirectHandler] handlePublishJob onError",
          "color: #FF5002",
          {
            response,
            redirectUrl,
          },
        );
        addToast({
          title:
            "There was an unkown error when trying to publish please contact support@polymer.co",
          kind: "warning",
        });
      },
    });
  };

  if (redirectUrl == undefined || isLoadingPublishJob) {
    return <LoadingIndicator label="Loading..." />;
  }

  return <Redirect to={redirectUrl} />;
  // return <div>Huzzah</div>;
}

export default JobStripeCheckoutRedirectHandler;

import React from "react";
import isEmpty from "lodash/isEmpty";
import { Route, Switch, Link } from "react-router-dom";

import { JobViewProvider } from "@ats/src/context/JobViewContext";
import WebsocketJobChannelHandler from "@ats/src/websockets/WebsocketJobChannelHandler";

import Banner from "@ats/src/components/shared/Banner";
import NavBar from "@shared/components/NavBar";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import NewCandidateModal from "@ats/src/views/jobApplications/candidates/NewCandidateModal";

import JobStagesContainer from "@ats/src/views/jobApplications/JobStagesContainer";
import JobStripeCheckoutRedirectHandler from "@ats/src/views/jobApplications/JobStripeCheckoutRedirectHandler";

import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useJob } from "@shared/queryHooks/useJob";
import { useCreateJobCandidatesCsvExport } from "@shared/queryHooks/useCsvExport";
import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import FriendlyErrors from "@ats/src/components/shared/FriendlyErrors";
import JobImportCandidatesModal from "@ats/src/components/modals/JobImportCandidatesModal";
import { hiringStagesForJob, hiringStagesForJobOptions } from "@ats/src/lib/utils/helpers";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobContainer(props: any) {
  // useWhyDidYouUpdate("JobContainer ", props);
  // window.logger("%c[JobContainer] ", "background: #EFDDEF; color: #1976D2", props);
  const { match, history, location } = props;
  const memoMatch = React.useMemo(() => match, [match]);
  const { currentOrganization, currentOrganizationUser } = useCurrentSession();
  const hadAnActiveSubscriptionInThePast =
    !currentOrganization.stripeSubscriptionInGoodStanding &&
    currentOrganization.publishedJobsCount > 0;
  const jobId = Number(match.params.jobId);

  const { data: jobData, refetch: refetchJob, isFetching: isFetchingJob, error } = useJob({
    jobId,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: createJobCandidatesCsvExport,
    error: errorOnCreateCandidatesCsvExport,
    isLoading: isLoadingCreateCandidatesCsvExport,
  } = useCreateJobCandidatesCsvExport();

  const setJob = useJobStore(React.useCallback((state) => state.set, []));
  const { openModal, removeModal } = useModalContext();
  const [orgAdminJobsListUrl, setOrgAdminJobsListUrl] = React.useState("");

  React.useEffect(() => {
    // setOrgAdminJobsListUrl once on initial component rende
    if (currentOrganizationUser.isAdmin) {
      setOrgAdminJobsListUrl(location.state?.orgAdminJobsListUrl);
    }
  }, []);

  const updateJobStore = React.useCallback(() => {
    // window.logger("%c[JobContainer] UPDATE JOB STORE", "background: #FF002F; color: #F9FFFF", {
    //   jobData,
    //   setJob,
    // });
    setJob((state) => {
      state.jobsById[jobData.id] = jobData;
      state.questionsByJobId[jobData.id] = jobData.questions;
      state.hiringStagesByJobId[jobData.id] = jobData.hiringStages;

      jobData.questions.forEach((question) => {
        if (question != undefined) {
          state.questionsById[question.id] = question;
        }
      });
      jobData.hiringStages.forEach((hiringStage) => {
        if (hiringStage != undefined) {
          state.hiringStagesById[hiringStage.id] = hiringStage;
        }
      });
    });
  }, [jobData, setJob]);

  if (jobData != undefined) {
    updateJobStore();
  }

  React.useEffect(() => {
    // SAVE JOB
    if (jobData != undefined) {
      updateJobStore();
    }
  }, [jobData, jobId, updateJobStore]);

  React.useEffect(() => {
    // window.logger("%c[JobContainer] useEffect refetchJob", "background: #FF002F; color: #F9FFFF", {
    //   jobId,
    // });
    // Get the Job
    refetchJob();
  }, [jobId, refetchJob]);

  const job = useJobStore(React.useCallback((state) => state.jobsById[jobId], [jobId])) as any;
  const jobTitle = job && job.title ? job.title : "";
  const backUrl = orgAdminJobsListUrl ? orgAdminJobsListUrl : "/jobs";

  if (error) {
    return (
      <FriendlyErrors
        statusCode={error.response.status}
        resource="job"
        role={currentOrganizationUser?.role}
      />
    );
  }

  const inboxStage = jobData && jobData.hiringStages.find((stage) => stage.kind === "kind_inbox");

  // window.logger("%c[JobContainer] render", "background: #f199f1; color: #1900D2", {
  //   inboxStage,
  //   jobData,
  //   job,
  //   jobId: job?.id,
  //   matchSAME: match === memoMatch,
  // });

  const exportCandidatesToCsv = (event) => {
    event.preventDefault();

    createJobCandidatesCsvExport({ jobId });
  };

  const importCandidatesFromCsv = () => {
    const hiringStageOptions = hiringStagesForJobOptions(job);
    const modal = (
      <JobImportCandidatesModal
        onCancel={removeModal}
        jobId={jobId}
        hiringStageOptions={hiringStageOptions}
      />
    );

    openModal(modal);
  };

  const jobActions = () => {
    return (
      <DropdownMenu testid="job-candidates-menu" label="Job options">
        <button onClick={handleClickOnAdd}>Add new candidate</button>
        <button onClick={importCandidatesFromCsv}>Import candidates</button>
        <button onClick={exportCandidatesToCsv}>Export candidates</button>
      </DropdownMenu>
    );
  };

  const onCompleteAddingCandidate = (jobApplication) => {
    if (jobApplication) {
      history.push(`/jobs/${jobId}/stages/${inboxStage.id}/applicants/${jobApplication.id}`);
      return removeModal; // Must return uncalled "removeModal" function in order to allow this function to be called after the animation to remove the component completes.
    }
  };

  /* ADD NEW CANDIDATE
  --===================================================-- */
  const handleClickOnAdd = (e) => {
    e.preventDefault();
    // window.logger("%c[JobContainer] handleClickOnAdd props", "color: #1976D2", props);

    const modal = (
      <NewCandidateModal
        jobId={job.id}
        history={history}
        match={match}
        onCancel={removeModal}
        onComplete={onCompleteAddingCandidate}
      />
    );

    openModal(modal);
  };

  const getBanner = () => {
    const bannerLinkToContent = { pathname: `/hire/settings/billing`, state: { orginalPathname: location.pathname }};
    
    if (job?.status === "status_archived") {
      return <Banner message="This job has been archived" />;
    } else if (job?.status === "draft") {
      return <Banner message="This job is not yet published" />;
    } else if (job?.status === "published" && job.visible === false) {
      return <Banner message="This job post is currently hidden on your public job board" />;
    } else if (hadAnActiveSubscriptionInThePast) {
      return (
        <Banner
          message={
            <span>
              Published job posts are not visible without an active subscription. Visit{" "}
              {<Link to={bannerLinkToContent}>plan & billing</Link>} to find out more.
            </span>
          }
        />
      );
    }
  };

  return location.pathname.includes("stripe_checkout_redirect_handler") ? (
    <Route
      exact
      path="/jobs/:jobId/stripe_checkout_redirect_handler"
      render={(renderProps) => (
        <JobStripeCheckoutRedirectHandler {...props} {...renderProps} job={job} />
      )}
    />
  ) : (
    <JobViewProvider>
      <WebsocketJobChannelHandler jobId={jobId}>
        {getBanner()}
        <NavBar
          isBack={true}
          backUrl={backUrl}
          title={jobTitle}
          titleUrl={`/jobs/${jobId}/stages`}
          history={history}
          secondary={jobActions()}
        />
        {isEmpty(job) && isFetchingJob ? (
          // ||
          // (isEmpty(jobApplications) && isFetchingJobApplications && job.status != "draft")
          <LoadingIndicator label="Loading..." />
        ) : (
          <Switch>
            <Route
              path="/jobs/:jobId"
              render={(renderProps) => (
                <JobStagesContainer
                  {...props}
                  {...renderProps}
                  job={job}
                  refetchJob={refetchJob}
                  match={memoMatch}
                  orgAdminJobsListUrl={orgAdminJobsListUrl}//to allow for use in nested & linked components
                />
              )}
            />
          </Switch>
        )}
      </WebsocketJobChannelHandler>
    </JobViewProvider>
  );
}

export default JobContainer;

import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";

import { useAddHiringTeamMembership } from "@shared/queryHooks/useHiringTeamMembership";
import { useAllInvites } from "@shared/queryHooks/useInvite";
import { useCreateHiringTeamInvite } from "@shared/queryHooks/useHiringTeamInvite";

type Props = {
  onCancel: () => void;
  allUsers: [];
  organizationUsers: any[];
  allInvites: [];
  teamMembers: [];
  hiringTeamInvites: [];
  job: { id; organizationUserIds: any[] };
};

function NewTeamMemberModal(props: Props) {
  window.logger("%c[NewTeamMemberModal] render", "color: #1976D2", { props });
  const { onCancel, allUsers, organizationUsers, job, hiringTeamInvites } = props;
  const { mutate: addTeamMember, isLoading: isLoadingAdd } = useAddHiringTeamMembership();
  const [selectedOrganizationUserId, setSelectedOrganizationUserId] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const {
    mutate: createHiringTeamInvite,
    isLoading: isLoadingCreateHiringTeamInvite,
  } = useCreateHiringTeamInvite();

  const { data: invitesData } = useAllInvites();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedOrganizationUserId.includes("organizationUser-")) {
      addTeamMember(
        {
          jobId: job.id,
          organizationUserId: selectedOrganizationUserId.replace("organizationUser-", ""),
        },
        {
          onSuccess: (data) => {
            window.logger("%c[NTMM] addTeamMember SUCCESS", "color: #1976D2", { data });
            onCancel();
          },
        },
      );
    } else {
      createHiringTeamInvite(
        { jobId: job.id, inviteId: selectedOrganizationUserId.replace("invite-", "") },
        {
          onSuccess: (data) => {
            window.logger("%c[NTMM] createHiringteamInvite SUCCESS", "color: #1976D2", { data });
            onCancel();
          },
        },
      );
    }
  };

  const availableTeamMembers = () => {
    // return allUsers
    //   .filter((user: { isActive }) => user.isActive)
    //   .filter((user: { id }) => !job.userIds.includes(user.id));

    return organizationUsers
      .filter((organizationUser: { isActive: boolean }) => organizationUser.isActive)
      .filter((organizationUser: { role: string }) => organizationUser.role !== "org_interviewer")
      .filter(
        (organizationUser: { id: number }) =>
          !job.organizationUserIds.includes(organizationUser.id),
      );
  };

  const availableInvites = () => {
    return invitesData == undefined
      ? []
      : invitesData.items
        // .filter((invite: { isActive }) => invite.isActive);
        .filter((invite: { role }) => invite.role !== "org_interviewer")
        .filter(
          (invite: { id }) =>
            !hiringTeamInvites
              .map((hiringTeamInvite: { invite }) => hiringTeamInvite.invite.id)
              .includes(invite.id),
        );
  };

  const teamMemberOptions = () => {
    const users = availableTeamMembers().map((organizationUser: { id: number; user: any }) => ({
      id: organizationUser.id,
      value: `organizationUser-${organizationUser.id}`,
      label: organizationUser.user.fullName,
    }));

    const invites = availableInvites().map((invite: { id; email }) => ({
      id: invite.id,
      value: `invite-${invite.id}`,
      label: invite.email,
    }));

    return [...users, ...invites] as const;
  };

  window.logger("%c[NewTeamMembershipModal] RENDER ", "color: #1976D2", { invitesData });

  const handleSelection = (name, value) => {
    window.logger("[NewTeamMemberModal] handleSelection", {
      name,
      value,
    });
    setSelectedOrganizationUserId(value);
    setIsDirty(true);
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingAdd || isLoadingCreateHiringTeamInvite}
        disabled={
          isLoadingAdd || isLoadingCreateHiringTeamInvite || selectedOrganizationUserId == undefined
        }
      >
        Add to team
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal
      headerTitleText="Add a team member"
      onCancel={onCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        Select the individual to add to this job's hiring team.
      </Styled.Instructions>
      <FormContainer buttons={submitButton}>
        <FormSelect
          onChange={handleSelection}
          options={teamMemberOptions()}
          name="teamMember"
          label="New team member"
          value={selectedOrganizationUserId}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

NewTeamMemberModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default NewTeamMemberModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

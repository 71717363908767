import { useState } from "react";
import CenterModal from "@ats/src/components/modals/CenterModal";
import { useCreateJobCandidatesCsvImport } from "@shared/queryHooks/useCsvImport";
import FormFile from "../forms/FormFile";
import FormSelect from "@ats/src/components/forms/FormSelect";
import Button from "@ats/src/components/shared/Button";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useToastContext } from "@shared/context/ToastContext";
import { HiringStage } from "@ats/types/job";
import { HiringStageOption } from "@ats/src/lib/utils/helpers";
import Icon from "@ats/src/components/shared/Icon";

interface JobImportCandidatesModalProps {
  onCancel: () => void;
  jobId: number;
  hiringStageOptions: HiringStageOption[];
}

function JobImportCandidatesModal({
  onCancel,
  jobId,
  hiringStageOptions,
}: JobImportCandidatesModalProps) {
  const [file, setFile] = useState<File>(null);
  const [hiringStageId, setHiringStageId] = useState<number>(hiringStageOptions[0].value);
  const [errors, setErrors] = useState<string>(null);
  const addToast = useToastContext();
  const { mutate: createJobCandidatesCsvImport } = useCreateJobCandidatesCsvImport();

  const onChangeUploader = (file: File) => {
    setErrors(null);
    setFile(file);
  };

  const handleChangeStage = (name, value) => {
    setHiringStageId(value);
  };

  const onSubmit = () => {
    if (!file) {
      setErrors("Please provide a valid CSV file.");
      return;
    }
    createJobCandidatesCsvImport(
      { jobId, file, hiringStageId },
      {
        onError: (data: any) => {
          setErrors(data.data.errors);
        },
        onSuccess: () => {
          addToast({
            kind: "success",
            title:
              "Your import is processing and may take several minutes. We will notify you when it is complete.",
            delay: 5000,
          });

          onCancel();
        },
      },
    );
  };

  return (
    <CenterModal headerTitleText="Import candidates" onCancel={onCancel} hasUnsavedChanges={false}>
      <StyledInstructions>
        You can bulk import candidates using a CSV file populated with their information. View our
        template to see the required format.
        <Button
          type="externalLink"
          link="https://inflow-public.s3.amazonaws.com/polymer_-_candidate_upload_template.csv"
          styleType="text"
        >
          Download CSV template
          <Icon name="download" />
        </Button>
      </StyledInstructions>
      <StyledRequirements>
        The supplied CSV must meet the following requirements:
        <ul>
          <li>Candidate first names are required</li>
          <li>Email addresses must be a valid format</li>
          <li>
            Email addresses must be unique and not exist for pre-existing candidates for this job
          </li>
          <li>Resumes must be provided as links to PDF files</li>
        </ul>
      </StyledRequirements>
      {/* <StyledInstructions>
        Once you have a populated CSV file, select it for import using the upload button below.
      </StyledInstructions> */}
      <FormFile
        label="Source file"
        description="Provide a CSV file containing candidate information"
        expectedFileType="csv"
        onChange={onChangeUploader}
      />
      <FormSelect
        onChange={handleChangeStage}
        name="jobStage"
        label="Hiring stage"
        description="Imported candidates will be added to the selected hiring stage"
        className=""
        value={hiringStageId}
        options={hiringStageOptions}
        errors={[]}
        isClearable={false}
        autoFocus
        menuPlacement="top"
      />

      {errors && <StyledErrors>{errors}</StyledErrors>}
      <StyledButtonContainer>
        <Button onClick={onSubmit}>Import candidates</Button>
        <Button styleType="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </StyledButtonContainer>
    </CenterModal>
  );
}

export default JobImportCandidatesModal;

const StyledInstructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(5)]}

    a {
      ${t.mt(3)}
    }
  `;
});

const StyledRequirements = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(5), t.pt(4), t.px(4), t.pb(1), t.rounded.sm]}
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[700] : t.color.white};
    color: ${t.dark ? t.color.gray[300] : t.color.gray[600]};

    ul {
      ${[t.pl(8)]}
      margin: 12px 0;
      list-style-type: disc;
      line-height: 1.6;
    }

    li {
      margin-bottom: 0.375rem;
    }
  `;
});

const StyledButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }

    ${t.mt(10)}

    ${t.mq["md"]} {
      ${t.mt(8)}
    }
  `;
});

const StyledErrors = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.p(2), t.my(6), t.rounded.sm]}
    border: 1px solid ${t.dark ? t.color.red[400] : t.color.red[600]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.red[100]};
    color: ${t.dark ? t.color.red[400] : t.color.red[600]};
  `;
});

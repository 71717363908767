export interface JobApplication {
  id: number;
  source: string;
  desiredCompensation: string;
  jobId: number;
  settings: JobApplicationSettings;
  jobTitle: string;
  relativeUrl: string;
  createdAtTimestamp: number | string;
  questionResponses: Array<CustomQuestionResponseWithQuestion>;
}

export interface JobApplicationSettings {
  overview: string;
  messages: string;
  compensation: string;
}

type JobApplicationSettingType = "public" | "private";

export interface CustomQuestionResponseWithQuestion extends CustomQuestionResponse {
  question: CustomQuestion;
}
export interface CustomQuestionResponse {
  id: number;
  jobApplicationId: number;
  questionId: number;
  body: null | string;
  responseArray: Array<String>;
  customFileUrl: null | string;
  customFileFilename: null | string;
  createdAtTimestamp: number;
}

export interface CustomQuestion {
  id: number;
  questionId: number;
  jobId: number;
  questionText: string;
  placeholderText: null | string;
  requirementSetting: string;
  kind: string;
  position: number;
  options: {
    data: Array<String>;
  };
  visibilitySetting: VisibilitySetting;
}

export enum VisibilitySetting {
  PRIVATE = "visibility_private",
  PUBLIC = "visibility_public",
}

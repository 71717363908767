import { useMutation } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createJobCandidatesCsvImport = async ({
  jobId,
  file,
  hiringStageId,
}: {
  jobId: number;
  file: File;
  hiringStageId: number;
}) => {
  if (jobId == undefined) {
    return;
  }
  const formData = new FormData();
  formData.append("file", file);
  formData.append("hiring_stage_id", hiringStageId.toString());
  return await apiPost({ path: `/jobs/${jobId}/job_csv_import`, variables: formData });
};

function useCreateJobCandidatesCsvImport() {
  return useMutation(createJobCandidatesCsvImport, {});
}

export { useCreateJobCandidatesCsvImport };

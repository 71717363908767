import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";
import theme from "@ats/styles/theme";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Logo from "@ats/src/components/shared/Logo";

export default function HeaderBar() {
  const { isDarkMode } = useColorSchemeContext();

  const modeStyles = isDarkMode
    ? css`
        border-bottom: 1px solid ${theme.color.gray[800]};
      `
    : css`
        border-bottom: 1px solid ${theme.color.gray[200]};
      `;

  return (
    <Styled.Container css={modeStyles}>
      <a href="https://polymer.co" className="app-header__logo" rel="noopener noreferrer">
        <Logo /> Polymer
      </a>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;

  return css`
    label: HeaderBar;
    position: sticky;
    top: 0;
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit;

    svg {
      ${t.mr(3)};
    }

    a {
      ${[t.text.lg, t.text.bold]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
});

import create, { SetState, GetState } from "zustand";
import schema from "@shared/ProseMirror/config/schema";

type ProseMirrorEditorStore = {
  /* Link
  --===================================================-- */
  linkToolbarIsActive: boolean;
  setLinkToolbarIsActive: (active: boolean) => any;
  linkAttrs: { href: string | null; title?: string };
  setLinkHref: (href) => any;
  setLinkAttrs: (linkAttrs: { href: string | null; title?: string }) => any;
  clearLinkStore: () => void;
  handleClickOnLinkMenuItem: (state: any, dispatch: any) => any;
  /* Image
  --===================================================-- */
  imageToolbarIsActive: boolean;
  // setImageToolbarIsActive: (active: boolean) => any;
  imageSrc: string | null;
  // setImageSrc: (src) => any;
  clearImageStore: () => void;
  handleClickOnImageMenuItem: (state: any, dispatch: any) => any;
  /* Iframe
  --===================================================-- */
  iframeToolbarIsActive: boolean;
  iframeSrc: string | null;
  clearIframeStore: () => void;
  handleClickOnIframeMenuItem: (state: any, dispatch: any) => any;
};

const useProseMirrorEditorStore = create<ProseMirrorEditorStore>(
  (set: SetState<ProseMirrorEditorStore>, get: GetState<ProseMirrorEditorStore>) => ({
    /* Link
    --===================================================-- */
    linkToolbarIsActive: false,
    setLinkToolbarIsActive: (active) => {
      set((state) => ({ linkToolbarIsActive: active }));
    },
    linkAttrs: { href: null, title: null },
    setLinkHref: (href) => {
      const linkAttrs = get().linkAttrs;
      set((state) => ({ linkAttrs: { ...linkAttrs, href } }));
    },
    setLinkAttrs: (linkAttrs) => {
      set((state) => ({ linkAttrs: { ...linkAttrs } }));
    },
    clearLinkStore: () => {
      set({
        linkToolbarIsActive: false,
        linkAttrs: { href: null, title: null },
      });
    },
    handleClickOnLinkMenuItem: (editorState, dispatch) => {
      set((state) => {
        return handleClickOnLinkMenuItem(editorState, dispatch);
      });
    },
    /* Image
    --===================================================-- */
    imageToolbarIsActive: false,
    // setImageToolbarIsActive: (active) => {
    //   set((state) => ({ imageToolbarIsActive: active }));
    // },
    imageSrc: null,
    // setImageSrc: (src) => {
    //   set((state) => ({ imageSrc: src }));
    // },
    clearImageStore: () => {
      set({
        imageToolbarIsActive: false,
        imageSrc: null,
      });
    },
    handleClickOnImageMenuItem: (editorState, dispatch) => {
      set((state) => {
        return handleClickOnImageMenuItem(editorState, dispatch);
      });
    },
    /* Iframe
    --===================================================-- */
    iframeToolbarIsActive: false,
    // setIframeToolbarIsActive: (active) => {
    //   set((state) => ({ iframeToolbarIsActive: active }));
    // },
    iframeSrc: null,
    // setIframeSrc: (src) => {
    //   set((state) => ({ iframeSrc: src }));
    // },
    clearIframeStore: () => {
      set({
        iframeToolbarIsActive: false,
        iframeSrc: null,
      });
    },
    handleClickOnIframeMenuItem: (editorState, dispatch) => {
      set((state) => {
        return handleClickOnIframeMenuItem(editorState, dispatch);
      });
    },
  }),
);

const handleClickOnLinkMenuItem = (state, dispatch) => {
  const { from, to, $from, $to } = state.selection;
  const selectionContainsLink = state.doc.rangeHasMark(from, to, schema.marks.link);
  const { parent, parentOffset } = state.doc.resolve(from);
  const start = parent.childAfter(parentOffset);

  // window.logger("%c[ProseMirror] handleClickOnLinkMenuItem", "background-color: #61e473", {
  //   ...state,
  //   selectionContainsLink,
  //   start,
  //   parent,
  //   parentOffset,
  // });

  const link = start.node.marks.find((mark) => mark.type === schema.marks.link);

  // If there is no link inside the selection treat this as a new link
  if (!link) {
    // showLinkToolbar();
    // return null;

    // Empty State
    // return get().clearLinkStore();
    return { linkToolbarIsActive: true, linkAttrs: { href: null, title: null } };
  }

  // If there is no link before the end of the selection treat this as a new link
  if ($to.nodeBefore != undefined && $to.nodeBefore.marks.length == 0) {
    // showLinkToolbar();
    // return null;
    return { linkToolbarIsActive: true, linkAttrs: { href: null, title: null } };
  }

  const href = link.attrs.href;

  // showLinkToolbar(href);
  return { linkToolbarIsActive: true, linkAttrs: { href, title: null } };
};

const handleClickOnImageMenuItem = (state, dispatch) => {
  window.logger("%c[proseMirrorEditorStore] handleClickOnImageMenuItem", "color: #1976D2", {
    state,
    dispatch,
  });
  return { imageToolbarIsActive: true, imageSrc: null };
};

const handleClickOnIframeMenuItem = (state, dispatch) => {
  window.logger("%c[proseMirrorEditorStore] handleClickOnIframeMenuItem", "color: #1976D2", {
    state,
    dispatch,
  });
  return { iframeToolbarIsActive: true, iframeSrc: null };
};

export { useProseMirrorEditorStore };

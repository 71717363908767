import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import keys from "lodash/keys";
import Tooltip from "@ats/src/components/shared/Tooltip";
import FormSelect from "@ats/src/components/forms/FormSelect";

import { currentActiveTextLevel } from "@shared/ProseMirror/config/menu";
import { findSelectOptionByValue } from "@ats/src/lib/lookups";
import ShortcutKey from "@ats/src/components/shared/ShortcutKey";
import Icon from "@ats/src/components/shared/Icon";
import CommentTemplateSelectionModal from "@ats/src/components/modals/CommentTemplateSelectionModal";

import { useProseMirrorEditorStore } from "@ats/src/lib/store/zustand/proseMirrorEditorStore";
import { useModalContext } from "@shared/context/ModalContext";

/* Questions
--===================================================-- */
const BLOCK_STYLE_OPTIONS = [
  { value: "paragraph", label: "Normal text" },
  { value: "h1", label: "Heading 1" },
  { value: "h2", label: "Heading 2" },
  { value: "h3", label: "Heading 3" },
  // { value: "h4", label: "Heading 4" },
  // { value: "h5", label: "Heading 5" },
  // { value: "h6", label: "Heading 6" },
];

function MenuBar({
  viewRef,
  menu,
  state,
  dispatch,
  enableCheckboxes,
  enableInsertMenuItems = true,
  enableInsertReviewTemplates = false,
  showAsTextArea,
  handleInsertTemplate,
}) {
  const { openModal, removeModal } = useModalContext();
  const { marks, blocks, insert } = menu;
  // const { type, attrs } = currentLevel;
  const [
    handleClickOnLinkMenuItem,
    handleClickOnImageMenuItem,
    handleClickOnIframeMenuItem,
  ] = useProseMirrorEditorStore((state) => [
    state.handleClickOnLinkMenuItem,
    state.handleClickOnImageMenuItem,
    state.handleClickOnIframeMenuItem,
  ]);

  const valueFromLevel = () => {
    const currentLevel = currentActiveTextLevel(state);
    if (currentLevel != undefined) {
      if (currentLevel.type.name === "paragraph") {
        return "paragraph";
      } else if (currentLevel.type.name === "heading") {
        return `h${currentLevel.attrs.level}`;
      }

      return null;
    }

    return null;
  };

  const currentBlockType = valueFromLevel();
  const currentSelectedBlockStyleOption = findSelectOptionByValue(
    BLOCK_STYLE_OPTIONS,
    currentBlockType,
  );

  // window.logger("%c[MenuBar][ProseMirror] render", "color: #1976D2", {
  //   menu,
  //   state,
  //   dispatch,
  //   valueFromLevel: valueFromLevel(),
  //   currentSelectedBlockStyleOption,
  //   enableCheckboxes,
  // });

  const handleOnClickInsertTemplate = (event) => {
    event.preventDefault();

    const modal = (
      <CommentTemplateSelectionModal
        onCancel={removeModal}
        handleInsertTemplate={handleInsertTemplate}
      />
    );

    openModal(modal);
  };

  const handleBlockStyleSelection = (name, value) => {
    // window.logger("%c[MenuBar] handleBlockStyleSelection ", "color: #1976D2", { name, value });
    viewRef.current.view.focus();
    menu.blocks[value].run(state, dispatch);
  };

  return (
    <Styled.MenuContainer className="ProseMirror-menu" showAsTextArea={showAsTextArea}>
      {/* Marks */}
      <div>
        <span>
          {keys(marks).map((item, index) => (
            <Tooltip
              key={item}
              label={
                <>
                  {marks[item].title}
                  {marks[item].keys && (
                    <>
                      {" "}
                      {marks[item].keys
                        .map((key) => <ShortcutKey key={`${item.title}-${key}`}>{key}</ShortcutKey>)
                        .reduce((prev, curr) => [prev, " + ", curr])}
                    </>
                  )}
                </>
              }
            >
              <button
                type="button"
                className={marks[item].active && marks[item].active(state) ? "active" : null}
                disabled={marks[item].enable && !marks[item].enable(state)}
                onMouseDown={(event) => {
                  event.preventDefault();
                  viewRef.current.view.focus();
                  if (item === "link") {
                    /* Handle the Link Menu Option
                    --===================================================-- */
                    handleClickOnLinkMenuItem(state, dispatch);
                  } else {
                    // menuSection[item].run(state, dispatch);
                    marks[item].run(state, dispatch);
                  }
                }}
              >
                {marks[item].content}
              </button>
            </Tooltip>
          ))}
        </span>

        {/* Blocks */}
        <span>
          <Styled.FormSelect
            showAsTextArea={showAsTextArea}
            onChange={handleBlockStyleSelection}
            name="textStyle"
            placeholder="None"
            value={currentSelectedBlockStyleOption?.value}
            // isClearable={false}
            options={BLOCK_STYLE_OPTIONS}
          // isRequired={isRequired}
          // errors={errors}
          />
        </span>

        <span>
          {keys(blocks)
            .filter((item) => blocks[item].display)
            .map((item, index) => {
              if (!enableCheckboxes && item === "checkbox_list") {
                return null;
              } else {
                return (
                  <Tooltip key={item} label={blocks[item].title}>
                    <button
                      type="button"
                      className={
                        blocks[item].active && blocks[item].active(state) ? "active" : null
                      }
                      // disabled={menu[key].enable && !menu[key].enable(state)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        viewRef.current.view.focus();
                        blocks[item].run(state, dispatch);
                      }}
                    >
                      {blocks[item].content}
                    </button>
                  </Tooltip>
                );
              }
            })}
        </span>

        {/* Insert */}
        {enableInsertMenuItems ? (
          <span>
            {keys(insert).map((item, index) => (
              <Tooltip key={item} label={insert[item].title}>
                <button
                  type="button"
                  className={insert[item].active && insert[item].active(state) ? "active" : null}
                  // disabled={menu[key].enable && !menu[key].enable(state)}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    viewRef.current.view.focus();
                    // insert[item].run(state, dispatch);

                    window.logger("%c[MenuBar] click", "color: #1976D2", { item, insert });

                    if (item === "image") {
                      /* Handle the Image Menu Option
                    --===================================================-- */
                      handleClickOnImageMenuItem(state, dispatch);
                    } else if (item === "iframe") {
                      /* Handle the Image Menu Option
                    --===================================================-- */
                      handleClickOnIframeMenuItem(state, dispatch);
                    } else {
                      // menuSection[item].run(state, dispatch);
                      insert[item].run(state, dispatch);
                    }
                  }}
                >
                  {insert[item].content}
                </button>
              </Tooltip>
            ))}
          </span>
        ) : null}
      </div>

      {/* Templates */}
      {enableInsertReviewTemplates ? (
        <Styled.InsertButton showAsTextArea={showAsTextArea}>
          <button onClick={handleOnClickInsertTemplate}>
            <Icon name="plus-square" />
            <span>Insert template</span>
          </button>
        </Styled.InsertButton>
      ) : null}
    </Styled.MenuContainer>
  );
}

export default MenuBar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.MenuContainer = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: ProseMirror_Menu;
    ${[t.h(12)]}
    min-height: ${t.spacing[12]};
    align-items: center;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-radius: 0;
    position: sticky;
    z-index: 1;
    top: 0;
    background: ${props.showAsTextArea
      ? "transparent"
      : t.dark
        ? t.color.gray[900]
        : t.color.white};
    max-width: 100%;
    display: flex;

    ${t.mq["md"]} {
      ${[t.h(10)]}
      min-height: ${t.spacing[10]};
    }

    > div {
      display: flex;
      height: 100%;
      align-items: center;

      > span {
        ${[t.px(1)]}
        height: 100%;
        display: flex;
        align-items: center;
        &:after {
          content: "";
          display: block;
          ${[t.h(4), t.w("px"), t.ml(2)]}
          background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[700]
        : t.color.gray[800]
      : t.color.gray[200]};
        }
        &:last-of-type:after {
          display: none;
        }
      }
    }

    button {
      ${[t.rounded.sm, t.h(8), t.w(8)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      margin: 0 0.125rem;
      background-color: transparent;
      transition: background-color 0.2s ease;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 1.25em;
        width: 1.25em;
        stroke-width: 1.75px;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px
          ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[600]
      : t.color.gray[300]};
      }
      &:active:focus {
        box-shadow: 0 0 0 1px
          ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[600]
      : t.color.gray[400]};
      }
      &:hover {
        color: ${t.dark
      ? props.showAsTextArea
        ? t.color.white
        : t.color.gray[200]
      : t.color.black};
        border: none;
        background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[700]
      : t.color.gray[200]};
      }
      &.active {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        border: none;
        background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[800]
      : t.color.gray[200]};
      }
      &.active:hover {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        border: none;
        background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[700]
      : t.color.gray[200]};
      }
      &:disabled {
        color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
        cursor: default;
        pointer-events: none;
      }
    }
  `;
});

Styled.FormSelect = styled(FormSelect)((props) => {
  const t: any = props.theme;
  return css`
    label: ProseMirror_FormSelect;
    ${[t.mt(5)]}
    min-width: ${t.spacing[32]};
    margin-left: 0.125rem;
    margin-right: 0.125rem;

    .form-select-ui__control {
      border: none;
      background-color: transparent;

      &:focus,
      &:focus:hover,
      &--is-focused,
      &--is-focused:hover {
        background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[800]
      : t.color.gray[200]};
        box-shadow: none;

        .form-select-ui__indicator svg {
          stroke: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[700]
        : t.color.gray[900]
      : t.color.gray[200]};
          fill: ${t.dark ? t.color.gray[200] : t.color.black};
        }
        .form-select-ui__single-value {
          color: ${t.dark ? t.color.gray[200] : t.color.black};
        }
      }
    }
    .form-select-ui__indicator svg {
      fill: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    .form-select-ui__single-value {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    &:hover {
      .form-select-ui__control {
        background-color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[700]
      : t.color.gray[200]};
      }
      .form-select-ui__indicator svg {
        stroke: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[600]
        : t.color.gray[800]
      : t.color.gray[200]};
        fill: ${t.dark ? t.color.gray[200] : t.color.black};
      }
      .form-select-ui__single-value {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
      }
    }
  `;
});

Styled.InsertButton = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    overflow: hidden;
    ${t.ml(-1)}

    button {
      ${[t.rounded.sm, t.h(8), t.px(2), t.mr(2)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      width: auto;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;

      span {
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 1;
        text-overflow: ellipsis;
      }

      svg {
        ${t.mr(1)}
        flex-shrink: 0;
      }
    }
  `;
});

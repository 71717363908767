import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Fallback
/***********************************/

interface FriendlyErrorsProps {
  statusCode: number;
  resource: string;
  actionUrl?: string;
  role?: string;
}
// default return to jobs list unless role is interviewer - then omit rendering link at all
export default function FriendlyErrors({ statusCode, resource, actionUrl = "/jobs", role }: FriendlyErrorsProps) {
  if (statusCode === 422) {
    return (
      <Styled.Component>
        <h1>The {resource} you are looking for could not be found.</h1>
        {role !== "org_interviewer" && (
          <p>
            <a href={actionUrl}>Click&nbsp;here</a> to return to your jobs.
          </p>
        )}
      </Styled.Component>
    );
  }

  if (statusCode === 403) { 
    return (
      <Styled.Component>
        <h1>You do not have access to the {resource} you are trying to view.</h1>
        <p>
          Please check with your admin if you believe you should have access.{" "}
          {role !== "org_interviewer" && (
            <>
              <a href={actionUrl}>Click&nbsp;here</a> to return to your jobs.
            </>
          )}
        </p>
      </Styled.Component>
    );
  }

  return (
    <Styled.Component>
      <h1>Something didn't work.</h1>
      <p>
        An error has occurred, please try refreshing or visiting your{" "}
        <a href="/">Polymer&nbsp;home</a>.
      </p>
    </Styled.Component>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FriendlyErrors;
    ${[t.py(24), t.px(4)]};
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      ${[t.mb(2), t.text.h2]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      line-height: 1.4;
    }

    p {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;
    }

    a {
      ${[t.text.medium]}
      color: ${t.dark ? t.color.gray[300] : t.color.black} !important;
      &:hover {
        text-decoration: underline;
      }
    }
  `;
});

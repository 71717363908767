import { useQuery } from "react-query";
import { apiGet } from "@shared/queryHooks/api";

const getJobCategories = async () => {
  return await apiGet({ path: "/job_categories" });
};

export default function useJobCategories(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
} {
  return useQuery("jobCategories", getJobCategories);
}

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import useOnClickOutside from "use-onclickoutside";

import { useProseMirrorEditorStore } from "@ats/src/lib/store/zustand/proseMirrorEditorStore";

function FloatingToolbar({ viewRef, state, children }) {
  const menuRef = React.useRef(null);
  const [style, setStyle] = React.useState({ left: 0, bottom: 0 });
  const [
    imageToolbarIsActive,
    clearLinkStore,
    clearImageStore,
    clearIframeStore,
    iframeToolbarIsActive,
  ] = useProseMirrorEditorStore((state) => [
    state.imageToolbarIsActive,
    state.clearLinkStore,
    state.clearImageStore,
    state.clearIframeStore,
    state.iframeToolbarIsActive,
  ]);

  useOnClickOutside(menuRef, () => {
    clearLinkStore();
    clearImageStore();
    clearIframeStore();
  });

  // console.log("%c[FloatingToolbar][ProseMirror] render", "color: #1976D2", {
  //   menu,
  //   state,
  //   dispatch,
  //   view: viewRef.current?.view,
  // });

  const calculateStyle = React.useCallback(() => {
    const { selection } = state;

    // console.log("%c[FloatingToolbar][ProseMirror] render", "background-color: #5dd219", {
    //   selection,
    // });

    /* Removed selection.empty so that this works for inserting an Image URL
    --===================================================-- */
    if (!selection || (selection.empty && !imageToolbarIsActive && !iframeToolbarIsActive)) {
      return {
        left: -10000000,
        bottom: 0,
      };
    }

    // These are in screen coordinates
    const { from, to } = selection;
    const start = viewRef.current.view.coordsAtPos(from);
    const end = viewRef.current.view.coordsAtPos(to);

    //offsetParent is the nearst "positioned" parent element
    const box = menuRef.current.offsetParent.getBoundingClientRect();
    const left = Math.max((start.left + end.left) / 2, start.left + 3);
    const menuWidth = menuRef.current.offsetWidth;
    const menuWidthOffset = menuWidth / 2;
    const leftCoordinate = left - box.left - menuWidthOffset;

    const newCoords = {
      left:
        leftCoordinate < 0
          ? 0
          : leftCoordinate > box.width - menuWidth // too far left to show whole menu
            ? box.width - menuWidth // set left as width of menu away from the right
            : leftCoordinate,
      bottom: box.bottom - start.top + 5,
    };

    // console.log("%c[FloatingToolbar] calculateStyle", "background-color: #5ea4e9", {
    //   viewRef: viewRef.current.view,
    //   menuParent: menuRef.current.parent,
    //   menuOffsetParent: menuRef.current.offsetParent,
    //   // viewRef: viewRef.current.view.root.offsetParent.getBoundingClientRect(),
    //   start,
    //   end,
    //   box,
    //   left,
    //   menuWidthOffset,
    //   leftCoordinate,
    //   newCoords,
    //   boxBottom: box.bottom,
    //   startTop: start.top,
    // });

    return newCoords;
  }, [state, viewRef, imageToolbarIsActive, iframeToolbarIsActive]);

  React.useEffect(() => {
    setStyle(() => calculateStyle());
  }, [state, calculateStyle]);

  return (
    <Styled.FloatingToolbar ref={menuRef} style={style}>
      {children}
    </Styled.FloatingToolbar>
  );
}

export default FloatingToolbar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.FloatingToolbar = styled.div((props) => {
  const t: any = props.theme;
  const modeStyles = t.dark ?
    css`
      background-color: ${t.color.gray[700]};
      border: 1px solid ${t.color.gray[600]};
      color: ${t.color.gray[200]};
  ` :
    css`
      background-color: ${t.color.white};
      border: 1px solid ${t.color.gray[200]};
      color: ${t.color.gray[600]};
  `;
  return css`
    label: ProseMirror_FloatingToolbar;
    ${[t.rounded.sm, t.p(1)]}
    position: absolute;
    z-index: 10;
    white-space: nowrap;
    margin: 0;
    display: flex;
    line-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    ${modeStyles}
  `;
});

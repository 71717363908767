/* eslint-disable @getify/proper-arrows/return */
import React, { useState } from "react";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { pluralize } from "@ats/src/lib/utils/helpers";
import { timeAgoInWordsLong, prettyDate } from "@shared/lib/time";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import PromoCodeCenterModal from "@ats/src/components/modals/PromoCodeCenterModal";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import Icon from "@ats/src/components/shared/Icon";

import {
  useStripeCustomer,
  useBillingPrices,
  useCreateStripeCheckoutSession,
  useCreateStripeCustomerPortalSession,
  useCreateSubscription,
  useSwapCheckoutSessionIdForPaymentMethod,
  useStripeCustomerSubscription,
} from "@shared/queryHooks/useBilling";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

const DEFAULT_PRICE_LOOKUP_KEY = "plan_simple_ats_per_job_tiered";

function AccountBilling(props) {
  const { currentOrganization } = useCurrentSession(); // TODO: use the reactQuery Organization instead of the Redux organization
  const { publishedJobsCount } = currentOrganization;

  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();

  /* Customer
  --===================================================-- */
  const { data: stripeCustomerData, isFetching: isFetchingStripeCustomer } = useStripeCustomer({
    refetchOnWindowFocus: false,
  });
  const customer = stripeCustomerData ? stripeCustomerData.customer : null;

  /* Get Subscription
  --===================================================-- */
  const {
    data: stripeCustomerSubscriptionData,
    isFetching: isFetchingStripeCustomerSubscription,
  } = useStripeCustomerSubscription({
    refetchOnWindowFocus: false,
  });
  const currentSubscription = stripeCustomerSubscriptionData
    ? stripeCustomerSubscriptionData.subscription
    : null;
  const hasActiveSubscription = currentOrganization.stripeSubscriptionInGoodStanding === true;

  /* Create Subscription
  --===================================================-- */
  const {
    mutate: createSubscription,
    isLoading: isLoadingCreateSubscription,
  } = useCreateSubscription();
  const {
    mutate: swapCheckoutSessionIdForPaymentMethod,
    isLoading: isLoadingSwapCheckoutSessionIdForPaymentMethod,
  } = useSwapCheckoutSessionIdForPaymentMethod();
  const { data: billingPricesData, isFetching: isFetchingBillingPrices } = useBillingPrices({
    refetchOnWindowFocus: false,
  });

  const billingPrices = billingPricesData != undefined ? billingPricesData.data : [];
  // const targetBillingPrice = billingPrices.length > 0 ? billingPrices[0] : null;

  const targetBillingPrice =
    billingPrices.length > 0
      ? billingPrices.find((price) => price.lookupKey === DEFAULT_PRICE_LOOKUP_KEY)
      : null;

  // const targetBillingPrice =
  //   billingPrices.length > 0
  //     ? billingPrices.filter(
  //         (price) =>
  //           price.nickname?.includes("Per") ||
  //           price.nickname == undefined ||
  //           !price.nickname?.includes("Test"),
  //       )[0]
  //     : null;

  const prettyBillingPrice =
    targetBillingPrice == undefined
      ? 0
      : targetBillingPrice.billingScheme === "tiered"
      ? targetBillingPrice.tiers[0].unitAmount / 100.0
      : targetBillingPrice.unitAmount / 100.0;

  const currentPriceObject = currentSubscription && currentSubscription.items.data[0].price;
  const currentProductPrice =
    currentPriceObject != undefined
      ? currentPriceObject.billingScheme === "tiered"
        ? currentPriceObject.tiers[0].unitAmount / 100.0
        : currentPriceObject.amount / 100.0
      : null;

  const hadAnActiveSubscriptionInThePast =
    currentOrganization.stripeSubscriptionInGoodStanding &&
    currentOrganization.publishedJobsCount > 0;
  const canRenewSubscription =
    hadAnActiveSubscriptionInThePast && currentOrganization.stripeDefaultPaymentMethodOnFile;

  const isTrialing =
    currentSubscription &&
    currentSubscription.status === "trialing" &&
    currentSubscription.discount != undefined;
  // const trialEndDays = isTrialing && formatMDY(currentSubscription.trialEnd);
  const trialEndDays = isTrialing && timeAgoInWordsLong(currentSubscription.trialEnd);

  const hasCoupon =
    currentSubscription &&
    currentSubscription.discount &&
    currentSubscription.discount.coupon != undefined;
  const discount = hasCoupon && currentSubscription.discount;
  const coupon = discount && discount.coupon;

  const { mutate: createStripeCustomerPortalSession } = useCreateStripeCustomerPortalSession();
  const {
    mutate: createStripeCheckoutSession,
    error: errorOnCreateCheckoutSession,
    isLoading: isLoadingCreateCheckoutSession,
  } = useCreateStripeCheckoutSession();

  React.useEffect(() => {
    const { checkout, session_id, redirect_url } = queryString.parse(props.location.search);

    window.logger("%c[AccountBilling] useEffect ", "color: #1976D2", {
      checkout,
      session_id,
      redirect_url,
      customer,
    });

    // Used when adding a payment method using Checkout flow
    if (checkout === "success" && session_id != undefined) {
      swapCheckoutSessionIdForPaymentMethod(
        {
          sessionId: session_id,
        },
        {
          onSuccess: (data) => {
            window.logger(
              "%c[AccountBilling] swapCheckoutSessionIdForPaymentMethod onSuccess",
              "background: orange; color: #1976D2",
              data,
            );
          },
        },
      );
    }
  }, []);

  window.logger("%c[AccountBilling] render", "background: #EFDDEF; color: #1976D2", {
    props,
    location,
    hasActiveSubscription,
    customer,
    billingPricesData,
    // targetPlan,
    currentPriceObject,
    currentProductPrice,
    targetBillingPrice,
    currentSubscription,
    currentOrganization,
    // currentPeriodEndDate,
    canRenewSubscription,
    publishedJobsCount,
    isTrialing,
    trialEndDays,
    // currentPeriodEndDateISO,
  });

  const handleCreateCheckoutSetupSession = () => {
    createStripeCheckoutSession(
      {
        successUrl: `/hire/settings/billing`,
        cancelUrl: `/hire/settings/billing`,
        locationPathname: location.pathname,
        checkoutMode: "setup",
      },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[AccountBilling] completed creation of Checkout Session",
            "background: orange; color: #1976D2",
            data,
          );
          (window as any).STRIPE.redirectToCheckout({
            sessionId: data.id,
          }).then(function (result) {
            window.logger(
              "%c[AccountBilling] REDIRECTING",
              "background: green; color: white",
              result,
            );
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        },
      },
    );
  };

  const handleCreateCheckoutSubscriptionSession = () => {
    if (targetBillingPrice != undefined) {
      createStripeCheckoutSession(
        {
          priceId: targetBillingPrice.id,
          successUrl: `/hire/settings/billing`,
          cancelUrl: `/hire/settings/billing`,
          locationPathname: location.pathname,
          checkoutMode: "subscription",
        },
        {
          onSuccess: (data) => {
            window.logger(
              "%c[AccountBilling] completed creation of Subscription Checkout Session",
              "background: orange; color: #1976D2",
              data,
            );
            (window as any).STRIPE.redirectToCheckout({
              sessionId: data.id,
            }).then(function (result) {
              window.logger(
                "%c[AccountBilling] REDIRECTING",
                "background: green; color: white",
                result,
              );
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
          },
        },
      );
    } else {
      addToast({
        title:
          "We were unable to find an active plan, this is not right so please reach out to support@polymer.co and we will make sure we take care of you",
        kind: "warning",
      });
    }
  };

  const handleCreateBillingPortalSession = () => {
    createStripeCustomerPortalSession(
      { returnUrl: "/hire/settings/billing" },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountContainer] ", "color: #1976D2", { data });
          window.location.href = data.redirectUrl;
        },
      },
    );
  };

  const handleCreateSubscription = () => {
    createSubscription(
      { priceId: targetBillingPrice.id },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountBilling] createSubscription SUCCESS", "color: #19d219", {
            data,
          });
          addToast({
            title: "Subscription activated",
            kind: "success",
          });
        },
        onError: (response) => {
          window.logger("%c[AccountBilling] createSubscription FAILURE", "color: #990f6b", {
            response,
          });
          addToast({
            title: "Unable to renew subscription",
            kind: "warning",
          });
        },
      },
    );
  };

  const handleOnClickUsePromoCode = (event) => {
    event.preventDefault();

    const modal = <PromoCodeCenterModal onCancel={() => removeModal()} />;

    openModal(modal);
  };

  const manageBillingButton = (ctaCopy, mode = "checkoutSession") => (
    <Button
      styleType="secondary"
      onClick={
        mode == "checkoutSession"
          ? handleCreateCheckoutSetupSession // checkoutSession
          : handleCreateBillingPortalSession // billingPortal
      }
    >
      {ctaCopy}
    </Button>
  );

  const noPlanPrice =
    targetBillingPrice == undefined ? null : (
      <Styled.PricingPrice>
        <h3>${prettyBillingPrice}</h3>
        <span>
          per published job post <br />
          per {targetBillingPrice.interval || targetBillingPrice.recurring.interval}
        </span>
      </Styled.PricingPrice>
    );

  const viewLink = (
    <Button type="externalLink" link="https://polymer.co/pricing" styleType="text">
      View pricing page
      <Icon name="external-link" />
    </Button>
  );

  if (isFetchingStripeCustomer) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <Helmet title="Plan & billing" />
      <SettingsContainer
        title="Plan & billing"
        description={
          "With our per-job pricing, you only pay for active jobs that have been published to your job board. If a job post you’ve already paid for becomes inactive within a month, we’ll add a prorated credit to your account for the unused time."
        }
        actions={viewLink}
      >
        {!hasActiveSubscription && publishedJobsCount === 0 ? (
          customer && customer.invoiceSettings.defaultPaymentMethod == undefined ? (
            <Styled.Pricing>
              {noPlanPrice}
              {manageBillingButton("Add payment method", "checkoutSession")}
            </Styled.Pricing>
          ) : (
            <Styled.Subscription>
              <Styled.SubscriptionRow>
                <div>
                  <h3>No published jobs</h3>
                  <span>$0 per month (${prettyBillingPrice} each)</span>
                </div>
                {manageBillingButton("Manage billing", "billingPortal")}
              </Styled.SubscriptionRow>
            </Styled.Subscription>
          )
        ) : null}

        {!hasActiveSubscription &&
        // currentOrganization.stripeSubscriptionStatus != undefined &&
        publishedJobsCount > 0 ? (
          <Styled.Pricing>
            {noPlanPrice}
            <Styled.ButtonWrapper>
              {manageBillingButton("Manage billing", "billingPortal")}
              {canRenewSubscription ? (
                <Button onClick={handleCreateSubscription} loading={isLoadingCreateSubscription}>
                  Restart subscription
                </Button>
              ) : (
                <Button onClick={handleCreateCheckoutSubscriptionSession}>
                  Start subscription
                </Button>
              )}
            </Styled.ButtonWrapper>
          </Styled.Pricing>
        ) : null}

        {/* Has Active Subscription
        --===================================================-- */}

        {hasActiveSubscription ? (
          <>
            <Styled.Subscription>
              <Styled.SubscriptionRow>
                <div>
                  {currentOrganization.plan === "plan_simple_ats_per_job" ? (
                    <>
                      <h3>
                        {currentSubscription?.quantity} published job{" "}
                        {pluralize("post", currentSubscription?.quantity)}
                      </h3>
                      <span>
                        {isTrialing && !hasCoupon && `Free for ${trialEndDays} then `}$
                        {currentProductPrice * (currentSubscription?.quantity || 0)} per month ($
                        {currentProductPrice} each)
                      </span>
                    </>
                  ) : currentOrganization.plan === "plan_simple_ats_paid" ? (
                    <>
                      <h3>Unlimited published job posts</h3>
                      <span>
                        $
                        {currentPriceObject?.unitAmount != undefined
                          ? currentPriceObject?.unitAmount / 100.0
                          : currentProductPrice}{" "}
                        per {currentPriceObject?.recurring.interval} ‧ Locked in until canceled
                      </span>
                    </>
                  ) : null}
                </div>

                <Styled.SubscripionActions>
                  {hasActiveSubscription &&
                    !hasCoupon &&
                    currentOrganization.stripePromoCode == undefined && (
                      <Styled.SubscriptionMenu>
                        <DropdownMenu>
                          <Button onClick={handleOnClickUsePromoCode} styleType="text">
                            Use promo code
                          </Button>
                        </DropdownMenu>
                      </Styled.SubscriptionMenu>
                    )}

                  {manageBillingButton("Manage billing", "billingPortal")}
                </Styled.SubscripionActions>
              </Styled.SubscriptionRow>

              {hasCoupon && (
                <Styled.Promo>
                  <Styled.PromoTitle>
                    <h4>{coupon.name}</h4>
                    <Styled.PromoBadge>Promo code</Styled.PromoBadge>
                  </Styled.PromoTitle>
                  <span>
                    {coupon.percentOff}% off until {prettyDate(discount.end)}
                  </span>
                </Styled.Promo>
              )}
            </Styled.Subscription>
          </>
        ) : null}
      </SettingsContainer>
    </>
  );
}

AccountBilling.propTypes = {};

AccountBilling.defaultProps = {};

export default AccountBilling;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Pricing = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_Pricing;
    ${[t.rounded.md, t.py(4), t.mt(8), t.px(4)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
});

Styled.PricingPrice = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PricingPrice;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[200] : t.color.black};

    h3 {
      ${[t.text.bold, t.text.xxxl]}
      font-size: ${t.spacing[8]}
    }

    span {
      ${[t.text.xs, t.ml(2), t.mt("-px"), t.mb("px")]}
      line-height: 1.4;
    }
  `;
});

Styled.ButtonWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_ButtonWrapper;
    display: flex;
    align-items: center;

    button {
      ${t.ml(3)}
    }
  `;
});

Styled.Subscription = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_Subscription;
    ${[t.rounded.md, t.mt(8)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
  `;
});

Styled.SubscriptionRow = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscriptionRow;
    ${[t.p(4)]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      ${[t.mb(1)]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    > div > span {
      ${[t.text.xs]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.SubscripionActions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscripionActions;
    display: flex;
  `;
});

Styled.SubscriptionMenu = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_SubscriptionMenu;
    ${[t.pr(3)]}
  `;
});

Styled.Promo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_Promo;
    ${[t.px(4), t.h(12)]}
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      ${[t.text.xs]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.PromoTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PromoTitle;
    ${[t.mr(4)]};
    display: flex;
    align-items: center;
    line-height: 1.4;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.PromoBadge = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PromoBadge;
    ${[t.h(4), t.ml(2), t.rounded.xs]};
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    font-size: 0.625rem;
    color: ${t.color.black};
    border: 1px solid ${t.color.orange[200]};
    background: ${t.dark
      ? "linear-gradient(120deg, #FBD7FF -40%, #FFDEC1 80%)"
      : "linear-gradient(120deg, #FFD8D8 -40%, #FFDEC1 80%)"};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `;
});

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useSpring, animated } from "react-spring";

import JobList from "@ats/src/views/jobs/JobList";
import AccountContainer from "@ats/src/views/accountAdmin/AccountContainer";
import NotificationsContainer from "@ats/src/views/notifications/NotificationsContainer";
import ReviewKitContainer from "@ats/src/views/reviews/ReviewKitContainer";
import AdminContainer from "@ats/src/views/admin/AdminContainer";
import JobContainer from "@ats/src/views/jobApplications/JobContainer";
import InterviewerPage from "@ats/src/views/interviewer/InterviewerPage";

import AppHeader from "@ats/src/views/layouts/AppHeader";
import { WebsocketProvider } from "@ats/src/websockets/WebsocketContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import Banner from "@ats/src/components/shared/Banner";
import { useGlobalChannelContext } from "@ats/src/context/GlobalChannelContext";
import PolymerBar from "@shared/components/PolymerBar";
import MaintenanceCompleteModal from "@ats/src/components/modals/MaintenanceCompleteModal";

// import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

function AppContainer(props) {
  const { isLoadingOrganization } = props;
  const { currentUser, currentOrganizationUser, currentOrganization } = useCurrentSession();
  const { updateAvailable, maintenanceComplete } = useGlobalChannelContext();

  const spring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const handleNavigationToAccountSettings = () => {
    if (location.pathname === "/hire/settings") {
      if (currentOrganizationUser.isAdmin) {
        return (
          <Redirect to={{ pathname: "/hire/settings/organization", state: props.location.state }} />
        );
      } else {
        return (
          <Redirect to={{ pathname: "/hire/settings/preferences", state: props.location.state }} />
        );
      }
    }
  };

  const handleInterviewerRoleNavigation = () => {
    const authorizedRoute = location.pathname.includes("reviews/new") || location.pathname.includes("interviewer");
    if (currentOrganizationUser.role === "org_interviewer" && !authorizedRoute) {
      return <Redirect to="/interviewer?message=error" />;
    }
  };

  if (currentUser == undefined) {
    return null;
  }

  return (
    <WebsocketProvider>
      <Styled.Container>
        <PolymerBar />
        {updateAvailable && (
          <Banner
            loud
            message={
              <span>
                An update is available. <a href="javascript:window.location.reload()">Refresh</a>{" "}
                the page to receive the latest changes.
              </span>
            }
          />
        )}
        {maintenanceComplete && <MaintenanceCompleteModal />}
        <AppHeader
          {...props}
          currentUser={currentUser}
          currentOrganization={currentOrganization}
          isLoadingOrganization={isLoadingOrganization}
        />
        <Styled.Content style={spring}>
          {isLoadingOrganization ? null : ( // Not sure this does anything anymore since we are using isLoadingOrganization in AppAuthed
            <Switch>
              {handleInterviewerRoleNavigation()}
              {handleNavigationToAccountSettings()}
              <Route
                path="/jobs"
                render={(renderProps) => <JobList {...renderProps} />}
                exact={true}
              />
              <Route
                path="/jobs/archived"
                render={(renderProps) => (
                  <JobList
                    {...renderProps}
                    meta={{
                      status: "status_archived",
                      adminOnly: true,
                    }}
                  />
                )}
                exact={true}
                // meta={{
                //   status: "status_archived",
                //   adminOnly: true,
                // }}
              />
              <Route
                path="/jobs/all"
                render={(renderProps) => (
                  <JobList
                    {...renderProps}
                    meta={{
                      adminOnly: true,
                    }}
                  />
                )}
                exact={true}
                // meta={{
                //   adminOnly: true,
                // }}
              />
              <Route
                path="/jobs/:jobId"
                render={(renderProps) => <JobContainer {...renderProps} />}
                exact={false}
              />
              <Route
                path="/hire/settings/:accountView"
                render={(renderProps) => <AccountContainer {...renderProps} />}
                exact={false}
              />
              <Route
                path="/notifications"
                render={(renderProps) => <NotificationsContainer {...renderProps} />}
                exact={true}
              />
              <Route
                path="/applicants/:jobApplicationHashId/reviews/:commentId"
                render={(renderProps) => <ReviewKitContainer {...renderProps} />}
                exact={true}
              />
              <Route
                path="/admin/:adminView"
                render={(renderProps) => <AdminContainer {...renderProps} />}
                exact={true}
              />
              <Route
                path="/interviewer"
                render={(renderProps) => <InterviewerPage {...renderProps} />}
                exact={true}
              />
              {/* {renderRoutes(props.matchUrl, props)} */}
            </Switch>
          )}
        </Styled.Content>
      </Styled.Container>
    </WebsocketProvider>
  );
}

export default AppContainer;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AppContainer;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.Content = styled(animated.div)`
  label: AppContainer_Content;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
`;

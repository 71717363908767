import React from "react";
import createPersistedState from "use-persisted-state";

type State = { isDarkMode: boolean; toggleMode: () => void };
type ColorSchemeProviderProps = { children: React.ReactNode };

const ColorSchemeContext = React.createContext<State | undefined>(undefined);

export const usePolymerThemeState = createPersistedState("customTheme"); // system, light, dark

function ColorSchemeProvider({ children }: ColorSchemeProviderProps) {
  const customThemeState = usePolymerThemeState(null);
  const [customTheme, setCustomTheme] = customThemeState;

  const [isSystemDarkMode, setIsSystemDarkMode] = React.useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  React.useEffect(() => {
    if (customTheme == undefined) {
      setCustomTheme("system");
    }

    const handleModeChange = (e) => {
      // const newColorScheme = e.matches ? "dark" : "light";
      setIsSystemDarkMode(e.matches);
    };

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleModeChange);

    return window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", handleModeChange);
  }, []);

  const toggleMode = () => {
    setIsSystemDarkMode(!isSystemDarkMode);
  };

  const isDarkMode =
    customTheme == undefined || customTheme === "system"
      ? isSystemDarkMode
      : customTheme === "dark";

  return (
    <ColorSchemeContext.Provider value={{ isDarkMode, toggleMode }}>
      {children}
    </ColorSchemeContext.Provider>
  );
}

function useColorSchemeContext() {
  const context = React.useContext(ColorSchemeContext);
  if (context === undefined) {
    throw new Error("useColorSchemeContext must be used within a ColorSchemeProvider");
  }
  return context;
}

export { ColorSchemeProvider, useColorSchemeContext, ColorSchemeContext };

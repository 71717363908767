import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import textStyles from "@ats/styles/text";

import { prettyDateAndTime } from "@shared/lib/time";
import { reviewRatingOptions, getOptionLabel } from "@ats/src/lib/lookups";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import UserAvatar from "@ats/src/components/shared/UserAvatar";
import Tooltip from "@ats/src/components/shared/Tooltip";

import { useDeleteComment } from "@shared/queryHooks/useComment";

import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function ReviewListItem({
  jobApplicationId,
  id,
  body,
  rating,
  // kind,
  hashId,
  organizationUser,
  jobApplication,
  publishedAtTimestamp,
  publishedAtTimeAgoShort,
  orgAdminJobsListUrl
}) {
  const { openModal, removeModal } = useModalContext();
  const { currentUser } = useCurrentSession();
  const { user } = organizationUser;

  const {
    mutate: deleteComment,
    error: errorOnDelete,
    isLoading: isLoadingDelete,
  } = useDeleteComment();

  const handleOnClickDeleteReview = () => {

    const deleteMessage = `This review will be permanently deleted.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this review?"
        subcopy={deleteMessage}
        confirmText="Delete review"
        cancelText="Keep review"
        onConfirm={() => {
          removeModal();
          handleDeleteReview();
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    window.logger("%c[ReviewListItem] handleOnClickDeleteReview", "background-color: #82d219", {
      jobApplicationId,
      id,
      body,
      rating,
    });

    openModal(modal);
  };

  const handleDeleteReview = async () => {

    try {
      await deleteComment({
        jobApplicationId,
        id,
        body,
      });

    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[ReviewListItem] handle error when deleting Comment", "color: #FF7602", {
        error,
      });
    }
  };

  return (
    <Styled.Review>
      <Styled.Event>
        <div>
          <Styled.Avatar>
            <UserAvatar size="sm" user={user} />
          </Styled.Avatar>
          {user.fullName} left a review
          <Styled.Seperator>‧</Styled.Seperator>
          <Tooltip label={prettyDateAndTime(publishedAtTimestamp)}>
            <span>{publishedAtTimeAgoShort}</span>
          </Tooltip>
        </div>

        {currentUser.id === user.id ? (
          <div>
            <Link
              to={{
                pathname: `/applicants/${jobApplication?.hashId}/reviews/${hashId}`,
                state: {
                  orginalPathname: location.pathname, // tells ReviewKit about the back button
                  orgAdminJobsListUrl: orgAdminJobsListUrl, // persists value to ReviewKit when accessed via "Edit" button
                },
              }}
            >
              Edit
            </Link>
            <button onClick={handleOnClickDeleteReview}>Delete</button>
          </div>
        ) : null}
      </Styled.Event>

      <Styled.Content dangerouslySetInnerHTML={{ __html: body }} />
      {rating && <Styled.Rating>{getOptionLabel(reviewRatingOptions, rating)}</Styled.Rating>}
      {/* <button onClick={handleOnClickEditReview}>Edit</button> */}
    </Styled.Review>
  );
}

export default ReviewListItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Event = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: ReviewListItem_Event;
    ${[t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      svg {
        ${t.mr(2)}
      }
    }

    button,
    a {
      ${[t.pl(3), t.text.xs]}
      justify-self: flex-end;
      background: none;
      border: none;
      outline: none;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  `;
});

Styled.Review = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: ReviewListItem_Comment;
    ${[t.mt(4), t.mb(4), t.p(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 1.75rem;
      width: 4px;
      margin-left: -2px;
      height: ${t.spacing[6]};
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
  `;
});

Styled.Content = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: ReviewListItem_Content;
    ${[textStyles]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    ${t.dark &&
    `
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}
  `;
});

Styled.Rating = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: ReviewListItem_Content;
    ${[t.px(2), t.mt(1), t.mb(3), t.rounded.xs, t.text.xs]}
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    display: inline-flex;
    align-items: center;
    line-height: 1.4;
    border: 1px solid ${t.dark ? t.color.gray[600] : t.color.orange[200]};
    background: ${t.dark ?
      t.color.gray[600]
      // "linear-gradient(120deg, #D6D7E5 0%, #F3E2CB 100%)"
      :
      "linear-gradient(120deg, #FFD8D8 -40%, #FFDEC1 80%)"
    };
    color: ${t.dark ? t.color.white : t.color.black};
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewListItem_Seperator;
    ${t.mx(1)}
  `;
});

Styled.Avatar = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewListItem_Avatar;
    ${t.mr(2)}
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Button from "@ats/src/components/shared/Button";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import { useModalContext } from "@shared/context/ModalContext";
import AdminNewFeatureFlagModal from "./AdminNewFeatureFlagModal";

import { useFeatureFlippers, useEnableFeature, useDisableFeature, useDeleteFeatureFlag } from "@shared/queryHooks/useFeatureFlippers";

function AdminFeatureFlippers(props: any) {
  const { openModal, removeModal } = useModalContext();

  window.logger("%c[AdminFeatureFlippers] render", "color: #1976D2", {
    props
  });

  /* Query Hooks
  ===================================================-- */
  const { data: featureFlagsData, isLoading: isLoadingFeatureFlippers } = useFeatureFlippers();
  const { mutate: enableFeature, isLoading: isLoadingEnableFeature } = useEnableFeature();
  const { mutate: disableFeature, isLoading: isLoadingDisableFeature } = useDisableFeature();
  const { mutate: deleteFeatureFlag, isLoading: isLoadingDeleteFeatureFlag } = useDeleteFeatureFlag();


  /* Log GET data */
  if (featureFlagsData != undefined) {
    window.logger("%c[AdminFeatureFlippers] featureFlagsData", "color: #82d219", {
      featureFlagsData,
    });
  }


  /* ENABLE/DISABLE feature
  =================================================== */
  /* Handle Enable Feature */
  const handleEnableFeature = async (featureFlagKey) => {
    try {
      await enableFeature({
        featureFlag: featureFlagKey
      });
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[AdminFeatureFlippers] handle error when enabling feature", "color: #FF7602", {
        error,
      });
    }
  };

  /* Handle Disable Feature */
  const handleDisableFeature = async (featureFlagKey) => {
    try {
      await disableFeature({
        featureFlag: featureFlagKey
      });
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[AdminFeatureFlippers] handle error when disabling feature", "color: #FF7602", {
        error,
      });
    }
  };

  /* Handle Toggle Feature Flag  */
  const handleChangeFeatureFlagState = (featureFlagKey, featureFlagState) => {
    if (featureFlagState === 'on') {
      handleDisableFeature(featureFlagKey)
    } else if (featureFlagState === 'off') {
      handleEnableFeature(featureFlagKey)
    }
  }

  /* Handle OnClick (open confirmation modal) */
  const handleOnClickChangeState = (featureFlagKey, featureFlagState) => {
    const enableMessage = `Are you sure you want to ENABLE ${featureName(featureFlagKey)}?`;
    const disableMessage = `Are you sure you want to DISABLE ${featureName(featureFlagKey)}?`
    const subMessage = featureFlagState === 'on' ? disableMessage : enableMessage
    const modal = (
      <ConfirmationModal
        title="Confirm Action"
        subcopy={subMessage}
        confirmText="Continue"
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          handleChangeFeatureFlagState(featureFlagKey, featureFlagState);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    window.logger("%c[ChangeFeatureStateModal] handleOnClickChangeState", "color: #82d219", {
      featureFlagKey,
      featureFlagState
    });

    openModal(modal);
  };

  /* CREATE new feature flag
  =================================================== */
  const handleClickCreateFeatureFlag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const modal = <AdminNewFeatureFlagModal history={props.history} onCancel={() => removeModal()} />;

    openModal(modal);
  };

  const createFeatureFlagButton = (
    <Styled.Button>
      <Button
        styleType="secondary"
        onClick={handleClickCreateFeatureFlag}
        data-testid="create-feature-flag-button"
      >
        Add feature flag
      </Button>
    </Styled.Button>
  )

  /* DELETE a feature flag
  =================================================== */
  /* Handle Delete Feature Flag */
  const handleDeleteFeatureFlag = async (featureFlagKey) => {
    try {
      await deleteFeatureFlag({
        featureFlag: featureFlagKey
      });
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[AdminFeatureFlippers] handle error when deleting feature", "color: #FF7602", {
        error,
      });
    }
  };

  /* Handle OnClick (open confirmation modal) */
  const handleOnClickDeleteFeatureFlag = (featureFlagKey) => {

    const subMessage = `Are you sure you want to delete ${featureFlagKey}? You can DISABLE a feature instead to hide the feature.`
    const modal = (
      <ConfirmationModal
        title="Confirm DELETION of feature flag"
        subcopy={subMessage}
        confirmText="DELETE FEATURE FLAG"
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          props.history.push("/admin/flippers");
          handleDeleteFeatureFlag(featureFlagKey);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={true}
      />
    );

    window.logger("%c[DeleteFeatureFlagConfirmationModal]", "color: #DC2626", {
      featureFlagKey
    });

    openModal(modal);
  };

  /* Rendering UI
  =================================================== */
  //return readable feature name for UI
  const featureName = (featureFlagKey) => {
    let splitFlagName = featureFlagKey.toLowerCase().split('_')
    let capitals = splitFlagName.map(word => {
      return word.charAt(0).toUpperCase() + word.substring(1)
    })
    return capitals.join(' ')
  }

  //actual list items
  const featureFlagNodes = () => {
    if (featureFlagsData == undefined) {
      return [];
    }
    const featureFlags = featureFlagsData?.features
    //sort alphabetically to have stable order for index for table key (no id returned in data)
    const alphabeticallySortedFeatureFlags = featureFlags.sort((a, b) => a.key.localeCompare(b.key))

    window.logger("%c[FlipperFeatureFlagsAdmin] featureFlagNodes", "background-color: #82d219", {
      alphabeticallySortedFeatureFlags
    });

    return alphabeticallySortedFeatureFlags.map(function (featureFlag, index) {

      /* ONLY RENDER ENABLE/DISABLE & DELETE & ADD BUTTONS IN DEV ENV */
      return (
        <tr className="admin-featureFlag-list__item" key={index}>
          <td>{featureName(featureFlag?.key)}</td>
          <td>{featureFlag?.key}</td>
          <td style={{ color: `${featureFlag?.state === 'on' ? "green" : "red"}`, fontWeight: 'bold' }}>
            {featureFlag?.state === 'on' ? "Enabled" : "Disabled"}
          </td>
          <td>
            <Styled.Button>
              <Button
                type="button"
                styleType="secondary"
                onClick={() => handleOnClickChangeState(featureFlag?.key, featureFlag?.state)}
              >
                {featureFlag?.state === 'on' ? "Disable" : "Enable"}
              </Button>
            </Styled.Button>
          </td>
          <td>
            <Button
              type="button"
              styleType="text"
              onClick={() => handleOnClickDeleteFeatureFlag(featureFlag?.key)}
              dangerous={true}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };


  return (
    <Styled.Component>
      <Styled.Header>
        <h2>Feature Flippers</h2>
        {createFeatureFlagButton}
      </Styled.Header>

      <Styled.Table>
        <tbody>
          <tr>
            <th>Feature Name</th>
            <th>Feature Flag</th>
            <th>State</th>
            <th>Change State</th>
            <th></th>
          </tr>
          {featureFlagNodes()}
        </tbody>
      </Styled.Table>
    </Styled.Component>
  );
}

export default AdminFeatureFlippers;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminfeatureFlags;
    ${t.p(4)};
  `;
});

Styled.Header = styled.header((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(2), t.pb(2)]}
    label: AdminfeatureFlagsSearch_Header;
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid ${t.color.gray[200]};

    h2 {
      ${[t.mt(1), t.text.h3]};
      display: flex;
      align-items: center;
    }
  `;
});

Styled.Table = styled.table((props) => {
  const t: any = props.theme;
  return css`
    label: AdminFeatureFlags_Table;
    border-collapse: collapse;
    width: 100%;

    th {
      ${[t.pb(2), t.text.s, t.text.bold]};
      text-align: start;
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.primary};
    }

    tr {
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.secondary};
    }

    tr:last-of-type {
      border-bottom: none;
    }

    svg {
      stroke: ${t.color.black};
    }

    td,
    th {
      ${[t.p(2)]};

      &:first-of-type {
        ${t.pl(0)}
      }

      &:last-of-type {
        ${t.pr(0)}
      }
    }
  `;
});

Styled.Button = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FeatureFlag_Button;
  
    > button {
      ${t.text.sm}
    }
  `;
});
import React from "react";
import { useQueryClient } from "react-query"; // Used to clear cache on logout

import { useLogout } from "@shared/queryHooks/useSession";

function Logout(props) {
  window.logger("%c[Logout] render", "color: #1976D2", { ...props });
  const queryCache = useQueryClient();
  const { mutate: logout } = useLogout();

  React.useEffect(() => {
    window.logger("%c[Logout] useEffect", "color: #1976D2");
    logout(null, {
      onSuccess: () => {
        queryCache.clear();
        const search = new URLSearchParams(window.location.search);
        const path = search?.has("path") ? search.get("path") : null;
        // window.location.href = `${window.APP_ATS_ROOT_URL}/auth`; // This is a hard refresh of the page to clear console and make sure App component refreshes
        if (path) {
          props.history.push(`/auth?path=${path}`);
        } else {
          props.history.push("/auth");
        }
      },
    });
  }, []);

  return null;
}

export default Logout;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormCheckbox from "@ats/src/components/forms/FormCheckbox";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormLabel from "@ats/src/components/forms/FormLabel";
import CopyField from "@ats/src/components/shared/CopyField";

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  linkedInCompanyId: number | string;
};

function LinkedinIdConfirmationModal(props: Props) {
  const { onConfirm, onCancel, linkedInCompanyId } = props;
  const [confirmedId, setConfirmedId] = React.useState(false);

  const title = "Confirm LinkedIn ID";
  const subcopy =
    "Confirm your LinkedIn Company ID by opening the link below to verify that it leads to your LinkedIn company page.";
  const confirmText = "Confirm";

  const handleOnClickCheckbox = () => {
    setConfirmedId(!confirmedId);
  };

  const modalButtons = (
    <Styled.ButtonContainer>
      <Button onClick={onConfirm} disabled={!confirmedId}>
        {confirmText}
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const companyPageUrl = `https://www.linkedin.com/company/${linkedInCompanyId}`;

  return (
    <CenterModal headerTitleText={title} onCancel={onCancel}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <FormContainer buttons={modalButtons} onSubmit={onConfirm}>
        <FormLabel label="LinkedIn Company Page Url" />
        <CopyField value={companyPageUrl} label="Copy URL" isLink={true} />
        {/* <Styled.Link>
          <Icon name="linkedin" />
          <a
            href={`https://www.linkedin.com/company/${linkedInCompanyId}`}
            target="_blank"
            rel="noreferrer"
          >
            {`https://www.linkedin.com/company/${linkedInCompanyId}`}
          </a>
        </Styled.Link> */}
        <FormCheckbox
          name="confirmedId"
          label="The link works"
          checked={confirmedId}
          onChange={handleOnClickCheckbox}
        />
      </FormContainer>
    </CenterModal>
  );
}

export default LinkedinIdConfirmationModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: LinkedinIdConfirmationModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LinkedinIdConfirmationModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.Link = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LinkedinIdConfirmationModal_Link;
    ${[t.ml(2), t.mb(4)]}
    display: flex;
    align-items: center;
    height: 32px;
    svg {
      ${[t.mr(2)]}
      flex-shrink: 0;
    }
    span,
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.4rem;
    }
    a:hover {
      text-decoration: underline;
    }
  `;
});


import React from "react";
import toLower from "lodash/toLower";
import queryString from "query-string";

import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";
import Button from "@ats/src/components/shared/Button";

import { validateRegistration } from "@shared/lib/validateWithYup";

import { useRegister } from "@shared/queryHooks/useSession";
import { useGetInvite } from "@shared/queryHooks/useInvite";

function SignupForm(props) {
  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const inviteToken = queryString.parse(props.location.search).invite_token;
  const [referral, setReferral] = React.useState(queryString.parse(props.location.search).referral);
  const [errors, setErrors] = React.useState(null);

  const { firstName, lastName, email, password } = state;
  const { mutate: register, isLoading } = useRegister();
  const { data: inviteData, isLoading: isLoadingInvite } = useGetInvite(inviteToken);

  const inviteEmail = inviteData?.email;
  React.useEffect(() => {
    if (inviteEmail != undefined) {
      setState((state) => ({ ...state, email: inviteEmail }));
      setReferral("invite");
    }
  }, [inviteEmail]);

  const handleSignup = async (e) => {
    e.preventDefault();
    window.logger("%c[SignupForm] handleSignup state", "color: #1976D2", state);

    setErrors(null);

    const [isValid, validationErrors] = await validateRegistration({
      firstName,
      lastName,
      email,
      password,
    });

    if (isValid) {
      register(
        {
          firstName,
          lastName,
          email: toLower(email),
          password,
          inviteToken,
          referral,
        },
        {
          onSuccess: (data) => {
            window.logger("[SignupForm] register onScucess", { data });
            props.onComplete();
          },
          onError: (response: any) => {
            window.logger("[SignupForm] server error", { response });
            if (response.data.errors) {
              setErrors(response.data.errors);
            }
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (name, value) => {
    setErrors(null);
    setState({ ...state, [name]: value });
  };

  const button = (
    <Button type="submit" disabled={isLoading} loading={isLoading} isTracked={false}>
      Sign up
    </Button>
  );

  return (
    <FormContainer onSubmit={handleSignup} errors={errors} buttons={button}>
      <FormInput
        onChange={handleInputChange}
        name="firstName"
        placeholder=""
        label="First name"
        className=""
        errors={errors}
      />

      <FormInput
        onChange={handleInputChange}
        name="lastName"
        placeholder=""
        label="Last name"
        className=""
        errors={errors}
      />

      <FormInput
        onChange={handleInputChange}
        isDisabled={inviteToken != null}
        name="email"
        placeholder=""
        label="Email address"
        inputType="email"
        className=""
        value={email}
        errors={errors}
      />

      <FormInput
        onChange={handleInputChange}
        name="password"
        placeholder=""
        label="Password"
        className=""
        inputType="password"
        errors={errors}
      />
    </FormContainer>
  );
}

export default SignupForm;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import FloatingToolbar from "@shared/ProseMirror/FloatingToolbar";
// import FormInput from "@ats/src/components/forms/FormInput";
import Icon from "@ats/src/components/shared/Icon";

import { markActive } from "@shared/ProseMirror/config/menu";
import schema from "@shared/ProseMirror/config/schema";
import { toggleMark } from "prosemirror-commands";

import { useProseMirrorEditorStore } from "@ats/src/lib/store/zustand/proseMirrorEditorStore";

function LinkToolbar({ viewRef, state, dispatch }) {
  const editorState = state;
  const [linkAttrs, clearLinkStore] = useProseMirrorEditorStore((state) => [
    state.linkAttrs,
    state.clearLinkStore,
  ]);

  const [url, setUrl] = React.useState(linkAttrs.href || "");

  const handleOnChangeUrl = (event) => {
    event.preventDefault();
    setUrl(event.currentTarget.value);
  };

  React.useEffect(() => {
    setUrl(linkAttrs.href || "");
  }, [linkAttrs.href]);

  const handleSaveUrl = (href) => {
    let url = href;
    // setLinkToolbarIsActive(false);
    // hideLinkToolbar();
    clearLinkStore();

    // Remove the link if the URL is empty
    if (url === "") {
      toggleMark(schema.marks.link, { href: url })(editorState, dispatch);
      return true;
    }

    window.logger(
      "%c[ProseMirror][LinkToolbar] handleSaveUrl LinkToolbar focus",
      "color: #5dad11",
      {
        url,
        isLinkActive: markActive(schema.marks.link)(editorState),
      },
    );

    // Checks if this is already a link, if so then we just want to replace it
    if (markActive(schema.marks.link)(editorState)) {
      // toggleMark(schema.marks.link)(state, dispatch);
      // return true;

      // This effectively replaces the current Link with a new one
      dispatch(
        editorState.tr.addMark(
          editorState.selection.from,
          editorState.selection.to,
          schema.marks.link.create({ href: url }),
        ),
      );

      return true;
    }

    if (!url) return false;

    if (url && !/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }

    toggleMark(schema.marks.link, { href: url })(editorState, dispatch);
  };

  return (
    <FloatingToolbar viewRef={viewRef} state={state}>
      <Styled.LinkToolbarMenuItems>
        <Styled.Input
          type="text"
          name="url"
          placeholder="Enter link URL"
          onChange={handleOnChangeUrl}
          onKeyDown={(event) => {
            // window.logger("%c[LinkToolbar] onKeyDown", "color: #1976D2", {
            //   event,
            //   keyCode: event.keyCode,
            //   charCode: event.charCode,
            //   key: event.key,
            // });
            if (event.key == "Enter") {
              event.preventDefault();
              event.stopPropagation();
              viewRef.current.view.focus();
              handleSaveUrl(url);
            }
          }}
          value={url || ""}
          autoFocus
        />

        <button
          type="button"
          onMouseDown={(event) => {
            event.preventDefault();
            viewRef.current.view.focus();
            handleSaveUrl(url);
            // menuSection[item].run(state, dispatch);
          }}
        >
          <Icon name="check" />
        </button>
      </Styled.LinkToolbarMenuItems>
    </FloatingToolbar>
  );
}

export default LinkToolbar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.LinkToolbarMenuItems = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;

    span {
      height: 100%;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        ${[t.h(4), t.w("px"), t.ml(2)]}
        background-color: ${t.color.gray[200]};
      }
      &:last-of-type:after {
        display: none;
      }
    }

    button {
      ${[t.h(8), t.w(8), t.rounded.sm]}
      margin: 0 0.125rem;
      color: ${t.dark ? t.color.gray[200] : t.color.gray[600]};
      background-color: transparent;
      transition: background-color 0.2s ease;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 1.25em;
        width: 1.25em;
        stroke-width: 2.25px;
      }
      &:focus {
        outline: none;
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &:active:focus {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &:hover {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
      &.active {
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
      }
    }
  `;
});

Styled.Input = styled.input((props) => {
  const t: any = props.theme;
  return css`
    background: none;
    border: none;
    outline: none;
    ${[t.h(8), t.px(2), t.rounded.sm, t.text.sm]}
    width: ${t.spacing[64]};
    text-overflow: ellipsis;
    color: ${t.dark ? t.color.white : t.color.black};
    &::placeholder {
      color: ${t.color.gray[500]};
    }
  `;
});

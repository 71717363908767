import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import BottomButtonBar from "@ats/src/components/shared/BottomButtonBar";
import Button from "@ats/src/components/shared/Button";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateJob } from "@shared/queryHooks/useJob";

function JobSetupDocument(props) {
  const { job, setIsDirty } = props;
  // const [sharedDocumentValue, setSharedDocumentValue] = React.useState(
  //   job.sharedDocumentTemplate || "",
  // );

  const editorRef = React.useRef(undefined);

  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const addToast = useToastContext();

  const handleChange = (value) => {
    setIsDirty(true);
    // setSharedDocumentValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    updateJob(
      { id: job.id, sharedDocumentTemplate: editorRef.current?.serializedState() },
      {
        onSuccess: () => {
          setIsDirty(false);
          addToast({
            title: `Saved template`,
            kind: "success",
          });
        },
        onError: () => {
          addToast({
            title: `Could not save template`,
            kind: "warning",
          });
        },
      },
    );
  };

  return (
    <Styled.Container>
      <Styled.Form>
        <ProseMirrorEditor
          ref={editorRef}
          enableStaticMenuBar
          onChange={handleChange}
          defaultValue={job.sharedDocumentTemplate || ""}
          placeholder="Provide optional content here to generate a hiring document for each applicant..."
        />

        <BottomButtonBar static>
          <Button type="button" onClick={handleSubmit} loading={isLoadingUpdateJob}>
            Save changes
          </Button>
        </BottomButtonBar>
      </Styled.Form>
      <Styled.Sidebar>
        <h3>Hiring documents</h3>
        <p>
          If content is added to your template, a hiring document will be created for each candidate that applies. Each candidate's document starts as a copy of what you create here.
        </p>
        <p>
          While optional, it can be a great place to outline your hiring process and objectives.
        </p>
      </Styled.Sidebar>
    </Styled.Container>
  );
}

export default JobSetupDocument;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDocument;
    display: flex;
    height: 100%;
  `;
});

Styled.Form = styled.form((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDocument_Form;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100vw;

    ${t.mq['lg']} {
      width: 50vw;
    }
  `;
});

Styled.Sidebar = styled.aside((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDocument_Description;
    ${t.p(4)};
    border-left: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    display: none;

    ${t.mq['lg']} {
      display: block;
      flex: 1;
    }

    h3 {
      ${[t.mt(1), t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black}
    }
    p {
      ${[t.text.xs, t.mt(3)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]}
    }
    b {
      ${[t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black}
    }
  `;
});

import React from "react";
type Payload = { activeTabIndex: number };
type Action =
  | { type: "SET_ACTIVE_TAB_INDEX"; payload: Payload }
  | { type: "FUTURE_THING"; payload: Payload };
type Dispatch = (action: Action) => void;
type State = {
  activeTabIndex: number;
  // author: string;
  // url: string;
};
type JobViewProviderProps = { children: React.ReactNode };

const JobViewStateContext = React.createContext<State | undefined>(undefined);
const JobViewDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function jobViewReducer(state, action) {
  switch (action.type) {
    case "SET_ACTIVE_TAB_INDEX": {
      return { activeTabIndex: action.payload.activeTabIndex };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function JobViewProvider({ children }: JobViewProviderProps) {
  const [state, dispatch] = React.useReducer(jobViewReducer, { activeTabIndex: undefined });
  return (
    <JobViewStateContext.Provider value={state}>
      <JobViewDispatchContext.Provider value={dispatch}>{children}</JobViewDispatchContext.Provider>
    </JobViewStateContext.Provider>
  );
}

function useJobViewState() {
  const context = React.useContext(JobViewStateContext);
  if (context === undefined) {
    throw new Error("useJobViewState must be used within a JobViewProvider");
  }
  return context;
}

function useJobViewDispatch() {
  const context = React.useContext(JobViewDispatchContext);
  if (context === undefined) {
    throw new Error("useJobViewDispatch must be used within a JobViewProvider");
  }
  return context;
}
export { JobViewProvider, useJobViewState, useJobViewDispatch };

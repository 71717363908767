import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiDelete, apiGet, apiPost, apiPut } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const updateCareersPage = async ({ id, ...careersPage }) => {
  window.logger("%c[useCareersPage] updateCareersPage\n\n\n\n", "background-color: #FF76D2", {
    careersPage,
  });
  return await apiPut({
    path: `/careers_pages/${id}`,
    variables: { careersPage },
  });
};

const getCareersPageCustomDomainStatus = async (careersPageId) => {
  window.logger("%c[useCareersPage] getCareersPageCustomDomainStatus", "background: #19d219", {
    careersPageId,
  });
  if (careersPageId != undefined) {
    return await apiGet({ path: `/careers_pages/${careersPageId}/custom_domain_status` });
  }
};

const createCustomDomain = async ({ id, customDomain, organizationId }) => {
  window.logger("%c[useCareersPage] createCustomDomain\n\n\n\n", "background-color: #FF76D2", {
    customDomain,
  });
  return await apiPost({
    path: `/careers_pages/${id}/create_custom_domain`,
    variables: { customDomain },
  });
};

const deleteCustomDomain = async ({ id, organizationId }) => {
  window.logger("%c[useCareersPage] deleteCustomDomain\n\n\n\n", "background-color: #FF76D2", {
    id,
  });
  return await apiDelete({
    path: `/careers_pages/${id}/delete_custom_domain`,
    variables: undefined,
  });
};

/* Hooks
--===================================================-- */
function useUpdateCareersPage() {
  const queryClient = useQueryClient();
  return useMutation(updateCareersPage, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCareersPage] useUpdateCareersPage onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["careersPage", data.id]);
      queryClient.invalidateQueries(["currentOrganization", data.organizationId]);
      queryClient.invalidateQueries("me");
      queryClient.refetchQueries(["careersPageCustomDomainStatus", data.id]);
      return data;
    },
    // throwOnError: true,
  });
}

function useCareersPageCustomDomainStatus(
  careersPageId,
): {
  status: any;
  data: any;
  error: any;
  refetch: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} {
  return useQuery(
    ["careersPageCustomDomainStatus", careersPageId],
    () => getCareersPageCustomDomainStatus(careersPageId),
    {
      refetchOnWindowFocus: false,
      retry: 2, // 3, retries 3 times
    },
  );
}

function useCreateCustomDomain() {
  const queryClient = useQueryClient();
  return useMutation(createCustomDomain, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCareersPage] useCreateCustomDomain onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["careersPage", mutationVariables.id]);
      queryClient.invalidateQueries(["currentOrganization", mutationVariables.organizationId]);
      queryClient.refetchQueries(["careersPageCustomDomainStatus", mutationVariables.id]);
      return data;
    },
    // throwOnError: true,
  });
}

function useDeleteCustomDomain() {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomDomain, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCareersPage] useDeleteCustomDomain onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["careersPage", mutationVariables.id]);
      queryClient.invalidateQueries(["currentOrganization", mutationVariables.organizationId]);
      queryClient.refetchQueries(["careersPageCustomDomainStatus", mutationVariables.id]);
      return data;
    },
    // throwOnError: true,
  });
}

export {
  useUpdateCareersPage,
  useCareersPageCustomDomainStatus,
  useCreateCustomDomain,
  useDeleteCustomDomain,
};

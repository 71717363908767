// import { keymap } from "prosemirror-keymap";
import { undoInputRule } from "prosemirror-inputrules";
import { undo, redo } from "prosemirror-history";
import { wrapInList, splitListItem, liftListItem, sinkListItem } from "prosemirror-schema-list";
import { Slice, Fragment, NodeRange } from "prosemirror-model";

// import { goToNextCell } from "prosemirror-tables";
import {
  baseKeymap,
  toggleMark,
  wrapIn,
  setBlockType,
  chainCommands,
  exitCode,
  joinUp,
  joinDown,
  lift,
  selectParentNode,
} from "prosemirror-commands";

import schema from "./schema";

const insertBreak = (state, dispatch) => {
  const br = schema.nodes.hard_break.create();
  dispatch(state.tr.replaceSelectionWith(br).scrollIntoView());
  return true;
};

const insertRule = (state, dispatch) => {
  const hr = schema.nodes.horizontal_rule.create();
  dispatch(state.tr.replaceSelectionWith(hr).scrollIntoView());
  return true;
};

const standardKeys = {
  "Mod-z": undo,
  "Shift-Mod-z": redo,
  Backspace: undoInputRule,
  "Mod-y": redo,
  "Alt-ArrowUp": joinUp,
  "Alt-ArrowDown": joinDown,
  "Mod-BracketLeft": lift,
  Escape: selectParentNode,
  "Mod-b": toggleMark(schema.marks.strong),
  "Mod-i": toggleMark(schema.marks.em),
  "Mod-u": toggleMark(schema.marks.underline),
  "Mod-`": toggleMark(schema.marks.code),
  "Shift-Ctrl-8": wrapInList(schema.nodes.bullet_list),
  "Shift-Ctrl-9": wrapInList(schema.nodes.ordered_list),
  "Ctrl->": wrapIn(schema.nodes.blockquote),
  "Mod-Enter": chainCommands(exitCode, insertBreak),
  "Shift-Enter": chainCommands(exitCode, insertBreak),
  "Ctrl-Enter": chainCommands(exitCode, insertBreak), // mac-only?
  Enter: chainCommands(
    splitListItem(schema.nodes.list_item),
    splitCheckboxItem(schema.nodes.checkbox_item),
  ),
  "Mod-[": liftListItem(schema.nodes.list_item),
  "Mod-]": sinkListItem(schema.nodes.list_item),
  Tab: sinkListItem(schema.nodes.list_item),
  "Shift-Tab": liftListItem(schema.nodes.list_item),
  "Shift-Ctrl-0": setBlockType(schema.nodes.paragraph),
  "Shift-Ctrl-\\": setBlockType(schema.nodes.code_block),
  "Shift-Ctrl-1": setBlockType(schema.nodes.heading, { level: 1 }),
  "Shift-Ctrl-2": setBlockType(schema.nodes.heading, { level: 2 }),
  "Shift-Ctrl-3": setBlockType(schema.nodes.heading, { level: 3 }),
  "Shift-Ctrl-4": setBlockType(schema.nodes.heading, { level: 4 }),
  "Shift-Ctrl-5": setBlockType(schema.nodes.heading, { level: 5 }),
  "Shift-Ctrl-6": setBlockType(schema.nodes.heading, { level: 6 }),
  "Mod-_": insertRule,
  // "Shift-Tab": goToNextCell(-1),
  "Shift-Ctrl-u": (stuff) => {
    window.logger("%c[ProseMirror][keys] ", "color: #1976D2", { stuff });
  },
};

const messagesKeys = {
  ...standardKeys,
  Enter: chainCommands(
    splitListItem(schema.nodes.list_item),
    removeHardBreakAndInsertParagraph(schema),
    insertHardBreak(schema),
  ),
};

Object.keys(baseKeymap).forEach((key) => {
  if (standardKeys[key]) {
    standardKeys[key] = chainCommands(standardKeys[key], baseKeymap[key]);
    messagesKeys[key] = chainCommands(messagesKeys[key], baseKeymap[key]);
  } else {
    standardKeys[key] = baseKeymap[key];
    messagesKeys[key] = baseKeymap[key];
  }
});

// const messagesKeymap = keymap(messagesKeys);

// export default keymap(keys);

export { messagesKeys, standardKeys };

/**
 * Helper function to remove hard break and insert paragraph instead.
 * @param {Object} params
 * @param {import('prosemirror-model').NodeType} params.hard_break
 * @param {import('prosemirror-model').NodeType} params.paragraph
 */
function removeHardBreakAndInsertParagraph(schema) {
  /**
   * @param {import('prosemirror-state').EditorState} state ProseMirror editor state.
   * @param {Function} dispatch Editor's dispatch function.
   */
  const { hard_break, paragraph, list_item } = schema.nodes;
  return (state, dispatch) => {
    const { $from } = state.selection;
    window.logger("%c[ProseMirror][keys] removeHardBreakAndInsertParagraph", "color: #1976D2", {
      from: $from,
    });

    if (!$from.parent.isBlock) {
      return false;
    }

    if ($from.parent.type !== paragraph) {
      return false;
    }

    if ($from.nodeBefore && $from.nodeBefore.type !== hard_break) {
      return false;
    }

    // AG
    if (!$from.nodeBefore) {
      return false;
    }

    window.logger(
      "%c[ProseMirror][keys] removeHardBreakAndInsertParagraph DONE",
      "background-color: #0FFFD2",
      {
        from: $from,
      },
    );

    if (dispatch) {
      dispatch(
        state.tr
          .delete($from.pos - $from.nodeBefore.nodeSize, $from.pos)
          .replaceSelectionWith(paragraph.create())
          .scrollIntoView(),
      );
    }

    return true;
  };
}

/**
 * Helper function to insert hard break.
 * @param {Object} params
 * @param {import('prosemirror-model').NodeType} params.hard_break
 * @param {import('prosemirror-model').NodeType} params.paragraph
 */
function insertHardBreak(schema) {
  /**
   * @param {import('prosemirror-state').EditorState} state ProseMirror editor state.
   * @param {Function} dispatch Editor's dispatch function.
   */
  const { hard_break, paragraph, list_item } = schema.nodes;

  return (state, dispatch) => {
    const { $from } = state.selection;

    window.logger("%c[ProseMirror][keys] insertHardBreak", "color: #1976D2", {
      from: $from,
    });

    if (!$from.parent.isBlock) {
      return false;
    }

    if ($from.parent.type !== paragraph) {
      return false;
    }

    // AG
    if (!$from.nodeBefore) {
      return false;
    }

    window.logger("%c[ProseMirror][keys] insertHardBreak DONE", "background-color: #FF76D2", {
      from: $from,
    });

    if (dispatch) {
      dispatch(state.tr.replaceSelectionWith(hard_break.create()).scrollIntoView());
    }

    return true;
  };
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Build a command that splits a non-empty textblock at the top level
// of a list item by also splitting that list item.
function splitCheckboxItem(itemType) {
  return function (state, dispatch) {
    let { $from, $to, node } = state.selection;
    if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) return false;
    let grandParent = $from.node(-1);
    if (grandParent.type != itemType) return false;
    if ($from.parent.content.size == 0 && $from.node(-1).childCount == $from.indexAfter(-1)) {
      // In an empty block. If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.
      if (
        $from.depth == 2 ||
        $from.node(-3).type != itemType ||
        $from.index(-2) != $from.node(-2).childCount - 1
      )
        return false;
      if (dispatch) {
        let wrap = Fragment.empty,
          keepItem = $from.index(-1) > 0;
        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (let d = $from.depth - (keepItem ? 1 : 2); d >= $from.depth - 3; d--)
          wrap = Fragment.from($from.node(d).copy(wrap));
        // Add a second list item with an empty default start node
        wrap = wrap.append(Fragment.from(itemType.createAndFill()));
        let tr = state.tr.replace(
          $from.before(keepItem ? null : -1),
          $from.after(-3),
          new Slice(wrap, keepItem ? 3 : 2, 2),
        );
        tr.setSelection(
          state.selection.constructor.near(tr.doc.resolve($from.pos + (keepItem ? 3 : 2))),
        );
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
    let nextType = $to.pos == $from.end() ? grandParent.contentMatchAt(0).defaultType : null;
    let tr = state.tr.delete($from.pos, $to.pos);

    /* BEGIN CHANGES TO splitListItem
    --===================================================-- */

    let types = nextType && [{ type: itemType }, { type: nextType }];
    // let types = nextType && [null, { type: nextType }];

    // if (!canSplit(tr.doc, $from.pos, 2, types)) return false;
    if (!canSplitCheckbox(tr.doc, $from.pos, 2, types)) return false;

    /* END CHANGES
    --===================================================-- */
    if (dispatch) dispatch(tr.split($from.pos, 2, types).scrollIntoView());
    return true;
  };
}

// :: (Node, number, number, ?[?{type: NodeType, attrs: ?Object}]) → bool
// Check whether splitting at the given position is allowed.
function canSplitCheckbox(doc, pos, depth = 1, typesAfter) {
  let $pos = doc.resolve(pos),
    base = $pos.depth - depth;
  let innerType = (typesAfter && typesAfter[typesAfter.length - 1]) || $pos.parent;

  if (
    base < 0 ||
    $pos.parent.type.spec.isolating ||
    !$pos.parent.canReplace($pos.index(), $pos.parent.childCount) ||
    !innerType.type.validContent(
      $pos.parent.content.cutByIndex($pos.index(), $pos.parent.childCount),
    )
  )
    return false;

  window.logger("%c[ProseMirror][keys] canSplitCheckbox 1", "color: #1976D2", { innerType });

  for (let d = $pos.depth - 1, i = depth - 2; d > base; d--, i--) {
    let node = $pos.node(d),
      index = $pos.index(d);

    if (node.type.spec.isolating) return false;

    let rest = node.content.cutByIndex(index, node.childCount);
    let after = (typesAfter && typesAfter[i]) || node;
    /* CHANGES - add check for type checkb_box
    --===================================================-- */
    if (after != node && after.type.name != "checkbox_item") {
      window.logger(
        "%c[ProseMirror][keys] canSplitCheckbox 2 - after does not match node",
        "color: #1976D2",
        { after, node, typesAfter, i },
      );
      rest = rest.replaceChild(0, after.type.create(after.attrs));
    }

    window.logger("%c[ProseMirror][keys] canSplitCheckbox 3", "color: #1976D2", {
      rest,
      after,
      nodeCanReplace: node.canReplace(index + 1, node.childCount),
      afterValidConent: after.type.validContent(rest),
    });

    if (!node.canReplace(index + 1, node.childCount) || !after.type.validContent(rest))
      return false;

    window.logger("%c[ProseMirror][keys] canSplitCheckbox 3", "color: #1976D2", { innerType });
  }

  let index = $pos.indexAfter(base);
  let baseType = typesAfter && typesAfter[0];

  return $pos
    .node(base)
    .canReplaceWith(index, index, baseType ? baseType.type : $pos.node(base + 1).type);
}

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import qs from "query-string";

import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";
import Button from "@ats/src/components/shared/Button";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";

import { useToastContext } from "@shared/context/ToastContext";
import { useResetPassword } from "@shared/queryHooks/useSession";
import { validatePassword } from "@shared/lib/validateWithYup";

function PasswordReset(props) {
  const [errors, setErrors] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [resetPasswordToken, setResetPasswordToken] = React.useState("");
  const addToast = useToastContext();

  const { mutate: resetPassword, isLoading } = useResetPassword();

  React.useEffect(() => {
    addResetPasswordTokenFromParams();
  }, []);

  /* Add the Reset Password Token from URL
  --===================================================-- */
  const addResetPasswordTokenFromParams = () => {
    const token = qs.parse(props.location.search).reset_password_token;

    if (token) {
      setResetPasswordToken(token);
    }
  };

  const handleFormInputChange = (name, value) => {
    setErrors(null);
    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordConfirmation") {
      setPasswordConfirmation(value);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    window.logger("%c[PasswordReset] handleSubmitForm", "color: #1976D2", {
      password,
      passwordConfirmation,
      resetPasswordToken,
    });

    const [isValid, validationErrors] = await validatePassword({ password, passwordConfirmation });

    if (password !== passwordConfirmation) {
      setErrors(["Passwords must match"]);
      return;
    }

    if (isValid) {
      resetPassword(
        { password, passwordConfirmation, resetPasswordToken },
        {
          onSuccess: () => {
            setPassword("");
            setPasswordConfirmation("");
            setErrors(null);
            setResetPasswordToken(null);
            addToast({ title: `Password reset successfully`, kind: "success" });
            props.history.push(`/auth`);
          },
          onError: (response: any) => {
            setErrors(response.data.errors);
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const submitButton = (
    <Button type="submit" className="button-primary" loading={isLoading}>
      Reset password
    </Button>
  );

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        <Styled.Form>
          <Helmet title="Reset password" />
          <h2>Reset password</h2>
          <FormContainer onSubmit={handleSubmitForm} errors={errors} buttons={submitButton}>
            <FormInput
              onChange={handleFormInputChange}
              name="password"
              placeholder=""
              label="Password"
              inputType="password"
              value={password}
              errors={errors}
            />

            <FormInput
              onChange={handleFormInputChange}
              name="passwordConfirmation"
              placeholder=""
              label="Password confirmation"
              inputType="password"
              value={passwordConfirmation}
              errors={errors}
            />
          </FormContainer>
        </Styled.Form>
        <Styled.Links>
          <Link to="/auth">View login screen</Link>
          {/* <Link to="/register">Don&apos;t have an account?</Link> */}
        </Styled.Links>
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(PasswordReset);

import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPut, apiGet, apiDelete } from "@shared/queryHooks/api";

/* API
--===================================================-- */
const getUser = async ({ userId }) => {
  return await apiGet({ path: `/users/${userId}` });
};

const updateUser = async ({ id, ...variables }) => {
  return await apiPut({ path: `/users/${id}`, variables });
};

const deactivateUser = async ({ id, ...variables }) => {
  return await apiDelete({ path: `/users/${id}`, variables });
};

/* Hooks
--===================================================-- */
export function useGetUser(
  userId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
} {
  return useQuery(["users", userId], () => getUser({ userId }), {
    onSettled: () => { },
    onError: () => { },
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", data.userId]);
      queryClient.invalidateQueries("currentOrganization");
    },
    // throwOnError: true,
  });
}

export function useDeactivateUser() {
  const queryClient = useQueryClient();
  return useMutation(deactivateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", data.userId]);
      queryClient.invalidateQueries("currentOrganization");
    },
    // throwOnError: true,
  });
}

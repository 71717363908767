// ./src/index.js
// import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./views/layouts/App";
import store from "@ats/src/lib/store";

if (window.SERVER_ENV !== "test") {
  Sentry.init({
    dsn: window.SENTRY_JAVASCRIPT_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5, // 0 is off and 1.0 is all, can do 0.5 to cut the sampling in half or any value between 0 and 1.0
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const render = (Component = App, store) => {
    ReactDOM.render(<Component server={false} store={store} />, document.getElementById("root"));
  };

  render(undefined, store);
});

import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormInput from "@ats/src/components/forms/FormInput";

import { useSearchCandidates } from "@shared/queryHooks/admin/useCandidate";

function AdminCandidatesSearch(props: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // QUERY HOOKS
  const {
    mutate: searchCandidates,
    data: candidateSearchData,
    isLoading: isFetchingCandidateResults,
    isSuccess: isSuccessCandidateResults,
  } = useSearchCandidates();

  window.logger("%c[AdminCandidatesSearch] render", "color: #1976D2", {
    props,
    candidateSearchData,
    isFetchingCandidateResults,
  });

  // handleDestroy????? ---> And also confirmation modal & success message
  // const handleDestroyCandidate = (candidateId) => {
  //   window.logger("%c[AdminCandidatesSearch] handleDestroyCandidate candidateId", "color: #1976D2", candidateId);

  //   destroyCandidate(
  //     { candidateId },
  //     {
  //       onSuccess: (data) => {
  //         window.logger(
  //           "%c[AdminCandidatesSearch] destroyCandidate onSuccess",
  //           "background-color: #c6d219",
  //           { data },
  //         );
  //       },
  //       onError: (response: any) => {
  //         window.logger("[AdminCandidatesSearch] destroyCandidate server error", { response });
  //         if (response.data.errors) {
  //           // setState({ ...state, errors: response.data.errors });
  //         }
  //       },
  //     },
  //   );
  // };

  // onChangeSearch
  const onChangeSearchCandidates = (_, value) => {
    setSearchTerm(value);
    if (value.length > 0) {
      searchCandidates(value, {
        onSuccess: (data) => {
          window.logger("%c[AdminCandidatesSearch] onChangeSearchCandidates", "color: #1976D2", {
            data,
          });
          setSearchResults(data.items);
        },
      });
    } else {
      setSearchResults([]);
    }
  };

  //actual list items
  const candidateNodes = () => {
    if (!isSuccessCandidateResults || candidateSearchData == undefined) {
      return [];
    }

    const candidates =
      searchResults.length > 0 && searchTerm.length > 0 ? searchResults : candidateSearchData.items;

    window.logger("%c[AdminCandidatesSearch] candidateNodes", "color: #1976D2", {
      candidates,
    });

    return candidates.map(function (candidate) {
      window.logger("%c[AdminCandidatesSearch] candidateNode ", "color: #1976D2", {
        candidate,
      });

      // let disableDestory = candidate?.email === .....???

      return (
        <tr className="admin-candidate-list__item" key={candidate?.id}>
          <td>{candidate?.id}</td>
          <td>{candidate?.fullName}</td>
          <td>{candidate?.email}</td>
          <td style={{ color: `${candidate?.hasValidEmail ? "green" : "red"}` }}>
            {candidate?.hasValidEmail ? "Valid" : "Invalid"}
          </td>
          <td>{candidate?.organizationName}</td>
          <td>{candidate?.jobApplicationsCount}</td>
          {/* <td>
            <Button
              styleType="text"
              type="button"
              onClick={() => handleDestroyCandidate(candidate?.id)}
              disabled={disableDestroy}
            >
              Destroy
            </Button>
          </td> */}
        </tr>
      );
    });
  };

  const resultsNotEmpty = searchResults.length > 0 ? true : false;

  return (
    <Styled.Component>
      <Styled.Header>
        <h2>Search Candidates</h2>

        <FormInput
          name="candidateSearch"
          placeholder="Search"
          value={searchTerm}
          onChange={onChangeSearchCandidates}
        />
      </Styled.Header>

      <Styled.Table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Valid Email</th>
            <th>Organization Name</th>
            <th>Application Count</th>
            {/* <th>Destroy</th> */}
          </tr>
          {resultsNotEmpty ? candidateNodes() : null}
        </tbody>
      </Styled.Table>
    </Styled.Component>
  );
}

AdminCandidatesSearch.propTypes = {};

AdminCandidatesSearch.defaultProps = {};

export default AdminCandidatesSearch;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminCandidatesSearch;
    ${t.p(4)};
  `;
});

Styled.Header = styled.header((props) => {
  const t: any = props.theme;
  return css`
    label: AdminCandidatesSearch_Header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      ${[t.mt(1), t.text.h3, t.mb(2)]};
      display: flex;
      align-items: center;
    }
  `;
});

Styled.Table = styled.table((props) => {
  const t: any = props.theme;
  return css`
    label: AdminCandidatesSearch_Table;
    border-collapse: collapse;
    width: 100%;

    th {
      ${[t.pb(2), t.text.xs, t.text.bold]};
      text-align: start;
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.primary};
    }

    tr {
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.secondary};
    }

    tr:last-of-type {
      border-bottom: none;
    }

    svg {
      stroke: ${t.color.black};
    }

    td,
    th {
      ${[t.p(2)]};

      &:first-of-type {
        ${t.pl(0)}
      }

      &:last-of-type {
        ${t.pr(0)}
      }
    }
  `;
});

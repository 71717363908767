import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

import {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomerPortalSession,
  useCreateSubscription,
} from "@shared/queryHooks/useBilling";

import { useToastContext } from "@shared/context/ToastContext";

import { prettyBillingPrice } from "@ats/src/lib/utils/helpers";

type AccountBillingNewPlanChoicesProps = {
  location: any; // Consider defining a more specific type or interface for location
  customer: any; // Consider defining a more specific type or interface for customer
  publishedJobsCount: number;
  canRenewSubscription: boolean;
  billingPrices: any[]; // Consider defining a more specific type or interface for billingPrices
  hasInactiveSubscription: boolean;
};

const AccountBillingNewPlanChoices: React.FC<AccountBillingNewPlanChoicesProps> = ({
  location,
  customer,
  publishedJobsCount,
  canRenewSubscription,
  billingPrices,
  hasInactiveSubscription
}) => {
  const addToast = useToastContext();

  /* Create Stripe Customer Portal Session
  --===================================================-- */
  const { mutate: createStripeCustomerPortalSession } = useCreateStripeCustomerPortalSession();

  /* Create Stripe Checkout Session
  --===================================================-- */
  const {
    mutate: createStripeCheckoutSession,
    error: errorOnCreateCheckoutSession,
    isLoading: isLoadingCreateCheckoutSession,
  } = useCreateStripeCheckoutSession();

  /* Create Subscription
  --===================================================-- */
  const {
    mutate: createSubscription,
    isLoading: isLoadingCreateSubscription,
  } = useCreateSubscription();

  const geminiBillingPrices = billingPrices.filter((price) => price.lookupKey?.includes("gemini"));
  const apolloBillingPrices = billingPrices
    .filter((price) => price.lookupKey?.includes("apollo"))
    .sort((a, b) => a.unitAmount - b.unitAmount); // Sorting by unitAmount so that the Monthly Plan shows up first in the list

  // const prettyBillingPrice = (price) => {
  //   return price.billingScheme === "tiered"
  //     ? price.tiers[0].unitAmount / 100.0
  //     : price.unitAmount / 100.0;
  // };

  window.logger("%c[AccountBillingPlanChoices] render", "background: #ed79ed; color: #b5d0eb", {
    location,
    customer,
    canRenewSubscription,
    publishedJobsCount,
    billingPrices,
    geminiBillingPrices,
    apolloBillingPrices,
  });

  /**
   * USED IF A CUSTOMER WANTS TO RENEW A SUBSCRIPTION
   * This function handles the creation of a new subscription for a customer that has already had a Subscription in the past.
   * Upon successful creation of the subscription, it displays a success toast message.
   * If the creation fails, it displays a warning toast message.
   */
  const handleCreateSubscription = (priceId: string) => {
    createSubscription(
      { priceId },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountBilling] createSubscription SUCCESS", "color: #19d219", {
            data,
          });
          addToast({
            title: "Subscription activated",
            kind: "success",
          });
        },
        onError: (response) => {
          window.logger("%c[AccountBilling] createSubscription FAILURE", "color: #990f6b", {
            response,
          });
          addToast({
            title: "Unable to renew subscription",
            kind: "warning",
          });
        },
      },
    );
  };

  /**
   * USED IF A CUSTOMER WANTS TO START A NEW SUBSCRIPTION
   * This function handles the creation of a Stripe Checkout Session for new subscriptions.
   * It checks if a target billing price is defined, then calls the `createStripeCheckoutSession` function
   * with parameters for a subscription session, including the price ID, success and cancel URLs,
   * and the current location pathname. Upon successful creation of the session, it uses the Stripe object
   * to redirect the user to the Stripe Checkout page. If the target billing price is not defined,
   * it displays a warning toast message advising the user to contact support.
   */
  const handleCreateCheckoutSubscriptionSession = ({ priceId, planType }) => {
    createStripeCheckoutSession(
      {
        priceId,
        successUrl: `/hire/settings/billing`,
        cancelUrl: `/hire/settings/billing`,
        locationPathname: location.pathname,
        checkoutMode: "subscription",
        planType,
      },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[AccountBilling] completed creation of Subscription Checkout Session",
            "background: orange; color: #1976D2",
            data,
          );
          (window as any).STRIPE.redirectToCheckout({
            sessionId: data.id,
          }).then(function (result) {
            window.logger(
              "%c[AccountBilling] REDIRECTING",
              "background: green; color: white",
              result,
            );
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        },
      },
    );
  };

  /**
   * USED IF A CUSTOMER WANTS TO MANAGE THEIR BILLING USING THE STRIPE CUSTOMER PORTAL
   * This function handles the creation of a Stripe Customer Portal Session.
   * It calls the `createStripeCustomerPortalSession` function with specific parameters,
   * including the return URL. Upon successful creation of the session, it redirects the user
   * to the Stripe Customer Portal page. If the redirection fails, it logs the error message.
   */
  const handleCreateBillingPortalSession = () => {
    createStripeCustomerPortalSession(
      { returnUrl: "/hire/settings/billing" },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountContainer] ", "color: #1976D2", { data });
          window.location.href = data.redirectUrl;
        },
      },
    );
  };

  return (
    <>
      <Subtitle>Subscription options</Subtitle>
      <PricingOptions>
        {apolloBillingPrices.map((price) => (
          <Pricing key={price.id}>
            <PricingTitle>
              <h4>{price.interval || price.recurring.interval}ly plan</h4>
            </PricingTitle>
            <PricingPrice>
              <p>
                ${prettyBillingPrice(price)}
                <span>
                  per month <br />
                  billed {price.interval || price.recurring.interval}ly
                </span>
              </p>
            </PricingPrice>
            <ButtonWrapper>
              {/* <Button styleType="secondary" onClick={handleCreateBillingPortalSession}>
              Manage billing
            </Button> */}
              {canRenewSubscription ? (
                <Button
                  onClick={() => handleCreateSubscription(price.id)}
                  loading={isLoadingCreateSubscription}
                >
                  Restart subscription
                </Button>
              ) : (
                <Button
                  styleType="secondary"
                  onClick={() =>
                    handleCreateCheckoutSubscriptionSession({
                      priceId: price.id,
                      planType: "unlimited",
                    })
                  }
                >
                  Select subscription
                </Button>
              )}
            </ButtonWrapper>
          </Pricing>
        ))}
      </PricingOptions>
      {hasInactiveSubscription && (
        <CurrentSubscription>
          <p>
            <Icon name="info" />
            <span>
              You have no active subscriptions
            </span>
          </p>
          <Button styleType="text" onClick={handleCreateBillingPortalSession}>
            Manage billing
          </Button>
        </CurrentSubscription>
      )}
    </>
  );
};

export default AccountBillingNewPlanChoices;

const Subtitle = styled.h3((props) => {
  const t: any = props.theme;
  return css`
    ${[t.text.bold, t.text.lg, t.mt(8)]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

const PricingOptions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;
    gap: ${t.spacing[4]};
  `;
});

const Pricing = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.rounded.md, t.py(4), t.mt(4), t.px(4)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    flex: 1;
  `;
});

const PricingTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: ${t.spacing[2]};

    h4 {
      ${[t.text.bold, t.text.md]}
      &:first-letter {
        text-transform: uppercase;
      }
    }
  `;
});

const PricingPrice = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PricingPrice;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    margin-bottom: ${t.spacing[2]};

    p {
      ${[t.text.bold]}
      font-size: ${t.spacing[10]}
    }

    span {
      display: inline-block;
      ${[t.text.normal, t.text.sm, t.ml(2)]}
      line-height: 1.4;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

const ButtonWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_ButtonWrapper;
    display: flex;

    button {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

const Promo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.rounded.md, t.mt(4), t.px(4), t.py(3)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};

    h4 {
      ${[t.text.bold, t.mb(1)]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    span {
      ${[t.text.xs]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

const Badge = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountBilling_PromoBadge;
    ${[t.h(4), t.ml(2), t.rounded.xs]};
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    font-size: 0.625rem;
    color: ${t.color.black};
    border: 1px solid ${t.color.orange[200]};
    background: ${t.dark
      ? "linear-gradient(120deg, #FBD7FF -40%, #FFDEC1 80%)"
      : "linear-gradient(120deg, #FFD8D8 -40%, #FFDEC1 80%)"};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `;
});

const CurrentSubscription = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.rounded.md, t.py(3), t.mt(4), t.px(4)]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[100]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: flex;
      align-items: center;
      gap: ${t.spacing[3]};

      span {
        color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      }
    }
  `;
});

export {
  Subtitle,
  PricingOptions,
  Pricing,
  PricingTitle,
  PricingPrice,
  ButtonWrapper,
  Badge,
  Promo,
  CurrentSubscription
};

import React from "react";

import { useToastContext } from "@shared/context/ToastContext";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSection from "@ats/src/components/forms/FormSection";

import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function AccountIntegrationsGoogleAnalytics(props) {
  const addToast = useToastContext();
  const { history } = props;
  const { currentOrganization } = useCurrentSession();
  const [isDirty, setIsDirty] = React.useState(false);
  const [settings, setSettings] = React.useState(currentOrganization.settings);

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  const updateOrganizationSettings = (name, value) => {
    setIsDirty(true);
    const updatedSettings = Object.assign({}, settings, { [name]: value });
    setSettings(updatedSettings);
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    updateOrganization(
      { id: currentOrganization.id, settings },
      {
        onSuccess: () => {
          setIsDirty(false);
          addToast({
            title: `Successfully updated`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          addToast({
            title: `Failed to update`,
            kind: "danger",
          });
        },
      },
    );
  };

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[JobDistributionWeWorkRemotely] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={onClickSubmit}
        disabled={isLoadingUpdateOrganization}
        loading={isLoadingUpdateOrganization}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="Google Analytics"
      description="Add your Google Analytics Measurement ID to every page of your company job board to enable tracking via the Google Analytics dashboard."
      bottomBar={BottomBar}
      fullWidthForm={true}
    >
      <FormSection>
        <FormInput
          name="googleAnalyticsTrackingId"
          onChange={updateOrganizationSettings}
          placeholder="G-0000000000"
          label="Google Analytics Measurement ID"
          description={`Create a property from your Google Analytics admin page and add a web stream with url "${(window as any).APP_JOBBOARD_ROOT_URL
            }". Then paste the measurement ID here.`}
          value={settings.googleAnalyticsTrackingId || ""}
          errors={null}
        />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsGoogleAnalytics;

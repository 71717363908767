import React from "react";
import { userRoleNames } from "@ats/src/lib/lookups";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

// import InitialsAvatar from "@ats/src/components/shared/InitialsAvatar";
import UserAvatar from "@ats/src/components/shared/UserAvatar";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import FormSelect from "@ats/src/components/forms/FormSelect";

export default function MemberListItem(props) {
  // const [state, setState] = React.useState({ ...props.user });
  const { organizationUser, roles, isSelf, isOrgOwner, errors, currentUser, isCurrentOwner, currentUserRole } = props;
  const [role, setRole] = React.useState(organizationUser.role);

  window.logger("%c[MemberListItem] RENDER ", "color: #1976D2", { ...organizationUser });

  const handleRoleSelectChange = (name, value) => {
    // setState({ ...state, [name]: value });
    setRole(value); // Only doing this for optimistic UI purposes

    if (value) {
      window.logger(`[MemberListItem] handleRoleSelectChange - Selected: ${value}`);
      updateOrganizationUserRole(value);
    }
  };

  const updateOrganizationUserRole = (role) => {
    props.updateOrganizationUserRole({ organizationUserId: organizationUser.id, role });
  };

  const deactivateUser = (e) => {
    e.preventDefault();
    props.deactivateOrganizationUser(organizationUser.id);
  };

  const activateUser = (e) => {
    e.preventDefault();
    props.activateOrganizationUser(organizationUser.id);
  };

  const transferOwnership = (e) => {
    e.preventDefault();
    props.transferOrgOwnership(organizationUser.id);
  };

  let isActiveButton;
  let roleNode = (
    <FormSelect
      onChange={handleRoleSelectChange}
      name="role"
      label=""
      description=""
      className=""
      value={role}
      options={roles}
      errors={errors}
    />
  );

  if (!organizationUser.isActive) {
    roleNode = <span>Deactivated</span>;
  } else if (isOrgOwner) {
    roleNode = <>Owner</>;
  } else if (role === "god_admin") {
    roleNode = <>Polymer Admin</>;
  } else if (isSelf && currentUserRole === "org_admin") {
    roleNode = <>Admin</>;
  } else if (!currentUser.currentOrganizationUser.isAdmin) {
    roleNode = <>{userRoleNames(organizationUser.role)}</>;
  }

  if (isSelf || isOrgOwner) {
    isActiveButton = (
      <Styled.DisabledAction onClick={null}>Cannot deactivate</Styled.DisabledAction>
    );
  } else if (organizationUser.isActive) {
    isActiveButton = (
      <a href="" onClick={deactivateUser}>
        Deactivate account
      </a>
    );
  } else if (!organizationUser.isActive) {
    isActiveButton = (
      <a href="" onClick={activateUser}>
        Reactivate account
      </a>
    );
  }

  return (
    <Styled.Container>
      <Styled.Member>
        {/* <InitialsAvatar initials={user.initials} size="lg" /> */}
        <UserAvatar user={organizationUser.user} size="lg" />
        <Styled.Info>
          <Styled.Name>{organizationUser.user.fullName}</Styled.Name>
          <Styled.Email>{organizationUser.user.email}</Styled.Email>
        </Styled.Info>
      </Styled.Member>
      <Styled.Controls>
        <Styled.Role>{roleNode}</Styled.Role>
        <DropdownMenu label="Options">
          {isActiveButton}
          {(isCurrentOwner && organizationUser.isActive && !isOrgOwner) &&
            (
              <a href="" onClick={transferOwnership}>
                Transfer ownership
              </a>
            )
          }
        </DropdownMenu>
      </Styled.Controls>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: MemberListItem;
    ${[t.pl(4), t.pr(3), t.py(3)]}
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    &:last-of-type {
      border-bottom: none;
    }
  `;
});

Styled.Member = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem_Member;
    display: flex;
    align-items: center;
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.ml(3)}
    label: MemberListItem_Info;
  `;
});

Styled.Name = styled.h3((props) => {
  const t: any = props.theme;
  return css`
    label: MemberListItem_Name;
    ${[t.mr(2), t.mb(1)]}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.Email = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: MemberListItem_Email;
    ${[t.text.xs]};
    display: block;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
});

Styled.Controls = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem_Controls;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    flex-shrink: 0;
  `;
});

Styled.Role = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: MemberListItem_Role;
    ${t.mr(4)}
    flex: 1;
    color: ${t.dark ? t.color.gray[200] : t.color.black};

    span {
      color: ${t.color.gray[500]};
    }

    > div {
      ${t.mb(0)}
    }
  `;
});

Styled.DisabledAction = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: MemberListItem_DisabledAction;
    color: ${t.color.dark ? t.color.gray[400] : t.color.gray[500]} !important;
    cursor: not-allowed !important;
    &:hover {
      background-color: ${t.color.gray[800]} !important;
    }
  `;
});

const fileTypes = {
  image: ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"],
  document: [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  excel: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  csv: ["text/csv"],
  text: ["text/plain"],
  audio: [
    "audio/mpeg",
    "audio/ogg",
    "audio/wav",
    "audio/x-wav", // Firefox & Safari MIME type for .wav files
    "video/ogg", // Firefox identifies audio/ogg files as video/ogg 
  ],
};

const customQuestionUploadFileTypes = [...fileTypes.image, ...fileTypes.document, ...fileTypes.excel, ...fileTypes.csv, ...fileTypes.text, ...fileTypes.audio];

fileTypes["combined"] = customQuestionUploadFileTypes;

export type FileTypes = "image" | "document" | "excel" | "csv" | "text" | "combined" | "any";

export const KILOBYTE_IN_BYTES = 1024;
export const MEGABYTE_IN_KILOBYTES = 1024;
export const MEGABYTE_IN_BYTES = KILOBYTE_IN_BYTES * MEGABYTE_IN_KILOBYTES;

export const isAllowedFileType = (expectedFileType, actualFileType) => {
  if (expectedFileType === "any") return true;

  return expectedFileType === undefined
    ? true
    : fileTypes[expectedFileType].includes(actualFileType);
};

export const isDocx = (contentType) => {
  return ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(
    contentType,
  );
};

export const isAllowedFileSize = (maxFileSize, actualFileSize) => {
  if (!maxFileSize) return true;
  return actualFileSize <= maxFileSize;
};

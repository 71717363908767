import React from "react";
import { Droppable, DragDropContext } from "react-beautiful-dnd";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function CommentTemplateList(props) {
  const { onDragEndCommentTemplate } = props;

  const onDragEnd = (result) => {
    onDragEndCommentTemplate(result);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="comment-templates-droppable-id">
        {(provided) => (
          <Styled.Container ref={provided.innerRef} {...provided.droppableProps}>
            {props.children}
            {provided.placeholder} {/* Increases space for dropping */}
          </Styled.Container>
        )}
      </Droppable>
    </DragDropContext>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateList;
    ${[t.my(8), t.rounded.md]}
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
  `;
});

import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */

const createStripeCheckoutSession = async ({
  priceId,
  successUrl,
  cancelUrl,
  jobId,
  locationPathname,
  checkoutMode = "subscription",
  planType = "per_job",
}: {
  priceId?: any;
  successUrl: string;
  cancelUrl: string;
  jobId?: number;
  locationPathname?: string;
  checkoutMode?: string;
  planType?: string;
}) => {
  return await apiPost({
    path: `/billing/checkout`,
    variables: { priceId, successUrl, cancelUrl, jobId, locationPathname, checkoutMode, planType },
  });
};

const createSubscription = async ({ priceId }: { priceId?: string }) => {
  return await apiPost({
    path: `/billing/create_subscription`,
    variables: { priceId },
  });
};

const changeSubscription = async ({ priceId }: { priceId?: string }) => {
  return await apiPost({
    path: `/billing/change_subscription`,
    variables: { priceId },
  });
};

const changeSubscriptionViaStripePortal = async ({
  priceId,
  subscriptionItemId,
  returnUrl,
}: {
  priceId?: string;
  subscriptionItemId?: string;
  returnUrl?: string;
}) => {
  return await apiPost({
    path: `/billing/change_subscription_portal_session`,
    variables: { priceId, subscriptionItemId, returnUrl },
  });
};

const applyPromoCode = async ({ promoCode }: { promoCode: string }) => {
  return await apiPut({
    path: `/billing/apply_promo_code`,
    variables: { promoCode },
  });
};

const swapCheckoutSessionIdForPaymentMethod = async ({ sessionId }: { sessionId?: string }) => {
  return await apiPut({
    path: `/billing/swap_checkout_session_id_for_payment_method`,
    variables: { sessionId },
  });
};

const createStripeCustomerPortalSession = async (variables = {}) => {
  return await apiPost({ path: `/billing/create_customer_portal_session`, variables });
};

const getStripeCustomer = async () => {
  return await apiGet({ path: `/billing/customer` });
};

const getStripeCustomerSubscription = async () => {
  return await apiGet({ path: `/billing/customer_subscription` });
};

const getPrices = async () => {
  return await apiGet({ path: `/billing/prices` });
};

const syncWithStripe = async () => {
  return await apiPut({
    path: `/billing/sync_with_stripe`,
    variables: null,
  });
};

/* Hooks
--===================================================-- */

function useCreateStripeCheckoutSession() {
  return useMutation(createStripeCheckoutSession, {
    // throwOnError: true,
  });
}

function useCreateStripeCustomerPortalSession() {
  return useMutation(createStripeCustomerPortalSession, {
    // throwOnError: true,
  });
}

function useSwapCheckoutSessionIdForPaymentMethod() {
  const queryClient = useQueryClient();
  return useMutation(swapCheckoutSessionIdForPaymentMethod, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["currentOrganization", data.id]);
      queryClient.invalidateQueries(["stripeCustomer"]);
    },
    // throwOnError: true,
  });
}

function useCreateSubscription() {
  const queryClient = useQueryClient();
  return useMutation(createSubscription, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // After success or failure, refetch the candidate query
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useBilling] useCreateSubscription", "color: #1976D2", {
        data,
        mutationVariables,
        organizationId: data.metadata.organizationId,
      });
      queryClient.invalidateQueries(["currentOrganization", Number(data.metadata.organizationId)]);
      queryClient.invalidateQueries(["stripeCustomer"]);
    },
    // throwOnError: true,
  });
}

function useChangeSubscription() {
  const queryClient = useQueryClient();
  return useMutation(changeSubscription, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // After success or failure, refetch the candidate query
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useBilling] useChangeSubscription", "color: #1976D2", {
        data,
        mutationVariables,
        organizationId: data.metadata.organizationId,
      });
      queryClient.invalidateQueries(["currentOrganization", Number(data.metadata.organizationId)]);
      queryClient.invalidateQueries(["stripeCustomer"]);
    },
    // throwOnError: true,
  });
}

function useChangeSubscriptionViaStripePortal() {
  const queryClient = useQueryClient();
  return useMutation(changeSubscriptionViaStripePortal, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useBilling] useChangePlanStripePortalSession", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["currentOrganization"]);
    },
  });
}

function useApplyPromoCode() {
  const queryClient = useQueryClient();
  return useMutation(applyPromoCode, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // After success or failure, refetch the candidate query
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useBilling] useApplyPromoCode", "color: #1976D2", {
        data,
        mutationVariables,
        // organizationId: data.metadata.organizationId,
      });
      queryClient.invalidateQueries(["currentOrganization", Number(data.metadata.organizationId)]);
      queryClient.invalidateQueries(["stripeCustomer"]);
      queryClient.invalidateQueries(["stripeCustomerSubscription"]);
    },
    // throwOnError: true, // this requires a try catch block around the use of this method
  });
}

function useStripeCustomer({
  refetchOnWindowFocus = false,
}: {
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useBilling] useStripeCustomer", "color: #1976D2", { refetchOnWindowFocus });
  return useQuery(["stripeCustomer"], getStripeCustomer, {
    refetchOnWindowFocus,
  });
}

function useStripeCustomerSubscription({
  refetchOnWindowFocus = false,
}: {
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useBilling] useStripeCustomerSubscription", "color: #1976D2", {
    refetchOnWindowFocus,
  });
  return useQuery(["stripeCustomerSubscription"], getStripeCustomerSubscription, {
    refetchOnWindowFocus,
  });
}

function useBillingPrices({
  refetchOnWindowFocus = false,
}: {
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useBilling] useBillingPrices", "color: #1976D2", { refetchOnWindowFocus });
  return useQuery(["billingPlans"], getPrices, {
    refetchOnWindowFocus,
  });
}

function useSyncWithStripe() {
  const queryClient = useQueryClient();
  return useMutation(syncWithStripe, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // After success or failure, refetch the candidate query
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useBilling] useSyncWithStrip", "color: #1976D2", {
        data,
        mutationVariables,
        organizationId: data.id,
      });
      queryClient.invalidateQueries(["currentOrganization", Number(data.id)]);
      queryClient.invalidateQueries(["stripeCustomer"]);
    },
    // throwOnError: true,
  });
}

export {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomerPortalSession,
  useSwapCheckoutSessionIdForPaymentMethod,
  useCreateSubscription,
  useChangeSubscription,
  useChangeSubscriptionViaStripePortal,
  useApplyPromoCode,
  useStripeCustomer,
  useStripeCustomerSubscription,
  useBillingPrices,
  useSyncWithStripe,
};

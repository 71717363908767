import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const LoadingIndicator = ({ label, subtle }) =>
  subtle ? (
    <Styled.Subtle>
      <div />
      <div />
      <div />
    </Styled.Subtle>
  ) : (
    <Styled.Container>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.Bar>
        <Styled.Indicator />
      </Styled.Bar>
    </Styled.Container>
  );

LoadingIndicator.propTypes = {
  label: PropTypes.string,
  subtle: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  label: "",
  subtle: false,
};

export default LoadingIndicator;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LoadingIndicator;
    ${[t.my(24), t.mx("auto")]}
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LoadingIndicator_Label;
    ${[t.text.xs, t.mb(2)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Bar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LoadingIndicator_Bar;
    ${[t.w(32)]}
    height: 0.1875rem;
    background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.gray[200]};
    position: relative;
    overflow: hidden;
  `;
});

Styled.Indicator = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LoadingIndicator_Indicator;
    ${[t.w(16)]}
    height: 100%;
    position: absolute;
    background-color: ${t.dark ? t.color.gray[400] : t.color.black};
    transition: transform 0.3s ease-in;
    animation: ${loading} 1.5s infinite ease;
  `;
});

const loading = keyframes`
  0%, 100% {
    transform: translate(-3rem,0);
  }
  50% {
    transform: translate(7rem,0);
  }
`;

const subtleKeyframe = keyframes`
  0% {
    height: 0.75rem;
  }
  30%,
  100% {
    height: 0.125rem;
  }
`;

Styled.Subtle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: LoadingIndicator_Subtle;
    ${t.h(10)}
    display: flex;
    align-items: center;
    div {
      position: relative;
      width: 0.125rem;
      margin: 0.125rem;
      background-color: ${t.dark ? t.color.gray[400] : t.color.gray[300]};
      animation: ${subtleKeyframe} 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div:nth-of-type(1) {
      animation-delay: -0.24s;
    }
    div:nth-of-type(2) {
      animation-delay: -0.12s;
    }
    div:nth-of-type(3) {
      animation-delay: 0;
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Button from "@ats/src/components/shared/Button";
import JobSetupApplicationFormCustomQuestions from "@ats/src/views/jobApplications/jobSetup/JobSetupApplicationFormCustomQuestions";
import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormSection from "@ats/src/components/forms/FormSection";
import FormSelect from "@ats/src/components/forms/FormSelect";
// import FormInput from "@ats/src/components/forms/FormInput";

import { useToastContext } from "@shared/context/ToastContext";

import { useUpdateJob } from "@shared/queryHooks/useJob";
import { jobSettingOptions } from "@ats/src/lib/lookups";

function JobSetupApplicationForm(props) {
  window.logger("%c[JobSetupApplicationForm] ", "background: #EFDDEF; color: #1976D2", props);
  const [job, setJob] = React.useState(props.job);
  const { questions, setIsDirty } = props;
  const { errors, settings, applyThrough, applicationThirdpartyUrl } = job;
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const addToast = useToastContext();

  if (!settings) return null;
  const {
    phone,
    location,
    linkedinUrl,
    githubUrl,
    twitterUrl,
    dribbbleUrl,
    websiteUrl,
    resume,
  } = settings;

  /* updateJobSettings
  --===================================================-- */
  const updateJobSettings = (name, value) => {
    setIsDirty(true);
    let { settings } = job;
    settings = Object.assign({}, settings, { [name]: value });
    setJob({ ...job, settings });
  };

  // const handleFormInputChange = (name, value) => {
  //   setIsDirty(true);
  //   setJob({ ...job, [name]: value });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    // Submit the form here
    console.warn("[JobSetupApplicationForm] onSubmit job", job);
    const { settings, applyThrough, applicationThirdpartyUrl } = job;

    updateJob(
      { id: props.job.id, settings, applyThrough, applicationThirdpartyUrl },
      {
        onSuccess: (data) => {
          setIsDirty(false);
          addToast({
            title: `Job updated`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          setJob({ ...job, errors: response.data.errors });
          addToast({
            title: `Could not update job`,
            kind: "warning",
          });
        },
      },
    );
  };

  window.logger("%c[JobSetupApplicationForm] props", "color: #FF76D2", { props, job });
  // window.logger("%c[JobSetupApplicationForm] job", "color: #19FFD2", job);

  // const thirdPartyUrlSection = (
  //   <>
  //     <FormInput
  //       onChange={handleFormInputChange}
  //       name="applicationThirdpartyUrl"
  //       placeholder="https://apply.here.com"
  //       label="URL of your job application form"
  //       className=""
  //       value={applicationThirdpartyUrl || ""}
  //       errors={errors}
  //     />
  //   </>
  // );

  const jobApplicationSection = (
    <>
      <FormSection title="Basic information">
        <FormLabel label="Name" />
        <Styled.PermaRequired>Always required</Styled.PermaRequired>
        <FormLabel label="Email address" />
        <Styled.PermaRequired>Always required</Styled.PermaRequired>

        <FormSelect
          onChange={updateJobSettings}
          name="phone"
          label="Phone"
          description=""
          className=""
          value={phone}
          options={jobSettingOptions}
          errors={errors}
        />

        <FormSelect
          onChange={updateJobSettings}
          name="location"
          label="Location"
          description=""
          className=""
          value={location}
          options={jobSettingOptions}
          errors={errors}
        />
      </FormSection>

      <FormSection title="Links">
        <FormSelect
          onChange={updateJobSettings}
          name="linkedinUrl"
          label="LinkedIn profile"
          description=""
          className=""
          value={linkedinUrl}
          options={jobSettingOptions}
          errors={errors}
        />

        <FormSelect
          onChange={updateJobSettings}
          name="twitterUrl"
          label="Twitter profile"
          description=""
          className=""
          value={twitterUrl}
          options={jobSettingOptions}
          errors={errors}
        />

        <FormSelect
          onChange={updateJobSettings}
          name="githubUrl"
          label="GitHub profile"
          description=""
          className=""
          value={githubUrl}
          options={jobSettingOptions}
          errors={errors}
        />

        <FormSelect
          onChange={updateJobSettings}
          name="dribbbleUrl"
          label="Dribbble profile"
          description=""
          className=""
          value={dribbbleUrl}
          options={jobSettingOptions}
          errors={errors}
        />

        <FormSelect
          onChange={updateJobSettings}
          name="websiteUrl"
          label="Website URL"
          description=""
          className=""
          value={websiteUrl}
          options={jobSettingOptions}
          errors={errors}
        />
      </FormSection>

      <FormSection title="File upload">
        <FormSelect
          onChange={updateJobSettings}
          name="resume"
          label="Resume"
          description=""
          className=""
          value={resume}
          options={jobSettingOptions}
          errors={errors}
        />
      </FormSection>

      <FormSection title="Custom fields" border={false} id="custom-fields">
        <JobSetupApplicationFormCustomQuestions jobId={props.job.id} questions={questions} />
      </FormSection>
    </>
  );

  const BottomBarContent = (
    <Button type="button" size="small" onClick={onSubmit} loading={isLoadingUpdateJob}>
      Save changes
    </Button>
  );

  return (
    <>
      <SettingsContainer
        title="Application form"
        description="Here's where you can edit which fields appear on this job's application form and if they are required or not. You can further customize the application form by adding additional questions."
        bottomBar={BottomBarContent}
      >
        {/*
        <FormSection title="How to Apply">
          <FormSelect
            onChange={handleFormInputChange}
            name="applyThrough"
            label="Apply Through"
            description=""
            className=""
            value={applyThrough}
            options={applyThroughOptions}
            errors={errors}
          />
          {applyThrough !== "apply_through_hire" && thirdPartyUrlSection}
        </FormSection>

        {applyThrough === "apply_through_hire" && jobApplicationSection}
        */}
        {jobApplicationSection}
      </SettingsContainer>
    </>
  );
}

export default JobSetupApplicationForm;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.PermaRequired = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupApplicationForm_PermaRequired;
    ${[t.mb(5), t.h(8), t.px(2), t.rounded.sm]}
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[300]};
    background-color: ${t.dark ? t.color.gray[900] : t.color.gray[100]};
  `;
});

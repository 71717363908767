import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut } from "@shared/queryHooks/api";

const getCandidate = async (candidateId) => {
  if (candidateId != undefined) {
    return await apiGet({ path: `/candidates/${candidateId}` });
  }
};

const createCandidate = async ({ jobId, candidate }) => {
  if (jobId != null) {
    return await apiPost({ path: `/jobs/${jobId}/candidates`, variables: candidate });
  }
};

const updateCandidate = async ({ jobApplicationId, candidate: { id, ...candidate } }) => {
  return await apiPut({ path: `/candidates/${id}`, variables: candidate });
};

const anonymizeCandidate = async ({ candidateId, jobApplicationId, hiringStageId, jobId }) => {
  return await apiPut({ path: `/candidates/${candidateId}/anonymize`, variables: null });
};

/* Hooks
--===================================================-- */
function useCandidate(
  candidate,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
} {
  return useQuery(["candidates", candidate.id], () => getCandidate(candidate.id));
}

function useCreateCandidate() {
  const queryClient = useQueryClient();
  return useMutation(createCandidate, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // onSuccess: (data, variables) => {
    //   queryClient.setQueryData(["jobCategory", { id: variables.id }], data);
    // },
    // Error
    // onError: (data, variables) => {
    //   window.logger(
    //     "%c[useCandidate] handle error when creating Job Category",
    //     "background-color: #19F6D2",
    //     {
    //       data,
    //       variables,
    //     },
    //   );
    // },
    // After success or failure, refetch the candidate query
    onSuccess: (data, mutationVariables) => {
      window.logger("[useCandidate] useCreateCandidate", { data, mutationVariables });
      queryClient.invalidateQueries(["jobs", mutationVariables.jobId]);
      queryClient.invalidateQueries(["jobApplications", mutationVariables.jobId]); // Not sure this is necessary
      queryClient.invalidateQueries([
        "jobApplicationsForStage",
        data.jobApplications.length > 0
          ? data.jobApplications[data.jobApplications.length - 1].hiringStageId
          : null,
      ]);
      queryClient.invalidateQueries(["candidates", data.id]);
    },
    // throwOnError: true,
  });
}

function useUpdateCandidate() {
  const queryClient = useQueryClient();
  return useMutation(updateCandidate, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCandidate] useUpdateCandidate", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["jobApplication", mutationVariables.jobApplicationId]);
      queryClient.invalidateQueries(["candidates", data.id]);
    },
    // throwOnError: true,
  });
}

function useAnonymizeCandidate() {
  const queryClient = useQueryClient();
  return useMutation(anonymizeCandidate, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCandidate] useAnonymizeCandidate", "color: #1976D2", {
        data,
        // mutationVariables,
      });
      queryClient.invalidateQueries(["jobApplicationsForStage", mutationVariables.hiringStageId]);
      queryClient.invalidateQueries(["jobApplication", mutationVariables.jobApplicationId]);
      queryClient.invalidateQueries(["jobs", mutationVariables.jobId]);
      queryClient.invalidateQueries(["candidates", mutationVariables.candidateId]);
    },
    // throwOnError: true,
  });
}

export { useCandidate, useCreateCandidate, useUpdateCandidate, useAnonymizeCandidate };

import { css } from "@emotion/react";
import * as Feather from "react-feather";

const toPascalCase = (text) => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

const clearAndUpper = (text) => {
  return text.replace(/-/, "").toUpperCase();
};

const Icon = ({ name }) => {
  const SelectedIcon = Feather[toPascalCase(name)];
  return (
    <SelectedIcon
      css={css`
        height: 1.25em;
        width: 1.25em;
        stroke-width: 1.75px;
      `}
    />
  );
};

export default Icon;

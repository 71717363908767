import React from "react";

import { enabledOrDisabledOptions } from "@ats/src/lib/lookups";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSection from "@ats/src/components/forms/FormSection";
import Icon from "@ats/src/components/shared/Icon";

function AccountIntegrationsXHiring(props) {
  const addToast = useToastContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const { currentOrganization } = useCurrentSession();
  const [enableXHiring, setEnableXHiring] = React.useState(currentOrganization.enableXHiring);

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  const updateOrganizationSettings = (name, value) => {
    setIsDirty(true);
    setEnableXHiring(value);
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    updateOrganization(
      { id: currentOrganization.id, enableXHiring: enableXHiring },
      {
        onSuccess: () => {
          setIsDirty(false);
          addToast({
            title: `Successfully updated`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          addToast({
            title: `Failed to update`,
            kind: "danger",
          });
        },
      },
    );
  };

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[AccountIntegrationsXHiring] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={onClickSubmit}
        loading={false}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );

  const introDescription = (
    <>
      Post jobs for free to X's{"  "}
      <a href="https://x.com/jobs" target="_blank" rel="noreferrer noopener">
        platform
      </a>{" "}
      and organically reach millions of relevant candidates or become a{"  "}
      <a href="https://help.x.com/en/using-x/verified-organizations" target="_blank" rel="noreferrer noopener">
        Verified Organization
      </a>
      {"  "}to promote your jobs and feature roles on your X profile.
    </>
  );

  const description = "Jobs typically go live on X within 24 hours of publishing. First-time syncs may take more time.  If you are a Verified Organization, please reach out to X at xhiringsupport@x.com to sync your jobs to your X profile.";

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://help.polymer.co/en/articles/9415910-post-your-jobs-to-x-hiring"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="X Hiring"
      description={introDescription}
      bottomBar={BottomBar}
      fullWidthForm={true}
      actions={ViewLink}
    >
      <FormSection>
        <FormSelect
          onChange={updateOrganizationSettings}
          name="enableXHiring"
          label="Show jobs on X"
          description={description}
          value={enableXHiring}
          options={enabledOrDisabledOptions}
          errors={null}
        />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsXHiring;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./styles";

class AccountDeactivated extends Component {
  static displayName = "AccountDeactivated";

  static propTypes = {
    history: PropTypes.object,
  };

  render() {
    return (
      <div className="account-deactivated">
        <Helmet title="Account Deactivated" />
        <div className="">
          <h2>
            Your account has been deactivated. Please contact your company's account administrator.
          </h2>
        </div>
        <Link to="/logout">Log out</Link>
      </div>
    );
  }
}

export default withRouter(AccountDeactivated);

import { withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import { useHotkeys } from "react-hotkeys-hook";
import { Link } from "react-router-dom";
import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";
import ShortcutKey from "@ats/src/components/shared/ShortcutKey";
import Box from "@shared/components/Box";
import Text from "@shared/components/Text";
import breakpoint from "@shared/styles/breakpoints";
import { useModalContext } from "@shared/context/ModalContext";
// import { useWindowSize } from "@ats/src/context/WindowSizeContext";

type Props = {
  isBack: boolean;
  backUrl?: string;
  history: any;
  title: any;
  titleUrl?: string;
  secondary?: any;
  closeUrl?: string;
  externalUrl?: string;
  orgAdminJobsListUrl?: string;
};

function NavBar(props: Props) {
  const { title, titleUrl, history, closeUrl, secondary, isBack, backUrl, externalUrl, orgAdminJobsListUrl } = props;
  const { modal, isVisible } = useModalContext();
  // const { size, dimensions } = useWindowSize();
  let navButton;

  // window.logger("%c[NavBar][ModalContext] render", "color: #1976D2", {
  //   modal,
  // });

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "esc",
    (event) => {
      event.preventDefault();

      // If there is no modal being displayed then Navigate Back, otherwise let the event propagate up to the Modal
      if (modal == undefined) {
        event.stopPropagation();
        if (isBack) {
          history.push(backUrl);
        } else if (closeUrl) {
          handleClose(event);
        }
        return false;
      }
    },
    {},
    [modal], // Need to have the modal as a dependency because it changes
  );

  const handleClose = (e) => {
    e.preventDefault();
    if (orgAdminJobsListUrl) {
      history.push({
        pathname: closeUrl,
        state: {
          orgAdminJobsListUrl: orgAdminJobsListUrl //allows value to be pushed back to originating job on close of review kit
        }
      });
    } else {
      history.push(closeUrl);
    }
  };

  const backTooltip = (
    <>
      Back <ShortcutKey>Esc</ShortcutKey>
    </>
  );
  const closeTooltip = (
    <>
      Close <ShortcutKey>Esc</ShortcutKey>
    </>
  );

  if (isBack) {
    navButton = (
      <Tooltip label={backTooltip}>
        <StyledButton to={backUrl} aria-label="Navigate back to all jobs" as={Link}>
          <Icon name="arrow-left" />
        </StyledButton>
      </Tooltip>
    );
  } else if (closeUrl) {
    navButton = (
      <Tooltip label={closeTooltip}>
        <StyledButton onClick={handleClose}>
          <Icon name="x" />
        </StyledButton>
      </Tooltip>
    );
  } else if (externalUrl) {
    navButton = (
      <StyledButton href={externalUrl} aria-label="Navigate back" as={"a"}>
        <Icon name="x" />
      </StyledButton>
    );
  }

  return (
    <StyledContainer>
      <StyledMain>
        {navButton}
        <Text as="h2" weight="bold" size="lg">{title}</Text>
        {/* <div>
          Window is Width: {dimensions.width} x Height: {dimensions.height}. Size: {size}
        </div> */}
      </StyledMain>
      <StyledSecondary>{secondary}</StyledSecondary>
    </StyledContainer>
  );
}

export default withRouter(NavBar);

/* Styled Components
======================================================= */

const StyledContainer = styled(Box)`
  height: 48px;
  min-height: 48px;
  padding-left: 4px;
  padding-right: 16px;
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  border-bottom: 1px solid ${(props: any) => props.theme.poly.color.border};
  color: ${(props: any) => props.theme.poly.color.primaryText};

  ${breakpoint.sm} {
    padding-left: 8px;
  }
`;

const StyledMain = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  ${Text} {
    padding-left: 4px;
  }

  ${breakpoint.sm} {
    ${Text} {
      padding-left: 8px;
    }
  }
`;

const StyledSecondary = styled(Box)`
  display: block;
`;

const StyledButton = styled(Box)((props: any) => `
  width: 40px;
  height: 40px;
  border-radius: ${props.theme.poly.radii.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:focus {
    outline: none;
    background-color: ${props.theme.poly.color.loudHover};
    color: ${props.theme.poly.color.loudText};
  }

  ${breakpoint.sm} {
    width: 32px;
    height: 32px;
    &:hover {
      background-color: ${props.theme.poly.color.loudHover};
      color: ${props.theme.poly.color.loudText};
    }
  }
`);

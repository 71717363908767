import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useApplyPromoCode } from "@shared/queryHooks/useBilling";
import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  onCancel: () => void;
};

function PromoCodeCenterModal(props: Props) {
  const { onCancel } = props;
  // window.logger("%c[PromoCodeCenterModal] ", "color: #1976D2", { props });
  const addToast = useToastContext();
  const [promoCode, setPromoCode] = useState("");
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const { mutate: applyPromoCode, isLoading: isLoadingApplyPromoCode } = useApplyPromoCode();
  const { modal, dismissModalWithAnimation } = useModalContext();

  const handleOnCancel = () => {
    window.logger("%c[PromoCodeCenterModal] handleOnCancel", "color: #1976D2", { modal });
    if (modal) {
      dismissModalWithAnimation(() => onCancel);
    } else {
      onCancel();
    }
  };

  const handleChangeTitle = (_, value) => {
    // const code = value.toUpperCase();
    setPromoCode(value);
    setErrors([]);
    setIsDirty(true);
  };

  const handleSubmitPromoCode = async (event) => {
    event.preventDefault();
    applyPromoCode(
      { promoCode: (promoCode || "").toUpperCase() },
      {
        onSuccess: (data) => {
          window.logger("%c[PromoCodeCenterModal] applyPromoCode onSuccess", "color: #1976D2", {
            data,
          });
          addToast({
            title: `Code applied`,
            kind: "success",
          });
          handleOnCancel();
        },
        onError: (response: any) => {
          if (
            response.data.errors &&
            response.data.errors.general &&
            response.data.errors.general[0] != undefined
          )
            addToast({
              title: response.data.errors.general[0],
              kind: "warning",
            });
          window.logger("%c[PromoCodeCenterModal] applyPromoCode onError", "color: #ad1b47", {
            response,
          });
        },
      },
    );
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="applyPromoCodeButton"
        type="submit"
        onClick={handleSubmitPromoCode}
        loading={isLoadingApplyPromoCode}
        disabled={isLoadingApplyPromoCode}
      >
        Apply code
      </Button>
      <Button styleType="secondary" onClick={handleOnCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText="Use promo code" onCancel={handleOnCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Enter the code you would like to use. Once applied, your subscription will be updated to
        reflect the discount.
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <Styled.Input>
          <FormInput
            onChange={handleChangeTitle}
            name="title"
            placeholder=""
            label="Code"
            value={promoCode}
            errors={errors}
            autoFocus
          />
        </Styled.Input>
      </FormContainer>
    </CenterModal>
  );
}

PromoCodeCenterModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default PromoCodeCenterModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: PromoCodeCenterModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: PromoCodeCenterModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.Input = styled.div`
  input {
    text-transform: uppercase;
  }
`;

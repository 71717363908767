import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  onConfirm: ({}) => void;
  onCancel: ({}) => void;
};

function ConfirmTransferOwnershipModal(props: Props) {
  const { onConfirm, onCancel } = props;

  const { dismissModalWithAnimation } = useModalContext();

  const title = "Are you sure you want to transfer ownership?";

  return (
    <CenterModal headerTitleText={title} onCancel={onCancel}>
      <Styled.Instructions>
        <span>Once you confirm this action your role will be changed from Owner to Admin.</span> You
        will not be able to revert this change yourself, as you will no longer be the Owner.
      </Styled.Instructions>
      <Styled.ButtonContainer>
        <Button
          className="TransferOwnership-accept"
          onClick={() => dismissModalWithAnimation(() => onConfirm)}
          data-testid="transfer-ownership-confirm-button"
        >
          Transfer ownership
        </Button>
        <Button
          styleType="secondary"
          className="TransferOwnership-cancel"
          onClick={() => dismissModalWithAnimation(() => onCancel)}
        >
          Cancel
        </Button>
      </Styled.ButtonContainer>
    </CenterModal>
  );
}

export default ConfirmTransferOwnershipModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ConfirmTransferOwnershipModal_Instructions;
    ${[t.mb(5)]}

    span {
      ${[t.text.bold]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ConfirmTransferOwnershipModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut, apiDelete } from "@shared/queryHooks/api";

const getHiringStages = async (jobId) => {
  if (jobId != undefined) {
    return await apiGet({ path: `/jobs/${jobId}/hiring_stages` });
  }
};

const createHiringStage = async ({ jobId, ...variables }) => {
  return await apiPost({ path: `/jobs/${jobId}/hiring_stages`, variables });
};

const updateHiringStage = async ({ jobId, id, ...variables }) => {
  return await apiPut({ path: `/jobs/${jobId}/hiring_stages/${id}`, variables });
};

const deleteHiringStage = async ({ jobId, id, ...variables }) => {
  return await apiDelete({ path: `/jobs/${jobId}/hiring_stages/${id}`, variables });
};

/* HOOKS
--===================================================-- */
function useCreateHiringStage() {
  const queryClient = useQueryClient();
  return useMutation(createHiringStage, {
    // After success or failure, refetch the jobCategories query
    onSettled: (data) => {
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useUpdateHiringStage() {
  const queryClient = useQueryClient();
  return useMutation(updateHiringStage, {
    onSettled: (data) => {
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useDeleteHiringStage() {
  const queryClient = useQueryClient();
  return useMutation(deleteHiringStage, {
    onSettled: (data) => {
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
    // throwOnError: true,
  });
}

function useHiringStages({
  jobId,
  refetchOnWindowFocus = false,
}: {
  jobId?: number;
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
} {
  return useQuery(["hiringStages", jobId], () => getHiringStages(jobId), { refetchOnWindowFocus });
}

export { useHiringStages, useCreateHiringStage, useUpdateHiringStage, useDeleteHiringStage };

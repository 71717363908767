import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function Logo() {
  return (
    <Styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      width="426"
      height="560"
      viewBox="0 0 426 560"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.243 360.513V211.664C182.243 178.207 155.176 151.084 121.788 151.084C90.3829 151.084 64.5708 175.081 61.6156 205.774C61.5289 207.879 61.4851 209.995 61.4851 212.122C61.4851 285.263 113.31 346.299 182.243 360.513ZM0.88705 210.243C0.881626 210.716 0.878906 211.19 0.878906 211.664V212.122V438.839C0.878906 505.754 55.0118 560 121.788 560C188.564 560 242.697 505.754 242.697 438.839V422.181C345.79 407.738 425.122 319.194 425.122 212.122C425.122 94.97 330.152 0 213 0C96.4757 0 1.89575 93.9565 0.88705 210.243ZM122.615 90.5066C147.848 71.7227 179.126 60.6061 213 60.6061C296.68 60.6061 364.516 128.442 364.516 212.122C364.516 285.637 312.158 346.924 242.697 360.728V211.664C242.697 145.025 189.011 90.9521 122.615 90.5066ZM182.243 422.029V438.839C182.243 472.297 155.176 499.419 121.788 499.419C88.4 499.419 61.3335 472.297 61.3335 438.839V360.421C93.0758 392.879 135.153 415.189 182.243 422.029Z"
        fill="currentColor"
      />
    </Styled.Svg>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Svg = styled.svg((props) => {
  const t = props.theme;
  return css`
    label: Logo;
    height: 32px;
    width: auto;
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FullModal from "@ats/src/components/modals/FullModal";
import Button from "@ats/src/components/shared/Button";
import { useModalContext } from "@shared/context/ModalContext";

type ChannelMessage = {
  bodySanitizedHtml: string;
  // bodyRawHtml: string;
};

type Props = {
  onCancel: () => void;
  onComplete: () => void;
  channelMessage: ChannelMessage;
};

function OriginalEmailModal(props: Props) {
  const { channelMessage } = props;

  window.logger("%c[OriginalMessageModal] RENDER", "color: #1976D2", { channelMessage });

  const { dismissModalWithAnimation } = useModalContext();

  return (
    <FullModal onCancel={() => dismissModalWithAnimation(() => props.onCancel)}>
      <Styled.Header>
        <h2>Original incoming email</h2>
        <Button styleType="secondary" onClick={() => dismissModalWithAnimation(() => props.onCancel)}>Dismiss</Button>
      </Styled.Header>
      <Styled.Body>
        <div dangerouslySetInnerHTML={{ __html: channelMessage.bodySanitizedHtml }} />
        {/* <hr />
        <div dangerouslySetInnerHTML={{ __html: channelMessage.bodyRawHtml }} /> */}
      </Styled.Body>
    </FullModal>
  );
}

export default OriginalEmailModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OriginalEmailModal_Header;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.Body = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OriginalEmailModal_Body;
    ${[t.m(4), t.p(4), t.rounded.md]}
    background: ${t.color.white};
    border: 1px solid ${t.color.gray[200]};
    a {
      text-decoration: underline;
    }
  `;
});

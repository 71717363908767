import React from "react";
import toLower from "lodash/toLower";

import { userRoleOptions, findSelectOptionByValue } from "@ats/src/lib/lookups";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormContainer from "@ats/src/components/forms/FormContainer";
import Icon from "@ats/src/components/shared/Icon";
import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";

import { useAllInvites, useCreateInvite } from "@shared/queryHooks/useInvite";
import { validateInviteEmail } from "@shared/lib/validateWithYup";

import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function NewInviteForm(props) {
  const { onCancel, onFormUpdate } = props;
  const { currentOrganization } = useCurrentSession();
  const addToast = useToastContext();
  const { mutate: createInvite, isLoading: isLoadingCreateInvite } = useCreateInvite();
  const { data: invitesData, isLoading: isLoadingInvites } = useAllInvites();

  // window.logger("%c[NewInviteForm] Render", "background-color: #7db4ec", {
  //   currentOrganization,
  //   invitesData,
  //   isLoadingInvites
  // });

  const [state, setState] = React.useState({
    email: "",
    role: "org_user",
    errors: null,
  });

  const { email, role, errors } = state;
  const { roleAbilities } = findSelectOptionByValue(userRoleOptions, role);

  const isViableEmail = (email: string) => {
    //create arrays with emails of current users and invited users (not accepted yet)
    const teamMembersEmails = currentOrganization?.organizationUsers?.map(
      (organizationUser) => organizationUser.user.email,
    );
    const invitedTeamMembersEmails = invitesData?.items?.map((invite) => invite.email);
    //check for new email in arrays
    const emailDuplicated = teamMembersEmails.includes(email);
    const invitedEmailDuplicated = invitedTeamMembersEmails.includes(email);
    //return false if email found --> it is not a viable email
    const result = emailDuplicated || invitedEmailDuplicated ? false : true;
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // pass in isViableEmail as callback for yup validation
    const [isValid, validationErrors] = await validateInviteEmail({ email }, isViableEmail);

    if (isValid) {
      createInvite(
        { email: toLower(email), role },
        {
          onSuccess: (data) => {
            addToast({ title: `Invited ${data.email}`, kind: "success" });
            setState({ ...state, email: "", errors: {} });
            onCancel();
          },
        },
      );
    } else {
      setState({ ...state, errors: validationErrors });
    }
  };

  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value, errors: null });
    if (onFormUpdate) {
      onFormUpdate();
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button type="submit" loading={isLoadingCreateInvite} disabled={isLoadingCreateInvite}>
        Send invite
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <FormContainer
      onSubmit={handleSubmit}
      errors={errors}
      buttons={submitButton}
      className="invite-form"
    >
      <FormInput
        // scope={scope}
        onChange={handleInputChange}
        name="email"
        placeholder=""
        label="Email address"
        type="email"
        className=""
        value={props.email}
        errors={props.errors}
      />

      <FormSelect
        // scope={scope}
        onChange={handleInputChange}
        name="role"
        label="Role"
        description=""
        className=""
        value={role}
        options={userRoleOptions}
        errors={errors}
      />

      <Styled.RoleAbilities>
        <Icon name="info" />
        <span>{roleAbilities}</span>
      </Styled.RoleAbilities>
    </FormContainer>
  );
}

export default NewInviteForm;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.RoleAbilities = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: NewInviteForm_Instructions;
    ${[t.p(2), t.mb(6), t.rounded.sm]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    min-height: ${t.spacing[8]};
    display: flex;
    align-items: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    span {
      ${t.ml(2)};
    }
    svg {
      flex-shrink: 0;
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NewInviteForm_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import textStyles from "@ats/styles/text";

import { prettyDateAndTime } from "@shared/lib/time";

import Tooltip from "@ats/src/components/shared/Tooltip";
import Icon from "@ats/src/components/shared/Icon";
import UserAvatar from "@ats/src/components/shared/UserAvatar";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import ChannelMessageTemplateModal from "@ats/src/components/modals/ChannelMessageTemplateModal";
import OriginalEmailModal from "@ats/src/views/jobApplications/channelMessages/OriginalEmailModal";

import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import OrgLogo from "@ats/src/components/shared/OrgLogo";

function ChannelMessageListItem(props: { message: any }) {
  const { message } = props;
  const { sentBy, user, candidate, htmlSafeBody } = message;
  const { openModal, removeModal } = useModalContext();
  const { currentOrganization } = useCurrentSession();

  let sender;

  if (sentBy === "sent_by_user") {
    sender = user;
  } else if (sentBy === "sent_by_candidate") {
    sender = candidate;
  }

  const onClickCreateTemplate = (event) => {
    event.preventDefault();

    const modal = (
      <ChannelMessageTemplateModal
        onCancel={removeModal}
        isEditing={false}
        channelMessageTemplate={{ id: null, name: "", body: message.body }}
      />
    );

    window.logger("%c[ChannelMessageListItem] onClickCreateTemplate", "color: #1976D2", {
      message,
      modal,
    });

    openModal(modal);
  };

  const onClickViewOriginal = (event) => {
    event.preventDefault();

    const modal = (
      <OriginalEmailModal
        channelMessage={message}
        onCancel={removeModal}
        onComplete={removeModal}
      />
    );

    openModal(modal);
  };

  const getSender = () => {
    const logo =
      sentBy === "sent_by_organization" ? (
        <OrgLogo {...currentOrganization} />
      ) : (
        <UserAvatar user={sender} size="lg" />
      );
    const name = sentBy === "sent_by_organization" ? currentOrganization?.name : sender?.fullName;

    return (
      <Styled.Sender>
        {logo}
        <Styled.Attribution>
          <div>
            <h3>{name}</h3>
            {sender === candidate && <Styled.CandidateBadge>Candidate</Styled.CandidateBadge>}
            <Styled.Seperator>‧</Styled.Seperator>
            <Tooltip label={prettyDateAndTime(message.createdAtTimestamp)}>
              <span>{message.createdAtTimeAgoShort}</span>
            </Tooltip>
          </div>
          <p>Sent via email</p>
        </Styled.Attribution>
      </Styled.Sender>
    );
  };

  const getActions = (action) => {
    if (sender !== user && sender !== candidate) return null;
    return (
      <Styled.Actions className="messageActions" data-testid="messages-dropdown-menu">
        <DropdownMenu label="Message options" button={false}>
          {action}
        </DropdownMenu>
      </Styled.Actions>
    );
  };

  return (
    <Styled.Container sentByUser={sender === user}>
      <Styled.Header>
        {getSender()}
        {sender === user &&
          getActions(<button onClick={onClickCreateTemplate}>Create template</button>)}
        {sender === candidate &&
          message.bodySanitizedHtml != undefined &&
          getActions(<button onClick={onClickViewOriginal}>View original</button>)}
      </Styled.Header>

      <Styled.MessageBody className="messageBody">
        <Styled.MessageText dangerouslySetInnerHTML={{ __html: htmlSafeBody }} />
        {message.filesArray.length > 0 ? (
          <Styled.FileList>
            {message.filesArray.map((file, index) => {
              return (
                <Styled.FileButton
                  key={`${file.filename}-${index}`}
                  href={file.url}
                  target="_blank"
                >
                  <Icon name="paperclip" />
                  <span>{file.filename}</span>
                </Styled.FileButton>
              );
            })}
          </Styled.FileList>
        ) : null}
      </Styled.MessageBody>
    </Styled.Container>
  );
}

ChannelMessageListItem.propTypes = {};

ChannelMessageListItem.defaultProps = {};

export default ChannelMessageListItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: ChannelMessageListItem;
    ${[t.mx(4), t.mb(4), t.px(4), t.pt(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    min-height: 4.5rem;

    &:first-of-type {
      ${t.mt(4)}
    }
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_Header;
    display: flex;
    justify-content: space-between;
  `;
});

Styled.Sender = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_Sender;
    display: flex;
    align-items: center;
  `;
});

Styled.MessageBody = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_MessageBody;
    flex: 1;
    overflow-x: hidden;
  `;
});

Styled.Attribution = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_Attribution;
    ${[t.ml(3)]}

    > div {
      ${[t.mb(1)]}
      display: flex;
      align-items: center;
    }

    h3 {
      ${[t.text.bold, t.mr("px")]}
    }

    span {
      ${[t.text.xs, t.pt("px")]}
      display: block;
      cursor: default;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    p {
      ${[t.text.xs]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      line-height: 1.15;
    }
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_Seperator;
    ${t.mx(1)}
  `;
});

Styled.CandidateBadge = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_CandidateBadge;
    ${[t.h(4), t.ml(1), t.rounded.xs]};
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    font-size: 0.625rem;
    border: 1px solid ${t.color.orange[200]};
    background: ${t.dark
      ? "linear-gradient(120deg, #FBD7FF -40%, #FFDEC1 80%)"
      : "linear-gradient(120deg, #FFD8D8 -40%, #FFDEC1 80%)"};
    color: ${t.color.black};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `;
});

Styled.MessageText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_MessageText;
    ${[t.mt(4), textStyles]}
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_Actions;
  `;
});

Styled.FileList = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_FileList;
    ${[t.mb(2)]}
    display: flex;
    flex-wrap: wrap;
  `;
});

Styled.FileButton = styled.a((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageListItem_FileButton;
    ${[t.px(3), t.mr(1), t.mb(1), t.text.xs, t.rounded.sm]}
    min-height: ${t.spacing[8]};
    display: flex;
    align-items: center;
    transition: border 0.2s ease;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    max-width: 100%;

    svg {
      ${[t.mr(1)]}
      flex-shrink: 0;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[400]};
    }
  `;
});

import React from "react";
import queryString from "query-string";

import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";
import Button from "@ats/src/components/shared/Button";

import { validateOnboardingProfile } from "@shared/lib/validateWithYup";

import { useUpdateMe } from "@shared/queryHooks/useMe";

function ProfileForm(props) {
  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = React.useState(null);

  const { firstName, lastName } = state;
  const { mutate: updateMe, isLoading: isLoadingUpdateMe } = useUpdateMe();

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.logger("%c[ProfileForm] handleSubmit state", "color: #1976D2", state);

    setErrors(null);

    const [isValid, validationErrors] = await validateOnboardingProfile({
      firstName,
      lastName,
    });

    if (isValid) {
      updateMe(
        {
          user: {
            firstName,
            lastName,
          },
        },
        {
          onSuccess: (data) => {
            window.logger("[ProfileForm] onScucess", { data });
            props.onComplete();
          },
          onError: (response: any) => {
            window.logger("[ProfileForm] server error", { response });
            if (response.data.errors) {
              setErrors(response.data.errors);
            }
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (name, value) => {
    setErrors(null);
    setState({ ...state, [name]: value });
  };

  const button = (
    <Button
      type="submit"
      disabled={isLoadingUpdateMe}
      loading={isLoadingUpdateMe}
      isTracked={false}
    >
      Next
    </Button>
  );

  return (
    <FormContainer onSubmit={handleSubmit} errors={errors} buttons={button}>
      <FormInput
        onChange={handleInputChange}
        name="firstName"
        placeholder=""
        label="First name"
        className=""
        errors={errors}
      />

      <FormInput
        onChange={handleInputChange}
        name="lastName"
        placeholder=""
        label="Last name"
        className=""
        errors={errors}
      />
    </FormContainer>
  );
}

export default ProfileForm;

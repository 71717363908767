import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { yesOrNoOptions } from "@ats/src/lib/lookups";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";
import { useSlackChannelList, useCreateSlackChannelIntegration } from "@shared/queryHooks/useSlack";
import { useDeleteOauthAuthentication } from "@shared/queryHooks/useOauth";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSection from "@ats/src/components/forms/FormSection";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import Icon from "@ats/src/components/shared/Icon";
import OauthConnectButton from "./OuathConnectButton";

function AccountIntegrationsSlack(props) {
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const { currentOrganization } = useCurrentSession();
  const [settings, setSettings] = React.useState(currentOrganization.settings);

  const { data: slackChannelList, isLoading: isLoadingSlackChannelList } = useSlackChannelList({
    enabled: currentOrganization?.hasSlackAuthentication,
  });
  const {
    mutate: createSlackChannelIntegration,
    isLoading: isLoadingCreateSlackChannelIntegration,
  } = useCreateSlackChannelIntegration();
  const {
    mutate: deleteOauthAuthentication,
    isLoading: isLoadingDeleteOauthAuthentication,
  } = useDeleteOauthAuthentication();

  window.logger("%c[AccountIntegrationsSlack] ", "color: #1976D2", {
    slackChannelList,
    currentOrganization,
  });

  const channelOptions = () => {
    return slackChannelList.map((channel) => {
      return { value: channel.id, label: `#${channel.name}` };
    });
  };

  const handleChannelSelection = (fieldName, value) => {
    // if (value === siteId) {
    //   return;
    // }

    const channel = slackChannelList.find((channel) => channel.id === value);
    const { id, name, nameNormalized } = channel;
    window.logger("%c[AccountIntegrationsSlack] ", "color: #1976D2", { fieldName, value, channel });

    createSlackChannelIntegration(
      {
        channelId: id,
        channelName: name,
        channelNameNormalized: nameNormalized,
      },
      {
        onSuccess: (data) => {
          window.logger("%c[AIS] created Slack Channel ", "color: #1976D2", { data });
        },
      },
    );
  };

  const handleDisconnectSlack = (event) => {
    event.preventDefault();

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to disconnect?"
        subcopy="New job applications will no longer post to Slack."
        confirmText="Disconnect"
        isDestructiveConfirm={true}
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          deleteOauthAuthentication(
            {
              provider: "slack",
            },
            {
              onSuccess: (data) => {
                window.logger("%c[AIS] deleted Slack Oauth Authentication", "color: #1976D2", {
                  data,
                });
              },
            },
          );
        }}
        onCancel={removeModal}
      />
    );

    openModal(modal);
  };

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://help.polymer.co/en/articles/5721143-have-new-candidate-notifications-show-up-in-a-slack-workspace"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>
    </>
  );

  const connectionActions = (
    <>
      {!currentOrganization.hasSlackAuthentication ? (
        // <Button
        //   className="submit-button"
        //   type="externalLink"
        //   target=""
        //   // link={`/auth/slack`}
        //   link={`/auth/slack?current_organization_id=${currentOrganization.id}`}
        //   aria-label="Connect Slack"
        //   loading={isLoadingSlackChannelList}
        // >
        //   Connect to Slack
        // </Button>

        <OauthConnectButton
          path={`/auth/slack?current_organization_id=${currentOrganization.id}`}
          label="Connect to Slack"
        />
      ) : (
        <Button
          className="submit-button"
          styleType="secondary"
          // type="externalLink"
          // target=""
          // link={`/auth/slack`}
          onClick={handleDisconnectSlack}
          aria-label="Disconnect Slack"
          loading={
            isLoadingSlackChannelList ||
            isLoadingDeleteOauthAuthentication ||
            isLoadingCreateSlackChannelIntegration
          }
          disabled={
            isLoadingSlackChannelList ||
            isLoadingDeleteOauthAuthentication ||
            isLoadingCreateSlackChannelIntegration
          }
        >
          Disconnect Slack
        </Button>
      )}
    </>
  );

  return (
    <SettingsContainer
      title="Slack"
      description="Notify a Slack channel whenever a new job application is received. The full candidate application is displayed, making it easy to review and discuss."
      actions={ViewLink}
      fullWidthForm={true}
    >
      {currentOrganization?.hasSlackAuthentication &&
        slackChannelList != undefined &&
        slackChannelList.length > 0 ? (
        <FormSection>
          <FormSelect
            onChange={handleChannelSelection}
            name="channelId"
            label="Channel"
            description="Select the Slack channel to post job applications to"
            className=""
            value={currentOrganization?.slackChannelIntegration?.channelId}
            options={channelOptions()}
            errors={null}
          />
        </FormSection>
      ) : null}
      {connectionActions}
    </SettingsContainer>
  );
}

export default AccountIntegrationsSlack;


import { useQuery } from "react-query";
import { apiGet } from "@shared/queryHooks/api";

const getChannels = async (jobApplicationId) => {
  return await apiGet({ path: `/job_applications/${jobApplicationId}/channels` });
};

const getChannel = async (channelId) => {
  window.logger("%c[useChannel] getChannel", "background: #19d219", { channelId });
  if (channelId != undefined) {
    return await apiGet({ path: `/channels/${channelId}` });
  }
};

function useChannels(
  jobApplicationId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
} {
  return useQuery(["channels", jobApplicationId], () => getChannels(jobApplicationId));
}

function useChannel(
  channel,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useQuery(["channel", channel ? channel.id : null], () => getChannel(channel?.id), {
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => !error.message.includes(401) && !error.message.includes(403), // Add the 403 because not sure if this is login or role related
  });
}

export { useChannels, useChannel, getChannel };

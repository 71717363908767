import { useMutation } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const postUniversalSearch = async ({ searchTerm }) => {
  return await apiPost({ path: "/universal_search", variables: { searchTerm } });
};

export function useUniversalSearch() {
  return useMutation(postUniversalSearch, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // onSuccess: (data, variables) => {
    //   queryClient.setQueryData(["jobCategory", { id: variables.id }], data);
    // },
    // Error
    // onError: (data, variables) => {
    //   window.logger(
    //     "%c[useJobCategory] handle error when creating Job Category",
    //     "background-color: #19F6D2",
    //     {
    //       data,
    //       variables,
    //     },
    //   );
    // },
    // After success or failure, refetch the jobCategories query
    onSettled: () => {
      // queryClient.invalidateQueries("jobCategories");
    },
    // throwOnError: true,
  });
}

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import AppAuthRouter from "@ats/src/views/layouts/AppAuthRouter";
// import AppUnauthed from "@ats/src/views/layouts/AppUnauthed";

import { ColorSchemeProvider } from "@shared/context/ColorSchemeContext";
import GlobalScrollbarStyles from '@shared/components/GlobalScrollbarStyles';

// MAIN APP
/***********************************/
function App(props) {
  /* REACT QUERY
  --===================================================-- */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  window.logger("%c\n\n\n[App] render", "background-color: #b0d219", {
    props,
    queryClient,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ColorSchemeProvider>
        <BrowserRouter>
          <>
            <Helmet
              htmlAttributes={{ lang: "en", amp: undefined }} // amp takes no value
              // title="Welcome" // if this is used it overwrites all other titles
              titleTemplate="%s | Polymer"
              defaultTitle="Polymer"
              titleAttributes={{ itemprop: "name", lang: "en" }}
            >
              <meta name="viewport" content="width=device-width,initial-scale=1" />
            </Helmet>
            <GlobalScrollbarStyles />
            <AppAuthRouter />

            {window.IS_DEVELOPMENT ? <ReactQueryDevtools initialIsOpen={false} /> : null}
          </>
        </BrowserRouter>
      </ColorSchemeProvider>
    </QueryClientProvider>
  );
}

export default App;

import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Icon from "@ats/src/components/shared/Icon";

type Props = {
  title?: string;
  message: string | object;
  icon?: string;
  borderless?: boolean;
  roomy?: boolean;
};

const EmptyState = ({ title, message, icon, borderless, roomy }: Props) => {
  return (
    <Styled.Container borderless={borderless} roomy={roomy}>
      {icon && (
        <Styled.IconWrapper>
          <Icon name={icon} />
        </Styled.IconWrapper>
      )}
      {title && <h2>{title}</h2>}
      {message && <p>{message}</p>}
    </Styled.Container>
  );
};

export default EmptyState;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: EmptyState;
    ${[t.px(6), t.rounded.md]}
    ${props.roomy ? t.py(8) : t.py(5)}
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${!props.borderless
      ? `
        border: 1px solid ${t.dark ? "transparent" : t.color.gray[200]};
        background-color: ${t.dark ? "rgba(255,255,255,0.07)" : "transparent"};
      `
      : ""}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    h2 {
      ${[t.text.sm, t.mb(1)]};
    }
    p {
      ${t.text.xs}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.IconWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: EmptyState_IconWrapper;
    ${[t.text.sm, t.mb(2)]}
  `;
});

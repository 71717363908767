import { useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createSupportMessage = async ({
  subject,
  body,
  kind,
  replyToEmail,
}: {
  subject: string;
  body: string;
  kind?: string;
  replyToEmail?: string;
}) => {
  return await apiPost({
    path: `/support_messages`,
    variables: { subject, body, kind, replyToEmail }
  });
};


/* HOOKS
--===================================================-- */
export function useCreateSupportMessage() {
  const queryClient = useQueryClient();
  return useMutation(createSupportMessage, {
    onSuccess: (
      data,
      mutationVariables: {
        subject: string;
        body: string;
        kind?: string;
        replyToEmail?: string;
      },
    ) => {
      window.logger("[useSupportMessage] useCreateSupportMessage", {
        data,
        mutationVariables,
        queryClient,
      });
      queryClient.invalidateQueries("supportMessages");
    },
    // throwOnError: true,
  });
}


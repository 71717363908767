import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost } from "@shared/queryHooks/api";
import queryString from "query-string";

const getJobApplication = async (jobApplicationId) => {
  // window.logger(
  //   "%c[useJobApplication][JobApplicationNotes] getJobApplication\n\n\n\n",
  //   "background-color: #00F6D2",
  //   {
  //     jobApplicationId,
  //   },
  // );
  if (jobApplicationId != undefined) {
    return await apiGet({
      path: `/job_applications/${jobApplicationId}`,
    });
  }
};

const getJobApplications = async (jobId, hiringStageId, page = null) => {
  if (jobId != undefined) {
    const params =
      hiringStageId != undefined
        ? `?${queryString.stringify({ hiring_stage: hiringStageId, page })}`
        : "";
    window.logger("%c[useJobApplication] getJobApplications\n\n\n\n", "background-color: #FF76D2", {
      jobId,
      hiringStageId,
      params,
    });
    return await apiGet({ path: `/jobs/${jobId}/job_applications${params}` });
  }
};

const getInfiniteJobApplications = async ({
  jobId,
  hiringStageId,
  page = null,
}: {
  jobId?: number;
  hiringStageId?: number;
  page?: number;
}) => {
  if (jobId != undefined && page != undefined) {
    const params =
      hiringStageId != undefined
        ? `?${queryString.stringify({ hiring_stage: hiringStageId, page })}`
        : "";
    window.logger(
      "%c[useJobApplication] getInfiniteJobApplications\n\n\n\n",
      "background-color: #FF76D2",
      {
        jobId,
        hiringStageId,
        params,
      },
    );
    return await apiGet({ path: `/jobs/${jobId}/job_applications${params}` });
  }
};

const updateJobApplication = async ({ id, ...jobApplication }) => {
  window.logger("%c[useJobApplication] updateJobApplication\n\n\n\n", "background-color: #FF76D2", {
    id,
    jobApplication,
  });
  return await apiPut({
    path: `/job_applications/${id}`,
    variables: { jobApplication: jobApplication },
  });
};

const cloneToNewJobAtHiringStage = async ({ id, hiringStageId }) => {
  window.logger("%c[useJobApplication] updateJobApplication\n\n\n\n", "background-color: #FF76D2", {
    id,
    hiringStageId,
  });
  return await apiPut({
    path: `/job_applications/${id}/clone_to_job`,
    variables: { hiringStageId: hiringStageId },
  });
};

const moveToNewJobAtHiringStage = async ({ id, hiringStageId, jobId }) => {
  window.logger("%c[useJobApplication] updateJobApplication\n\n\n\n", "background-color: #FF76D2", {
    id,
    hiringStageId,
    jobId,
  });
  return await apiPut({
    path: `/job_applications/${id}/move_to_job`,
    variables: { hiringStageId: hiringStageId },
  });
};

const bulkMoveJobApplicationsToStage = async ({
  jobId,
  targetHiringStageId,
  sourceHiringStageId,
  includedJobApplicationIds = null,
  excludedJobApplicationIds = null,
}) => {
  window.logger(
    "%c[useJobApplication] bulkMoveJobApplicationsToStage",
    "background-color: #FF76D2",
    {
      targetHiringStageId,
      sourceHiringStageId,
      includedJobApplicationIds,
      excludedJobApplicationIds,
    },
  );
  return await apiPost({
    path: `/jobs/${jobId}/bulk_move_job_applications_to_stage`,
    variables: {
      targetHiringStageId,
      sourceHiringStageId,
      includedJobApplicationIds,
      excludedJobApplicationIds,
    },
  });
};

function useJobApplications({
  jobId,
  refetchOnWindowFocus = false,
  stageId,
  page,
}: {
  jobId: number;
  refetchOnWindowFocus?: boolean;
  stageId?: number;
  page?: number;
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  return useQuery(
    ["jobApplications", jobId, Number(stageId)],
    () => getJobApplications(jobId, Number(stageId), page),
    {
      // enabled: job
      refetchOnWindowFocus,
      keepPreviousData: true,
    },
  );
}

function useInfiniteJobApplicationsForStage({
  jobId,
  // refetchOnWindowFocus = false,
  stageId,
}: {
  jobId: number;
  // refetchOnWindowFocus?: boolean;
  stageId?: number;
}): {
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isFetchingNextPage?: string | boolean; // isFetchingMore
  fetchNextPage: () => any; // fetchMore
  hasNextPage?: boolean; // canFetchMore
} {
  return useInfiniteQuery(
    ["jobApplicationsForStage", Number(stageId)],
    ({ pageParam = 1 }) =>
      getInfiniteJobApplications({ jobId, hiringStageId: Number(stageId), page: pageParam }),
    {
      onSuccess: (data) => {
        window.logger(
          "%c[useJobApplication] useInfiniteJobApplicationsForStage",
          "color: #1976D2",
          data,
        );
      },
      onSettled: () => {
        // TODO
      },
      // This generates the "pageParam"
      getNextPageParam: (lastPage, allPages) => {
        // window.logger(
        //   "%c[useJobApplication] useInfiniteJobApplicationsForStage",
        //   "background-color: #009DD2",
        //   {
        //     lastPage,
        //     allPages,
        //   },
        // );
        return lastPage?.meta.nextPage;
      },
      // refetchOnWindowFocus,
      // keepPreviousData: true,
    },
  );
}

function useUpdateJobApplication() {
  const queryClient = useQueryClient();
  return useMutation(updateJobApplication, {
    onSuccess: (data, mutationVariables) => {
      // window.logger(
      //   "%c[useJobApplication] useUpdateJobApplication onSuccess",
      //   "color: #1976D2",
      //   {
      //     data,
      //     mutationVariables,
      //   },
      // );
      queryClient.setQueryData(["jobApplication", data.id], {
        ...data,
      });
      queryClient.invalidateQueries(["jobApplication", data.id]);
    },
    // throwOnError: true,
  });
}

function useMoveJobApplicationToStage() {
  const queryClient = useQueryClient();
  return useMutation(updateJobApplication, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useJobApplication] useMoveJobApplicationToStage onSuccess",
        "background: #1046D2, color: #FFFFFF;",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.refetchQueries(["jobs", data.jobId], { exact: true });
      queryClient.invalidateQueries(["jobApplication", data.id]); // refetches the JobApplication
      // queryClient.refetchQueries(["jobApplications", data.jobId]); // not sure this is necessary
      // queryClient.refetchQueries(["jobApplicationsForStage", data.hiringStageId]); // refetches the jobApplications for this hiring stage
      queryClient.invalidateQueries("jobApplicationsForStage"); // refetches the jobApplications for this hiring stage
    },
    // throwOnError: true,
  });
}

function useBulkMoveJobApplicationsToStage() {
  const queryClient = useQueryClient();
  return useMutation(bulkMoveJobApplicationsToStage, {
    onSuccess: async (data, mutationVariables) => {
      window.logger(
        "%c[useJobApplication] useBulkMoveJobApplicationsToStage onSuccess",
        "background: #1046D2, color: #FFFFFF;",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.refetchQueries(["jobs", mutationVariables.jobId], { exact: true }); // refetches the Job
      // queryClient.removeQueries(
      //   ["jobApplicationsForStage", mutationVariables.targetHiringStageId],
      //   { exact: true },
      // );
      queryClient.invalidateQueries("jobApplication");
      queryClient.removeQueries("jobApplication", { stale: true })
      queryClient.invalidateQueries("jobApplicationsForStage");
      try {
        await queryClient.refetchQueries(
          ["jobApplicationsForStage", mutationVariables.targetHiringStageId],
          { inactive: true, active: true, exact: true },
          { throwOnError: true },
        );
        await queryClient.refetchQueries(
          ["jobApplicationsForStage", mutationVariables.sourceHiringStageId],
          { inactive: true, active: true, exact: true },
          { throwOnError: true },
        );
      } catch (error) {
        console.error("Failed to refetch queries: ", error);
      }
      // queryClient.refetchQueries(["jobApplications", mutationVariables.jobId]); // refetches the jobApplications for this job
      // queryClient.refetchQueries(["jobApplicationsForStage", mutationVariables.targetHiringStageId]); // refetches the jobApplications for this hiring stage
      // queryClient.invalidateQueries("jobApplicationsForStage"); // refetches the jobApplications for this hiring stage
    },
    // throwOnError: true,
  });
}

function useCloneToNewJobAtHiringStage() {
  const queryClient = useQueryClient();
  return useMutation(cloneToNewJobAtHiringStage, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useJobApplication] useCloneToNewJobAtHiringStage onSuccess",
        "background: #1046D2, color: #FFFFFF;",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.refetchQueries(["jobs", data.jobId], { exact: true });
      queryClient.invalidateQueries(["jobApplication", data.id]); // refetches the JobApplication
      // queryClient.refetchQueries(["jobApplications", data.jobId]); // refetches the jobApplications for this job
      queryClient.refetchQueries(["jobApplicationsForStage", data.hiringStageId]); // refetches the jobApplications for this hiring stage
    },
    // throwOnError: true,
  });
}

function useMoveToNewJobAtHiringStage() {
  const queryClient = useQueryClient();
  return useMutation(moveToNewJobAtHiringStage, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useJobApplication] useMoveToNewJobAtHiringStage onSuccess",
        "background: #1046D2, color: #FFFFFF;",
        {
          data,
          mutationVariables,
        },
      );
      // queryClient.refetchQueries(["jobs", data.jobId], { exact: true });
      queryClient.invalidateQueries(["jobs", mutationVariables.jobId], { exact: true });
      // queryClient.invalidateQueries("jobs");
      // queryClient.invalidateQueries(["jobApplication", data.id]); // refetches the JobApplication
      // queryClient.invalidateQueries(["jobApplication", mutationVariables.id]); // refetches the JobApplication
      queryClient.invalidateQueries("jobApplication"); // refetches the JobApplication
      // queryClient.refetchQueries(["jobApplications", data.jobId]); // refetches the jobApplications for this job
      queryClient.invalidateQueries("jobApplicationsForStage"); // refetches the jobApplications for this hiring stage
      // queryClient.refetchQueries(["jobApplicationsForStage", data.hiringStageId]); // refetches the jobApplications for this hiring stage
      // queryClient.invalidateQueries(["jobApplicationsForStage", mutationVariables.hiringStageId]); // refetches the jobApplications for this hiring stage
    },
    // throwOnError: true,
  });
}

function useJobApplication({
  jobApplicationId,
  initialData,
  refetchOnWindowFocus = false,
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  return useQuery(["jobApplication", jobApplicationId], () => getJobApplication(jobApplicationId), {
    initialData,
    refetchOnWindowFocus,
  });
}

export {
  useJobApplications,
  useInfiniteJobApplicationsForStage,
  getInfiniteJobApplications,
  // prefetchJobApplications,
  useJobApplication,
  useUpdateJobApplication,
  // useFireAndForgetUpdateJobApplication, // Does not invalidate/refetch queries
  useMoveJobApplicationToStage,
  useBulkMoveJobApplicationsToStage,
  useCloneToNewJobAtHiringStage,
  useMoveToNewJobAtHiringStage,
};

import { useQuery } from "react-query";
import { apiGet } from "@shared/queryHooks/api";

const getJobApplicationActivities = async ({ jobApplicationId }) => {
  if (jobApplicationId) {
    return await apiGet({ path: `/job_applications/${jobApplicationId}/activities` });
  }
};

export function useJobApplicationActivities({
  jobApplicationId,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery("jobApplicationActivities", () =>
    getJobApplicationActivities({ jobApplicationId }),
  );
}

import React from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import textStyles from "@ats/styles/text";
import useDeepCompareEffect from "use-deep-compare-effect";
import camelCase from "lodash/camelCase";

import { useProseMirror, ProseMirror } from "use-prosemirror";
import { DOMParser, DOMSerializer, Node as ProsemirrorNode, Schema } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { Plugin, TextSelection } from "prosemirror-state";

import { standardKeys } from "@shared/ProseMirror/config/keys";

import { useDebounce } from "@shared/hooks/useDebounce";

import MenuBar from "@shared/ProseMirror/MenuBar";
import SelectionToolbar from "@shared/ProseMirror/SelectionToolbar";
import LinkToolbar from "@shared/ProseMirror/LinkToolbar";
import ImageToolbar from "@shared/ProseMirror/ImageToolbar";
import IframeToolbar from "@shared/ProseMirror/IframeToolbar";
import MessageMailMergeMenuBar from "@shared/ProseMirror/MessageMailMergeMenuBar";
import FormLabel from "@ats/src/components/forms/FormLabel";

import { options as defaultOptions, menu } from "@shared/ProseMirror/config";
import plugins, { placeholder, basePlugins } from "@shared/ProseMirror/config/plugins";
import { messagesKeys } from "@shared/ProseMirror/config/keys";

import { useProseMirrorEditorStore } from "@ats/src/lib/store/zustand/proseMirrorEditorStore";

/* PARSE AND SERIALIZE EditorState
--===================================================-- */
const parser = (schema) => {
  const parser = DOMParser.fromSchema(schema);

  // return (content): ProsemirrorNode<Schema<any, any>> => {
  return (content) => {
    const container = document.createElement("article");
    container.innerHTML = content;
    return parser.parse(container);
  };
};

// const parserCool = (content): ProsemirrorNode<Schema> => {
//   const parser = DOMParser.fromSchema(defaultOptions.schema);

//   const container = document.createElement("article");
//   container.innerHTML = content;
//   return parser.parse(container);
// };

const serializer = (schema) => {
  const serializer = DOMSerializer.fromSchema(schema);

  return (doc) => {
    const container = document.createElement("article");
    container.appendChild(serializer.serializeFragment(doc.content));
    return container.innerHTML;
  };
};

export const parse = parser(defaultOptions.schema); // converts what was saved in the DB to something we can show in the editor
export const serialize = serializer(defaultOptions.schema);

export { defaultOptions, basePlugins, keymap, standardKeys };

function Editor(
  props: {
    editorId?: number | string;
    defaultValue: any;
    onSave?: any;
    onFocus?: (view: EditorView) => void;
    onBlur?: (view: EditorView) => void;
    autoSave?: () => void;
    onChange: any;
    enableStaticMenuBar: boolean;
    enableSelectionToolbar?: boolean;
    enableLabel?: boolean;
    enableCheckboxes?: boolean;
    enableMessageMailMergeMenuBar?: boolean;
    enableMessageKeys?: boolean;
    enableInsertMenuItems?: boolean;
    enableInsertReviewTemplates?: boolean;
    allowSenderMailMergeFields?: boolean;
    label?: string;
    description?: string;
    placeholder?: string;
    autoFocus?: boolean;
  },
  ref,
) {
  const {
    editorId,
    defaultValue,
    enableStaticMenuBar,
    enableSelectionToolbar,
    enableMessageMailMergeMenuBar,
    allowSenderMailMergeFields = true,
    enableMessageKeys,
    enableLabel,
    enableCheckboxes,
    enableInsertMenuItems,
    enableInsertReviewTemplates,
    onFocus,
    onBlur,
    onChange,
    autoSave,
    label,
    description,
    placeholder: placeholderMessage,
    autoFocus,
  } = props;

  const [
    linkToolbarIsActive,
    setLinkToolbarIsActive,
    setLinkHref,
    handleClickOnLinkMenuItem,
    imageToolbarIsActive,
    iframeToolbarIsActive,
  ] = useProseMirrorEditorStore((state) => [
    state.linkToolbarIsActive,
    state.setLinkToolbarIsActive,
    state.setLinkHref,
    state.handleClickOnLinkMenuItem,
    state.imageToolbarIsActive,
    state.iframeToolbarIsActive,
  ]);

  // window.logger("%c[ProseMirror][Editor] render", "color: #1976D2", {
  //   enableStaticMenuBar,
  //   enableCheckboxes,
  //   enableMessageKeys,
  //   placeholderMessage,
  //   defaultValue,
  // });

  /* LINK PLUGIN
  --===================================================-- */
  const showLinkToolbar = (href = null) => {
    setLinkToolbarIsActive(true);
    setLinkHref(href);
  };

  const hideLinkToolbar = () => {
    setLinkToolbarIsActive(false);
    setLinkHref(null);
  };

  function linkAround(state, pos) {
    const $pos = state.doc.resolve(pos);

    const { parent, parentOffset } = $pos;
    const start = parent.childAfter(parentOffset);
    if (!start.node) return null;

    const link = start.node.marks.find((mark) => mark.type === state.schema.marks.link);
    if (!link) return null;

    let startIndex = $pos.index();
    let startPos = $pos.start() + start.offset;
    let endIndex = startIndex + 1;
    let endPos = startPos + start.node.nodeSize;
    while (startIndex > 0 && link.isInSet(parent.child(startIndex - 1).marks)) {
      startIndex -= 1;
      startPos -= parent.child(startIndex).nodeSize;
    }
    while (endIndex < parent.childCount && link.isInSet(parent.child(endIndex).marks)) {
      endPos += parent.child(endIndex).nodeSize;
      endIndex += 1;
    }
    return { from: startPos, to: endPos };
  }

  const linkPlugin = new Plugin({
    props: {
      handleDOMEvents: {
        mouseover: (view, event: MouseEvent) => {
          if (
            event.target instanceof HTMLAnchorElement &&
            !event.target.className.includes("ProseMirror-widget")
          ) {
            // if (this.options.onHoverLink) {
            //   return this.options.onHoverLink(event);
            // }
          }
          return false;
        },

        /* Allow opening links with one click
        --===================================================-- */
        click: (view, event: MouseEvent) => {
          // if (view.props.editable && view.props.editable(view.state) && !isModKey(event)) {
          //   return false;
          // }

          if (event.target instanceof HTMLAnchorElement) {
            const href =
              event.target.href ||
              (event.target.parentNode instanceof HTMLAnchorElement
                ? event.target.parentNode.href
                : "");

            // window.logger(
            //   "%c[ProseMirror] LINK PLUGIN handleDOMEvents click",
            //   "background-color: #2b3102; color: #5dbd38",
            //   {
            //     event,
            //     target: event.target,
            //     isAnchor: event.target instanceof HTMLAnchorElement,
            //     href: event.target.href,
            //     view,
            //     selection: view.state.selection,
            //     // linkAround: linkAround(view.state, view.state.selection.from),
            //   },
            // );

            // const isHashtag = href.startsWith("#");
            // if (isHashtag) {
            //   event.stopPropagation();
            //   event.preventDefault();
            //   // this.options.onClickHashtag(href, event);
            //   return true;
            // }

            // if (this.options.onClickLink) {
            //   event.stopPropagation();
            //   event.preventDefault();
            //   this.options.onClickLink(href, event);
            //   return true;
            // }

            // Highlight the link that was clicked so that the LinkToolbar knows what to postition itself over
            const linkAroundCursor = linkAround(view.state, view.state.selection.from);
            window.logger(
              "%c[ProseMirror] LINK PLUGIN handleDOMEvents click",
              "background-color: #2b3102; color: #5dbd38",
              {
                event,
                target: event.target,
                isAnchor: event.target instanceof HTMLAnchorElement,
                href: event.target.href,
                view,
                selection: view.state.selection,
                linkAroundCursor,
              },
            );

            if (linkAroundCursor == undefined) {
              return false;
            }

            const newSelection = new TextSelection(
              view.state.doc.resolve(linkAroundCursor?.from),
              view.state.doc.resolve(linkAroundCursor?.to),
            );
            if (view.dispatch) {
              window.logger(
                "%c[ProseMirror] LINK PLUGIN handleDOMEvents click",
                "background-color: #2b3102; color: #5dbd38",
                {
                  event,
                  target: event.target,
                  isAnchor: event.target instanceof HTMLAnchorElement,
                  href: event.target.href,
                  view,
                  selection: view.state.selection,
                  newSelection,
                  // linkAround: linkAround(view.state, view.state.selection.from),
                },
              );

              view.dispatch(view.state.tr.setSelection(newSelection));
            }

            window.logger(
              "%c[ProseMirror] LINK PLUGIN handleDOMEvents click",
              "background-color: #e26ddc; color: #5dbd38",
              {
                event,
                target: event.target,
                isAnchor: event.target instanceof HTMLAnchorElement,
                href: event.target.href,
                view,
                selection: view.state.selection,
                linkAround: linkAround(view.state, view.state.selection.from),
                newSelection,
              },
            );

            showLinkToolbar(href);
            return true;
          }

          return false;
        },
      },
    },
  });

  /* END LINK PLUGIN
  --===================================================-- */

  const optionalPlugins = [];
  if (enableMessageKeys) optionalPlugins.push(keymap(messagesKeys));
  if (placeholderMessage != undefined && (defaultValue == undefined || defaultValue == ""))
    optionalPlugins.push(placeholder(`placeholder-${camelCase(placeholderMessage)}`));
  optionalPlugins.push(linkPlugin);

  const dispatch = (tr) => {
    const newState = editorState.apply(tr);
    onChange(newState);
    return setEditorState(newState);
  };

  const editorConfig = {
    ...defaultOptions,
    plugins: [
      ...optionalPlugins,
      ...plugins,
      keymap({
        "Mod-k": (state) => {
          window.logger("%c[JobSetupDescription] NEW LINK", "color: #f836c8", { state });
          handleClickOnLinkMenuItem(state, dispatch);
          return true;
        },
      }),
    ],
  };

  const [editorState, setEditorState] = useProseMirror({
    ...editorConfig,
    // doc: defaultOptions.schema.nodes.underline.createAndFill(),
    doc: parse(defaultValue),
  });

  const viewRef = React.useRef(null);
  const debouncedEditorState = useDebounce(editorState, 1300);

  React.useImperativeHandle(
    ref,
    () => {
      const dispatch = (tr) => setEditorState(editorState.apply(tr));

      return {
        state: () => editorState,
        setState: (state) => {
          window.logger("%c[NewEditor] setState", "color: #1976D2", { state });
          setEditorState(state);
        },
        serializedState: () => serialize(editorState.doc),
        clearEditor: () => {
          window.logger("%c[NewEditor] clearEditor", "color: #d26c19", {});
          setEditorState(EditorState.create({ ...editorConfig, doc: parse("") }));
        },
        replaceEditorContent: (content) => {
          window.logger("%c[NewEditor] replaceEditorContent", "color: #6019d2", { content });
          setEditorState(
            EditorState.create({
              ...editorConfig,
              doc: parse(content),
            }),
          );
        },
        insertTextAtCurrentSelection: (text) => {
          // let { from, to } = editorState.selection;

          window.logger("%c[BulkMessageModal] handleInsert", "color: #1976D2", {
            selection: editorState.selection,
            text,
            dispatch,
          });

          if (dispatch) {
            dispatch(editorState.tr.replaceWith(0, 0, parse(text).content));
          }

          return true;
        },
      };
    },
    [editorState, setEditorState, editorConfig],
  );

  /* AUTOSAVE when debouncedEditorState changes
  --===================================================-- */
  useDeepCompareEffect(() => {
    // window.logger(
    //   "%c[ProseMirror][Editor] useEffect debouncedEditorState",
    //   "color: #FF06D2; background: #EFFFEF",
    //   { debouncedEditorState, doc: serialize(debouncedEditorState.doc) },
    // );

    if (debouncedEditorState && autoSave != undefined) {
      autoSave();
    }

    if (autoFocus) {
      viewRef.current.view.focus();
    }
  }, [debouncedEditorState]);

  const labelNode =
    enableLabel && (label || description) ? (
      <>
        {label && <FormLabel label={label} for={editorId} />}
        {description && <Styled.Description>{description}</Styled.Description>}
      </>
    ) : null;

  /* CHECKBOXES
  --===================================================-- */
  const handleClickOnEditor = (editorView, pos, node, nodePos, event) => {
    if (event.target.classList.contains("polymer-checkbox")) {
      editorView.dispatch(toggleCheckboxItemAction(editorView.state, nodePos, node));
      return true;
    }
  };

  const toggleCheckboxItemAction = (state, pos, checkboxItemNode) => {
    return state.tr.setNodeMarkup(pos, null, { done: !checkboxItemNode.attrs.done });
  };
  /* END CHECKBOXES
  --===================================================-- */

  /* INSERT TEMPLATE
  --===================================================-- */
  const handleInsertTemplate = (template) => {
    if (dispatch) {
      dispatch(editorState.tr.replaceWith(0, 0, parse(template).content));
    }
  };

  return (
    <>
      {labelNode}
      <Styled.Container
        id={editorId}
        showAsTextArea={enableLabel}
        hastaticMenuBar={enableStaticMenuBar}
        placeholderMessage={placeholderMessage}
      >
        {enableStaticMenuBar && (
          <MenuBar
            showAsTextArea={enableLabel}
            viewRef={viewRef}
            menu={menu}
            state={editorState}
            dispatch={dispatch}
            enableCheckboxes={enableCheckboxes}
            enableInsertMenuItems={enableInsertMenuItems}
            enableInsertReviewTemplates={enableInsertReviewTemplates}
            handleInsertTemplate={handleInsertTemplate}
          />
        )}

        <ProseMirror
          ref={viewRef}
          className="ProseMirror-wrapper"
          state={editorState}
          onChange={(state) => {
            setEditorState(state);
            onChange(state);
          }}
          handleClickOn={handleClickOnEditor}
          handleDOMEvents={{
            focus: (view, event) => {
              // window.logger("%c[ProseMirror][Editor] handleDOMEvents focus", "color: #1976D2", {
              //   view,
              //   event,
              // });
              if (onFocus != undefined) {
                onFocus(view);
              }

              hideLinkToolbar();
              return false; // lets ProseMirror handle default
            },
            blur: (view, event) => {
              // window.logger("%c[ProseMirror][Editor] handleDOMEvents blur", "color: #1976D2", {
              //   view,
              //   event,
              // });
              if (onBlur != undefined) {
                onBlur(view);
              }
              if (autoSave != undefined) {
                autoSave();
              }
              return false; // lets ProseMirror handle default
            },
          }}
        />

        {enableSelectionToolbar &&
          !linkToolbarIsActive &&
          !imageToolbarIsActive &&
          !iframeToolbarIsActive && (
            <SelectionToolbar
              viewRef={viewRef}
              menu={menu}
              state={editorState}
              dispatch={dispatch}
              handleClickOnLinkMenuItem={handleClickOnLinkMenuItem}
            />
          )}
        {linkToolbarIsActive && (
          <LinkToolbar viewRef={viewRef} state={editorState} dispatch={dispatch} />
        )}
        {imageToolbarIsActive && (
          <ImageToolbar viewRef={viewRef} state={editorState} dispatch={dispatch} />
        )}
        {iframeToolbarIsActive && (
          <IframeToolbar viewRef={viewRef} state={editorState} dispatch={dispatch} />
        )}

        {enableMessageMailMergeMenuBar && (
          <MessageMailMergeMenuBar
            viewRef={viewRef}
            menu={menu}
            state={editorState}
            dispatch={dispatch}
            allowSenderMailMergeFields={allowSenderMailMergeFields}
          />
        )}
      </Styled.Container>
    </>
  );
}

const RefedEditor = React.forwardRef(Editor);

// This is how to handle defaultProps when wrapping a component in forwardRef
RefedEditor.defaultProps = {
  editorId: "custom-prosemirror-editor",
  enableStaticMenuBar: true,
  enableSelectionToolbar: false,
  enableLabel: false,
  enableCheckboxes: true,
  enableMessageKeys: false,
  enableInsertMenuItems: true,
  enableInsertReviewTemplates: false,
  autoFocus: false,
};

export default RefedEditor;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled("div")((props: any) => {
  // window.logger("%c[Editor][sup] ", "color: #1976D2", { props });

  const t: any = props.theme;

  const textAreaStyles = css`
    ${t.rounded.sm};
    border: 1px solid ${t.dark ? "transparent" : t.color.gray[300]};
    transition: border-color 0.2s ease;
    background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
    &:hover {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
    }
    &:focus,
    &:focus-within {
      border-color: ${t.dark ? t.color.gray[500] : t.color.gray[400]};
      ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
    }
    height: ${t.spacing[64]};

    ${t.dark &&
    `
      .ProseMirror-menu {
        border-bottom: 1px solid ${t.color.gray[700]};
      }
    `}

    .ProseMirror {
      min-height: 100%;
    }
  `;

  return css`
    label: ProseMirrorEditor_Container;
    display: flex;
    flex-direction: column;
    ${!props.showAsTextArea &&
    `
      overflow-y: hidden;
      overflow-x: hidden;
    `}
    flex: 1;
    ${props.showAsTextArea && props.hastaticMenuBar && textAreaStyles}

    .ProseMirror-wrapper {
      overflow-y: auto;
      height: 100%;
      ${props.showAsTextArea &&
    !props.hastaticMenuBar && [
      textAreaStyles,
      css`
          max-height: ${t.spacing[64]};
        `,
    ]}
    }

    .ProseMirror {
      ${props.showAsTextArea ? t.p(2) : t.p(4)}
      ${!props.showAsTextArea && `min-height: 100%;`}
      outline: none;
      line-height: 1.6;
      // Pre-wrap to fix Firefox/Prosemirros whitespace issues.
      white-space: pre-wrap;
      position: relative;
    }

    // BEGIN PLACEHOLDER
    .ProseMirror .placeholder-${camelCase(props.placeholderMessage)}::before {
      left: ${props.showAsTextArea ? t.spacing[2] : t.spacing[4]};
      right: ${props.showAsTextArea ? t.spacing[2] : t.spacing[4]};
      position: absolute;
      cursor: text;
      /* content: "${props.placeholderMessage}"; */
      color: ${t.color.gray[500]};
    }

    .ProseMirror > p.placeholder-${camelCase(props.placeholderMessage)}:first-child::before {
      content: "${props.placeholderMessage}";
    }
    // END PLACEHOLDER

    ${textStyles}
    color: ${t.dark
      ? props.showAsTextArea
        ? t.color.gray[200]
        : t.color.gray[300]
      : t.color.black};
    ${t.dark &&
    `
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}

    // CHECKBOX STYLES
    li[data-type="checkbox_item"] {
      display: flex;
      flex-direction: row;
    }

    .polymer-checkbox {
      position: relative;
      display: flex;
      user-select: none;
      align-items: flex-start;
      &:hover:after {
        border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      }
      &:after {
        ${[t.h(4), t.w(4), t.rounded.xs]}
        position: absolute;
        left: -1.375rem;
        top: 0.1875rem;
        content: "";
        border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[400]};
      }
    }

    .polymer-checkbox-checked {
      &:hover:after {
        border-color: ${t.dark ? t.color.gray[200] : t.color.black};
      }
      &:after {
        border: 1px solid ${t.dark ? t.color.gray[200] : t.color.black};
        background-color: ${t.dark ? t.color.gray[200] : t.color.black}, none;
        background-image: none,
          url("data:image/svg+xml;base64,${t.dark
      ? "PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSIjRUFFQUVBIi8+CjxwYXRoIGQ9Ik0xMy4zMzQ2IDRMNi4wMDEzIDExLjMzMzNMMi42Njc5NyA4IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
      : "PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJibGFjayIvPgo8cGF0aCBkPSJNMTMuMzMzMyA0TDUuOTk5OTYgMTEuMzMzM0wyLjY2NjYzIDgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="}");
        background-size: 14px;
      }
    }

    .polymer-checkbox-content {
      flex: 1;
    }

    li[data-done="true"] {
      text-decoration: none;
    }

    li[data-done="true"] .polymer-checkbox-unchecked {
      display: none;
    }

    li[data-done="false"] .polymer-checkbox-checked {
      display: none;
    }

    li[data-done="true"] li[data-done="false"] .polymer-checkbox-checked {
      display: inline-block;
    }

    li[data-done="false"] li[data-done="true"] .polymer-checkbox-checked {
      display: inline-block;
    }

    li[data-done="false"] {
      text-decoration: none;
    }
  `;
});

Styled.Description = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ProseMirrorEditor_Description;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    max-width: 32rem;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

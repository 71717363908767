import { useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createChannelMessage = async (variables) => {
  return await apiPost({ path: `/channels/${variables.channelId}/channel_messages`, variables });
};

export function useCreateChannelMessage() {
  const queryClient = useQueryClient();
  return useMutation(createChannelMessage, {
    onSuccess: (data, mutationVariables) => {
      window.logger("[useChannelMessage] useCreateChannelMessage", {
        data,
        mutationVariables,
        invalidate: ["channel", data.channelId],
        queryClient,
      });
      // queryClient.invalidateQueries();
      queryClient.invalidateQueries(["channel", data.channelId]);
    },
    // throwOnError: true,
  });
}

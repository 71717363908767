import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useCreateJobCategory, useUpdateJobCategory } from "@shared/queryHooks/useJobCategory";

import { validateJobCategory } from "@shared/lib/validateWithYup";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  onCancel: () => void;
  // history: any;
  isEditing: boolean;
  category: { id; name; jobsCount };
};

function JobCategoryModal(props: Props) {
  const { isEditing, onCancel, category } = props;
  const { dismissModalWithAnimation } = useModalContext();

  const {
    mutate: createJobCategory,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateJobCategory();
  const {
    mutate: updateJobCategory,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateJobCategory();
  const [categoryName, setCategoryName] = useState(props.category.name || "");
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeCategory = (_, value) => {
    setCategoryName(value);
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[JobCategoryModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateJobCategory({ categoryName });

    if (isValid) {
      isEditing ? handleUpdate() : handleCreate();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCreate = async () => {
    try {
      await createJobCategory({ name: categoryName });
      // jobCategory was successfully created
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      // window.logger("%c[JobCategoryModal] handle error when CREATE Job Category", "color: #FF7602", {
      //   error: error.response.data,
      // });
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors);
      }
    }
  };

  const handleUpdate = async () => {
    try {
      await updateJobCategory({ id: category.id, name: categoryName });
      // jobCategory was successfully updated
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[JobCategoryModal] error when updating Job Category", "color: #FF7602", {
        error,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate || isLoadingUpdate}
      >
        {isEditing ? "Update category" : "Create category"}
      </Button>
      <Button styleType="secondary" onClick={() => dismissModalWithAnimation(() => onCancel)}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const editMessage =
    Number(category.jobsCount) > 0
      ? `This category name will be updated for the ${category.jobsCount} active job${Number(category.jobsCount) === 1 ? " that is" : "s that are"
      } currently using it.`
      : `There are no active jobs that currently use this category.`;

  return (
    <CenterModal
      headerTitleText={isEditing ? "Edit category" : "Create new category"}
      onCancel={() => dismissModalWithAnimation(() => onCancel)}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        {isEditing ? editMessage : "Enter a name for the new job category."}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormInput
          onChange={handleChangeCategory}
          name="category"
          placeholder=""
          label="Category name"
          value={categoryName}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default JobCategoryModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React, { useState } from "react";
import toLower from "lodash/toLower";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";
import { validateCreatedEmailSelection } from "@shared/lib/validateWithYup";

import { useCreateInterviewerRequest } from "@shared/queryHooks/useInterviewerRequest";
import { useAllInvites } from "@shared/queryHooks/useInvite";
import { useCreateInterviewerInvite } from "@shared/queryHooks/useInterviewerInvite";

import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

type Props = {
  onCancel: () => void;
  organizationUsers: any[];
  jobApplicationId: number;
};

function ReviewRequestModal(props: Props) {
  window.logger("%c[ReviewRequestModal] render", "color: #1976D2", { props });
  const { onCancel, organizationUsers, jobApplicationId } = props;

  const { currentOrganization, currentOrganizationUser } = useCurrentSession();
  const addToast = useToastContext();
  const [formState, setFormState] = useState({
    selection: null,
    errors: [],
    isDirty: false,
  });

  const { selection, errors, isDirty } = formState;

  // window.logger("%c[ReviewRequestModal] formState", "color: #1976D2", { formState });
  const {
    mutate: createInterviewerRequest,
    isLoading: isLoadingCreateInterviewerRequest,
  } = useCreateInterviewerRequest();

  const {
    mutate: createInterviewerInvite,
    isLoading: isLoadingCreateInterviewerInvite,
  } = useCreateInterviewerInvite();

  const { data: invitesData, isLoading: isLoadingInvites } = useAllInvites();

  const getOrgUserIdByEmail = (email: string) => {
    const organizationUser = currentOrganization?.organizationUsers?.find(
      (organizationUser) => organizationUser.user.email === email,
    );
    return organizationUser.id;
  };

  const isViableInviteEmail = (email: string) => {
    const teamMembersEmails = currentOrganization?.organizationUsers?.map(
      (organizationUser) => organizationUser.user.email,
    );
    const isNotTeamMemberEmail = !teamMembersEmails.includes(email);
    //return true if NOT existing member email
    return isNotTeamMemberEmail;
  };

  const handleExistingUserRequest = (orgUserId) => {
    createInterviewerRequest(
      {
        jobApplicationId,
        organizationUserId: orgUserId,
      },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[ReviewRequestModal] createInterviewerRequest SUCCESS",
            "color: #1976D2",
            {
              data,
            },
          );
          // const username = data?.organizationUser.user.fullName
          // addToast({ title: `Requested a review from ${username}`, kind: "success" });
          onCancel();
        },
      },
    );
  };

  const handleInviteRequest = (inviteEmail: string) => {
    createInterviewerInvite(
      { jobApplicationId, email: toLower(inviteEmail) },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[ReviewRequestModal] createInterviewerInvite SUCCESS",
            "color: #1976D2",
            { data },
          );
          // addToast({delay: 3000, title: `Requested a review from ${data.invite.email}`, kind: "success" });
          onCancel();
        },
      },
    );
  };
  // handles created option --> should be email address
  const handleEmailInput = async () => {
    const [isValid, validationErrors] = await validateCreatedEmailSelection({
      selection: selection,
    });
    if (isValid) {
      const isInviteEmail = isViableInviteEmail(selection);
      if (isInviteEmail) {
        handleInviteRequest(selection);
      } else {
        const orgUserId = getOrgUserIdByEmail(selection);
        if (orgUserId !== currentOrganizationUser?.id) {
          handleExistingUserRequest(orgUserId);
        } else {
          addToast({ delay: 3000, title: `Cannot request review from yourself.`, kind: "warning" });
          onCancel();
        }
      }
    } else {
      setFormState({ ...formState, errors: validationErrors });
    }
  };
  // Handles selected option (existing)
  function handleExistingUserOrInvite() {
    if (selection.includes("organizationUser-")) {
      const orgUserId = selection.replace("organizationUser-", "");
      handleExistingUserRequest(orgUserId);
    } else if (selection.includes("invite-")) {
      const inviteEmail = selection.replace("invite-", "");
      handleInviteRequest(inviteEmail);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selection?.includes("organizationUser-") || selection?.includes("invite-")) {
      handleExistingUserOrInvite();
    } else if (selection) {
      handleEmailInput();
    }
  };

  const availableInterviewers = organizationUsers.filter(
    (organizationUser) => organizationUser.isActive && organizationUser.id !== currentOrganizationUser.id,
  );

  const availableInvites =
    invitesData?.items.filter((invite) => invite.status === "status_pending") || [];

  const interviewerOptions = (newOption = null) => {
    const users = availableInterviewers.map((organizationUser: { id: number; user: any }) => ({
      id: organizationUser.id,
      value: `organizationUser-${organizationUser.id}`,
      label: organizationUser.user.fullName,
    }));

    const invites = availableInvites.map((invite: { id: number; email: string }) => ({
      id: invite.id,
      value: `invite-${invite.email}`,
      label: invite.email,
    }));

    const existingOptions = new Set([...users, ...invites].map((option) => option.value));
    //adds user 'created' email to options array to display in UI
    const option = createOption(newOption, existingOptions);
    if (option) {
      return [...users, ...invites, ...option] as const;
    } else {
      return [...users, ...invites] as const;
    }
  };
  // create an option object (for react-select) to allow UI display of *created* email
  const createOption = (option: string, existingOptions: Set<string>) => {
    if (!option || existingOptions.has(option)) {
      return null;
    }
    return [{ id: option, value: option, label: option }];
  };

  const handleInputChange = (name: string, value: string) => {
    // window.logger("[ReviewRequestModal] handleInputChange", {
    //   name,
    //   value,
    // });
    setFormState({ ...formState, isDirty: true, [name]: value });
  };

  const handleSelection = (name: string, value: string) => {
    window.logger("[ReviewRequestModal] handleSelection", {
      name,
      value,
    });
    setFormState({ ...formState, isDirty: true, [name]: value });
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="addInterviewerRequest"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreateInterviewerRequest || isLoadingCreateInterviewerInvite}
        disabled={isLoadingInvites || selection === null}
      >
        Request review
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText="Request a review" onCancel={onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Select an existing team member or invite a new user via email. This will create a request for a candidate review.
      </Styled.Instructions>
      <FormContainer buttons={submitButton}>
        <FormSelect
          isCreatable
          isClearable
          onChange={handleSelection}
          onInputChange={handleInputChange}
          options={interviewerOptions(selection)}
          name="selection"
          label="Recipient"
          value={selection}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default ReviewRequestModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewRequestModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewRequestModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import HiringStageAutomationModal from "@ats/src/components/modals/HiringStageAutomationModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import EmptyState from "@ats/src/components/shared/EmptyState";
import FormLabel from "@ats/src/components/forms/FormLabel";

import HiringStageAutomationListItem from "@ats/src/views/jobApplications/jobSetup/components/HiringStageAutomationListItem";
import { HiringStageAutomation } from "@ats/types/job";

import {
  useHiringStageAutomations,
  useUpdateHiringStageAutomation,
  useDeleteHiringStageAutomation,
} from "@shared/queryHooks/useHiringStageAutomation";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { hiringStagesForJobOptions } from "@ats/src/lib/utils/helpers";

export default function HiringStageAutomations(props) {
  const { job } = props;
  const { status, data } = useHiringStageAutomations({ jobId: job.id });
  const addToast = useToastContext();

  const { openModal, removeModal } = useModalContext();

  // window.logger("%c[HiringStageAutomations] render", "background: #a05195; color:  #FFFF;", {
  //   props,
  //   status,
  // });
  const { mutate: updateHiringStageAutomation } = useUpdateHiringStageAutomation();
  const { mutate: deleteHiringStageAutomation } = useDeleteHiringStageAutomation();

  const automationsList = data != undefined ? data : [];

  const handleToggleEnable = async (hiringStageAutomation) => {
    const { id } = hiringStageAutomation;
    await updateHiringStageAutomation(
      { id, jobId: job.id, ...hiringStageAutomation },
      {
        onSuccess: (data) => {
          const currentState = data?.enabled ? "enabled" : "disabled";
          addToast({
            title: `Hiring stage automation ${currentState}.`,
            kind: "success",
          });
        },
        onError: () => {
          addToast({
            title: `Hiring stage automation could not be updated`,
            kind: "warning",
          });
        },
      },
    );
  };

  const handleOnClickDeleteHiringStageAutomation = (hiringStageAutomation) => {
    const { id, jobId, name } = hiringStageAutomation;
    const deleteMessage = `You will no longer be able to use the ${name}.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this automation?"
        subcopy={deleteMessage}
        confirmText="Delete automation"
        cancelText="Keep automation"
        onConfirm={() => {
          removeModal();
          handleDeleteHiringStageAutomation(jobId, id);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const handleDeleteHiringStageAutomation = async (jobId: number, id: number) => {
    await deleteHiringStageAutomation(
      { jobId, id },
      {
        onSuccess: () => {
          addToast({
            title: `Hiring stage automation deleted.`,
            kind: "success",
          });
        },
        onError: () => {
          addToast({
            title: `Hiring stage automation could not be deleted`,
            kind: "warning",
          });
        },
      },
    );
  };

  /* HiringStageAutomationModal
  --===================================================-- */
  const openNewHiringStageAutomationModal = (event) => {
    event.preventDefault();
    openHiringStageAutomationModal({}, false);
  };

  const openEditHiringStageAutomationModal = (hiringStageAutomation) => {
    openHiringStageAutomationModal(hiringStageAutomation, true);
  };

  const openHiringStageAutomationModal = (hiringStageAutomation, isEditing) => {
    const hiringStageOptions = hiringStagesForJobOptions(job);

    const modal = (
      <HiringStageAutomationModal
        onCancel={onCloseModal}
        isEditing={isEditing}
        passedHiringStageAutomation={hiringStageAutomation}
        job={job}
        hiringStageOptions={hiringStageOptions}
      />
    );
    openModal(modal);
  };

  const onCloseModal = () => {
    removeModal();
  };

  /* HiringStageAutomation nodes
  --===================================================-- */
  const hiringStageAutomationNodes = () => {
    window.logger(
      "%c[HiringStageAutomations] hiringStageAutomationNodes",
      "background: #a05195; color:  #FFFF;",
      {
        data,
        automationsList,
      },
    );

    automationsList.sort((a, b) => {
      const kindOrder = {
        kind_inbox: 1,
        kind_in_process: 2,
        kind_hired: 3,
        kind_archived: 4,
      };
      if (a.hiringStage.kind !== b.hiringStage.kind) {
        return kindOrder[a.hiringStage.kind] - kindOrder[b.hiringStage.kind];
      }
      if (a.hiringStage.kind === "kind_in_process") {
        return a.hiringStage.position - b.hiringStage.position;
      }
      return 0;
    });

    if (automationsList.length === 0) {
      return <EmptyState message="No hiring stage automations have been added" />;
    } else {
      return automationsList.map((hiringStageAutomation: HiringStageAutomation) => {
        return (
          <HiringStageAutomationListItem
            key={`${hiringStageAutomation.id}_${hiringStageAutomation.name}`}
            hiringStageAutomation={hiringStageAutomation}
            handleToggleEnable={handleToggleEnable}
            openEditHiringStageAutomationModal={openEditHiringStageAutomationModal}
            deleteHiringStageAutomation={handleOnClickDeleteHiringStageAutomation}
          />
        );
      });
    }
  };

  if (status === "loading") {
    return <LoadingIndicator label="Loading..." />;
  }
  return (
    <>
      <FormLabel label="Trigger candidate messages" />
      <Styled.Description>
        Automatically trigger a template based email message when a candidate is moved to a selected
        hiring stage. Create or edit a message template in{" "}
        <Button
          type="internalLink"
          to={{
            pathname: `/hire/settings/message_templates`,
            state: {
              orginalPathname: location.pathname, // tells App Settings about the close button
            },
          }}
          styleType="text"
        >
          App settings.
        </Button>
      </Styled.Description>
      <Styled.ListContainer>{hiringStageAutomationNodes()}</Styled.ListContainer>
      <Button styleType="secondary" onClick={openNewHiringStageAutomationModal}>
        Add an automation
      </Button>
    </>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Description = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Requirements;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
    max-width: 32rem;
  `;
});

Styled.ListContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateList;
    ${[t.mb(8), t.mt(1), t.rounded.md]}
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
  `;
});

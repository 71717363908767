import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const getDiscordChannelList = async () => {
  return await apiGet({ path: "/integrations/discord_channel_integrations/list" });
};

// const getDiscordChannelsList = async () => {
//   return await apiGet({ path: "/integrations/discord_channel_integrations/list" });
// };

const createDiscordChannelIntegration = async ({ channelId, channelName }) => {
  window.logger(
    "%c[useDiscord] createDiscordChannelIntegration\n\n\n\n",
    "background-color: #FF76D2",
    {
      channelId,
      channelName,
    },
  );
  return await apiPost({
    path: `/integrations/discord_channel_integrations`,
    variables: { channelId, channelName },
  });
};

// const deleteDiscordChannelIntegration = async ({ channelId }) => {
//   window.logger("%c[useDiscord] deleteDiscordChannelIntegration\n\n\n\n", "background-color: #FF76D2", {
//     channelId,
//   });
//   return await apiDelete({
//     path: `/integrations/discord_channel_integrations/${channelId}`,
//     variables: {},
//   });
// };

// const updateDiscordChannelIntegration = async ({
//   discordChannelId, // currently just a placeholder
//   siteId,
//   siteName,
//   sitePreviewUrl,
//   collectionId,
//   collectionName,
//   fieldMapping,
// }: {
//   discordChannelId: any; // currently just a placeholder
//   siteId?: string;
//   siteName?: string;
//   sitePreviewUrl?: string;
//   collectionId?: string;
//   collectionName?: string;
//   fieldMapping?: {};
// }) => {
//   window.logger("%c[useDiscord] updateDiscordChannelIntegration\n\n\n\n", "background-color: #FF76D2", {
//     siteId,
//     siteName,
//     sitePreviewUrl,
//     collectionId,
//     collectionName,
//     fieldMapping,
//   });
//   return await apiPut({
//     path: `/integrations/discord_channel_integrations/${discordChannelId}`,
//     variables: { siteId, siteName, sitePreviewUrl, collectionId, collectionName, fieldMapping },
//   });
// };

/* Hooks
--===================================================-- */
export function useDiscordChannelList({
  enabled = false,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  refetch: () => void;
} {
  return useQuery("discordChannels", getDiscordChannelList, { retry: false, enabled });
}

export function useCreateDiscordChannelIntegration() {
  const queryClient = useQueryClient();
  return useMutation(createDiscordChannelIntegration, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useDiscord] useCreateDiscordChannelIntegration onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("discordChannels");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

// export function useDeleteDiscordChannelIntegration() {
//   const queryClient = useQueryClient();
//   return useMutation(deleteDiscordChannelIntegration, {
//     onSettled: () => {
//       queryClient.invalidateQueries("currentOrganization");
//       queryClient.invalidateQueries("discordChannels");
//     },
//     // throwOnError: true,
//   });
// }

// export function useUpdateDiscordChannelIntegration() {
//   const queryClient = useQueryClient();
//   return useMutation(updateDiscordChannelIntegration, {
//     onSuccess: (data, mutationVariables) => {
//       // window.logger("%c[useDiscord] useUpdateDiscordChannelIntegration onSuccess", "color: #1976D2", {
//       //   data,
//       //   mutationVariables,
//       // });
//       queryClient.invalidateQueries("me");
//       queryClient.invalidateQueries("currentOrganization");
//       queryClient.invalidateQueries("discordChannels");
//       // queryClient.invalidateQueries(["questions", data.jobId]);
//     },

//     // throwOnError: true,
//   });
// }

// export function useUpdateSelectedSite() {
//   const queryClient = useQueryClient();
//   return useMutation(updateDiscordChannelIntegration, {
//     onSuccess: (data, mutationVariables) => {
//       // window.logger("%c[useDiscord] useUpdateDiscordChannelIntegration onSuccess", "color: #1976D2", {
//       //   data,
//       //   mutationVariables,
//       // });
//       queryClient.invalidateQueries("me");
//       queryClient.invalidateQueries("currentOrganization");
//       // queryClient.invalidateQueries(["jobs", data.jobId]);
//       // queryClient.invalidateQueries(["questions", data.jobId]);
//     },

//     // throwOnError: true,
//   });
// }

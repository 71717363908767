import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Rails from "@rails/ujs";

import Button from "@ats/src/components/shared/Button";

function OauthConnectButton({ path, label }) {
  return (
    <form action={path} method="post">
      <Styled.Connect type="submit">{label}</Styled.Connect>
      <input name="authenticity_token" type="hidden" value={Rails.csrfToken()} />
    </form>
  );
}

OauthConnectButton.defaultProps = {};

export default OauthConnectButton;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Connect = styled(Button)((props) => {
  const t: any = props.theme;
  return css`
    label: Connect;
    ${[t.mt(6)]}
  `;
});

import React from "react";
import toLower from "lodash/toLower";
import trim from "lodash/trim";

import { validateLogin } from "@shared/lib/validateWithYup";

import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";

import { useLogin } from "@shared/queryHooks/useSession";

function LoginForm(props) {
  const [state, setState] = React.useState({
    email: "",
    password: "",
    errors: [],
  });

  const { mutate: login, isLoading } = useLogin();
  const { email, password, errors } = state;

  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value, errors: null });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const [isValid, validationErrors] = await validateLogin({ email, password });

    if (isValid) {
      login(
        { email: trim(toLower(email)), password },
        {
          onSuccess: (data) => {
            window.logger("[LoginForm] data", data);
            props.onComplete();
          },
          onError: (response: any) => {
            window.logger("[LoginForm] server error", { response });
            // Handles Devise Errors
            if (response.data.error) {
              setState({ ...state, errors: [response.data.error] });
            }
            // Handles non Devise Errors
            if (response.data.errors) {
              setState({ ...state, errors: response.data.errors });
            }
          },
        },
      );
    } else {
      window.logger("[LoginForm] errors", { validationErrors });
      setState({ ...state, errors: validationErrors });
    }
  };

  return (
    <FormContainer
      onSubmit={handleLogin}
      errors={errors}
      buttons={
        <Button type="submit" disabled={isLoading} loading={isLoading} isTracked={false}>
          Log in
        </Button>
      }
    >
      <FormInput
        onChange={handleInputChange}
        name="email"
        placeholder=""
        label="Email"
        className=""
        errors={errors}
      />

      <FormInput
        onChange={handleInputChange}
        name="password"
        placeholder=""
        label="Password"
        className=""
        inputType="password"
        errors={errors}
      />
    </FormContainer>
  );
}

export default LoginForm;

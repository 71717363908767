import create, { SetState, GetState } from "zustand";

type JobApplicationStore = {
  convertingDocxToPdf: boolean;
  setConvertingDocxToPdf: (converting: boolean) => void;
};

const useJobApplicationStore = create<JobApplicationStore>(
  (set: SetState<JobApplicationStore>, get: GetState<JobApplicationStore>) => ({
    convertingDocxToPdf: false,
    setConvertingDocxToPdf: (converting): void => {
      // const { convertingDocxToPdf } = get();
      set((state) => ({ convertingDocxToPdf: converting }));
    },
  }),
);

export { useJobApplicationStore };

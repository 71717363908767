import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
// import FormTextarea from "@ats/src/components/forms/FormTextarea";
import FormSelect from "@ats/src/components/forms/FormSelect";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useChannelMessageTemplates } from "@shared/queryHooks/useChannelMessageTemplate";
import { useCreateBulkMessage } from "@shared/queryHooks/useBulkMessage";

import { useToastContext } from "@shared/context/ToastContext";
import { validateBulkMessage } from "@shared/lib/validateWithYup";

type Props = {
  jobId: number;
  hiringStageId: number;
  onCancel: () => void;
  jobApplications: [any];
  idsArray: Array<number>;
  idsArrayType: string;
  candidatesCount: number;
};

function BulkMessageModal(props: Props) {
  const {
    jobId,
    hiringStageId,
    onCancel,
    jobApplications,
    idsArray,
    idsArrayType,
    candidatesCount,
  } = props;

  const addToast = useToastContext();
  const editorRef = React.useRef(undefined);
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const {
    mutate: createBulkMessage,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateBulkMessage();

  const { data: channelMessageTemplates } = useChannelMessageTemplates();

  const channelMessageTemplateSelectOptions = channelMessageTemplates
    ? channelMessageTemplates.items.map((template) => {
        return { value: template.id, label: template.name };
      })
    : [];

  // window.logger("%c[BulkMessageModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   idsArray,
  //   idsArrayType,
  //   candidatesCount,
  // });

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateBulkMessage({
      body: editorRef.current.serializedState(),
      jobApplications,
    });

    if (isValid) {
      handleSendBulkMessage();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSendBulkMessage = async () => {
    try {
      await createBulkMessage(
        {
          jobId,
          hiringStageId,
          body: editorRef.current.serializedState(),
          excludedJobApplicationIds: idsArrayType === "excluded" ? idsArray : [],
          includedJobApplicationIds: idsArrayType === "included" ? idsArray : [],
        },
        {
          onSuccess: (data) => {
            // window.logger(
            //   "%c[BulkMessageModal] handleSendBulkMessage onSuccess",
            //   "background-color: #1cd219",
            //   { data },
            // );
            addToast({
              title: `Message sent to ${candidatesCount} candidate${
                candidatesCount === 1 ? "" : "s"
              }`,
              kind: "success",
            });
          },
          onError: (error: any) => {
            // window.logger(
            //   "%c[BulkMessageModal] handleSendBulkMessage onError",
            //   "background-color: #d21919",
            //   { error },
            // );
            if (error?.data?.errors?.general != undefined) {
              addToast({
                title: error?.data?.errors?.general[0],
                delay: 20000,
                kind: "error",
              });
            }
          },
          onSettled: (data) => {
            // BulkMessage was successfully created
            onCancel(); // this closes the modal
          },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      window.logger("%c[BulkMessageModal] handle error when SendBulkMessage", "color: #FF7602", {
        error,
        // error: error.response.data,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleChangeSelectChannelMessageTemplate = (name, value) => {
    if (channelMessageTemplates == undefined) {
      return;
    }

    const template = channelMessageTemplates.items.find(
      (channelMessageTemplate) => channelMessageTemplate.id === value,
    );

    // setChannelMessageTemplate(template);
    if (template) {
      editorRef.current.insertTextAtCurrentSelection(template.body);
    }

    setIsDirty(true);

    window.logger(
      "%c[BulkMessageModal] handleChangeSelectChannelMessageTemplate",
      "color: #F976D2",
      {
        name,
        value,
        template,
      },
    );
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createBulkMessageButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate}
        disabled={isLoadingCreate || candidatesCount === 0}
      >
        Send message
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal
      headerTitleText={"Message candidates"}
      onCancel={onCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        {candidatesCount === 0 ? (
          <p>
            <span>No candidates selected.</span> Use the checkboxes next to the candidate names to select the
            candidates to message. To <b>select all candidates</b> within this hiring stage, use the checkbox next to the stage name.
          </p>
        ) : (
          <p>
            Send a message to <b>{candidatesCount}</b> candidates within this hiring stage. You can
            choose an existing message template or draft one from scratch.
          </p>
        )}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormSelect
          onChange={handleChangeSelectChannelMessageTemplate}
          name="channelMessageTemplate"
          label="Template"
          // description="Choose a template."
          className=""
          // value={channelMessageTemplate}
          options={channelMessageTemplateSelectOptions}
          // errors={errors}
          isClearable
        />

        <Styled.TemplateInput>
          {/* <FormTextarea
            onChange={handleChangeBulkMessageBody}
            name="body"
            placeholder=""
            label="Message body"
            value={bulkMessageBody || ""}
            errors={errors}
            onSelectionUpdate={handleBulkeMessageSelectionUpdate}
          /> */}
          <ProseMirrorEditor
            ref={editorRef}
            enableLabel
            label="Message body"
            editorId="bulk-message-editor"
            enableStaticMenuBar={false}
            enableSelectionToolbar={true}
            enableMessageMailMergeMenuBar={true}
            onChange={handleChangeEditorState}
            defaultValue=""
          />
        </Styled.TemplateInput>
      </FormContainer>
    </CenterModal>
  );
}

BulkMessageModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default BulkMessageModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMessageModal_Instructions;
    ${[t.mb(5)]}

    span {
      font-weight: bold;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.TemplateInput = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMessageModal_TemplateInput;
    > div {
      ${[t.mb(0)]}
    }
    textarea {
      min-height: 12rem;
    }
  `;
});

Styled.VariableButtons = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMessageModal_VariableButtons;
    transition: box-shadow 0.2s ease;
    button {
      ${[t.h(6), t.px(2), t.text.xs, t.mr(1), t.mt(1)]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMessageModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

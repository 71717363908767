/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

// import { useMixpanel } from "@shared/MixpanelContext";

const Button = ({
  children,
  type,
  link,
  onClick,
  onMouseDown,
  disabled,
  loading,
  styleType,
  dangerous,
  isTracked,
  trackingPayload,
  ...props
}) => {
  const eventName =
    type === "internalLink"
      ? "Click Internal Link"
      : type === "externalLink"
        ? "Click External Link"
        : "Click Button";
  const mixpanelPayload = { eventName, ...trackingPayload };

  // const mixpanel = useMixpanel(isTracked);

  const handleClick = (...args) => {
    if (!disabled && onClick) {
      onClick(...args);
    } else if (disabled) {
      args[0].preventDefault();
      args[0].stopPropagation();
    }

    try {
      const ctaText = args[0].target ? args[0].target.innerText : "Unknown";
      const ctaLocation = undefined;

      // if (mixpanel) {
      //   mixpanel.track(eventName, { ctaText, ctaLocation, ...mixpanelPayload });
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnMouseDown = (event) => {
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const getStyles = (t, dark, loading = false) => {
    const typeStyles = getTypeStyles(t, dark);
    return css`
      ${[typeStyles, t.text.medium, t.rounded.sm]}
      white-space: nowrap;
      border: none;
      appearance: none;
      position: relative;
      cursor: pointer;
      max-width: 100%;

      &:disabled {
        opacity: ${loading ? 1 : 0.5};
        cursor: default;
      }
    `;
  };

  const getTypeStyles = (t, dark) => {
    const buttonShape = css`
      ${[t.h(10), t.text.base, t.px(4)]}
      display: ${type === "internalLink" || type === "externalLink" ? "inline-flex" : "flex"};
      align-items: center;
      justify-content: center;
      outline: none;
      width: 100%;

      ${t.mq["md"]} {
        ${[t.h(8), t.text.sm]}
        width: auto;
      }
    `;
    switch (styleType) {
      case "primary":
        return css`
          ${buttonShape};
          color: ${t.dark ? t.color.black : t.color.white};
          &:link,
          &:visited {
            color: ${t.dark ? t.color.black : t.color.white};
          }
          background: ${t.dark
            ? "linear-gradient(120deg, #FBD7FF 10%, #FFDEC1 90%)"
            : dangerous
              ? t.color.red[600]
              : t.color.black};
          transition: background 0.2s ease, box-shadow 0.2s ease;
          &:hover:not([disabled]) {
            background: ${t.dark
            ? "linear-gradient(to right, #FBD7FF -30%, #FFDEC1 70%)"
            : dangerous
              ? t.color.red[700]
              : t.color.gray[700]};
          }
          &:focus {
            ${t.dark
            ? `box-shadow: 0 0 0 2px ${t.color.gray[500]}`
            : `box-shadow: 0 0 0 2px ${dangerous ? t.color.gray[300] : t.color.gray[400]}`};
          }

          > span {
            overflow-x: hidden;
            line-height: 1.6;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        `;
      case "secondary":
        return css`
          ${buttonShape}
          background-color: ${t.dark ? t.color.gray[700] : t.color.gray[200]};
          transition: background-color 0.2s ease, box-shadow 0.2s ease;
          color: ${t.dark ? t.color.gray[200] : dangerous ? t.color.red[600] : t.color.black};
          &:link,
          &:visited {
            color: ${t.dark ? t.color.gray[200] : dangerous ? t.color.red[600] : t.color.black};
          }
          border: 1px solid ${t.color.gray[200]};
          &:hover:not([disabled]) {
            color: ${t.dark ? t.color.white : dangerous ? t.color.red[700] : t.color.black};
            background-color: ${t.dark ? t.color.gray[600] : t.color.gray[300]};
          }
          &:focus {
            box-shadow: 0 0 0 2px ${t.dark ? t.color.gray[500] : t.color.gray[300]};
          }
          &:hover:focus {
            box-shadow: 0 0 0 2px ${t.dark ? t.color.gray[500] : t.color.gray[300]};
          }

          > span {
            overflow-x: hidden;
            line-height: 1.6;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        `;
      case "text":
        return css`
          ${type === "externalLink" &&
          `
            svg {
              margin-left: 0.375rem;
              height: 1.125em;
              width: 1.125em;
            }
          `}

          display: inline-flex;
          background-color: transparent;
          color: ${t.dark ? t.color.gray[300] : dangerous ? t.color.red[600] : t.color.black};
          &:link,
          &:visited {
            color: ${t.dark ? t.color.gray[300] : dangerous ? t.color.red[600] : t.color.black};
          }
          outline: none;
          &:hover:not([disabled]) {
            text-decoration: underline;
          }
          &:focus {
            text-decoration: underline;
          }

          > span {
            display: flex;
            align-items: center;
          }
        `;
    }
  };

  /* KIND TRANSITION
  ======================================================= */
  switch (type) {
    case "internalLink":
      return (
        <Link to={link} {...props} onClick={handleClick} css={(theme) => getStyles(theme)}>
          <span>{children}</span>
        </Link>
      );
    case "externalLink":
      return (
        <a
          href={link}
          target={props.target || "_blank"}
          rel="noopener noreferrer"
          {...props}
          onClick={handleClick}
          css={(theme) => getStyles(theme)}
        >
          <span>{children}</span>
        </a>
      );
    default:
      return (
        <button
          type={type}
          css={(theme) => getStyles(theme, loading)}
          {...props}
          onClick={handleClick}
          onMouseDown={handleOnMouseDown}
          disabled={disabled}
        >
          <span>{children}</span>
          {loading && (
            <Styled.Loader styleType={styleType} data-testid="loading-animation">
              <div />
              <div />
              <div />
            </Styled.Loader>
          )}
        </button>
      );
  }
};

Button.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string.isRequired,
  styleType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  dangerous: PropTypes.bool,
  form: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  styleType: "primary",
  size: "medium",
  link: null,
  disabled: false,
  loading: false,
  dangerous: false,
  isTracked: true,
};

export default Button;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Loader = styled.div((props) => {
  const t = props.theme;
  return css`
    label: Button_Loader;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    opacity: 100%;
    &:before {
      ${t.rounded.sm}
      background: ${props.styleType === "primary"
      ? t.dark
        ? "linear-gradient(to right, #FBD7FF -40%, #FFDEC1 80%)"
        : t.color.gray[700]
      : t.dark
        ? t.color.gray[600]
        : t.color.gray[300]};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: "";
    }
    div {
      position: relative;
      width: 0.125rem;
      margin: 0.125rem;
      background-color: ${props.styleType === "primary"
      ? t.dark
        ? t.color.black
        : t.color.white
      : t.dark
        ? t.color.gray[200]
        : t.color.black};
      animation: ${loadingAnimation} 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div:nth-of-type(1) {
      animation-delay: -0.24s;
    }
    div:nth-of-type(2) {
      animation-delay: -0.12s;
    }
    div:nth-of-type(3) {
      animation-delay: 0;
    }
  `;
});

const loadingAnimation = keyframes`
  0% {
    height: 0.75rem;
  }
  30%,
  100% {
    height: 0.125rem;
  }
`;

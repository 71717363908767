import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { History } from "react-router-dom";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import Button from "@ats/src/components/shared/Button";
import Tooltip from "@ats/src/components/shared/Tooltip";
import ShortcutKey from "@ats/src/components/shared/ShortcutKey";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import JobStageAutomationInfo from "@ats/src/views/jobApplications/JobStageAutomationInfo";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import EditCandidateModal from "@ats/src/views/jobApplications/candidates/EditCandidateModal";
import JobApplicationStageMoveModal from "@ats/src/views/jobApplications/JobApplicationStageMoveModal";
import JobApplicationCloneModal from "@ats/src/views/jobApplications/JobApplicationCloneModal";

import { prettyErrorsArray } from "@shared/lib/formHelpers";
import { nextLogical } from "@ats/src/lib/utils/helpers";

import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

import {
  useMoveJobApplicationToStage,
  useCloneToNewJobAtHiringStage,
  useMoveToNewJobAtHiringStage,
} from "@shared/queryHooks/useJobApplication";
import { useJobs } from "@shared/queryHooks/useJob";
import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
import { useAnonymizeCandidate } from "@shared/queryHooks/useCandidate";
import { CandidatePrivacyStatus } from "@shared/types/candidate";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import { HiringStageAutomation } from "@ats/types/job";
import { getActiveHiringStageAutomation } from "@ats/src/lib/utils/helpers";

const FINAL_STAGES = ["kind_archived", "kind_hired"];

type HiringStage = {
  name: string;
  id: number;
  kind: string;
  jobId: number;
  hiringStageAutomation: HiringStageAutomation;
};

type JobApplication = { id: number; candidateId: number; hiringStageId: number; jobId: number };

type Props = {
  candidate: any;
  jobApplication: any;
  history: History;
  hiringStages: Array<HiringStage>;
  jobApplicationsForStage: Array<JobApplication>;
};

function JobApplicationSidebarActions({
  candidate,
  jobApplication,
  hiringStages,
  jobApplicationsForStage,
  history,
  ...props
}: Props) {
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  // window.logger("%c[JobApplicationSidebarActions] ", "background: #EFDDEF; color: #1976D2", props);
  const { currentOrganizationUser } = useCurrentSession();
  // const { candidate, jobApplication, hiringStages, jobApplicationsForStage } = props;
  const hiringStagesById = useJobStore((state) => state.hiringStagesById);

  const {
    mutate: moveJobApplicationToStage,
    isLoading: isLoadingMoveJobApplicationToStage,
  } = useMoveJobApplicationToStage();

  const {
    mutate: cloneToNewJobAtHiringStage,
    isLoading: isLoadingCloneToNewJobAtHiringStage,
  } = useCloneToNewJobAtHiringStage();

  const {
    mutate: moveToNewJobAtHiringStage,
    isLoading: isLoadingMoveToNewJobAtHiringStage,
  } = useMoveToNewJobAtHiringStage();

  const {
    mutate: anonymizeCandidate,
    isLoading: isLoadingAnonymizeCandidate,
  } = useAnonymizeCandidate();

  const { data: jobsData, isFetching: isFetchingJobs } = useJobs({
    refetchOnWindowFocus: false,
  });

  const archiveHiringStage = hiringStages.find((stage) => stage.kind === "kind_archived");
  const currentStage = hiringStages.find((stage) => stage.id === jobApplication.hiringStageId);
  const isActive = currentStage && !FINAL_STAGES.includes(currentStage.kind);

  const jobs = jobsData ? jobsData.items.filter((job) => job.id !== currentStage.jobId) : [];

  // const nextLogical = ({ list, currentItem }) => {
  //   const length = list.length;
  //   const currentIndex = list.findIndex((item) => item.id === currentItem.id);

  //   if (currentIndex + 1 === length) {
  //     // last Item, check if is only one, if so do nothing.
  //     if (currentIndex === 0) {
  //       return null;
  //     }

  //     // return the Previous Item
  //     return list[currentIndex - 1];
  //   } else {
  //     // return the Next Item
  //     return list[currentIndex + 1];
  //   }
  // };

  const nextLogicalJobApplication = nextLogical({
    list: jobApplicationsForStage,
    currentItem: jobApplication,
  });

  const nextLogicalHiringStage = nextLogical({
    list: hiringStages.filter((stage) => stage.kind !== "kind_archived"),
    currentItem: currentStage,
  });

  const nextLogicalHiringStageIncludingArchived = nextLogical({
    list: hiringStages,
    currentItem: currentStage,
  });

  const nextLogicalJobApplicationUrl =
    nextLogicalJobApplication != undefined
      ? `/jobs/${nextLogicalJobApplication.jobId}/stages/${nextLogicalJobApplication.hiringStageId}/applicants/${nextLogicalJobApplication.id}`
      : `/jobs/${jobApplication.jobId}/stages/${jobApplication.hiringStageId}/applicants`;

  window.logger("%c[JASA] moveFocuseToNextLogicalJobApplication", "background-color: #eb89d6", {
    nextLogicalJobApplication,
    nextLogicalHiringStage,
    nextLogicalJobApplicationUrl,
    jobApplication,
  });

  /* MOVE STAGE MODAL
  --===================================================-- */
  const moveStageModal = (
    <JobApplicationStageMoveModal
      title={`Move candidate`}
      subcopy={`Select the hiring stage to move ${jobApplication.candidate.fullName} to.`}
      currentStage={currentStage}
      nextLogicalHiringStage={nextLogicalHiringStage}
      hiringStages={hiringStages}
      onConfirm={(targetStageId) => {
        window.logger(
          "%c[JobApplicationSidebarActions] MOVE STAGE MODAL CONFIRM",
          "color: #19FF02",
          {
            props,
            targetStageId,
          },
        );
        removeModal();

        const targetStage = hiringStagesById[targetStageId];
        moveToStage({ id: targetStage.id, name: targetStage.name });
      }}
      onCancel={() => {
        window.logger(
          "%c[JobApplicationSidebarActions] MOVE STAGE MODAL CANCEL",
          "color: #FF5F02",
          {
            props,
          },
        );
        removeModal();
      }}
    />
  );

  /* MOVE or CLONE JOB APPLICATION MODAL
  --===================================================-- */
  const moveToJobModal = (
    <JobApplicationCloneModal
      title={`Move to job`}
      subcopy={`Select the job and hiring stage to move ${jobApplication.candidate.fullName} to.`}
      currentStage={currentStage}
      nextLogicalHiringStage={nextLogicalHiringStage}
      hiringStages={hiringStages}
      onConfirm={({ targetJob, targetHiringStage, cloneInsteadOfMove }) => {
        window.logger(
          "%c[JobApplicationSidebarActions] CLONE JOB APPLICATION CONFIRM",
          "color: #19FF02",
          {
            props,
            targetHiringStage,
            targetJob,
          },
        );
        removeModal();

        if (cloneInsteadOfMove) {
          cloneJobApplicationToJob({ targetJob, targetHiringStage });
        } else {
          moveJobApplicationToJob({ targetJob, targetHiringStage });
        }
      }}
      onCancel={() => {
        window.logger(
          "%c[JobApplicationSidebarActions] CLONE JOB APPLICATION CANCEL",
          "color: #FF5F02",
          {
            props,
          },
        );
        removeModal();
      }}
    />
  );

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "m",
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      openModal(moveStageModal);
      return false;
    },
    {},
    [jobApplication],
  );

  useHotkeys(
    "e",
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      moveToArchived();
      return false;
    },
    {},
    [jobApplication],
  );

  /* EDIT CANDIDATE
  --===================================================-- */
  const handleClickOnEdit = (event) => {
    event.preventDefault();
    window.logger(
      "%c[JobApplicationSidebarActions] handleClickOnAdd props",
      "color: #1976D2",
      props,
    );

    const modal = (
      <EditCandidateModal
        candidate={candidate}
        onCancel={removeModal}
        onComplete={removeModal}
        jobApplication={jobApplication}
        jobApplicationId={jobApplication.id}
      />
    );

    openModal(modal);
  };

  /* MOVE TO STAGE - Same Job
  --===================================================-- */
  const moveToStage = ({ id: hiringStageId, name }) => {
    if (hiringStageId) {
      const destinationUrl = `/jobs/${jobApplication.jobId}/stages/${hiringStageId}/applicants/${jobApplication.id}`;

      moveJobApplicationToStage(
        { ...jobApplication, hiringStageId },
        {
          onSuccess: (data) => {
            addToast({
              title: `Moved ${jobApplication.candidate.fullName} to ${name}`,
              kind: "success",
              delay: 6000,
              linkTo: destinationUrl,
            });

            moveFocusToNextLogicalJobApplication();
          },

          onError: (response) => {
            window.logger("%c[JASA] onError", "color: #1976D2", { response });
            addToast({
              title: `An unknown error occurred, please try again or contact support@polymer.co`,
              kind: "warning",
              delay: 5000,
            });
          },
        },
      );
    }
  };

  /* MOVE TO NEXT LOGICAL HIRING STAGE
  --===================================================-- */
  const moveToNextLogicalHiringStage = () => {
    if (nextLogicalHiringStage != undefined) {
      moveToStage(nextLogicalHiringStage);
    }
  };

  /* MOVE FOCUS TO NEXT LOGICAL JOB APPLICATION
  --===================================================-- */
  const moveFocusToNextLogicalJobApplication = () => {
    window.logger("%c[JASA] moveFocuseToNextLogicalJobApplication", "background-color: #96fc88", {
      nextLogicalJobApplication,
      nextLogicalJobApplicationUrl,
    });

    if (nextLogicalJobApplicationUrl) {
      history.push(nextLogicalJobApplicationUrl);
    }
  };

  /* CLONE JOB APPLICATION TO STAGE ON JOB
  --===================================================-- */
  const cloneJobApplicationToJob = ({ targetJob, targetHiringStage }) => {
    cloneToNewJobAtHiringStage(
      { ...jobApplication, hiringStageId: targetHiringStage.id },
      {
        onSuccess: (data) => {
          const destinationUrl = `/jobs/${targetJob.id}/stages/${targetHiringStage.id}/applicants/${data.id}`;
          window.logger("%c[JobApplicationSidebarActions] CLONE", "color: #1976D2", {
            data,
            destinationUrl,
          });
          addToast({
            title: `Cloned ${jobApplication.candidate.fullName} to ${targetJob.title}`,
            kind: "success",
            delay: 6000,
            linkTo: destinationUrl,
          });

          // moveFocusToNextLogicalJobApplication(); // Only do this for Move candidate
        },

        onError: (response: { data: { errors } }) => {
          window.logger("%c[JASA] cloneJobApplication onError", "color: #1976D2", {
            response,
            errors: prettyErrorsArray(response.data.errors),
          });
          const prettyErrors = prettyErrorsArray(response.data.errors);
          addToast({
            title: prettyErrors[0],
            kind: "warning",
            delay: 5000,
          });
        },
      },
    );
  };

  /* MOVE JOB APPLICATION TO STAGE ON JOB
  --===================================================-- */
  const moveJobApplicationToJob = ({ targetJob, targetHiringStage }) => {
    const destinationUrl = `/jobs/${targetJob.id}/stages/${targetHiringStage.id}/applicants/${jobApplication.id}`;

    moveToNewJobAtHiringStage(
      { ...jobApplication, hiringStageId: targetHiringStage.id },
      {
        onSuccess: (data) => {
          addToast({
            title: `Moved ${jobApplication.candidate.fullName} to ${targetJob.title}`,
            kind: "success",
            delay: 6000,
            linkTo: destinationUrl,
          });

          moveFocusToNextLogicalJobApplication();
        },

        onError: (response: { data: { errors } }) => {
          window.logger("%c[JASA] moveJobApplication onError", "color: #1976D2", {
            response,
            errors: prettyErrorsArray(response.data.errors),
          });
          const prettyErrors = prettyErrorsArray(response.data.errors);
          addToast({
            title: prettyErrors[0],
            kind: "warning",
            delay: 5000,
          });
        },
      },
    );
  };

  /* ANONYMIZE CANDIDATE DATA
  --===================================================-- */
  const handleAnonymizeCandidateConfirm = () => {
    anonymizeCandidate(
      {
        candidateId: jobApplication.candidate.id,
        jobApplicationId: jobApplication.id,
        hiringStageId: jobApplication.hiringStageId,
        jobId: jobApplication.jobId,
      },
      {
        onSuccess: (data) => {
          addToast({
            title: `Anonymized ${jobApplication.candidate.fullName}`,
            kind: "success",
            delay: 6000,
          });

          moveFocusToNextLogicalJobApplication();
        },

        onError: (response: { data: { errors } }) => {
          window.logger("%c[JASA] anonymizeCandidate onError", "color: #1976D2", {
            response,
            errors: prettyErrorsArray(response.data.errors),
          });
          const prettyErrors = prettyErrorsArray(response.data.errors);
          addToast({
            title: prettyErrors[0],
            kind: "warning",
            delay: 5000,
          });
        },
      },
    );
  };

  /* HANDLER -  MOVE JOB APPLICATION TO JOB
  --===================================================-- */
  const handleClickOnMoveToJob = (event) => {
    event.preventDefault();
    openModal(moveToJobModal);
  };

  /* HANDLER - CHOOSE STAGE
  --===================================================-- */
  const handleClickOnMove = (event) => {
    event.preventDefault();
    openModal(moveStageModal);
  };

  /* HANDLER - ADVANCE STAGE
  --===================================================-- */
  const handleOnClickAdvanceToNextStage = (event) => {
    event.preventDefault();
    moveToNextLogicalHiringStage();
  };

  /* HANDLER - ARCHIVE CANDIDATE
  --===================================================-- */
  const handleClickOnArchive = (event) => {
    event.preventDefault();

    moveToArchived();
  };

  /* HANDLER - ANONYMIZE CANDIDATE
  --===================================================-- */
  const handleClickOnAnonymize = (event) => {
    event.preventDefault();
    const anonymizeText = `This action will permanently delete all personally-identifying data for this candidate across every application they have submitted. Additionally, their applications will be archived.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this candidate?"
        subcopy={anonymizeText}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          handleAnonymizeCandidateConfirm();
        }}
        onCancel={() => {
          removeModal();
        }}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const moveToArchived = () => {
    if (currentStage.id !== archiveHiringStage.id) {
      const stage = archiveHiringStage;
      moveToStage(stage);
    }
  };
  const archiveTooltip = (
    <>
      Archive candidate <ShortcutKey>E</ShortcutKey>
    </>
  );

  const canAnonymize = () =>
    candidate.privacyStatus !== CandidatePrivacyStatus.ANONYMIZED &&
    currentOrganizationUser.isAdmin &&
    currentStage.kind !== "kind_hired";

  const nextStageAutomation = getActiveHiringStageAutomation(nextLogicalHiringStageIncludingArchived);

  return (
    <Styled.Container>
      <Styled.ButtonContainer>
        {nextLogicalHiringStageIncludingArchived != undefined &&
        nextLogicalHiringStageIncludingArchived.kind != "kind_archived" &&
        isActive ? (
          <Button
            type="button"
            styleType="primary"
            onClick={handleOnClickAdvanceToNextStage}
            loading={isLoadingMoveJobApplicationToStage}
            disabled={isLoadingMoveJobApplicationToStage}
          >
            {`Move to ${nextLogicalHiringStageIncludingArchived.name}`}
          </Button>
        ) : null}

        <DropdownMenu hpos="right" vpos="top" button testid="job-application-sidebar-overlow-menu">
          <button onClick={handleClickOnEdit}>Edit candidate</button>
          <button type="button" onClick={handleClickOnMove}>
            Move to stage <ShortcutKey>M</ShortcutKey>
          </button>
          {jobs.length > 0 && <button onClick={handleClickOnMoveToJob}>Move to job</button>}
          <FeatureFlipper feature="ANONYMIZE_CANDIDATES">
            {canAnonymize() && (
              <button onClick={handleClickOnAnonymize}>Delete candidate data</button>
            )}
          </FeatureFlipper>
        </DropdownMenu>
        {isActive && (
          <Tooltip label={archiveTooltip}>
            <Button
              type="button"
              styleType="secondary"
              onClick={handleClickOnArchive}
              disabled={isLoadingMoveJobApplicationToStage}
            >
              {/* {jobApplication.status === "status_in_process" ? "Archive" : "Unarchive"} */}
              Archive
            </Button>
          </Tooltip>
        )}
      </Styled.ButtonContainer>
      <FeatureFlipper feature="HIRING_STAGE_AUTOMATIONS">  
        <Styled.JobStageAutomationInfo>
          <JobStageAutomationInfo
            stageAutomation={nextStageAutomation}
            stageName={nextLogicalHiringStageIncludingArchived.name}
            bulk={false}
          />
        </Styled.JobStageAutomationInfo>
      </FeatureFlipper>
    </Styled.Container>
  );
}

JobApplicationSidebarActions.propTypes = {};
JobApplicationSidebarActions.defaultProps = {};

export default JobApplicationSidebarActions;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebarActions_Container;
    ${[t.px(4), t.pb(1)]}
    display: flex;
    flex-direction: column;
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebarActions_ButtonContainer;
    display: flex;
    flex-wrap: wrap;
    > * {
      ${[t.mr(3), t.mb(3)]}
    }
  `;
});

Styled.JobStageAutomationInfo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebarActions_AutomationInfo;
    display: flex;
    margin-top: -0.5rem;
  `;
});

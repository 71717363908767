import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { Link } from "react-router-dom";

import { yesOrNoOptions, localeCodeOptions } from "@ats/src/lib/lookups";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormInput from "@ats/src/components/forms/FormInput";
import FormTextarea from "@ats/src/components/forms/FormTextarea";
import FormSelect from "@ats/src/components/forms/FormSelect";
import ProseMirrorEditor from "@shared/ProseMirror/Editor";
import Button from "@ats/src/components/shared/Button";

import JobBoardBillboardMediaChooser from "@ats/src/views/accountAdmin/components/JobBoardBillboardMediaChooser";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

function AccountJobBoardContent(props: any) {
  const addToast = useToastContext();

  const { currentOrganization } = props;
  const {
    mutate: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const [careersPage, setCareersPage] = React.useState(currentOrganization?.careersPages[0]);

  const [errors, setErrors] = React.useState(null);

  const editorRef = React.useRef(undefined);

  window.logger("%c[AccountJobBoardContent] ", "background-color: #FF76D2", {
    currentOrganization,
    careersPage,
  });

  /* Update careersPage if currentOrganization changes
  --===================================================-- */
  React.useEffect(() => {
    setCareersPage(currentOrganization?.careersPages[0]);
  }, [currentOrganization]);

  if (careersPage == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const {
    additionalContent,
    showPublishedAtTimes,
    billboardTitle,
    billboardIntro,
    billboardVideoUrl,
    language,
  } = careersPage;

  const defaultEditorValue = additionalContent || "";

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    updateCareersPage(
      {
        id: careersPage.id,
        additionalContent: editorRef.current.serializedState(),
        showPublishedAtTimes,
        billboardTitle,
        billboardIntro,
        billboardVideoUrl,
        language,
      },
      {
        onSuccess: () => {
          props.setIsDirty(false);
          addToast({ title: "Job board settings saved", kind: "success" });
        },
        onError: (data) => {
          setErrors((data as any).errors);
          addToast({
            title: `Could not save settings`,
            kind: "danger",
          });
          // onError(data);
          window.logger(
            "%c[AccountJobBoardContent] updateCareersPage onerror",
            "background-color: #eb7f37",
            { data },
          );
        },
      },
    );
  };

  /* Social Share Image
  --===================================================-- */
  const handleImageUploadErrors = (errors) => {
    window.logger("%c[AccountJobBoardContent] handleImageUploadErrors", "color: #1976D2", {
      errors,
    });
    // setOrganization({ ...organization, errors });
    setErrors(errors);
  };

  /* updateCareersPageState
  --===================================================-- */
  const updateCareersPageState = (name, value) => {
    props.setIsDirty(true);
    // settings = Object.assign({}, settings, { [name]: value });
    window.logger(
      "%c[AccountJobBoardContent] updateCareersPageState",
      "color: #1976D2",
      careersPage,
      name,
      value,
    );
    setCareersPage({ ...careersPage, [name]: value });
  };

  const handleEditorChange = () => {
    props.setIsDirty(true);
  };

  const bottomBarContent = (
    <Button className="submit-button" size="small" styleType="primary" onClick={onClickSubmit}>
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="Job board content" />

      <SettingsContainer
        title="Job board content"
        description="Here's where you can define optional, additional content to be displayed on your job board."
        // actions={viewLink()}
        bottomBar={bottomBarContent}
        fullWidthForm={true}
      >
        <form onSubmit={handleSubmit}>
          <FormSection title="Language preference">
            <FormSelect
              onChange={updateCareersPageState}
              name="language"
              label="Select your prefered locale code"
              description="Change the language your job board shows up in"
              value={language}
              options={localeCodeOptions}
              errors={errors}
            />
          </FormSection>
          <FormSection title="Intro content">
            <JobBoardBillboardMediaChooser
              organizationId={currentOrganization.id}
              errors={errors}
              onError={handleImageUploadErrors}
            />

            <FormInput
              name="billboardTitle"
              onChange={updateCareersPageState}
              placeholder=""
              label="Job board title"
              description="An optional, page title that will be displayed at the top of your job board."
              className=""
              value={billboardTitle || ""}
              errors={errors}
            />

            <FormTextarea
              onChange={updateCareersPageState}
              name="billboardIntro"
              placeholder=""
              label="Job board intro"
              description="An optional, short statement displayed at the top of your job board. Limited to a maximum of 280 characters."
              value={billboardIntro || ""}
              errors={errors}
              maxCharacterCount={280}
            />
          </FormSection>

          <FormSection title="Other content">
            <FormSelect
              onChange={updateCareersPageState}
              name="showPublishedAtTimes"
              label="Display when jobs were published"
              description="Show how long ago each job was published."
              value={showPublishedAtTimes}
              options={yesOrNoOptions}
              errors={errors}
            />

            {/* --==== added the wrapping div with position relative to make the floating LinkToolbar work ====-- */}
            <div style={{ position: "relative" }}>
              <ProseMirrorEditor
                ref={editorRef}
                editorId="account-jobboard-editor"
                enableLabel
                enableStaticMenuBar
                enableCheckboxes={false}
                label="Additional page content"
                description="Optional, additional content to be displayed under the job list such as an 'About us' section. Formatted text is supported."
                onChange={handleEditorChange}
                defaultValue={defaultEditorValue}
              />
            </div>
          </FormSection>
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountJobBoardContent;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContent_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});

import React from "react";
import PropTypes, { any } from "prop-types";
import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormLabel from "./FormLabel";

type Props = {
  name: string;
  placeholder?: string;
  label?: string;
  description?: string;
  scope?: any; // TODO: remove usage of this prop and delete it, bad pattern
  onChange?: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
  className?: string;
  inputType?: string;
  rows?: any;
  value?: any;
  autoFocus?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  errors?: any[];
  onSelectionUpdate?: ({ ...args }) => void;
  children?: any;
  maxCharacterCount?: number;
  requiredLabel?: string;
};
function FormTextarea(props: Props) {
  const { errors, name, value, maxCharacterCount, requiredLabel = "required" } = props;
  const textAreaRef = React.useRef(null);
  let errorText = errorTextViaYupOrRailsOrJoi({ errors, name });
  const [characterCount, setCharacterCount] = React.useState(0);

  React.useEffect(() => {
    updateSelection();
  }, []);

  React.useEffect(() => {
    textAreaRef.current.addEventListener("keydown", (event) => {
      if (event.keyCode === 27 || event.key === "Escape") {
        textAreaRef.current.blur();
      }
      return () => {
        textAreaRef.current.removeEventListener("keydown");
      };
    });
  }, []);

  const handleChange = (event) => {
    event.preventDefault();

    updateSelection();

    if (props.scope !== undefined) {
      // TODO: deprecate this please, don't think actively used anymore
      props.scope.setState({ [props.name]: event.currentTarget.value, errors: null });
    } else if (props.onChange !== undefined) {
      const newValue = event.currentTarget.value;
      if (newValue.length > maxCharacterCount) {
        const sliced = newValue.slice(0, 280);
        props.onChange(props.name, sliced);
        setCharacterCount(sliced.length);
      } else {
        props.onChange(props.name, event.currentTarget.value);
        setCharacterCount(newValue.length);
      }
    }
  };

  const handleBlur = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Use the parent's scope to setState
    if (props.scope !== undefined && props.onBlur === undefined) {
      props.scope.setState({ [props.name]: event.currentTarget.value });
    } else if (props.onBlur !== undefined) {
      props.onBlur(props.name, event.currentTarget.value);
    }
  };

  const updateSelection = () => {
    const textarea = textAreaRef.current;

    if (props.onSelectionUpdate != undefined) {
      props.onSelectionUpdate({
        start: textarea.selectionStart,
        end: textarea.selectionEnd,
        textarea,
      });
    }

    // window.logger("%c[CMTM] updateSelection", "color: #1976D2", {
    //   // textarea,
    //   start: textarea.selectionStart,
    //   end: textarea.selectionEnd,
    // });
  };

  const textareaDescription = () => {
    if (props.description) {
      return (
        <p>
          {props.description}
          {maxCharacterCount != undefined ? (
            <span className={characterCount >= maxCharacterCount ? "over-count" : ""}>
              {`${characterCount > 0 ? ` (${characterCount}/${maxCharacterCount})` : ""}`}
            </span>
          ) : null}
        </p>
      );
    }
  };

  const inputId = props.label
    ? `input${props.label.replace(/\s+/g, "")}`
    : props.name
    ? props.name
    : "Default";

  return (
    <Styled.Container>
      <FormLabel
        label={props.label}
        for={inputId}
        isRequired={props.isRequired}
        requiredLabel={requiredLabel}
        error={errorText}
      />
      {textareaDescription()}
      <Styled.TextArea
        ref={textAreaRef}
        id={inputId}
        hasError={errorText}
        rows={props.rows}
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={props.name}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        disabled={props.isDisabled}
        onMouseUp={updateSelection}
        onKeyUp={updateSelection}
      />
      {props.children} {/* For displaying Joi rendered errors. See NewInviteForm example. */}
    </Styled.Container>
  );
}

FormTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  scope: PropTypes.object, // typically set to "this" from the parent so we can use setState
  onChange: PropTypes.func, //.isRequired, // This get's used as a callback if scope is not set
  className: PropTypes.string,
  inputType: PropTypes.string,
  rows: PropTypes.any,
  value: PropTypes.any,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelectionUpdate: PropTypes.func,
};

FormTextarea.defaultProps = {
  isRequired: false,
  isDisabled: false,
};

export default FormTextarea;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: FormTextarea;
    ${t.mb(6)}
    > p {
      ${[t.text.sm, t.mt(-1), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq["md"]} {
        ${t.text.xs}
      }
    }

    ${t.mq["md"]} {
      ${t.mb(5)}
    }

    span {
      ${[t.px(1), t.rounded.xs]}
      display: inline-flex;

      &.over-count {
        background-color: ${t.dark ? t.color.gray[700] : t.color.red[100]};
        color: ${t.dark ? t.color.red[400] : t.color.red[600]};
      }
    }
  `;
});

Styled.TextArea = styled.textarea((props: any) => {
  const t: any = props.theme;
  return css`
    label: FormTextarea_UI;
    ${[t.p(2), t.text.base, t.rounded.sm]}
    appearance: none;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: ${t.spacing[24]};
    line-height: 1.4;
    text-overflow: ellipsis;
    border-width: 1px;
    border-style: solid;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    border-color: ${props.hasError
      ? t.dark
        ? t.color.red[400]
        : t.color.red[500]
      : t.dark
      ? "transparent"
      : t.color.gray[300]};
    &::placeholder {
      color: ${t.color.gray[500]};
    }
    &:hover {
      border-color: ${props.hasError
        ? t.dark
          ? t.color.red[300]
          : t.color.red[600]
        : t.dark
        ? t.color.gray[600]
        : t.color.gray[400]};
    }
    &:focus {
      outline: none;
      border-color: ${props.hasError
        ? t.dark
          ? t.color.red[300]
          : t.color.red[600]
        : t.dark
        ? t.color.gray[500]
        : t.color.gray[400]};
      ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
    }
    &:disabled {
      color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
      background-color: ${t.dark ? "transparent" : t.color.gray[100]};
      ${t.dark && `border: 1px solid ${t.color.gray[800]};`}
    }
    &:disabled:hover {
      ${!t.dark && `border-color: ${t.color.gray[300]};`}
    }

    ${t.mq["md"]} {
      ${[t.text.sm]}
    }
  `;
});

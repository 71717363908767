import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { Link } from "react-router-dom";
import toLower from "lodash/toLower";
import { removeHttpPrefix } from "@shared/lib/utils";

import { careerNavigationOptions } from "@ats/src/lib/lookups";
import { validateAccountJobBoardNavigation } from "@shared/lib/validateWithYup";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import Button from "@ats/src/components/shared/Button";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

function AccountJobBoardNavigation(props: any) {
  const addToast = useToastContext();

  const { currentOrganization } = props;
  const {
    mutate: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const [careersPage, setCareersPage] = React.useState(currentOrganization?.careersPages[0]);
  const [errors, setErrors] = React.useState(null);

  window.logger("%c[AccountJobBoardNavigation] ", "background-color: #FF76D2", {
    currentOrganization,
    careersPage,
  });

  /* Update careersPage if currentOrganization changes
  --===================================================-- */
  React.useEffect(() => {
    setCareersPage(currentOrganization?.careersPages[0]);
  }, [currentOrganization]);

  if (careersPage == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const { slug, hasCustomNavigation, customNavigationUrl } = careersPage;

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    const [isValid, validationErrors] = await validateAccountJobBoardNavigation({
      slug,
    });

    window.logger("%c[AccountJobBoardNavigation] handleSubmit VALIDATION", "color: #1976D2", {
      isValid,
      validationErrors,
    });

    if (!isValid) {
      setErrors(validationErrors);
    } else {
      updateCareersPage(
        {
          id: careersPage.id,
          slug: toLower(slug),
          hasCustomNavigation,
          customNavigationUrl,
        },
        {
          onSuccess: () => {
            props.setIsDirty(false);
            addToast({ title: "Navigation settings saved", kind: "success" });
          },
          onError: (data) => {
            setErrors((data as any).errors);
            addToast({
              title: `Could not save settings`,
              kind: "danger",
            });
            // onError(data);
            window.logger(
              "%c[AccountJobBoardNavigation] updateCareersPage onerror",
              "background-color: #eb7f37",
              { data },
            );
          },
        },
      );
    }

    // const schema = Joi.object().keys({
    //   slug: Joi.string().min(2).required(),
    //   // /\A[a-z0-9_-.]+\z/i
    // });

    // Joi.validate({ slug }, schema, (err) => {
    //   let errors;
    //   if (err !== null) {
    //     errors = {};

    //     err.details.forEach((index) => {
    //       let key = index.context.key;
    //       let message = index.message.replace(`"${key}" `, "");
    //       errors[key] = [message];
    //     });

    //     window.logger(
    //       "%c[AccountJobBoardNavigation] handleSubmit errors",
    //       "color: #1976D2",
    //       err.details,
    //     );

    //     setErrors(err.details);
    //   } else {

    //   }
    // });
  };

  /* updateCareersPageState
  --===================================================-- */
  const updateCareersPageState = (name, value) => {
    props.setIsDirty(true);
    // settings = Object.assign({}, settings, { [name]: value });
    window.logger(
      "%c[AccountJobBoardNavigation] updateCareersPageState",
      "color: #1976D2",
      careersPage,
      name,
      value,
    );
    setCareersPage({ ...careersPage, [name]: value });
  };

  const navigationDescription = (
    <span data-testid="careers-page-navigation-description">
      By default, the 'Back' navigation link that appears on your job posts will take visitors to
      your full job board (
      <a href={currentOrganization?.careersPageUrl} target="_blank" rel="noopener noreferrer">
        {removeHttpPrefix(currentOrganization?.careersPageUrl)}
      </a>
      ). You can supply a custom URL if you would like the link to take visitors to a different web
      page.
    </span>
  );

  const bottomBarContent = (
    <Button className="submit-button" size="small" styleType="primary" onClick={onClickSubmit}>
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="Job board navigation" />

      <SettingsContainer
        title="Job board navigation"
        description="Here's where you can edit the URL and navigation behavior of your job board."
        // actions={viewLink()}
        bottomBar={bottomBarContent}
        fullWidthForm={true}
      >
        <form onSubmit={handleSubmit}>
          <FormSection>
            <FormInput
              name="slug"
              onChange={updateCareersPageState}
              pretext="https://jobs.polymer.co/"
              placeholder="organization-name"
              label="Job board URL"
              className=""
              value={slug || ""}
              errors={errors}
            />

            <FormSelect
              onChange={updateCareersPageState}
              name="hasCustomNavigation"
              label="Job post 'Back' navigation"
              description={navigationDescription}
              className=""
              value={hasCustomNavigation}
              options={careerNavigationOptions}
              errors={errors}
            />
            {hasCustomNavigation && (
              <FormConditionalFields>
                <div className="account-company__custom-url">
                  <FormInput
                    onChange={updateCareersPageState}
                    name="customNavigationUrl"
                    pretext="https://"
                    placeholder="..."
                    label="Custom navigation URL"
                    description="You selected 'Custom URL' for the preceding job post 'Back' navigation setting. What URL would you like to use?"
                    className="account-company__custom-url-field"
                    value={removeHttpPrefix(customNavigationUrl) || ""}
                    errors={errors}
                  />
                </div>
              </FormConditionalFields>
            )}
          </FormSection>
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountJobBoardNavigation;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardNavigation_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});

import colors from "./colors";
import theme from "./theme";

const poly = {
  color: {
    canvas: colors.gray[900],
    border: colors.gray[800],
    borderHover: colors.gray[600],
    loudText: colors.gray[200],
    primaryText: colors.gray[300],
    secondaryText: colors.gray[400],
    placeholderText: colors.gray[500],

    subtleHover: colors.gray[800],
    loudHover: colors.gray[700],

    wellCanvas: colors.gray[900],

    calloutCanvas: "rgba(255, 255, 255, 0.07)",

    dropdownCanvas: colors.gray[700],
    dropdownBorder: colors.gray[600],
    dropdownDivider: colors.gray[600],
    dropdownHover: colors.gray[500],
    dropdownTextHover: colors.white,

    cardCanvas: colors.gray[800],
    cardBorder: colors.gray[800],
    cardDivider: colors.gray[700],

    chipBorder: colors.gray[700],
  },
  ...theme,
};

export default poly;

import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

class InitialsAvatar extends Component {
  static displayName = "InitialsAvatar";

  static propTypes = {
    initials: PropTypes.string.isRequired,
    size: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    // key: value,
  };

  constructor(props) {
    super(props);
    //this.state = {};
  }

  render() {
    return <Styled.Avatar size={this.props.size}>{this.props.initials}</Styled.Avatar>;
  }
}

export default InitialsAvatar;

/* Styled Components
======================================================= */
const Styled = {};

const tinyFont = css`
  font-size: 0.7rem;
`;

Styled.Avatar = styled.div((props) => {
  const t = props.theme;
  const size = props.size;
  return css`
    label: InitialsAvatar;
    ${size === "lg" ? [t.h(10), t.w(10), t.text.lg, t.rounded.sm, t.text.bold] :
      size === "sm" ? [t.h(6), t.w(6), tinyFont, t.rounded.xs, t.text.bold] :
        [t.h(8), t.w(8), t.text.base, t.rounded.sm, t.text.bold]}
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${t.color.white};
    background-color: ${t.color.gray[600]};
    text-transform: lowercase;
    flex-shrink: 0;
  `;
});

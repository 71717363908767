import React, { useState } from "react";
import useScript from "@shared/hooks/useScript";
import AsyncSelect from "react-select/lib/Async";
import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormLabel from "@ats/src/components/forms/FormLabel";

type Props = {
  value: string;
  name: string;
  label: string;
  description: string;
  language: string;
  className: string;
  onChange: (value: any) => void;
  isRequired?: boolean;
  errors: any;
};

const FormSelectGoogleLocation = (props: Props) => {
  let errorText = errorTextViaYupOrRailsOrJoi(props);

  const [state, setState] = useState({
    value: props.value || "",
  });

  // wrkhq.com has a Google cloud account and has the Maps API enabled
  const [mapsScriptLoaded, mapsScriptError] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${(window as any).GOOGLE_MAPS_API_KEY
    }&libraries=places&language=${props.language}`,
  );

  // Geocode based on place_id
  // https://maps.googleapis.com/maps/api/geocode/json?place_id=ChIJOV0UiM4sT4gRCxvJBEeoYXY&key=AIzaSyDX-jh453PLhf9_jinTpIbic-PfC_I-SXw

  function parsePlacesToOptions(places) {
    if (places === null) return [];

    // the types array in the result
    // https://developers.google.com/maps/documentation/geocoding/overview#Types

    return places.map((place) => {
      // window.logger(
      //   "%c[FormSelectGoogleLocation] parsePlacesToOptions",
      //   "background-color: #1976D2",
      //   {
      //     place,
      //   },
      // );
      return { value: place.description, label: place.description, place };
    });
  }

  function placeOptions(value, callback) {
    const request = {
      input: value,
    };

    if ((window as any).google) {
      const autoComplete = new (window as any).google.maps.places.AutocompleteService();

      if (value.length > 0) {
        autoComplete.getPlacePredictions(request, (data) => {
          callback(parsePlacesToOptions(data));
        });
      } else {
        callback([]);
      }
    } else {
      console.error(
        "[FormSelectGoogleLocation] placeOptions window.google was not loaded!",
        (window as any).google,
      );
    }
  }

  function handleInputChange(newValue, actionMeta) {
    if (actionMeta.action !== "input-blur" && actionMeta.action !== "menu-close") {
      setState({ ...state, value: newValue });
    }
  }

  function handleChange(newValue, actionMeta) {
    if (actionMeta.action === "select-option" && props.onChange !== undefined) {
      props.onChange(newValue);
    }
  }

  if (!mapsScriptLoaded || mapsScriptError) {
    return <span>Loading...</span>;
  }

  return (
    <Styled.Container>
      <FormLabel label={props.label} isRequired={props.isRequired} error={errorText} />
      {props.description && <p>{props.description}</p>}
      <Styled.Select
        id="async-react-select"
        classNamePrefix="form-select-ui"
        hasError={errorText}
        inputValue={state.value}
        isClearable
        cacheOptions
        loadOptions={placeOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder="Search for a location..."
      />
    </Styled.Container>
  );
};

FormSelectGoogleLocation.defaultProps = {
  language: "en",
};

export default FormSelectGoogleLocation;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormSelectGoogleLocation;
    ${t.mb(5)}
    > p {
      ${[t.text.xs, t.mt(-1), t.mb(2)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq['md']} {
        ${t.text.xs}
      }
    }

    ${t.mq['md']} {
      ${t.mb(5)}
    }
  `;
});

Styled.Select = styled(AsyncSelect)((props: any) => {
  const t: any = props.theme;
  return css`
    label: FormSelectGoogleLocation_UI;
    .form-select-ui {
      &__clear-indicator {
        cursor: pointer;
      }
      &__control {
        ${[t.h(10), t.rounded.sm, t.text.base]}
        min-height: ${t.spacing[10]};
        width: 100%;
        appearance: none;
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
        border-width: 1px;
        border-style: solid;
        border-color: ${props.hasError
      ? t.dark
        ? t.color.red[400]
        : t.color.red[500]
      : t.dark
        ? "transparent"
        : t.color.gray[300]};
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        &:hover {
          border-color: ${props.hasError
      ? t.dark
        ? t.color.red[300]
        : t.color.red[600]
      : t.dark
        ? t.color.gray[600]
        : t.color.gray[400]};
        }
        &:focus {
          outline: none;
          border-color: ${props.hasError
      ? t.dark
        ? t.color.red[300]
        : t.color.red[600]
      : t.dark
        ? t.color.gray[500]
        : t.color.gray[400]};
          ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &:focus:hover,
        &--is-focused,
        &--is-focused:hover {
          outline: none;
          border-color: ${props.hasError
      ? t.dark
        ? t.color.red[300]
        : t.color.red[600]
      : t.dark
        ? t.color.gray[500]
        : t.color.gray[400]};
          ${t.dark ? "box-shadow: none;" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &--is-disabled {
          background-color: ${t.dark ? "transparent" : t.color.gray[100]};
          .form-select-ui__single-value {
            color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
          }
          ${t.dark && `border: 1px solid ${t.color.gray[800]};`}

          .form-select-ui__indicator svg {
            fill: ${t.color.gray[500]};
            stroke: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
          }
        }

        ${t.mq['md']} {
          ${[t.h(8), t.text.sm]}
          min-height: ${t.spacing[8]};
        }
      }
      &__option {
        ${[t.h(10), t.px(2), t.text.base]}
        transition: color 0.2s ease, background-color 0.2s ease;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[700] : t.color.white};
        color: ${t.dark ? t.color.gray[200] : t.color.gray[600]};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &--is-focused {
          background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &--is-selected {
          background-color: ${t.dark ? t.color.gray[600] : t.color.gray[100]};
          color: ${t.dark ? t.color.white : t.color.black};
        }

        ${t.mq['md']} {
          ${[t.h(8), t.text.sm]}
        }
      }
      &__placeholder {
        ${[t.mx(0)]}
        color: ${t.color.gray[500]};
      }
      &__indicators {
        ${t.h(8)}
        align-items: center;
        height: 100%;
        span {
          display: none;
        }
      }
      &__indicator svg {
        height: 1.125rem;
        width: 1.125rem;
        stroke-width: 1px;
        fill: ${t.dark ? t.color.gray[300] : t.color.black};
        stroke: ${t.dark ? t.color.gray[800] : t.color.white};
      }
      &__dropdown-indicator {
        ${t.mr("px")}
        padding: 0.3125rem;
      }
      &__clear-indicator svg {
        ${[t.rounded.xs]}
        position: relative;
        right: -${t.spacing[2]};
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
      }
      &__clear-indicator:hover svg {
        background-color: ${t.dark ? t.color.orange[200] : t.color.gray[300]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.orange[200] : t.color.gray[300]};
        fill: ${t.color.black};
      }
      &__menu {
        ${[t.mt("-px"), t.rounded.sm]};
        border: 1px solid ${t.dark ? t.color.gray[500] : t.color.gray[400]};
        box-shadow: none;
        z-index: 20;
      }
      &__menu-list {
        ${[t.rounded.xs]};
        padding: 0;
      }
      &__value-container {
        ${[t.mx(0)]};
        height: 100%;
        > div {
          ${t.m(0)}
        }
      }
      &__input,
      &__single-value {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        ${t.m(0)};
      }
      &__multi-value {
        ${[t.m("px"), t.rounded.xs]};
        margin-right: 0.25rem !important;
        height: 1.375rem;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
        transition: background-color 0.2s ease;
        &__label {
          ${[t.text.sm, t.py("px"), t.pr(0), t.pl(2)]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &__remove {
          height: 1.375rem;
          color: ${t.dark ? t.color.gray[400] : t.color.gray[500]};
          &:hover {
            background: none;
            color: ${t.dark ? t.color.white : t.color.black};
            cursor: pointer;
          }
        }
      }
      &__menu-notice,
      &__menu-notice--no-options {
        color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
        background-color: ${t.dark ? t.color.gray[800] : t.color.white};
      }
    }
  `;
});

import React from "react";
import { Global, css } from "@emotion/react";
import scrollbarStyles from "@shared/styles/scrollbar";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

const GlobalScrollbarStyles: React.FC = () => {
  const { isDarkMode } = useColorSchemeContext();

  return (
    <Global
      styles={css`
        html, body, #root, * {
          ${scrollbarStyles(isDarkMode)}
        }
      `}
    />
  );
};

export default GlobalScrollbarStyles;
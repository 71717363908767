import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useToastContext } from "@shared/context/ToastContext";
import { prettyBillingPrice } from "@ats/src/lib/utils/helpers";

import { useBillingPrices, useCreateStripeCheckoutSession } from "@shared/queryHooks/useBilling";

import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

type Props = {
  onCancel: () => void;
  jobId: number;
  location: any;
  isAdmin: boolean; // Testing
};

const DEFAULT_PRICE_LOOKUP_KEY = "plan_ats_tier_apollo_monthly";

function SubscriptionRequiredModal(props: Props) {
  const { jobId, location } = props;
  const addToast = useToastContext();
  const { currentOrganization } = useCurrentSession();

  const {
    data: billingPricesData,
    isFetching: isFetchingBillingPrices,
    isLoading: isLoadingBillingPrices,
  } = useBillingPrices({
    refetchOnWindowFocus: false,
  });
  const billingPrices = billingPricesData != undefined ? billingPricesData.data : [];
  const apolloBillingPrices = billingPrices
    .filter((price) => price.lookupKey?.includes("apollo"))
    .sort((a, b) => a.unitAmount - b.unitAmount); // Sorting by unitAmount so that the Monthly Plan shows up first in the list

  const targetBillingPrice =
    billingPrices.length > 0
      ? billingPrices.find((price) => price.lookupKey === DEFAULT_PRICE_LOOKUP_KEY)
      : null;

  const planBasedBillingEnabled = useFeatureFlipper()({ feature: Features.PLAN_BASED_BILLING });

  window.logger("%c[SubscriptionRequiredModal] ", "color: #1976D2", {
    billingPrices,
    targetBillingPrice,
  });

  const {
    mutate: createStripeCheckoutSession,
    error: errorOnCreateCheckoutSession,
    isLoading: isLoadingCreateCheckoutSession,
  } = useCreateStripeCheckoutSession();

  window.logger("%c[SubscriptionRequiredModal] ", "background-color: #7fa7cf", {
    props,
    location,
    jobId,
    billingPricesData,
    billingPrices,
    targetBillingPrice,
    serverEnv: window.SERVER_ENV,
  });

  const createCheckoutSession = ({ priceId, planType }) => {
    createStripeCheckoutSession(
      {
        priceId,
        successUrl: `/jobs/${jobId}/stripe_checkout_redirect_handler`,
        cancelUrl: `/jobs/${jobId}/stripe_checkout_redirect_handler`,
        locationPathname: location.pathname,
        jobId,
        planType,
      },
      {
        onError: (data) => {
          window.logger(
            "%c[SubscriptionRequiredModal] onError createStripeCheckoutSession",
            "background: orange; color: #1976D2",
            data,
          );
        },
        onSuccess: (data) => {
          window.logger(
            "%c[SubscriptionRequiredModal] completed creation of Checkout Session",
            "background: orange; color: #1976D2",
            data,
          );
          (window as any).STRIPE.redirectToCheckout({
            sessionId: data.id,
          }).then(function (result) {
            window.logger(
              "%c[SubscriptionRequiredModal] REDIRECTING",
              "background: green; color: white",
              result,
            );

            props.onCancel();
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        },
      },
    );
  };

  const planOptions = (
    <Styled.ButtonContainer key={targetBillingPrice?.id}>
      {props.isAdmin && (
        <Button
          name={`startPlanButton-${targetBillingPrice?.lookupKey}`}
          type="submit"
          onClick={() =>
            createCheckoutSession({ priceId: targetBillingPrice?.id, planType: "unlimited" })
          }
          loading={isLoadingCreateCheckoutSession}
          disabled={targetBillingPrice == undefined || isLoadingCreateCheckoutSession}
        >
          {/* ${prettyBillingPrice(targetBillingPrice)}{" "}
            <span>per {targetBillingPrice.interval || targetBillingPrice.recurring.interval}</span> */}
          Continue
        </Button>
      )}
      <Button styleType="secondary" onClick={props.onCancel}>
        {props.isAdmin ? "Cancel" : "Dismiss"}
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText={"Start your free trial"} onCancel={props.onCancel}>
      <>
        <Styled.Instructions>
          {`Set up your subscription to publish jobs and you'll receive a 14-day free trial. Then it's `}
          <b>{`$${targetBillingPrice ? prettyBillingPrice(targetBillingPrice) : null}`}</b>
          <span>
            {" "}
            per {targetBillingPrice?.interval || targetBillingPrice?.recurring?.interval}
          </span>
          {`. Learn more on our `}
          <a href="https://polymer.co/pricing" target="_blank" rel="noopener noreferrer">
            pricing page
          </a>
          .
        </Styled.Instructions>
        {planOptions}
      </>
    </CenterModal>
  );
}

export default SubscriptionRequiredModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: SubscriptionRequiredModal_Instructions;
    ${[t.mb(5)]}

    a {
      ${t.text.medium}
    }

    a:hover {
      text-decoration: underline;
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SubscriptionRequiredModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import { useSpring, animated } from "react-spring";

import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";

import useMeasure from "@shared/hooks/useMeasure";

interface Props {
  children: React.ReactNode;
  label: string;
  iconName?: string;
  isDisabled?: boolean;
  emptyLabel?: string;
  defaultHeight?: number;
}

function CollapsibleItem({
  children,
  label,
  iconName,
  isDisabled = false,
  emptyLabel = "No content available",
  defaultHeight = 32,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [resizeRef, { height }] = useMeasure();

  // window.logger("%c[CollapsibleItem] render", "color: #1976D2", { props });

  const handleKeyPress = (event) => {
    if (!isDisabled && (event.key === "Enter" || event.key === "Space")) {
      handleClickCollapsibleItem();
    }
  };

  const handleClickCollapsibleItem = () => {
    setIsOpen(!isOpen);
  };

  const expandSpring = useSpring({
    height: isOpen ? `${height + defaultHeight}px` : `${defaultHeight}px`,
    config: {
      tension: 250,
      friction: 36,
    },
  });

  const collapsibleItemBodySpring = useSpring({
    from: {
      opacity: 0,
      height: 0,
    },
    to: {
      opacity: isOpen ? 1 : 0,
      height: isOpen ? height : 0,
    },
    config: {
      tension: 250,
      friction: 36,
    },
  });

  const animatedChevron = useSpring({
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  });

  return (
    <Styled.CollapsibleItemContainer
      isOpen={isOpen}
      style={expandSpring}
      tabIndex="0"
      role="button"
      aria-expanded={isOpen}
      onKeyDown={handleKeyPress}
      disabled={isDisabled}
      aria-disabled={isDisabled}
    >
      <Styled.CollapsibleItemHeader
        onClick={handleClickCollapsibleItem}
        isOpen={isOpen}
        aria-controls="collapsible-body"
        disabled={isDisabled}
        aria-disabled={isDisabled}
      >
        <Styled.CollapsibleItemLabel>
          {iconName && <Icon name={iconName} />}
          {isDisabled ? emptyLabel : label}
        </Styled.CollapsibleItemLabel>
        <Tooltip label={isOpen ? "Hide content" : "Expand content"}>
          <Styled.AnimatedIcon style={animatedChevron} disabled={isDisabled}>
            <Icon name="chevron-down" />
          </Styled.AnimatedIcon>
        </Tooltip>
      </Styled.CollapsibleItemHeader>
      <Styled.CollapsibleItemBody
        id="collapsible-body"
        style={collapsibleItemBodySpring}
        aria-hidden={!isOpen}
      >
        <div ref={resizeRef}>{children}</div>
      </Styled.CollapsibleItemBody>
    </Styled.CollapsibleItemContainer>
  );
}

export default CollapsibleItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CollapsibleItemContainer = styled(animated.div, { shouldForwardProp: isPropValid })(
  (props: any) => {
    const t: any = props.theme;
    const open = props.isOpen;
    const disabled = props.disabled;
    // window.logger("%c[Styled.CollapsibleItemContainer] props", "color: #1976D2", { props });

    return css`
      label: CollapsibleItem_CollapsibleItemContainer;
      ${[t.mt(3), t.text.sm, t.rounded.sm]}
      width: 100%;
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      background: ${t.dark ? t.color.gray[800] : t.color.white};
      transition: box-shadow 0.2s ease, border-radius 0.2s ease; background 0.2s ease;

      ${
        !open &&
        `
          box-shadow: inset 0 0 0 1px ${t.dark ? "transparent" : t.color.gray[200]};
        `
      }

      ${
        open &&
        `
          border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[300]};
          outline: none;
        `
      }

      ${
        disabled &&
        `
        pointer-events: none;
      `
      }

      &:hover {
        ${
          !open &&
          `
            box-shadow: inset 0 0 0 1px ${t.dark ? t.color.gray[700] : t.color.gray[400]};
            background: ${t.dark ? t.color.gray[900] : t.color.white}; 
          `
        }
      }

      > div {
        &:hover {
          color: ${t.dark ? t.color.gray[300] : t.color.black};
          cursor: pointer;
        }

        ${
          open &&
          `
            color: ${t.dark ? t.color.gray[300] : t.color.black};
          `
        }
      }

      &:focus {
        ${
          !open &&
          `
            outline: none;
            box-shadow: inset 0 0 0 1px ${t.dark ? t.color.gray[600] : t.color.gray[400]};
          `
        }
      }
    `;
  },
);

Styled.CollapsibleItemHeader = styled.div((props: any) => {
  const t: any = props.theme;
  const open = props.isOpen;
  return css`
    label: CollapsibleItem_Header;
    ${[t.rounded.sm, t.pl(2)]}
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border: none;
    outline: none;
    background: transparent;

    ${
      open &&
      `
        border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[300]};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `
    }
    }
  `;
});

Styled.CollapsibleItemLabel = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleItem_Label;
    ${[t.text.sm]}
    display: flex;
    align-items: center;

    svg {
      ${t.mr(2)}
    }
  `;
});

Styled.AnimatedIcon = styled(animated.div)((props: any) => {
  const t: any = props.theme;
  const disabled = props.disabled;
  return css`
    ${[t.h(8), t.w(8), t.rounded.sm]}
    label: CollapsibleItem_AnimatedIcon;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    ${disabled &&
    `
        visibility: hidden;
      `}
  `;
});

Styled.CollapsibleItemBody = styled(animated.div)((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleItem_Body;
    ${[t.rounded.sm]}
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const FormConditionalFields = ({ children }) => {
  return (
    <Styled.Component>
      {children}
    </Styled.Component>
  );
};

export default FormConditionalFields;

let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormConditionalFields;
    ${[t.mb(5), t.pl(3)]}
    border-left: 4px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
  `;
});
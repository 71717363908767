import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { DirectUpload } from "activestorage";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import Button from "@ats/src/components/shared/Button";

import { isAllowedFileType } from "@ats/src/lib/utils/fileUploads";
import { useToastContext } from "@shared/context/ToastContext";
import { useJobApplicationStore } from "@ats/src/lib/store/zustand/jobApplicationStore";

const EXPECTED_FILETYPE = "document";

type Props = {
  onCompleteDirectUpload: any;
  onStartDirectUpload: any;
};

function DragAndDropResumeUploader({ onCompleteDirectUpload, onStartDirectUpload }: Props) {
  const addToast = useToastContext();
  const [convertingDocxToPdf, setConvertingDocxToPdf] = useJobApplicationStore((state) => [
    state.convertingDocxToPdf,
    state.setConvertingDocxToPdf,
  ]);
  const onDrop = React.useCallback((acceptedFiles) => {
    // Do something with the files

    const file = acceptedFiles[0];

    if (file == undefined) {
      return;
    }

    window.logger("%c[DragAndDropResumeUploader] onDrop", "color: #1976D2", {
      acceptedFiles,
      fileType: file.type,
    });

    if (!isAllowedFileType(EXPECTED_FILETYPE, file.type)) {
      addToast({
        title: `File must be a PDF or DOCX`,
        kind: "warning",
      });
      return;
    }

    handleFileUpload(file);

    window.logger("%c[DADRES] ", "color: #1976D2");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [errors, setErrors] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  // window.logger("%c[DragAndDropResumeUploader] RENDER", "color: #086868", {
  //   getRootProps: { ...getRootProps() },
  //   getInputProps: { ...getInputProps() },
  //   isDragActive,
  // });

  const handleFileUpload = (file) => {
    const upload = new DirectUpload(
      file,
      `/api/v1/public/rails/active_storage/direct_uploads`,
      this,
    );

    onStartDirectUpload(true);

    upload.create((error, blob) => {
      if (error) {
        console.error("[DADRES] handleFileUpload directUpload error", error);
      } else {
        // window.logger('%c[DADRES] uploaded file', 'color: #1976D2', { blob });
        onCompleteDirectUpload(blob).then((data) => {
          window.logger("%c[DADRES] onCompleteDirectUpload", "color: #1976D2", { data });
          // if (data !== undefined) send("UPLOAD_COMPLETE");
        });
      }
    });
  };

  const onChangeUploader = ({ fileToUpload }) => {
    setFileToUpload(fileToUpload);
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid resume file`, kind: "warning" });
  };

  return (
    <Styled.Container {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />
      <Styled.IconWrapper>
        <Icon name="file" />
      </Styled.IconWrapper>
      <h2>No resume to show</h2>
      <p>You can drag and drop one here to upload it or...</p>
      <Button styleType="secondary">Select a file</Button>
    </Styled.Container>
  );
}

export default DragAndDropResumeUploader;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: DragAndDropResumeUploader;
    ${[t.py(5), t.px(6), t.rounded.md]}
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    transition: border-color 0.2s ease;
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    ${props.isDragActive
      ? `
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      `
      : ""}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    h2 {
      ${[t.text.sm]};
    }
    p {
      ${[t.text.xs, t.mt(1)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    button {
      ${t.mt(4)}
    }
  `;
});

Styled.IconWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: DragAndDropResumeUploader_IconWrapper;
    ${[t.text.sm, t.mb(2)]}
  `;
});

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import HiringStageModal from "@ats/src/components/modals/HiringStageModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import AlertModal from "@ats/src/components/modals/AlertModal";
import EmptyState from "@ats/src/components/shared/EmptyState";

import HiringStageList from "@ats/src/views/accountAdmin/components/HiringStageList";
import HiringStageListItem from "@ats/src/views/accountAdmin/components/HiringStageListItem";

import { useHiringStages } from "@shared/queryHooks/useHiringStage";
import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateHiringStage, useDeleteHiringStage } from "@shared/queryHooks/useHiringStage";
import { moveFromIndexToIndex } from "@shared/lib/utils";

export default function AccountHiringStages(props) {
  const { job } = props;

  const { status, data, error, isFetching } = useHiringStages({ jobId: job.id });
  const { mutate: updateHiringStage, isLoading: isLoadingUpdate } = useUpdateHiringStage();
  const { mutate: deleteHiringStage, isLoading: isLoadingDelete } = useDeleteHiringStage();
  const { openModal, removeModal } = useModalContext();
  const addToast = useToastContext();

  const hiringStageItems = data != undefined ? data : [];
  const [hiringStages, setHiringStages] = useState(hiringStageItems);
  const [errors, setErrors] = useState(null);

  const inboxStage = hiringStages.filter((stage) => stage.kind === "kind_inbox")[0];
  const inProcessStages = hiringStages.filter((stage) => stage.kind === "kind_in_process");
  const hiredStage = hiringStages.filter((stage) => stage.kind === "kind_hired")[0];
  const archivedStage = hiringStages.filter((stage) => stage.kind === "kind_archived")[0];

  React.useEffect(() => {
    setHiringStages(hiringStageItems);
  }, [hiringStageItems]);

  window.logger("%c[AccountHiringStages] render", "background: #EFDDEF; color: #1976D2", {
    props,
    hiringStages,
    inboxStage,
    inProcessStages,
    hiredStage,
    archivedStage,
  });

  const handleClickOnDeleteHiringStage = (hiringStage) => {
    let modal;

    if (Number(hiringStage.jobApplicationsCount) > 0) {
      modal = (
        <AlertModal
          title="This hiring stage is not empty"
          subcopy={`You need to remove all candidates from the ${hiringStage.name} stage before it can be deleted.`}
          confirmText="Okay"
          onConfirm={removeModal}
          onCancel={removeModal}
        />
      );
    } else {
      modal = (
        <ConfirmationModal
          title="Are you sure you want to delete this hiring stage?"
          subcopy={`This will remove the ${hiringStage.name} stage from this job.`}
          confirmText="Delete stage"
          cancelText="Keep stage"
          onConfirm={() => {
            removeModal();
            handleDeleteHiringStage(hiringStage);
          }}
          onCancel={removeModal}
          isDestructiveConfirm={false}
        />
      );
    }

    openModal(modal);
  };

  const handleDeleteHiringStage = async (hiringStage) => {
    try {
      await deleteHiringStage(hiringStage, {
        onError: ({ response }) => {
          window.logger("[HiringStageModal] handle error when deleting HiringStage", { response });

          // setErrors(response.data.errors);
          const generalErrors = response.data.errors["general"];

          if (generalErrors) {
            addToast({ title: generalErrors[0], kind: "warning", delay: 3000 });
          }
        },
      });
      // hiringStage was successfully deleted
    } catch (error) {
      // Uh oh, something went wrong
      console.error("[HiringStageModal] handle error when deleting Job HiringStage", {
        error,
      });
    }
  };

  /* HiringStageModal
  --===================================================-- */
  const openNewHiringStageModal = (event) => {
    event.preventDefault();
    openHiringStageModal({}, false);
  };

  const openEditHiringStageModal = (hiringStage) => {
    openHiringStageModal(hiringStage, true);
  };

  const openHiringStageModal = (hiringStage, isEditing) => {
    const modal = (
      <HiringStageModal
        onCancel={onCloseModal}
        jobId={job.id}
        isEditing={isEditing}
        hiringStage={hiringStage}
      />
    );

    openModal(modal);
  };

  const onCloseModal = () => {
    removeModal();
  };

  /* onDragEndHiringStage
  --===================================================-- */
  const onDragEndHiringStage = (result: { destination: any; source: any; draggableId: number }) => {
    window.logger("%c[AccountHiringStages] onDragEndHiringStage result", "color: #1976D2", result);
    // eslint-disable-next-line no-unused-vars
    const { destination, source, draggableId } = result;

    // If there is no destinatiom then do nothing
    if (!destination) return;

    // If the user drops the item back in its original spot then do nothing
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const hiringStage: { id: number; jobId: number } = inProcessStages[source.index];
    const position: number = destination.index + 1;

    const newHiringStages = moveFromIndexToIndex(inProcessStages, source.index, destination.index);

    setHiringStages([inboxStage, ...newHiringStages, hiredStage, archivedStage]);

    updateHiringStage({ ...hiringStage, position });
  };

  /* HiringStage nodes
  --===================================================-- */
  const hiringStageNodes = () => {
    const positions = inProcessStages.map((hiringStage) => hiringStage.position);
    window.logger(
      "%c[JobSetupHiringStages] hiringStageNodes RENDER",
      "color: #19FFD2",
      positions,
      //  {
      //   data,
      // }
    );
    window.logger("%c[JobSetupHiringStages] hiringStageNodes RENDER", "color: #850596", {
      data,
    });

    if (inProcessStages.length === 0) {
      return <EmptyState message="No Stages have been added" />;
    } else {
      return inProcessStages.map(
        (hiringStage: { name: string; position: number }, index: number) => {
          return (
            <HiringStageListItem
              key={`${index}_${hiringStage.name}_${hiringStage.position}`}
              index={index}
              hiringStage={hiringStage}
              openEditHiringStageModal={openEditHiringStageModal}
              deleteHiringStage={handleClickOnDeleteHiringStage}
            />
          );
        },
      );
    }
  };

  if (status === "loading") {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <Helmet title="Hiring stages" />
      <SettingsContainer
        title="Hiring stages"
        description="Here's where you can manage the hiring stages for this Job. You can also control the order of the stages by dragging and dropping."
      >
        <FormSection>
          {/* {errors ? (
            <ul>
              {errors["general"].map((error, index) => (
                <li key={index} style={{ color: "red" }}>
                  {error}
                </li>
              ))}
            </ul>
          ) : null} */}

          {inboxStage && (
            <Styled.FixedStage>
              <Styled.FixedStageText>{inboxStage.name}</Styled.FixedStageText>
              <Styled.FixedStageDetails>{`${Number(inboxStage.jobApplicationsCount) > 0 ? inboxStage.jobApplicationsCount : "No"
                } candidate${Number(inboxStage.jobApplicationsCount) === 1 ? "" : "s"
                }`}</Styled.FixedStageDetails>
            </Styled.FixedStage>
          )}

          <HiringStageList onDragEndHiringStage={onDragEndHiringStage}>
            {hiringStageNodes()}
          </HiringStageList>

          {hiredStage && (
            <Styled.FixedStage>
              <Styled.FixedStageText>{hiredStage.name}</Styled.FixedStageText>
              <Styled.FixedStageDetails>{`${Number(hiredStage.jobApplicationsCount) > 0
                  ? hiredStage.jobApplicationsCount
                  : "No"
                } candidate${Number(hiredStage.jobApplicationsCount) === 1 ? "" : "s"
                }`}</Styled.FixedStageDetails>
            </Styled.FixedStage>
          )}

          {archivedStage && (
            <Styled.FixedStage>
              <Styled.FixedStageText>{archivedStage.name}</Styled.FixedStageText>
              <Styled.FixedStageDetails>{`${Number(archivedStage.jobApplicationsCount) > 0
                  ? archivedStage.jobApplicationsCount
                  : "No"
                } candidate${Number(archivedStage.jobApplicationsCount) === 1 ? "" : "s"
                }`}</Styled.FixedStageDetails>
            </Styled.FixedStage>
          )}

          <Button styleType="secondary" onClick={openNewHiringStageModal}>
            Add additional stage
          </Button>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.FixedStage = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountHiringStages;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    &:first-of-type {
      ${t.mt(8)}
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
    &:last-of-type {
      ${t.mb(8)}
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  `;
});

Styled.FixedStageText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountHiringStages_FixedStageText;
    ${t.mb(1)}
    color: ${t.dark ? t.color.gray[200] : t.color.black}
  `;
});

Styled.FixedStageDetails = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: AccountHiringStages_FixedStageDetails;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]}
  `;
});

import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "@ats/styles/theme";
import lightTheme from "@shared/styles/lightTheme";
import darkTheme from "@shared/styles/darkTheme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import * as Sentry from "@sentry/react";

import AppContainer from "@ats/src/views/layouts/AppContainer";

import Logout from "@ats/src/views/sessions/Logout";
import NeedsEmailConfirmation from "@ats/src/views/sessions/NeedsEmailConfirmation";
import VerifyEmail from "@ats/src/views/sessions/VerifyEmail";
import NewOrganization from "@ats/src/views/sessions/NewOrganization";
import AccountDeactivated from "@ats/src/views/accountAdmin/AccountDeactivated";
import OrganizationSwitcher from "@ats/src/views/accountAdmin/OrganizationSwitcher";

// unauthed routes
import Login from "@ats/src/views/sessions/Login";
import Auth from "@ats/src/views/sessions/Auth";
import Signup from "@ats/src/views/sessions/Signup";
import PasswordReset from "@ats/src/views/sessions/PasswordReset";
import PasswordResetRequest from "@ats/src/views/sessions/PasswordResetRequest";

import ErrorBoundaryFallback from "@ats/src/components/shared/ErrorBoundaryFallback";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { startsWith } from "@ats/src/lib/utils/helpers";

import { CurrentSessionProvider } from "@ats/src/context/CurrentSessionContext";
import { ToastProvider } from "@shared/context/ToastContext";
import { ModalProvider } from "@shared/context/ModalContext";
import { WindowSizeProvider } from "@ats/src/context/WindowSizeContext";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

import { useOrganization } from "@shared/queryHooks/useOrganization";
import { useFeatureFlippers } from "@shared/queryHooks/useFeatureFlippers";
import { useOrganizationStore } from "@ats/src/lib/store/zustand/organizationStore";
import { useFeatureFlipperStore } from "@ats/src/lib/store/zustand/featureFlipperStore";
import { useGetMe, useAcceptInvite } from "@shared/queryHooks/useMe";
import { GlobalChannelContextProvider } from "@ats/src/context/GlobalChannelContext";
import OnboardingProfile from "../sessions/OnboardingProfile";

/*
If YES User on Login Redirect to Home
If YES User on normal route render Route
If NO User and on Home then redirect to LogIn
If NO User and on Login render Route
--===================================================-- */

const UNAUTHED_ROUTES = [
  "/auth",
  "/verify-email",
  "/login",
  "/register",
  "/password-reset",
  "/request-password-reset",
];

function AppAuthed(props) {
  const { location } = props;
  const search = new URLSearchParams(location?.search);
  const isAcceptingAnInvite = search?.has("invite_token");

  const { isDarkMode } = useColorSchemeContext();

  /* Fetch Session
  --===================================================-- */
  const isUnauthedRoute = UNAUTHED_ROUTES.includes(location.pathname);

  const { data: currentUser, isLoading: isLoadingUser } = useGetMe({
    enabled: !isUnauthedRoute, // || isAcceptingAnInvite,
    refetchOnWindowFocus: !isUnauthedRoute,
  });
  const organizationId = currentUser?.organizationId;
  const hasUser = currentUser != undefined && currentUser.id !== undefined;

  /* Accept Invite
  --===================================================-- */
  const { mutate: acceptInvite, isLoading: isLoadingAcceptInvite } = useAcceptInvite();

  /* Fetch Organization
  --===================================================-- */
  const { data: currentOrganization, isLoading: isLoadingOrganization } = useOrganization(
    organizationId,
  );

  /* Fetch Feature Flags
  --===================================================-- */
  const { data: featureFlagsData, isLoading: isLoadingFeatureFlippers } = useFeatureFlippers();

  window.logger("%c[AppAuthed] console RENDER", "background-color: #FFA500; color: #1976D2", {
    location,
    // currentUser,
    // organizationId,
    // featureFlagsData,
    // currentOrganization,
    // isLoadingOrganization,
    locationPathname: location.pathname,
    meEnabled: !isUnauthedRoute || isAcceptingAnInvite,
  });

  /* Save Organization to Store
  --===================================================-- */
  const setOrganization = useOrganizationStore(React.useCallback((state) => state.set, []));

  if (currentOrganization != undefined) {
    setOrganization((state) => {
      state.currentOrganization = currentOrganization;
      state.users = currentOrganization.users || [];
      state.users.forEach((user) => {
        state.usersById[user?.id] = user;
      });
    });
  }

  /* Save Feature Flags to Store
  --===================================================-- */
  const setFeatureFlippers = useFeatureFlipperStore(
    React.useCallback((state) => state.setFeatures, []),
  );

  if (featureFlagsData != undefined) {
    // window.logger("%c[AppAuthed] featureFlagsData", "background-color: #FFA500; color: #1976D2", {
    //   featureFlagsData,
    // });

    setFeatureFlippers(featureFlagsData.features);
  }

  /* Organization Helpers
  --===================================================-- */
  const stripeSubscriptionInGoodStanding = currentOrganization
    ? currentOrganization.stripeSubscriptionInGoodStanding
    : false;
  const organizationName = currentOrganization ? currentOrganization.name : null;
  // const hasOrganization = currentOrganization != undefined && currentOrganization.id !== undefined;

  /* HEAP ANALYTICS & PIONEER
  --===================================================-- */
  React.useEffect(() => {
    window.heap.identify(currentUser?.hashId);
    window.heap.addUserProperties({
      // OrganizationName: organization.name,
      OrganizationId: organizationId,
      OrganizationName: organizationName,
      Email: currentUser?.email,
      StripeSubscriptionInGoodStanding: stripeSubscriptionInGoodStanding,
    });

    // Pioneer Ticker
    // if (currentUser?.hashId) {
    //   window.pioneerAnalytics.identify(currentUser?.hashId);
    //   window.pioneerAnalytics.active();
    // }
  }, [currentUser, organizationId]);

  /* Google Analytics - on URL change
  --===================================================-- */
  React.useEffect(() => {
    if ((window as any).ga) {
      (window as any).ga("send", "pageview", props.location);
    }
  }, [props.location]);

  /* Track in Intercom
  --===================================================-- */
  if ((window as any).Intercom) {
    (window as any).Intercom("boot", {
      app_id: (window as any).intercomAppId,
      name: currentUser?.fullName, // Full name
      email: currentUser?.email, // Email address
      role: currentUser?.currentOrganizationUser?.role,
      company_name: organizationName,
      has_active_subscription: stripeSubscriptionInGoodStanding,
      created_at: currentUser?.createdAtTimestamp, // Signup date as a Unix timestamp
    });
  }

  /* Routing Helpers
  --===================================================-- */
  const defaultPath =
    currentUser?.currentOrganizationUser?.role === "org_interviewer" ? "/interviewer" : "/jobs";
  const redirectPath = search?.has("path") ? search?.get("path") : defaultPath;

  const needsHandleDeactivatedRoute = () => {
    if (currentUser == undefined) {
      return null;
    }

    if (
      currentUser?.currentOrganizationUser?.isActive === false &&
      location.pathname != "/organization/manage"
    ) {
      return <Redirect to="/organization/manage" />;
    }
  };

  // const needsMagicLinkConfirmation = () => {
  //   window.logger("%c[AppAuthRouter] needsMagicLinkConfirmation", "color: #1976D2", {
  //     currentUser,
  //     hasConfirmedEmail: currentUser?.hasConfirmedEmail,
  //     location,
  //   });

  //   // if (currentUser == undefined) {
  //   //   return null;
  //   // }

  //   if (currentUser?.hasConfirmedEmail) {
  //     if (location.pathname === "/verify-email") {
  //       return <Redirect to="/jobs" />;
  //     } else {
  //       return null;
  //     }
  //   }

  //   if (location.pathname !== "/verify-email") {
  //     // window.logger(
  //     //   "%c[AppAuthRouter] needsMagicLinkConfirmation REDIRECT to /verify-email ",
  //     //   "color: #1976D2",
  //     //   { currentUser, currentOrganization },
  //     // );
  //     return <Redirect to="/verify-email" />;
  //   } else {
  //     return (
  //       <Route
  //         path="/verify-email"
  //         render={(renderProps) => (
  //           // <NeedsEmailConfirmation {...renderProps} currentUser={currentUser} />
  //           <div>Need to verify the link we just sent you</div>
  //         )}
  //         // exact={true}
  //       />
  //     );
  //   }
  // };

  const needsEmailConfirmationRoute = () => {
    // window.logger("%c[AppAuthRouter] needsEmailConfirmationRoute", "color: #1976D2", {
    //   currentUser,
    //   hasConfirmedEmail: currentUser?.hasConfirmedEmail,
    //   location,
    // });

    if (currentUser == undefined) {
      return null;
    }

    if (currentUser?.hasConfirmedEmail) {
      if (location.pathname === "/needs-email-confirmation") {
        return <Redirect to="/jobs" />;
      } else {
        return null;
      }
    }

    if (location.pathname !== "/needs-email-confirmation") {
      // window.logger(
      //   "%c[AppAuthRouter] needsEmailConfirmationRoute REDIRECT to /needs-email-confirmation ",
      //   "color: #1976D2",
      //   { currentUser, currentOrganization },
      // );
      return <Redirect to="/needs-email-confirmation" />;
    } else {
      return (
        <Route
          path="/needs-email-confirmation"
          render={(renderProps) => (
            <NeedsEmailConfirmation {...renderProps} currentUser={currentUser} />
          )}
          // exact={true}
        />
      );
    }
  };

  const needsOnboardingProfileRoute = () => {
    // console.log("%c[AppAuthRouter] needsOnboardingProfileRoute\n\n\n\n\n\n\n\n", "color: #9a1569", {
    //   currentUser,
    // });
    if (currentUser == undefined || !currentUser?.email) {
      return null;
    }

    if (currentUser?.hasCompletedProfile) {
      if (location.pathname === "/onboarding/profile") {
        return <Redirect to={redirectPath} />;
      } else {
        return null;
      }
    }

    if (!currentUser?.hasCompletedProfile && location.pathname !== "/onboarding/profile") {
      console.log("%c[AppAuthRouter] TRY TO REDIRECT TO /onboarding/profile", "color: #9a1515", {
        ...currentUser,
      });
      const noredirectPaths = ["/", "/jobs", "/interviewer"];
      const queryParams = !noredirectPaths.includes(location.pathname)
        ? `?path=${location.pathname}`
        : "";
      return <Redirect to={`/onboarding/profile${queryParams}`} />;
    } else {
      return (
        <Route
          path="/onboarding/profile"
          render={(renderProps) => <OnboardingProfile {...renderProps} />}
          // exact={true}
        />
      );
    }
  };

  const needsNewOrganizationRoute = () => {
    if (currentUser == undefined) {
      return null;
    }

    if (currentUser?.hasConfirmedEmail) {
      if (organizationId == undefined && location.pathname !== "/organization/new") {
        return <Redirect to="/organization/new" />;
      }
      return null;
    }

    return null;
  };

  const needsGodAdminRoute = () => {
    if (currentUser == undefined) {
      return null;
    }

    if (
      startsWith(location.pathname, "/admin/") &&
      currentUser?.currentOrganizationUser?.role !== "god_admin"
    ) {
      return <Redirect to="/jobs" />;
    }

    return null;
  };

  const needsRedirectToJobsRoute = () => {
    if (currentUser == undefined) {
      return null;
    }

    if (!!currentUser?.email && UNAUTHED_ROUTES.includes(location.pathname)) {
      return <Redirect to="/jobs" />;
    }
    return null;
  };

  const handleRegisterRoute = (renderProps) => {
    // window.logger("%c[AppAuthed] console handleRegisterRoute", "color: #1976D2", {
    //   pathname: location.pathname,
    //   currentUser,
    // });
    return currentUser == undefined ? (
      <Signup {...props} {...renderProps} />
    ) : (
      needsHandleAcceptInvite()
    );
  };

  const needsHandleAcceptInvite = () => {
    // window.logger("%c[AppAuthed] needsHandleAcceptInvite", "color: #1976D2", {
    //   pathname: location.pathname,
    //   currentUser,
    // });

    if (isAcceptingAnInvite) {
      const inviteToken = search.get("invite_token");
      acceptInvite(
        { inviteToken },
        {
          onSuccess: () => {
            // history.push("/jobs");
          },
        },
      );
    }

    return <Redirect to="/jobs" />;
  };

  return (
    <ThemeProvider
      theme={{
        ...theme,
        dark: isDarkMode,
        poly: isDarkMode ? darkTheme : lightTheme,
      }}
    >
      <Styled.AppWrapper>
        <WindowSizeProvider>
          <CurrentSessionProvider
            currentUser={currentUser}
            currentOrganization={currentOrganization}
          >
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
              <GlobalChannelContextProvider>
                <ToastProvider>
                  <ModalProvider>
                    {isLoadingUser || isLoadingOrganization ? (
                      <LoadingIndicator label="Loading..." />
                    ) : (
                      <Switch>
                        {needsHandleDeactivatedRoute()}
                        {needsGodAdminRoute()}
                        {needsEmailConfirmationRoute()}
                        {needsOnboardingProfileRoute()}
                        {needsNewOrganizationRoute()}
                        {/* {needsHandleAcceptInvite()} */}
                        {needsRedirectToJobsRoute()}
                        <Route
                          path={`/login`}
                          render={(renderProps) => <Login {...props} {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path={`/auth`}
                          render={(renderProps) => <Auth {...props} {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path={`/register`}
                          render={(renderProps) => handleRegisterRoute(renderProps)}
                          exact={true}
                        />
                        <Route
                          path={`/password-reset`}
                          render={(renderProps) => <PasswordReset {...props} {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path={`/request-password-reset`}
                          render={(renderProps) => (
                            <PasswordResetRequest {...props} {...renderProps} />
                          )}
                          exact={true}
                        />
                        <Route
                          path="/verify-email"
                          render={(renderProps) => (
                            // <NeedsEmailConfirmation {...renderProps} currentUser={currentUser} />
                            <VerifyEmail location={props.location} />
                          )}
                          exact={true}
                        />
                        {location.pathname === "/" && hasUser ? (
                          <Redirect to={defaultPath} />
                        ) : null}
                        <Route
                          path={`/logout`}
                          render={(renderProps) => <Logout {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path="/organization/new"
                          render={(renderProps) => (
                            <NewOrganization
                              {...renderProps}
                              isLoadingOrganization={isLoadingOrganization}
                            />
                          )}
                          exact={true}
                        />
                        <Route
                          path="/organization/manage"
                          render={(renderProps) => <OrganizationSwitcher {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path={`/deactivated`}
                          render={(renderProps) => <AccountDeactivated {...renderProps} />}
                          exact={true}
                        />
                        <Route
                          path={`/`}
                          render={(renderProps) => {
                            const { match, ...restRenderProps } = renderProps;
                            return (
                              <AppContainer
                                {...restRenderProps}
                                matchUrl={match.url}
                                isLoadingOrganization={isLoadingOrganization}
                              />
                            );
                          }}
                          exact={false}
                        />
                      </Switch>
                    )}
                  </ModalProvider>
                </ToastProvider>
              </GlobalChannelContextProvider>
            </Sentry.ErrorBoundary>
          </CurrentSessionProvider>
        </WindowSizeProvider>
      </Styled.AppWrapper>
    </ThemeProvider>
  );
}

function AuthedLogoutDecision(props) {
  // window.logger("%c[AuthedLogoutDecision] props.location.pathname", "color: #d219c9", {
  //   pathname: props.location.pathname,
  // });
  if (props.location.pathname.includes("/logout")) {
    return <Logout {...props} />;
  } else {
    return <AppAuthed {...props} />;
  }
}

export default withRouter(AuthedLogoutDecision);

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.AppWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AppContainer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${t.dark ? t.color.gray[900] : t.color.white};
  `;
});

import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
// import queryString from "query-string";
import Icon from "@ats/src/components/shared/Icon";
import Box from "@shared/components/Box";
import Text from "@shared/components/Text";
import PathNavLink from "@ats/src/components/shared/PathNavLink";
import breakpoint from "@shared/styles/breakpoints";

type Props = {
  to: string;
  label: string;
  icon?: string;
  count?: number;
  chevron?: boolean;
  usePathMatching?: boolean;
};

function NavItem(props: Props) {
  const { usePathMatching } = props;

  const Wrapper = usePathMatching ? StyledPathNavLinkComponent : StyledNavLinkComponent;

  return (
    <Wrapper to={props.to} chevron={props.chevron}>
      <StyledLabel>
        {props.icon && <Icon name={props.icon} />}
        <Text>{props.label}</Text>
      </StyledLabel>
      {props.count && props.count}
      {props.chevron && <Icon name="chevron-right" />}
    </Wrapper>
  );
}

export default NavItem;

NavItem.defaultProps = { usePathMatching: false };

/* Styled Components
======================================================= */

const linkStyles = (props) => `
  height: 40px;
  border-radius: ${props.theme.poly.radii.sm};
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  padding-left: 0.625rem;
  padding-right: ${props.chevron ? "6px" : "10px"};
  margin-bottom: 2px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: background-color 0.2s ease;

  > svg {
    transition: opacity 0.2s ease;
    flex-shrink: 0;
    opacity: 0;
  }
  &.active {
    color: ${props.theme.poly.color.loudText};
    background: ${props.theme.poly.color.subtleHover};
    > svg {
      opacity: 1;
    }
  }

  ${breakpoint.sm} {
    height: 32px;
    &:hover {
      color: ${props.theme.poly.color.loudText};
      background: ${props.theme.poly.color.loudHover};
      > svg {
        stroke: ${props.theme.poly.color.secondaryText};
        opacity: 1;
      }
    }
  }
`;

const StyledPathNavLinkComponent = styled(PathNavLink, { shouldForwardProp: isPropValid })(linkStyles);

const StyledNavLinkComponent = styled(NavLink, { shouldForwardProp: isPropValid })(linkStyles);

const StyledLabel = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;

  ${Text} {
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

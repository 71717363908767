import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import CenterModal from "@ats/src/components/modals/CenterModal";
import { useGlobalChannelContext } from "@ats/src/context/GlobalChannelContext";
import Button from "@ats/src/components/shared/Button";

function MaintenanceCompleteModal(props) {
  const { disableMaintenanceComplete } = useGlobalChannelContext();

  const buttons = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={() => window.location.reload()}
      >
        Refresh now
      </Button>
      <Button styleType="secondary" onClick={disableMaintenanceComplete}>
        <span>Dismiss</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal
      headerTitleText="Refresh required"
      onCancel={() => { }}
      hasUnsavedChanges={false}
    >
      <Styled.Instructions>
        <p>
          We have just completed a major app update and a refresh is required. You will not be able to save any changes you have made until you refresh the page.
        </p>
        <p>
          If you have any unsaved content, we suggest dismissing this modal, copying your content, and then manually refreshing the app.
        </p>
      </Styled.Instructions>

      {buttons}
    </CenterModal>
  );
}

export default MaintenanceCompleteModal;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Instructions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: MaintenanceCompleteModal_Instructions;
    ${[t.mb(5)]}
    p {
      ${[t.mb(2)]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: MaintenanceCompleteModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

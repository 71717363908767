import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import CopyField from "@ats/src/components/shared/CopyField";
import Button from "@ats/src/components/shared/Button";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";

import { useToastContext } from "@shared/context/ToastContext";

export default function JobCustomShareUrlForm(props) {
  const { jobUrl, onCancel, onFormUpdate, isDirty } = props;
  window.logger("%c[JobShareForm] ", "background: #EFDDEF; color: #1976D2", { props });

  const addToast = useToastContext();
  const [source, setSource] = useState("");
  const [errors, setErrors] = useState([]);
  const [customShareUrl, setCustomShareUrl] = useState("");

  const createShareLink = () => {
    const customSource = encodeURIComponent(source);
    const sourceParam = customSource.length > 0 ? `?source=${customSource}` : "";
    const shareUrl = `${jobUrl}${sourceParam}`;
    setCustomShareUrl(shareUrl);
  };

  const handleChangeSource = (_, value) => {
    setSource(value);
    setErrors([]);
    if (onFormUpdate) {
      onFormUpdate();
    }
  };

  /* NOTE REGARDING CHANGING BACK TO USING THE "Create link" BUTTON

  The below useEffect is to keep the copy url field up to date with the input field IF we decided to remove the "Create link" button.

  To return to using the "Create link" button uncomment all the currently commented out code & remove or comment out the below useEffect
  */

  useEffect(() => {
    createShareLink();
  }, [source]);

  // const handleSaveUrlState = (event) => {
  //   event.preventDefault();
  //   createShareLink()
  // }

  const Buttons = (
    <Styled.ButtonContainer>
      {/* <Button
        type="primary"
        onClick={handleSaveUrlState}
        disabled={!isDirty}
      >
        Create link
      </Button> */}
      <Button styleType="secondary" onClick={onCancel}>
        Dismiss
      </Button>
    </Styled.ButtonContainer>
  );

  const CopyLink = (
    <>
      <FormLabel label="Job link" />
      <CopyField value={customShareUrl} label="Copy link" />
    </>
  );

  return (
    <FormContainer errors={errors} buttons={Buttons}>
      <FormLabel label="Source" />
      <FormInput
        name="source"
        onChange={handleChangeSource}
        value={source}
        errors={errors}
        autoFocus
      />
      {CopyLink}
    </FormContainer>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ShareUrl = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCustomShareUrlForm_ShareUrl;
    ${[t.mb(5), t.h(8), t.pl(2), t.rounded.sm]}
    border: 1px solid ${t.dark ? t.color.gray[600] : t.color.gray[300]};
    background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.gray[100]};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    > div {
      height: 100%;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;
});

Styled.CopyButton = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobCustomShareUrlForm_CopyButton;
    ${[t.w(8), t.ml(1)]}
    height: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &:hover {
      background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
      color: ${t.dark ? t.color.white : t.color.black};
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCustomShareUrlForm_ButtonContainer;
    ${t.mt(4)}
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

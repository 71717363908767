const theme = {
  radii: {
    xs: "4px",
    sm: "5px",
    md: "7px",
    lg: "13px",
  },
  shadow: "0px 8px 20px -4px rgba(0, 0, 0, 0.08)",
};

export default theme;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface Props {
  name: string;
  label: string;
  checked: boolean;
  onChange: (name, value) => void;
}

const FormCheckbox: React.FunctionComponent<Props> = ({ name, label, checked, onChange }) => {
  const handleClick = (event) => {
    // event.preventDefault(); // removed this to allow the checkbox to update correctly
    onChange(event.currentTarget.name, event.currentTarget.checked);
  };

  // return <button onChange={(event) => handleClick(event)}>{label}</button>;
  return (
    <Styled.Component>
      <input id={name} name={name} type="checkbox" checked={checked} onChange={handleClick} />
      {label && <label htmlFor={name}>{label}</label>}
    </Styled.Component>
  );
};

export default FormCheckbox;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.h(8)}
    display: flex;
    align-items: center;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      position: relative;
      padding-left: 1.625rem;
      padding-right: 0.5rem;
      cursor: pointer;
      transition: color 0.2s ease;
      height: 100%;
      display: flex;
      align-items: center;
    }

    [type="checkbox"]:checked + label {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    [type="checkbox"]:not(:checked) + label::before,
    [type="checkbox"]:checked + label::before {
      content: "";
      position: absolute;
      left: 0;
      width: 1.125rem;
      height: 1.125rem;
      border: 1px solid ${t.dark ? "transparent" : t.color.gray[300]};
      border-radius: 0.25rem;
      background: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
    }

    [type="checkbox"]:not(:checked) + label::after,
    [type="checkbox"]:checked + label::after {
      content: "";
      background-image: ${t.dark
        ? `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='%23E7E7E7'/%3E%3Cpath d='M20 6L9 17L4 12' stroke='black' stroke-width='2.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E")`
        : `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='black'/%3E%3Cpath d='M20 6L9 17L4 12' stroke='white' stroke-width='2.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E")`};
      border-radius: 0.25rem;
      overflow: hidden;
      position: absolute;
      left: 0;
      width: 1.125rem;
      height: 1.125rem;
    }

    [type="checkbox"]:not(:checked) + label::after {
      opacity: 0;
    }

    [type="checkbox"]:checked + label::after {
      opacity: 1;
    }

    [type="checkbox"]:disabled:not(:checked) + label::before,
    [type="checkbox"]:disabled:checked + label::before {
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }

    [type="checkbox"]:disabled:checked + label::after {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    [type="checkbox"]:disabled + label {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    [type="checkbox"]:checked:hover + label::before,
    [type="checkbox"]:not(:checked):hover + label::before {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
    }

    [type="checkbox"]:checked:hover + label,
    [type="checkbox"]:not(:checked):hover + label {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    [type="checkbox"]:checked:focus + label::before,
    [type="checkbox"]:not(:checked):focus + label::before {
      border-color: ${t.dark ? t.color.gray[500] : t.color.gray[400]};
      box-shadow: 0 0 0 2px ${t.dark ? "none" : t.color.gray[300]};
    }
  `;
});

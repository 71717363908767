import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import queryString from "query-string";

import {
  useInfiniteNotifications,
  useNotificationsSummaryCounts,
} from "@shared/queryHooks/useNotification";

import NavBar from "@shared/components/NavBar";
import NavItem from "@ats/src/components/shared/NavItem";
import NotificationList from "@ats/src/views/notifications/NotificationList";
import { useFeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";
import { useJobs, useAllJobs } from "@shared/queryHooks/useJob";

function NotificationsContainer(props: any) {
  window.logger("%c[NotificationsContainer] ", "background: #EFDDEF; color: #1976D2", props);
  const { history, location } = props;
  const { filter, job_id } = queryString.parse(location.search);
  const {
    data: jobsData,
    refetch: refetchJobs,
    isLoading: isLoadingJobs,
    isFetching: isFetchingJobs,
  } = useJobs({
    refetchOnWindowFocus: false,
  });

  const summaryCountsEnabled = useFeatureFlipper()({ feature: "NOTIFICATION_SUMMARY_COUNTS" });

  const [searchVariables, setSearchVariables] = React.useState({ filter: filter, job_id: job_id });
  const [returnPathname] = React.useState(
    history && history.location.state ? history.location.state.orginalPathname : "/jobs",
  );

  React.useEffect(() => {
    // window.logger("%c[NotificationsContainer] useEffect", "color: #19FFD2; background: red;", {
    //   returnPathname,
    //   location,
    //   history,
    // });
    let mounted = true;
    const { filter, job_id } = queryString.parse(location.search);
    if (mounted) {
      setSearchVariables({ filter, job_id });
    }

    return () => {
      mounted = false;
    };
  }, [location]);

  const {
    data,
    isFetching: isFetchingInfiniteNotifications,
    isSuccess: isSuccessInfiniteNotifications,
    isLoading: isLoadingInfiniteNotifications,
    // isFetchingMore,
    // canFetchMore,
    // fetchMore,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteNotifications(searchVariables);

  const {
    data: summaryCounts,
    isFetching: isFetchingNotificationsSummaryCounts,
    isSuccess: isSuccessNotificationsSummaryCounts,
    isLoading: isLoadingNotificationsSummaryCounts,
  } = useNotificationsSummaryCounts();

  /* Notification Counts
  --===================================================-- */
  const totalNotifications = summaryCounts?.total || 0;
  const countNewJobApplications = summaryCounts?.byKind?.newJobApplication || 0;
  const countNewChannelMessages =
    (summaryCounts?.byKind?.newInboundChannelMessage || 0) +
    (summaryCounts?.byKind?.newOutboundChannelMessage || 0);
  const countNewReviews = summaryCounts?.byKind?.newReview || 0;
  const countNewComments = summaryCounts?.byKind?.newComment || 0;
  const countNewActivity = countNewReviews + countNewComments;

  /* Helpers
  --===================================================-- */
  // const returnPathname =
  //   history && history.location.state ? history.location.state.orginalPathname : "/jobs";

  window.logger("%c[NotificationsContainer] render", "background-color: #19FFD2", {
    data,
    // summaryCounts,
    searchVariables,
  });

  return (
    <>
      <NavBar
        isBack={false}
        backUrl={""}
        closeUrl={returnPathname}
        history={history}
        title="Notification hub"
      />
      <Styled.Container>
        <Styled.Sidebar>
          <Styled.List>
            <NavItem
              to="/notifications"
              icon="bell"
              label="All notifications"
              usePathMatching
              chevron={!summaryCountsEnabled}
              count={summaryCountsEnabled ? totalNotifications || null : null}
            />
          </Styled.List>

          <Styled.Divider />
          <Styled.Label>Types</Styled.Label>
          <Styled.List>
            <NavItem
              to="/notifications?filter=new_candidates"
              icon="user"
              label="New candidates"
              chevron={!summaryCountsEnabled}
              usePathMatching
              count={summaryCountsEnabled ? countNewJobApplications || null : null}
            />

            <NavItem
              to="/notifications?filter=new_activity"
              icon="edit-3"
              label="New activity"
              chevron={!summaryCountsEnabled}
              usePathMatching
              count={summaryCountsEnabled ? countNewActivity || null : null}
            />

            <NavItem
              to="/notifications?filter=new_messages"
              icon="message-square"
              label="New messages"
              chevron={!summaryCountsEnabled}
              usePathMatching
              count={summaryCountsEnabled ? countNewChannelMessages || null : null}
            />
          </Styled.List>

          <Styled.Divider />
          {jobsData && jobsData.items.length > 0 && <Styled.Label>Jobs</Styled.Label>}
          <Styled.List>
            {(jobsData?.items || []).map((job) => (
              <NavItem
                key={job.id}
                to={`/notifications?job_id=${job.id}`}
                icon="sidebar"
                label={job.title}
                chevron={!summaryCountsEnabled}
                usePathMatching
                count={summaryCountsEnabled ? summaryCounts?.byJob[job.id] || null : null}
              />
            ))}
          </Styled.List>
        </Styled.Sidebar>
        <Styled.Content>
          <NotificationList
            history={history}
            data={data}
            searchVariables={searchVariables}
            fetchMoreNotifications={fetchNextPage}
            isFetchingInfiniteNotifications={isFetchingInfiniteNotifications}
            isLoadingInfiniteNotifications={isLoadingInfiniteNotifications}
            isFetchingMore={isFetchingNextPage}
            canFetchMore={hasNextPage}
          />
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

NotificationsContainer.propTypes = {};

NotificationsContainer.defaultProps = {};

export default NotificationsContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer;
    display: flex;
    height: 100%;
    overflow-y: hidden;
  `;
});

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer_Sidebar;
    ${[t.pb(10)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 25%;
    overflow-y: auto;
  `;
});

Styled.List = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer_List;
    margin-top: 0.375rem;
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: 0;
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer_Label;
    ${[t.px(4), t.text.xs, t.text.bold, t.mb(1)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NotificationsContainer_Content;
    width: 75%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import FormInput from "@ats/src/components/forms/FormInput";
import AdminOrganizationEditModal from "@ats/src/views/admin/AdminOrganizationEditModal";
import AdminRemoveBadActorModal from "@ats/src/views/admin/AdminRemoveBadActorModal";

import { useOrganizations, useSearchOrganizations } from "@shared/queryHooks/admin/useOrganization";
import { useBecomeUser } from "@shared/queryHooks/admin/useBecome";

import { getOptionLabel, adminOrganizationFlipperGroupOptions } from "@ats/src/lib/lookups";

import Button from "@ats/src/components/shared/Button";
import { useModalContext } from "@shared/context/ModalContext";

function AdminDashboardCustomers(props: any) {
  const { openModal, removeModal } = useModalContext();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const {
    data: customerOrganizations,
    isFetching: isFetchingCustomers,
    isSuccess: isSuccessCustomers,
  } = useOrganizations({
    filters: { is_claimed: true, order_by: "created_at_desc", customers: true },
  });
  const { mutate: becomeUser, isLoading: isLoadingBecome } = useBecomeUser();

  const {
    mutate: searchOrganizations,
    isSuccess: isSuccessSearchOrganizations,
  } = useSearchOrganizations();

  window.logger("%c[AdminDashboardCustomers] render", "color: #1976D2", {
    props,
    customerOrganizations,
    isFetchingCustomers,
  });

  const handleBecomeUser = (userId) => {
    window.logger("%c[AdminDashboardCustomers] handleBecomeUser userId", "color: #1976D2", userId);

    becomeUser(
      { userId },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[AdminDashboardCustomers] becomeUser onSuccess",
            "background-color: #c6d219",
            { data },
          );
          window.location.href = `${window.APP_ATS_ROOT_URL}/jobs`;
        },
        onError: (response: any) => {
          window.logger("[AdminDashboardCustomers] becomeUser server error", { response });
          if (response.data.errors) {
            // setState({ ...state, errors: response.data.errors });
          }
        },
      },
    );
  };

  const onChangeSearchOrganizations = (_, value) => {
    setSearchTerm(value);
    if (value.length > 2) {
      searchOrganizations(value, {
        onSuccess: (data) => {
          window.logger(
            "%c[AdminDashboardCustomers] onChangeSearchOrganizations",
            "color: #1976D2",
            { data },
          );
          setSearchResults(data.items);
        },
      });
    }
  };

  const handleOnClickEditOrganization = (organization) => {
    const modal = <AdminOrganizationEditModal organization={organization} onCancel={removeModal} />;

    openModal(modal);
  };

  const handleOnClickTakeover = (organization) => {
    const modal = (
      <AdminRemoveBadActorModal
        organizationId={organization.id}
        organizationName={organization.name}
        onCancel={removeModal}
      />
    );
    openModal(modal);
  };

  const organizationNodes = () => {
    if (!isSuccessCustomers || customerOrganizations == undefined) {
      return [];
    }

    const organizations =
      searchResults.length > 0 && searchTerm.length > 2
        ? searchResults
        : customerOrganizations.items;

    window.logger("%c[AdminDashboardCustomers] organizationNodes", "color: #1976D2", {
      organizations,
    });

    return organizations.map(function (organization) {
      let disableBecome = organization?.owner?.email === "system@inflowhq.com";

      return (
        <tr className="admin-organization-list__item" key={organization.id}>
          <td>{organization.id}</td>
          <td>{organization.name}</td>
          {/* <td>{organization.twitterHandle ? <Icon name="twitter" /> : ""}</td> */}
          <td>
            <Link to={`/admin/jobs?organization_id=${organization.id}`}>
              {organization.publishedJobsCount} Job(s)
            </Link>
          </td>
          <td>
            <div>{organization?.owner?.fullName}</div>
            {organization?.owner?.email}
          </td>
          <td>{getOptionLabel(adminOrganizationFlipperGroupOptions, organization.flipperGroup)}</td>
          <td>
            {organization.careersPageUrl != undefined ? (
              <a href={organization.careersPageUrl} target="_blank" rel="noopener noreferrer">
                {organization?.careersPageSlug}
              </a>
            ) : null}
          </td>
          <td
            style={{ color: `${organization.stripeSubscriptionInGoodStanding ? "green" : "red"}` }}
          >
            {organization.stripeSubscriptionInGoodStanding ? "Active" : "Inactive"}
          </td>
          <td>
            <Button
              styleType="text"
              type="button"
              onClick={() => handleBecomeUser(organization.owner.id)}
              disabled={disableBecome}
            >
              Become
            </Button>
          </td>
          <td>
            <Button
              styleType="text"
              type="button"
              onClick={() => handleOnClickEditOrganization(organization)}
              // disabled={disableBecome}
            >
              Edit
            </Button>
          </td>
          <td>
            <Button
              styleType="text"
              type="button"
              onClick={() => handleOnClickTakeover(organization)}
            >
              Takeover & Shutdown
            </Button>
          </td>
        </tr>
      );
    });
  };

  const orgCount = organizationNodes().length;

  return (
    <Styled.Component>
      <Styled.Header>
        <h2>
          {orgCount} Most Recent PAID Customers <span>{false && orgCount}</span>
        </h2>

        <FormInput
          name="search"
          placeholder="Search"
          value={searchTerm}
          onChange={onChangeSearchOrganizations}
        />
      </Styled.Header>

      <Styled.Table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Jobs</th>
            {/* <th>Users</th> */}
            <th>Owner</th>
            <th>Flipper Group</th>
            <th>Job Board</th>
            <th>Subscription</th>
            <th />
            <th />
            <th />
          </tr>
          {organizationNodes()}
        </tbody>
      </Styled.Table>
    </Styled.Component>
  );
}

AdminDashboardCustomers.propTypes = {};

AdminDashboardCustomers.defaultProps = {};

export default AdminDashboardCustomers;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminDashboardCustomers;
    ${t.p(4)};
  `;
});

Styled.Header = styled.header((props) => {
  const t: any = props.theme;
  return css`
    label: AdminDashboardCustomers_Header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      ${[t.mt(1), t.text.h3, t.mb(2)]};
      display: flex;
      align-items: center;
    }
    span {
      ${[t.ml(2), t.text.sm, t.text.secondary, t.text.normal]};
    }
  `;
});

Styled.Filters = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminDashboardCustomers_Filters;
    ${t.mb(6)};
    display: flex;

    button {
      ${t.mr(3)};
    }
  `;
});

Styled.Table = styled.table((props) => {
  const t: any = props.theme;
  return css`
    label: AdminDashboardCustomers_Table;
    border-collapse: collapse;
    width: 100%;

    th {
      ${[t.pb(2), t.text.xs, t.text.bold]};
      text-align: start;
      ${t.text.primary};
    }

    tr {
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.secondary};
    }

    tr:last-of-type {
      border-bottom: none;
    }

    svg {
      stroke: ${t.color.black};
    }

    td,
    th {
      ${[t.p(2)]};

      &:first-of-type {
        ${t.pl(0)}
      }

      &:last-of-type {
        ${t.pr(0)}
      }
    }
  `;
});

import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { jobBoardLogoVisibilityOptions } from "@ats/src/lib/lookups";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormSelect from "@ats/src/components/forms/FormSelect";
import Button from "@ats/src/components/shared/Button";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";
import FormInput from "@ats/src/components/forms/FormInput";

import JobBoardLogoUploader from "@ats/src/views/accountAdmin/components/JobBoardLogoUploader";
import JobBoardSocialShareImageUploader from "@ats/src/views/accountAdmin/components/JobBoardSocialShareImageUploader";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";

import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

function AccountJobBoardBranding(props: any) {
  const { currentOrganization } = props;
  const addToast = useToastContext();

  const {
    mutate: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const [careersPage, setCareersPage] = React.useState(currentOrganization?.careersPages[0]);

  const [errors, setErrors] = React.useState(null);

  window.logger("%c[AccountJobBoardBranding] ", "background-color: #FF76D2", {
    currentOrganization,
    careersPage,
  });

  /* Update careersPage if currentOrganization changes
  --===================================================-- */
  React.useEffect(() => {
    setCareersPage(currentOrganization?.careersPages[0]);
  }, [currentOrganization]);

  if (careersPage == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const {
    logoVisibility,
    billboardTitle,
    billboardIntro,
    billboardVideoUrl,
  } = careersPage;

  //logo_only is an invalid selection if no logo uploaded
  //revert to logo_and_name if logo_only selected & no logo has been uploaded, 
  //otherwise, use dropdown selection 
  const handleOnSubmit = (e) => {
    if (e) e.preventDefault();
    if (logoVisibility === "logo_visibility_logo_only" && !currentOrganization.hasLogo) {
      updateCareersPageState("logoVisibility", "logo_visibility_both_logo_and_name");
      handleSubmit(e, "logo_visibility_both_logo_and_name");
    } else {
      handleSubmit(e, logoVisibility)
    };
  };

  const onClickSubmit = (event) => {
    event.preventDefault();
    handleOnSubmit(event);
  };

  const handleSubmit = (e, validLogoVisiblity) => {
    if (e) e.preventDefault();

    updateCareersPage(
      {
        id: careersPage.id,
        logoVisibility: validLogoVisiblity,
        billboardTitle,
        billboardIntro,
        billboardVideoUrl,
      },
      {
        onSuccess: () => {
          props.setIsDirty(false);
          addToast({ title: "Job board branding settings saved", kind: "success" });
        },
        onError: (data) => {
          setErrors((data as any).errors);
          addToast({
            title: `Could not save settings`,
            kind: "danger",
          });
          // onError(data);
          window.logger(
            "%c[AccountJobBoardBranding] updateCareersPage onerror",
            "background-color: #eb7f37",
            { data },
          );
        },
      },
    );
  };

  /* Logo
  --===================================================-- */
  const handleLogoErrors = (errors) => {
    setErrors(errors);
  };

  /* Social Share Image
  --===================================================-- */
  const handleImageUploadErrors = (errors) => {
    window.logger("%c[AccountJobBoardBranding] handleImageUploadErrors", "color: #1976D2", {
      errors,
    });
    setErrors(errors);
  };

  /* updateCareersPageState
  --===================================================-- */
  const updateCareersPageState = (name, value) => {
    props.setIsDirty(true);

    window.logger(
      "%c[AccountJobBoardBranding] updateCareersPageState",
      "color: #1976D2",
      careersPage,
      name,
      value,
    );

    setCareersPage({ ...careersPage, [name]: value });
  };

  const bottomBarContent = (
    <Button className="submit-button" size="small" styleType="primary" onClick={onClickSubmit}>
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="Job board branding" />

      <SettingsContainer
        title="Job board branding"
        description="Here's where you can configure how your brand is represented on your job board and links shared on social media."
        // actions={viewLink()}
        bottomBar={bottomBarContent}
        fullWidthForm={true}
      >
        <form onSubmit={handleOnSubmit}>
          <FormSection>
            <FormSelect
              onChange={updateCareersPageState}
              name="logoVisibility"
              label="Header visuals"
              description="Choose what to display at the top of your job board. If no logo image is provided, your organization name will be displayed."
              value={logoVisibility}
              options={jobBoardLogoVisibilityOptions}
              errors={errors}
            />

            {logoVisibility !== "logo_visibility_name_only" && (
              <FormConditionalFields>
                <JobBoardLogoUploader errors={errors} onError={handleLogoErrors} />
              </FormConditionalFields>
            )}
          </FormSection>

          <FormSection>
            <JobBoardSocialShareImageUploader
              organizationId={currentOrganization.id}
              errors={errors}
              onError={handleImageUploadErrors}
            />
          </FormSection>
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountJobBoardBranding;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardBranding_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});

/* eslint-disable @getify/proper-arrows/return */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import Button from "@ats/src/components/shared/Button";
import FormUploader from "@ats/src/components/forms/FormUploader";
import DragAndDropResumeUploader from "@ats/src/components/DragAndDropResumeUploader";
import EmptyState from "@ats/src/components/shared/EmptyState";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateJobApplication } from "@shared/queryHooks/useJobApplication";

import { useJobApplicationStore } from "@ats/src/lib/store/zustand/jobApplicationStore";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import Resume from "@ats/src/views/jobApplications/Resume";

import { isDocx } from "@ats/src/lib/utils/fileUploads";

export default function JobApplicationResume(props) {
  const addToast = useToastContext();
  const { jobApplication, resumeUploading } = props;
  const [uploading, setUploading] = React.useState(false);
  // const [firstPageLoading, setFirstPageLoading] = React.useState(false);
  const [convertingDocxToPdf, setConvertingDocxToPdf] = useJobApplicationStore((state) => [
    state.convertingDocxToPdf,
    state.setConvertingDocxToPdf,
  ]);
  // const [count, increment] = useCountStore((state) => [state.counter, state.increment]);
  // const [convertingDocxToPdf, setConvertingDocxToPdf] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [numPages, setNumPages] = useState(1);
  // const [pageNumber, setPageNumber] = useState(1);
  const [errors, setErrors] = useState(null);
  const fileExists = fileToUpload !== null || props.jobApplication.hasResume;

  const {
    mutate: updateJobApplication,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateJobApplication();

  window.logger("%c[JobApplicationResume] render", "background-color: #19F0F2", {
    isLoadingUpdate,
    uploading,
  });

  const onChangeUploader = ({ fileToUpload }) => {
    setFileToUpload(fileToUpload);
  };

  /* Once file is uploaded to S3 go ahead and update the JobApplication with the file blob's signed_id
  --===================================================-- */
  const onCompleteDirectUpload = async (blob) => {
    const { signed_id: uploadedFile, content_type } = blob;

    const fileIsDocx = isDocx(content_type);
    setConvertingDocxToPdf(fileIsDocx);

    window.logger("%c[JobApplicationResume] onCompleteDirectUpload", "background-color: #F9FFD2", {
      uploadedFile,
      blob,
      convertingDocxToPdf,
      fileIsDocx,
    });

    if (uploadedFile) {
      setErrors(null);
      setNumPages(1);

      try {
        return await updateJobApplication(
          {
            id: jobApplication.id,
            resume: uploadedFile,
          },
          {
            onSuccess: () => {
              setUploading(false);
            },
            onError: () => {
              setUploading(false);
              addToast({ title: `Could not upload resume`, kind: "warning" });
            },
          },
        );
      } catch (error) {
        // Uh oh, something went wrong
        console.error(
          "%c[JobApplicationResume] handle error when updating JobApplication",
          "color: #FF7602",
          {
            error,
          },
        );
        // if (error.response && error.response.data) setErrors(error.response.data.errors);
        return Promise.resolve();
      }
    } else {
      addToast({ title: `Could not upload resume`, kind: "warning" });
      return Promise.resolve();
    }
  };

  const onStartDirectUpload = (loading) => {
    setUploading(loading);
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid resume file`, kind: "warning" });
  };

  const actionButton = () => {
    const buttonContent = props.jobApplication.hasResume ? "Replace resume" : "Upload resume";

    return (
      <DropdownMenu label="Resume options">
        <FormUploader
          name="resume"
          allowAutomaticUploading={true}
          onChange={onChangeUploader}
          onCompleteDirectUpload={onCompleteDirectUpload}
          onStartDirectUpload={onStartDirectUpload}
          onInvalidFile={onInvalidFile}
          fileIsAlreadyUploaded={fileExists}
          file={fileToUpload}
          placeholder={buttonContent}
          expectedFileType="document"
          errors={errors}
          displayType="dropdownLink"
          loading={resumeUploading}
          isRequired={false}
        />

        {props.jobApplication.hasResume && (
          <Button
            type="externalLink"
            size="small"
            link={jobApplication.resumeUrl}
            styleType="text"
            target="_blank"
          >
            Download PDF
          </Button>
        )}
        {props.jobApplication.hasResume && jobApplication.resumeContentType !== "application/pdf" && (
          <Button
            type="externalLink"
            size="small"
            link={jobApplication.resumeOriginalUrl}
            styleType="text"
            target="_blank"
          >
            Download DOCX
          </Button>
        )}
      </DropdownMenu>
    );
    // }
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Subtitle>Resume</Styled.Subtitle>
        <Styled.Actions>{actionButton()}</Styled.Actions>
      </Styled.Header>
      {/* uploading, convertingDocxToPdf, isFetchingJobApplication in that specific order should prevent any issues with attemtping to load a PDF that no longer exists
      : isFetchingJobApplication ? (
        <Styled.Processing>
          <LoadingIndicator label="Loading..." />
        </Styled.Processing>
      )
      --===================================================-- */}
      {uploading || isLoadingUpdate ? (
        <Styled.Processing>
          <LoadingIndicator label="Uploading..." />
        </Styled.Processing>
      ) : convertingDocxToPdf ? (
        <Styled.Processing>
          <LoadingIndicator label="Processing file..." />
        </Styled.Processing>
      ) : jobApplication.hasResume ? (
        // (
        //   jobApplication.shouldUseMicrosoftDocxViewer ? (
        //     microsoftViewer()
        //   ) : (
        //     pdfRenderer()
        //   )
        // )
        <Resume jobApplication={jobApplication} />
      ) : jobApplication.externalResumeStatus === "pending" ? (
        <Styled.Processing>
          <LoadingIndicator label="Processing resume from bulk import..." />
        </Styled.Processing>
      ) : (
        <>
          {jobApplication.externalResumeStatus === "error" && (
            <StyledExternalResumeUrlError>
              <p>
                The resume URL provided when this candidate was imported is invalid:&nbsp;
                <a href={jobApplication.externalResumeUrl} target="_blank" rel="noopener noreferrer">
                  {jobApplication.externalResumeUrl}
                </a>
              </p>
            </StyledExternalResumeUrlError>
          )}
          <DragAndDropResumeUploader
            onCompleteDirectUpload={onCompleteDirectUpload}
            onStartDirectUpload={onStartDirectUpload}
          />

        </>
      )}
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.p(4)]}
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(4)]}
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.Subtitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.text.h2}
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    button {
      ${t.mr(3)}
    }
  `;
});

// Styled.ResumeContainer = styled.div((props) => {
//   const t: any = props.theme;
//   return css`
//     > div {
//       ${t.rounded.md}
//       border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
//       background-color ${t.dark ? t.color.gray[800] : t.color.white};
//       overflow: hidden;
//       min-height: 6.625rem;

//       .page {
//         border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
//         canvas {
//           width: 100% !important;
//           height: auto !important;
//         }
//       }

//       .page:last-child {
//         border-bottom: none;
//       }
//     }
//   `;
// });

Styled.Loading = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};

    > div {
      ${t.m(0)}
    }
  `;
});

Styled.Processing = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.rounded.md}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 100%;
    min-height: 6.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};

    > div {
      ${t.m(0)}
    }
  `;
});

Styled.DocumentWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.rounded.md}
    flex: 1;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    overflow: hidden;
    > div {
      ${[t.mt("-px"), t.ml("-px")]}
      height: 100%;
      width: 100%;
    }
  `;
});

const StyledExternalResumeUrlError = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(4), t.px(4), t.rounded.md, t.text.xs]}
    min-height: ${t.spacing[12]};
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      ${t.text.medium}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      flex-shrink: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  `;
});

import React from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import JobCustomShareUrlForm from "@ats/src/views/jobApplications/jobSetup/components/JobCustomShareUrlForm";
import JobShareLinks from "@ats/src/views/jobApplications/jobSetup/components/JobShareLinks";

function ShareJobModal(props) {
  const { job, jobUrl, onCancel } = props;
  const [isDirty, setIsDirty] = React.useState(false);

  return (
    <CenterModal headerTitleText="Share this job" onCancel={onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Easily share this job post on social media using the buttons below.
      </Styled.Instructions>
      <JobShareLinks job={job} />
      <Styled.Instructions>
        Or generate a custom link to track applications that originate from it. The source will be displayed in the candidate's application.
      </Styled.Instructions>
      <JobCustomShareUrlForm onCancel={onCancel} jobUrl={jobUrl} onFormUpdate={() => setIsDirty(true)} isDirty={isDirty} />
    </CenterModal>
  );
}

export default ShareJobModal;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: NewJobCenterModal_Instructions;
    ${[t.mb(5)]}
  `;
});
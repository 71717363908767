import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";
import OrgLogo from "./OrgLogo";

export default function OrgSwitcherLogo({
  name,
  logoMedium,
  hasLogo,
  history,
}: {
  name: string;
  logoMedium: string;
  hasLogo: boolean;
  history: any;
}) {
  const navigateToOrganizationSwitcher = (event) => {
    event.preventDefault();
    history.push("/organization/manage");
  };

  return (
    <Styled.Container>
      <Tooltip label="Change current organization">
        <Styled.LogoWrapper onClick={navigateToOrganizationSwitcher}>
          <OrgLogo name={name} logoMedium={logoMedium} hasLogo={hasLogo} />
          <span>
            <Icon name="repeat" />
          </span>
        </Styled.LogoWrapper>
      </Tooltip>
      <Styled.Name>{name}</Styled.Name>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgSwitcherLogo;
    display: flex;
    align-items: center;
    max-width: 100%;
  `;
});

Styled.LogoWrapper = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgSwitcherLogo_LogoWrapper;
    ${[t.rounded.sm]}
    width: 36px;
    height: 36px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    > div {
      width: 36px;
      height: 36px;
      object-fit: cover;
    }

    span {
      ${t.text.sm}
      color: ${t.dark ? t.color.white : t.color.black};
      opacity: 0;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-color: ${t.dark ? t.color.gray[600] : t.color.gray[300]};
      transition: opacity 0.2s ease, box-shadow 0.2s ease;
    }

    &:hover {
      > div {
        opacity: 0;
      }
      span {
        opacity: 1;
      }
    }
  `;
});

Styled.ImageAvatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgSwitcherLogo_ImageAvatar;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    transition: opacity 0.2s ease;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
});

Styled.Avatar = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgSwitcherLogo_Avatar;
    ${[t.pt("px"), t.text.lg, t.text.bold]}
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${t.color.white};
    background-color: ${t.color.gray[600]};
    text-transform: uppercase;
    flex-shrink: 0;
    transition: opacity 0.2s ease;
  `;
});

Styled.Name = styled.div((props: any) => {
  const t = props.theme;
  return css`
    label: OrgSwitcherLogo_Name;
    ${[t.text.bold, t.text.lg, t.pt("px"), t.ml(3)]}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
});

import React from "react";
import { useInViewEffect } from "react-hook-inview";

function LoadMoreTrigger({ onEnterView }) {
  // const [state, setState] = useState("");

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        onEnterView();
      }
    },
    { threshold: 0.5 },
  );

  return (
    <div ref={ref} style={{ minHeight: "2px" }}>
      {/* <button onClick={() => onClick()}>Load More</button> */}
    </div>
  );
}

LoadMoreTrigger.propTypes = {};

LoadMoreTrigger.defaultProps = {};

export default LoadMoreTrigger;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import textStyles from "@ats/styles/text";
import Icon from "@ats/src/components/shared/Icon";

function SharedDocumentFeedItem({
  body,
  openSharedDocumentModal,
}: {
  body: string;
  openSharedDocumentModal?: any;
}) {
  return (
    <Styled.Component>
      <Styled.Intro>
        <div>
          <Styled.Icon>
            <Icon name="file-text" />
          </Styled.Icon>
          Hiring document shared with team
          {/* <Styled.Seperator>‧</Styled.Seperator>
          shared with hiring team */}
        </div>
        <button onClick={openSharedDocumentModal}>Edit</button>
      </Styled.Intro>

      <Styled.Content dangerouslySetInnerHTML={{ __html: body }} />
    </Styled.Component>
  );
}

export default SharedDocumentFeedItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentFeedItem_Component;
    ${[t.mb(4), t.px(4), t.pt(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.Intro = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentFeedItem_Intro;
    ${[t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    button {
      ${t.text.xs}
      justify-self: flex-end;
      background: none;
      border: none;
      outline: none;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  `;
});

Styled.Icon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Icon;
    ${[t.mr(2), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: CommentListItem_Seperator;
    ${t.mx(1)}
  `;
});

Styled.Content = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: CommentListItem_Content;
    ${textStyles}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    ${t.dark &&
    `
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}
  `;
});

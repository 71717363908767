import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import { HiringStageAutomation } from "@ats/types/job";

type Props = {
  hiringStageAutomation: HiringStageAutomation;
  openEditHiringStageAutomationModal: (hiringStageAutomation: HiringStageAutomation) => void;
  deleteHiringStageAutomation: (hiringStageAutomation: HiringStageAutomation) => void;
  handleToggleEnable: (hiringStageAutomation: HiringStageAutomation) => void;
};

export default function StageAutomationListItem(props: Props) {
  const {
    hiringStageAutomation,
    openEditHiringStageAutomationModal,
    deleteHiringStageAutomation,
    handleToggleEnable,
  } = props;
  const { channelMessageTemplateName, frequency, enabled, hiringStage } = hiringStageAutomation;

  const handleOnClickToggleEnable = (event) => {
    event.preventDefault();
    const updatedAutomation = {
      ...hiringStageAutomation,
      enabled: !hiringStageAutomation.enabled,
    };
    handleToggleEnable(updatedAutomation);
  };

  const handleOnClickEdit = (event) => {
    event.preventDefault();

    openEditHiringStageAutomationModal(hiringStageAutomation);
  };

  const handleOnClickDelete = async (event) => {
    event.preventDefault();

    deleteHiringStageAutomation(hiringStageAutomation);
  };

  return (
    <Styled.ListItemContainer>
      <div>
        <Styled.Info>
          <Styled.StageAutomationTitle>{hiringStage.name}</Styled.StageAutomationTitle>
          {enabled ? null : (
            <Styled.BrightLabel>
              <span>Disabled</span>
            </Styled.BrightLabel>
          )}
        </Styled.Info>
        <Styled.StageAutomationDetails>
          <p>Send a message using the </p>
          <span>{channelMessageTemplateName}</span>
          <p> template</p>
          <span>{frequency === "once" ? "the first time" : "every time"}</span>
          <p>this stage is entered.</p>
        </Styled.StageAutomationDetails>
      </div>
      <Styled.Actions>
        <DropdownMenu label="Options">
          <a href="" onClick={handleOnClickToggleEnable}>
            {enabled ? "Disable automation" : "Enable automation"}
          </a>
          <a href="" onClick={handleOnClickEdit}>
            Edit automation
          </a>
          <a href="" onClick={handleOnClickDelete}>
            Delete automation
          </a>
        </DropdownMenu>
      </Styled.Actions>
    </Styled.ListItemContainer>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ListItemContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: StageAutomationListItem_Container;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    transition: background-color 0.2s ease, border-color 0.2s ease;
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    &:hover,
    &:active {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: StageAutomationListItem_Info;
    display: flex;
    height: 1.5rem;
    ${t.mb(1)}
  `;
});

Styled.StageAutomationTitle = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: StageAutomationListItem_StageAutomationTitle;
    ${[t.pr(2)]}
    line-height: 1.25;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.StageAutomationDetails = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: StageAutomationListItem_Details;
    ${[t.text.xs, t.mb(4)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    max-width: 40rem;
    display: flex;
    flex-wrap: wrap;
    text-wrap: nowrap;

    span {
      ${[t.mt("-px"), t.mx(1), t.px(2), t.mb(1), t.rounded.xs]}
      padding-top: 0.1875rem;
      padding-bottom: 0.125rem;
      height: 1.375rem;
      align-items: center;
      text-wrap: nowrap;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    }
  `;
});

Styled.Actions = styled.div`
  label: StageAutomationListItem_Actions;
  display: flex;
`;

Styled.BrightLabel = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: StageAutomationListItem__BrightLabel;
    ${[t.ml("1px"), t.mb(1), t.pb(1), t.rounded.xs]}
    padding-top: 0.375rem;
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    margin-top: -0.125rem;
    font-size: 0.6875rem;
    label: JobListItem_JobDetailNode;
    display: flex;
    align-items: center;
    border: 1px solid ${t.color.orange[200]};
    background: linear-gradient(120deg, #ffd8d8 30%, #ffdec1 80%);
    color: ${t.color.black};
    height: 1.25rem;
  `;
});

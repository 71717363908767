import React, { useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FullModal from "@ats/src/components/modals/FullModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";
import textStyles from "@ats/styles/text";

import { useCommentTemplates, useCommentTemplate } from "@shared/queryHooks/useCommentTemplate";
import EmptyState from "../shared/EmptyState";

type Props = {
  onCancel: () => void;
  handleInsertTemplate: (commentTemplate) => void;
};

function PreviewSelection(props) {
  const { commentTemplatePreview, commentTemplatePreviewIsLoading } = props;

  window.logger("%c[CMTSM] PreviewSelection", "color: #F900D2", {
    commentTemplatePreview,
    // jobApplicationId,
    // commentTemplateId,
  });

  if (commentTemplatePreviewIsLoading) {
    return <>Loading...</>;
  }

  return (
    <Styled.PreviewContainer>
      <div dangerouslySetInnerHTML={{ __html: commentTemplatePreview.body }} />
    </Styled.PreviewContainer>
  );
}

function CommentTemplateSelectionModal(props: Props) {
  const { data: commentTemplates } = useCommentTemplates();

  const [commentTemplate, setCommentTemplate] = useState<{
    id?: number | undefined;
    name?: string;
  }>({ id: undefined }); // { id?: number;}
  const [isDirty, setIsDirty] = useState(false);

  const {
    data: commentTemplatePreview,
    isLoading: commentTemplatePreviewIsLoading,
  } = useCommentTemplate({
    commentTemplateId: commentTemplate?.id,
  });

  const commentTemplateSelectOptions = commentTemplates
    ? commentTemplates.items.map((template) => {
      return { value: template.id, label: template.name };
    })
    : [];

  window.logger("%c[CTSM] render", "color: #FF0EF3", {
    commentTemplatePreview,
    commentTemplateId: commentTemplate?.id,
    commentTemplateSelectOptions,
    commentTemplates,
  });

  const handleInsertTemplate = (event) => {
    event.preventDefault();

    window.logger("%c[CTSM] handleInsertTemplate", "color: #FF0EF3", {
      commentTemplatePreview,
      commentTemplateId: commentTemplate?.id,
      commentTemplateSelectOptions,
      commentTemplates,
    });

    if (commentTemplatePreview != undefined) {
      props.handleInsertTemplate(commentTemplatePreview.body);
      props.onCancel();
    }
  };

  const handleChangeSelectCommentTemplate = (name, value) => {
    if (commentTemplates == undefined) {
      return;
    }

    const commentTemplateId = value;

    const template = commentTemplates.items.find(
      (commentTemplate) => commentTemplate.id === commentTemplateId,
    );

    setCommentTemplate(template);
    setIsDirty(true);

    window.logger(
      "%c[CommentTemplateSelectionModal] handleChangeSelectCommentTemplate",
      "color: #F976D2",
      {
        name,
        value,
        template,
        commentTemplateSelectOptions,
      },
    );
  };

  return (
    <FullModal onCancel={props.onCancel} hasUnsavedChanges={isDirty}>
      <Styled.ModalContent>
        <Styled.FormHeader>
          <h2>Insert review template</h2>
        </Styled.FormHeader>
        <Styled.Container>
          <FormSelect
            onChange={handleChangeSelectCommentTemplate}
            name="commentTemplate"
            label="Template"
            // description="Choose a template."
            className=""
            value={commentTemplate?.id}
            options={commentTemplateSelectOptions}
            // errors={errors}
            isClearable
          />

          {commentTemplate?.id != undefined ? (
            <PreviewSelection
              commentTemplatePreview={commentTemplatePreview}
              commentTemplatePreviewIsLoading={commentTemplatePreviewIsLoading}
            />
          ) : (
            <EmptyState
              icon="file-plus"
              title="Select a review template to insert"
              message="The content from the selected template will be added to your review and available for additional editing"
            />
          )}
        </Styled.Container>
        <Styled.ButtonContainer>
          <Button
            name="insertTemplateButton"
            type="submit"
            onClick={handleInsertTemplate}
            disabled={commentTemplatePreviewIsLoading}
          >
            Select template
          </Button>
          <Button styleType="secondary" onClick={props.onCancel}>
            <span>Cancel</span>
          </Button>
        </Styled.ButtonContainer>
      </Styled.ModalContent>
    </FullModal>
  );
}

export default CommentTemplateSelectionModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ModalContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateSelectionModal_ModalContent;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.FormHeader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateSelectionModal_FormHeader;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.Container = styled(FormContainer)((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateSelectionModal_Container;
    ${[t.px(4), t.py(5)]}
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateSelectionModal_ButtonContainer;
    ${t.p(4)}
    position: sticky;
    bottom: 0;
    display: flex;
    border-bottom-left-radius: 0.4375rem;
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.PreviewContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateSelectionModal_PreviewContainer;
    ${[textStyles, t.p(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    flex-grow: 1;
    overflow-y: auto;
    ${t.dark &&
    `
      color: ${t.color.gray[200]};
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}
  `;
});

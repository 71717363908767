import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormTextarea from "@ats/src/components/forms/FormTextarea";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";
import JobBoardSocialShareImageUploader from "@ats/src/views/accountAdmin/components/JobBoardSocialShareImageUploader";


function AccountJobBoardSeo(props: any) {
  const { currentOrganization } = props;
  const addToast = useToastContext();

  const {
    mutate: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const [careersPage, setCareersPage] = React.useState(currentOrganization?.careersPages[0]);

  const [errors, setErrors] = React.useState(null);

  window.logger("%c[AccountJobBoardSeo] ", "background-color: #FF76D2", {
    currentOrganization,
    careersPage,
  });

  /* Update careersPage if currentOrganization changes
  --===================================================-- */
  React.useEffect(() => {
    setCareersPage(currentOrganization?.careersPages[0]);
  }, [currentOrganization]);

  if (careersPage == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const {
    pageTitle,
    pageDescription,
    socialShareTitle,
    socialShareDescription,
  } = careersPage;

  const onClickSubmit = (event) => {
    event.preventDefault();
    handleSubmit(event);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    updateCareersPage(
      {
        id: careersPage.id,
        pageTitle,
        pageDescription,
        socialShareTitle,
        socialShareDescription,
      },
      {
        onSuccess: () => {
          props.setIsDirty(false);
          addToast({ title: "Job board seo settings saved", kind: "success" });
        },
        onError: (data) => {
          setErrors((data as any).errors);
          addToast({
            title: `Could not save settings`,
            kind: "danger",
          });
          // onError(data);
          window.logger(
            "%c[AccountJobBoardSeo] updateCareersPage onerror",
            "background-color: #eb7f37",
            { data },
          );
        },
      },
    );
  };


  /* updateCareersPageState
  --===================================================-- */
  const updateCareersPageState = (name, value) => {
    props.setIsDirty(true);

    window.logger(
      "%c[AccountJobBoardSeo] updateCareersPageState",
      "color: #1976D2",
      careersPage,
      name,
      value,
    );

    setCareersPage({ ...careersPage, [name]: value });
  };

  /* Social Share Image
--===================================================-- */
  const handleImageUploadErrors = (errors) => {
    window.logger("%c[AccountJobBoardBranding] handleImageUploadErrors", "color: #1976D2", {
      errors,
    });
    setErrors(errors);
  };

  const bottomBarContent = (
    <Button className="submit-button" size="small" styleType="primary" onClick={onClickSubmit}>
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="Job board SEO" />

      <SettingsContainer
        title="Job board SEO"
        description="Here's where you can configure how your job board appears in search results and when linked on social media."
        // actions={viewLink()}
        bottomBar={bottomBarContent}
        fullWidthForm={true}
      >
        <form onSubmit={handleSubmit}>
          <FormSection>
            <FormInput
              name="pageTitle"
              onChange={updateCareersPageState}
              placeholder=""
              label="Title"
              description={`If left blank we will show "Jobs at ${currentOrganization.name}" as the title of the main careers page.`}
              className=""
              value={pageTitle || ""}
              errors={errors}
            />

            <FormTextarea
              name="pageDescription"
              onChange={updateCareersPageState}
              placeholder=""
              label="Description"
              description={`If left blank we will show "View our open jobs at ${currentOrganization.name}." as the description of the main careers page.`}
              className=""
              value={pageDescription || ""}
              errors={errors}
            />
          </FormSection>

          {/* <FormSection title="Social Media" intro="The title, description, and image that will be displayed when your job board is shared on sites such as  LinkedIn, Twitter, and Facebook.">
            <FormInput
              name="socialShareTitle"
              onChange={updateCareersPageState}
              placeholder=""
              label="Social media title"
              description={`If left blank we will show the Page title value.`}
              className=""
              value={socialShareTitle || ""}
              errors={errors}
            />

            <FormTextarea
              name="socialShareDescription"
              onChange={updateCareersPageState}
              placeholder=""
              label="Social media description"
              description={`If left blank we will show the Page description value.`}
              className=""
              value={socialShareDescription || ""}
              errors={errors}
            />

            <JobBoardSocialShareImageUploader
              organizationId={currentOrganization.id}
              errors={errors}
              onError={handleImageUploadErrors}
            />
          </FormSection> */}
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountJobBoardSeo;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardSeo_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});

import upperFirst from "lodash/upperFirst";
import lowerCase from "lodash/lowerCase";
import { object, string, array, number } from "yup";

// setLocale({
//   mixed: {
//     default: 'Não é válido',
//   },
//   number: {
//     min: 'Deve ser maior que ${min}',
//   },
// });

const NOT_EMPTY = "is not allowed to be empty";

const companySchema = object({
  name: string().required(),
  websiteUrl: string().required(),
  remoteness: string().required(),
});

const companyLabels = {
  name: "Organization name",
  websiteUrl: "Organization website",
  remoteness: "How remote",
};

const jobSchema = object({
  title: string().required(NOT_EMPTY),
  description: string().ensure().required(),
});

const jobLabels = {
  title: "Job title",
  description: "Job description",
};

const userSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
  password: string().required(),
});

const channelMessageTemplateSchema = object({
  name: string().required(),
  body: string().required(),
});

const bulkMessageSchema = object({
  body: string().required(),
  jobApplications: array().min(1),
});

const hiringStageSchema = object({
  hiringStageName: string().required(NOT_EMPTY),
});

// const userLabels = {
//   title: "Job Title",
//   description: "Job Description",
// };

export async function validateSchema(fields: {}, schema: { validate: any }, labels: {} = {}) {
  return await schema
    .validate(fields, { abortEarly: false })
    .then((validation) => {
      // console.log("%c[YUP] validateSchema validation", "color: #FF34FF", validation);
      return [true, validation];
    })
    .catch(function (err) {
      let errors = {};
      const isValid = err === null;

      console.log("%c[YUP] validateSchema err", "color: #FF3434", err);

      if (!isValid) {
        // Match the Rails format for errors
        err.inner.forEach((error) => {
          const fieldError = error.message.replace(
            new RegExp(`${error.params.label || error.path} `, "gi"),
            "",
          );

          errors[error.path] = {
            label: error.params.label || error.path,
            fieldError,
            groupError: `${labels[error.path] || upperFirst(lowerCase(error.path))} ${fieldError}`,
          };
        });
      }

      return [isValid, errors];
    });
}

export async function validator(
  fields: {},
  schemaAndLabels: { schema: { validate: any }; labels: {} },
) {
  return await validateSchema(fields, schemaAndLabels.schema, schemaAndLabels.labels);
}

/* VALIDATORS
 *********************/

export async function validateRemoteCompany(fields: {}) {
  // console.log("%c[YUP] validateRemoteCompany fields", "color: #1976D2", fields);
  return await validateSchema(fields, companySchema, companyLabels);
}

export async function validateRemoteJob(fields: {}) {
  // console.log("%c[YUP] validateRemoteJob fields", "color: #1976D2", fields);
  return await validateSchema(fields, jobSchema, jobLabels);
}

export async function validateNewJob(fields: {}) {
  return await validateSchema(
    fields,
    object({
      title: string().required(NOT_EMPTY),
    }),
    { title: "Job title" },
  );
}

export async function validateRemoteUser(fields: {}) {
  // console.log("%c[YUP] validateRemoteJob fields", "color: #1976D2", fields);
  return await validateSchema(fields, userSchema);
}

export async function validateChannelMessageTemplate(fields: {}) {
  // console.log("%c[YUP] validateRemoteJob fields", "color: #1976D2", fields);
  return await validateSchema(fields, channelMessageTemplateSchema);
}

export async function validateCommentTemplate(fields: {}) {
  // console.log("%c[YUP] validateRemoteJob fields", "color: #1976D2", fields);
  return await validator(fields, {
    schema: object({
      name: string().required(),
      body: string().required(),
    }),
    labels: {
      name: "Name",
      body: "Body",
    },
  });
}

export async function validateBulkMessage(fields: {}) {
  // console.log("%c[YUP] validateBulkMessage fields", "color: #1976D2", fields);
  return await validateSchema(fields, bulkMessageSchema);
}

export async function validateHiringStage(fields: {}) {
  return await validateSchema(fields, hiringStageSchema);
}

export async function validateJobCategory(fields: {}) {
  return await validateSchema(
    fields,
    object({
      categoryName: string().required(NOT_EMPTY),
    }),
  );
}

export async function validateWwrCategory(fields: {}) {
  return await validateSchema(
    fields,
    object({
      wwrCategory: string().required(NOT_EMPTY),
    }),
  );
}

export async function validateAccountJobBoardNavigation(fields: { slug: string }) {
  return await validator(fields, {
    schema: object({
      slug: string().required(NOT_EMPTY),
    }),
    labels: {
      slug: "Organization url",
    },
  });
}

export async function validateLogin(fields: { email: string; password: string }) {
  return await validator(fields, {
    schema: object({
      email: string().email().required(),
      password: string().required(),
    }),
    labels: {
      email: "Email",
      password: "Password",
    },
  });
}

export async function validateRegistration(fields: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) {
  return await validator(fields, {
    schema: object({
      firstName: string().required(),
      lastName: string().required(),
      email: string().email().required(),
      password: string().min(8).required(),
    }),
    labels: {
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      password: "Password",
    },
  });
}

export async function validateNewOrganization(fields: { name: string }) {
  return await validator(fields, {
    schema: object({
      name: string().min(2).required(),
    }),
    labels: {
      name: "Organization name",
    },
  });
}

export async function validateEmail(fields: { email: string }) {
  return await validator(fields, {
    schema: object({
      email: string().email().required(),
    }),
    labels: {
      email: "Email",
    },
  });
}

export async function validateCreatedEmailSelection(fields: { selection: string }) {
  return await validator(fields, {
    schema: object({
      selection: string().email().required(),
    }),
    labels: {
      selection: "Email",
    },
  });
}

export async function validateInviteEmail(fields: { email: string }, callback) {
  return await validator(fields, {
    schema: object({
      email: string()
        .email()
        .test("test-name", "has already been invited", function (email) {
          //callback function checks if email is viable and returns false if not viable
          return callback(email);
        })
        .required(),
    }),
    labels: {
      email: "Email",
    },
  });
}

export async function validatePassword(fields: { password: string; passwordConfirmation }) {
  return await validator(fields, {
    schema: object({
      password: string().min(8).required(),
      passwordConfirmation: string().min(8).required(),
    }),
    labels: {
      password: "Password",
      passwordConfirmation: "Password confirmation",
    },
  });
}

export async function validateComment(fields: { body: string }) {
  return await validator(fields, {
    schema: object({
      body: string().min(2).required(),
    }),
    labels: {
      body: "Comment",
    },
  });
}

export async function validateNewFeatureFlag(fields: { name: string }) {
  return await validator(fields, {
    schema: object({
      name: string().min(2).required(),
    }),
    labels: {
      title: "Feature flag name",
    },
  });
}

export async function validateSupportMessage(fields: { subject: string; body: string }) {
  return await validator(fields, {
    schema: object({
      subject: string().min(2).required(),
      body: string().min(8).required(),
    }),
    labels: {
      subject: "Subject",
      body: "Message",
    },
  });
}

export async function validateOnboardingProfile(fields: { firstName: string; lastName: string }) {
  return await validator(fields, {
    schema: object({
      firstName: string().required(),
      lastName: string().required(),
    }),
    labels: {
      firstName: "First name",
      lastName: "Last name",
    },
  });
}

export async function validateSalaryAmounts(fields: {
  salaryMin: number;
  salaryMax: number;
  salaryType: string;
}) {
  // Use typeError to catch null which may mean empty OR content with letters, commas, or currency symbols
  // because the form field is of input type number any of the above will be passed as null
  return await validator(fields, {
    schema: object({
      salaryMin: number().when("salaryType", {
        is: (value) => ["Range", "Fixed"].includes(value),
        then: number()
          .typeError("Amount is required and must be a number with no non-numeric symbols.")
          .positive("Amount must be a positive number"),
        otherwise: number().nullable(),
      }),
      salaryMax: number().when("salaryType", {
        is: "Range",
        then: number()
          .typeError("Amount is required and must be a number with no non-numeric symbols.")
          .positive("Amount must be a positive number"),
        otherwise: number().nullable(),
      }),
    }),
    labels: {
      salaryMin: "Minimum",
      salaryMax: "Maximum",
    },
  });
}

export async function validateTakeoverOrganization(
  fields: { organizationName: string },
  expectedName: string,
) {
  return await validator(fields, {
    schema: object({
      organizationName: string()
        .required(NOT_EMPTY)
        .oneOf([expectedName], `Organization name must match ${expectedName}`),
    }),
    labels: {
      organizationName: "Organization name",
    },
  });
}
import React from "react";
// import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function ShortcutKey(props: { children: React.ReactNode | {} }) {
  return <Styled.Container className="key"> {props.children}</Styled.Container>;
}

/* Styled Components
======================================================= */

let Styled: any;
Styled = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: ShortcutKey;
    ${[t.text.xs, t.px(1), t.py("px"), t.rounded.xs]};
    display: inline-block;
    background-color: ${t.color.gray[500]};
    color: ${t.color.white};
    &:first-of-type {
      ${t.ml(2)};
    }
  `;
});

import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";

// import Button from "@ats/src/components/shared/Button";
import OrganizationForm from "@ats/src/views/sessions/components/OrganizationForm";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

// import { useChooseOrganizationUser } from "@shared/queryHooks/useMe";

function NewOrganization(props) {
  const queryClient = useQueryClient();
  const { currentOrganization, currentUser } = useCurrentSession();
  // const {
  //   mutate: chooseOrganizationUser,
  //   isLoading: isLoadingChooseOrganizationUser,
  // } = useChooseOrganizationUser();

  const onComplete = (organizationUser) => {
    window.logger("%c[NewOrganization] onComplete organizationUser", "color: #1976D2", {
      organizationUser,
      history: props.history,
    });

    window.dataLayer.push({
      event: "newOrganization",
      transactionId: Date.now().toString(),
      transactionValue: "5",
      userData: {
        email: currentUser.email,
      },
    });

    queryClient.clear();

    props.history.push("/jobs");
  };

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        {props.isLoadingOrganization ? (
          <LoadingIndicator label="Logging in to organization..." />
        ) : (
          <>
            <Styled.Form>
              <Helmet title="New Organization" />
              <h2>Create new organization</h2>
              <div>
                <OrganizationForm
                  onComplete={onComplete}
                  currentOrganization={currentOrganization}
                />
              </div>
            </Styled.Form>
            <Styled.Links>
              <Link to="/logout">Log out</Link>
            </Styled.Links>
          </>
        )}
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(NewOrganization);

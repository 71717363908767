import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import AdminDashboardCustomers from "@ats/src/views/admin/AdminDashboardCustomers";
import AdminDashboardStatistics from "@ats/src/views/admin/AdminDashboardStatistics";

function AdminDashboardContainer(props) {
  window.logger("%c[AdminDashboardContainer] render", "color: #1976D2", {
    props,
  });

  return (
    <>
      <Helmet title="Admin Dashboard" />
      <AdminDashboardStatistics />
      <AdminDashboardCustomers />
    </>
  );
}

AdminDashboardContainer.propTypes = {};

AdminDashboardContainer.defaultProps = {};

export default AdminDashboardContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationList;
    ${t.p(4)};
  `;
});

import React from "react";
import { Route, Redirect, NavLink, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import NavItem from "@ats/src/components/shared/NavItem";
import JobDistributionWeWorkRemotely from "@ats/src/views/jobApplications/jobDistribution/JobDistributionWeWorkRemotely";

function JobDistributionContainer(props) {
  const { match, job } = props;

  return (
    <>
      <Helmet title="Distribution" />
      <Styled.Container>
        <Styled.Sidebar>
          <NavItem to={`${match.url}/weworkremotely`} label="We Work Remotely" chevron />
        </Styled.Sidebar>
        <Styled.Content>
          <Switch>
            <Route
              path={`${match.path}/weworkremotely`}
              render={(renderProps) => (
                <JobDistributionWeWorkRemotely {...props} {...renderProps} />
              )}
            />
            <Redirect to={`${match.path}/weworkremotely`} />
          </Switch>
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default JobDistributionContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: JobSetupContainer;
  display: flex;
  height: 100%;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 21.5909091%;
    padding-top: 0.375rem;
  `;
});

Styled.Content = styled.div`
  label: JobDistributionContainer_Content;
  width: 78.4090909%;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

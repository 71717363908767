import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isEmpty from "lodash/isEmpty";

import FullModal from "@ats/src/components/modals/FullModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useModalContext } from "@shared/context/ModalContext";

import { useUpdateJobApplication } from "@shared/queryHooks/useJobApplication";

type Props = {
  onCancel: () => void;
  jobApplication: { id: number; sharedDocument?: string };
  onSuccess: () => void;
};

function SharedDocumentModal(props: Props) {
  const { jobApplication, onCancel, onSuccess } = props;
  const { dismissModalWithAnimation } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const isEditing = !isEmpty(jobApplication?.sharedDocument);

  const {
    mutate: updateJobApplication,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateJobApplication();

  const editorRef = React.useRef(undefined);

  const [errors, setErrors] = useState([]);

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[SharedDocumentModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  // });

  const handleCancel = () => {
    dismissModalWithAnimation(() => onCancel);
  };

  // const autoSave = () => {
  //   // window.logger("%c[SharedDocumentModal] autoSave", "border: 1px solid #906feb;", {
  //   //   state: editorRef.current?.serializedState(),
  //   // });

  //   if (isDirty && editorRef.current != undefined) {
  //     updateJobApplication(
  //       {
  //         id: jobApplication?.id,
  //         sharedDocument: editorRef.current.serializedState(),
  //       },
  //       {
  //         onSuccess: (data) => {
  //           // window.logger(
  //           //   "%c[SharedDocumentModal] autoSave onSuccess",
  //           //   "border: 1px solid #318011;",
  //           //   {
  //           //     data,
  //           //   },
  //           // );
  //           setIsDirty(false);
  //         },
  //         onError: (response) => {},
  //       },
  //     );
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateJobApplication(
        {
          id: jobApplication.id,
          sharedDocument: editorRef.current.serializedState(),
        },
        {
          onSuccess: () => {
            dismissModalWithAnimation(() => onCancel);
            onSuccess();
          },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[SharedDocumentModal] handle error when updating Job SharedDocument",
        "color: #FF7602",
        {
          error,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  return (
    <FullModal onCancel={handleCancel} hasUnsavedChanges={isDirty}>
      <Styled.ModalContent>
        <Styled.FormHeader>
          <h2>{isEditing ? "Edit hiring document" : "Add hiring document"}</h2>
        </Styled.FormHeader>

        <Styled.Container errors={errors} fullWidthForm modal onSubmit={handleSubmit}>
          <ProseMirrorEditor
            ref={editorRef}
            editorId="shared-document-editor"
            enableLabel
            enableStaticMenuBar
            enableCheckboxes={false}
            placeholder="Hiring documents are shared with and able to be edited by the entire hiring team..."
            // autoSave={autoSave}
            autoFocus
            onChange={handleChangeEditorState}
            defaultValue={jobApplication?.sharedDocument || ""}
          />
        </Styled.Container>
        <Styled.ButtonContainer>
          <Button
            name="createJobButton"
            type="submit"
            onClick={handleSubmit}
            loading={isLoadingUpdate}
            // loading={isLoadingUpdate && !isDirty}
            disabled={isLoadingUpdate}
          >
            {isEditing ? "Save changes" : "Add document"}
          </Button>
          <Button styleType="secondary" onClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          {/* {isDirty ? <span>Changes not yet saved</span> : <span>No unsaved changes</span>} */}
        </Styled.ButtonContainer>
      </Styled.ModalContent>
    </FullModal>
  );
}

SharedDocumentModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default SharedDocumentModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.FormHeader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentModal_FormHeader;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.ModalContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentModal_ModalContent;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.Container = styled(FormContainer)((props) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentModal_Container;
    ${[t.px(4), t.py(5)]}
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SharedDocumentModal_ButtonContainer;
    ${t.p(4)}
    position: sticky;
    bottom: 0;
    display: flex;
    border-bottom-left-radius: 0.4375rem;
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { billboardMediaTypeOptions } from "@ats/src/lib/lookups";
import { removeHttpPrefix } from "@shared/lib/utils";

import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormUploader from "@ats/src/components/forms/FormUploader";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";

function JobBoardBillboardMediaChooser(props: {
  organizationId: number;
  onError: (errors: any) => void;
  errors: any;
}) {
  const { onError, errors } = props;
  const addToast = useToastContext();
  const { currentOrganization } = useCurrentSession();
  const {
    mutateAsync: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const careersPage = currentOrganization?.careersPages[0];

  const [billboardVideoUrl, setBillboardVideoUrl] = React.useState(careersPage?.billboardVideoUrl);

  React.useEffect(() => {
    setBillboardVideoUrl(careersPage?.billboardVideoUrl);
  }, [careersPage]);

  if (currentOrganization == undefined || careersPage == undefined) {
    return null;
  }

  const {
    // billboardVideoUrl, // editable
    // billboardImageUrl, // generated
    previewBillboardImageUrl, // generated
    billboardImageFilename,
    hasBillboardImage,
    billboardMediaType,
  } = careersPage;

  // window.logger("%c[JobBoardBillboardMediaChooser] render", "color: #1976D2", {
  //   billboardVideoUrl, // editable
  //   billboardImageUrl, // generated
  //   previewBillboardImageUrl, // generated
  //   billboardImageFilename,
  //   hasBillboardImage,
  //   billboardMediaType,
  //   currentOrganization,
  // });

  const hasFile = billboardMediaType == "billboard_media_type_image" && hasBillboardImage;

  const onCompleteDirectUpload = async (blob) => {
    const { signed_id: billboardImage } = blob;

    if (billboardImage) {
      return await updateCareersPage(
        { id: careersPage.id, billboardImage },
        {
          onSuccess: (data) => {
            addToast({ title: "Image uploaded", kind: "success" });
            onError([]);
            return data;
          },
          onError: (response) => {
            addToast({
              title: `Could not upload`,
              kind: "danger",
            });
            onError((response as any).data.errors);
            return null;
          },
        },
      );
    }

    // return Promise.resolve();
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid file`, kind: "warning" });
  };

  const updateCareersPageMediaType = (name, value) => {
    updateCareersPage({ id: careersPage.id, billboardMediaType: value });
  };

  const updateCareersPageVideoUrl = (name, value) => {
    updateCareersPage({ id: careersPage.id, billboardVideoUrl: value });
  };

  const handleChangeBillboardVideoUrl = (name, value) => {
    setBillboardVideoUrl(value);
  };

  return (
    <>
      <FormSelect
        onChange={updateCareersPageMediaType}
        name="billboardMediaType"
        label="Job board banner"
        description="An optional, large image or video displayed at the top of your job board."
        value={billboardMediaType}
        options={billboardMediaTypeOptions}
        errors={errors}
      />

      {billboardMediaType === "billboard_media_type_none" ? null : billboardMediaType ===
        "billboard_media_type_video" ? (
        <FormConditionalFields>
          <FormInput
            name="billboardVideoUrl"
            onChange={handleChangeBillboardVideoUrl}
            onBlur={updateCareersPageVideoUrl}
            placeholder="..."
            label="Job board banner video"
            pretext="https://"
            description="Provide a YouTube or Vimeo web address for the video you would like displayed."
            className=""
            value={removeHttpPrefix(billboardVideoUrl)}
            // defaultValue={removeHttpPrefix(billboardVideoUrl)}
            errors={errors}
          />
        </FormConditionalFields>
      ) : billboardMediaType === "billboard_media_type_image" ? (
        <FormConditionalFields>
          <FormUploader
            allowAutomaticUploading
            onCompleteDirectUpload={onCompleteDirectUpload}
            onInvalidFile={onInvalidFile}
            name="billboardImage"
            label="Job board banner image"
            description="Provide the image to use. It must be at least 1728px wide. PNG and JPG file types are acceptable. The maxium height of the banner is limited to a 16:9 aspect ratio."
            htmlFor="billboardImageUploader"
            fileIsAlreadyUploaded={hasFile}
            fileName={billboardImageFilename}
            placeholder="Upload"
            errors={errors}
            loading={isLoadingUpdateCareersPage}
            expectedFileType="image"
          />
          {hasFile ? (
            <Styled.CardPreview>
              <img src={previewBillboardImageUrl} />
            </Styled.CardPreview>
          ) : null}
        </FormConditionalFields>
      ) : null}
    </>
  );
}

JobBoardBillboardMediaChooser.propTypes = {};

JobBoardBillboardMediaChooser.defaultProps = {};

export default JobBoardBillboardMediaChooser;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CardPreview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoard_CardPreview;
    ${[t.mt(-2), t.p(2), t.rounded.sm]}
    display: inline-block;
    vertical-align: top;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : 'transparent'};
    > img {
      ${[t.rounded.md]}
      max-width: 32rem;
      overflow: hidden;
    }
  `;
});

import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useQueryClient } from "react-query";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useHotkeys } from "react-hotkeys-hook";

import JobApplicationSidebar from "@ats/src/views/jobApplications/JobApplicationSidebar";
import JobApplicationActivity from "@ats/src/views/jobApplications/JobApplicationActivity";
import JobApplicationResume from "@ats/src/views/jobApplications/JobApplicationResume";
import JobApplicationNotes from "@ats/src/views/jobApplications/JobApplicationNotes";
import JobApplicationMessages from "@ats/src/views/jobApplications/JobApplicationMessages";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import CommentModal from "@ats/src/views/jobApplications/comments/CommentModal";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useJobApplication } from "@shared/queryHooks/useJobApplication";
import { getChannel } from "@shared/queryHooks/useChannel";
import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
import { useModalContext } from "@shared/context/ModalContext";
import JobApplicationFiles from "./JobApplicationFiles";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobApplicationContainer({
  match,
  location,
  hiringStageId,
  jobApplicationId,
  hiringStages,
  shallowJobApplication,
  jobApplicationsForStage,
  ...props
}) {
  // useWhyDidYouUpdate("JobApplicationContainer ", props);

  // window.logger(
  //   "%c\n\n\n\n\n\n\n\n[JobApplicationContainer] render props",
  //   "background: #EFDDEF; color: #1976D2",
  //   props,
  // );
  const { openModal, removeModal } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const [currentViewPath, setCurrentViewPath] = React.useState(null);
  const setJob = useJobStore((state) => state.set);

  const jobApplicationFromStore = useJobStore((state) => {
    const jaFromStore =
      shallowJobApplication != undefined ? state.jobApplicationsById[jobApplicationId] : null;

    window.logger(
      "%c[JobApplicationContainer] jaFromStore",
      "background: #d66bd6; color: #0006D2",
      {
        jaFromStore,
        shallowJobApplication,
        jobApplicationsById: state.jobApplicationsById,
      },
    );
    return jaFromStore;
    // return state.jobApplicationsById[jobApplicationId];
  });

  const {
    data: jobApplication,
    isLoading: isLoadingJobApplication,
    isFetching: isFetchingJobApplication,
  } = useJobApplication({
    jobApplicationId,
    initialData: jobApplicationFromStore,
    // initialData: null,
  });

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "c",
    (event) => {
      event.preventDefault();

      window.logger("%c[JobApplicationContainer] hot key", "color: #1976D2", {
        event,
        jobApplication,
      });

      // searchInputRef.current.focus();
      // setNavigatingResults(false);

      const commentModal = (
        <CommentModal
          jobApplicationId={jobApplicationId}
          comment={{}}
          onCancel={removeModal}
          isEditing={false}
          activityPath={jobApplication?.urls?.activityPath}
        />
      );

      openModal(commentModal);

      return false;
    },
    {},
    [],
  );
  // window.logger("%c[JobApplicationContainer] render", "background: #ecf1bd; color: #132404", {
  //   shallowJobApplication,
  //   jobApplicationFromStore,
  //   jobApplication,
  //   jobApplicationId,
  //   isLoadingJobApplication,
  //   isFetchingJobApplication,
  //   match,
  //   locations: location.pathname.split("/"),
  // });

  if (jobApplication) {
    setJob((state) => {
      state.jobApplicationsById[jobApplication.id] = jobApplication;
    });
  }

  const allChannel =
    jobApplication != undefined && jobApplication.channels != undefined
      ? jobApplication.channels.find((channel) => channel.kind === "kind_all")
      : null;

  const queryClient = useQueryClient();
  const prefetchChannel = React.useCallback(
    async (channel) => {
      window.logger("%c[JobApplicationContainer][useChannel] prefetchChannel", "color: #1976D2", {
        channel,
      });
      await queryClient.prefetchQuery(["channel", channel.id], () => getChannel(channel.id));
      // The results of this query will be cached like a normal query
    },
    [queryClient],
  );

  // PREFETCH CHANNEL MESSAGES
  React.useEffect(() => {
    window.logger("%c[JobApplicationContainer] useEffect", "background-color: #bd19d2", {
      allChannel,
    });
    if (allChannel) {
      prefetchChannel(allChannel);
    }
  }, [allChannel, prefetchChannel]);

  React.useEffect(() => {
    const possiblePaths = ["overview", "resume", "messages", "files", "notes"];
    const locations = location.pathname.split("/").reverse();
    const currentView = locations[0];

    if (possiblePaths.includes(currentView)) {
      setCurrentViewPath(currentView);
    }
  }, [location]);

  // window.logger("%c\n\n[JobApplicationContainer] render\n\n", "background-color: #19d276", {
  //   jobApplication,
  //   isFetching,
  //   isSuccess,
  //   isLoading,
  //   isJobApplicationInCurrentStage: isJobApplicationInCurrentStage(),
  // });

  if (jobApplication == undefined) {
    // This is before the jobApplication == undefined Redirect so that we have time to fetch the jobApplicationsForStage
    window.logger("%c[JAC] NULL", "color: #FF0002", { jobApplication });
    return null;
  } else if (jobApplication.hiringStageId !== hiringStageId) {
    // This handles the situation where a user has visited an old Url for a jobApplication that was in a different stage than it's current stage
    const redirectUrl = `/jobs/${jobApplication.jobId}/stages/${jobApplication.hiringStageId}/applicants/${jobApplication.id}`;
    window.logger("%c[JAC] REDIRECT", "color: #920954", {
      jobApplication,
      hiringStageId,
      redirectUrl,
    });
    return <Redirect to={redirectUrl} />;
  }

  const { candidate } = jobApplication;

  const redirector = () => {
    if (currentViewPath != undefined) {
      return <Redirect to={`${match.url}/${currentViewPath}`} />;
    } else {
      return <Redirect to={`${match.url}/overview`} />;
    }
  };

  if (isLoadingJobApplication) {
    return <LoadingIndicator />;
  }

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <JobApplicationSidebar
          history={history}
          match={match}
          jobApplicationsForStage={jobApplicationsForStage}
          hiringStages={hiringStages}
          candidate={candidate}
          jobApplication={jobApplication}
        />
      </Styled.Sidebar>

      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[JobApplicationContainer] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Styled.Content>
        <Switch>
          <Route
            path={`${match.path}/overview`}
            render={(renderProps) => (
              <JobApplicationActivity
                // {...props}
                // {...renderProps}
                jobApplication={jobApplication}
                orgAdminJobsListUrl={props.orgAdminJobsListUrl}
              // setIsDirty={setIsDirty}
              />
            )}
          />
          <Route
            path={`${match.path}/messages`}
            render={(renderProps) => (
              <JobApplicationMessages
                {...props}
                {...renderProps}
                candidate={candidate}
                jobApplication={jobApplication}
                setIsDirty={setIsDirty}
              />
            )}
          />
          <Route
            path={`${match.path}/resume`}
            render={(renderProps) => (
              <JobApplicationResume {...props} {...renderProps} jobApplication={jobApplication} />
            )}
          />
          <Route
            path={`${match.path}/files`}
            render={(renderProps) => (
              <JobApplicationFiles {...props} {...renderProps} jobApplication={jobApplication} />
            )}
          />
          <Route
            path={`${match.path}/notes`}
            render={(renderProps) => {
              return (
                <JobApplicationNotes
                  {...props}
                  {...renderProps}
                  jobApplication={jobApplication}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          />

          {redirector()}

          {/* {isInboxStage() ? (
            <Redirect to={`${match.url}/overview`} />
          ) : (
            <Redirect to={`${match.url}/overview`} />
          )} */}
        </Switch>
      </Styled.Content>
    </Styled.Container>
  );
}

JobApplicationContainer.defaultProps = {};

export default JobApplicationContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: JobApplicationContainer;
  display: flex;
  height: 100%;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    flex-shrink: 0;
    width: 90vw;

    ${t.mq["lg"]} {
      width: 27.536%;
      flex-shrink: 1;
    }
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationContainer_Content;
    overflow-y: auto;
    flex-shrink: 0;
    width: 100vw;

    ${t.mq["lg"]} {
      width: 72.464%;
      flex-shrink: 1;
    }
  `;
});

import { useMutation, useQueryClient } from "react-query";
import { apiPost, apiPut, apiDelete } from "@shared/queryHooks/api";

const createJobCategory = async (variables) => {
  return await apiPost({ path: "/job_categories", variables });
};

const updateJobCategory = async (variables) => {
  return await apiPut({ path: `/job_categories/${variables.id}`, variables });
};

const deleteJobCategory = async (variables) => {
  return await apiDelete({ path: `/job_categories/${variables.id}`, variables });
};

export function useCreateJobCategory() {
  const queryClient = useQueryClient();
  return useMutation(createJobCategory, {
    // Notice the second argument is the variables object that the `mutate` function receives
    // onSuccess: (data, variables) => {
    //   queryClient.setQueryData(["jobCategory", { id: variables.id }], data);
    // },
    // Error
    // onError: (data, variables) => {
    //   window.logger(
    //     "%c[useJobCategory] handle error when creating Job Category",
    //     "background-color: #19F6D2",
    //     {
    //       data,
    //       variables,
    //     },
    //   );
    // },
    // After success or failure, refetch the jobCategories query
    onSettled: () => {
      queryClient.invalidateQueries("jobCategories");
    },
    // throwOnError: true,
  });
}

export function useUpdateJobCategory() {
  const queryClient = useQueryClient();
  return useMutation(updateJobCategory, {
    onSettled: () => {
      queryClient.invalidateQueries("jobCategories");
    },
    // throwOnError: true,
  });
}

export function useDeleteJobCategory() {
  const queryClient = useQueryClient();
  return useMutation(deleteJobCategory, {
    onSettled: () => {
      queryClient.invalidateQueries("jobCategories");
    },
    // throwOnError: true,
  });
}

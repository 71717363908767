import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";

import { useCreateComment, useUpdateComment } from "@shared/queryHooks/useComment";

import { validateComment } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  isEditing: boolean;
  comment: { id?: number; body?: string; rating?: string; kind?: string };
  jobApplicationId: number;
  activityPath?: string;
};

function CommentModal(props: Props) {
  const { isEditing, onCancel, jobApplicationId, activityPath } = props;
  const { dismissModalWithAnimation } = useModalContext();
  const addToast = useToastContext();
  // const [selection, setSelection] = React.useState<
  //   { start: any; end: any; textarea: any } | undefined
  // >();
  const {
    mutate: createComment,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateComment();
  const {
    mutate: updateComment,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateComment();

  const [comment, setComment] = useState(props.comment);
  const { body, rating, kind } = comment;

  const editorRef = React.useRef(undefined);
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[CommentModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  //   location,
  //   activityPath,
  // });

  const handleCancel = () => {
    dismissModalWithAnimation(() => onCancel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateComment({
      body: editorRef.current.serializedState(),
    });

    if (isValid) {
      isEditing ? handleUpdate() : handleCreate();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCreate = async () => {
    try {
      await createComment(
        {
          jobApplicationId,
          body: editorRef.current.serializedState(),
        },
        {
          onSuccess: (data) => {
            window.logger("%c[CommentModal] createComment onSuccess ", "color: #076e20", {
              data,
              // error: error.response.data,
            });
            dismissModalWithAnimation(() => onCancel);
            if (!location.pathname.includes("/overview") && activityPath != undefined) {
              addToast({
                title: `Comment saved`,
                kind: "success",
                delay: 4000,
                linkTo: activityPath,
              });
            }
          },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      window.logger("%c[CommentModal] handle error when CREATE Job Comment", "color: #FF7602", {
        error,
        // error: error.response.data,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleUpdate = async () => {
    const { id, ...restComment } = comment;

    // window.logger("%c[CommentModal] handleUpdate", "color: #1976D2", {
    //   jobApplicationId,
    //   id,
    //   body: editorRef.current.serializedState(),
    //   rating,
    //   kind,
    // });

    try {
      await updateComment({
        jobApplicationId,
        id,
        ...restComment,
        body: editorRef.current.serializedState(),
      });
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[CommentModal] handle error when updating Job Comment", "color: #FF7602", {
        error,
      });
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createOrEditButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate || isLoadingUpdate}
      >
        {isEditing ? "Save changes" : "Add comment"}
      </Button>
      <Button styleType="secondary" onClick={handleCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const editMessage = `Make changes to your comment shared with the hiring team.`;

  return (
    <CenterModal
      headerTitleText={isEditing ? "Edit comment" : "Add a new comment"}
      onCancel={handleCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        {isEditing ? editMessage : "Create and share a comment with the hiring team."}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <Styled.CommentInput>
          <ProseMirrorEditor
            ref={editorRef}
            enableLabel
            label="Your comment"
            editorId="comments-editor"
            enableStaticMenuBar={false}
            enableSelectionToolbar={true}
            onChange={handleChangeEditorState}
            defaultValue={body || ""}
            autoFocus
          />
        </Styled.CommentInput>
      </FormContainer>
    </CenterModal>
  );
}

CommentModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default CommentModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: CommentModal_Instructions;
    ${[t.text.gray, t.mb(5)]}
  `;
});

Styled.CommentInput = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentModal_CommentInput;
    > div {
      ${[t.mb(0)]}
    }
    textarea {
      min-height: 12rem;
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

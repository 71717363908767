import React from "react";
import { Draggable } from "react-beautiful-dnd";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { useDeleteChannelMessageTemplate } from "@shared/queryHooks/useChannelMessageTemplate";

import { useToastContext } from "@shared/context/ToastContext";

/*
--===================================================-- */

export default function ChannelMessageTemplateListItem(props) {
  const { channelMessageTemplate, index, openEditChannelMessageTemplateModal } = props;
  const { name, body, createdByUserFullName } = channelMessageTemplate;

  const handleOpenChannelMessageTemplateModal = (event) => {
    event.preventDefault();

    openEditChannelMessageTemplateModal(channelMessageTemplate);
  };

  const handleDeleteChannelMessageTemplate = async (event) => {
    event.preventDefault();

    props.deleteChannelMessageTemplate(channelMessageTemplate);

    // try {
    //   await deleteChannelMessageTemplate(channelMessageTemplate);
    // } catch (error) {
    //   // Uh oh, something went wrong
    //   window.logger(
    //     "%c[ChannelMessageTemplateListItem] handle error when DESTROY Job ChannelMessageTemplate",
    //     "color: #FF7602",
    //     {
    //       error,
    //       errorData: error.response.data,
    //     },
    //   );
    // }
  };

  return (
    <Draggable
      draggableId={channelMessageTemplate.id} // required prop
      index={index} // required prop
    >
      {(provided) => {
        return (
          <Styled.Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div>
              <Styled.ChannelMessageTemplateText>{name}</Styled.ChannelMessageTemplateText>

              <Styled.ChannelMessageTemplateDetails>
                Created by {createdByUserFullName != undefined ? createdByUserFullName : "Polymer"}
              </Styled.ChannelMessageTemplateDetails>

              {/* <Styled.ChannelMessageTemplateDetails>{`${
                Number(channelMessagesCount) > 0 ? channelMessagesCount : "No"
              } active job${
                Number(channelMessagesCount) === 1 ? "" : "s"
              }`}</Styled.ChannelMessageTemplateDetails> */}
            </div>
            <DropdownMenu label="Options">
              <a href="" onClick={handleOpenChannelMessageTemplateModal}>
                Edit template
              </a>
              <a href="" onClick={handleDeleteChannelMessageTemplate}>
                Delete template
              </a>
            </DropdownMenu>
          </Styled.Container>
        );
      }}
    </Draggable>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    transition: background-color 0.2s ease, border-color 0.2s ease;
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    &:hover,
    &:active {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.ChannelMessageTemplateText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateListItem_ChannelMessageTemplateText;
    ${t.mb(1)}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.ChannelMessageTemplateDetails = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateListItem_ChannelMessageTemplateDetails;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    max-width: 32rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
});

import React from "react";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormSection from "@ats/src/components/forms/FormSection";
import CopyField from "@ats/src/components/shared/CopyField";
import { useOrganizationApiKeys } from "@shared/queryHooks/useOrganization";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

function AccountIntegrationsZapier(props) {
  const { currentOrganization } = useCurrentSession();

  const { data: organizationApiKeys } = useOrganizationApiKeys(currentOrganization.id);

  const apiKey = organizationApiKeys?.zapierApiKey;
  const apiUrl = `https://${location.host}`;

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://help.polymer.co/en/articles/6218084-trigger-automated-workflows-in-zapier"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>

      <Button type="externalLink" link="https://zapier.com/" styleType="text">
        Visit Zapier
        <Icon name="external-link" />
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="Zapier"
      description="Create automated workflows using Polymer triggers."
      fullWidthForm={true}
      actions={ViewLink}
    >
      <FormSection>
        <FormLabel label="Admin API key" />
        <CopyField value={apiKey} label="Copy key" />

        <FormLabel label="API URL" />
        <CopyField value={apiUrl} label="Copy URL" />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsZapier;

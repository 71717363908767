import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost } from "@shared/queryHooks/api";
import queryString from "query-string";
import { BasicReportResponse } from "@shared/types/reporting";

interface BasicReportRequest {
  jobId: number;
  startDate?: string;
  endDate?: string;
  source?: string;
}

const getBasicReport = async ({ jobId, startDate, endDate, source }) => {
  const startDateParam = startDate ? `&start_date=${startDate}` : "";
  const endDateParam = endDate ? `&end_date=${endDate}` : "";
  const sourceParam = source ? `&source=${source}` : "";
  return await apiGet({
    path: `/analytics/basic_report?job_id=${jobId}${startDateParam}${endDateParam}${sourceParam}`,
  });
};

export function useBasicReport({
  jobId,
  startDate,
  endDate,
  source,
}: BasicReportRequest): {
  status: any;
  data: BasicReportResponse;
  error: any;
  isLoading: boolean;
  refetch: any;
} {
  return useQuery(["basicReport"], () => getBasicReport({ jobId, startDate, endDate, source }));
}

import create, { SetState, GetState } from "zustand";

type FeatureFlipperStore = {
  features: any[];
  setFeatures: (features) => void;
};

const useFeatureFlipperStore = create<FeatureFlipperStore>(
  (set: SetState<FeatureFlipperStore>, get: GetState<FeatureFlipperStore>) => ({
    features: [],
    setFeatures: (features): void => {
      set((state) => ({ features }));
    },
  }),
);

export { useFeatureFlipperStore };

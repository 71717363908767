import React from "react";
import { useWebsocket } from "@ats/src/websockets/WebsocketContext";

import { useJobApplicationStore } from "@ats/src/lib/store/zustand/jobApplicationStore";
import { useJobApplication } from "@shared/queryHooks/useJobApplication";
import { useJob } from "@shared/queryHooks/useJob";

function WebsocketJobChannelHandler(props) {
  const { jobId } = props;
  const channel = `JobChannel`;
  const Websocket = useWebsocket();

  const [activeJobApplicationId, setActiveJobApplicationId] = React.useState(null);
  const [convertingDocxToPdf, setConvertingDocxToPdf] = useJobApplicationStore((state) => [
    state.convertingDocxToPdf,
    state.setConvertingDocxToPdf,
  ]);

  // Just want to be able to refetch this query once DOCX is done converting to PDF, so that we disable the Loading indicator after the new url is available
  const { refetch: refetchJobApplication } = useJobApplication({
    jobApplicationId: activeJobApplicationId,
    initialData: undefined,
  });

  const { refetch: refetchJob } = useJob({
    jobId,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (activeJobApplicationId != undefined) {
      refetchJobApplication().then((data) => {
        // window.logger(
        //   "%c[WebsocketJobChannelHandler] useEffect refetchJobApplication",
        //   "background-color: #E4FF09",
        //   {
        //     activeJobApplicationId,
        //     data,
        //   },
        // );

        setConvertingDocxToPdf(false);
        setActiveJobApplicationId(null);
      });
    }
  }, [activeJobApplicationId, refetchJobApplication, setConvertingDocxToPdf]);

  const handleJobMessage = React.useCallback(
    (data) => {
      // window.logger("%c[WebsocketJobChannelHandler] data", "color: #FF76D2", { data });
      if (data.event != null) {
        switch (data.event) {
          case "wwr_listing_published":
          case "wwr_listing_updated":
            refetchJob();
            return;
          case "docx_to_pdf_conversion_complete":
            // This triggers the useEffect that does a refetchJobApplication
            setActiveJobApplicationId(data.payload.jobApplicationId);
            return;
          default:
            return;
        }
      }
    },
    [refetchJob],
  );

  const setupWebsocketConnections = React.useCallback(() => {
    if (jobId == undefined) return;

    // window.logger(
    //   "%c[WebsocketJobChannelHandler] setupWebsocketConnections",
    //   "background: #334F30; color: #E4FF09",
    //   { channel, jobId },
    // );

    return Websocket.subscriptions.create(
      { channel, jobId },
      {
        connected: function () {
          // window.logger(
          //   "%c[WebsocketJobChannelHandler] WEBSOCKET JobChannel ---- Connected",
          //   "background: #003423; color: #E4FF09",
          //   { channel, jobId },
          // );
        },

        disconnected: function () {
          // window.logger(
          //   "%c[WebsocketJobChannelHandler] WEBSOCKET JobChannel ---- Disconnected",
          //   "background: #EF3423; color: #E4FF09",
          //   { channel },
          // );
        },

        received: function (data) {
          // window.logger(
          //   "%c[WebsocketJobChannelHandler] WEBSOCKET JobChannel ---- Received",
          //   "background: #003423; color: #E4FF09",
          //   { data, channel, jobId },
          // );

          handleJobMessage(data);
        },
      },
    );
  }, [Websocket.subscriptions, channel, jobId, handleJobMessage]);

  React.useEffect(() => {
    // window.logger(
    //   "%c[WebsocketJobChannelHandler] useEffect",
    //   "background-color: #FF76D2; color: red;",
    // );
    const subscription = setupWebsocketConnections();
    return () => subscription.unsubscribe();
  }, [jobId, setupWebsocketConnections]);

  return <>{props.children}</>;
}

export default WebsocketJobChannelHandler;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Button from "@ats/src/components/shared/Button";

function MessageMailMergeMenuBar({
  viewRef,
  menu,
  state,
  dispatch,
  allowSenderMailMergeFields = true,
}) {
  // console.log("%c[MessageMailMergeMenuBar][ProseMirror] render", "color: #1976D2", {
  //   menu,
  //   state,
  //   dispatch,
  //   view: viewRef.current?.view,
  // });

  const handleInsert = (text) => {
    return (state, dispatch) => {
      let { from, to } = state.selection;
      // index = $from.index();

      console.log("%c[MessageMailMergeMenuBar] handleInsert", "color: #1976D2", {
        selection: state.selection,
      });
      // if (!$from.parent.canReplaceWith(index, index, dinoType)) return false;
      if (dispatch) dispatch(state.tr.insertText(text, from, to));
      return true;
    };
  };

  return (
    <Styled.VariableButtons>
      <Button
        styleType="secondary"
        onMouseDown={(event) => {
          event.preventDefault();
          handleInsert("{{CandidateFirstName}}")(state, dispatch);
        }}
      >
        <span>Candidate first name</span>
      </Button>
      <Button
        styleType="secondary"
        // onClick={() => handleInsert("{{CandidateFullName}}")}
        onMouseDown={(event) => {
          event.preventDefault();
          handleInsert("{{CandidateFullName}}")(state, dispatch);
        }}
      >
        <span>Candidate full name</span>
      </Button>
      <Button
        styleType="secondary"
        // onClick={() => handleInsert("{{JobTitle}}")}
        onMouseDown={(event) => {
          event.preventDefault();
          handleInsert("{{JobTitle}}")(state, dispatch);
        }}
      >
        <span>Job title</span>
      </Button>
      {allowSenderMailMergeFields && (
        <>
          <Button
            styleType="secondary"
            // onClick={() => handleInsert("{{SenderFirstName}}")}
            onMouseDown={(event) => {
              event.preventDefault();
              handleInsert("{{SenderFirstName}}")(state, dispatch);
            }}
          >
            <span>Sender first name</span>
          </Button>
          <Button
            styleType="secondary"
            // onClick={() => handleInsert("{{SenderFullName}}")}
            onMouseDown={(event) => {
              event.preventDefault();
              handleInsert("{{SenderFullName}}")(state, dispatch);
            }}
          >
            <span>Sender full name</span>
          </Button>
        </>
      )}
      <Button
        styleType="secondary"
        onMouseDown={(event) => {
          event.preventDefault();
          handleInsert("{{OrganizationName}}")(state, dispatch);
        }}
      >
        <span>Organization name</span>
      </Button>
    </Styled.VariableButtons>
  );
}

export default MessageMailMergeMenuBar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.VariableButtons = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: MessageMailMergeMenuBar_VariableButtons;
    ${[t.mt(1)]}
    transition: box-shadow 0.2s ease;
    display: flex;
    flex-wrap: wrap;
    button {
      ${[t.h(8), t.px(2), t.text.xs, t.mr(1), t.mt(1)]}

      ${t.mq["md"]} {
        ${t.h(6)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

import FormUploader from "@ats/src/components/forms/FormUploader";

function JobBoardLogoUploader(props: {
  errors: any[];
  onError: (errors: any) => void;
  // logoUploading: boolean;
  // onUploadComplete: any;
}) {
  const { onError, errors } = props;

  const { currentOrganization } = useCurrentSession();
  const careersPage = currentOrganization.careersPages[0];
  const { hasLogo, logoFilename, logoMedium, faviconUrl } = careersPage;
  const {
    mutateAsync: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const addToast = useToastContext();

  const onCompleteDirectUpload = async (blob) => {
    const { signed_id: logo } = blob;
    const { id } = careersPage;

    if (logo) {
      return await updateCareersPage(
        { id, logo },
        {
          onSuccess: (data) => {
            addToast({ title: "Logo uploaded", kind: "success" });
            window.logger(
              "%c[JobBoardLogoUploader] updateCareersPage onSuccess",
              "background-color: #7fb4e9",
              { data },
            );

            return data;
          },
          onError: (data) => {
            addToast({
              title: `Could not upload`,
              kind: "danger",
            });
            onError(data);
            return null;
          },
        },
      );

      // return Promise.resolve({ dummyData: true });
    } else {
      console.error("%c[JobBoardLogoUploader] no file found", "color: #1976D2");
      return Promise.resolve();
    }
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid file`, kind: "warning" });
  };

  return (
    <>
      <FormUploader
        allowAutomaticUploading
        onCompleteDirectUpload={onCompleteDirectUpload}
        onInvalidFile={onInvalidFile}
        name="logo"
        label="Job board logo"
        description="Your logo should be a square image with dimensions of at least 128px. PNG, JPG, and SVG file types are acceptable. The image you use on your company's Twitter, Facebook, or LinkedIn account should work great."
        fileIsAlreadyUploaded={hasLogo}
        fileName={logoFilename}
        placeholder="Upload"
        errors={errors}
        loading={isLoadingUpdateCareersPage}
        expectedFileType="image"
      />

      {hasLogo ? (
        <>
          <Styled.LogoPreview>
            <img src={logoMedium} />
          </Styled.LogoPreview>
          {/* <Styled.LogoPreview>
            <img src={faviconUrl} />
          </Styled.LogoPreview> */}
        </>
      ) : null}
    </>
  );
}

JobBoardLogoUploader.propTypes = {};

JobBoardLogoUploader.defaultProps = {};

export default JobBoardLogoUploader;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.LogoPreview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoard_LogoPreview;
    ${[t.mt(-2), t.p(2), t.rounded.md]}
    display: inline-block;
    vertical-align: top;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : 'transparent'};

    > img {
      ${[t.h(16), t.w(16), t.rounded.sm]}
      overflow: hidden;
    }
  `;
});

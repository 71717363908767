import { useReducer, useCallback } from "react";

export type ListState = {
  itemIdsSet: Set<number>;
  allSelected: boolean;
  itemIdsSetType: "excluded" | "included";
};

type Action =
  | { type: "SELECT_ITEM"; payload: number; totalItemsPossible: number }
  | { type: "DESELECT_ITEM"; payload: number }
  | { type: "SELECT_ALL" }
  | { type: "DESELECT_ALL" };

function checklistReducer(state: ListState, action: Action): ListState {
  const { itemIdsSet, itemIdsSetType } = state;
  switch (action.type) {
    case "SELECT_ITEM": {
      let newState = { ...state };
      if (itemIdsSetType === "included") {
        const newIncludedSet = new Set(itemIdsSet).add(action.payload);
        newState = {
          ...state,
          itemIdsSet: newIncludedSet,
          allSelected: newIncludedSet.size === action.totalItemsPossible,
        };
      } else if (itemIdsSetType === "excluded") {
        const newExcludedSet = new Set(itemIdsSet);
        newExcludedSet.delete(action.payload);
        newState = {
          ...state,
          itemIdsSet: newExcludedSet,
          allSelected: newExcludedSet.size === 0,
        };
      }
      return newState;
    }
    case "DESELECT_ITEM": {
      let newState = { ...state };
      if (itemIdsSetType === "included") {
        const newIncludedSet = new Set(itemIdsSet);
        newIncludedSet.delete(action.payload);
        newState = {
          ...state,
          itemIdsSet: newIncludedSet,
          allSelected: false,
        };
      } else if (itemIdsSetType === "excluded") {
        const newExcludedSet = new Set(itemIdsSet).add(action.payload);
        newState = {
          ...state,
          itemIdsSet: newExcludedSet,
          allSelected: false,
        };
      }
      return newState;
    }
    case "SELECT_ALL":
      return {
        ...state,
        itemIdsSet: new Set(),
        allSelected: true,
        itemIdsSetType: "excluded",
      };
    case "DESELECT_ALL":
      return { ...state, itemIdsSet: new Set(), allSelected: false, itemIdsSetType: "included" };
    default: {
      throw new Error("Unhandled action type in checklist reducer");
    }
  }
}

export const useChecklist = () => {
  const [state, dispatch] = useReducer(checklistReducer, {
    itemIdsSet: new Set(),
    allSelected: false,
    itemIdsSetType: "included",
  } as ListState);

  const selectItem = useCallback((id: number, totalItemsPossible: number) => {
    dispatch({ type: "SELECT_ITEM", payload: id, totalItemsPossible: totalItemsPossible });
  }, []);

  const deselectItem = useCallback((id: number) => {
    dispatch({ type: "DESELECT_ITEM", payload: id });
  }, []);

  const selectAll = useCallback(() => {
    dispatch({ type: "SELECT_ALL" });
  }, []);

  const deselectAll = useCallback(() => {
    dispatch({ type: "DESELECT_ALL" });
  }, []);

  const { itemIdsSet, allSelected, itemIdsSetType } = state;

  return {
    state: {
      itemIdsSet,
      allSelected,
      itemIdsSetType,
    },
    actions: {
      selectItem,
      deselectItem,
      selectAll,
      deselectAll,
    },
  };
};

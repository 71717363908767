import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Route, Switch, NavLink } from "react-router-dom";

import NavBar from "@shared/components/NavBar";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import AdminDashboardContainer from "@ats/src/views/admin/AdminDashboardContainer";
import AdminUsersSearch from "@ats/src/views/admin/AdminUsersSearch";
import AdminCandidatesSearch from "@ats/src/views/admin/AdminCandidatesSearch";
import AdminFeatureFlippers from "@ats/src/views/admin/AdminFeatureFlippers";
// import AdminOrganizationListWrapper from "@ats/src/views/admin/AdminOrganizationList/wrapper.js";
// import AdminJobListWrapper from "@ats/src/views/admin/AdminJobList/wrapper.js";

function AdminContainer(props) {
  window.logger("%c[AdminContainer] render", "color: #1976D2", props);

  const adminViews = () => {
    return (
      <Styled.DropdownContent>
        <NavLink to="/admin/dashboard">Dashboard</NavLink>
        {/* <NavLink to="/admin/organizations">Companies</NavLink> */}
        <NavLink to="/admin/users">Users</NavLink>
        <NavLink to="/admin/candidates">Candidates</NavLink>
        {window.IS_DEVELOPMENT && <NavLink to="/admin/flippers">Flippers</NavLink>}
      </Styled.DropdownContent>
    );
  };

  const jobActions = () => {
    return (
      <DropdownMenu testid="app-admin-nav-menu" label="app-admin-nav-options">
        {adminViews()}
      </DropdownMenu>
    );
  };

  return (
    <>
      <NavBar
        // loading={null}
        isBack={false}
        closeUrl={`/jobs`}
        history={props.history}
        title="Polymer admin"
        secondary={jobActions()}
      />

      <Styled.Container>
        <Switch>
          <Route
            path={`/admin/dashboard`}
            render={(renderProps) => <AdminDashboardContainer {...props} {...renderProps} />}
          />
          <Route
            path={`/admin/users`}
            render={(renderProps) => <AdminUsersSearch {...props} {...renderProps} />}
          />
          <Route
            path={`/admin/candidates`}
            render={(renderProps) => <AdminCandidatesSearch {...props} {...renderProps} />}
          />
          <Route
            path={`/admin/flippers`}
            render={(renderProps) => <AdminFeatureFlippers {...props} {...renderProps} />}
          />
        </Switch>
      </Styled.Container>
    </>
  );
}

AdminContainer.propTypes = {};

AdminContainer.defaultProps = {};

export default AdminContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  return css`
    label: AdminContainer;
    overflow-y: auto;
  `;
});

Styled.DropdownContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminNav_Secondary;
    display: flex;
    flex-direction: column;

    a {
      ${[t.h(8), t.pl(3), t.pr(6), t.text.medium]};
      display: flex;
      width: auto;
      align-items: center;

      &:hover {
        background-color: ${t.color.gray[100]};
      }
      &.active {
        background-color: ${t.color.gray[100]} !important;
      }
    }
  `;
});

import React, { useState } from "react";
import { Helmet } from "react-helmet";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import ChannelMessageTemplateModal from "@ats/src/components/modals/ChannelMessageTemplateModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import EmptyState from "@ats/src/components/shared/EmptyState";

import ChannelMessageTemplateList from "@ats/src/views/accountAdmin/components/ChannelMessageTemplateList";
import ChannelMessageTemplateListItem from "@ats/src/views/accountAdmin/components/ChannelMessageTemplateListItem";

import AlertModal from "@ats/src/components/modals/AlertModal";

import {
  useChannelMessageTemplates,
  useUpdateChannelMessageTemplate,
  useDeleteChannelMessageTemplate,
} from "@shared/queryHooks/useChannelMessageTemplate";
import { moveFromIndexToIndex } from "@shared/lib/utils";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

export default function AccountChannelMessageTemplates(props) {
  const { status, data, error, isFetching } = useChannelMessageTemplates();
  const addToast = useToastContext();

  // throw new Error("Testing errors");

  const { openModal, removeModal } = useModalContext();

  // window.logger("%c[AccountChannelMessageTemplates] render", "background: #EFDDEF; color: #1976D2", {
  //   props,
  //   status,
  // });

  const {
    mutate: updateChannelMessageTemplate,
    isLoading: isLoadingUpdate,
  } = useUpdateChannelMessageTemplate();
  const {
    mutate: deleteChannelMessageTemplate,
    isLoading: isLoadingDelete,
  } = useDeleteChannelMessageTemplate();

  const templateItems = data != undefined ? data.items : [];
  const hasTemplateItems = data && data.items;

  const [channelMessageTemplates, setChannelMessageTemplates] = useState([templateItems]);

  React.useEffect(() => {
    window.logger(
      "%c[AccountChannelMessageTemplates] useEffect",
      "background: #FF00EF; color: #1976D2",
      data,
    );
    if (hasTemplateItems) {
      setChannelMessageTemplates(templateItems);
    }
  }, [hasTemplateItems]);

  const handleClickOnDeleteChannelMessageTemplate = (channelMessageTemplate) => {
    const deleteMessage = `You will no longer be able to use the ${channelMessageTemplate.name} template.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this template?"
        subcopy={deleteMessage}
        confirmText="Delete template"
        cancelText="Keep template"
        onConfirm={() => {
          removeModal();
          handleDeleteChannelMessageTemplate(channelMessageTemplate);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const handleDeleteChannelMessageTemplate = async (channelMessageTemplate) => {
    try {
      await deleteChannelMessageTemplate(channelMessageTemplate);
      // channelMessageTemplate was successfully deleted
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[ChannelMessageTemplateModal] handle error when deleting ChannelMessageTemplate",
        {
          error,
        },
      );
    }
  };

  /* ChannelMessageTemplateModal
  --===================================================-- */
  const openNewChannelMessageTemplateModal = (event) => {
    event.preventDefault();
    openChannelMessageTemplateModal({}, false);
  };

  const openEditChannelMessageTemplateModal = (channelMessageTemplate) => {
    openChannelMessageTemplateModal(channelMessageTemplate, true);
  };

  const openChannelMessageTemplateModal = (channelMessageTemplate, isEditing) => {
    const modal = (
      <ChannelMessageTemplateModal
        onCancel={onCloseModal}
        // onSubmit={handleSubmitForm}
        isEditing={isEditing}
        channelMessageTemplate={channelMessageTemplate}
      />
    );
    openModal(modal);
  };

  const onCloseModal = () => {
    // window.logger("%c[AccountChannelMessageTemplate] onCloseModal", "color: #1976D2", {});
    removeModal();
  };

  /* onDragEndChannelMessageTemplate
  --===================================================-- */
  const onDragEndChannelMessageTemplate = (result: {
    destination: any;
    source: any;
    draggableId: number;
  }) => {
    window.logger(
      "%c[AccountChannelMessageTemplates] onDragEndChannelMessageTemplate result",
      "color: #1976D2",
      result,
    );
    // eslint-disable-next-line no-unused-vars
    const { destination, source, draggableId } = result;

    // If there is no destinatiom then do nothing
    if (!destination) return;

    // If the user drops the item back in its original spot then do nothing
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const channelMessageTemplate: { id: number } = data.items[source.index];
    const position: number = destination.index + 1;

    const newChannelMessageTemplates = moveFromIndexToIndex(
      channelMessageTemplates,
      source.index,
      destination.index,
    );

    setChannelMessageTemplates(newChannelMessageTemplates);

    updateChannelMessageTemplate({ ...channelMessageTemplate, position });
  };

  /* ChannelMessageTemplate nodes
  --===================================================-- */
  const channelMessageTemplateNodes = () => {
    // window.logger(
    //   "%c[AccountChannelMessageTemplates] channelMessageTemplateNodes",
    //   "color: #19FFD2",
    //   { data },
    // );

    if (channelMessageTemplates.length === 0) {
      return <EmptyState message="No message templates have been added" />;
    } else {
      return channelMessageTemplates.map(
        (channelMessageTemplate: { name: string }, index: number) => {
          return (
            <ChannelMessageTemplateListItem
              key={`${index}_${channelMessageTemplate.name}`}
              index={index}
              channelMessageTemplate={channelMessageTemplate}
              openEditChannelMessageTemplateModal={openEditChannelMessageTemplateModal}
              deleteChannelMessageTemplate={handleClickOnDeleteChannelMessageTemplate}
            />
          );
        },
      );
    }
  };

  // const alertModal = (
  //   <AlertModal
  //     title="Please saw you saw this"
  //     subcopy={`We are testing this out`}
  //     confirmText="Okay"
  //     onConfirm={() => {
  //       window.logger("%c[ACMT] MODAL CONFIRM", "color: #19FF02", { props });
  //       return removeModal;
  //     }}
  //     onCancel={() => {
  //       window.logger("%c[ACMT] MODAL CANCEL", "color: #FF5F02", { props });
  //       return removeModal;
  //     }}
  //   />
  // );

  // const alertModal = <div>Alert this!</div>;

  if (status === "loading") {
    return <LoadingIndicator label="Loading..." />;
  }

  const testingErrorBoundary = undefined;

  return (
    <>
      <Helmet title="Message templates" />
      <SettingsContainer
        title="Message templates"
        description="These templates allow hiring team members to easily send pre-authored messages to candidates. Changes to the ordering of the templates displayed here will affect how they are listed when selecting one for use."
      >
        {/* {testingErrorBoundary.test} */}
        <FormSection>
          <ChannelMessageTemplateList
            onDragEndChannelMessageTemplate={onDragEndChannelMessageTemplate}
          >
            {channelMessageTemplateNodes()}
          </ChannelMessageTemplateList>

          <Button styleType="secondary" onClick={openNewChannelMessageTemplateModal}>
            Add a template
          </Button>
        </FormSection>

        {/* <Button onClick={() => addToast({ title: "This is a test of the toasts" })}>
          Add Toast
        </Button>
        <Button onClick={() => openModal(alertModal)}>Add Modal</Button> */}
      </SettingsContainer>
    </>
  );
}

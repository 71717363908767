import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import SignupForm from "@ats/src/views/sessions/components/SignupForm";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";

function Signup(props) {
  const signupComplete = (user) => {
    props.history.push("/jobs");
  };

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        <Styled.Form>
          <Helmet title="Signup" />
          <h2>Create an account</h2>
          <div>
            <SignupForm onComplete={signupComplete} {...props} />
          </div>
        </Styled.Form>
        <Styled.Links>
          <Link to="/auth">Already have an account?</Link>
        </Styled.Links>
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(Signup);

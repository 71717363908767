import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const updateOrganization = async ({ id, ...organization }) => {
  window.logger("%c[useOrganization] updateOrganization\n\n\n\n", "background-color: #FF76D2", {
    organization,
  });
  return await apiPut({
    path: `/organizations/${id}`,
    variables: { organization },
  });
};

const createOrganization = async ({ ...organization }) => {
  window.logger("%c[useOrganization] updateOrganization\n\n\n\n", "background-color: #FF76D2", {
    organization,
  });
  return await apiPost({
    path: `/organizations`,
    variables: { organization },
  });
};

const getOrganization = async (organizationId) => {
  window.logger("%c[useOrganization] getOrganization", "background-color: #00F6D2", {
    organizationId,
  });
  if (organizationId != undefined) {
    return await apiGet({
      path: `/organizations/${organizationId}`,
    });
  }
};

const getOrganizationApiKeys = async (organizationId) => {
  window.logger("%c[useOrganization] getOrganizationApiKeys", "background-color: #00F6D2", {
    organizationId,
  });
  if (organizationId != undefined) {
    return await apiGet({
      path: `/organizations/${organizationId}/api_keys`,
    });
  }
};

const getTimestamp = async () => {
  window.logger("%c[useOrganization] getTimestamp\n\n\n\n", "background-color: #00F6D2", {});
  return await apiGet({
    path: `/timestamp/now`,
  });
};

const transferOrganizationOwnership = async ({ id, organizationUserId }) => {
  window.logger(
    "%c[useOrganization] transferOrganizationOwnership\n\n\n\n",
    "background-color: #FF76D2",
    {
      id,
      organizationUserId,
    },
  );
  return await apiPut({
    path: `/organizations/${id}/transfer_ownership`,
    variables: { organizationUserId },
  });
};

/* Hooks
--===================================================-- */
function useUpdateOrganization() {
  const queryClient = useQueryClient();
  return useMutation(updateOrganization, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useOrganization] useUpdateOrganization onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["currentOrganization", data.id]);
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

function useCreateOrganization() {
  const queryClient = useQueryClient();
  return useMutation(createOrganization, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useOrganization] useCreateOrganization onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries("currentOrganization");
      // queryClient.removeQueries("currentOrganization");
      queryClient.setQueryData("currentOrganization", data);
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

function useOrganization(
  organizationId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  return useQuery(["currentOrganization", organizationId], () => getOrganization(organizationId), {
    refetchOnWindowFocus: false,
  });
}

function useOrganizationApiKeys(
  organizationId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  return useQuery(
    ["currentOrganizationApiKeys", organizationId],
    () => getOrganizationApiKeys(organizationId),
    {
      refetchOnWindowFocus: false,
    },
  );
}

function useTransferOrganizationOwnership() {
  const queryClient = useQueryClient();
  return useMutation(transferOrganizationOwnership, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c [useOrganization] useTransferOrganizationOwnership onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries(["currentOrganization", data.id]);
    },
    // throwOnError: true,
  });
}

export {
  useOrganization,
  useUpdateOrganization,
  useCreateOrganization,
  getTimestamp,
  useOrganizationApiKeys,
  useTransferOrganizationOwnership,
};

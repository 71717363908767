import create, { SetState, GetState } from "zustand";
import produce from "immer";

type JobStore = {
  jobsById: {};
  hiringStagesById: {};
  hiringStagesByJobId: {};
  questionsByJobId: {};
  questionsById: {};
  jobApplicationsById: {};
  jobApplicationsByJobId: {};
  set: (fn: any) => any;
};

const useJobStore = create<JobStore>((set: SetState<JobStore>, get: GetState<JobStore>) => ({
  jobsById: {},
  hiringStagesById: {}, // for looking up an individual HiringStage
  hiringStagesByJobId: {}, // for looking up the HiringStages for a Job
  questionsByJobId: {}, // for getting the list of Questions
  questionsById: {}, // for looking up an individual Question (used in JobApplicationActivity)
  jobApplicationsByJobId: {}, // Sorted to have Inbox, ...InProcess, Archived
  jobApplicationsById: {}, // for looking up an individual JobApplication
  set: (fn) => {
    set(produce(fn));
  },
}));

// const refetchJob = (jobId) => {
//  queryCache.refetchQueries(["jobs", data.jobId], { exact: true });
// }

export { useJobStore };

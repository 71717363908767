import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

const FormLabel = (props) => {
  return (
    <Styled.Container>
      <Styled.Label>
        <label htmlFor={props.for}>{props.label}</label>
        {props.isRequired && <Styled.RequiredLabel>({props.requiredLabel})</Styled.RequiredLabel>}
      </Styled.Label>
      {props.error && <Styled.Error>{props.error}</Styled.Error>}
    </Styled.Container>
  );
};

FormLabel.propTypes = {
  label: PropTypes.string,
  for: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  requiredLabel: PropTypes.string,
};

FormLabel.defaultProps = {
  requiredLabel: "required",
};

export default FormLabel;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div`
  label: FormLabel;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormLabel_Label;
    ${[t.text.sm, t.text.bold]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    display: inline-block;
    line-height: 1.4;
    margin-bottom: 0.375rem;

    ${t.mq["md"]} {
      ${[t.text.xs]}
    }
  `;
});

Styled.RequiredLabel = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: FormLabel_RequiredLabel;
    ${[t.text.normal, t.ml(1)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Error = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormLabel_Error;
    ${t.text.sm}
    color: ${t.dark ? t.color.red[400] : t.color.red[600]};

    ${t.mq["md"]} {
      ${[t.text.xs]}
    }
  `;
});

import React, { cloneElement } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Icon from "@ats/src/components/shared/Icon";
import { useTooltip, TooltipPopup } from "@reach/tooltip";
import { useSpring, animated, config } from "react-spring";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";
import theme from "@ats/styles/theme";

const AnimatedTooltipPopup = animated(TooltipPopup);

// Center the tooltip, but collisions will win
const centered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 4 + window.scrollY,
  };
};

function ReachTooltip({ children, label, "aria-label": ariaLabel }) {
  // get the props from useTooltip
  const [trigger, tooltip, isVisible] = useTooltip();
  const { isDarkMode } = useColorSchemeContext();

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "scaleY(0.95)",
    },
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "scaleY(1)" : "scaleY(0.95)",
    },
    delay: 960,
    config: {
      tension: 480,
      clamp: true,
    },
  });

  // destructure off what we need to position the triangle
  // const { isVisible, triggerRect } = tooltip;
  return (
    <>
      {cloneElement(children, trigger)}
      {label && (
        <>
          {/* {isVisible && (
            // The Triangle. We position it relative to the trigger, not the popup
            // so that collisions don't have a triangle pointing off to nowhere.
            // Using a Portal may seem a little extreme, but we can keep the
            // positioning logic simpler here instead of needing to consider
            // the popup's position relative to the trigger and collisions
            <Portal>
              <div
                style={{
                  position: "absolute",
                  left: triggerRect && triggerRect.left - 6 + triggerRect.width / 2,
                  top: triggerRect && triggerRect.bottom + window.scrollY + 2,
                  width: 0,
                  height: 0,
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  borderBottom: `6px solid ${theme.color.gray[800]}`,
                  zIndex: 90,
                  pointerEvents: 'none',
                }}
              />
            </Portal>
          )} */}
          <AnimatedTooltipPopup
            {...tooltip}
            label={label}
            aria-label={ariaLabel}
            style={{
              position: "absolute",
              background: isDarkMode ? theme.color.gray[700] : theme.color.white,
              color: isDarkMode ? theme.color.white : theme.color.gray[600],
              border: isDarkMode
                ? `1px solid ${theme.color.gray[600]}`
                : `1px solid ${theme.color.gray[200]}`,
              boxShadow: "none",
              borderRadius: "0.1875rem",
              padding: theme.spacing[1] + " " + theme.spacing[2],
              fontSize: theme.spacing[3],
              maxWidth: theme.spacing[48],
              lineHeight: "1.4",
              zIndex: 100,
              pointerEvents: "none",
              transformOrigin: "top left",
              ...spring,
            }}
            position={centered}
          />
        </>
      )}
    </>
  );
}

export default function Tooltip({ children, label }) {
  return (
    <ReachTooltip aria-label={label} label={label}>
      {children ? (
        <div style={{ maxWidth: "100%" }}>{children}</div>
      ) : (
        <Styled.Container>
          <Icon name="info" />
        </Styled.Container>
      )}
    </ReachTooltip>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Tooltip;
    ${[t.text.sm, t.text.bold, t.h(4), t.w(4)]}
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: top;
  `;
});

import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiDelete } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const deleteOauthAuthentication = async ({ provider }) => {
  return await apiDelete({
    path: `/integrations/oauth_authentications/${provider}`,
    variables: {},
  });
};

/* Hooks
--===================================================-- */

export function useDeleteOauthAuthentication() {
  const queryClient = useQueryClient();
  return useMutation(deleteOauthAuthentication, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      // queryClient.refetchQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

import React from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import useOnClickOutside from "use-onclickoutside";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import ModalBackdrop from "@ats/src/components/modals/ModalBackdrop";
import Icon from "@ats/src/components/shared/Icon";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import EmptyState from "@ats/src/components/shared/EmptyState";
import Button from "@ats/src/components/shared/Button";

import { useUniversalSearch } from "@shared/queryHooks/useUniversalSearch";
import useMeasure from "@shared/hooks/useMeasure";
import useRoveFocus from "@shared/hooks/useRoveFocus";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

function UniversalSearchResultListItem({
  // index,
  focus,
  // setFocus,
  children,
  candidateShowUrl,
  // history,
  handleSelectingResult,
}) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus]);

  const handleSelect = React.useCallback(() => {
    // alert(`${character}`);
    // setting focus to that element when it is selected
    // setFocus(index);
    handleSelectingResult(candidateShowUrl);
  }, [handleSelectingResult, candidateShowUrl]);

  return (
    <li
      tabIndex={focus ? 0 : -1}
      role="button"
      ref={ref}
      // onClick={handleSelect}
      onKeyPress={handleSelect}
    >
      {children}
    </li>
  );
}

function UniversalSearch({ history }) {
  const searchRef = React.useRef(null);
  const searchInputRef = React.useRef(null);
  const theme: any = useTheme();
  const { isDarkMode } = useColorSchemeContext();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [showResults, setShowResults] = React.useState(false);
  const [navigatingResults, setNavigatingResults] = React.useState(false);

  const { mutate: mutateUniversalSearch, data, isLoading } = useUniversalSearch();
  const [resizeRef, { height }] = useMeasure();

  // const jobApplications = (data && data.jobApplications) || [];

  const [jobApplications, setJobApplications] = React.useState(
    (data && data.jobApplications) || [],
  );

  React.useEffect(() => {
    const searchInput = searchInputRef?.current;
    if (searchInput) {
      searchInput.addEventListener("keydown", handleKeydownEvent);
    }

    return () => {
      searchInputRef && searchInput.removeEventListener("keydown", handleKeydownEvent);
    };
  }, []);

  /* HOTKEYS
  --===================================================-- */
  // window.logger("%c[UniversalSearch] BIND hot key", "color: #1976D2");
  useHotkeys(
    "ctrl+f, /, f",
    (event) => {
      event.preventDefault();

      // window.logger("%c[UniversalSearch] hot key", "color: #1976D2", { event });

      searchInputRef.current.focus();
      setNavigatingResults(false);
      return false;
    },
    {},
    [],
  );

  React.useEffect(() => {
    if (data) {
      // window.logger("%c[US] useEffect setJobApplications", "background-color: #FEFF02", { data });
      setJobApplications(data.jobApplications);
    }
  }, [data]);

  const [focus, setFocus] = useRoveFocus(jobApplications.length, resizeRef.current);

  // window.logger("%c[UniversalSearch] render", "color: #1976D2", { data, jobApplications, isLoading });

  useOnClickOutside(searchRef, () => {
    // window.logger("%c[US] useOnClickOutside", "background-color: #FEFF02", { data });
    hideSearch();
  });

  const handleKeydownEvent = (event) => {
    // window.logger("%c[UniversalSearch] searchInputRef keydown event", "color: #1976D2", {
    //   event,
    // });
    if (event.keyCode === 27 || event.key === "Escape") {
      hideSearch();
      searchInputRef.current.blur();
    } else if (event.keyCode === 40 || event.key === "ArrowDown") {
      // TODO: use arrow keys to navigate search results
      // window.logger("%c[UniversalSearch] searchInputRef key ArrowDown", "color: #1976D2", {
      //   event,
      // });
      // resizeRef.current.focus();
      setNavigatingResults(true);
      return false;
    }
  };

  const hideSearch = () => {
    // Close results
    setShowResults(false);
    setJobApplications([]);
    // Clean up local state
    setSearchTerm("");
  };

  const hideSearchResults = () => {
    setShowResults(false);
  };

  const showSearchResults = () => {
    setNavigatingResults(false);
    setFocus(0);
    setShowResults(true);
  };

  const handleClickClear = (event) => {
    event.preventDefault();
    hideSearch();
  };

  const handleOnChange = (event) => {
    event.preventDefault();
    const target = event.currentTarget;
    setSearchTerm(target.value);
    search(target.value);
  };

  const search = async (value) => {
    try {
      await mutateUniversalSearch({
        searchTerm: value,
      });
      // window.logger("%c[UniversalSearch] search success", "color: #23D722", {
      //   searchTerm,
      //   value,
      //   data,
      // });
    } catch (error) {
      window.logger("%c[UniversalSearch] search error", "color: #F90002", {
        searchTerm,
        value,
        data,
      });
    }
  };

  const searchFieldSpring = useSpring({
    backgroundColor: isDarkMode
      ? showResults
        ? theme.color.gray[800]
        : theme.color.gray[800]
      : showResults
        ? theme.color.white
        : theme.color.gray[100],
    config: {
      tension: 480,
    },
  });

  const resultsSpring = useSpring({
    from: {
      opacity: 0,
      height: 0,
    },
    to: {
      opacity: showResults ? 1 : 0,
      height: showResults ? height : 0,
    },
    config: {
      tension: 480,
      friction: 36,
    },
  });

  return (
    <Styled.Container>
      <ModalBackdrop isVisible={showResults} onClick={hideSearchResults} />
      <Styled.Search style={searchFieldSpring} ref={searchRef} showResults={showResults}>
        <Icon name="search" />
        <input
          ref={searchInputRef}
          type="text"
          id="search"
          name="searchTerm"
          placeholder="Search candidates"
          value={searchTerm}
          onFocus={showSearchResults}
          onChange={handleOnChange}
        />
        {searchTerm && (
          <Button styleType="text" onClick={handleClickClear}>
            Clear
          </Button>
        )}

        <Styled.Results style={resultsSpring}>
          <div ref={resizeRef}>
            {isLoading ? (
              <Styled.Loading>
                <LoadingIndicator label="Searching..." />
              </Styled.Loading>
            ) : jobApplications.length > 0 ? (
              <ul>
                {jobApplications.map((jobApplication, index) => {
                  let candidateShowUrl = `/jobs/${jobApplication.jobId}/stages/${jobApplication.hiringStageId}/applicants/${jobApplication.id}`;

                  return (
                    <UniversalSearchResultListItem
                      key={jobApplication.id}
                      // index={index}
                      focus={focus === index && navigatingResults}
                      // setFocus={setFocus}
                      candidateShowUrl={candidateShowUrl}
                      // history={history}
                      handleSelectingResult={(candidateShowUrl) => {
                        hideSearch();
                        history.push(candidateShowUrl);
                      }}
                    >
                      <Styled.CandidateLink to={candidateShowUrl} onClick={hideSearch}>
                        <Styled.Name>
                          <Icon name="user" />
                          <div>
                            {jobApplication.candidateName}
                            <span>{`as ${jobApplication.jobTitle}`}</span>
                          </div>
                        </Styled.Name>
                        <Icon name="chevron-right" />
                      </Styled.CandidateLink>
                    </UniversalSearchResultListItem>
                  );
                })}
              </ul>
            ) : searchTerm ? (
              <EmptyState
                borderless={true}
                icon="user-x"
                title="No candidates found"
                message="There are no active candidates that match your search."
              />
            ) : showResults ? (
              <EmptyState
                borderless={true}
                icon="users"
                title="Candidate quick search"
                message="Start typing to search through your active candidates by name."
              />
            ) : null}
          </div>
        </Styled.Results>
      </Styled.Search>
    </Styled.Container>
  );
}

export default UniversalSearch;

let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: UniversalSearch;
    flex: 2;
    z-index: 100;
    display: none;

    ${t.mq["lg"]} {
      display: block;
    }
  `;
});

// Styled.Search = styled(animated.div)((props: any) => {
Styled.Search = styled(animated.div, { shouldForwardProp: isPropValid })((props: any) => {
  const t: any = props.theme;
  const results = props.showResults;

  // window.logger("%c[Styled.Search] props", "color: #1976D2", { props });

  return css`
    label: UniversalSearch_Search;
    ${[t.h(8), t.px(3), t.rounded.md]}
    height: 38px;
    flex: 2;
    display: flex;
    align-items: center;
    position: relative;
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    transition: box-shadow 0.2s ease, border-radius 0.2s ease;
    ${!results && `box-shadow: inset 0 0 0 1px ${t.dark ? "transparent" : t.color.gray[200]};`}

    ${results &&
    `
      border-bottom-width: 1px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

    &:hover {
      ${!results &&
    `box-shadow: inset 0 0 0 1px ${t.dark ? t.color.gray[600] : t.color.gray[300]};`}
    }

    &:focus-within {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    }

    > svg {
      ${t.mr(2)}
    }

    input {
      ${[t.p(0)]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background: none;
      border: none;
      outline: none;
      flex: 1;
      height: 100%;

      &::placeholder {
        color: ${t.color.gray[500]};
      }
    }
  `;
});

Styled.Results = styled(animated.div)((props) => {
  const t: any = props.theme;
  return css`
    label: UniversalSearch_Results;
    ${[t.rounded.md]}
    position: absolute;
    left: 0;
    right: 0;
    top: 38px;
    display: flex;
    flex-direction: column;
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;

    ul {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    li:focus,
    li:focus-visible {
      outline: none;
      background: ${t.dark ? t.color.gray[700] : t.color.gray[200]};

      svg {
        stroke: ${t.dark ? t.color.white : t.color.black};
        display: block;
      }
    }
  `;
});

Styled.Loading = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: UniversalSearch_Loading;
    height: 6.53125rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      ${t.m(0)}
    }
  `;
});

Styled.CandidateLink = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: UniversalSearch_CandidateLink;
    ${[t.pl(3), t.pr(2), t.h(10)]}
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: background-color 0.2s ease;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    > svg {
      ${t.ml(2)}
      flex-shrink: 0;
      display: none;
    }
    &:hover,
    &:focus {
      color: ${t.dark ? t.color.white : t.color.black};
      background: ${t.dark ? t.color.gray[700] : t.color.gray[200]};
      > svg {
        stroke: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
        display: block;
      }
      outline: none;
    }
  `;
});

Styled.Name = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: UniversalSearch_Name;
    display: flex;
    align-items: center;
    overflow: hidden;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    span {
      ${[t.ml(1)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";

import FormInput from "@ats/src/components/forms/FormInput";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";

import { validateNewOrganization } from "@shared/lib/validateWithYup";
import { useCreateOrganization } from "@shared/queryHooks/useOrganization";

import { useCookieValue } from "@shared/hooks/useCookieValue";

function OrganizationForm(props) {
  const { onComplete, currentOrganization } = props;
  const [name, setName] = React.useState("");
  const [heardAboutUsFrom, setHeardAboutUsFrom] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const theme: any = useTheme();
  const { mutate: createOrganization, isLoading } = useCreateOrganization();

  const gclAwCookieValue = useCookieValue("_gcl_aw");
  const parsedArray = gclAwCookieValue ? gclAwCookieValue.split(".") : null;
  // Expected format for cookie value:  GCL.1719852261.actualGoogleClickIdHere
  // Since the beginning can be edited in GTM, get last element of array
  const googleClickId =
    parsedArray && parsedArray.length > 1 ? parsedArray[parsedArray.length - 1] : null;

  window.logger("[OrganizationForm] get _gcl_aw cookie value", { gclAwCookieValue, googleClickId });

  const handleNameChange = (name, value) => {
    setName(value);
  };

  const handleHeardAboutUsFromChange = (name, value) => {
    setHeardAboutUsFrom(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [isValid, validationErrors] = await validateNewOrganization({ name });

    if (isValid) {
      createOrganization(
        { name, googleClickId, heardAboutUsFrom },
        {
          onSuccess: (data) => {
            window.logger("[OrganizationForm] createOrganization onSuccess", { data });
            onComplete(data);
          },
          onError: (response: any) => {
            setErrors(response.data.errors);
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <FormContainer
      onSubmit={handleSubmit}
      buttons={
        <div
          css={css`
            display: flex;
            flex-direction: column;
            ${theme.mq["md"]} {
              flex-direction: row;
            }
          `}
        >
          <Button type="submit" disabled={isLoading} loading={isLoading}>
            Create organization
          </Button>
          {currentOrganization != undefined ? (
            <Button
              type="internalLink"
              styleType="secondary"
              disabled={isLoading}
              to={`/organization/manage`}
              css={css`
                ${theme.mt(3)}
                ${theme.mq["md"]} {
                  ${[theme.mt(0), theme.ml(3)]}
                }
              `}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      }
    >
      <FormInput
        onChange={handleNameChange}
        name="name"
        placeholder=""
        label="Organization name"
        className=""
        value={name}
        errors={errors}
        isRequired={true}
      />

      <FormInput
        onChange={handleHeardAboutUsFromChange}
        name="heardAboutUsFrom"
        placeholder=""
        label="How did you hear about us?"
        className=""
        value={heardAboutUsFrom}
        errors={errors}
      />

      {/* <FormInput
          name="slug"
          description="The URL that your organization's Polymer job board will be visible at. For example: https://jobs.polymer.co/organization-name"
          pretext="https://jobs.polymer.co/"
          placeholder="..."
          label="Job board URL"
          className=""
          value={slug}
          errors={errors}
        /> */}
    </FormContainer>
  );
}

export default OrganizationForm;

/* eslint-disable @getify/proper-arrows/return */
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import JobApplicationContainer from "@ats/src/views/jobApplications/JobApplicationContainer";
import EmptyState from "@ats/src/components/shared/EmptyState";
import JobStageMenu from "@ats/src/views/jobApplications/JobStageMenu";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import LoadMoreTrigger from "@ats/src/components/shared/LoadMoreTrigger";
import FormCheckbox from "@ats/src/components/forms/FormCheckbox";
import JobApplicationNavItem from "@ats/src/views/jobApplications/JobApplicationNavItem";

import { useInfiniteJobApplicationsForStage } from "@shared/queryHooks/useJobApplication";
import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
import { useChecklist } from "@shared/hooks/useChecklist";

import { arrayToObject } from "@ats/src/lib/utils/helpers";
import { CandidatePrivacyStatus } from "@shared/types/candidate";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobApplicationListContainer({ match, history, job, hiringStages, orgAdminJobsListUrl, ...props }) {
  // useWhyDidYouUpdate(" JobApplicationListContainer", props);
  // const { match, job } = props;
  const stageId = match.params.stageId;
  const candidatesByNameListRef = React.useRef(null);
  const [canLoadMore, setCanLoadMore] = React.useState(true);
  const { state: listState, actions } = useChecklist();

  const { selectItem, deselectItem, selectAll, deselectAll } = actions;

  const {
    data: jobApplicationsForStageData,
    isLoading: isLoadingJobApplicationsForStage,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteJobApplicationsForStage(
    { jobId: job.id, stageId }, //jobData
  );

  const scrollHandler = React.useCallback(() => {
    // window.logger("%c[JobApplicationListContainer] scroll event", "color: #209425", {
    //   scrollTop: candidatesByNameListRef.current.scrollTop,
    //   clientHeight: candidatesByNameListRef.current.clientHeight,
    //   heightOfList: candidatesByNameListRef.current.scrollTop + candidatesByNameListRef.current.clientHeight,
    //   scrollHeight: candidatesByNameListRef.current.scrollHeight,
    // });
    if (
      candidatesByNameListRef.current.scrollTop +
        candidatesByNameListRef.current.clientHeight +
        100 >=
      candidatesByNameListRef.current.scrollHeight
    ) {
      if (canLoadMore) fetchNextPage();
    }
  }, [fetchNextPage, canLoadMore]);

  React.useEffect(() => {
    const listEl = candidatesByNameListRef.current;

    if (listEl) listEl.addEventListener("scroll", scrollHandler);

    return () => {
      listEl.removeEventListener("scroll", scrollHandler);
    };
  }, [candidatesByNameListRef, scrollHandler]);
  // }, [candidatesByNameListRef, fetchNextPage, hasNextPage]);

  /* Guard against extra loading
  --===================================================-- */
  React.useEffect(() => {
    if (canLoadMore && hasNextPage === false) setCanLoadMore(false);
  }, [hasNextPage, canLoadMore]);

  const jobApplications =
    jobApplicationsForStageData != undefined && jobApplicationsForStageData.pages != undefined
      ? jobApplicationsForStageData.pages.reduce((accumulator, currentValue) => {
          return accumulator.concat(currentValue && currentValue.items ? currentValue.items : []);
        }, [])
      : [];

  const currentStage = useJobStore(
    React.useCallback((state) => state.hiringStagesById[stageId], [stageId]),
  );
  const setJob = useJobStore((state) => state.set);

  const jobApplicationsForStage = React.useMemo(
    () =>
      !isEmpty(jobApplications)
        ? jobApplications.filter((jobApplication: any) => {
            return (
              jobApplication.hiringStageId === currentStage.id // && jobApplication.status === "status_in_process"
            );
          })
        : [],
    [jobApplications, currentStage.id],
  );

  if (jobApplicationsForStage != undefined) {
    setJob((state) => {
      state.jobApplicationsById = arrayToObject(jobApplicationsForStage);
    });
  }

  const firstJobApplicationId = !isEmpty(jobApplicationsForStage)
    ? jobApplicationsForStage[0].id
    : null;

  window.logger(
    "%c[JobApplicationListContainer] Infinite ",
    "background: #EFDDEF; color: #1976D2",
    {
      //props
      job,
      stageId,
      // match,
      jobApplications,
      jobApplicationsForStageData,
      jobApplicationsForStage,
      firstJobApplicationId,
      isFetchingNextPage,
      hasNextPage,
      canLoadMore,
      itemIdsSet: listState.itemIdsSet,
      itemIdsSetType: listState.itemIdsSetType,
    },
  );
  /* Handle Selections
  ________________________________*/
  //If stage id changes, reset list
  React.useEffect(() => {
    deselectAll();
  }, [currentStage.id, deselectAll]);
  // Remove ids from selectedIds if no longer in jobApplicationsForStage
  const idsToRemove = React.useMemo(() => {
    if (listState.itemIdsSetType === "included") {
      return Array.from(listState.itemIdsSet).filter(
        (id) => !jobApplicationsForStage.some((jobApplication) => jobApplication.id === id),
      );
    } else {
      return [];
    }
  }, [jobApplicationsForStage, listState.itemIdsSet, listState.itemIdsSetType]);

  React.useEffect(() => {
    idsToRemove.forEach((id) => {
      deselectItem(id);
    });
  }, [idsToRemove, deselectItem]);

  const isChecked = (id: number) => {
    if (listState.itemIdsSetType === "included") {
      return listState.itemIdsSet.has(id);
    } else if (listState.itemIdsSetType === "excluded") {
      return !listState.itemIdsSet.has(id);
    }
  };
  // Toggle selection functions
  const toggleSelectItem = (jobApplicationId: number, currentStageApplicationsCount: number) => {
    const itemIsChecked = isChecked(jobApplicationId);
    if (itemIsChecked) {
      deselectItem(jobApplicationId);
    } else {
      selectItem(jobApplicationId, currentStageApplicationsCount);
    }
  };

  const toggleSelectAll = () => {
    if (listState.allSelected) {
      deselectAll();
    } else {
      selectAll();
    }
  };

  /* Candidates list
  ________________________________*/

  const candidateList = () => {
    if (isLoadingJobApplicationsForStage) {
      return <LoadingIndicator label="Loading..." />;
    }

    if (isEmpty(jobApplicationsForStage)) {
      return (
        <EmptyState
          borderless
          icon="user-x"
          title="No candidates"
          message="There are currently no candidates in this hiring stage"
        />
      );
    }

    return jobApplicationsForStage.map((jobApplication) => {
      const { candidate, id } = jobApplication;

      let jobApplicationShowUrl = `/jobs/${jobApplication.jobId}/stages/${currentStage.id}/applicants/${id}`;

      return (
        <JobApplicationNavItem
          key={`${id}-${jobApplication.jobId}`}
          id={id}
          to={jobApplicationShowUrl}
          label={candidate.fullName}
          icon={candidate.privacyStatus === CandidatePrivacyStatus.ANONYMIZED ? "user-x" : "user"}
          onChange={toggleSelectItem}
          checked={isChecked(id)}
          applicationsCount={currentStage?.jobApplicationsCount}
        />
      );
    });
  };

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <Styled.Header>
          <Styled.HeaderContent>
            {currentStage?.jobApplicationsCount > 0 ? (
              <FormCheckbox
                name="toggleAllCheckbox"
                label=" "
                checked={listState.allSelected}
                onChange={toggleSelectAll}
              />
            ) : <span />}
            <h3>{currentStage.name}</h3>
          </Styled.HeaderContent>
          {!isEmpty(jobApplicationsForStage) && (
            <JobStageMenu
              job={job}
              currentStage={currentStage}
              jobApplications={jobApplicationsForStage}
              listState={listState}
              history={history}
              resetList={() => deselectAll()}
            />
          )}
        </Styled.Header>
        <Styled.CandidateList ref={candidatesByNameListRef} data-testid="connect-member-list">
          {candidateList()}
          {isFetchingNextPage && (
            <Styled.PageLoader>
              <LoadingIndicator label="Loading..." subtle={true} />
            </Styled.PageLoader>
          )}
          {/* {canLoadMore && !isFetchingNextPage && ( */}
          {hasNextPage && !isFetchingNextPage && (
            <LoadMoreTrigger
              onEnterView={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
              }}
            />
          )}
        </Styled.CandidateList>
      </Styled.Sidebar>
      <Styled.Content>
        <Switch>
          <Route
            path={`${match.path}/:jobApplicationId`}
            render={(renderProps) => {
              const params = renderProps.match.params;
              const jobId = Number(params.jobId);
              const jobApplicationId = Number(params.jobApplicationId);
              const hiringStageId = Number(params.stageId);
              const shallowJobApplication = jobApplications.find(
                (ja) => ja.jobId === jobId && ja.id === jobApplicationId,
              );

              window.logger(
                "%c[JobApplicationListContainer] RENDER JOBAPPLICATION CONTAINER",
                "background-color: #d25419",
                { props, renderProps, match, shallowJobApplication },
              );

              // if (shallowJobApplication == undefined && jobApplicationId != undefined) {
              //   return null;
              // }

              // if (shallowJobApplication != undefined) {
              //   return (
              //     <JobApplicationContainer
              //       {...renderProps}
              //       stageId={match.params.stageId}
              //       jobApplicationsForStage={jobApplicationsForStage}
              //       shallowJobApplication={shallowJobApplication}
              //       jobApplicationId={jobApplicationId}
              //       jobId={jobId}
              //       hiringStageId={hiringStageId}
              //       hiringStages={hiringStages}
              //     />
              //   );
              // } else {
              //   const redirectUrl = `${match.url}`;
              //   // window.logger("%c[JALC] no shallowJobApplication", "color: #3a7a05", {
              //   //   jobApplications,
              //   //   shallowJobApplication,
              //   //   jobApplicationId,
              //   //   redirectUrl,
              //   // });
              //   return <Redirect to={redirectUrl} />;
              // }

              return (
                <JobApplicationContainer
                  {...renderProps}
                  stageId={match.params.stageId}
                  jobApplicationsForStage={jobApplicationsForStage}
                  shallowJobApplication={shallowJobApplication}
                  jobApplicationId={jobApplicationId}
                  jobId={jobId}
                  hiringStageId={hiringStageId}
                  hiringStages={hiringStages}
                  orgAdminJobsListUrl={orgAdminJobsListUrl}
                />
              );
            }}
          />
          {/* Redirect to the first JobApplication in this Stage if there are any */}
          {firstJobApplicationId && <Redirect to={`${match.url}/${firstJobApplicationId}`} />}
        </Switch>
      </Styled.Content>
    </Styled.Container>
  );
}

JobApplicationListContainer.propTypes = {};
JobApplicationListContainer.defaultProps = {};

export default JobApplicationListContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: JobApplicationListContainer;
  display: flex;
  height: 100%;
  overflow-y: hidden;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_Sidebar;
    ${[t.pb(10)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    flex-shrink: 0;
    width: 50vw;

    ${t.mq["lg"]} {
      width: 21.5909091%;
      flex-shrink: 1;
    }
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_Header;
    ${[t.h(10), t.pl(2)]}
    padding-right: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
});

Styled.HeaderContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_HeaderContent;
    display: flex;
    align-items: center;
    overflow: hidden;

    h3 {
      ${[t.text.bold, t.mr(1)]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      ${t.pl(2)}
    }
  `;
});

Styled.CandidateList = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_CandidateList;
    height: 100%;
    overflow-y: auto;
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_Content;
    width: 78.4090909%;
  `;
});

Styled.PageLoader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationListContainer_Name;
    display: flex;
    justify-content: center;
  `;
});

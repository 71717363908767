import React from "react";
import { Draggable } from "react-beautiful-dnd";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

/*
--===================================================-- */

// export default class JobCategoryListItem extends Component {
export default function JobCategoryListItem(props) {
  const { jobCategory, index, openEditJobCategoryModal, deleteJobCategory } = props;
  const { name, jobsCount } = jobCategory;

  // window.logger("%c[JobCategoryListItem] category", "color: #1976D2", { jobCategory });

  const handleOpenJobCategoryModal = (event) => {
    event.preventDefault();

    openEditJobCategoryModal(jobCategory);
  };

  const handleDeleteJobCategory = (event) => {
    event.preventDefault();

    deleteJobCategory(jobCategory);
  };

  return (
    <Draggable
      draggableId={jobCategory.id} // required prop
      index={index} // required prop
    >
      {(provided) => {
        return (
          <Styled.Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            data-testid={name}
          >
            <div>
              <Styled.JobCategoryText>{name}</Styled.JobCategoryText>
              <Styled.JobCategoryDetails>{`${Number(jobsCount) > 0 ? jobsCount : "No"} active job${Number(jobsCount) === 1 ? "" : "s"
                }`}</Styled.JobCategoryDetails>
            </div>
            <DropdownMenu label="Options">
              <a href="" onClick={handleOpenJobCategoryModal}>
                Edit category
              </a>
              <a href="" onClick={handleDeleteJobCategory}>
                Delete category
              </a>
            </DropdownMenu>
          </Styled.Container>
        );
      }}
    </Draggable>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    transition: background-color 0.2s ease, border-color 0.2s ease;
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    &:hover,
    &:active {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.JobCategoryText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryListItem_JobCategoryText;
    ${t.mb(1)}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.JobCategoryDetails = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: JobCategoryListItem_JobCategoryDetails;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

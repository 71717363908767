import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";

import {
  useCreateHiringStageAutomation,
  useUpdateHiringStageAutomation,
} from "@shared/queryHooks/useHiringStageAutomation";
import { useChannelMessageTemplates } from "@shared/queryHooks/useChannelMessageTemplate";

import { HiringStageAutomation } from "@ats/types/job";

type HiringStageOption = {
  value: number; // id
  label: string; // name
};

type Props = {
  onCancel: () => void;
  isEditing: boolean;
  passedHiringStageAutomation: HiringStageAutomation;
  job: any;
  hiringStageOptions: Array<HiringStageOption>;
};

function HiringStageAutomationModal(props: Props) {
  const { isEditing, onCancel, passedHiringStageAutomation, job, hiringStageOptions } = props;
  const [stageAutomationState, setStageAutomationState] = useState({
    hiringStageId: passedHiringStageAutomation?.hiringStage?.id || null,
    channelMessageTemplateId: passedHiringStageAutomation?.channelMessageTemplateId || null,
    frequency: passedHiringStageAutomation?.frequency || "",
  });
  const [errors, setErrors] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const { data: channelMessageTemplates } = useChannelMessageTemplates();
  const {
    mutate: createHiringStageAutomation,
    isLoading: isLoadingCreate,
  } = useCreateHiringStageAutomation();
  const {
    mutate: updateHiringStageAutomation,
    isLoading: isLoadingUpdate,
  } = useUpdateHiringStageAutomation();

  window.logger("%c[HiringStageAutomationModal] render", "color: #a05195", {
    props,
    stageAutomationState,
  });

  const channelMessageTemplateSelectOptions = channelMessageTemplates
    ? channelMessageTemplates.items.map((template) => {
        return { value: template.id, label: template.name };
      })
    : [];

  const frequencyOptions = [
    { value: "once", label: "Once" },
    { value: "every_time", label: "Every time" },
  ];

  const stageOptionsWithoutInbox = hiringStageOptions.filter(
    (options) => options.label !== "Inbox",
  );
  // Do not allow new Automations to be created for Hiring Stages with an existing Automation
  const filteredStageOptionsWithoutInbox = stageOptionsWithoutInbox.filter(
    (option) =>
      !job.hiringStages.some(
        (stage) => stage.hiringStageAutomation !== null && option.value === stage.id,
      ),
  );

  const stageOptions = isEditing ? stageOptionsWithoutInbox : filteredStageOptionsWithoutInbox;

  const handleChangeHiringStageAutomation = (name, value) => {
    // window.logger("%c[HiringStageAutomationModal] onChange", "color: #a05195", {
    //   name,
    //   value,
    //   stageAutomationState,
    // });
    setStageAutomationState({ ...stageAutomationState, [name]: value });
    setErrors([]);
    setIsDirty(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    isEditing ? handleUpdate() : handleCreate();
  };

  const handleCreate = async () => {
    await createHiringStageAutomation(
      { jobId: job.id, ...stageAutomationState },
      {
        onSuccess: (data) => {
          window.logger("%c[HiringStageAutomationModal] handleCreate onSuccess", "color: #a05195", {
            data,
          });
          onCancel(); // this closes the modal
        },
        onError: (response: any) => {
          window.logger("%c[HiringStageAutomationModal] handleCreate onError", "color: #FF7602", {
            response,
          });
          if (response.data && response.data.errors) setErrors(response.data.errors);
        },
      },
    );
  };

  const handleUpdate = async () => {
    await updateHiringStageAutomation(
      {
        id: passedHiringStageAutomation.id,
        jobId: job.id,
        ...stageAutomationState,
      },
      {
        onSuccess: (data) => {
          window.logger("%c[HiringStageAutomationModal] handleUpdate onSuccess", "color: #a05195", {
            data,
          });
          onCancel(); // this closes the modal
        },
        onError: (response: any) => {
          window.logger("%c[HiringStageAutomationModal] handleUpdate onError", "color: #FF7602", {
            response,
          });
          if (response.data && response.data.errors) setErrors(response.data.errors);
        },
      },
    );
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate || isLoadingUpdate}
      >
        {isEditing ? "Update automation" : "Add automation"}
      </Button>
      <Button styleType="secondary" onClick={onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const instructions = isEditing ? (
    <>
      Edit the automation for the hiring stage{" "}
      <span>{passedHiringStageAutomation?.hiringStage?.name}</span>. Moving a candidate to the
      selected hiring stage will trigger an email message to the candidate using the chosen message
      template.
    </>
  ) : (
    "Moving a candidate to the selected hiring stage will trigger an email message to the candidate using the chosen message template."
  );

  return (
    <CenterModal
      headerTitleText={isEditing ? "Edit automation" : "Add automation"}
      onCancel={onCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>{instructions}</Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormSelect
          onChange={handleChangeHiringStageAutomation}
          name="hiringStageId"
          label="Hiring stage"
          description={null}
          className=""
          value={stageAutomationState.hiringStageId}
          options={stageOptions}
          errors={errors}
          isClearable={false}
          isDisabled={isEditing}
          autoFocus
        />
        <FormSelect
          onChange={handleChangeHiringStageAutomation}
          name="frequency"
          label="Frequency"
          description="Choose to send the message once or every time a candidate is moved to the stage."
          className=""
          value={stageAutomationState.frequency}
          options={frequencyOptions}
          errors={errors}
          isClearable={false}
        />
        <FormSelect
          onChange={handleChangeHiringStageAutomation}
          name="channelMessageTemplateId"
          label="Message template"
          description="Select an existing message template."
          className=""
          value={stageAutomationState.channelMessageTemplateId}
          options={channelMessageTemplateSelectOptions}
          errors={errors}
          isClearable={false}
        />
      </FormContainer>
    </CenterModal>
  );
}

export default HiringStageAutomationModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageAutomationModal_Instructions;
    ${[t.mb(5)]}

    span {
      font-weight: 700;
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: HiringStageAutomationModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormLabel from "@ats/src/components/forms/FormLabel";
import FormInput from "@ats/src/components/forms/FormInput";
import Button from "@ats/src/components/shared/Button";
import { removeFromArray } from "@shared/lib/utils";

type Props = {
  label: string;
  options: any[];
  updateOptions: (options) => void;
  errors: {};
};

function FormSelectBuilder(props: Props) {
  const { updateOptions, errors, options } = props;
  const [activeValue, setActiveValue] = React.useState("");

  const handleClickAdd = (event) => {
    event.preventDefault();

    const tempOptions = options.concat(activeValue);
    updateOptions(tempOptions);
    setActiveValue("");
  };

  const handleInputChange = (name, value) => {
    setActiveValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClickAdd(event);
    }
  };

  const removeOption = (option) => {
    const newArr = removeFromArray(options, (a) => a === option);
    window.logger("%c[FormSelectBuilder] removeOption", "color: #1976D2", { newArr });
    updateOptions(newArr);
  };

  return (
    <>
      <FormLabel label="Answer choices" isRequired />
      {options.length > 0 && (
        <Styled.Choices>
          {options.map((option, index) => {
            return (
              <Styled.Choice key={`${option}-${index}`}>
                {option}{" "}
                <Button styleType="text" onClick={() => removeOption(option)}>
                  Remove
                </Button>
              </Styled.Choice>
            );
          })}
        </Styled.Choices>
      )}
      <Styled.Field>
        <FormInput
          name="option"
          placeholder="Answer text"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={activeValue}
          errors={errors}
        />

        <Button styleType="secondary" onClick={handleClickAdd}>
          Add
        </Button>
      </Styled.Field>
    </>
  );
}

FormSelectBuilder.propTypes = {};

FormSelectBuilder.defaultProps = {};

export default FormSelectBuilder;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Field = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormSelectBuilder_Field;
    ${t.mb(6)}
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      ${[t.mb(0), t.pr(3)]}
      flex: 1;
    }
  `;
});

Styled.Choices = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: FormSelectBuilder_Choices;
    ${[t.mb(3), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[700] : t.color.white};
  `;
});

Styled.Choice = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: FormSelectBuilder_Choice;
    ${[t.px(2), t.py(2)]}
    min-height: ${t.spacing[8]};
    border-bottom: 1px solid ${t.dark ? t.color.gray[600] : t.color.gray[200]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
      border-bottom: none;
    }

    button {
      ${[t.mr(1), t.ml(6)]}
      flex-shrink: 0;
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import textStyles from "@ats/styles/text";

import { prettyDateAndTime } from "@shared/lib/time";

import Tooltip from "@ats/src/components/shared/Tooltip";
import CommentModal from "@ats/src/views/jobApplications/comments/CommentModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import UserAvatar from "@ats/src/components/shared/UserAvatar";

import { useDeleteComment } from "@shared/queryHooks/useComment";

import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function CommentListItem({
  id,
  body,
  rating,
  kind,
  organizationUser,
  // userAvatarSmall,
  jobApplicationId,
  publishedAtTimestamp,
  publishedAtTimeAgoShort,
}) {
  const { openModal, removeModal } = useModalContext();
  const { currentUser } = useCurrentSession();
  // window.logger("%c[CommentListItem] RENDER", "background-color: #82d219", {
  //   jobApplicationId,
  //   id,
  //   body,
  //   rating,
  //   kind,
  //   organizationUser,
  // });
  const {
    mutate: deleteComment,
    error: errorOnDelete,
    isLoading: isLoadingDelete,
  } = useDeleteComment();

  const { user } = organizationUser;

  const handleOnClickEditComment = (event) => {
    event.preventDefault();
    const modal = (
      <CommentModal
        jobApplicationId={jobApplicationId}
        comment={{ id, body, rating, kind }}
        onCancel={removeModal}
        isEditing
      />
    );

    window.logger("%c[CommentListItem] handleOnClickEditComment", "background-color: #82d219", {
      jobApplicationId,
      id,
      body,
      rating,
      kind,
    });

    openModal(modal);
  };


  const handleOnClickDeleteComment = () => {

    const deleteMessage = `This comment will be permanently deleted.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this comment?"
        subcopy={deleteMessage}
        confirmText="Delete comment"
        cancelText="Keep comment"
        onConfirm={() => {
          removeModal();
          handleDeleteComment();
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    window.logger("%c[CommentListItem] handleOnClickDeleteComment", "background-color: #82d219", {
      jobApplicationId,
      id,
      body,
      kind,
    });

    openModal(modal);
  };

  const handleDeleteComment = async () => {

    try {
      await deleteComment({
        jobApplicationId,
        id,
        body,
      });

    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[CommentModal] handle error when deleting Job Comment", "color: #FF7602", {
        error,
      });
    }
  };


  // const user = {
  //   initials: "cd",
  //   hasAvatar: false,
  //   fullName: "test dood",
  //   avatarMedium: userAvatarSmall,
  // };

  return (
    <Styled.Comment>
      <Styled.Event>
        <div>
          <Styled.Avatar>
            <UserAvatar size="sm" user={user} />
          </Styled.Avatar>
          {user.fullName} left a comment
          <Styled.Seperator>‧</Styled.Seperator>
          <Tooltip label={prettyDateAndTime(publishedAtTimestamp)}>
            <span>{publishedAtTimeAgoShort}</span>
          </Tooltip>
        </div>
        {currentUser.id === user.id ? (
          <div>
            <button onClick={handleOnClickEditComment}>Edit</button>
            <button onClick={handleOnClickDeleteComment}>Delete</button>
          </div>
        ) : null}
      </Styled.Event>
      <Styled.Content dangerouslySetInnerHTML={{ __html: body }} />
    </Styled.Comment>
  );
}

export default CommentListItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Event = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: Comment_Event;
    ${[t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      svg {
        ${t.mr(2)}
      }
    }

    button {
      ${[t.pl(3), t.text.xs]}
      justify-self: flex-end;
      background: none;
      border: none;
      outline: none;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  `;
});

Styled.Comment = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: CommentListItem_Comment;
    ${[t.mt(4), t.mb(4), t.px(4), t.pt(4), t.pb(1), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 1.75rem;
      width: 4px;
      margin-left: -2px;
      height: ${t.spacing[6]};
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
  `;
});

Styled.Content = styled.div((props: any) => {
  const t: any = props.theme;
  const sentByUser = props.sentByUser;
  return css`
    label: CommentListItem_Content;
    ${textStyles}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    ${t.dark &&
    `
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: CommentListItem_Seperator;
    ${t.mx(1)}
  `;
});

Styled.Avatar = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: CommentListItem_Avatar;
    ${t.mr(2)}
  `;
});

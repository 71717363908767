import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import Button from "@ats/src/components/shared/Button";
import NewJobCenterModal from "@ats/src/components/modals/NewJobCenterModal";
import { useNotificationsHasUnread } from "@shared/queryHooks/useNotification";
import { useModalContext } from "@shared/context/ModalContext";
import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";
import UniversalSearch from "@ats/src/components/shared/UniversalSearch";
// import { CurrentUser, CurrentOrganization } from "@ats/src/context/CurrentSessionContext";
import { CurrentUser } from "@shared/types/currentUser";
import { CurrentOrganization } from "@shared/types/organization";
import OrgSwitcherLogo from "@ats/src/components/shared/OrgSwitcherLogo";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";

type Props = {
  headerStyle: string;
  currentUser: CurrentUser;
  currentOrganization: CurrentOrganization;
  isLoadingOrganization: boolean;
  location: any;
  history: any;
};

function AppHeader(props: Props) {
  const { currentUser, currentOrganization, location, history } = props;
  const {
    data: hasUnreadData,
    isFetching: isFetchingNotificationsHasUnread,
    isSuccess,
    isLoading,
  } = useNotificationsHasUnread();
  const { openModal, removeModal } = useModalContext();
  const authorized = useAuthorization();

  // window.logger("%c[AppHeader] render", "color: #1976D2", {
  //   isFetchingNotificationsHasUnread,
  //   hasUnreadData,
  //   props,
  //   currentUser,
  //   currentOrganization,
  // });

  const accountSettingsLinkTo = (
    <Styled.Link
      to={{
        pathname: `${
          currentUser.currentOrganizationUser.isAdmin
            ? "/hire/settings/organization"
            : "/hire/settings/preferences"
        }`,
        state: { orginalPathname: props.location.pathname },
      }}
    >
      <Icon name="settings" />
    </Styled.Link>
  );

  // const { hasUnread = false } = hasUnreadData;
  const hasUnread = hasUnreadData != undefined ? hasUnreadData.hasUnread : false;

  // const userNav = <UserNav user={currentUser} {...props} />;

  const handleClickCreateNewJob = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const modal = <NewJobCenterModal history={history} onCancel={() => removeModal()} />;

    openModal(modal);
  };

  return (
    <Styled.Container>
      <Styled.LogoWrapper>
        <Styled.Logo>
          <OrgSwitcherLogo {...currentOrganization} history={history} />
        </Styled.Logo>

        <Styled.Buttons>
          <DropdownMenu>
            <Link to="/organization/manage">Your organizations</Link>
          </DropdownMenu>
          {/* <Tooltip label="App settings">
            {accountSettingsLinkTo}
          </Tooltip> */}
          {/* <Tooltip label="Create new job">
            <Styled.CreateButton><Icon name="plus" /></Styled.CreateButton>
          </Tooltip> */}
        </Styled.Buttons>
      </Styled.LogoWrapper>

      {authorized({ minimumRole: "org_user" }) ? <UniversalSearch history={history} /> : null}

      {authorized({ minimumRole: "org_user" }) ? (
        <Styled.UserWrapper>
          <Tooltip label="Notifications">
            <Styled.Notifications
              hasUnread={hasUnread}
              to={{
                pathname: `/notifications`,
                state: { orginalPathname: location.pathname },
              }}
            >
              <Icon name="bell" />
            </Styled.Notifications>
          </Tooltip>
          <Tooltip label="App settings">{accountSettingsLinkTo}</Tooltip>
          {/* {userNav} */}
          {/* <Button
            styleType="secondary"
            onClick={handleClickCreateNewJob}
            data-testid="create-job-button"
            trackingPayload={{ ctaLocation: "Job List View" }}
          >
            Create new job
          </Button> */}
        </Styled.UserWrapper>
      ) : null}
    </Styled.Container>
  );
}

AppHeader.defaultProps = {
  headerStyle: "default",
};

export default AppHeader;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  const modeStyles = t.dark
    ? css`
        color: ${t.color.gray[300]};
        border-bottom: 1px solid ${t.color.gray[800]};
      `
    : css`
        border-bottom: 1px solid ${t.color.gray[200]};
      `;

  return css`
    ${[t.t(0), t.l(0), t.r(0)]};
    display: flex;
    height: 3rem;
    min-height: 3rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 20;
    ${modeStyles}
  `;
});

Styled.LogoWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.pl(4)]}
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    ${t.mq["lg"]} {
      width: 25%;
    }
  `;
});

Styled.Logo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    overflow: hidden;
  `;
});

Styled.Buttons = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.pl(2)}
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${t.mq["lg"]} {
      ${t.pr(2)}
      position: static;
    }
  `;
});

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    ${[t.h(8), t.w(8), t.rounded.sm]}
    label: JobListItem_Link;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;

    &:visited,
    &:link {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    &:hover {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    }
  `;
});

Styled.CreateButton = styled.button((props) => {
  const t: any = props.theme;
  return css`
    ${[t.h(8), t.w(8), t.rounded.sm]}
    label: JobListItem_Link;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;
    border: none;
    background: none;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    cursor: pointer;

    &:hover {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    }
  `;
});

Styled.UserWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.pr(4)}
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      display: none;
    }

    ${t.mq["lg"]} {
      width: 25%;
      button {
        display: flex;
      }
    }
  `;
});

Styled.Notifications = styled(Link, { shouldForwardProp: isPropValid })((props: any) => {
  const t: any = props.theme;
  return css`
    ${[t.h(8), t.w(8), t.mx(2), t.rounded.sm]}
    justify-content: center;
    align-items: center;
    position: relative;
    display: none;

    ${t.mq["lg"]} {
      display: flex;
    }

    &:hover {
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    ${props.hasUnread &&
    css`
      &:after {
        ${[t.h(2), t.w(2), t.rounded.md]}
        content: '';
        position: absolute;
        top: 0.125rem;
        right: 0.125rem;
        background-color: ${t.color.red[500]};
      }
    `}
  `;
});

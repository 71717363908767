import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useTransition, animated } from "react-spring";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

const ModalBackdrop = ({ whenClicked, isVisible, onClose }) => {
  const { isDarkMode } = useColorSchemeContext();
  const transitions = useTransition(isVisible, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 0.75,
    },
    leave: {
      opacity: 0,
    },
    config: {
      tension: 480,
      clamp: true,
    },
    onDestroyed: onClose,
  });

  return (
    <>
      {transitions((props, item) => {
        // window.logger("%c[ModalBackdrop] transitions", "color: #FF00F0", {
        //   item,
        //   style,
        //   index,
        // });
        return (
          item && (
            <animated.div
              style={props}
              css={(theme) => css`
                background-color: ${isDarkMode ? theme.color.black : `rgba(0,0,0,0.25)`};
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
              `}
              onClick={whenClicked}
            />
          )
        );
      })}
    </>
  );
};

ModalBackdrop.propTypes = {
  whenClicked: PropTypes.func,
};

export default ModalBackdrop;

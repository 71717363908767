import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import NavBar from "@shared/components/NavBar";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import NavItem from "@ats/src/components/shared/NavItem";

import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";

import AccountTeam from "@ats/src/views/accountAdmin/AccountTeam";
import AccountOrganizationDetails from "@ats/src/views/accountAdmin/AccountOrganizationDetails";
import AccountIntegrationsContainer from "@ats/src/views/accountAdmin/accountIntegrations/AccountIntegrationsContainer";
import AccountJobBoardContainer from "@ats/src/views/accountAdmin/accountJobBoard/AccountJobBoardContainer";
import AccountJobCategories from "@ats/src/views/accountAdmin/AccountJobCategories";
import AccountPreferences from "@ats/src/views/accountAdmin/AccountPreferences";
import AccountBillingOld from "@ats/src/views/accountAdmin/AccountBillingOld";
import AccountBillingNew from "@ats/src/views/accountAdmin/AccountBillingNew";
import AccountChannelMessageTemplates from "@ats/src/views/accountAdmin/AccountChannelMessageTemplates";
import AccountCommentTemplates from "@ats/src/views/accountAdmin/AccountCommentTemplates";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useGetMe } from "@shared/queryHooks/useMe";
import { useOrganization } from "@shared/queryHooks/useOrganization";

import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";

function AccountContainer(props: any) {
  // window.logger("%c[AccountContainer] ", "background: #EFDDEF; color: #1976D2", props);
  const { history, loading } = props;
  const { currentUser, currentOrganizationUser, currentOrganization } = useCurrentSession();
  const [isDirty, setIsDirty] = useState(false);
  const { data: me, isLoading: isLoadingGetMe } = useGetMe({});
  const { data: organization, isLoading: isLoadingOrganization } = useOrganization(
    currentUser.organizationId,
  );

  const [returnPathname] = useState(
    history && history.location.state ? history.location.state.orginalPathname : "/jobs",
  );

  const planBasedBillingEnabled = useFeatureFlipper()({ feature: Features.PLAN_BASED_BILLING });

  window.logger("%c[AccountContainer] render", "color: #19FFD2; background: green;", {
    history,
    me,
    organizationId: me.organizationId,
    returnPathname,
  });

  const memberPathNames = {
    "/hire/settings/message_templates": "Message templates",
    "/hire/settings/review_templates": "Review templates",
    "/hire/settings/preferences": "User preferences",
  };

  const adminUserPathNames = {
    "/hire/settings/preferences": "User preferences",
  };

  const adminOrgPathNames = {
    "/hire/settings/organization": "Organization",
    "/hire/settings/team": "Team",
    "/hire/settings/message_templates": "Message templates",
    "/hire/settings/review_templates": "Review templates",
    "/hire/settings/jobboard": "Job board",
    "/hire/settings/categories": "Job categories",
    "/hire/settings/integrations": "Integrations",
    "/hire/settings/billing": "Plan & billing",
  };

  const accountLinks = (pathNames) =>
    Object.keys(pathNames)
      .map((url, index) => {
        let title = pathNames[url],
          key = `account-menu-item-${index}`;
        return <NavItem key={key} to={url} label={title} chevron />;
      })
      .filter((item) => item);

  const getNavItems = () => {
    if (currentOrganizationUser.isAdmin) {
      return (
        <>
          <Styled.List>{accountLinks(adminUserPathNames)}</Styled.List>
          <Styled.Divider />
          <Styled.Admin>Admin</Styled.Admin>
          <Styled.List>{accountLinks(adminOrgPathNames)}</Styled.List>
        </>
      );
    } else return <Styled.List>{accountLinks(memberPathNames)}</Styled.List>;
  };

  return (
    <>
      <NavBar
        isBack={false}
        backUrl={""}
        closeUrl={returnPathname}
        history={history}
        title="App settings"
      />
      <Styled.Container>
        <Styled.Sidebar>{getNavItems()}</Styled.Sidebar>

        <UnsavedChangesGuard
          hasUnsavedChanges={isDirty}
          navigate={(pathname) => {
            window.logger(
              "%c[AccountContainer] UnsavedChangesGuard navigate",
              "color: #1976D2",
              pathname,
            );
            setIsDirty(false);
            props.history.push(pathname);
          }}
        />

        <Styled.Content>
          {loading ? (
            <LoadingIndicator label="Loading..." />
          ) : (
            <Switch>
              <Route
                path={`/hire/settings/organization`}
                render={(renderProps) => (
                  <AccountOrganizationDetails {...props} {...renderProps} setIsDirty={setIsDirty} />
                )}
              />
              <Route
                path={`/hire/settings/team`}
                render={(renderProps) => (
                  <AccountTeam
                    {...props}
                    {...renderProps}
                    currentUser={currentUser}
                    currentOrganization={currentOrganization}
                  />
                )}
              />
              <Route
                path={`/hire/settings/integrations`}
                exact={false}
                render={(renderProps) => (
                  <AccountIntegrationsContainer
                    {...props}
                    {...renderProps}
                    currentOrganization={currentOrganization}
                  />
                )}
              />
              <Route
                path={`/hire/settings/jobboard`}
                render={(renderProps) => (
                  // <AccountJobBoardWrapper {...props} {...renderProps} setIsDirty={setIsDirty} />
                  <AccountJobBoardContainer {...props} {...renderProps} setIsDirty={setIsDirty} />
                )}
              />
              <Route
                path={`/hire/settings/categories`}
                render={(renderProps) => (
                  <AccountJobCategories {...props} {...renderProps} setIsDirty={setIsDirty} />
                )}
              />
              <Route
                path={`/hire/settings/billing`}
                render={(renderProps) =>
                  planBasedBillingEnabled ? (
                    <AccountBillingNew {...props} {...renderProps} />
                  ) : (
                    <AccountBillingOld {...props} {...renderProps} />
                  )
                }
              />
              <Route
                path={`/hire/settings/message_templates`}
                render={(renderProps) => (
                  <AccountChannelMessageTemplates
                    {...props}
                    {...renderProps}
                    setIsDirty={setIsDirty}
                  />
                )}
              />
              <Route
                path={`/hire/settings/review_templates`}
                render={(renderProps) => (
                  <AccountCommentTemplates {...props} {...renderProps} setIsDirty={setIsDirty} />
                )}
              />
              <Route
                path={`/hire/settings/preferences`}
                render={(renderProps) => (
                  <AccountPreferences {...props} {...renderProps} setIsDirty={setIsDirty} />
                )}
              />
            </Switch>
          )}
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default AccountContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: AccountContainer;
  display: flex;
  height: 100%;
  overflow-y: hidden;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 40vw;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 25%;
      flex-shrink: 1;
    }
  `;
});

Styled.List = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_List;
    margin-top: 0.375rem;
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: AccountContainer_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: 0;
  `;
});

Styled.Admin = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountContainer_Admin;
    ${[t.px(4), t.text.xs, t.text.bold, t.mb(1)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black}
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountContainer_Content;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 75%;
      flex-shrink: 1;
    }
  `;
});

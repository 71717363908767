import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { socialShareImageTypeOptions } from "@ats/src/lib/lookups";
import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import { useUpdateCareersPage } from "@shared/queryHooks/useCareersPage";

import FormSelect from "@ats/src/components/forms/FormSelect";
import FormUploader from "@ats/src/components/forms/FormUploader";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";

function JobBoardSocialShareImageUploader(props: {
  organizationId: number;
  onError: (errors: any) => void;
  errors: any;
}) {
  const { organizationId, onError, errors } = props;
  const addToast = useToastContext();
  // const { data: organization, isFetching: isFetchingOrganization } = useOrganization(
  //   organizationId,
  // );

  const { currentOrganization } = useCurrentSession(); // TODO: use the reactQuery Organization instead of the Redux organization
  const {
    mutateAsync: updateCareersPage,
    isLoading: isLoadingUpdateCareersPage,
  } = useUpdateCareersPage();

  const careersPage = currentOrganization?.careersPages[0];

  if (currentOrganization == undefined || careersPage == undefined) {
    return null;
  }

  const {
    socialShareImageUrl,
    socialShareImageFilename,
    hasSocialShareImage,
    socialShareImageType,
  } = careersPage;

  const hasFile = socialShareImageType == "social_share_image_type_default" || hasSocialShareImage;

  const onCompleteDirectUpload = async (blob) => {
    const { signed_id: socialShareImage } = blob;

    if (socialShareImage) {
      return await updateCareersPage(
        { id: careersPage.id, socialShareImage },
        {
          onSuccess: (data) => {
            addToast({ title: "Image uploaded", kind: "success" });
            return data;
          },
          onError: (data) => {
            addToast({
              title: `Could not upload`,
              kind: "danger",
            });
            onError(data);
            return null;
          },
        },
      );
    }

    // return Promise.resolve();
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid file`, kind: "warning" });
  };

  const updateOrganizationSettings = (name, value) => {
    updateCareersPage({ id: careersPage.id, socialShareImageType: value });
  };

  return (
    <>
      <FormSelect
        onChange={updateOrganizationSettings}
        name="socialShareImageType"
        label="Job board social media image"
        description="The image that will be displayed when your job board is shared on sites such as LinkedIn, Twitter, and Facebook."
        value={socialShareImageType}
        options={socialShareImageTypeOptions}
        errors={errors}
      />

      {socialShareImageType === "social_share_image_type_custom" && (
        <FormConditionalFields>
          <FormUploader
            allowAutomaticUploading
            onCompleteDirectUpload={onCompleteDirectUpload}
            onInvalidFile={onInvalidFile}
            name="socialShareImage"
            label="Custom social media image"
            description="Provide the custom image to use. It should be an 1.9:1 ratio image with dimensions of 1200 x 630. PNG and JPG file types are acceptable. If no image is provided, the default will be used."
            htmlFor="socialShareImageUploader"
            fileIsAlreadyUploaded={hasFile}
            fileName={socialShareImageFilename}
            placeholder="Upload"
            errors={errors}
            loading={isLoadingUpdateCareersPage}
            expectedFileType="image"
          />
          {hasFile ? (
            <Styled.CardPreview>
              <img src={socialShareImageUrl} />
            </Styled.CardPreview>
          ) : null}
        </FormConditionalFields>
      )}
    </>
  );
}

JobBoardSocialShareImageUploader.propTypes = {};

JobBoardSocialShareImageUploader.defaultProps = {};

export default JobBoardSocialShareImageUploader;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CardPreview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoard_CardPreview;
    ${[t.mt(-2), t.p(2), t.rounded.sm]}
    display: inline-block;
    vertical-align: top;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : 'transparent'};
    > img {
      ${[t.rounded.md]}
      max-width: 32rem;
      overflow: hidden;
    }
  `;
});

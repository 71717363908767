import React from "react";
import { useState, useRef } from "react";
import Icon from "@ats/src/components/shared/Icon";
import UserAvatar from "@ats/src/components/shared/UserAvatar";
import useOnClickOutside from "use-onclickoutside";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";
import { useGetMe } from "@shared/queryHooks/useMe";
import { useModalContext } from "@shared/context/ModalContext";
import SupportMessageModal from "@ats/src/components/modals/SupportMessageModal";
import Text from "@shared/components/Text";
import Box from "@shared/components/Box";
import breakpoint from "@shared/styles/breakpoints";

const polymerLogo = () => (
  <Logo>
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="19" viewBox="0 0 13 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55482 2.33789C4.04119 2.33789 2.00349 4.49506 2.00349 7.15607C2.00349 9.81708 4.04119 11.9743 6.55482 11.9743C9.06845 11.9743 11.1061 9.81708 11.1061 7.15607C11.1061 4.49506 9.06845 2.33789 6.55482 2.33789ZM0.114258 7.15607C0.114258 3.39049 2.99779 0.337891 6.55482 0.337891C10.1118 0.337891 12.9954 3.39049 12.9954 7.15607C12.9954 10.9217 10.1118 13.9743 6.55482 13.9743C2.99779 13.9743 0.114258 10.9217 0.114258 7.15607Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.114258 6.97425C0.114258 4.81532 1.76749 3.06516 3.80685 3.06516C5.8462 3.06516 7.49943 4.81532 7.49943 6.97425V14.4288C7.49943 16.5877 5.8462 18.3379 3.80685 18.3379C1.76749 18.3379 0.114258 16.5877 0.114258 14.4288V6.97425ZM3.80685 5.06516C2.81088 5.06516 2.00349 5.91989 2.00349 6.97425V14.4288C2.00349 15.4832 2.81088 16.3379 3.80685 16.3379C4.80281 16.3379 5.6102 15.4832 5.6102 14.4288V6.97425C5.6102 5.91989 4.80281 5.06516 3.80685 5.06516Z"
        fill="currentColor"
      />
    </svg>
    <Text as="h1" weight="bold">
      Polymer
    </Text>
  </Logo>
);

const orgToggle = (currentOrganization) => (
  <UserToggle>
    {currentOrganization.hasLogo ? (
      <OrgImageAvatar>
        <img src={currentOrganization.logoMedium} />
      </OrgImageAvatar>
    ) : (
      <OrgAvatar>
        <Text size="xs" weight="bold" align="center">
          {currentOrganization.name.charAt(0)}
        </Text>
      </OrgAvatar>
    )}
    <Text>{currentOrganization.name}</Text>
  </UserToggle>
);

const userToggle = (currentUser) => (
  <UserToggle>
    <UserAvatar size="sm" user={currentUser} />
    <Text>{currentUser.fullName}</Text>
  </UserToggle>
);

const DropdownMenu = ({ label, hpos = "right", ...props }) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    handleClickOutside();
  });

  const handleClickOutside = () => {
    if (dropdownIsVisible) {
      setDropdownIsVisible(false);
    }
  };

  const toggleDropdownVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownIsVisible(!dropdownIsVisible);
  };

  // closes the dropdown via a click on an intem in the dropdown
  const closeDropdown = (e) => {
    e.stopPropagation();
    handleClickOutside();
  };

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.95)",
      pointerEvents: "none",
    },
    to: {
      opacity: dropdownIsVisible ? 1 : 0,
      transform: dropdownIsVisible ? "scale(1)" : "scale(0.95)",
      pointerEvents: dropdownIsVisible ? "all" : "none",
    },
    config: {
      tension: 480,
      clamp: true,
    },
  });

  return (
    <DropdownContainer ref={ref} data-testid={props.testid || "bar-dropdown-menu"}>
      <DropdownToggle
        onClick={toggleDropdownVisibility}
        aria-haspopup="true"
        aria-expanded="false"
        data-testid="bar-dropdown-toggle-button"
      >
        {label}
      </DropdownToggle>

      <Dropdown
        hpos={hpos}
        style={spring}
        onClick={closeDropdown}
        data-testid="bar-dropdown-menu-list"
      >
        <DropdownContent>{props.children}</DropdownContent>
      </Dropdown>
    </DropdownContainer>
  );
};

export default function PolymerBar() {
  const { data: currentUser } = useGetMe({});
  const { openModal, removeModal } = useModalContext();

  const handleChangelogClick = () => {
    window.open("https://polymer.co/changelog", "_blank");
  };

  const handleHelpClick = () => {
    window.open("https://help.polymer.co", "_blank");
  };

  const handleSendSupportMessageClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const modal = <SupportMessageModal onCancel={removeModal} />;

    openModal(modal);
  };

  return (
    <Container>
      <Logo>{polymerLogo()}</Logo>
      {/* <ul>
        <li>
          <DropdownMenu label={polymerLogo()} testid="logo-dropdown-menu">
            <a>Polymer website</a>
            <a>Changelog</a>
          </DropdownMenu>
        </li>
        <li>
          <DropdownMenu label="Profile" testid="profile-dropdown-menu">
            <a>Edit profile</a>
          </DropdownMenu>
        </li>
        <li>
          <DropdownMenu label="Organizations" testid="organizations-dropdown-menu">
            <a>Your organizations</a>
            <a>Create new organization</a>
          </DropdownMenu>
        </li>
        <li>
          <DropdownMenu label="Apps" testid="apps-dropdown-menu">
            <a>Team (coming soon)</a>
            <a>Hiring</a>
          </DropdownMenu>
        </li>
        <li>
          <DropdownMenu label="Help" testid="help-dropdown-menu">
            <a>Support docs</a>
          </DropdownMenu>
        </li>
      </ul> */}
      <ul>
        {/* <DropdownMenu hpos="left" label={orgToggle(currentOrganization)} testid="org-dropdown-menu">
          <a href="/organization/manage">Your organizations</a>
        </DropdownMenu> */}
        <DropdownMenu hpos="left" label={userToggle(currentUser)}>
          <a href="/account">Account settings</a>
          <Divider />

          {currentUser.currentOrganizationUser?.role === "god_admin" && (
            <>
              <a href="/admin/dashboard">Admin - Dashboard</a>
            </>
          )}

          <button onClick={handleSendSupportMessageClick}>Contact us</button>
          <button onClick={handleHelpClick}>
            Help docs
            <Icon name="external-link" />
          </button>
          <button onClick={handleChangelogClick}>
            Changelog
            <Icon name="external-link" />
          </button>
          <Divider />
          <a href="/logout">Log out</a>
        </DropdownMenu>
      </ul>
    </Container>
  );
}

/* Styled Components
======================================================= */

const Container = styled(Box)`
  padding: 0 4px;
  min-height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props: any) => props.theme.poly.color.border};

  ul {
    display: flex;
    align-items: center;
  }

  ${breakpoint.sm} {
    min-height: 32px;
  }
`;

const Logo = styled(Box)`
  display: flex;
  align-items: center;
  color: ${(props: any) => props.theme.poly.color.primaryText};

  svg {
    margin-left: 14px;
    margin-right: 10px;
    height: 21px;
    width: auto;
  }
`;

const DropdownContainer = styled(Box)`
  ${breakpoint.sm} {
    position: relative;
  }
`;

const Dropdown = styled(animated(Box))(
  (props: any) => `
  top: 39px;
  transform-origin: top;
  background-color: ${props.theme.poly.color.dropdownCanvas};
  border: 1px solid ${props.theme.poly.color.dropdownBorder};
  border-radius: ${props.theme.poly.radii.sm};
  padding: 4px;
  z-index: 21;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  ${
    props.hpos === "right"
      ? `
        left: 8px;
        transform-origin: top left;
      `
      : `
        right: 8px;
        transform-origin: top right;
      `
  };

  ${breakpoint.sm} {
    top: 31px;
    ${
      props.hpos === "right"
        ? `
          left: 0;
        `
        : `
          right: 0;
        `
    };
  }
`,
);

const DropdownToggle = styled(Box)`
  padding: 0 12px;
  border-radius: ${(props: any) => props.theme.poly.radii.xs};
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props: any) => props.theme.poly.color.secondaryText};

  ${breakpoint.sm} {
    height: 31px;

    &:hover {
      color: ${(props: any) => props.theme.poly.color.primaryText};
      background-color: ${(props: any) => props.theme.poly.color.subtleHover};
      cursor: pointer;
    }
  }
`;

const UserToggle = styled(Box)`
  display: flex;
  align-items: center;

  ${Text} {
    display: none;
  }

  svg {
    margin-left: 4px;
    margin-right: -2px;
  }

  ${breakpoint.sm} {
    ${Text} {
      display: inline;
      margin-left: 8px;
      color: ${(props: any) => props.theme.poly.color.primaryText};
    }
  }
`;

const OrgImageAvatar = styled(Box)`
  height: 24px;
  width: 24px;
  border-radius: ${(props: any) => props.theme.poly.radii.sm};
  overflow: hidden;
  flex-shrink: 0;
  transition: opacity 0.2s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const OrgAvatar = styled(Box)`
  height: 24px;
  width: 24px;
  border-radius: ${(props: any) => props.theme.poly.radii.sm};
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #525252;
  text-transform: uppercase;
  flex-shrink: 0;
  transition: opacity 0.2s ease;

  ${Text} {
    margin-left: 0;
    color: #ffffff;
  }
`;

const DropdownContent = styled(Box)`
  display: flex;
  flex-direction: column;

  > span,
  a,
  button,
  a:link,
  a:visited {
    border-radius: ${(props: any) => props.theme.poly.radii.xs};
    padding-left: 12px;
    padding-right: 24px;
    height: 40px;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${(props: any) => props.theme.poly.color.primaryText};
    font-weight: 400;
    transition: background-color 0.2s ease;
    &:focus {
      color: ${(props: any) => props.theme.poly.color.dropdownTextHover};
      background-color: ${(props: any) => props.theme.poly.color.dropdownHover};
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }

    ${breakpoint.sm} {
      height: 28px;
      padding-left: 10px;
      &:hover {
        color: ${(props: any) => props.theme.poly.color.dropdownTextHover};
        background-color: ${(props: any) => props.theme.poly.color.dropdownHover};
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }
    }
  }

  svg {
    flex-shrink: 0;
    margin-left: 6px;
    height: 1.125em;
    width: 1.125em;
    color: ${(props: any) => props.theme.poly.color.secondaryText};
  }
`;

const Divider = styled(Box)`
  height: 1px;
  margin: 4px -4px;
  background-color: ${(props: any) => props.theme.poly.color.dropdownDivider};
`;

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useToastContext } from "@shared/context/ToastContext";

import Button from "@ats/src/components/shared/Button";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";

import { useResendEmailConfirmation } from "@shared/queryHooks/useMe";

function NeedsEmailConfirmation(props) {
  const addToast = useToastContext();
  const { currentUser, currentOrganization } = props;

  /* Queries
  --===================================================-- */
  const {
    mutate: resendEmailConfirmation,
    isLoading: isLoadingResendEmailConfirmation,
  } = useResendEmailConfirmation();

  const sendEmailConfirmation = (event) => {
    event.preventDefault();

    resendEmailConfirmation(null, {
      onSuccess: (data) => {
        addToast({
          title: `Confirmation email sent`,
          kind: "success",
        });
      },
    });
  };

  if (currentUser == null) {
    window.logger("%c[NeedsEmailConfirmation] RENDER", "color: #d21947", {
      currentUser,
      currentOrganization,
    });
    return null;
  }

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        <Styled.Form>
          <Helmet title="Email confirmation" />
          <h2>Confirmation email sent</h2>
          <Styled.Directions>
            <p>We sent an email to {currentUser.email}.</p>
            <p>
              In that email you'll find a link that will bring you back to the site, confirm your
              account, and let you get started.
            </p>
            <p>
              If for some reason you do not receive the confirmation email, contact us at{" "}
              <a href="mailto:support@polymer.co">support@polymer.co</a>.
            </p>
          </Styled.Directions>
        </Styled.Form>
        <Styled.Links>
          <Link to="/logout">Log out</Link>
          <Button styleType="text" onClick={sendEmailConfirmation}>
            Re-send email
          </Button>
        </Styled.Links>

        {currentUser.confirmationUrl != undefined && (
          <Styled.Directions>
            <h2>**** DEVELOPMENT ONLY WORKAROUND ****</h2>
            <a href={currentUser.confirmationUrl}>Click here to confirm email</a>
          </Styled.Directions>
        )}
      </Styled.UI>
    </Styled.Container>
  );
}

export default NeedsEmailConfirmation;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import LoginForm from "@ats/src/views/sessions/components/LoginForm";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";
import Banner from "@ats/src/components/shared/Banner";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

function Login(props) {
  const [emailConfirmed, setEmailConfirmed] = React.useState(false);

  React.useEffect(() => {
    showEmailConfirmationBannerIfApplicable();
  }, []);

  const showEmailConfirmationBannerIfApplicable = () => {
    let emailConfirmedParam = queryString.parse(props.location.search).email_confirmed;

    if (emailConfirmedParam != undefined) {
      const emailConfirmed = emailConfirmedParam === "true";
      setEmailConfirmed(emailConfirmed);
    }
  };

  const loginComplete = (user) => {
    props.history.push("/jobs");
  };

  return (
    <Styled.Container>
      <HeaderBar />
      {emailConfirmed && <Banner message="Email address confirmed. Log in to continue." />}
      <Styled.UI>
        {/* <Announcement>
          <p>Looking for <b>Wrk</b>? We recently rebranded to <b>Polymer</b>!</p>
          <Button styleType="secondary" type="externalLink" link="https://polymer.co/blog/hello-polymer" target="_self">
            Read the announcement
          </Button>
        </Announcement> */}
        <Styled.Form>
          <Helmet title="Login" />
          <h2>Login</h2>
          <div>
            <LoginForm onComplete={loginComplete} />
          </div>
        </Styled.Form>
        <Styled.Links>
          <Link to="/register">Don&apos;t have an account?</Link>
          <Link to="/request-password-reset">Forgot your password?</Link>
        </Styled.Links>
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(Login);

/* Styled Components
======================================================= */

// const Announcement = styled.div((props) => {
//   const t: any = props.theme;

//   return css`
//     label: Announcement;
//     ${[t.m(4), t.mb(4), t.p(4), t.rounded.md]}
//     color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
//     border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
//     background-color: ${t.dark ? t.color.gray[800] : t.color.white};
//     line-height: 1.4;
//     flex: 1;

//     ${t.mq["lg"]} {
//       ${[t.mt(-12), t.mb(8), t.p(6)]}
//       width: 25rem;
//     }

//     b {
//       ${t.text.bold}
//       color: ${t.dark ? t.color.gray[300] : t.color.black}
//     }

//     p {
//       ${t.mb(5)}
//     }
//   `;
// });

import { css } from "@emotion/react";
import t from "@ats/styles/theme";

const textStyles = css`
  line-height: 1.6;

  h1 {
    ${[t.text.h2, t.mt(8), t.mb(2)]}
    line-height: 1.3;
    &:first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
      ${t.mt(0)}
    }
  }

  h2 {
    ${[t.text.h3, t.mt(6), t.mb(2)]}
    line-height: 1.4;
    &:first-child {
      ${t.mt(0)}
    }
  }

  h3 {
    ${[t.text.h4, t.mt(4), t.mb(1)]}
    line-height: 1.4;
    &:first-child {
      ${t.mt(0)}
    }
  }

  h4 {
    ${[t.text.h5, t.mt(4), t.mb(1)]}
    line-height: 1.5;
    &:first-child {
      ${t.mt(0)}
    }
  }

  h5 {
    ${[t.text.h6, t.mt(4), t.mb(1)]}
    line-height: 1.6;
    &:first-child {
      ${t.mt(0)}
    }
  }

  h6 {
    ${[t.text.xs, t.mt(4), t.text.bold, t.mb(1)]}
    line-height: 1.6;
    &:first-child {
      ${t.mt(0)}
    }
  }

  p {
    ${t.mb(3)}
    line-height: 1.6;
  }

  b,
  strong {
    ${t.text.bold}
  }

  a {
    ${t.text.medium}
    text-decoration: underline;
  }

  blockquote {
    ${[t.text.secondary, t.mb(3), t.pl(3)]}
    border-left: 4px solid ${t.color.gray[200]};
    line-height: 1.6;
  }

  ul {
    ${[t.mb(3), t.pl(8)]}
    list-style-type: disc;
    line-height: 1.6;

    p {
      ${t.mb(0)}
    }
  }

  ol {
    ${[t.mb(3), t.pl(8)]}
    list-style-type: decimal;
    line-height: 1.6;

    p {
      ${t.mb(0)}
    }
  }

  ul ul,
  ol ul {
    ${t.mb(1)}
    margin-top: 0.375rem;
    padding-left: 1.2rem;
    list-style-type: circle;
  }

  ol ol,
  ul ol {
    ${t.mb(1)}
    margin-top: 0.375rem;
    padding-left: 1.2rem;
    list-style-type: lower-latin;
  }

  li {
    margin-bottom: 0.375rem;
  }

  img {
    margin: 16px 0;
    max-width: 100%;
    border-radius: 7px;
    overflow: hidden;
    &:first-child {
      margin-top: 0;
    }
  }

  code {
    overflow-x: scroll;
    white-space: pre-wrap;
  }

  .wrk-iframe-container {
    display: block;
    position: relative;
    padding-top: 56.25%;
    margin: 16px 0;
    max-width: 100%;
    border-radius: 7px;
    overflow: hidden;
    &:first-child {
      margin-top: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${t.color.gray[400]};
  }
`;

export default textStyles;

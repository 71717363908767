import React from "react";
import debounce from "lodash/debounce";

type State = {
  dimensions: {
    height: number;
    width: number;
  };
  size: string;
};

type WindowSizeProviderProps = { children: React.ReactNode };

const WindowSizeContext = React.createContext<State | undefined>(undefined);

function WindowSizeProvider({ children }: WindowSizeProviderProps) {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const size = () => {
    if (dimensions.width >= 1536) return "2xl";
    else if (dimensions.width >= 1280) return "xl";
    else if (dimensions.width >= 1024) return "lg";
    else if (dimensions.width >= 768) return "md";
    else return "sm";
  };

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <WindowSizeContext.Provider value={{ dimensions, size: size() }}>
      {children}
    </WindowSizeContext.Provider>
  );
}

function useWindowSize() {
  const context = React.useContext(WindowSizeContext);
  if (context === undefined) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }
  return context;
}

export { WindowSizeProvider, useWindowSize };

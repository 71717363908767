import { ChangeEvent, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FileTypes, isAllowedFileSize, isAllowedFileType } from "@ats/src/lib/utils/fileUploads";
import Icon from "@ats/src/components/shared/Icon";
import FormLabel from "@ats/src/components/forms/FormLabel";

interface FormFileProps {
  maxFileSize?: number;
  expectedFileType: FileTypes;
  onChange: (f: File) => void;
  label?: string;
  description?: string;
}

function FormFile({ expectedFileType, maxFileSize, onChange, label, description }: FormFileProps) {
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const onChangeUploader = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const isValid = isValidFile(file);
    setSelectedFile(file);

    if (!isValid) {
      onChange(null);
    } else {
      onChange(file);
    }
  };

  const isValidFile = (file: File | null): boolean => {
    if (!file) {
      setErrors([]);
      return true;
    }

    const newErrors = [];

    if (!isAllowedFileSize(maxFileSize, file.size)) {
      newErrors.push(`File size must be less than ${maxFileSize}KB.`);
    } else if (!isAllowedFileType(expectedFileType, file.type)) {
      newErrors.push(`File must be a ${expectedFileType}.`);
    }
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  return (
    <div>
      <StyledContainer>
        {label && <FormLabel label={label} />}
        {description && <p>{description}</p>}
        <StyledLabel selectedFile={selectedFile} htmlFor="input-file">
          <StyledInput id="input-file" type="file" name="input-file" onChange={onChangeUploader} />
          {selectedFile ? (
            <>
              <Icon name="paperclip" />
              <span>{selectedFile.name}</span>
            </>
          ) : (
            <>Upload</>
          )}
        </StyledLabel>
      </StyledContainer>
      {errors.length > 0 && <StyledErrors>{errors.join(" ")}</StyledErrors>}
    </div>
  );
}

export default FormFile;

const StyledContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > p {
      ${[t.text.sm, t.mt(-1), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq["md"]} {
        ${t.text.xs}
      }
    }
  `
});

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label((props: any) => {
  const t: any = props.theme;

  const base = css`
    border: none;
    appearance: none;
    outline: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
  `;

  return css`
    ${[t.h(10), t.text.base, t.rounded.sm, base]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    svg {
      margin-right: ${t.spacing[1]};
      flex-shrink: 0;
    }

    ${t.mq["md"]} {
      ${[t.h(8), t.text.sm]}
    }

    ${props.selectedFile
      ? css`
          ${[t.px(2)]}
          width: 100%;
          border: 1px solid ${t.dark ? "transparent" : t.color.gray[300]};
          background-color: ${t.dark ? "rgba(255,255,255,0.08)" : t.color.white};
          transition: background-color 0.2s ease, border-color 0.2s ease;
          &:hover {
            border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
          }
        `
      : css`
          ${[t.px(4), t.text.medium]}
          background-color: ${t.dark ? t.color.gray[700] : t.color.gray[200]};
          transition: background-color 0.2s ease;
          &:hover {
            background-color: ${t.dark ? t.color.gray[600] : t.color.gray[300]};
            color: ${t.dark ? t.color.white : t.color.black};
          }
        `}
  `;
});

const StyledErrors = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.p(2), t.my(6), t.rounded.sm]}
    border: 1px solid ${t.dark ? t.color.red[400] : t.color.red[600]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.red[100]};
    color: ${t.dark ? t.color.red[400] : t.color.red[600]};
  `;
});

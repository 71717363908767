import React from "react";
import PropTypes from "prop-types";
import Text from "@shared/components/Text";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function FormSection(props) {
  const { id, title, intro, children } = props;
  return (
    <Styled.Container>
      {title !== undefined && (
        <Styled.Intro id={id}>
          <Text as="h4" size="md" weight="bold">{title}</Text>
          {intro ? <p>{intro}</p> : null}
        </Styled.Intro>
      )}
      {children}
    </Styled.Container>
  );
}

FormSection.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  intro: PropTypes.element,
};

FormSection.defaultProps = {};

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

Styled.Intro = styled.div`
  margin-bottom: 16px;
  color: ${(props) => props.theme.poly.color.primaryText};
  p {
    margin-top: 8px;
    color: ${(props) => props.theme.poly.color.secondaryText};
  }
`;

import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Tooltip from "@ats/src/components/shared/Tooltip";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import { jobSettingNames, questionKindNames } from "@ats/src/lib/lookups";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";

import { useModalContext } from "@shared/context/ModalContext";
import { VisibilitySetting } from "@shared/types/jobApplication";

/*
--===================================================-- */

export default function QuestionListItem(props) {
  const { openModal, removeModal } = useModalContext();
  const { question, index } = props;
  const { questionText, kind, requirementSetting, options, visibilitySetting } = question;

  const openQuestionModal = (e) => {
    e.preventDefault();

    props.openEditCustomQuestionModal(props.question);
  };

  const deleteQuestion = () => {
    props.deleteQuestion(props.question);
  };

  const handleClickOnDeleteQuestion = (e) => {
    e.preventDefault();

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this question?"
        subcopy="It will be removed from this job's application form."
        confirmText="Delete question"
        cancelText="Keep question"
        onConfirm={() => {
          removeModal();
          deleteQuestion();
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  window.logger("%c[QuestionListItem] question", "background-color: #19d285", { question });

  return (
    <Draggable
      draggableId={question.id} // required prop
      index={index} // required prop
    >
      {(provided) => {
        return (
          <Styled.Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div>
              <Styled.Question>
                <Styled.QuestionText>{questionText}</Styled.QuestionText>
                {visibilitySetting == VisibilitySetting.PRIVATE && (
                  <Tooltip label="Only admins can view candidate answers to this question.  Editing or deleting this question requires admin access.">
                    <Styled.Label key="draft">Admin only</Styled.Label>
                  </Tooltip>
                )}
              </Styled.Question>
              <Styled.QuestionDetails>{`${questionKindNames(kind)}, ${jobSettingNames(
                requirementSetting,
              )}`}</Styled.QuestionDetails>
              {options.data.length > 0 && (
                <Styled.Options>
                  {options.data.map((option, index) => {
                    return <li key={`${option}-${index}`}>{option}</li>;
                  })}
                </Styled.Options>
              )}
            </div>
            <Styled.DropdownMenu>
              <DropdownMenu label="Options">
                <a href="" onClick={openQuestionModal}>
                  Edit question
                </a>
                <a href="" onClick={handleClickOnDeleteQuestion}>
                  Delete question
                </a>
              </DropdownMenu>
            </Styled.DropdownMenu>
          </Styled.Container>
        );
      }}
    </Draggable>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    transition: border-color 0.2s ease;
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    &:hover,
    &:active {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
      z-index: 16;
    }
  `;
});

Styled.QuestionText = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_QuestionText;
    ${t.my(1)}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.QuestionDetails = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_QuestionDetails;
    ${[t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Options = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_Options;
    ${[t.ml(6), t.mt(1), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    list-style-type: circle;

    li {
      ${[t.pt(1), t.pb("px")]}

      &:last-of-type {
        ${t.pb(0)}
      }
    }
  `;
});

Styled.DropdownMenu = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_DropdownMenu;
    ${[t.mt("px"), t.pt("px")]}
  `;
});

Styled.Question = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_Question;
    display: flex;
    ${[t.mb(1)]}
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Label;
    ${[t.mt("-px"), t.mx(2), t.h(6), t.px(2), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
  `;
});

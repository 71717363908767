import React, { useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useCreateFeatureFlag } from "@shared/queryHooks/useFeatureFlippers";

import { validateNewFeatureFlag } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  history: any;
};

function AdminNewFeatureFlagModal(props: Props) {
  // window.logger("%c[AdminNewFeatureFlagModal] ", "color: #1976D2", { props });
  const [name, setName] = useState("");
  const [errors, setErrors] = useState([]);
  const { mutate: createFeatureFlag, isLoading: isLoadingCreateFeatureFlag } = useCreateFeatureFlag();
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeName = (_, value) => {
    setName(value);
    setErrors([]);
    setIsDirty(true);
  };

  const handleSubmitCreateFeatureFlag = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateNewFeatureFlag({ name });

    if (isValid) {
      createFeatureFlag(
        { name },
        {
          onSuccess: (data) => {
            window.logger("%c[AdminNewFeatureFlagModal] createFeatureFlag onSuccess", "color: #1976D2", { data });
            props.onCancel();
            props.history.push(`/admin/flippers`);
          },
          onError: (response) => {
            window.logger("%c[AdminNewFeatureFlagModal] createFeatureFlag onError", "color: #ad1b47", {
              response,
            });
            // setErrors(response.errors);
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createFeatureFlag"
        type="submit"
        onClick={handleSubmitCreateFeatureFlag}
        loading={isLoadingCreateFeatureFlag}
      >
        Create feature flag
      </Button>
      <Button styleType="secondary" onClick={props.onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText="Create New Feature Flag" onCancel={props.onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Enter the name of the feature flag you are creating. The current naming convention for Polymer. uses uppercase and underscores such as: FEATURE_FLAG.
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormInput
          onChange={handleChangeName}
          name="name"
          placeholder="NEW_FEATURE_FLAG"
          label="Feature flag name"
          value={name}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default AdminNewFeatureFlagModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: AdminNewFeatureFlagModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminNewFeatureFlagModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

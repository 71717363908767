import { useMutation } from "react-query";
import { apiPost } from "@shared/queryHooks/api";


const searchCandidates = async (candidateEmail) => {
  return await apiPost({
    path: `/admin/candidates/search`,
    variables: { searchTerm: candidateEmail },
  });
};

/* Hooks
--===================================================-- */

export function useSearchCandidates(): {
  mutate: any;
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useMutation(searchCandidates);
}
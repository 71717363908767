import React, { useState } from "react";
import { Route, Redirect, NavLink, Link, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { removeHttpPrefix } from "@shared/lib/utils";

import Icon from "@ats/src/components/shared/Icon";
import NavItem from "@ats/src/components/shared/NavItem";
import Button from "@ats/src/components/shared/Button";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

// import Original from "./Original";
import AccountJobBoardBranding from "./AccountJobBoardBranding";
import AccountJobBoardSeo from "./AccountJobBoardSeo";
import AccountJobBoardNavigation from "./AccountJobBoardNavigation";
import AccountJobBoardContent from "./AccountJobBoardContent";
import AccountJobBoardCustomDomain from "./AccountJobBoardCustomDomain";
import AccountJobBoardEmbed from "./AccountJobBoardEmbed";

import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { getTimestamp } from "@shared/queryHooks/useOrganization";

function AccountJobBoardContainer(props) {
  window.logger(
    "%c[AccountJobBoardContainer] RENDER",
    "background: #EFDDEF; color: #1976D2",
    props,
  );
  const { match } = props;

  const [isDirty, setIsDirty] = useState(false);
  const isAuthorized = useAuthorization();
  const authorized = isAuthorized({ adminOnly: true });

  const { currentOrganization } = useCurrentSession();

  if (!authorized) {
    return null;
  }

  if (currentOrganization == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  // After the loading to ensure it exists
  const { stripeSubscriptionInGoodStanding } = currentOrganization;

  const viewLink = () => {
    if (stripeSubscriptionInGoodStanding) {
      return (
        <Styled.PreviewLink>
          <Icon name="link" />
          <Link to={currentOrganization?.careersPageUrl} target="_blank" rel="noopener">
            {removeHttpPrefix(currentOrganization?.careersPageUrl)}
          </Link>
        </Styled.PreviewLink>
      );
    } else {
      return (
        <Styled.PreviewLink>
          <Icon name="external-link" />
          <Button styleType="text" onClick={handleClickOnViewPreview}>
            Preview job board
          </Button>
        </Styled.PreviewLink>
      );
    }
  };

  const handleClickOnViewPreview = () => {
    getTimestamp().then((data) => {
      window.logger("%c[AccountJobBoard] handleClickOnViewPreview getTimestamp", "color: #1976D2", {
        data,
      });
      window.open(`${currentOrganization?.careersPageUrl}/preview/${data.timestamp}`, "_blank");
    });
  };

  return (
    <>
      <Helmet title="Job board" />
      <Styled.Container>
        <Styled.Sidebar>
          <Styled.List>
            <NavItem to={`${match.url}/branding`} label="Branding" chevron />
            <NavItem to={`${match.url}/content`} label="Content" chevron />
            <NavItem to={`${match.url}/seo`} label="SEO" chevron />
            <NavItem to={`${match.url}/navigation`} label="Navigation" chevron />

            {/* <FeatureFlipper feature="CUSTOM_DOMAINS"> */}
            <NavItem to={`${match.url}/customdomain`} label="Custom domain" chevron />
            <NavItem to={`${match.url}/embed`} label="Embed" chevron />
            {/* </FeatureFlipper> */}
          </Styled.List>

          <Styled.Divider />
          <Styled.Preview>
            <Styled.PreviewTitle>Job board</Styled.PreviewTitle>
            {viewLink()}
          </Styled.Preview>
        </Styled.Sidebar>

        <UnsavedChangesGuard
          hasUnsavedChanges={isDirty}
          navigate={(pathname) => {
            window.logger(
              "%c[AccountJobBoardContainer] UnsavedChangesGuard navigate",
              "color: #1976D2",
              pathname,
            );
            setIsDirty(false);
            props.history.push(pathname);
          }}
        />

        <Styled.Content>
          <Switch>
            {/* <Route
              path={`${match.path}/original`}
              render={(renderProps) => (
                <Original
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            /> */}
            <Route
              path={`${match.path}/branding`}
              render={(renderProps) => (
                <AccountJobBoardBranding
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/content`}
              render={(renderProps) => (
                <AccountJobBoardContent
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/seo`}
              render={(renderProps) => (
                <AccountJobBoardSeo
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/customdomain`}
              render={(renderProps) => (
                <AccountJobBoardCustomDomain
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/navigation`}
              render={(renderProps) => (
                <AccountJobBoardNavigation
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/embed`}
              render={(renderProps) => (
                <AccountJobBoardEmbed
                  {...props}
                  {...renderProps}
                  currentOrganization={currentOrganization}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Redirect to={`${match.url}/branding`} />
          </Switch>
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default AccountJobBoardContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: AccountJobBoardContainer;
  display: flex;
  height: 100%;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    width: 40vw;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 33.333%;
      flex-shrink: 1;
    }
  `;
});

Styled.List = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_List;
    margin-top: 0.375rem;
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_Content;
    height: 100%;
    overflow-y: auto;
    position: relative;
    width: 100vw;
    flex-shrink: 0;

    ${t.mq["lg"]} {
      width: 66.666%;
      flex-shrink: 1;
    }
  `;
});

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoard_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});

Styled.Name = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_Name;
    display: flex;
    align-items: center;
    overflow: hidden;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: 0;
  `;
});

Styled.Preview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Preview;
    ${[t.px(4)]}
  `;
});

Styled.PreviewTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_PreviewTitle;
    ${[t.text.xs, t.text.bold, t.mb(2)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.PreviewLink = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardContainer_PreviewLink;
    ${[t.text.xs, t.h(6), t.mb("px")]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
    display: flex;
    align-items: center;
    overflow: hidden;
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    div,
    a,
    button {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      text-align: start;
      cursor: pointer;
      padding: 0;
      appearance: none;
      outline: none;
      background: transparent;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button:hover,
    button:focus,
    a:hover {
      text-decoration: underline;
    }
  `;
});

import React, { useState } from "react";
import { Helmet } from "react-helmet";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Icon from "@ats/src/components/shared/Icon";

import CommentTemplateModal from "@ats/src/components/modals/CommentTemplateModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import EmptyState from "@ats/src/components/shared/EmptyState";

import CommentTemplateList from "@ats/src/views/accountAdmin/components/CommentTemplateList";
import CommentTemplateListItem from "@ats/src/views/accountAdmin/components/CommentTemplateListItem";

import {
  useCommentTemplates,
  useUpdateCommentTemplate,
  useDeleteCommentTemplate,
} from "@shared/queryHooks/useCommentTemplate";
import { moveFromIndexToIndex } from "@shared/lib/utils";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

export default function AccountCommentTemplates(props) {
  const { status, data, error } = useCommentTemplates();
  const addToast = useToastContext();

  // throw new Error("Testing errors");

  const { openModal, removeModal } = useModalContext();

  // window.logger("%c[AccountCommentTemplates] render", "background: #EFDDEF; color: #1976D2", {
  //   props,
  //   status,
  // });

  const { mutate: updateCommentTemplate, isLoading: isLoadingUpdate } = useUpdateCommentTemplate();
  const { mutate: deleteCommentTemplate, isLoading: isLoadingDelete } = useDeleteCommentTemplate();

  const templateItems = data != undefined ? data.items : [];
  const hasTemplateItems = data && data.items;

  const [commentTemplates, setCommentTemplates] = useState([templateItems]);

  React.useEffect(() => {
    window.logger(
      "%c[AccountCommentTemplates] useEffect",
      "background: #FF00EF; color: #1976D2",
      data,
      templateItems,
    );
    if (hasTemplateItems) {
      setCommentTemplates(templateItems);
    }
  }, [hasTemplateItems]);

  const handleClickOnDeleteCommentTemplate = (commentTemplate) => {
    const deleteComment = `You will no longer be able to use the ${commentTemplate.name} template.`;

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to delete this template?"
        subcopy={deleteComment}
        confirmText="Delete template"
        cancelText="Keep template"
        onConfirm={() => {
          removeModal();
          handleDeleteCommentTemplate(commentTemplate);
        }}
        onCancel={removeModal}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const handleDeleteCommentTemplate = async (commentTemplate) => {
    try {
      await deleteCommentTemplate(commentTemplate);
      // commentTemplate was successfully deleted
    } catch (error) {
      // Uh oh, something went wrong
      console.error("%c[CommentTemplateModal] handle error when deleting CommentTemplate", {
        error,
      });
    }
  };

  /* CommentTemplateModal
  --===================================================-- */
  const openNewCommentTemplateModal = (event) => {
    event.preventDefault();
    openCommentTemplateModal({}, false);
  };

  const openEditCommentTemplateModal = (commentTemplate) => {
    openCommentTemplateModal(commentTemplate, true);
  };

  const openCommentTemplateModal = (commentTemplate, isEditing) => {
    const modal = (
      <CommentTemplateModal
        onCancel={onCloseModal}
        // onSubmit={handleSubmitForm}
        isEditing={isEditing}
        commentTemplate={commentTemplate}
      />
    );
    openModal(modal);
  };

  const onCloseModal = () => {
    // window.logger("%c[AccountCommentTemplate] onCloseModal", "color: #1976D2", {});
    removeModal();
  };

  /* onDragEndCommentTemplate
  --===================================================-- */
  const onDragEndCommentTemplate = (result: {
    destination: any;
    source: any;
    draggableId: number;
  }) => {
    window.logger(
      "%c[AccountCommentTemplates] onDragEndCommentTemplate result",
      "color: #1976D2",
      result,
    );
    // eslint-disable-next-line no-unused-vars
    const { destination, source, draggableId } = result;

    // If there is no destinatiom then do nothing
    if (!destination) return;

    // If the user drops the item back in its original spot then do nothing
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const commentTemplate: { id: number } = data.items[source.index];
    const position: number = destination.index + 1;

    const newCommentTemplates = moveFromIndexToIndex(
      commentTemplates,
      source.index,
      destination.index,
    );

    setCommentTemplates(newCommentTemplates);

    updateCommentTemplate({ ...commentTemplate, position });
  };

  /* CommentTemplate nodes
  --===================================================-- */
  const commentTemplateNodes = () => {
    // window.logger(
    //   "%c[AccountCommentTemplates] commentTemplateNodes",
    //   "color: #19FFD2",
    //   { data },
    // );

    if (commentTemplates.length === 0) {
      return <EmptyState message="No review templates have been added" />;
    } else {
      return commentTemplates.map((commentTemplate: { name: string }, index: number) => {
        return (
          <CommentTemplateListItem
            key={`${index}_${commentTemplate.name}`}
            index={index}
            commentTemplate={commentTemplate}
            openEditCommentTemplateModal={openEditCommentTemplateModal}
            deleteCommentTemplate={handleClickOnDeleteCommentTemplate}
          />
        );
      });
    }
  };

  if (status === "loading") {
    return <LoadingIndicator label="Loading..." />;
  }

  const testingErrorBoundary = undefined;

  const viewLink = (
    <Button
      type="externalLink"
      link="https://help.polymer.co/en/articles/5506525-using-review-templates"
      styleType="text"
    >
      View docs
      <Icon name="external-link" />
    </Button>
  );

  return (
    <>
      <Helmet title="Review templates" />
      <SettingsContainer
        title="Review templates"
        actions={viewLink}
        description="These templates enable you to encourage structured feedback when team members conduct a candidate review. Changes to the ordering of the templates displayed here will affect how they are listed when selecting one for use."
      >
        {/* {testingErrorBoundary.test} */}
        <FormSection>
          <CommentTemplateList onDragEndCommentTemplate={onDragEndCommentTemplate}>
            {commentTemplateNodes()}
          </CommentTemplateList>

          <Button styleType="secondary" onClick={openNewCommentTemplateModal}>
            Add a template
          </Button>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

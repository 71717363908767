import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormInput from "@ats/src/components/forms/FormInput";
import EmptyState from "@ats/src/components/shared/EmptyState";
import Button from "@ats/src/components/shared/Button";

import { useSearchUsers } from "@shared/queryHooks/admin/useUser";
import { useBecomeUser } from "@shared/queryHooks/admin/useBecome";

function AdminUsersSearch(props: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // QUERY HOOKS
  const {
    mutate: searchUsers,
    data: userSearchData,
    isLoading: isFetchingUserResults,
    isSuccess: isSuccessUserResults,
  } = useSearchUsers();

  const { mutate: becomeUser, isLoading: isLoadingBecome } = useBecomeUser();

  window.logger("%c[AdminUsersSearch] render", "color: #1976D2", {
    props,
    userSearchData,
    isFetchingUserResults,
  });

  // handleBecome
  const handleBecomeUser = (userId) => {
    window.logger("%c[AdminDashboardCustomers] handleBecomeUser userId", "color: #1976D2", userId);

    becomeUser(
      { userId },
      {
        onSuccess: (data) => {
          window.logger("%c[AdminUsersSearch] becomeUser onSuccess", "background-color: #c6d219", {
            data,
          });
          window.location.href = `${window.APP_ATS_ROOT_URL}/jobs`;
        },
        onError: (response: any) => {
          window.logger("[AdminUsersSearch] becomeUser server error", { response });
          if (response.data.errors) {
            // setState({ ...state, errors: response.data.errors });
          }
        },
      },
    );
  };

  // onChangeSearch
  const onChangeSearchUsers = (_, value) => {
    setSearchTerm(value);
    if (value.length > 0) {
      searchUsers(value, {
        onSuccess: (data) => {
          window.logger("%c[AdminUsersSearch] onChangeSearchUsers", "color: #1976D2", { data });
          setSearchResults(data.items);
        },
      });
    } else {
      setSearchResults([]);
    }
  };

  const noResults = () => {
    return (
      <EmptyState
        icon="alert-triangle"
        title="No search results to show."
        message="Search by user id or by user email address."
      />
    );
  };

  //actual list items
  const userNodes = () => {
    if (!isSuccessUserResults || userSearchData == undefined) {
      return [];
    }

    const users =
      searchResults.length > 0 && searchTerm.length > 0 ? searchResults : userSearchData.items;

    window.logger("%c[AdminUsersSearch] userNodes", "color: #1976D2", {
      users,
    });

    return users.map(function (user) {
      window.logger("%c[AdminUsersSearch] userNode ", "color: #1976D2", {
        user,
      });

      let disableBecome = user?.email === "system@inflowhq.com";

      return (
        <tr className="admin-user-list__item" key={user?.id}>
          <td>{user?.id}</td>
          <td>{user?.fullName}</td>
          <td>{user?.email}</td>
          <td style={{ color: `${user?.hasConfirmedEmail ? "green" : "red"}` }}>
            {user?.hasConfirmedEmail ? "Confirmed" : "Unconfirmed"}
          </td>
          <td>{user?.currentOrganizationUser ? user?.currentOrganizationUser.role : "null"}</td>
          <td>
            {user?.currentOrganizationUser
              ? user?.currentOrganizationUserId
              : "Missing OrganizationUser"}
          </td>
          <td>{user?.organizationName}</td>

          <td>
            <Button
              styleType="text"
              type="button"
              onClick={() => handleBecomeUser(user?.id)}
              disabled={disableBecome}
            >
              Become
            </Button>
          </td>
        </tr>
      );
    });
  };

  const resultsNotEmpty = searchResults.length > 0 ? true : false;

  return (
    <Styled.Component>
      <Styled.Header>
        <h2>Search Users</h2>

        <FormInput
          name="userSearch"
          placeholder="Search"
          value={searchTerm}
          onChange={onChangeSearchUsers}
        />
      </Styled.Header>

      <Styled.Table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Confirmed Email</th>
            <th>Role</th>
            <th>Organization User Id</th>
            <th>Organization Name</th>
            {/* <th>Organization Id</th> */}
            <th />
          </tr>
          {resultsNotEmpty ? userNodes() : null}
        </tbody>
      </Styled.Table>
      {!resultsNotEmpty && noResults()}
    </Styled.Component>
  );
}

AdminUsersSearch.propTypes = {};

AdminUsersSearch.defaultProps = {};

export default AdminUsersSearch;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminUsersSearch;
    ${t.p(4)};
  `;
});

Styled.Header = styled.header((props) => {
  const t: any = props.theme;
  return css`
    label: AdminUsersSearch_Header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      ${[t.mt(1), t.text.h3, t.mb(2)]};
      display: flex;
      align-items: center;
    }
    span {
      ${[t.ml(2), t.text.sm, t.text.secondary, t.text.normal]};
    }
  `;
});

Styled.Table = styled.table((props) => {
  const t: any = props.theme;
  return css`
    label: AdminUsersSearch_Table;
    border-collapse: collapse;
    width: 100%;

    th {
      ${[t.pb(2), t.text.xs, t.text.bold]};
      text-align: start;
      ${t.text.primary};
    }

    tr {
      border-bottom: 1px solid ${t.color.gray[200]};
      ${t.text.secondary};
    }

    tr:last-of-type {
      border-bottom: none;
    }

    svg {
      stroke: ${t.color.black};
    }

    td,
    th {
      ${[t.p(2)]};

      &:first-of-type {
        ${t.pl(0)}
      }

      &:last-of-type {
        ${t.pr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import NavItem from "@ats/src/components/shared/NavItem";
import FormCheckbox from "@ats/src/components/forms/FormCheckbox";

interface Props {
  id: number;
  to: string;
  icon: string;
  label: string;
  onChange?: (id, applicationsCount) => void;
  checked?: boolean;
  applicationsCount: number;
}

function JobApplicationNavItem({ id, applicationsCount, ...props }: Props) {
  // window.logger(
  //   "%c[JobApplicationNavItem] render",
  //   "background: #EFDDEF; color: #1976D2",
  //   {
  //     id,
  //     props,
  //     applicationCount
  //   },
  // );

  const onChangeSelect = () => {
    props.onChange(id, applicationsCount);
  };

  return (
    <Styled.Container>
      <FormCheckbox
        name={`jobApplicationItem-${id}`}
        label=" "
        checked={props.checked}
        onChange={onChangeSelect}
      />
      <NavItem to={props.to} label={props.label} chevron />
    </Styled.Container>
  );
}

export default JobApplicationNavItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationNavItem;
    display: flex;
    position: relative;
    width: 100%;
    ${[t.pl(2)]}

    a {
      margin-left: -8px;
      width: calc(100% - 32px);
    }
  `;
});

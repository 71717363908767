import React from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";
import EmptyState from "@ats/src/components/shared/EmptyState";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import NewTeamMemberModal from "@ats/src/components/modals/NewTeamMemberModal";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import TeamMemberListItem from "@ats/src/views/jobApplications/jobSetup/components/TeamMemberListItem";
import TeamMemberInviteListItem from "./components/TeamMemberInviteListItem";
import { IfAuthorized } from "@ats/src/components/shared/AuthorizationManager";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { useOrganizationStore } from "@ats/src/lib/store/zustand/organizationStore";

import { useHiringTeamInvites } from "@shared/queryHooks/useHiringTeamInvite";

import { useRemoveHiringTeamMembership } from "@shared/queryHooks/useHiringTeamMembership";
import { useUpdateJob } from "@shared/queryHooks/useJob";
import { useDestroyHiringTeamInvite } from "@shared/queryHooks/useHiringTeamInvite";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function JobSetupTeam(props) {
  const { job } = props;
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const { currentOrganization } = useCurrentSession();

  const { mutate: removeTeamMember, isLoading: isLoadingRemove } = useRemoveHiringTeamMembership();
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const { mutate: destroyHiringTeamInvite } = useDestroyHiringTeamInvite();

  const {
    data: hiringTeamInvitesData,
    isLoading: isLoadingHiringTeamInvites,
  } = useHiringTeamInvites(job?.id);
  const hiringTeamInvites = hiringTeamInvitesData != undefined ? hiringTeamInvitesData?.items : [];

  const { organizationUsers } = currentOrganization;
  const hiringTeamMemberships = job.organizationUserIds.map((id) =>
    organizationUsers.find((u) => u.id === id),
  );

  const teamMembers = hiringTeamMemberships.filter(
    (organizationUser) => organizationUser && organizationUser.isActive,
  );

  window.logger("%c[JobSetupTeam] render", "color: #1976D2", {
    props,
    currentOrganization,
    teamMembers,
    hiringTeamMemberships,
    hiringTeamInvites,
    hiringTeamInvitesData,
  });

  const handleRemoveAllNonAdmins = () => {
    const nonAdminTeamMembers = teamMembers.filter((member) => member.role === "org_user");
    // remove hiring team invited members who were not assigned an admin role
    let invitedNonAdmins = [];
    if (hiringTeamInvites.length > 0) {
      invitedNonAdmins = hiringTeamInvites.filter((invited) => invited.invite.role === "org_user");
    }

    window.logger("%c[JobSetupTeam] handleRemoveAllAdmins", "color: #1976D2", {
      hiringTeamMemberships,
      hiringTeamInvites,
      nonAdminTeamMembers,
      invitedNonAdmins,
    });

    if (nonAdminTeamMembers.length > 0) {
      nonAdminTeamMembers.forEach((member) => {
        removeTeamMember({ jobId: job.id, organizationUserId: member.id });
      });
    }
    if (invitedNonAdmins.length > 0) {
      invitedNonAdmins.forEach((invited) => {
        destroyHiringTeamInvite({ id: invited.id, jobId: job.id });
      });
    }
  };
  const handleClickOnRemoveNonAdmins = () => {
    const modal = (
      <ConfirmationModal
        title="Are you sure you want to remove all non-admin members?"
        subcopy="They will be removed from the hiring team and lose access to this job. This includes invited organization members who have not yet registered."
        confirmText="Remove non-admin members"
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          handleRemoveAllNonAdmins();
        }}
        onCancel={() => removeModal()}
        isDestructiveConfirm={false}
      />
    );
    openModal(modal);
  };

  const handleClickOnRemoveTeamMember = (organizationUserId) => {
    const modal = (
      <ConfirmationModal
        title="Are you sure you want to remove this team member?"
        subcopy="If they are not an admin, they will lose access to this job."
        confirmText="Remove from team"
        cancelText="Keep on team"
        onConfirm={() => {
          removeModal();
          removeUserFromTeam(organizationUserId);
        }}
        onCancel={() => removeModal()}
        isDestructiveConfirm={false}
      />
    );

    openModal(modal);
  };

  const openNewTeamMemberModal = (event) => {
    event.preventDefault();
    openTeamMemberModal();
  };

  const openTeamMemberModal = () => {
    const modal = (
      <NewTeamMemberModal
        {...props}
        onCancel={onCloseModal}
        // updateJob={handleUpdateJob}
        // allUsers={allUsers}
        organizationUsers={currentOrganization?.organizationUsers}
        teamMembers={teamMembers}
        hiringTeamInvites={hiringTeamInvites}
      />
    );

    openModal(modal);
  };

  const onCloseModal = () => {
    removeModal();
  };

  const removeUserFromTeam = (organizationUserId) => {
    removeTeamMember(
      { jobId: job.id, organizationUserId },
      {
        onSuccess: () => {
          addToast({
            title: `Removed`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleDestroyHiringTeamInvite = (inviteId) => {
    destroyHiringTeamInvite(
      { id: inviteId, jobId: job.id },
      {
        onSuccess: () => {
          addToast({
            title: `Removed`,
            kind: "success",
          });
        },
      },
    );
  };

  const teamMemberNodes = () => {
    return teamMembers.length > 0 ? (
      teamMembers.map((teamMember, index) => {
        return (
          <TeamMemberListItem
            key={teamMember.id}
            teamMember={teamMember}
            removeTeamMember={handleClickOnRemoveTeamMember}
          />
        );
      })
    ) : (
      <EmptyState message="No hiring team members have been added" />
    );
  };

  const teamInviteNodes = () => {
    return hiringTeamInvites.length > 0
      ? hiringTeamInvites.map((invite, index) => {
        return (
          <TeamMemberInviteListItem
            key={`${invite.id}-${index}`}
            email={invite.invite.email}
            hiringTeamInviteId={invite.id}
            destroyHiringTeamInvite={handleDestroyHiringTeamInvite}
          />
        );
      })
      : null;
  };

  if (currentOrganization?.organizationUsers == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <>
      <SettingsContainer
        title="Hiring team"
        description="Members who are assigned as part of the hiring team gain access to the job. They can interact with candidates and update the job settings."
      >
        <FormSection>
          <Styled.TeamMemberList>
            {teamMemberNodes()}
            {teamInviteNodes()}
          </Styled.TeamMemberList>

          <IfAuthorized adminOnly>
            <Styled.Actions>
              <Button styleType="secondary" onClick={openNewTeamMemberModal}>
                Add a team member
              </Button>
              <Button styleType="secondary" onClick={handleClickOnRemoveNonAdmins}>
                Remove all non-admins
              </Button>
            </Styled.Actions>
          </IfAuthorized>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

export default JobSetupTeam;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.TeamMemberList = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupTeam_TeamMemberList;
    ${[t.my(8), t.rounded.sm]}
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupTeam_Actions;
    display: flex;
    button {
      ${[t.mr(3)]}
    }
  `;
});

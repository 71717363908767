import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import BottomButtonBar from "@ats/src/components/shared/BottomButtonBar";
import Button from "@ats/src/components/shared/Button";

import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateJob } from "@shared/queryHooks/useJob";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useModalContext } from "@shared/context/ModalContext";
import AlertModal from "@ats/src/components/modals/AlertModal";

function JobSettingsDescription(props) {
  const { setIsDirty } = props;
  const [job, setJob] = React.useState(props.job);

  const { description, descriptionHtml } = job;
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const editorRef = React.useRef(undefined);

  const modal = (
    <AlertModal
      title="Testing custom keys opening a modal"
      subcopy={`Looking good.`}
      confirmText="Okay"
      onConfirm={removeModal}
      onCancel={removeModal}
    />
  );

  const handleChange = (value) => {
    setIsDirty(true);
    // setJob({ ...job, description: value });
  };

  // window.logger("%c[JobSetupDescription][ProseMirror] RENDER", "background-color: #1976D2", {
  //   description,
  // });

  const handleSubmit = (event) => {
    event.preventDefault();

    // const data = new FormData(event.target);

    // window.logger("%c[JobSetupDescription][ProseMirror] handleSubmit", "background-color: #1976D2", {
    //   event,
    //   data,
    //   description,
    //   tester: data.get("tester"),
    // });

    updateJob(
      { id: props.job.id, description: editorRef.current.serializedState() },
      {
        onSuccess: (data) => {
          setIsDirty(false);
          addToast({
            title: `Job updated`,
            kind: "success",
          });
        },
        onError: () => {
          addToast({
            title: `Could not update job`,
            kind: "warning",
          });
        },
      },
    );
  };

  return (
    <Styled.Container>
      <Styled.Form id="job-setup-description" onSubmit={handleSubmit}>
        <ProseMirrorEditor
          ref={editorRef}
          enableStaticMenuBar
          enableCheckboxes={false}
          onChange={handleChange}
          defaultValue={description || ""}
        />

        <BottomButtonBar static>
          <Button
            type="submit"
            form="job-setup-description"
            // onClick={handleSubmit}
            loading={isLoadingUpdateJob}
          >
            Save changes
          </Button>
        </BottomButtonBar>
      </Styled.Form>
      <Styled.Sidebar>
        <h3>Job descriptions</h3>
        <p>
          You can author a description for each job you create in Polymer. This job description will be
          displayed on the public job post that potential candidates will visit.
        </p>
      </Styled.Sidebar>
    </Styled.Container>
  );
}

export default JobSettingsDescription;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDescription;
    display: flex;
    height: 100%;
  `;
});

Styled.Form = styled.form((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDescription_Form;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100vw;

    ${t.mq['lg']} {
      width: 50vw;
    }
  `;
});

Styled.Sidebar = styled.aside((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDescription_Sidebar;
    ${t.p(4)};
    border-left: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    display: none;

    ${t.mq['lg']} {
      display: block;
      flex: 1;
    }

    h3 {
      ${[t.mt(1), t.text.bold]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    p {
      ${[t.text.xs, t.mt(3)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    b {
      ${[t.text.bold]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    em {
      ${[t.text.medium]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      font-style: normal;
    }
    a {
      ${[t.text.medium]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      text-decoration: underline;
    }
  `;
});

import React, { Component } from "react";

import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { prettyErrorsArray } from "@shared/lib/formHelpers";

class FormContainer extends Component {
  static displayName = "FormContainer";

  formErrors = (errors) => {
    if (errors && isArray(errors) && !isEmpty(errors)) {
      return this.handleErrorsArray(errors);
    } else if (errors && isObject(errors) && !isEmpty(errors)) {
      return this.handleErrorsObject(errors);
    }
  };

  /* Likely used to handle Joi errors
  --===================================================-- */
  handleErrorsArray = (errors) => {
    return errors.map((error, index) => {
      const key = error.context ? error.context.key : error.key;
      const keyRegex = error.context ? new RegExp(`"${key}" `, "gi") : new RegExp(`${key} `, "gi");
      const message = error.message ? error.message.replace(keyRegex, "") : error;
      return <li key={index}>{`${upperFirst(lowerCase(key))} ${message}`}</li>;
    });
  };

  /* Likely Used to handle Rails and Yup errors
  --===================================================-- */
  handleErrorsObject = (errors) => {
    let groupErrorText;
    if (errors && errors.customQuestionsErrors && !isEmpty(errors.customQuestionsErrors)) {
      return <li>Questions are missing a response</li>;
    } else {
      return prettyErrorsArray(errors).map((error, index) => {
        return <li key={index}>{error}</li>;
      });
    }
  };

  // Note the second param "ref" provided by React.forwardRef.
  // We can pass it along to LogProps as a regular prop, e.g. "forwardedRef"
  // And it can then be attached to the Component.
  // return React.forwardRef((props, ref) => {
  //   return <LogProps {...props} forwardedRef={ref} />;
  // });

  render() {
    // window.logger("%c[FormContainer] props", "color: #1976D2", this.props);
    const { id, children, buttons, errors, onSubmit, className } = this.props;
    const formErrors = this.formErrors(errors);

    return (
      <form id={id} className={className} onSubmit={onSubmit}>
        {children}

        {formErrors ? (
          <Styled.ErrorsContainer>
            <Styled.ErrorsLabel>Form Errors:</Styled.ErrorsLabel>
            <Styled.ErrorsList>{formErrors}</Styled.ErrorsList>
          </Styled.ErrorsContainer>
        ) : null}

        {buttons && <Styled.Buttons>{buttons}</Styled.Buttons>}
      </form>
    );
  }
}

export default FormContainer;

/* Styled Components
======================================================= */
const Styled = {};

Styled.ErrorsContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormContainer_ErrorsContainer;
    ${[t.p(2), t.my(6), t.rounded.sm]}
    border: 1px solid ${t.dark ? t.color.red[400] : t.color.red[600]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.red[100]};
    color: ${t.dark ? t.color.red[400] : t.color.red[600]};

    ${t.mq["md"]} {
      ${[t.px(2), t.py(1)]}
    }
  `;
});

Styled.ErrorsLabel = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: FormContainer_ErrorsLabel;
    ${[t.text.sm, t.text.medium]}

    ${t.mq["md"]} {
      ${t.text.xs}
    }
  `;
});

Styled.ErrorsList = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: FormContainer_ErrorsList;
    ${[t.text.base, t.mt(1), t.pl(6)]}
    list-style-type: disc;
    li {
      ${t.mb(1)}
    }

    ${t.mq["md"]} {
      ${t.text.sm}
    }
  `;
});

Styled.Buttons = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: FormContainer_Buttons;
    ${t.mt(10)}

    ${t.mq["md"]} {
      ${t.mt(8)}
    }
  `;
});

import React from "react";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormSection from "@ats/src/components/forms/FormSection";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormTextarea from "@ats/src/components/forms/FormTextarea";
import FormLabel from "@ats/src/components/forms/FormLabel";
import Icon from "@ats/src/components/shared/Icon";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";

import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";

import { wwrCategoryOptions, wwrUpgradeCosts } from "@ats/src/lib/lookups";
import { distanceInWords } from "@shared/lib/time";
import { capitalizeSingleWord } from "@ats/src/lib/utils/helpers";
import { validateWwrCategory } from "@shared/lib/validateWithYup";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";
import { useCreateBoardWwrListing, useUpdateBoardWwrListing } from "@shared/queryHooks/useJob";

const Upgrade = ({ upgrade, selectedPlan, onSelect, isActive, isPurchasing }) => {
  const upgrades = {
    good: {
      title: "Good",
      features: 4,
      price: 59,
    },
    better: {
      title: "Better",
      features: 5,
      price: 99,
    },
    best: {
      title: "Best",
      features: 7,
      price: 149,
    },
  };

  const features = [
    "Display your company logo",
    "Share in WWR Slack channel",
    "2x Social media posts",
    "Distribute job to Partner Network",
    "Highlight your ad",
    "Pin ad for 7 days",
    "Featured email placement",
  ];

  const selected = selectedPlan === upgrade;

  return (
    <Styled.Upgrade selected={selected}>
      <Styled.Price>
        {upgrades[upgrade].title}
        <span>+${upgrades[upgrade].price}</span>
      </Styled.Price>
      {features.map((feature, index) => {
        if (index < upgrades[upgrade].features) {
          return (
            <Styled.Feature key={index}>
              <Icon name="check" />
              {feature}
            </Styled.Feature>
          );
        }
        return (
          <Styled.NonFeature key={index}>
            <Icon name="x" />
            {feature}
          </Styled.NonFeature>
        );
      })}
      {selected ? (
        <Button
          className="submit-button"
          type="button"
          styleType="primary"
          size="small"
          onClick={() => onSelect("standard")}
          aria-label="Select"
          disabled={isActive || isPurchasing}
        >
          Remove
        </Button>
      ) : (
        <Button
          className="submit-button"
          type="button"
          styleType="secondary"
          size="small"
          onClick={() => onSelect(upgrade)}
          aria-label="Select"
          disabled={isActive || isPurchasing}
        >
          Select
        </Button>
      )}
    </Styled.Upgrade>
  );
};

function JobDistributionWeWorkRemotely(props) {
  const { job, location } = props;
  const { activeWwrListing } = job;
  const { currentOrganization } = useCurrentSession();
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();
  const {
    mutate: createBoardWwrListing,
    isLoading: isLoadingCreateBoardWwrListing,
  } = useCreateBoardWwrListing();
  const {
    mutate: updateBoardWwrListing,
    isLoading: isLoadingUpdateBoardWwrListing,
  } = useUpdateBoardWwrListing();

  const isLoadingSomething =
    isLoadingUpdateOrganization || isLoadingCreateBoardWwrListing || isLoadingUpdateBoardWwrListing;

  const boardWwrListing = activeWwrListing;

  const [state, setState] = React.useState({
    wwrCategory: boardWwrListing ? boardWwrListing.wwrCategory : "",
    wwrJobListingType:
      boardWwrListing && boardWwrListing.wwrJobListingType
        ? boardWwrListing.wwrJobListingType
        : "Full-Time",
    wwrRegion:
      boardWwrListing && boardWwrListing.wwrRegion
        ? boardWwrListing.wwrRegion
        : "Anywhere in the World",
    plan: boardWwrListing && boardWwrListing.isActive ? boardWwrListing.plan : "better",
    wwrCompanyBio: currentOrganization ? currentOrganization.wwrCompanyBio : null,
  });

  const { wwrCategory, wwrJobListingType, wwrCompanyBio, wwrRegion, plan } = state;

  const [errors, setErrors] = React.useState(null);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isPurchasing, setIsPurchasing] = React.useState(false);
  const stripeSubscriptionInGoodStanding = currentOrganization
    ? currentOrganization.stripeSubscriptionInGoodStanding
    : false;
  const isPublished = job.status === "published";
  const isArchived = job.status === "status_archived";
  const isVisible = job.visible;
  const isAllowedRemote = job.isAllowedRemote === true;
  const hasDescription = !isEmpty(trim(job.description));
  const hasCompanyUrl = !isEmpty(trim(currentOrganization.websiteUrl));
  const hasBeenPurchased = boardWwrListing && boardWwrListing.wwrListingId != undefined;
  const isActive = boardWwrListing && boardWwrListing.isActive;
  const publishedAt = boardWwrListing && boardWwrListing.publishedAt;
  const expiresAt = boardWwrListing && boardWwrListing.expiresAt;
  const listingUrl = boardWwrListing && boardWwrListing.listingUrl;
  const hasBeenUpgraded = plan && plan !== "standard";

  window.logger("%c[JobDistributionWeWorkRemotely] ", "background: #EFDDEF; color: #1976D2", {
    props,
    state,
    boardWwrListing,
    wwrCompanyBio,
    currentOrganization,
    hasBeenUpgraded,
  });

  if (currentOrganization == undefined) {
    return null;
  }

  const ViewLink = (
    <Button type="externalLink" link="https://weworkremotely.com" styleType="text">
      Visit We Work Remotely
      <Icon name="external-link" />
    </Button>
  );

  const isMissingRequirements = () => {
    return !(
      stripeSubscriptionInGoodStanding &&
      isPublished &&
      isVisible &&
      isAllowedRemote &&
      hasDescription &&
      hasCompanyUrl
    );
  };

  const handleClickOnCheckout = async () => {
    const [isValid, validationErrors] = await validateWwrCategory({ wwrCategory });
    if (isValid) {
      handleSubmit();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSubmit = () => {
    setIsPurchasing(true);
    if (wwrCompanyBio) {
      updateOrganization(
        { id: currentOrganization.id, wwrCompanyBio },
        {
          onSuccess: () => {
            createOrUpdateWwrListing();
          },
        },
      );
    } else {
      createOrUpdateWwrListing();
    }

    window.logger("%c[JobDistributionWeWorkRemotely] handleSubmit", "color: #1976D2", {
      wwrCategory,
      wwrJobListingType,
      wwrRegion,
      plan,
    });
  };

  const createOrUpdateWwrListing = () => {
    if (isActive) {
      handleUpdateBoardWwrListing({ status: "approved" });
    } else {
      handleCreateBoardWwrListing();
    }
  };

  const handleCreateBoardWwrListing = () => {
    createBoardWwrListing(
      {
        jobId: job.id,
        wwrCategory,
        wwrJobListingType,
        wwrRegion,
        plan,
      },
      {
        onSuccess: () => {
          setErrors(null);
          setIsDirty(false);
        },
        onError: (response: { data: any }) => {
          setErrors(response.data.errors);

          if (response.data.errors["general"] != undefined) {
            window.logger(
              "%c[JobDistributionWeWorkRemotely] createBoardWwrListing",
              "color: #FF0602",
              {
                response,
              },
            );
            addToast({ title: response.data.errors.general[0], kind: "warning" });
          }
        },
      },
    );
  };

  const handleUpdateBoardWwrListing = ({ status = "approved" }) => {
    updateBoardWwrListing(
      {
        jobId: job.id,
        id: boardWwrListing.id,
        wwrCategory,
        wwrJobListingType,
        wwrRegion,
        plan,
        status,
      },
      {
        onSuccess: () => {
          setErrors(null);
          setIsDirty(false);
        },
        onError: (response: { data: any }) => {
          setErrors(response.data.errors);

          if (response.data.errors["general"] != undefined) {
            window.logger(
              "%c[JobDistributionWeWorkRemotely] updateBoardWwrListing",
              "color: #FF0602",
              {
                response,
              },
            );
            addToast({ title: response.data.errors.general[0], kind: "warning" });
          }
        },
      },
    );
  };

  const handleClickOnDeletePost = () => {
    const modal = (
      <ConfirmationModal
        title="Are you sure you want to remove your We Work Remotely post?"
        subcopy="This will immediately remove it from We Work Remotely. A new purchase will be required to display it again."
        confirmText="Remove job post"
        isDestructiveConfirm={true}
        cancelText="Keep job post"
        onConfirm={() => {
          handleUpdateBoardWwrListing({ status: "canceled" });
          removeModal();
          setState({ ...state, plan: "better" });
        }}
        onCancel={() => {
          removeModal();
        }}
      />
    );

    openModal(modal);
  };

  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value });
    setErrors(null);
    setIsDirty(true);
  };

  const handleSelectUpgrade = (upgrade) => {
    setState({ ...state, plan: upgrade });
  };

  const Purchased = (
    <>
      <Styled.Divider />
      <Styled.Purchased>
        {isActive ? (
          <>
            <Styled.PurchasedTitle>
              {`Purchased ${distanceInWords(publishedAt)}`}{" "}
              <span>({distanceInWords(expiresAt, false)} left)</span>
            </Styled.PurchasedTitle>
            <Styled.PurchasedLink>
              <Icon name="external-link" />
              {listingUrl != undefined ? (
                <a href={listingUrl} target="_blank" rel="noopener noreferrer">
                  View post on WWR
                </a>
              ) : (
                <div>Could not find URL</div>
              )}
            </Styled.PurchasedLink>
          </>
        ) : (
          <p>Your last listing has expired. That post is no longer visible on WWR.</p>
        )}
      </Styled.Purchased>
    </>
  );

  const Sidebar = (
    <Styled.Sidebar>
      <Styled.SidebarSection>
        {hasBeenUpgraded ? (
          <>
            <Styled.Price>
              <h3>Total price</h3>
              <h3>${wwrUpgradeCosts[plan].total}</h3>
            </Styled.Price>
            <p>
              This ${wwrUpgradeCosts[plan].total} purchase includes the optional,{" "}
              {capitalizeSingleWord(plan)} upgrade for ${wwrUpgradeCosts[plan].price}.
            </p>
          </>
        ) : (
          <Styled.Price>
            <h3>Purchase price</h3>
            <h3>$299</h3>
          </Styled.Price>
        )}
        {!isMissingRequirements() && (!hasBeenPurchased || (hasBeenPurchased && !isActive)) && (
          <p>
            Clicking the purchase button below will immediately create a post on the We Work
            Remotely job board and apply a ${hasBeenUpgraded ? wwrUpgradeCosts[plan].total : "299"}{" "}
            charge to the payment method associated with your Polymer subscription.
          </p>
        )}
        <p>
          Your post will be displayed on the We Work Remotely job board for 30 days from the time of
          purchase.
        </p>
        {isMissingRequirements() && (
          <p>Checkout is disabled until all job board requirements are met.</p>
        )}
        {!isActive ? (
          <Button
            onClick={handleClickOnCheckout}
            disabled={isMissingRequirements() || isLoadingSomething || isPurchasing}
            loading={isLoadingSomething || isPurchasing}
          >
            Purchase now
          </Button>
        ) : (
          <Button
            styleType="secondary"
            onClick={handleClickOnDeletePost}
            loading={isLoadingSomething}
          >
            Remove post
          </Button>
        )}
      </Styled.SidebarSection>
      {hasBeenPurchased && Purchased}
    </Styled.Sidebar>
  );

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[JobDistributionWeWorkRemotely] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={handleSubmit}
        loading={isLoadingSomething || isLoadingUpdateOrganization}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );

  return (
    <>
      <SettingsContainer
        fillHeight={true}
        title="We Work Remotely"
        description="We Work Remotely is the largest remote work community in the world. With over 2.5M monthly visitors, WWR is the #1 destination to find and list incredible remote jobs."
        actions={ViewLink}
        sidebar={Sidebar}
        bottomBar={isActive && BottomBar}
      >
        {isMissingRequirements() && (
          <Styled.Requirements>
            <FormLabel label="Job board requirements" />
            <Styled.RequirementInstructions>
              Your job post does not meet the following requirements for this job board.
            </Styled.RequirementInstructions>
            <div>
              {!stripeSubscriptionInGoodStanding && (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    You must have an active Polymer subscription to post to We Work Remotely.
                  </Styled.RequirementTitle>
                  <Button 
                    type="internalLink" 
                    link={{ 
                      pathname: `/hire/settings/billing`, 
                      state: { orginalPathname: location.pathname }
                    }} 
                    styleType="text"
                  >
                    View plans
                  </Button>
                </Styled.Requirement>
              )}
              {isArchived ? (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Job has been archived. You may no longer post it as a listing on WWR.
                  </Styled.RequirementTitle>
                </Styled.Requirement>
              ) : !isPublished ? (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Job must be published
                  </Styled.RequirementTitle>
                  <Button type="internalLink" link={`/jobs/${job.id}/setup`} styleType="text">
                    Go to job setup
                  </Button>
                </Styled.Requirement>
              ) : !isVisible ? (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Job must be visible on your company job board (currently hidden).
                  </Styled.RequirementTitle>
                  <Button type="internalLink" link={`/jobs/${job.id}/setup`} styleType="text">
                    Go to job setup
                  </Button>
                </Styled.Requirement>
              ) : null}
              {!isAllowedRemote && (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Job must be remote-friendly to distribute to this board.
                  </Styled.RequirementTitle>
                  <Button
                    type="internalLink"
                    link={`/jobs/${job.id}/setup/details`}
                    styleType="text"
                  >
                    Go to job details
                  </Button>
                </Styled.Requirement>
              )}
              {!hasDescription && (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Job must have a description
                  </Styled.RequirementTitle>
                  <Button
                    type="internalLink"
                    link={`/jobs/${job.id}/setup/description`}
                    styleType="text"
                  >
                    Go to job description
                  </Button>
                </Styled.Requirement>
              )}
              {!hasCompanyUrl && (
                <Styled.Requirement>
                  <Styled.RequirementTitle>
                    <Icon name="alert-triangle" />
                    Organization must have a website URL
                  </Styled.RequirementTitle>
                  <Button 
                    type="internalLink" 
                    link={{ 
                      pathname: `/hire/settings/organization`, 
                      state: { orginalPathname: location.pathname }
                    }} 
                    styleType="text"
                  >
                  {/* <Button type="internalLink" link={`/hire/settings/organization`} styleType="text"> */}
                    Go to organization management
                  </Button>
                </Styled.Requirement>
              )}
            </div>
          </Styled.Requirements>
        )}

        <FormSection title="Additional job information">
          <FormSelect
            onChange={handleInputChange}
            name="wwrCategory"
            label="Job category on We Work Remotely"
            isRequired={true}
            value={wwrCategory}
            options={wwrCategoryOptions}
            errors={errors}
            isDisabled={isMissingRequirements()}
          />

          <FormSelect
            onChange={handleInputChange}
            name="wwrRegion"
            label="Region on We Work Remotely"
            isRequired={true}
            value={wwrRegion}
            options={[
              { value: "Anywhere in the World", label: "Anywhere in the World" },
              { value: "USA Only", label: "USA only" },
              { value: "North America Only", label: "North America only" },
              { value: "Europe Only", label: "Europe only" },
              { value: "Americas Only", label: "Americas only" },
              { value: "Canada Only", label: "Canada only" },
              { value: "EMEA Only", label: "EMEA only" },
              { value: "Asia Only", label: "Asia only" },
              { value: "Africa Only", label: "Africa only" },
            ]}
            errors={errors}
            isDisabled={isMissingRequirements()}
          />

          <FormSelect
            onChange={handleInputChange}
            name="wwrJobListingType"
            label="Job type on We Work Remotely"
            isRequired={true}
            value={wwrJobListingType}
            options={[
              { value: "Full-Time", label: "Full-time" },
              { value: "Contract", label: "Contract" },
            ]}
            errors={errors}
            isDisabled={isMissingRequirements()}
          />
        </FormSection>

        <FormSection title="Additional company information">
          <FormTextarea
            onChange={handleInputChange}
            name="wwrCompanyBio"
            label="Company description"
            description="Your company or organization’s description and/or mission statement. This will be displayed on your We Work Remotely company profile."
            value={wwrCompanyBio || ""}
            errors={errors}
            isDisabled={isMissingRequirements()}
          />
        </FormSection>

        <FormSection title="Help your ad stand out">
          <Styled.Upgrades>
            <Upgrade
              upgrade="good"
              onSelect={handleSelectUpgrade}
              selectedPlan={state.plan}
              isActive={isActive}
              isPurchasing={isPurchasing}
            />
            <Upgrade
              upgrade="better"
              onSelect={handleSelectUpgrade}
              selectedPlan={state.plan}
              isActive={isActive}
              isPurchasing={isPurchasing}
            />
            <Upgrade
              upgrade="best"
              onSelect={handleSelectUpgrade}
              selectedPlan={state.plan}
              isActive={isActive}
              isPurchasing={isPurchasing}
            />
          </Styled.Upgrades>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

export default JobDistributionWeWorkRemotely;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Requirements = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Requirements;
    ${[t.mt(6), t.mb(8)]}
    display: flex;
    flex-direction: column;
  `;
});

Styled.RequirementInstructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Requirements;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
    max-width: 32rem;
  `;
});

Styled.Requirement = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Requirement;
    ${[t.px(4)]}
    min-height: ${t.spacing[12]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : "transparent"};
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  `;
});

Styled.RequirementTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_RequirementTitle;
    ${[t.my(2), t.mr(4)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    line-height: 1.4;

    svg {
      ${[t.mr(2)]};
      color: ${t.dark ? t.color.red[500] : t.color.red[600]};
      flex-shrink: 0;
    }
  `;
});

Styled.Upgrades = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Upgrades;
    display: flex;
  `;
});

Styled.Upgrade = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Upgrade;
    ${[t.mr(4), t.rounded.md, t.p(3)]}
    position: relative;
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    flex: 1;
    ${props.selected
      ? css`
          border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[400]};
          background-color: ${t.dark ? t.color.gray[800] : t.color.white};
        `
      : css`
          border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
          background-color: ${t.dark ? "transparent" : t.color.white};
        `}
    &:last-of-type {
      ${t.mr(0)}
    }
    button {
      ${t.mt(3)}
    }
  `;
});

Styled.Feature = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Feature;
    ${[t.text.xs, t.py(1)]}
    display: flex;
    color: ${t.dark ? t.color.gray[400] : t.color.black};
    line-height: 1.4;
    svg {
      ${[t.mt("-px"), t.mr(1)]}
    }
  `;
});

Styled.NonFeature = styled(Styled.Feature)((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_NonFeature;
    color: ${t.color.gray[500]};
    line-height: 1.4;
  `;
});

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Sidebar;
    position: sticky;
    top: ${t.spacing[4]};
    h3 {
      ${[t.mt(1), t.pt("px"), t.text.base, t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
    p {
      ${[t.text.xs, t.mt(3)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    b {
      ${[t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.SidebarSection = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_SidebarSection;
    ${t.px(4)};

    button {
      ${t.mt(4)};
    }
  `;
});

Styled.Price = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Price;
    ${[t.text.bold, t.text.base, t.mb(2)]}
    display: flex;
    justify-content: space-between;
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: none;
  `;
});

Styled.Purchased = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Purchased;
    ${t.px(4)}
    p {
      ${[t.mt(0), t.mb(4), t.text.xs]}
    }
  `;
});

Styled.PurchasedTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_PurchasedTitle;
    ${[t.text.xs, t.text.bold, t.mb(2)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    span {
      ${[t.text.normal]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.PurchasedLink = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_PurchasedLink;
    ${[t.text.xs, t.h(6)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    overflow: hidden;
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    div,
    a,
    button {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      text-align: start;
      padding: 0;
      appearance: none;
      outline: none;
      background: transparent;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button:hover,
    button:focus,
    a:hover {
      text-decoration: underline;
    }
  `;
});

Styled.Delete = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobDistributionWWR_Delete;

    button {
      ${t.m(4)}
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { useCreateChannelMessage } from "@shared/queryHooks/useChannelMessage";

import FormContainer from "@ats/src/components/forms/FormContainer";
import ChannelMessageTemplateSelectionModal from "@ats/src/components/modals/ChannelMessageTemplateSelectionModal";
import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";

import { prettyErrorsArray } from "@shared/lib/formHelpers";

function ChannelMessageNew({ jobApplication, setIsDirty, allChannel }) {
  const { openModal, removeModal } = useModalContext();
  const addToast = useToastContext();
  const editorRef = React.useRef(undefined);

  const { mutate: createChannelMessage, isLoading: isLoadingCreate } = useCreateChannelMessage();

  const [errors, setErrors] = React.useState(null);

  const handleChange = () => {
    setErrors(null);
    setIsDirty(editorRef.current.serializedState() !== "<p></p>");
  };

  const onClickInsertTemplate = (event) => {
    event.preventDefault();

    openChannelMessageTemplateSelectionModal();
  };

  const handleInsertTemplate = (mailMerge) => {
    window.logger("%c[ChannelMessageNew] handleInsertTemplate", "color: #1976D2", { mailMerge });
    editorRef.current.replaceEditorContent(mailMerge?.messageHtml);
  };

  const clearEditor = () => {
    editorRef.current.clearEditor();
  };

  const openChannelMessageTemplateSelectionModal = () => {
    const modal = (
      <ChannelMessageTemplateSelectionModal
        onCancel={onCloseModal}
        handleInsertTemplate={handleInsertTemplate}
        jobApplicationId={jobApplication.id}
      />
    );

    openModal(modal);
  };

  const onCloseModal = () => {
    removeModal();
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();

    const serializedState = editorRef.current.serializedState();

    if (!editorRef.current.state() || serializedState === "<p></p>") {
      setIsDirty(false);
      return;
    }

    // window.logger("%c[ChannelMessageNew] handleSendMessage", "color: #1976D2", {
    //   editorState: editorRef.current.state(),
    //   doc: serialize(editorRef.current.state().doc),
    // });

    createChannelMessage(
      {
        channelId: allChannel?.id,
        // body: serialize(editorRef.current.state().doc),
        body: serializedState,
      },
      {
        onSuccess: (data) => {
          window.logger("%c[ChannelMessageNew onSuccess", "color: #1976D2", { data });
          clearEditor();
          setIsDirty(false);
        },
        onError: (response: { data: { errors } }) => {
          window.logger(
            "%c[ChannelMessageNew] handle error when CREATE New Message",
            "color: #FF7602",
            {
              response,
              errors: prettyErrorsArray(response.data.errors),
            },
          );
          const prettyErrors = prettyErrorsArray(response.data.errors);
          addToast({
            title: prettyErrors[0],
            kind: "error",
            delay: 6000,
          });
          clearEditor();
          setIsDirty(false);
        },
      },
    );
  };

  return (
    <FormContainer
      id="channel-message-form" // connects the form to the Submit button outside the <form>
      errors={errors}
      onSubmit={handleSendMessage}
    >
      <Styled.NewMessageArea>
        {jobApplication.candidate.hasValidEmail === true ? (
          <Styled.NewMessageField
            ref={editorRef}
            placeholder="Type a message to the candidate here..."
            onChange={handleChange}
            enableStaticMenuBar={false}
            enableSelectionToolbar={true}
            enableMessageKeys={true}
            defaultValue=""
          />
        ) : (
          <Styled.Invalid>
            <Icon name="alert-triangle" />
            The email address for this candidate is not valid.
          </Styled.Invalid>
        )}

        <Styled.Controls>
          <Button
            className="submit-button"
            size="small"
            styleType="primary"
            form="channel-message-form" // this ties it to the form it is outside of
            type="submit"
            onClick={handleSendMessage}
            loading={isLoadingCreate}
            disabled={jobApplication.candidate.hasValidEmail === false}
          >
            Send message
          </Button>

          <Button
            className="submit-button"
            size="small"
            styleType="secondary"
            form="channel-message-form" // this ties it to the form it is outside of
            type="submit"
            onClick={onClickInsertTemplate}
            // loading={isLoadingCreate}
            disabled={jobApplication.candidate.hasValidEmail === false}
          >
            Use template
          </Button>
        </Styled.Controls>
      </Styled.NewMessageArea>
    </FormContainer>
  );
}

export default ChannelMessageNew;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.NewMessageArea = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_NewMessageArea;
    border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
  `;
});

// Styled.NewMessageField = styled(TextareaAutosize)((props) => {
Styled.NewMessageField = styled(ProseMirrorEditor)((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_NewMessageField;
    ${[t.px(4), t.pt(4), t.mb(6)]}
    display: block;
    width: 100%;
    resize: none;
    outline: none;
    line-height: 1.6;
    border: none;
    max-height: ${t.spacing[64]};

    &:disabled {
      background: ${t.dark ? t.color.gray[900] : t.color.white};
    }

    &::placeholder {
      color: ${t.color.gray[500]};
    }
  `;
});

Styled.Controls = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Controls;
    ${[t.px(4), t.pb(4)]}
    display: flex;

    button {
      ${t.mr(3)}
      width: auto;
    }
  `;
});

Styled.Invalid = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_RequirementTitle;
    ${[t.px(4), t.pt(4), t.pb("px"), t.mb(6)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    line-height: 1.6;

    svg {
      ${[t.mr(2)]};
      color: ${t.dark ? t.color.red[500] : t.color.red[600]};
      flex-shrink: 0;
    }
  `;
});

import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  BarChart,
  Bar,
  Label,
  ResponsiveContainer,
} from "recharts";
import { StageData } from "@shared/types/reporting";
import theme from "@ats/styles/theme";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

interface JobMetricsStageFunnelChartProps {
  stageData: StageData[];
  totalApplications: number;
}

const yTickFormatter = (value: string, index: number) => {
  const limit = 16; // put your maximum character
  if (!value) return value;
  if (value.length <= limit) return value;
  return `${value.substring(0, limit)}...`;
};

const legendFormatter = (value: string) => {
  return "Candidate traffic";
};

function JobMetricsStageFunnelChart(props: JobMetricsStageFunnelChartProps) {
  const { stageData, totalApplications } = props;
  const { isDarkMode } = useColorSchemeContext();

  const data = !stageData
    ? []
    : stageData
        .filter((stage) => stage.stageName !== "Archived")
        .map((stage) => ({
          count: (stage.funnelCount / totalApplications) * 100,
          stageName: stage.isDeleted ? `${stage.stageName} (deleted)` : stage.stageName,
        }));

  return (
    <ResponsiveContainer width="100%" height={data.length * (12 + 20) + 48}>
      <BarChart
        data={data}
        layout="vertical"
        barSize={12}
        barGap={0}
        barCategoryGap={10}
        margin={{ top: -40, right: 6, bottom: 0, left: 0 }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          stroke={isDarkMode ? theme.color.gray[600] : theme.color.gray[200]}
        />
        <Bar dataKey="count" fill={isDarkMode ? theme.color.gray[300] : theme.color.black} />
        <XAxis
          type="number"
          height={48}
          tickMargin={6}
          tick={{ fill: isDarkMode ? theme.color.gray[400] : theme.color.gray[600] }}
        >
          <Label
            value="Percent of all candidates"
            position="insideBottom"
            fill={isDarkMode ? theme.color.gray[400] : theme.color.gray[600]}
          />
        </XAxis>
        <YAxis
          tickFormatter={yTickFormatter}
          width={120}
          type="category"
          dataKey="stageName"
          tickMargin={6}
          tick={{ width: 120, fill: isDarkMode ? theme.color.gray[400] : theme.color.gray[600] }}
        />
        <Legend
          formatter={legendFormatter}
          iconType="square"
          align="right"
          verticalAlign="top"
          height={40}
          iconSize={10}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default JobMetricsStageFunnelChart;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

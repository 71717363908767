import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useHotkeys } from "react-hotkeys-hook";

import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";

import FormLabel from "@ats/src/components/forms/FormLabel";

const FormInput = (props) => {
  const { errors, label, isRequired, onKeyDown, defaultValue, requiredLabel } = props;
  let errorText = errorTextViaYupOrRailsOrJoi(props); // errorsDisplay(props);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current.addEventListener("keydown", (event) => {
      if (event.keyCode === 27 || event.key === "Escape") {
        inputRef.current.blur();
      }
      return () => {
        inputRef.current.removeEventListener("keydown");
      };
    });
  }, []);

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "esc",
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      inputRef.current.blur();
      return false;
    },
    {},
    [],
  );

  const handleChange = (event) => {
    event.preventDefault();

    // Use the parent's scope to setState
    if (props.scope !== undefined) {
      props.scope.setState({
        [props.name]: event.currentTarget.value,
        errors: null,
      });
    } else if (props.onChange !== undefined) {
      props.onChange(props.name, event.currentTarget.value);
    }
  };

  const handleBlur = (event) => {
    event.preventDefault();

    if (props.onBlur !== undefined) {
      props.onBlur(props.name, event.currentTarget.value);
    }
  };

  // window.logger("%c[FormInput] Errors", "background: red; color: white;", {
  //   errors,
  //   errorText,
  // });

  const inputId = props.label
    ? `input${props.label.replace(/\s+/g, "")}`
    : props.name
    ? props.name
    : "Default";

  return (
    <Styled.Container>
      {label && (
        <FormLabel
          label={label}
          for={inputId}
          isRequired={isRequired}
          error={errorText}
          requiredLabel={requiredLabel}
        />
      )}
      {props.description && <p>{props.description}</p>}
      <Styled.UI hasPretext={props.pretext} hasError={errorText}>
        {props.pretext && <Styled.Pretext>{props.pretext}</Styled.Pretext>}
        <input
          id={inputId}
          ref={inputRef}
          type={props.inputType || "text"}
          value={props.value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={handleBlur}
          name={props.name}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          disabled={props.isDisabled}
          onKeyDown={onKeyDown}
        />
      </Styled.UI>
      {props.children} {/* For displaying Joi rendered errors. See NewInviteForm example. */}
    </Styled.Container>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  scope: PropTypes.object, // typically set to "this" from the parent so we can use setState
  onChange: PropTypes.func, //.isRequired, // This get's used as a callback if scope is not set
  className: PropTypes.string,
  inputType: PropTypes.string,
  pretext: PropTypes.string,
  value: PropTypes.any,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requiredLabel: PropTypes.string,
};

FormInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  requiredLabel: "required",
};

export default FormInput;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormInput;
    ${t.mb(6)}
    > p {
      ${[t.text.sm, t.mt(-1), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq["md"]} {
        ${t.text.xs}
      }
    }

    ${t.mq["md"]} {
      ${t.mb(5)}
    }
  `;
});

Styled.UI = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormInput_UI;
    display: flex;
    input {
      ${[t.h(10), t.px(2), t.rounded.sm, t.text.base]}
      appearance: none;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
      width: 100%;
      text-overflow: ellipsis;
      border-width: 1px;
      border-style: solid;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      border-color: ${props.hasError
        ? t.dark
          ? t.color.red[400]
          : t.color.red[500]
        : t.dark
        ? "transparent"
        : t.color.gray[300]};
      ${props.hasPretext ? "border-top-left-radius: 0; border-bottom-left-radius: 0;" : ""}
      &::placeholder {
        color: ${t.color.gray[500]};
      }
      &:hover {
        border-color: ${props.hasError
          ? t.dark
            ? t.color.red[300]
            : t.color.red[600]
          : t.dark
          ? t.color.gray[600]
          : t.color.gray[400]};
      }
      &:focus {
        outline: none;
        border-color: ${props.hasError
          ? t.dark
            ? t.color.red[300]
            : t.color.red[600]
          : t.dark
          ? t.color.gray[500]
          : t.color.gray[400]};
        ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
      }
      &:disabled {
        color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
        background-color: ${t.dark ? "transparent" : t.color.gray[100]};
        ${t.dark && `border: 1px solid ${t.color.gray[800]};`}
        border-color: ${t.dark ? "rgba(255,255,255,0.08)" : t.color.gray[300]};
      }
      &:disabled:hover {
        ${!t.dark && `border-color: ${t.color.gray[300]};`}
      }

      ${t.mq["md"]} {
        ${[t.h(8), t.text.sm]}
      }
    }
    input[type="number"] {
      appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  `;
});

Styled.Pretext = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormInput_Pretext;
    ${[t.h(10), t.px(2), t.text.base, t.rounded.sm]}
    color: ${t.dark ? t.color.gray[300] : t.color.gray[600]};
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 1px solid ${t.dark ? "transparent" : t.color.gray[300]};
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};

    ${t.mq["md"]} {
      ${[t.h(8), t.text.sm]}
    }
  `;
});

import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPost, apiGet, apiDelete } from "@shared/queryHooks/api";

const createHiringTeamInvite = async ({ jobId, inviteId }) => {
  return await apiPost({
    path: `/jobs/${jobId}/hiring_team_invites`,
    variables: { inviteId },
  });
};

const getHiringTeamInvites = async (jobId) => {
  window.logger("%c[useHiringTeamInvite] jobId", "color: #1976D2", { jobId });
  if (jobId) {
    return await apiGet({ path: `/jobs/${jobId}/hiring_team_invites` });
  }
};

const destroyHiringTeamInvite = async ({ id, jobId }) => {
  return await apiDelete({ path: `/jobs/${jobId}/hiring_team_invites/${id}`, variables: {} });
};

/* Hooks
--===================================================-- */

export function useHiringTeamInvites(
  jobId,
): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useQuery(["hiringTeamInvites", jobId], () => getHiringTeamInvites(jobId));
}

export function useCreateHiringTeamInvite() {
  const queryClient = useQueryClient();
  return useMutation(createHiringTeamInvite, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringTeamInvite] useCreateHiringTeamInvite onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries(["hiringTeamInvites", mutationVariables.jobId]);
    },
    // throwOnError: true,
  });
}

export function useDestroyHiringTeamInvite() {
  const queryClient = useQueryClient();
  return useMutation(destroyHiringTeamInvite, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["hiringTeamInvites", mutationVariables.jobId]);
    },
    // throwOnError: true,
  });
}

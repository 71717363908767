import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormCheckbox from "@ats/src/components/forms/FormCheckbox";
import JobStageAutomationInfo from "@ats/src/views/jobApplications/JobStageAutomationInfo";
import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

import { useModalContext } from "@shared/context/ModalContext";
import { useJobs } from "@shared/queryHooks/useJob";
import { useHiringStages } from "@shared/queryHooks/useHiringStage";
import { HiringStageAutomation } from "@ats/types/job";
import { activeHiringStageAutomation } from "@ats/src/lib/utils/helpers";

type HiringStage = {
  name: string;
  id: number;
  kind: string;
  jobId: number;
  hiringStageAutomation: HiringStageAutomation;
};

type Props = {
  title: string;
  subcopy?: string;
  onConfirm: ({ targetJob, targetHiringStage, cloneInsteadOfMove }) => void;
  onCancel: () => void;
  currentStage: HiringStage;
  nextLogicalHiringStage?: HiringStage;
  hiringStages: Array<HiringStage>;
};

function JobApplicationMoveModal(props: Props) {
  const { title, subcopy, onConfirm, onCancel, currentStage } = props;
  const [errors, setErrors] = React.useState([]);
  const [isDirty, setIsDirty] = React.useState(false);
  const [targetJobId, setTargetJobId] = React.useState(null);
  const [targetHiringStageId, setTargetHiringStageId] = React.useState(null);
  const [cloneInsteadOfMove, setCloneInsteadOfMove] = React.useState(false);

  const { dismissModalWithAnimation } = useModalContext();
  const { data: jobsData, isFetching: isFetchingJobs } = useJobs({
    refetchOnWindowFocus: false,
  });
  const { data: hiringStagesData, isFetching: isFetchingHiringStages } = useHiringStages({
    jobId: targetJobId,
    refetchOnWindowFocus: false,
  });

  const jobs = jobsData ? jobsData.items.filter((job) => job.id !== currentStage.jobId) : [];
  const hiringStages = hiringStagesData ? hiringStagesData : [];

  // window.logger("%c[JobApplicationMoveModal] render", "background-color: #65FF92", {
  //   hiringStagesData,
  //   hiringStages,
  //   jobs,
  // });

  // Select the first Job and Hiring Stage
  React.useEffect(() => {
    // window.logger("%c[JobApplicationMoveModal] useEffect", "background-color: #65FF92", {
    //   hiringStagesData,
    //   hiringStages,
    //   jobs,
    //   targetJobId,
    //   targetHiringStageId,
    // });

    if (targetJobId == undefined && jobs != undefined && jobs.length > 0) {
      setTargetJobId(jobs[0].id);
    }

    if (targetHiringStageId == undefined && hiringStages != undefined && hiringStages.length > 0) {
      setTargetHiringStageId(hiringStages[0].id);
    }
  }, [jobs, hiringStages, targetJobId]);

  const jobsOptions = jobs ? jobs.map((job) => ({ value: job.id, label: job.title })) : [];
  const hiringStagesOptions = hiringStages
    ? hiringStages.map((hiringStage) => ({ value: hiringStage.id, label: hiringStage.name }))
    : [];
  const targetStageName = hiringStagesOptions.find((option) => option.value === targetHiringStageId)?.label;
  const targetStageAutomation = activeHiringStageAutomation(hiringStages, targetHiringStageId);

  const handleOnCancel = (event) => {
    if (event != undefined) {
      event.preventDefault();
    }
    dismissModalWithAnimation(() => onCancel);
  };

  const handleOnConfirm = (event) => {
    event.preventDefault();
    dismissModalWithAnimation(() => handleConfirmation); // have to call the function like this so that onConfirm does not run immediately
  };

  const handleOnClickCheckbox = (name, value) => {
    // event.preventDefault();
    window.logger("%c[JobApplicationMoveModal] handleOnClickCheckbox", "color: #1976D2", {
      name,
      value,
    });
    setCloneInsteadOfMove(value);
  };

  const handleConfirmation = () => {
    // window.logger(
    //   "%c[JobApplicationMoveModal] handleConfirmation",
    //   "background-color: #197600; color: #FFF;",
    //   {
    //     targetHiringStageId,
    //   },
    // );

    const targetJob = jobs.find((job) => job.id === targetJobId);
    const targetHiringStage = hiringStages.find(
      (hiringStage) => hiringStage.id === targetHiringStageId,
    );

    onConfirm({ targetJob, targetHiringStage, cloneInsteadOfMove });
  };

  const handleChangeJob = (name, value) => {
    setTargetJobId(value);
    setTargetHiringStageId(null);
    setIsDirty(true);
  };

  const handleChangeStage = (name, value) => {
    setTargetHiringStageId(value);
    setIsDirty(true);
  };

  // const selectHiringStageValue = () => {
  //   const stage = jobsOptions.find((stage) => stage.value === targetHiringStageId);
  //   return (stage && stage.value) || null;
  // };

  const modalButtons = (
    <Styled.ButtonContainer>
      <Button
        className="submit-button"
        type="submit"
        size="small"
        onClick={handleOnConfirm}
        // loading={isLoadingStageUpdate}
        aria-label="Move to job"
        disabled={targetHiringStageId == undefined}
      >
        Move
      </Button>
      <Button styleType="secondary" onClick={handleOnCancel}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText={title} onCancel={handleOnCancel} hasUnsavedChanges={isDirty}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <FormContainer errors={errors} buttons={modalButtons} onSubmit={handleOnConfirm}>
        <FormSelect
          onChange={handleChangeJob}
          name="job"
          label="Select job"
          description={null}
          className=""
          value={targetJobId}
          options={jobsOptions}
          errors={errors}
          isClearable={false}
          autoFocus
        />

        <FormSelect
          onChange={handleChangeStage}
          name="jobStage"
          label="Select stage"
          description={null}
          className=""
          value={targetHiringStageId}
          options={hiringStagesOptions}
          errors={errors}
          isClearable={false}
        />
        <FeatureFlipper feature="HIRING_STAGE_AUTOMATIONS">
          <Styled.AutomationInfoContainer>
            <JobStageAutomationInfo
              stageAutomation={targetStageAutomation}
              stageName={targetStageName}
              bulk={false}
            />
          </Styled.AutomationInfoContainer>
        </FeatureFlipper>

        <FormCheckbox
          name="cloneInsteadOfMove"
          label="Keep a copy of the candidate in the current job"
          checked={cloneInsteadOfMove}
          onChange={handleOnClickCheckbox}
        />
      </FormContainer>
    </CenterModal>
  );
}

export default JobApplicationMoveModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMoveModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMoveModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.AutomationInfoContainer = styled.div`
  label: JobApplicationMoveModal_AutomationInfoContainer;
  margin-top: -1rem;
  height: 3rem;
`;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

import { userRoleOptions, userRoleNames } from "@ats/src/lib/lookups";

import InviteListItem from "@ats/src/views/accountAdmin/components/InviteListItem";
import MemberListItem from "@ats/src/views/accountAdmin/components/MemberListItem";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import InviteModal from "@ats/src/components/modals/InviteModal";
import ConfirmTransferOwnershipModal from "@ats/src/components/modals/ConfirmTransferOwnershipModal";
import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import Button from "@ats/src/components/shared/Button";

import {
  useAllInvites,
  useUpdateInvite,
  useResendInvite,
  useDestroyInvite,
} from "@shared/queryHooks/useInvite";

import { useUpdateUser, useDeactivateUser } from "@shared/queryHooks/useUser";
import {
  useUpdateOrganizationUser,
  useDeactivateOrganizationUser,
} from "@shared/queryHooks/useOrganizationUser";
import { useTransferOrganizationOwnership } from "@shared/queryHooks/useOrganization";

function AccountTeam(props) {
  const { currentUser, currentOrganization } = props;
  const currentUserId = currentUser?.id;
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  
  const [ state, setState ] = React.useState({
    currentOwnerId:  currentOrganization?.ownerId,
    currentUserRole: currentUser?.currentOrganizationUser?.role,
    isCurrentOwner: currentUser?.currentOrganizationUser?.role === "org_owner"
  })
  const { currentOwnerId, currentUserRole, isCurrentOwner } = state;
  
  /* Queries
  --===================================================-- */
  const { data: invitesData, isLoading: isLoadingInvites } = useAllInvites();
  const { mutate: updateInvite, isLoading: isLoadingUpdateInvite } = useUpdateInvite();
  const { mutate: resendInvite, isLoading: isLoadingResendInvite } = useResendInvite();
  const { mutate: destroyInvite, isLoading: isLoadingDestroyInvite } = useDestroyInvite();
  // const { data: userData, isLoading: isLoadingUser } = useGetUser();
  const { mutate: updateUser, isLoading: isLoadingUpdateUser } = useUpdateUser();
  const {
    mutate: updateOrganizationUser,
    isLoading: isLoadingUpdateOrganizationUser,
  } = useUpdateOrganizationUser();
  const { mutate: deactivateUser, isLoading: isLoadingDeactivateUser } = useDeactivateUser();
  const {
    mutate: deactivateOrganizationUser,
    isLoading: isLoadingDeactivateOrganizationUser,
  } = useDeactivateOrganizationUser();
  const {
    mutate: transferOwnership,
    isLoading: isLoadingTransferOwnership,
  } = useTransferOrganizationOwnership();

  window.logger("%c[AccountTeam] RENDER", "background-color: #7db4ec", {
    invitesData,
    isLoadingInvites,
    currentUser,
    currentOrganization,
    state
  });

  const inviteNodes = () => {
    if (invitesData == undefined) {
      return [];
    }

    return invitesData.items.map((invite) => {
      return (
        <InviteListItem
          key={invite.id}
          invite={invite}
          roles={userRoleOptions}
          resendInvite={handleResendInvite}
          destroyInvite={handleDestroyInvite}
          updateInviteRole={updateInviteRole}
          currentUser={currentUser}
        />
      );
    });
  };

  const memberNodes = () => {
    return currentOrganization.organizationUsers.map((organizationUser, index) => {
      return (
        <MemberListItem
          key={organizationUser.id}
          organizationUser={organizationUser}
          roles={userRoleOptions}
          updateOrganizationUserRole={handleUpdateOrganizationUserRole}
          deactivateOrganizationUser={handleDeactivateOrganizationUser}
          activateOrganizationUser={handleActivateOrganizationUser}
          transferOrgOwnership={handleClickTransferOwnership}
          isSelf={currentUserId === organizationUser.user.id}
          isOrgOwner={organizationUser.user.id === currentOwnerId}
          currentUser={currentUser}
          isCurrentOwner={isCurrentOwner}
          currentUserRole={currentUserRole}
        />
      );
    });
  };

  const handleUpdateOrganizationUserRole = ({ organizationUserId, role }) => {
    updateOrganizationUser(
      { id: organizationUserId, role },
      {
        onSuccess: (data) => {
          addToast({
            title: `Updated ${data.user.fullName}'s role to ${userRoleNames(data.role)}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleDeactivateOrganizationUser = (organizationUserId) => {
    deactivateOrganizationUser(
      { id: organizationUserId },
      {
        onSuccess: (data) => {
          addToast({
            title: `Successfully deactivated ${data.user.fullName}`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          if (response.data.errors.general) {
            addToast({ title: response.data.errors.general[0], kind: "warning" });
          }
        },
      },
    );
  };

  const handleActivateOrganizationUser = (organizationUserId) => {
    updateOrganizationUser(
      { id: organizationUserId, isActive: true },
      {
        onSuccess: (data) => {
          addToast({
            title: `Successfully re-activated ${data.user.fullName}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleClickTransferOwnership = (organizationUserId) => {
    const modal = (
      <ConfirmTransferOwnershipModal
        onConfirm={() => {
          removeModal();
          handleTranferOwnership(organizationUserId);
        }}
        onCancel={() => {
          removeModal();
        }}
      />
    );

    openModal(modal);
  };

  const handleTranferOwnership = (organizationUserId) => {
    window.logger("%c[AccountTeam] handleTransferOwnership", "background-color: #7db4ec", {
      organizationUserId,
    });

    transferOwnership(
      { id: currentOrganization.id, organizationUserId: organizationUserId },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountTeam] handleTransferOwnership", "background-color: #7db4ec", {
            data,
          });
          setState({
            currentOwnerId: data?.ownerId,
            currentUserRole: "org_admin",
            isCurrentOwner: false,
          });
          addToast({
            title: `Successfully transferred ownership.`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          if (response.data.errors) {
            addToast({ title: response.data.errors[0], kind: "warning" });
          }
        },
      },
    );

  }

  /* INVITES
  --===================================================-- */
  const updateInviteRole = (inviteId, invite) => {
    updateInvite(
      { id: inviteId, ...invite },
      {
        onSuccess: (data) => {
          addToast({
            title: `Updated role to ${userRoleNames(invite.role)}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleResendInvite = (inviteId) => {
    resendInvite(
      { id: inviteId },
      {
        onSuccess: (data) => {
          addToast({ title: `Resent invitation to ${data.email}`, kind: "success" });
        },
      },
    );
  };

  const handleDestroyInvite = (inviteId) => {
    destroyInvite(
      { id: inviteId },
      {
        onSuccess: (data) => {
          addToast({
            title: `Canceled invitation for ${data.email}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleClickOnInvite = (e) => {
    e.preventDefault();

    const modal = (
      <InviteModal
        headerTitleText="Invite team member"
        onCancel={removeModal}
      // onCompleteInvite={handleSubmitNewInviteForm}
      />
    );

    openModal(modal);
  };

  const InviteTeamMemberButton = currentUser.currentOrganizationUser.isAdmin ? (
    <Button type="button" styleType="secondary" onClick={handleClickOnInvite}>
      Invite new member
    </Button>
  ) : null;

  const SidebarContent = (
    <Styled.Sidebar>
      <h3>Team roles</h3>
      <p><b>Interviewers</b> can only submit candidate reviews. They receive access via review requests.</p>
      <p>
        <b>Members</b> can only access jobs they have been assigned to. They can not invite new
        members or assign members to jobs.
      </p>
      <p>
        <b>Admins</b> and the account <b>Owner</b> have access to all jobs and can create new jobs.
        They can invite new members, set member roles, and can assign hiring team members to jobs.
      </p>
    </Styled.Sidebar>
  );

  return (
    <>
      <Helmet title="Team" />
      <SettingsContainer
        title="Team management"
        description="This section is for managing who from your organization can log in and use your Polymer account."
        sidebar={SidebarContent}
      >
        <FormSection>
          <Styled.Team data-testid="team-members">
            {memberNodes()}
            {inviteNodes()}
          </Styled.Team>
          {InviteTeamMemberButton}
        </FormSection>
      </SettingsContainer>
    </>
  );
}

export default AccountTeam;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Team = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountTeam;
    ${[t.my(8), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
  `;
});

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountTeam_Sidebar;
    ${t.px(4)};
    position: sticky;
    top: ${t.spacing[4]};
    h3 {
      ${[t.mt(1), t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
    p {
      ${[t.text.xs, t.mt(3)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    b {
      ${[t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

import { history } from "prosemirror-history";
import { dropCursor } from "prosemirror-dropcursor";
import { gapCursor } from "prosemirror-gapcursor";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { keymap } from "prosemirror-keymap";
// import { columnResizing, tableEditing } from "prosemirror-tables";
// import { placeholder } from "@aeaton/prosemirror-placeholder";
// import { footnotes } from "@aeaton/prosemirror-footnotes";

// import "prosemirror-tables/style/tables.css";
import "prosemirror-gapcursor/style/gapcursor.css";
// import "@aeaton/prosemirror-footnotes/style/footnotes.css";
// import "@aeaton/prosemirror-placeholder/style/placeholder.css";

import { standardKeys } from "./keys";
import rules from "./rules";

export const placeholder = (className = "empty-node") => {
  return new Plugin({
    props: {
      decorations: (state) => {
        const decorations = [];

        // window.logger("%c[ProseMirror][plugins.ts] placeholder", "color: #1976D2", {
        //   state,
        //   doc: state.doc,
        //   childCount: state.doc.childCount,
        // });

        const decorate = (node, pos) => {
          if (node.type.isBlock && node.childCount === 0 && state.doc.childCount === 1) {
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: className,
              }),
            );
          }
        };

        state.doc.descendants(decorate);

        return DecorationSet.create(state.doc, decorations);
      },
    },
  });
};

export const basePlugins = [
  rules,
  // keys,
  // placeholder(),
  // footnotes(),
  dropCursor(),
  gapCursor(),
  history(),
  // columnResizing(),
  // tableEditing(),
];

export default [...basePlugins, keymap(standardKeys)];

// for tables
// document.execCommand("enableObjectResizing", false, "false");
// document.execCommand("enableInlineTableEditing", false, false);

import React from "react";

import FullModal from "@ats/src/components/modals/FullModal";
import CandidateForm from "@ats/src/views/jobApplications/candidates/CandidateForm";

import { useModalContext } from "@shared/context/ModalContext";
import { useCreateCandidate } from "@shared/queryHooks/useCandidate";
import { Candidate } from "@shared/types/candidate";
import { useUpdateJobApplication } from "@shared/queryHooks/useJobApplication";

type Props = {
  onCancel: () => void;
  onComplete: (number) => void;
  history: any;
  match: any;
  jobId: number;
};

function NewCandidateModal(props: Props) {
  const { onCancel } = props;
  const [errors, setErrors] = React.useState(null);
  const [isDirty, setIsDirty] = React.useState(false);
  const [candidate, setCandidate] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    linkedinUrl: "",
    twitterUrl: "",
    githubUrl: "",
    dribbbleUrl: "",
    websiteUrl: "",
    errors: null,
  });
  const [jobApplication, setJobApplication] = React.useState({
    source: "",
    desiredCompensation: "",
    settings: {
      overview: "public",
      messages: "public",
      compensation: "public",
    },
  });

  const { mutate: createCandidate, isLoading: isLoadingCreateCandidate } = useCreateCandidate();
  const {
    mutate: updateJobApplication,
    isLoading: isLoadingUpdateJobApplication,
  } = useUpdateJobApplication();

  const { dismissModalWithAnimation } = useModalContext();

  const handleCancel = () => {
    dismissModalWithAnimation(() => onCancel);
  };

  const handleCandidateChange = (updates) => {
    // window.logger("%c[NewCandidateModal] updates", "color: #1976D2", updates);
    setCandidate({ ...candidate, ...updates });
    setIsDirty(true);
  };

  const handleJobApplicationChange = (updates) => {
    setJobApplication({ ...jobApplication, ...updates });
  };

  const handleUpdateJobApplication = (applicationId: number) => {
    updateJobApplication(
      { ...jobApplication, id: applicationId },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[NewCandidateModal] handleUpdateJobApplication onSuccess application",
            "color: #1900D2",
            {
              data,
            },
          );
          setErrors(null);

          dismissModalWithAnimation(() => props.onComplete(data));
        },
        onError: (response: any) => {
          setErrors(response.data.errors);
        },
      },
    );
  };

  const onSubmit = () => {
    createCandidate(
      { jobId: props.jobId, candidate },
      {
        onSuccess: (data: Candidate) => {
          window.logger("%c[NewCandidateModal] onSubmit onSuccess candidate", "color: #1900D2", {
            data,
          });
          setErrors(null);
          handleUpdateJobApplication(
            data.jobApplications.find((application) => application.jobId === props.jobId).id,
          );
        },
        onError: (response: any) => {
          // console.error("%c[NewCandidateModal] createCandidate ERROR", "color: #1976D2", {
          //   response,
          // });
          setErrors(response.data.errors);
        },
      },
    );
  };

  return (
    <FullModal onCancel={handleCancel} hasUnsavedChanges={isDirty}>
      <CandidateForm
        formTitle="Add new candidate"
        onSubmit={onSubmit}
        onCandidateChange={handleCandidateChange}
        onApplicationChange={handleJobApplicationChange}
        onCancel={handleCancel}
        isEditing={false}
        candidate={candidate}
        jobApplication={jobApplication}
        errors={errors}
        loading={isLoadingCreateCandidate || isLoadingUpdateJobApplication}
      />
    </FullModal>
  );
}

export default NewCandidateModal;

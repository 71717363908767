import React, { Component } from "react";
import PropTypes from "prop-types";
import { Droppable, DragDropContext } from "react-beautiful-dnd";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default class QuestionList extends Component {
  static propTypes = {
    onDragEndQuestion: PropTypes.func.isRequired,
  };

  onDragEnd = (result) => {
    this.props.onDragEndQuestion(result);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="questions-droppable-id">
          {(provided) => (
            <Styled.Container ref={provided.innerRef} {...provided.droppableProps}>
              {this.props.children}
              {provided.placeholder} {/* Increases space for dropping */}
            </Styled.Container>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionList;
    ${t.rounded.sm}
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
  `;
});

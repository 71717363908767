import React from "react";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import BulkMessageModal from "@ats/src/components/modals/BulkMessageModal";
import BulkMoveModal from "@ats/src/components/modals/BulkMoveModal";

import { hiringStagesForJob } from "@ats/src/lib/utils/helpers";

import { useModalContext } from "@shared/context/ModalContext";
import { ListState } from "@shared/hooks/useChecklist";

function JobStageMenu(props: {
  job?: { id: number };
  currentStage: any;
  jobApplications: [any];
  listState: ListState;
  history: History;
  resetList: () => void;
}) {
  const {
    jobApplications,
    job,
    currentStage,
    listState: { itemIdsSet, itemIdsSetType, allSelected },
    history,
  } = props;
  const { openModal, removeModal } = useModalContext();
  // const job = useJobStore(React.useCallback((state) => state.jobsById[jobId], [jobId])) as any;
  // Get the Job Application to move focus to after move
  const firstJobApplicationNotSelected = React.useMemo(() => {
    if (itemIdsSetType === "included") {
      return jobApplications.find((jobApplication) => !itemIdsSet.has(jobApplication.id));
    } else {
      return jobApplications.find((jobApplication) => itemIdsSet.has(jobApplication.id));
    }
  }, [jobApplications, itemIdsSetType, itemIdsSet]);

  const firstRemainingJobApplicationUrl =
    firstJobApplicationNotSelected != undefined &&
    `/jobs/${firstJobApplicationNotSelected.jobId}/stages/${firstJobApplicationNotSelected.hiringStageId}/applicants/${firstJobApplicationNotSelected.id}`;

  const hiringStages = React.useMemo(() => hiringStagesForJob(job), [job]);
  const itemIds = Array.from(itemIdsSet);

  // window.logger("%c[JobStageMenu] Selection ", "background: #EFDDEF; color: #197682", {
  //   allSelected,
  //   currentStageCount: currentStage.jobApplicationsCount,
  //   setSize: itemIdsSet.size,
  //   firstJobApplicationNotSelected,
  // });

  const candidatesCount =
    allSelected
    ? currentStage.jobApplicationsCount
    : itemIdsSetType === "included" && itemIds.length > 0
    ? itemIds.length
    : itemIdsSetType === "excluded" && itemIds.length > 0
    ? currentStage.jobApplicationsCount - itemIds.length
    : 0;

  const handleOnClickMessageAll = (event) => {
    event.preventDefault();

    const modal = (
      <BulkMessageModal
        jobId={job.id}
        hiringStageId={currentStage.id}
        onCancel={() => removeModal()}
        jobApplications={jobApplications}
        idsArray={itemIds}
        idsArrayType={itemIdsSetType}
        candidatesCount={candidatesCount}
      />
    );

    openModal(modal);
  };

  const handleOnClickMoveAll = (event) => {
    event.preventDefault();

    const modal = (
      <BulkMoveModal
        jobId={job.id}
        onCancel={() => removeModal()}
        currentStage={currentStage}
        hiringStages={hiringStages}
        idsArray={itemIds}
        idsArrayType={itemIdsSetType}
        resetList={props.resetList}
        candidatesCount={candidatesCount}
        firstRemainingJobApplicationUrl={firstRemainingJobApplicationUrl}
        history={history}
      />
    );

    openModal(modal);
  };

  return (
    <DropdownMenu label="Bulk options">
      <button onClick={handleOnClickMessageAll}>Message candidates</button>
      <button onClick={handleOnClickMoveAll}>Move candidates to stage</button>
    </DropdownMenu>
  );
}

JobStageMenu.propTypes = {};

JobStageMenu.defaultProps = {};

export default JobStageMenu;

import React from "react";
// import PropTypes from "prop-types";
import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";
import { DirectUpload } from "activestorage";
import { useMachine } from "@xstate/react";
import { Machine } from "xstate";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import FormLabel from "@ats/src/components/forms/FormLabel";

import { isAllowedFileSize, isAllowedFileType } from "@ats/src/lib/utils/fileUploads";

type Props = {
  displayType?: string;
  allowAutomaticUploading: boolean;
  htmlFor?: string; // used to connect the input and the uploader
  file?: any;
  fileName?: string;
  fileIsAlreadyUploaded: boolean;
  isRequired?: boolean;
  requiredLabel?: string;
  loading: boolean;
  name: string;
  label?: string;
  placeholder: {} | string;
  description?: string;
  tabIndex?: string;
  onChange?: ({}) => void;
  onCompleteDirectUpload: any;
  onStartDirectUpload?: (loading: boolean) => void;
  onInvalidFile: (file, expectedFileType) => void;
  errors: any;
  children?: any;
  expectedFileType: string;
  maxFileSize?: number;
};

export default function FormUploader(props: Props) {
  // window.logger("%c[FormUploader] ", "color: #1976D2", { props });
  const {
    displayType,
    allowAutomaticUploading,
    tabIndex,
    placeholder,
    htmlFor,
    file,
    fileIsAlreadyUploaded,
    fileName, // useful for when displaying information for an already uploaded file
    label,
    isRequired,
    description,
    loading,
    children,
    expectedFileType,
    maxFileSize,
    onStartDirectUpload,
    requiredLabel = "required",
  } = props;

  const uploadMachine = Machine(
    {
      id: "upload",
      initial: "noFile",
      states: {
        noFile: {
          on: {
            ATTACH_FILE: "fileAttachedForUpload",
            AUTO_UPLOAD_FILE: "newFileIsUploading",
            HAS_EXISTING_FILE: "existingFile",
          },
        },
        existingFile: {
          on: {
            ATTACH_FILE: "fileAttachedForUpload",
            AUTO_UPLOAD_FILE: {
              target: "replacementFileIsUploading",
              actions: ["sendOnLoadingCallback"],
            },
          },
        },
        fileSuccessfullyUploaded: {
          on: {
            ATTACH_FILE: "fileAttachedForUpload",
            AUTO_UPLOAD_FILE: {
              target: "replacementFileIsUploading",
              actions: ["sendOnLoadingCallback"],
            },
          },
        },
        fileAttachedForUpload: {
          on: { INVALID_FILE: "noFile" },
        },
        newFileIsUploading: {
          on: { UPLOAD_COMPLETE: "fileSuccessfullyUploaded" },
        },
        replacementFileIsUploading: {
          on: { UPLOAD_COMPLETE: "fileSuccessfullyUploaded" },
        },
      },
    },
    {
      actions: {
        sendOnLoadingCallback: (context, event) => {
          window.logger("%c[FormUploader] sendOnLoadingCallback", "color: #1976D2", {
            context,
            event,
          });
          const isLoading = event.type === "AUTO_UPLOAD_FILE";
          if (onStartDirectUpload != undefined) {
            onStartDirectUpload(isLoading);
          }
        },
      },
    },
  );

  const [state, send] = useMachine(uploadMachine);

  const [fileToUpload, setFileToUpload] = React.useState(file);

  let errorText = errorTextViaYupOrRailsOrJoi(props);

  React.useEffect(() => {
    setFileToUpload(file);

    if (fileIsAlreadyUploaded) {
      send("HAS_EXISTING_FILE");
    }
  }, [file, fileIsAlreadyUploaded, send]);

  // window.logger("%c[FormUploader] STATE MACHINE value", "color: #1976D2", { value: state.value });

  const fileExists = [
    "existingFile",
    "fileAttachedForUpload",
    "fileSuccessfullyUploaded",
    "replacementFileIsUploading",
  ].includes(String(state.value));

  const buttonText =
    displayType !== "button"
      ? placeholder
      : file != null && fileToUpload != null && fileExists
      ? fileToUpload.name
      : fileIsAlreadyUploaded && fileName
      ? fileName
      : placeholder;

  // const fileTypes = {
  //   image: ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"],
  //   document: [
  //     "application/pdf",
  //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //   ],
  // };

  // const trimFilename = (filename) => {
  //   let cutStart, cutEnd;

  //   if (filename.length > 20) {
  //     cutStart = 10;
  //     cutEnd = filename.length - 7;
  //     return filename.substr(0, cutStart) + "..." + filename.substr(cutEnd + 1);
  //   } else {
  //     return filename;
  //   }
  // };

  // const isAllowedFileType = (expectedFileType, fileType) => {
  //   return expectedFileType === undefined ? true : fileTypes[expectedFileType].includes(fileType);
  // };

  /* Handle File Input Change
  --===================================================-- */
  const handleFileInputChange = (e) => {
    e.preventDefault();
    const file = e.currentTarget.files[0];
    const upload = new DirectUpload(
      file,
      `/api/v1/public/rails/active_storage/direct_uploads`,
      this,
    );

    if (
      file &&
      isAllowedFileType(expectedFileType, file.type) &&
      isAllowedFileSize(maxFileSize, file.size)
    ) {
      if (props.onChange) props.onChange({ fileToUpload: file });

      if (allowAutomaticUploading) {
        send("AUTO_UPLOAD_FILE");
        upload.create((error, blob) => {
          if (error) {
            console.error("[FormUploader] handleFileInputChange directUpload error", error);
          } else {
            // window.logger('%c[FormUploader] uploaded file', 'color: #1976D2', { blob });
            props.onCompleteDirectUpload(blob).then((data) => {
              window.logger("%c[FormUploader] onCompleteDirectUpload", "color: #1976D2", { data });
              if (data !== undefined) send("UPLOAD_COMPLETE");
            });
          }
        });
      } else {
        setFileToUpload(file);
        send("ATTACH_FILE");
      }
    } else {
      send("INVALID_FILE");
      props.onInvalidFile(file, expectedFileType);
    }
  };

  return (
    <Styled.Container displayType={displayType}>
      {label && (
        <FormLabel
          label={label}
          error={errorText}
          isRequired={isRequired}
          requiredLabel={requiredLabel}
        />
      )}
      {description && <p>{description}</p>}
      <Styled.UIContainer displayType={displayType}>
        <Styled.Input
          id={htmlFor || "file"}
          type="file"
          name="file"
          onChange={handleFileInputChange}
          tabIndex={tabIndex}
        />
        <Styled.UploadButton
          hasError={errorText}
          displayType={displayType}
          fileExists={fileExists}
          htmlFor={htmlFor || "file"}
        >
          {displayType === "button" && <Icon name="paperclip" />}
          <Styled.UploadButtonLabel>{buttonText}</Styled.UploadButtonLabel>
        </Styled.UploadButton>

        {loading && (
          <Styled.UploadStatus>
            <Icon name="loader" />
          </Styled.UploadStatus>
        )}
        {!loading && displayType === "button" && state.value === "fileSuccessfullyUploaded" && (
          <Styled.UploadStatus>
            <Icon name="check-circle" />
            <span>Uploaded</span>
          </Styled.UploadStatus>
        )}
        {!loading && displayType === "button" && state.value === "fileAttachedForUpload" && (
          <Styled.UploadStatus>
            <Icon name="check-circle" />
            <span>{window.APP_TRANSLATIONS.file_attached_label}</span>
          </Styled.UploadStatus>
        )}
      </Styled.UIContainer>
      {children}
      {/* For displaying Joi rendered errors. See NewInviteForm example. */}
    </Styled.Container>
  );
}

FormUploader.defaultProps = {
  // allowAutomaticUploading: true,
  displayType: "button",
};

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: FormUploader;
    ${props.displayType === "dropdownLink" ? "margin-bottom: 2px" : t.mb(6)};
    > p {
      ${[t.text.sm, t.mt(-1), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq["md"]} {
        ${t.text.xs}
      }
    }

    ${t.mq["md"]} {
      ${props.displayType === "dropdownLink" ? "margin-bottom: 2px" : t.mb(5)};
    }
  `;
});

Styled.UIContainer = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: FormUploader_UI;
    display: flex;
    align-items: center;

    [type="file"]:focus + label,
    [type="file"]:focus + label:hover {
      transition: border-color 0.2s ease, box-shadow 0.2s;
      ${props.displayType === "dropdownLink"
        ? "text-decoration: underline;"
        : t.dark
        ? ""
        : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`};
      border-color: ${t.dark ? t.color.gray[500] : t.color.gray[400]};
    }
  `;
});

Styled.UploadButton = styled.label((props: any) => {
  const t: any = props.theme;
  const base = css`
    label: FormUploader_UploadButton;
    border: none;
    appearance: none;
    outline: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
  `;

  if (props.displayType === "dropdownLink") {
    return css`
      ${[t.text.medium, base]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      background-color: transparent;
      &:hover {
        text-decoration: underline;
      }
    `;
  } else {
    return props.fileExists
      ? css`
          ${[t.h(10), t.text.base, t.rounded.sm, base]};
          padding: 0 ${t.spacing[2]};
          width: 100%;
          color: ${t.dark ? t.color.gray[200] : t.color.black};
          border: 1px solid ${t.dark ? "transparent" : t.color.gray[300]};
          background-color: ${t.dark ? "rgba(255,255,255,0.08)" : t.color.white};
          transition: background-color 0.2s ease, border-color 0.2s ease;
          svg {
            margin-right: ${t.spacing[1]};
          }
          &:hover {
            border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
          }

          ${t.mq["md"]} {
            ${[t.h(8), t.text.sm]}
          }
        `
      : css`
          ${[t.h(10), t.text.base, t.rounded.sm, base]}
          padding: 0 ${t.spacing[4]};
          transition: background-color 0.2s ease;
          background-color: ${t.dark ? t.color.gray[700] : t.color.gray[200]};
          font-weight: 450;
          color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[200]
            : t.color.black};
          svg {
            display: none;
          }
          &:hover {
            background-color: ${t.dark ? t.color.gray[600] : t.color.gray[300]};
            color: ${t.dark ? t.color.white : t.color.black};
          }

          ${t.mq["md"]} {
            ${[t.h(8), t.text.sm]}
          }
        `;
  }
});

Styled.UploadButtonLabel = styled.span`
  label: FormUploader_UploadButtonLabel;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

Styled.Input = styled.input`
  label: FormUploader_Input;
  position: absolute;
  margin: -1px;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
`;

Styled.UploadStatus = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: FormUploader_UploadStatus;
    ${t.ml(3)}
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    svg {
      ${t.mr(1)}
    }
  `;
});

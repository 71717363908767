import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { yesOrNoOptions } from "@ats/src/lib/lookups";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";
import { useDeleteOauthAuthentication } from "@shared/queryHooks/useOauth";
import OauthConnectButton from "@shared/components/OauthConnectButton";

import {
  useDiscordChannelList,
  useCreateDiscordChannelIntegration,
} from "@shared/queryHooks/useDiscord";
// import {
//   useDiscordChannelList,
//   useCreateDiscordChannelIntegration,
// } from "@shared/queryHooks/useDiscord";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSection from "@ats/src/components/forms/FormSection";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import Icon from "@ats/src/components/shared/Icon";

function AccountIntegrationsDiscord(props) {
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const { currentOrganization } = useCurrentSession();
  const [settings, setSettings] = React.useState(currentOrganization.settings);

  const {
    data: discordChannelList,
    isLoading: isLoadingDiscordChannelList,
    refetch: refetchDiscordChannelList,
  } = useDiscordChannelList({
    enabled: currentOrganization?.hasDiscordAuthentication,
  });
  const {
    mutate: createDiscordChannelIntegration,
    isLoading: isLoadingCreateDiscordChannelIntegration,
  } = useCreateDiscordChannelIntegration();
  const {
    mutate: deleteOauthAuthentication,
    isLoading: isLoadingDeleteOauthAuthentication,
  } = useDeleteOauthAuthentication();

  window.logger("%c[AccountIntegrationsDiscord] ", "color: #1976D2", {
    discordChannelList,
    currentOrganization,
  });

  const channelOptions = () => {
    return discordChannelList.map((channel) => {
      return { value: String(channel.id), label: `#${channel.name}` };
    });
  };

  const handleChannelSelection = (fieldName, value) => {
    // if (value === siteId) {
    //   return;
    // }

    const channel = discordChannelList.find((channel) => String(channel.id) === String(value));
    const { id, name } = channel;
    window.logger("%c[AccountIntegrationsDiscord] ", "color: #1976D2", {
      fieldName,
      value,
      channel,
    });

    createDiscordChannelIntegration(
      {
        channelId: id,
        channelName: name,
      },
      {
        onSuccess: (data) => {
          window.logger("%c[AIS] created Discord Channel ", "color: #1976D2", { data });
        },
      },
    );
  };

  /* Refetch the Discord Channel List if it is empty
  --===================================================-- */
  React.useEffect(() => {
    if (discordChannelList != undefined && discordChannelList.length == 0) {
      refetchDiscordChannelList();
    }
  }, [discordChannelList, refetchDiscordChannelList]);

  const handleDisconnectDiscord = (event) => {
    event.preventDefault();

    const modal = (
      <ConfirmationModal
        title="Are you sure you want to disconnect?"
        subcopy="New job applications will no longer post to Discord."
        confirmText="Disconnect"
        isDestructiveConfirm={true}
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          deleteOauthAuthentication(
            {
              provider: "discord",
            },
            {
              onSuccess: (data) => {
                window.logger("%c[AIS] deleted Discord Oauth Authentication", "color: #1976D2", {
                  data,
                });
              },
            },
          );
        }}
        onCancel={removeModal}
      />
    );

    openModal(modal);
  };

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://help.polymer.co/en/articles/5721747-have-new-candidate-notifications-show-up-in-a-discord-server"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>
      {/* <Button onClick={() => refetchDiscordChannelList()}>Refresh channel list</Button> */}
    </>
  );

  const connectionActions = (
    <>
      {!currentOrganization.hasDiscordAuthentication ? (
        // <Button
        //   className="submit-button"
        //   type="externalLink"
        //   target=""
        //   link={`/auth/discord?current_organization_id=${currentOrganization.id}`}
        //   aria-label="Connect to Discord"
        // >
        //   Connect to Discord
        // </Button>

        <OauthConnectButton
          path={`/auth/discord?current_organization_id=${currentOrganization.id}`}
          label="Connect to Discord"
        />
      ) : (
        <Styled.Action>
          <Button
            className="submit-button"
            styleType="secondary"
            onClick={handleDisconnectDiscord}
            aria-label="Disconnect Discord"
            loading={
              isLoadingDeleteOauthAuthentication ||
              isLoadingDiscordChannelList ||
              isLoadingCreateDiscordChannelIntegration
            }
            disabled={
              isLoadingDeleteOauthAuthentication ||
              isLoadingDiscordChannelList ||
              isLoadingCreateDiscordChannelIntegration
            }
          >
            Disconnect Discord
          </Button>
        </Styled.Action>
      )}
    </>
  );

  return (
    <SettingsContainer
      title="Discord"
      description="Notify a Discord channel whenever a new job application is received. The full candidate application is displayed, making it easy to review and discuss."
      actions={ViewLink}
      fullWidthForm={true}
    >
      {currentOrganization.hasDiscordAuthentication && !isLoadingDiscordChannelList ? (
        discordChannelList != undefined && discordChannelList.length > 0 ? (
          <Styled.Field>
            <FormSection>
              <FormSelect
                onChange={handleChannelSelection}
                name="channelId"
                label="Channel"
                description="Select the Discord channel to post job applications to"
                className=""
                value={currentOrganization?.discordChannelIntegration?.channelId}
                options={channelOptions()}
                errors={null}
              />
            </FormSection>
          </Styled.Field>
        ) : (
          <Styled.Action>
            <Button onClick={() => refetchDiscordChannelList()}>Refresh channel list</Button>
          </Styled.Action>
        )
      ) : null}
      {connectionActions}
    </SettingsContainer>
  );
}

export default AccountIntegrationsDiscord;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Field = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsDiscord_Field;
    margin-bottom: -${t.spacing[6]};
  `;
});

Styled.Action = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsDiscord_Action;
    ${t.mr(3)};
    display: inline-block;
    &:before {
      ${t.h(6)}
      content: "";
      display: block;
    }
  `;
});

import React from "react";
import indefinate from "indefinite";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@ats/src/components/shared/Button";

import Icon from "@ats/src/components/shared/Icon";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

export default function JobShareLinks(props) {
  const { job } = props;
  window.logger("%c[JobShareLinks] ", "background: #EFDDEF; color: #1976D2", { props });
  const { currentOrganization } = useCurrentSession();

  const jobUrl = `${currentOrganization?.careersPageUrl}/${job.id}`;

  //names - two versions - of ised anywhere other than in the tweet the subbed name should be used
  const companyName = currentOrganization?.name
  // const subbedCompanyName = companyName.replace(new RegExp("&", "g"), "and")
  const jobTitle = job?.title
  // const subbedJobTitle = jobTitle.replace(new RegExp("&", "g"), "and")

  //add "a" or "an"
  const articlerizedJobTitle = (title) => {
    if (title) {
      return indefinate(title)
    } else {
      return title
    };
  };


  // const metaDescription = `${subbedCompanyName} is hiring ${articlerizedJobTitle(subbedJobTitle)}`
  const tweet = `${companyName} is hiring ${articlerizedJobTitle(jobTitle)}` //Twitter can handle the "&" ampersand symbol

  // const handleShareToEmailClick = () => {
  //   const emailShareUrl = `${jobUrl}?source=employee_email`
  //   const emailBody = `Take a look at this job opening at ${subbedCompanyName}:  ${emailShareUrl}`
  //   window.open(`mailto:?subject=${metaDescription}&body=${emailBody}`, "_blank");
  // };

  const handleShareToTwitterClick = () => {
    const twitterShareUrl = `${jobUrl}?source=twitter`
    const tweetParam = encodeURIComponent(tweet)
    window.open(`https://twitter.com/intent/tweet?text=${tweetParam}&url=${twitterShareUrl}`, "_blank");
  };

  const handleShareToLinkedinClick = () => {
    const linkedinShareUrl = `${jobUrl}?source=linkedin`
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${linkedinShareUrl}`, "_blank");
  };

  return (
    <>
      <Styled.JobShareLinksContainer>
        {/* <Styled.ShareLink>
          <Icon name="mail" />
          <button onClick={handleShareToEmailClick} data-testid="email-job-link">Share via email </button>
        </Styled.ShareLink> */}
        <Button styleType="secondary" onClick={handleShareToTwitterClick} data-testid="twitter-job-link">
          <Styled.ShareLabel>
            <Icon name="twitter" />Tweet this job
          </Styled.ShareLabel>
        </Button>
        <Button styleType="secondary" onClick={handleShareToLinkedinClick} data-testid="linkedin-job-link">
          <Styled.ShareLabel>
            <Icon name="linkedin" />Share on LinkedIn
          </Styled.ShareLabel>
        </Button>
      </Styled.JobShareLinksContainer>
    </>
  )
};

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.JobShareLinksContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobShareLinks_JobShareLinksContainer;
    ${t.mb(6)};
    display: flex;
    flex-direction: column;

    button {
      ${t.mb(3)};
      &:last-of-type {
        ${t.mb(0)};
      }
    }

    ${t.mq['md']} {
      flex-direction: row;

      button {
        ${[t.mr(3), t.mb(0)]};
        &:last-of-type {
          ${t.mr(0)};
        }
      }
    }
  `;
});

Styled.ShareLabel = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobShareLinks_ShareLabel;
    display: flex;
    align-items: center;
    svg {
      ${t.mr(2)}
    }
  `;
});
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut, apiDelete } from "@shared/queryHooks/api";

const getCommentTemplates = async () => {
  return await apiGet({ path: "/comment_templates" });
};

const getCommentTemplate = async ({ commentTemplateId }) => {
  if (commentTemplateId) {
    return await apiGet({ path: `/comment_templates/${commentTemplateId}` });
  }
};

const createCommentTemplate = async (variables) => {
  return await apiPost({ path: "/comment_templates", variables: { ...variables, kind: "review" } });
};

const updateCommentTemplate = async (variables) => {
  return await apiPut({ path: `/comment_templates/${variables.id}`, variables });
};

const deleteCommentTemplate = async (variables) => {
  return await apiDelete({ path: `/comment_templates/${variables.id}`, variables });
};

// const getMailMerge = async ({ jobApplicationId, channelMessageTemplateId }) => {
//   return await apiGet({s
//     path: `/job_applications/${jobApplicationId}/comment_templates_mail_merge/${channelMessageTemplateId}`,
//   });
// };

/* Hooks
--===================================================-- */
export function useCommentTemplates(): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery("channelMessageTemplates", getCommentTemplates);
}

export function useCommentTemplate({
  commentTemplateId,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["channelMessageTemplates", commentTemplateId], () =>
    getCommentTemplate({ commentTemplateId }),
  );
}

export function useCreateCommentTemplate() {
  const queryClient = useQueryClient();
  return useMutation(createCommentTemplate, {
    // After success or failure, refetch the channelMessageTemplates query
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

export function useUpdateCommentTemplate() {
  const queryClient = useQueryClient();
  return useMutation(updateCommentTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

export function useDeleteCommentTemplate() {
  const queryClient = useQueryClient();
  return useMutation(deleteCommentTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

// /* Mail Merged Template
// --===================================================-- */
// export function useMailMerge({
//   jobApplicationId,
//   channelMessageTemplateId,
// }): {
//   status: any;
//   data: any;
//   error: any;
//   isFetching: boolean;
//   isLoading: boolean;
//   isSuccess: boolean;
// } {
//   return useQuery(
//     ["mailmerge", { jobApplicationId, channelMessageTemplateId }],
//     () => getMailMerge({ jobApplicationId, channelMessageTemplateId }),
//     {
//       enabled: channelMessageTemplateId != undefined,
//     },
//   );
// }

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import NavBar from "@shared/components/NavBar";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import ReviewKit from "@ats/src/views/reviews/ReviewKit";
import FriendlyErrors from "@ats/src/components/shared/FriendlyErrors";

import { useJobApplication } from "@shared/queryHooks/useJobApplication";
import { useComment, useReviewDraft } from "@shared/queryHooks/useComment";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
// import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";
// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function ReviewKitContainer({ match, history, location }) {
  // useWhyDidYouUpdate("ReviewKitContainer ", props);

  // window.logger("%c[ReviewKitContainer] ", "background: #EFDDEF; color: #1976D2", {
  //   history,
  //   location,
  //   match,
  // });
  const { currentOrganizationUser } = useCurrentSession();

  const [returnPathname] = React.useState(
    location && location.state ? location.state.orginalPathname : "/jobs",
  );

  const [orgAdminJobsListUrl] = React.useState(
    location && location.state ? location.state.orgAdminJobsListUrl : "",
  );

  const jobApplicationHashId = match.params.jobApplicationHashId;
  const commentId = match.params.commentId;
  const isDraft = commentId === "new"; // If the commentId is the word "new" it means we are creating a new Review

  const {
    data: jobApplication,
    isLoading: isLoadingJobApplication,
    error: errorGettingJobApplication,
  } = useJobApplication({
    jobApplicationId: jobApplicationHashId,
    // initialData: jobApplicationFromStore,
    initialData: null,
  });

  const { data: comment, isLoading: isLoadingComment, error: errorGettingComment } = useComment({
    jobApplicationId: jobApplicationHashId,
    commentId,
    enabled: !isDraft,
  });

  const { data: commentDraft, isLoading: isLoadingCommentDraft } = useReviewDraft({
    jobApplicationId: jobApplicationHashId,
    enabled: isDraft,
  });

  window.logger("%c[ReviewKitContainer] render", "background-color: #19FFD2", {
    //TODO
    jobApplication,
    jobApplicationHashId,
    comment,
    commentDraft,
    commentId,
    isDraft,
  });

  const title = <h2>Review kit</h2>;

  if (errorGettingComment) {
    return (
      <FriendlyErrors
        statusCode={errorGettingComment.response.status}
        resource="review"
        role={currentOrganizationUser?.role}
      />
    );
  } else if (errorGettingJobApplication) {
    return (
      <FriendlyErrors
        statusCode={errorGettingJobApplication.response.status}
        resource="candidate"
        role={currentOrganizationUser?.role}
      />
    );
  }

  return (
    <>
      {currentOrganizationUser?.role !== "org_interviewer" ? (
        <NavBar
          isBack={false}
          backUrl={""}
          closeUrl={returnPathname}
          history={history}
          title={title}
          orgAdminJobsListUrl={orgAdminJobsListUrl} //allows value to be pushed back to originating job on close of review kit
        />
      ) : null}
      <Styled.Container>
        {isLoadingJobApplication ||
          isLoadingComment ||
          isLoadingCommentDraft ||
          jobApplication == undefined ? (
          <LoadingIndicator label="Loading..." />
        ) : (
          <ReviewKit
            jobApplication={jobApplication}
            review={comment || commentDraft}
            isDraft={isDraft}
            returnPathname={returnPathname}
            history={history}
            orgAdminJobsListUrl={orgAdminJobsListUrl}
            role={currentOrganizationUser?.role}
          />
          // <div>Coming</div>
        )}
      </Styled.Container>
    </>
  );
}

ReviewKitContainer.propTypes = {};

ReviewKitContainer.defaultProps = {};

export default ReviewKitContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ReviewKitContainer;
    display: flex;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  `;
});

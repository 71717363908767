import React from "react";
import { CurrentUser, CurrentOrganizationUser } from "@shared/types/currentUser";
import { CurrentOrganization } from "@shared/types/organization";

// export type CurrentUser = {
//   id: number;
//   organizationId: number;
//   // role: boolean;
//   // role: string;
//   fullName: string;
//   // isAdmin: boolean;
//   initials: string;
//   hasAvatar: boolean;
//   avatarMedium: any;
//   avatarSmall: any;
//   organizationUsers: any[];
//   currentOrganizationUserId: number;
//   currentOrganizationUser: any;
//   isAnOrgOwner: boolean;
// };

// export type CurrentOrganizationUser = {
//   id: number;
//   userId: number;
//   role: string;
//   isAdmin: boolean;
// };

// export type CurrentOrganization = {
//   id: number;
//   name: string;
//   websiteUrl: string;
//   remoteness: string;
//   hasLogo: boolean;
//   logoMedium: string;
//   stripeSubscriptionInGoodStanding: boolean;
//   stripeDefaultPaymentMethodOnFile: boolean;
//   stripePromoCode: string | null;
//   stripeSubscriptionStatus: string | null;
//   stripeCurrentPeriodEndAt?: string;
//   stripeSubscription?: any;
//   hasWebflowMapping: boolean;
//   wwrCompanyStatement: string;
//   wwrCompanyBio: string;
//   publishedJobsCount: number;
//   plan: string;
//   careersPages: CareersPage[];
//   flipperId: string;
//   flipperGroup: string;
//   careersPageUrl: string;
//   careersPageSlug: string;
//   settings: any;
//   users: any;
//   organizationUsers: any[];
//   ownerId: number;
//   hasWebflowAuthentication: boolean;
//   webflowIntegration: any;
//   hasSlackAuthentication: boolean;
//   slackChannelIntegration: any;
//   hasDiscordAuthentication: boolean;
//   discordChannelIntegration: any;
//   zapierApiKey: string;
//   linkedinCompanyId: number;
//   enabledLinkedinBasicJobs: boolean;
// };

type State = {
  currentUser: CurrentUser;
  currentOrganizationUser: CurrentOrganizationUser;
  currentOrganization?: CurrentOrganization;
};

const CurrentSessionContext = React.createContext<State | undefined>(undefined);

function CurrentSessionProvider({ currentUser, currentOrganization, children }) {
  // window.logger(
  //   "%c[CurrentSessionContext] useCurrentSession",
  //   "color: #1976D2; border: 1px solid black;",
  //   { currentUser, reduxLocalStorageOrganization },
  // );

  return (
    <CurrentSessionContext.Provider
      value={{
        currentUser,
        currentOrganizationUser: currentUser?.currentOrganizationUser,
        currentOrganization,
      }}
    >
      {children}
    </CurrentSessionContext.Provider>
  );
}

function useCurrentSession() {
  // window.logger(
  //   "%c[CurrentSessionContext] useCurrentSession",
  //   "color: #1976D2; border: 1px solid black;",
  // );

  const context = React.useContext(CurrentSessionContext);
  if (context === undefined) {
    throw new Error("useCurrentSession must be used within a CurrentSessionProvider");
  }

  const { currentUser, currentOrganizationUser, currentOrganization } = context;

  return {
    currentUser,
    currentOrganizationUser,
    currentOrganization,
  } as {
    currentUser: CurrentUser;
    currentOrganizationUser: CurrentOrganizationUser;
    currentOrganization: CurrentOrganization;
  };
}
export { CurrentSessionProvider, useCurrentSession };

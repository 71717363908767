import React from "react";
import useOnClickOutside from "use-onclickoutside";

import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useSpring, animated } from "react-spring";

type Props = {
  hpos?: string;
  vpos?: string;
  label?: string;
  testid?: string;
  testidPrefix?: string;
  button?: boolean;
  icon?: string;
  children?: any;
};

const DropdownMenu = ({
  hpos = "left",
  vpos = "bottom",
  label = "More options",
  testid,
  testidPrefix,
  icon,
  button,
  children,
}: // setDisableOnClickOutside,
  Props) => {
  const [dropdownIsVisible, setDropdownIsVisible] = React.useState(false);
  const ref = React.useRef(null);
  const dataTestIdPrefix = testidPrefix ? `${testidPrefix}-` : "";

  useOnClickOutside(ref, () => {
    handleClickOutside();
  });

  const handleClickOutside = () => {
    if (dropdownIsVisible) {
      setDropdownIsVisible(false);
      // setDisableOnClickOutside(true);
    }
  };

  const toggleDropdownVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownIsVisible(!dropdownIsVisible);

    // if (dropdownIsVisible) {
    //   setDisableOnClickOutside(false);
    // }
  };

  // closes the dropdown via a click on an intem in the dropdown
  const closeDropdown = (e) => {
    e.stopPropagation();
    handleClickOutside();
  };

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.95)",
      pointerEvents: "none",
    },
    to: {
      opacity: dropdownIsVisible ? 1 : 0,
      transform: dropdownIsVisible ? "scale(1)" : "scale(0.95)",
      pointerEvents: dropdownIsVisible ? "all" : "none",
    },
    config: {
      tension: 480,
      clamp: true,
    },
  });

  return (
    <Styled.Container ref={ref} data-testid={testid || `${dataTestIdPrefix}dropdown-menu`}>
      <Tooltip label={!dropdownIsVisible && label}>
        <Styled.Toggle
          button={button}
          onClick={toggleDropdownVisibility}
          aria-haspopup="true"
          aria-expanded="false"
          data-testid={`${dataTestIdPrefix}dropdown-toggle-button`}
        >
          <Icon name={icon || "more-vertical"} />
        </Styled.Toggle>
      </Tooltip>

      <Styled.Dropdown
        style={spring}
        hpos={hpos}
        vpos={vpos}
        onClick={closeDropdown}
        data-testid={`${dataTestIdPrefix}dropdown-menu-list`}
      >
        <Styled.DropdownContent>{children}</Styled.DropdownContent>
      </Styled.Dropdown>
    </Styled.Container>
  );
};

export default DropdownMenu;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: DropdownMenu;
  position: relative;
`;

Styled.Toggle = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: DropdownMenu_Toggle;
    ${[t.w(10), t.h(10), t.p(1), t.rounded.sm, t.text.base]};
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: ${props.button
      ? t.dark
        ? t.color.gray[200]
        : t.color.black
      : t.dark
        ? t.color.gray[400]
        : t.color.gray[600]};
    background-color: ${props.button
      ? t.dark
        ? t.color.gray[700]
        : t.color.gray[200]
      : "transparent"};
    transition: background-color 0.2s ease;
    cursor: pointer;
    &:hover {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${props.button
      ? t.dark
        ? t.color.gray[600]
        : t.color.gray[300]
      : t.dark
        ? t.color.gray[700]
        : t.color.gray[100]};
    }

    ${t.mq["md"]} {
      ${[t.w(8), t.h(8), t.p(1)]};
    }
  `;
});

Styled.Dropdown = styled(animated.div)((props: any) => {
  const t: any = props.theme;
  const modeStyles = t.dark
    ? css`
        background-color: ${t.color.gray[700]};
        border: 1px solid ${t.color.gray[600]};
      `
    : css`
        background-color: ${t.color.white};
        border: 1px solid ${t.color.gray[400]};
      `;

  return css`
    label: DropdownMenu_Dropdown;
    ${[t.rounded.sm, t.p(1)]};
    padding-bottom: 2px;

    ${props.hpos === "right" ? t.l(0) : t.r(0)};
    ${props.vpos === "top" ? t.b(10) : t.t(10)};
    transform-origin: ${props.vpos === "top" ? "bottom" : "top"}
      ${props.hpos === "right" ? "left" : "right"};
    z-index: 19;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    ${modeStyles}

    ${t.mq["md"]} {
      ${props.hpos === "right" ? t.l(0) : t.r(0)};
      ${props.vpos === "top" ? t.b(8) : t.t(8)};
    }
  `;
});

Styled.DropdownContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: DropdownMenu_DropdownContent;
    display: flex;
    flex-direction: column;
    position: relative;

    > 
    span,
    a,
    button,
    a:link,
    a:visited {
      ${[t.pr(6), t.text.sm, t.rounded.xs]}
      margin-bottom: 2px;
      padding-left: 0.625rem;
      height: 28px;
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      font-weight: 400 !important;
      transition: background-color 0.2s ease;
      &:hover,
      &:focus {
        background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
        outline: none;
        box-shadow: none;
        text-decoration: none !important;

        .key {
          background-color: ${t.dark ? t.color.gray[400] : t.color.gray[300]};
        }
      }
      .key {
        position: relative;
        right: -${t.spacing[3]};
        line-height: 1.4;
        background-color: ${t.dark ? t.color.gray[500] : t.color.gray[300]};
        color: ${t.dark ? t.color.white : t.color.black};
        transition: background-color 0.2s ease;
      }
    }

    svg {
      margin-left: 0.375rem;
      height: 1.125em;
      width: 1.125em;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[500]};
    }

    ${/* Styles for resume uploader in candidate profiles */ ""}
    > div label {
      ${[t.h(8), t.pl(2), t.pr(6), t.text.sm, t.rounded.sm]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      font-weight: 400 !important;
      width: 100%;
      &:hover,
      &:focus {
        background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]} !important;
        color: ${t.dark ? t.color.white : t.color.black};
        outline: none;
        box-shadow: none;
        text-decoration: none !important;
      }
    > div:hover {
      color: ${t.dark ? t.color.white : t.color.black};
    }
  `;
});

import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost, apiDelete } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const getSlackChannelList = async () => {
  return await apiGet({ path: "/integrations/slack_channel_integrations/list" });
};

// const getSlackChannelsList = async () => {
//   return await apiGet({ path: "/integrations/slack_channel_integrations/list" });
// };

const createSlackChannelIntegration = async ({ channelId, channelName, channelNameNormalized }) => {
  window.logger("%c[useSlack] createSlackChannelIntegration\n\n\n\n", "background-color: #FF76D2", {
    channelId,
    channelName,
    channelNameNormalized,
  });
  return await apiPost({
    path: `/integrations/slack_channel_integrations`,
    variables: { channelId, channelName, channelNameNormalized },
  });
};

// const deleteSlackChannelIntegration = async ({ channelId }) => {
//   window.logger("%c[useSlack] deleteSlackChannelIntegration\n\n\n\n", "background-color: #FF76D2", {
//     channelId,
//   });
//   return await apiDelete({
//     path: `/integrations/slack_channel_integrations/${channelId}`,
//     variables: {},
//   });
// };

// const updateSlackChannelIntegration = async ({
//   slackChannelId, // currently just a placeholder
//   siteId,
//   siteName,
//   sitePreviewUrl,
//   collectionId,
//   collectionName,
//   fieldMapping,
// }: {
//   slackChannelId: any; // currently just a placeholder
//   siteId?: string;
//   siteName?: string;
//   sitePreviewUrl?: string;
//   collectionId?: string;
//   collectionName?: string;
//   fieldMapping?: {};
// }) => {
//   window.logger("%c[useSlack] updateSlackChannelIntegration\n\n\n\n", "background-color: #FF76D2", {
//     siteId,
//     siteName,
//     sitePreviewUrl,
//     collectionId,
//     collectionName,
//     fieldMapping,
//   });
//   return await apiPut({
//     path: `/integrations/slack_channel_integrations/${slackChannelId}`,
//     variables: { siteId, siteName, sitePreviewUrl, collectionId, collectionName, fieldMapping },
//   });
// };

/* Hooks
--===================================================-- */
export function useSlackChannelList({
  enabled = false,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery("slackChannels", getSlackChannelList, { retry: false, enabled });
}

export function useCreateSlackChannelIntegration() {
  const queryClient = useQueryClient();
  return useMutation(createSlackChannelIntegration, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useSlack] useCreateSlackChannelIntegration onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("slackChannels");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

// export function useDeleteSlackChannelIntegration() {
//   const queryClient = useQueryClient();
//   return useMutation(deleteSlackChannelIntegration, {
//     onSettled: () => {
//       queryClient.invalidateQueries("currentOrganization");
//       queryClient.invalidateQueries("slackChannels");
//     },
//     // throwOnError: true,
//   });
// }

// export function useUpdateSlackChannelIntegration() {
//   const queryClient = useQueryClient();
//   return useMutation(updateSlackChannelIntegration, {
//     onSuccess: (data, mutationVariables) => {
//       // window.logger("%c[useSlack] useUpdateSlackChannelIntegration onSuccess", "color: #1976D2", {
//       //   data,
//       //   mutationVariables,
//       // });
//       queryClient.invalidateQueries("me");
//       queryClient.invalidateQueries("currentOrganization");
//       queryClient.invalidateQueries("slackChannels");
//       // queryClient.invalidateQueries(["questions", data.jobId]);
//     },

//     // throwOnError: true,
//   });
// }

// export function useUpdateSelectedSite() {
//   const queryClient = useQueryClient();
//   return useMutation(updateSlackChannelIntegration, {
//     onSuccess: (data, mutationVariables) => {
//       // window.logger("%c[useSlack] useUpdateSlackChannelIntegration onSuccess", "color: #1976D2", {
//       //   data,
//       //   mutationVariables,
//       // });
//       queryClient.invalidateQueries("me");
//       queryClient.invalidateQueries("currentOrganization");
//       // queryClient.invalidateQueries(["jobs", data.jobId]);
//       // queryClient.invalidateQueries(["questions", data.jobId]);
//     },

//     // throwOnError: true,
//   });
// }

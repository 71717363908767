import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import Button from "@ats/src/components/shared/Button";
import BottomButtonBar from "@ats/src/components/shared/BottomButtonBar";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import {
  useCandidatePrivateNote,
  useUpdateCandidatePrivateNote,
} from "@shared/queryHooks/useCandidatePrivateNote";

import {
  useJobApplication,
  useUpdateJobApplication,
  // useFireAndForgetUpdateJobApplication,
} from "@shared/queryHooks/useJobApplication";

function JobApplicationNotes(props) {
  const { jobApplication: initialJobApplication } = props;
  const jobApplicationId = initialJobApplication.id;
  const candidateId = initialJobApplication.candidateId;
  const [noteIsDirty, setNoteIsDirty] = React.useState(false);
  const { data: jobApplication, isFetching, isSuccess, isLoading } = useJobApplication({
    jobApplicationId,
    initialData: {},
    // initialData: { ...initialJobApplication },
    refetchOnWindowFocus: true,
  });

  const { data: candidatePrivateNote, isLoading: isLoadingPrivateNote } = useCandidatePrivateNote({
    candidateId,
    enabled: true,
  });
  const editorRef = React.useRef(undefined);

  const {
    mutate: updateJobApplication,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateJobApplication();

  const {
    mutate: updateCandidatePrivateNote,
    error: errorOnUpdatePrivateNote,
    isLoading: isLoadingUpdatePrivateNote,
  } = useUpdateCandidatePrivateNote();

  window.logger("%c[JobApplicationNotes] RENDER", "border: 1px solid #906feb;", {
    candidatePrivateNote,
  });

  const handleAutoSave = () => {
    autoSave();
  };

  const autoSave = () => {
    window.logger("%c[JobApplicationNotes] autoSave", "border: 1px solid #906feb;", {
      state: editorRef.current?.serializedState(),
      noteIsDirty,
    });

    if (noteIsDirty && editorRef.current != undefined) {
      updateCandidatePrivateNote(
        {
          id: candidatePrivateNote?.id,
          candidateId,
          body: editorRef.current.serializedState(),
        },
        {
          onSuccess: (data) => {
            window.logger(
              "%c[JobApplicationNotes] autoSave onSuccess",
              "border: 1px solid #318011;",
              {
                data,
              },
            );
            setNoteIsDirty(false);
          },
          onError: (response) => { },
        },
      );
    }
  };

  const handleChange = (nextState) => {
    setNoteIsDirty(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await updateCandidatePrivateNote(
        {
          id: candidatePrivateNote?.id,
          candidateId,
          body: editorRef.current.serializedState(),
        },
        {
          onSuccess: (data) => {
            window.logger(
              "%c[JobApplicationNotes] handleSubmit onSuccess",
              "border: 1px solid #318011;",
              {
                data,
              },
            );
            setNoteIsDirty(false);
          },
          onError: () => { },
        },
      );
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[JobApplicationNotes] handle error when updating JobApplication",
        "color: #FF7602",
        {
          error,
        },
      );
    }
  };

  if (candidatePrivateNote == undefined || isLoadingPrivateNote) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <Styled.Form>
      <ProseMirrorEditor
        ref={editorRef}
        editorId="notes-editor"
        enableStaticMenuBar
        onChange={handleChange}
        autoSave={handleAutoSave}
        placeholder="This is a private document for notes..."
        defaultValue={candidatePrivateNote.body || ""}
      />

      {/* <h2>
        Below is the OLD Shared Document, just here for troubleshooting copying its data to the
        above field for a private note
      </h2>
      <ProseMirrorEditor
        ref={editorRef}
        editorId="notes-editor"
        enableStaticMenuBar
        onChange={handleChange}
        autoSave={handleAutoSave}
        defaultValue={jobApplication.sharedDocument || ""}
      /> */}

      <BottomButtonBar static>
        <Button
          type="button"
          onClick={handleSubmit}
          loading={isLoadingUpdatePrivateNote && !noteIsDirty}
          disabled={isLoadingUpdatePrivateNote}
        >
          Save changes
        </Button>
        {noteIsDirty ? <span>Changes not yet saved</span> : <span>No unsaved changes</span>}
      </BottomButtonBar>
    </Styled.Form>
  );
}

JobApplicationNotes.propTypes = {};
JobApplicationNotes.defaultProps = {};

export default JobApplicationNotes;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Form = styled.form((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationNotes;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;
});

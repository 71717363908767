import React from "react";
import BottomButtonBar from "@ats/src/components/shared/BottomButtonBar";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  fillHeight?: boolean;
  sidebar?: any;
  fullWidthForm?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  actions?: any;
  children?: any;
  bottomBar?: any;
};

export default function SettingsContainer({
  fillHeight,
  sidebar,
  fullWidthForm,
  title,
  description,
  actions,
  children,
  bottomBar,
}: Props) {
  return (
    <>
      <Styled.Container fillHeight={fillHeight}>
        <Styled.Content hasSidebar={!!sidebar}>
          <Styled.Form
            hasSidebar={!!sidebar}
            fullWidthForm={fullWidthForm}
            hasHeader={title || description}
          >
            {title && (
              <Styled.Row>
                <Styled.Title>{title}</Styled.Title>
                <Styled.Actions>{actions}</Styled.Actions>
              </Styled.Row>
            )}
            {description && <Styled.Description>{description}</Styled.Description>}
            {children}
          </Styled.Form>
          {bottomBar && <BottomButtonBar>{bottomBar}</BottomButtonBar>}
        </Styled.Content>
        {sidebar && <Styled.Sidebar>{sidebar}</Styled.Sidebar>}
      </Styled.Container>
    </>
  );
}

// SettingsContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   actions: PropTypes.node,
//   sidebar: PropTypes.node,
//   bottomBar: PropTypes.node,
//   fillHeight: PropTypes.bool,
//   fullWidthForm: PropTypes.bool,
//   modal: PropTypes.bool,
// };

SettingsContainer.defaultProps = {
  fillHeight: false,
  fullWidthForm: false,
  // roomy: false,
};

/* Styled Components
======================================================= */

let Styled: any;
Styled = {};

Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer;
    flex-grow: 1;
    ${props.fillHeight && "min-height: 100%;"};
    flex-shrink: 0;

    ${t.mq["lg"]} {
      display: flex;
    }
  `;
});

Styled.Content = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Content;
    width: 100vw;

    ${t.mq["lg"]} {
      width: ${props.hasSidebar ? "50vw" : "100%"};
    }
  `;
});

Styled.Form = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Form;
    ${[t.px(4), t.pb(1)]}
    ${props.hasHeader ? t.pt(4) : t.pt(1)};

    ${t.mq["lg"]} {
      width: auto;
      ${!props.hasSidebar && !props.fullWidthForm && "max-width: 50vw;"}
    }
  `;
});

Styled.Row = styled.div`
  label: SettingsContainer_Row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

Styled.Title = styled.h2((props) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Title;
    ${[t.text.h2, t.mb(1)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Actions;
    display: flex;
    align-items: center;
    ${t.mt(-1)}

    > * {
      ${t.ml(4)};
    }
  `;
});

Styled.Description = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Description;
    ${[t.pt(1)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.6;

    a {
      color: ${t.dark ? t.color.gray[200] : t.color.gray[800]};

      &:hover {
        text-decoration: underline;
      }
    }
  `;
});

Styled.Sidebar = styled.aside((props) => {
  const t: any = props.theme;
  return css`
    label: SettingsContainer_Description;
    border-left: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    display: none;

    ${t.mq["lg"]} {
      display: block;
      flex: 1;
    }
  `;
});

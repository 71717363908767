import React from "react";
import { Dispatch } from "redux";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CustomQuestionModal from "@ats/src/components/modals/CustomQuestionModal";
import EmptyState from "@ats/src/components/shared/EmptyState";
import Button from "@ats/src/components/shared/Button";
import QuestionList from "@ats/src/views/jobs/components/QuestionList";
import QuestionListItem from "@ats/src/views/jobs/components/QuestionListItem";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { useJobStore } from "@ats/src/lib/store/zustand/jobStore";

import {
  useCreateQuestion,
  useUpdateQuestion,
  useDropQuestion,
  useDeleteQuestion,
} from "@shared/queryHooks/useQuestion";

type Props = {
  jobId: number;
  questions: [];
};

function JobSetupApplicationFormCustomQuestions(props: Props) {
  const { jobId } = props;
  const setJob = useJobStore((state) => state.set);
  const questions = useJobStore(
    React.useCallback((state) => state.questionsByJobId[jobId], [jobId]),
  );
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();

  const { mutate: createQuestion, isLoading: isLoadingCreateQuestion } = useCreateQuestion();
  const { mutate: updateQuestion, isLoading: isLoadingUpdateQuestion } = useUpdateQuestion();
  const { mutate: dropQuestion, isLoading: isLoadingDropQuestion } = useDropQuestion();
  const { mutate: deleteQuestion, isLoading: isLoadingDeleteQuestion } = useDeleteQuestion();

  if (questions == undefined) {
    return null;
  }

  /* onDragEndQuestion
  --===================================================-- */
  const onDragEndQuestion = (result: { destination: any; source: any; draggableId: number }) => {
    window.logger(
      "%c[JobSetupApplicationFormCustomQuestions] onDragEndQuestion result",
      "color: #1976D2",
      result,
    );
    // eslint-disable-next-line no-unused-vars
    const { destination, source, draggableId } = result;

    window.logger("%c[JobSettingsAppliction] ", "color: #1976D2");

    // If there is no destinatiom then do nothing
    if (!destination) {
      return;
    }

    // If the user drops the item back in its original spot then do nothing
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // let questions: any[] = [...questions];
    const question: { id: number } = questions[source.index];

    const position: number = destination.index + 1;

    if (source.index !== destination.index) {
      let tempQuestions = questions;
      tempQuestions.splice(source.index, 1);
      tempQuestions.splice(destination.index, 0, question); // Add question to array in correct location

      /* Persist to Store
      --===================================================-- */
      setJob((state) => {
        state.questionsByJobId[jobId] = tempQuestions;
      });
    }

    dropQuestion(
      { jobId, id: question.id, position },
      {
        onError: ({ data: { errors } }) => {
          // window.logger("%c[JobSetup] useDropQuestion onError", "color: #FF7602", {
          //   errors
          // });
          addToast({
            title: errors[0],
            kind: "warning",
            delay: 5000,
          });
        },
      },
    );
  };

  /* onSubmitCustomQuestion
  --===================================================-- */
  const onSubmitCustomQuestion = (question, isEditingQuestion) => {
    removeModal();
    if (isEditingQuestion) {
      window.logger(
        "%c[JobSetupApplicationFormCustomQuestions] onSubmitCustomQuestion isEditingQuestion question",
        "color: #1906D2",
        question,
      );

      updateQuestion(
        { jobId: jobId, ...question },
        {
          onSuccess: () => {
            addToast({
              title: `Question updated`,
              kind: "success",
            });
          },
          onError: ({ data: { errors } }) => {
            const message = errors["kind"] ? errors["kind"] : errors[0];
            addToast({
              title: message,
              kind: "warning",
              delay: 5000,
            });
          },
        },
      );
    } else {
      createQuestion(
        { jobId: jobId, ...question },
        {
          onSuccess: () => {
            addToast({
              title: `Question created`,
              kind: "success",
            });
          },
          onError: ({ data: { errors } }) => {
            const message = errors["kind"] ? errors["kind"] : "Error creating question";
            addToast({
              title: message,
              kind: "warning",
              delay: 5000,
            });
          },
        },
      );
    }
  };

  /* onDeleteCustomQuestion
  --===================================================-- */
  const onDeleteCustomQuestion = (question) => {
    deleteQuestion(
      { jobId, ...question },
      {
        onSuccess: () => {
          addToast({
            title: `Question deleted`,
            kind: "success",
          });
        },
        onError: ({ data: { errors } }) => {
          addToast({
            title: errors[0],
            kind: "warning",
            delay: 5000,
          });
        },
      },
    );
  };

  /* CustomQuestionMoal
  --===================================================-- */
  const openNewCustomQuestionModal = (e) => {
    e.preventDefault();
    openCustomQuestionModal({}, false);
  };

  const openEditCustomQuestionModal = (question) => {
    openCustomQuestionModal(question, true);
  };

  const openCustomQuestionModal = (question, isEditingQuestion) => {
    const modal = (
      <CustomQuestionModal
        onCancel={onCloseModal}
        onSubmit={onSubmitCustomQuestion}
        isEditing={isEditingQuestion}
        question={question}
      />
    );

    openModal(modal);
  };

  const onCloseModal = () => {
    removeModal();
  };

  const questionNodes = () => {
    window.logger(
      "%c[JobSetupApplicationFormCustomQuestions] questionNodes RENDER QUESTIONS",
      "color: #19FFD2",
    );
    if (questions.length === 0) {
      return <EmptyState message="No additional application questions have been added" />;
    } else {
      return questions.map((question: { questionText: string }, index: number) => {
        window.logger(
          "%c[JSAFormCustomQuestions] questionNode QuestionListItem",
          "color: #1976D2; border: 1px solid red;",
          {
            question,
          },
        );
        return (
          <QuestionListItem
            key={`${index}_${question.questionText}`}
            index={index}
            question={question}
            openEditCustomQuestionModal={openEditCustomQuestionModal}
            deleteQuestion={onDeleteCustomQuestion}
          />
        );
      });
    }
  };

  return (
    <>
      <Styled.Label>Additional questions</Styled.Label>
      <QuestionList onDragEndQuestion={onDragEndQuestion}>{questionNodes()}</QuestionList>
      <Styled.Button styleType="secondary" onClick={openNewCustomQuestionModal}>
        Add additional question
      </Styled.Button>
    </>
  );
}

JobSetupApplicationFormCustomQuestions.propTypes = {};
JobSetupApplicationFormCustomQuestions.defaultProps = {};

export default JobSetupApplicationFormCustomQuestions;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupApplicationFormCustomQuestions;
    ${[t.text.xs, t.text.bold, t.mb(2)]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.Button = styled(Button)((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupApplicationFormCustomQuestions_Button;
    ${t.mt(3)}
  `;
});

/* eslint-disable @getify/proper-arrows/return */

import React, { useCallback, useEffect, useState } from "react";
// import { useTransition, animated } from "react-spring";

// import styled from "@emotion/styled";
// import { css } from "@emotion/react";
// import { useTheme } from "@emotion/react";

type Modal = {
  openModal: (modal: any) => void;
  removeModal: () => void;
  dismissModalWithAnimation: any; // (animationCompleteCallback: () => void) => void;
  hasModal: () => boolean;
  animationCompleteCallback: () => void;
  setAnimationCompleteCallback: (animationCompleteCallback: any) => void;
  isVisible: boolean;
  modal: object;
};

const ModalContext = React.createContext<Modal | undefined>(undefined);

function ModalProvider({ children }) {
  const [modal, setModal] = useState(undefined);
  const [animationCompleteCallback, setAnimationCompleteCallback] = useState(undefined);
  const [isVisible, setIsVisible] = React.useState(true);

  const openModal = useCallback(
    function (modal) {
      // window.logger("%c[ModalContext] openModal", "color: #FF0602", { modal });
      setModal(modal);
      setIsVisible(true);
    },
    [setModal, setIsVisible],
  );

  const removeModal = () => {
    // window.logger("%c[ModalContext] removeModal", "background-color: #80b2e4", {
    //   modal,
    //   animationCompleteCallback,
    // });
    setIsVisible(false);
    setModal(undefined);
    setAnimationCompleteCallback(undefined);
  };

  const dismissModalWithAnimation = (animationCompleteCallback) => {
    if (modal) {
      // window.logger("%c[ModalContext] dismissModalWithAnimation HAS MODAL", "color: #16883c", {
      //   modal,
      //   animationCompleteCallback,
      // });
      setIsVisible(false);
      setAnimationCompleteCallback(animationCompleteCallback);
    } else {
      // window.logger("%c[ModalContext] dismissModalWithAnimation NO MODAL", "color: #be2a05", {
      //   modal,
      //   animationCompleteCallback,
      // });
      animationCompleteCallback();
      removeModal();
    }
  };

  const hasModal = () => {
    return modal != undefined;
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        removeModal,
        hasModal,
        isVisible,
        dismissModalWithAnimation,
        animationCompleteCallback,
        setAnimationCompleteCallback,
      }}
    >
      {children}
      {modal ? modal : null}
    </ModalContext.Provider>
  );
}

function useModalContext() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }

  const {
    modal,
    openModal,
    removeModal,
    hasModal,
    isVisible,
    dismissModalWithAnimation,
    animationCompleteCallback,
    // setAnimationCompleteCallback,
  } = context;

  return {
    modal,
    openModal,
    removeModal,
    hasModal,
    isVisible,
    dismissModalWithAnimation,
    animationCompleteCallback,
    // setAnimationCompleteCallback,
  };
}

export { useModalContext, ModalProvider };

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiDelete, apiPut } from "@shared/queryHooks/api";

const getNotifications = async (page) => {
  // window.logger("%c[useNotification] getNotifications", "color: #1976D2", { page });
  return await apiGet({ path: `/me/job_application_notifications?page=${page}` });
};

const getInfiniteNotifications = async ({
  filter,
  job_id,
  page = null,
}: {
  filter?: string;
  job_id?: number;
  page?: number;
}) => {
  // const getInfiniteNotifications = async ({ filter = null, job_id = null }, page = 1) => {
  // window.logger("%c[useNotification] getInfiniteNotifications", "color: #FF76D2", {
  //   page,
  //   filter,
  //   job_id,
  // });
  return await apiGet({
    path: `/me/job_application_notifications?page=${page}&filter=${filter || ""}&job_id=${
      job_id || ""
    }`,
  });
  // return async (stuff) => {
  //  window.logger("%c[USE INFINITE QUERY] stuff", "color: #1976D2", { stuff });
  //   return await apiGet({ path: `/me/job_application_notifications?page=${page}` });
  // };
};

const getNotificationsHasUnread = async () => {
  return await apiGet({ path: `/me/job_application_notifications/has_unread` });
};

const getNotificationsSummaryCounts = async () => {
  return await apiGet({
    path: `/me/job_application_notifications/summary_counts`,
  });
};

const markNotificationAsRead = async (variables) => {
  return await apiPut({
    path: `/me/job_application_notifications/${variables.id}/mark_read`,
    variables: {},
  });
};

const markAllNotificationsAsRead = async (variables = {}) => {
  return await apiPut({ path: `/me/job_application_notifications/mark_all_read`, variables });
};

const markNotificationAsUnread = async (variables) => {
  return await apiPut({
    path: `/me/job_application_notifications/${variables.id}/mark_unread`,
    variables,
  });
};

const deleteNotification = async (variables) => {
  return await apiDelete({
    path: `/me/job_application_notifications/${variables.id}`,
    variables: {},
  });
};

const deleteAllNotifications = async (variables = {}) => {
  window.logger("%c[useNotification] deleteAllNotifications", "color: #1976D2", { variables });
  return await apiPut({ path: `/me/job_application_notifications/dismiss_all`, variables });
};

/* Hooks
--===================================================-- */
function useNotifications(
  page = 1,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} {
  return useQuery(["notifications", page], () => getNotifications(page));
}

function usePaginatedNotifications(
  page = 1,
): {
  status: any;
  data: any;
  resolvedData?: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} {
  return useQuery(["notifications", page], () => getNotifications(page), {
    keepPreviousData: true,
  });
}

function useInfiniteNotifications({
  filter,
  job_id,
}): {
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isFetchingNextPage?: string | boolean; // isFetchingMore
  fetchNextPage: () => any; // fetchMore
  hasNextPage?: boolean; // canFetchMore
} {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    ["infiniteNotifications", `${filter}-${job_id}`],
    ({ pageParam = 1 }) => getInfiniteNotifications({ filter, job_id, page: pageParam }),
    {
      // ...queryOptions,
      onSettled: () => {
        queryClient.invalidateQueries("notificationsHasUnread");
      },
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length + 1;
      },
    },
  );
}

function useNotificationsHasUnread(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} {
  return useQuery(["notificationsHasUnread"], () => getNotificationsHasUnread());
}

function useNotificationsSummaryCounts(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} {
  return useQuery(["notificationsSummaryCounts"], () => getNotificationsSummaryCounts());
}

function useMarkNotificationAsRead() {
  const queryClient = useQueryClient();
  return useMutation(markNotificationAsRead, {
    onSettled: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("infiniteNotifications");
      queryClient.invalidateQueries("notificationsHasUnread");
      queryClient.invalidateQueries("notificationsSummaryCounts");
    },
    // throwOnError: true,
  });
}

function useMarkAllNotificationsAsRead() {
  const queryClient = useQueryClient();
  return useMutation(markAllNotificationsAsRead, {
    onSettled: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("infiniteNotifications");
      queryClient.invalidateQueries("notificationsHasUnread");
      queryClient.invalidateQueries("notificationsSummaryCounts"); // Maybe don't need this at all anymore
    },
    // throwOnError: true,
  });
}

function useMarkNotificationAsUnread() {
  const queryClient = useQueryClient();
  return useMutation(markNotificationAsUnread, {
    onSettled: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("infiniteNotifications");
      queryClient.invalidateQueries("notificationsHasUnread");
      queryClient.invalidateQueries("notificationsSummaryCounts"); // Maybe don't need this at all anymore
    },
    // throwOnError: true,
  });
}

export function useDeleteNotification() {
  const queryClient = useQueryClient();
  return useMutation(deleteNotification, {
    onSettled: () => {
      // queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("infiniteNotifications");
      queryClient.invalidateQueries("notificationsHasUnread");
      queryClient.invalidateQueries("notificationsSummaryCounts");
    },
    // throwOnError: true,
  });
}

function useDismissAllNotifications() {
  const queryClient = useQueryClient();
  return useMutation(deleteAllNotifications, {
    onSettled: () => {
      // queryClient.invalidateQueries("notifications");
      // queryClient.invalidateQueries("infiniteNotifications");
      // queryClient.invalidateQueries("notificationsHasUnread");
      // queryClient.invalidateQueries("notificationsSummaryCounts");
    },
    // throwOnError: true,
  });
}

export {
  useNotifications,
  usePaginatedNotifications,
  useInfiniteNotifications,
  useNotificationsHasUnread,
  useNotificationsSummaryCounts,
  useMarkNotificationAsRead,
  useMarkNotificationAsUnread,
  useMarkAllNotificationsAsRead,
  useDismissAllNotifications,
};

import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";

import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import ProfileForm from "./components/ProfileForm";

function OnboardingProfile(props: any) {
  const [state, setState] = React.useState("");

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  //   console.log("[OnboardingProfile] handleClick", event.currentTarget);
  // };

  const onComplete = () => {
    props.history.push("/jobs");
  };

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        {props.isLoadingOrganization ? (
          <LoadingIndicator label="Completing profile setup..." />
        ) : (
          <>
            <Styled.Form>
              <Helmet title="Profile" />
              <h2>Tell us about you</h2>
              <div>
                <ProfileForm
                  onComplete={onComplete}
                  currentOrganization={props.currentOrganization}
                />
                {/* <OrganizationForm
                  onComplete={onComplete}
                  currentOrganization={currentOrganization}
                /> */}
              </div>
            </Styled.Form>
            <Styled.Links>
              <Link to="/logout">Log out</Link>
            </Styled.Links>
          </>
        )}
      </Styled.UI>
    </Styled.Container>
  );
}

OnboardingProfile.defaultProps = {};

export default OnboardingProfile;

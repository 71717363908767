import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Label,
  ResponsiveContainer,
} from "recharts";
import { StageData } from "@shared/types/reporting";
import theme from "@ats/styles/theme";
import { useColorSchemeContext } from "@shared/context/ColorSchemeContext";

interface JobMetricsStageTargetProps {
  stageData: StageData[];
}

const yTickFormatter = (value: string, index: number) => {
  const limit = 16; // put your maximum character
  if (!value) return value;
  if (value.length <= limit) return value;
  return `${value.substring(0, limit)}...`;
};

const legendFormatter = (value: string) => {
  return value === "promoted" ? "Advanced" : "Rejected";
};

function JobMetricsStageTarget(props: JobMetricsStageTargetProps) {
  const { stageData } = props;
  const { isDarkMode } = useColorSchemeContext();

  const data = stageData
    .filter((stage) => !["Archived", "Hired"].includes(stage.stageName))
    .map((stage) => {
      const promoted = stage.targetStages
        .filter((targetStage) => targetStage.stageName !== "Archived")
        .reduce((prev, cur) => prev + cur.count, 0);
      const rejected = stage.targetStages.find(
        (targetStage) => targetStage.stageName === "Archived",
      ).count;

      const total = promoted + rejected;
      return {
        stageName: stage.isDeleted ? `${stage.stageName} (deleted)` : stage.stageName,
        promoted: total === 0 ? 0 : (promoted / total) * 100,
        rejected: total === 0 ? 0 : (rejected / total) * 100,
      };
    });
  // .filter((stage) => stage.promoted + stage.rejected > 0);

  return (
    <ResponsiveContainer height={data.length * (12 + 20) + 48}>
      <BarChart
        data={data}
        layout="vertical"
        barSize={12}
        barGap={0}
        barCategoryGap={10}
        margin={{ top: -40, right: 6, bottom: 0, left: 0 }}
      >
        {/* <Tooltip 
          cursor={{ fill: isDarkMode ? theme.color.gray[700] : theme.color.gray[100] }}
          content={renderTooltip}
        /> */}
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          stroke={isDarkMode ? theme.color.gray[600] : theme.color.gray[200]}
        />
        <Bar
          dataKey="promoted"
          label="Advanced"
          fill={isDarkMode ? theme.color.gray[300] : theme.color.black}
          stackId="a"
        />
        <Bar
          dataKey="rejected"
          label="Rejected"
          fill={isDarkMode ? theme.color.gray[500] : theme.color.gray[500]}
          stackId="a"
        />
        <XAxis
          type="number"
          height={48}
          tickMargin={6}
          tick={{ fill: isDarkMode ? theme.color.gray[400] : theme.color.gray[600] }}
        >
          <Label
            value="Percent of candidates"
            position="insideBottom"
            fill={isDarkMode ? theme.color.gray[400] : theme.color.gray[600]}
          />
        </XAxis>
        <YAxis
          tickFormatter={yTickFormatter}
          width={120}
          type="category"
          dataKey="stageName"
          tickMargin={6}
          tick={{ fill: isDarkMode ? theme.color.gray[400] : theme.color.gray[600] }}
        />
        <Legend
          formatter={legendFormatter}
          iconType="square"
          align="right"
          verticalAlign="top"
          height={40}
          iconSize={10}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default JobMetricsStageTarget;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

import React from "react";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormSection from "@ats/src/components/forms/FormSection";
import CopyField from "@ats/src/components/shared/CopyField";
import { useOrganizationApiKeys } from "@shared/queryHooks/useOrganization";

function AccountJobBoardEmbed(props) {
  const { currentOrganization } = useCurrentSession();
  const { careersPageSlug } = currentOrganization;

  const copy = `<div id="polymer-jobboard-embed"></div>
  <script
    src="${window.JOB_BOARD_EMBED_URL}"
    jobboard="${careersPageSlug}"
    includeCss="true"
  >
  </script>`;

  return (
    <SettingsContainer
      title="Job board embed"
      description="Display a job list on your website by pasting the following embed code in the desired location within a page's HTML. Make sure not to modify the snippet, as this could affect its functionality."
      fullWidthForm={true}
    >
      <FormSection>
        <FormLabel label="Embed code snippet" />
        <CopyField value={copy} label="Copy embed" />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountJobBoardEmbed;

import React from "react";
import * as ActionCable from "@rails/actioncable";
import WebsocketGlobalChannelHandler from "@ats/src/websockets/WebsocketGlobalChannelHandler";

type WebsocketProviderProps = { children: React.ReactNode };

const WebsocketContext = React.createContext(undefined);

function WebsocketProvider({ children }: WebsocketProviderProps) {
  const cable = ActionCable.createConsumer(`${(window as any).APP_ATS_ROOT_URL}/cable`);

  return (
    <WebsocketContext.Provider value={cable}>
      <WebsocketGlobalChannelHandler>
        {/* Children */}
        {children}
      </WebsocketGlobalChannelHandler>
    </WebsocketContext.Provider>
  );
}

function useWebsocket() {
  const context = React.useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error("useWebsocket must be used within a WebsocketProvider");
  }
  return context;
}

export { WebsocketProvider, useWebsocket };

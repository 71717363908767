import React from "react";

import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";

function ArchiveJobModal({ jobStatus, onConfirm, onCancel }) {

  const archiveText = `This action will ${jobStatus === "published" ? "unpublish and": ""} remove this job from your active job list. Although an archived job will still be accessible, it cannot be unarchived.`;

  return (
    <ConfirmationModal
      title="Are you sure you want to archive this job?"
      subcopy={archiveText}
      confirmText="Archive"
      cancelText="Cancel"
      onConfirm={() => {
        onConfirm();
      }}
      onCancel={() => {
        onCancel();
      }}
      isDestructiveConfirm={false}
    />
  );
}

export default ArchiveJobModal;
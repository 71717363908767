import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  legend: string;
  description?: string;
  children: React.ReactNode;
};

function FormFieldset(props: Props) {
  const { legend, description, children } = props;

  return (
    <Styled.Fieldset>
      <Styled.Label>{legend}</Styled.Label>
      {description && <p>{description}</p>}
      {children}
    </Styled.Fieldset>
  );
}

export default FormFieldset;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Fieldset = styled.fieldset((props) => {
  const t: any = props.theme;
  return css`
    label: FormFieldset;
    ${[t.p(0), t.m(0)]}
    border: none;
    > p {
      ${[t.text.sm, t.mt(-1), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq['md']} {
        ${t.text.xs}
      }
    }
  `;
});

Styled.Label = styled.legend((props) => {
  const t: any = props.theme;
  return css`
    label: FormFieldset_Label;
    ${[t.text.sm, t.text.bold]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    display: inline-block;
    line-height: 1.4;
    margin-bottom: 0.375rem;

    ${t.mq['md']} {
      ${[t.text.xs]}
    }
  `;
});

import { css } from "@emotion/react";
import theme from "@ats/styles/theme";

const scrollbarStyles = (dark: boolean) => css`
  scrollbar-color: ${dark
    ? `${theme.color.gray[500]} ${theme.color.gray[800]}`
    : `${theme.color.gray[400]} ${theme.color.gray[50]}`};
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${dark ? theme.color.gray[800] : theme.color.gray[50]};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${dark ? theme.color.gray[500] : theme.color.gray[400]};
    border-radius: 8px;
    border: 4px solid ${dark ? theme.color.gray[800] : theme.color.gray[50]};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${dark ? theme.color.gray[400] : theme.color.gray[500]};
  }
`;

export default scrollbarStyles;

import React from "react";

function useRoveFocus(size, element) {
  const [currentFocus, setCurrentFocus] = React.useState(0);

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
      }
    },
    [size, currentFocus, setCurrentFocus],
  );

  React.useEffect(() => {
    if (element) {
      element.addEventListener("keydown", handleKeyDown, false);
      return () => {
        element.removeEventListener("keydown", handleKeyDown, false);
      };
    }
  }, [handleKeyDown, element]);

  return [currentFocus, setCurrentFocus] as const;
}

export default useRoveFocus;

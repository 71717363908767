import React, { useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import { useCreateJob } from "@shared/queryHooks/useJob";
import { useToastContext } from "@shared/context/ToastContext";

import { validateNewJob } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  history: any;
};

function NewJobCenterModal(props: Props) {
  // window.logger("%c[NewJobCenterModal] ", "color: #1976D2", { props });
  const addToast = useToastContext();
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState([]);
  const { mutate: createJob, isLoading: isLoadingCreateJob } = useCreateJob();
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeTitle = (_, value) => {
    setTitle(value);
    setErrors([]);
    setIsDirty(true);
  };

  const handleSubmitCreateNewJob = async (e) => {
    e.preventDefault();
    const [isValid, validationErrors] = await validateNewJob({ title });

    if (isValid) {
      createJob(
        { title },
        {
          onSuccess: (data) => {
            window.logger("%c[NewJobCenterModal] createJob onSuccess", "color: #1976D2", { data });
            // addToast({
            //   title: `Created ${job.title}`,
            //   kind: "success",
            // });
            props.onCancel();
            props.history.push(`/jobs/${data.id}/setup/details`);
          },
          onError: (response) => {
            window.logger("%c[NewJobCenterModal] createJob onError", "color: #ad1b47", {
              response,
            });
            // setErrors(response.errors);
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmitCreateNewJob}
        loading={isLoadingCreateJob}
      >
        Create job
      </Button>
      <Button styleType="secondary" onClick={props.onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText="Create new job" onCancel={props.onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        Enter the name of the job position you're hiring for. Once created, you'll be taken to its
        setup screen where you can configure it.
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormInput
          onChange={handleChangeTitle}
          name="title"
          placeholder=""
          label="Job title"
          value={title}
          errors={errors}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default NewJobCenterModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: NewJobCenterModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: NewJobCenterModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

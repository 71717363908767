import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import InitialsAvatar from "@ats/src/components/shared/InitialsAvatar";

function UserAvatar(props: {
  size: string;
  user: { initials; hasAvatar; avatarSmall?: string; avatarMedium };
}) {
  const { size, user } = props;
  const { initials, hasAvatar, avatarSmall, avatarMedium } = user;

  // window.logger("%c[UserAvatar] render", "color: #1976D2", { size, user });

  return hasAvatar ? (
    <Styled.Avatar size={size}>
      <img src={size === "sm" ? avatarSmall : avatarMedium} />
    </Styled.Avatar>
  ) : (
    <InitialsAvatar size={size} initials={initials} />
  );
}

UserAvatar.propTypes = {};

UserAvatar.defaultProps = {};

export default UserAvatar;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Avatar = styled.div((props: any) => {
  const t: any = props.theme;
  const size = props.size;
  return css`
    label: UserAvatar;
    ${[t.text.bold]};
    ${size === "lg"
      ? [t.h(10), t.w(10), t.text.lg, t.rounded.sm]
      : size === "sm"
        ? [t.h(6), t.w(6), t.text.xs, t.rounded.xs]
        : [t.h(8), t.w(8), t.text.base, t.rounded.sm]}
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
  `;
});

import React from "react";

import FullModal from "@ats/src/components/modals/FullModal";
import CandidateForm from "@ats/src/views/jobApplications/candidates/CandidateForm";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { useUpdateCandidate } from "@shared/queryHooks/useCandidate";
import { Candidate } from "@shared/types/candidate";
import { JobApplication } from "@shared/types/jobApplication";
import { useUpdateJobApplication } from "@shared/queryHooks/useJobApplication";

type Props = {
  onCancel: () => void;
  onComplete: () => void;
  candidate: Candidate;
  jobApplication: JobApplication;
  jobApplicationId: number;
};

function EditCandidateModal(props: Props) {
  const { jobApplicationId } = props;
  const [errors, setErrors] = React.useState(null);
  const [candidate, setCandidate] = React.useState({ ...props.candidate });
  const [jobApplication, setJobApplication] = React.useState({
    source: props.jobApplication.source,
    desiredCompensation: props.jobApplication.desiredCompensation,
    settings: props.jobApplication.settings,
  });
  const [isDirty, setIsDirty] = React.useState(false);

  const { mutate: updateCandidate, isLoading: isLoadingUpdateCandidate } = useUpdateCandidate();
  const {
    mutate: updateJobApplication,
    isLoading: isLoadingUpdateJobApplication,
  } = useUpdateJobApplication();

  const addToast = useToastContext();
  const { dismissModalWithAnimation } = useModalContext();

  const handleCandidateChange = (updates) => {
    // window.logger("%c[EditCandidateModal] updates", "color: #1976D2", updates);
    setCandidate({ ...candidate, ...updates });
    setIsDirty(true);
  };

  const handleJobApplicationChange = (updates) => {
    setJobApplication({ ...jobApplication, ...updates });
  };

  const handleUpdateJobApplication = (applicationId: number, fullName: string) => {
    updateJobApplication(
      { ...jobApplication, id: applicationId },
      {
        onSuccess: (data) => {
          window.logger("%c[EditCandidateModal] onSubmit candidate", "color: #1900D2", {
            data,
          });
          setErrors(null);

          dismissModalWithAnimation(() => props.onComplete);
          addToast({ title: `Successfully updated ${fullName}`, kind: "success" });
        },
        onError: (response: any) => {
          setErrors(response.data.errors);
        },
      },
    );
  };

  const onSubmit = () => {
    window.logger("%c[EditCandidateModal] onSubmit candidate", "color: #1900D2", candidate);
    // const { id, ...candidateProps } = candidate;

    updateCandidate(
      { jobApplicationId, candidate },
      {
        onSuccess: (data) => {
          handleUpdateJobApplication(jobApplicationId, data.fullName);
        },
        onError: (response: { data: any }) => {
          setErrors(response.data.errors);
        },
      },
    );
  };

  return (
    <FullModal
      onCancel={() => dismissModalWithAnimation(() => props.onCancel)}
      hasUnsavedChanges={isDirty}
    >
      <CandidateForm
        formTitle={`Edit ${candidate.fullName}`}
        onSubmit={onSubmit}
        onCandidateChange={handleCandidateChange}
        onApplicationChange={handleJobApplicationChange}
        onCancel={() => dismissModalWithAnimation(() => props.onCancel)}
        isEditing={true}
        candidate={candidate}
        jobApplication={jobApplication}
        errors={errors}
        loading={isLoadingUpdateCandidate || isLoadingUpdateJobApplication}
      />
    </FullModal>
  );
}

export default EditCandidateModal;

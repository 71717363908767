import keys from "lodash/keys";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";

/* standardizeQueryParamsObject
--===================================================-- */
// This ensures that search params are in an Array
export function standardizeQueryParamsObject(queryObject) {
  const queryKeys = keys(queryObject);
  let filterObject = queryObject;
  queryKeys.forEach((filterKey) => {
    if (isString(filterObject[filterKey])) {
      let value = filterObject[filterKey];
      filterObject[filterKey] = [value];
    }
  });
  return filterObject;
}

export const trimFilename = (filename) => {
  let cutStart, cutEnd;

  if (filename.length > 20) {
    cutStart = 10;
    cutEnd = filename.length - 7;
    return filename.substr(0, cutStart) + "..." + filename.substr(cutEnd + 1);
  } else {
    return filename;
  }
};

export const clearUrlParams = (history) => {
  history.push({ search: "" });
};

/* URLS
--===================================================-- */
export const addHttpsPrefix = (url) => {
  if (url == undefined) {
    return null;
  }

  // return url.replace(/(^\w+:|^)\/\//, "");
  return url.test(/(^\w+:|^)\/\//, "");
};

export const removeHttpPrefix = (url) => {
  if (url == undefined) {
    return null;
  }

  return url.replace(/(^\w+:|^)\/\//, ""); // removes http:// or https://
};

export const removeKnownUrlPrefix = (url) => {
  if (url == undefined) {
    return null;
  }

  return removeHttpPrefix(url) // removes http:// or https://
    .replace(/(^www\.)/, "") // removes www
    .replace(/(^linkedin\.com\/in\/)/, "") // removes linkedin.com
    .replace(/(^twitter\.com\/)/, "") // removes twitter.com
    .replace(/(^github\.com\/)/, "") // removes github.com
    .replace(/(^dribbble\.com\/)/, ""); // removes dribbble.com
  // .replace(/(\/$)/, ""); // removes trailing slashes
};

/* Remove from Array
--===================================================-- */
export const removeFromArray = (array, fn) => {
  const index = array.findIndex(fn);

  return removeFromArrayAtIndex(array, index);
};

export const removeFromArrayAtIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
};

export const insertIntoArrayAtIndex = (array, item, index) => {
  console.log("%c[utils] insertIntoArrayAtIndex", "color: #1976D2", { array, item, index });
  return [...array.slice(0, index), item, ...array.slice(index, array.length)];
};

export const moveFromIndexToIndex = (array, fromIndex, toIndex) => {
  const item = array[fromIndex];
  let newArray = removeFromArrayAtIndex(array, fromIndex);
  return insertIntoArrayAtIndex(newArray, item, toIndex);
};

/* Convert Array to Select Options
--===================================================-- */
export const convertArrayToSelectOptions = (options) => {
  return options.map((option, index) => {
    return { value: option, label: option };
  });
};

/* FORMATTING
--===================================================-- */
export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    // TODO: Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return str;
};

/* Check Object for Empty, Null, or Undefined Values
--====================================================-- */
export const objectHasEmptyValues = (obj) => {
  return Object.values(obj).some((value) => {
    if (value === null || value === undefined || value === "") {
      return true;
    }
    if (isArray(value) && value.length === 0) {
      return true;
    }
    if (isPlainObject(value) && keys(value).length === 0) {
      return true;
    }

    return false;
  });
};

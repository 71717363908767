import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import CollapsibleItem from "@ats/src/components/shared/CollapsibleItem";
import Tooltip from "@ats/src/components/shared/Tooltip";

import { JobApplication, VisibilitySetting } from "@shared/types/jobApplication";

function CollapsibleQuestionResponses({ jobApplication }: { jobApplication: JobApplication }) {
  const sortedQuestionResponses =
    jobApplication?.questionResponses?.length > 0
      ? jobApplication?.questionResponses?.sort(function (a, b) {
          return (
            a.createdAtTimestamp - b.createdAtTimestamp || a.question.position - b.question.position
          );
        })
      : [];

  const adminOnlyLabel = (
    <Tooltip label="Members cannot see this question or the candidate's response to this question.">
      <Styled.Label key="draft">Admin only</Styled.Label>
    </Tooltip>
  );

  const isEmpty = sortedQuestionResponses.length === 0;

  const responses =
    sortedQuestionResponses.length > 0
      ? sortedQuestionResponses.map((questionResponse, index) => {
          const question = questionResponse?.question;
          let response;
          if (question?.kind === "multi_select") {
            response = (
              <Styled.List>
                {questionResponse.responseArray.map((selection) => {
                  return <li key={`${selection}-${index}`}>{selection}</li>;
                })}
              </Styled.List>
            );
          } else if (question?.kind === "file_upload") {
            response = (
              <Styled.Response>
                <Styled.DocumentLink
                  href={questionResponse?.customFileUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {questionResponse?.customFileFilename}
                  <Icon name="download" />
                </Styled.DocumentLink>
              </Styled.Response>
            );
          } else {
            response = <Styled.Response>{questionResponse?.body}</Styled.Response>;
          }

          if (question == undefined) return null;

          return (
            <Styled.Content key={questionResponse.id}>
              <Styled.Question>
                <p>{question.questionText}</p>
                {question.visibilitySetting === VisibilitySetting.PRIVATE && adminOnlyLabel}
              </Styled.Question>
              {response}
            </Styled.Content>
          );
        })
      : null;

  return (
    <CollapsibleItem
      label={"Application question responses"}
      emptyLabel={"No question responses to show"}
      iconName="file-text"
      isDisabled={isEmpty}
    >
      <Styled.QuestionResponses>{responses}</Styled.QuestionResponses>
    </CollapsibleItem>
  );
}

export default CollapsibleQuestionResponses;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.QuestionResponses = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_QuestionResponses;
    ${[t.rounded.sm, t.px(4), t.mt(2), t.mb(6)]}
    position: relative;
  `;
});

Styled.List = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_AnswerList;
    ${[t.ml(6), t.mt(1), t.mb(3)]}
    line-height: 1.6;
    white-space: pre-wrap;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    list-style-type: circle;

    li {
      ${[t.pt(1), t.pb("px")]}

      &:last-of-type {
        ${t.pb(0)}
      }
    }
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_QuestionContent;
    line-height: 1.6;
    &:first-of-type {
      ${[t.pt(4)]}
    }
    &:last-of-type {
      ${[t.mb(4)]}
    }
  `;
});

Styled.Question = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_Question;
    ${[t.text.bold, t.text.xs, t.mb(1)]}
    display: flex;
    line-height: 1.6;
    color: ${t.dark ? t.color.gray[300] : t.color.black};

    p {
      ${t.pt(1)}
    }
  `;
});

Styled.Response = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_Response;
    ${[t.mb(3)]}
    line-height: 1.6;
    white-space: pre-wrap;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.DocumentLink = styled.a((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_DocumentLink;
    display: flex;
    align-items: center;
    &:link,
    &:visited {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    &:hover {
      text-decoration: underline;
    }
    > svg {
      margin-left: 0.375rem;
      height: 1.125em;
      width: 1.125em;
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CollapsibleQuestionResponses_Label;
    ${[t.mx(2), t.px(2), t.h(6), t.pt("px"), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

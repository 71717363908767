import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useModalContext } from "@shared/context/ModalContext";

import {
  useCreateChannelMessageTemplate,
  useUpdateChannelMessageTemplate,
} from "@shared/queryHooks/useChannelMessageTemplate";

import { validateChannelMessageTemplate } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  isEditing: boolean;
  channelMessageTemplate: { id; name; body };
};

function ChannelMessageTemplateModal(props: Props) {
  const { isEditing, onCancel } = props;
  const { dismissModalWithAnimation } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  // const [selection, setSelection] = React.useState<
  //   { start: any; end: any; textarea: any } | undefined
  // >();
  const {
    mutate: createChannelMessageTemplate,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateChannelMessageTemplate();
  const {
    mutate: updateChannelMessageTemplate,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateChannelMessageTemplate();

  const [channelMessageTemplate, setChannelMessageTemplate] = useState(
    props.channelMessageTemplate,
  );

  const { name, body } = channelMessageTemplate;

  const editorRef = React.useRef(undefined);

  const [errors, setErrors] = useState([]);

  const handleChangeChannelMessageName = (name, value) => {
    setChannelMessageTemplate({ ...channelMessageTemplate, [name]: value });
    setErrors([]);
    setIsDirty(true);
  };

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[ChannelMessageTemplateModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  // });

  const handleCancel = () => {
    dismissModalWithAnimation(() => onCancel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = channelMessageTemplate;
    const [isValid, validationErrors] = await validateChannelMessageTemplate({
      name,
      body: editorRef.current.serializedState(),
    });

    if (isValid) {
      isEditing ? handleUpdate() : handleCreate();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCreate = async () => {
    try {
      await createChannelMessageTemplate({
        ...channelMessageTemplate,
        body: editorRef.current.serializedState(),
      });
      // jobChannelMessageTemplate was successfully created
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      window.logger(
        "%c[ChannelMessageTemplateModal] handle error when CREATE Job ChannelMessageTemplate",
        "color: #FF7602",
        {
          error,
          // error: error.response.data,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleUpdate = async () => {
    try {
      await updateChannelMessageTemplate({
        ...channelMessageTemplate,
        body: editorRef.current.serializedState(),
      });
      // jobChannelMessageTemplate was successfully updated
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[ChannelMessageTemplateModal] handle error when updating Job ChannelMessageTemplate",
        "color: #FF7602",
        {
          error,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="createJobButton"
        type="submit"
        onClick={handleSubmit}
        loading={isLoadingCreate || isLoadingUpdate}
      >
        {isEditing ? "Update template" : "Create template"}
      </Button>
      <Button styleType="secondary" onClick={handleCancel}>
        {/* <Button styleType="secondary" onClick={props.onCancel}> */}
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  const editMessage = `Rename or change the content for this message template.`;

  return (
    <CenterModal
      headerTitleText={isEditing ? "Edit template" : "Create new template"}
      onCancel={handleCancel}
      hasUnsavedChanges={isDirty}
    >
      <Styled.Instructions>
        {isEditing ? editMessage : "Enter the name and content for a new message template."}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={submitButton}>
        <FormInput
          onChange={handleChangeChannelMessageName}
          name="name"
          placeholder=""
          label="Template name"
          value={name || ""}
          errors={errors}
          autoFocus
        />

        <Styled.TemplateInput>
          <ProseMirrorEditor
            ref={editorRef}
            enableLabel
            label="Template body"
            editorId="templates-editor"
            enableStaticMenuBar={false}
            enableSelectionToolbar={true}
            enableMessageMailMergeMenuBar={true}
            enableMessageKeys={true}
            onChange={handleChangeEditorState}
            defaultValue={body || ""}
          />
        </Styled.TemplateInput>
      </FormContainer>
    </CenterModal>
  );
}

ChannelMessageTemplateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ChannelMessageTemplateModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.TemplateInput = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateModal_TemplateInput;
    > div {
      ${[t.mb(0)]}
    }
    textarea {
      min-height: 12rem;
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
// import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export default function Banner(props: { message: string | {}, loud?: boolean }) {
  return <Styled.Container isLoud={props.loud}>{props.message}</Styled.Container>;
}

// Banner.propTypes = {
//   // message: PropTypes.string.isRequired,
// };

/* Styled Components
======================================================= */

let Styled: any;
Styled = {};

const getColorStyles = (isLoud, t) => {
  if (isLoud) {
    return css`
      background: ${t.dark ?
        `linear-gradient(120deg, #FBD7FF -20%, #FFDEC1 40%, #FFDEC1 60%, #FBD7FF 120%)`
        :
        t.color.black
      };
      color: ${t.dark ? t.color.black : t.color.white};
    `
  } else {
    return css`
      background: ${t.dark ?
        `linear-gradient(120deg, ${t.color.gray[700]} -20%, ${t.color.gray[600]} 40%, ${t.color.gray[600]} 60%, ${t.color.gray[700]} 120%)`
        :
        'linear-gradient(120deg, #FFD8D8 -10%, #FFDEC1 40%, #FFDEC1 60%, #FFD8D8 110%)'
      };
      color: ${t.dark ? t.color.white : t.color.black};
    `
  }
}


Styled.Container = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: Banner;
    ${[t.text.xs, t.px(4), t.py(2)]};
    min-height: ${t.spacing[8]};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.4;
    z-index: 3;
    ${getColorStyles(props.isLoud, t)}

    a {
      ${t.text.medium}

      &:hover {
        text-decoration: underline;
      }
    }
  `;
});

import React from "react";
import { userRoleNames } from "@ats/src/lib/lookups";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import FormSelect from "@ats/src/components/forms/FormSelect";

export default function InviteListItem(props) {
  const { invite, roles, currentUser } = props;
  const [newRole, setNewRole] = React.useState(invite.role);

  const handleRoleSelectChange = (name, value) => {
    setNewRole(value);
    if (value) {
      window.logger(`[InviteListItem] handleRoleSelectChange - Selected: ${value}`);
      updateInviteRole(value);
    }
  };

  const updateInviteRole = (role) => {
    props.updateInviteRole(invite.id, { role });
  };

  const resendInvite = (e) => {
    e.preventDefault();
    props.resendInvite(invite.id);
  };

  const destroyInvite = (e) => {
    e.preventDefault();
    props.destroyInvite(invite.id);
  };

  const rolePretty = userRoleNames(newRole);

  const statusMessage =
    invite.status === "status_pending"
      ? "Invite sent - not yet accepted"
      : "Invite sent - rejected";

  return (
    <Styled.Container data-testid="invited-member">
      <Styled.Member>
        <Styled.Info>
          <Styled.Name>{invite.email}</Styled.Name>
          <Styled.Email>{statusMessage}</Styled.Email>
        </Styled.Info>
      </Styled.Member>
      <Styled.Controls>
        <Styled.Role>
          {currentUser.currentOrganizationUser.isAdmin && (
            <FormSelect
              // scope={this} // Would typically use scope if the value was in the top level state, but need to call updateInviteRole
              onChange={handleRoleSelectChange}
              name="role"
              label=""
              description=""
              className=""
              value={newRole}
              options={roles}
              errors={invite.errors}
            />
          )}
          {!currentUser.currentOrganizationUser.isAdmin && <span>{rolePretty}</span>}
        </Styled.Role>
        <DropdownMenu label="Options">
          <a href="" onClick={resendInvite}>
            Re-send invite
          </a>
          {currentUser.currentOrganizationUser.isAdmin && (
            <a href="" onClick={destroyInvite}>
              Cancel invite
            </a>
          )}
        </DropdownMenu>
      </Styled.Controls>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem;
    ${[t.pl(4), t.pr(3), t.py(3)]}
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    &:last-of-type {
      border-bottom: none;
    }
  `;
});

Styled.Member = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamInviteListItem_Member;
    display: flex;
    align-items: center;
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css``;
});

Styled.Name = styled.h3((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem_Name;
    ${[t.mr(2), t.mb(1)]}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.Email = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem_Email;
    ${[t.text.xs]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
});

Styled.Controls = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem_Controls;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    flex-shrink: 0;
  `;
});

Styled.Role = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem_Role;
    ${t.mr(4)}
    flex: 1;

    > div {
      ${t.mb(0)}
    }
  `;
});

Styled.DisabledAction = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: InviteListItem_DisabledAction;
    color: ${t.color.gray[500]} !important;
    cursor: not-allowed !important;
    &:hover {
      text-decoration: none !important;
    }
  `;
});

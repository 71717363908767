import { useQuery } from "react-query";
import { apiGet } from "@shared/queryHooks/api";
// import queryString from "query-string";

const getStatistics = async () => {
  return await apiGet({ path: `/admin/dashboard/statistics` });
};

function useStatistics(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useQuery(["statistics"], getStatistics);
}

export { useStatistics };

import React from "react";

export function useCookieValue(cookieKey): string | undefined {
  const [cookieValue, setCookieValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((cookie) => cookie.startsWith(`${cookieKey}=`));
    if (!cookie) return;
    setCookieValue(cookie.split("=")[1]);
  });

  return cookieValue;
}

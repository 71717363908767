import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const getJob = async (jobId) => {
  // window.logger("%c[useJob] getJob", "color: #1976D2", { key, jobId });
  if (jobId != undefined) {
    return await apiGet({
      path: `/jobs/${jobId}`,
    });
  }
};

const getJobs = async (status) => {
  window.logger("%c[useJob] getJobs\n\n", "background-color: #FF76D2", {
    status,
  });
  const statusParam = status != undefined ? `?status=${status}` : "";
  return await apiGet({ path: `/jobs${statusParam}` });
};

const getAllJobs = async (status) => {
  window.logger("%c[useJob] getAllJobs\n\n", "background-color: #0F76D2", {
    status,
  });
  const statusParam = status != undefined ? `?status=${status}` : "";
  return await apiGet({ path: `/jobs/all${statusParam}` });
};

const createJob = async (job) => {
  window.logger("%c[useJob] updateJob\n\n\n\n", "background-color: #FF76D2", {
    job,
  });
  return await apiPost({
    path: `/jobs`,
    variables: { job },
  });
};

const updateJob = async ({ id, ...job }) => {
  window.logger("%c[useJob] updateJob\n\n\n\n", "background-color: #FF76D2", {
    job,
  });
  return await apiPut({
    path: `/jobs/${id}`,
    variables: { job },
  });
};

const publishJob = async ({ id, ...job }) => {
  window.logger("%c[useJob] publishJob\n\n\n\n", "background-color: #FF76D2", {
    job,
  });
  return await apiPut({
    path: `/jobs/${id}/publish`,
    variables: {},
  });
};

const cloneJob = async ({ id, ...job }) => {
  window.logger("%c[useJob] cloneJob\n\n\n\n", "background-color: #FF76D2", {
    job,
  });
  return await apiPost({
    path: `/jobs/${id}/clone`,
    variables: {},
  });
};

const forceWebflowSyncForJob = async ({ id, ...job }) => {
  window.logger("%c[useJob] forceWebflowSyncForJob\n\n\n\n", "background-color: #FF76D2", {
    job,
  });
  return await apiPut({
    path: `/jobs/${id}/webflow_sync`,
    variables: {},
  });
};

const createBoardWwrListing = async ({ jobId, ...boardWwrListing }) => {
  window.logger("%c[useJob] createBoardWwrListing\n\n\n\n", "background-color: #FF76D2", {
    boardWwrListing,
  });
  return await apiPost({
    path: `/jobs/${jobId}/board_wwr_listings`,
    variables: { boardWwrListing },
  });
};

const updateBoardWwrListing = async ({ jobId, id, ...boardWwrListing }) => {
  window.logger("%c[useJob] updateBoardWwrListing\n\n\n\n", "background-color: #FF76D2", {
    boardWwrListing,
  });
  return await apiPut({
    path: `/jobs/${jobId}/board_wwr_listings/${id}`,
    variables: { boardWwrListing },
  });
};

/* Hooks
--===================================================-- */
function useCreateJob() {
  const queryClient = useQueryClient();
  return useMutation(createJob, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useJob] useUpdateJob onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries(["jobs", {}]); // empty object because when status is null it is removed by react-query
      queryClient.invalidateQueries(["jobs", { status: "status_archived" }]);
      queryClient.invalidateQueries(["allJobs", {}]);
      queryClient.invalidateQueries(["allJobs", { status: "status_archived" }]);
    },

    // throwOnError: true,
  });
}

function useUpdateJob() {
  const queryClient = useQueryClient();
  return useMutation(updateJob, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useJob] useUpdateJob onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
        queryClient,
      });
      queryClient.invalidateQueries(["jobs", data.id]);
      queryClient.invalidateQueries("jobs");
      // queryClient.invalidateQueries(["jobs", {}]);
      queryClient.invalidateQueries(["jobs", { status: "status_archived" }]);
      queryClient.invalidateQueries(["allJobs", {}]);
      queryClient.invalidateQueries(["allJobs", { status: "status_archived" }]);
    },

    // throwOnError: true,
  });
}

function useUpdateJobOptimistic(job) {
  const queryClient = useQueryClient();
  return useMutation(updateJob, {
    // When mutate is called:
    onMutate: (newJob) => {
      window.logger("%c[useJob] useUpdateJobOptimistic onMutate", "color: #1976D2", {
        job,
        newJob,
      });
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["jobs", newJob.id]);

      // Snapshot the previous value
      const previousJob = queryClient.getQueryData(["jobs", newJob.id]);

      // Optimistically update to the new value (combining the full job with whatever the update is)
      queryClient.setQueryData(["jobs", newJob.id], { ...job, ...newJob });

      // Return a rollback function
      return () => queryClient.setQueryData(["jobs", newJob.id], previousJob);
    },

    // onSuccess: (data, mutationVariables) => {
    //   window.logger("%c[useJob] useUpdateJob onSuccess", "color: #1976D2", {
    //     data,
    //     mutationVariables,
    //   });
    //   queryClient.invalidateQueries(["jobs", data.id]);
    // },

    // If the mutation fails, use the rollback function we returned above in onMutate
    onError: (err: any, newJob: any, rollback: any) => rollback(),
    // Always refetch after error or success:
    onSettled: (newJob) => {
      queryClient.invalidateQueries(["jobs", newJob.id]);
    },

    // throwOnError: true,
  });
}

function usePublishJob() {
  const queryClient = useQueryClient();
  return useMutation(publishJob, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useJob] usePublishJob onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["jobs", data.id]);
      queryClient.invalidateQueries(["jobs", {}]);
      queryClient.invalidateQueries(["jobs", { status: "status_archived" }]);
      queryClient.invalidateQueries(["allJobs", {}]);
      queryClient.invalidateQueries(["allJobs", { status: "status_archived" }]);
    },

    // throwOnError: true,
  });
}

function useCloneJob() {
  const queryClient = useQueryClient();
  return useMutation(cloneJob, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useJob] useCloneJob onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      // queryClient.invalidateQueries(["jobs", data.id]);
      queryClient.refetchQueries(["jobs", data.id]);
    },

    // throwOnError: true,
  });
}

function useForceWebflowSyncForJob() {
  const queryClient = useQueryClient();
  return useMutation(forceWebflowSyncForJob, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useJob] useForceWebflowSyncForJob onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["jobs", data.id]);
      queryClient.invalidateQueries(["jobs", {}]);
      queryClient.invalidateQueries(["jobs", { status: "status_archived" }]);
      queryClient.invalidateQueries(["allJobs", {}]);
      queryClient.invalidateQueries(["allJobs", { status: "status_archived" }]);
    },

    // throwOnError: true,
  });
}

function useCreateBoardWwrListing() {
  const queryClient = useQueryClient();
  return useMutation(createBoardWwrListing, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["jobs", data.id]);
    },

    // throwOnError: true,
  });
}

function useUpdateBoardWwrListing() {
  const queryClient = useQueryClient();
  return useMutation(updateBoardWwrListing, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["jobs", data.id]);
    },

    // throwOnError: true,
  });
}

function useJob({
  jobId,
  refetchOnWindowFocus = false,
}: {
  jobId: number;
  refetchOnWindowFocus?: boolean | "always";
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useJob] useJob", "color: #1976D2", { jobId, refetchOnWindowFocus });
  return useQuery(["jobs", jobId], () => getJob(jobId), {
    refetchOnWindowFocus,
    retry: (failureCount, error) =>
      !error.message.includes(401) && !error.message.includes(403) && !error.message.includes(422),
  });
}

function useJobs({
  refetchOnWindowFocus = false,
  status,
  enabled = true,
}: {
  refetchOnWindowFocus?: boolean | "always";
  status?: string;
  enabled?: boolean;
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useJob] useJobs", "color: #1976D2", { status, enabled, refetchOnWindowFocus });
  return useQuery(["jobs", { status }], () => getJobs(status), {
    refetchOnWindowFocus,
    enabled,
  });
}

function useAllJobs({
  refetchOnWindowFocus = false,
  status,
  enabled = true,
}: {
  refetchOnWindowFocus?: boolean | "always";
  status?: string;
  enabled?: boolean;
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => any;
} {
  window.logger("%c[useJob] useAllJobs", "color: #1976D2", {
    status,
    enabled,
    refetchOnWindowFocus,
  });
  return useQuery(["allJobs", { status }], () => getAllJobs(status), {
    refetchOnWindowFocus,
    enabled,
  });
}

export {
  useJob,
  useJobs,
  useAllJobs,
  useCreateJob,
  useUpdateJob,
  useUpdateJobOptimistic,
  usePublishJob,
  useCloneJob,
  useForceWebflowSyncForJob,
  useCreateBoardWwrListing,
  useUpdateBoardWwrListing,
};

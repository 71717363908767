import React, { useState } from "react";
import { Route, Redirect, NavLink, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import NavItem from "@ats/src/components/shared/NavItem";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import AccountIntegrationsGoogleAnalytics from "./AccountIntegrationsGoogleAnalytics";
import AccountIntegrationsGoogleJobs from "./AccountIntegrationsGoogleJobs";
import AccountIntegrationsWebflow from "./AccountIntegrationsWebflow";
import AccountIntegrationsSlack from "./AccountIntegrationsSlack";
import AccountIntegrationsDiscord from "./AccountIntegrationsDiscord";
import AccountIntegrationsPolymerWebhooks from "./AccountIntegrationsPolymerWebhooks";
import AccountIntegrationsZapier from "./AccountIntegrationsZapier";
import AccountIntegrationsLinkedIn from "./AccountIntegrationsLinkedIn";
import AccountIntegrationsXHiring from "./AccountIntegrationsXHiring";

import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";

function AccountIntegrationsContainer(props) {
  window.logger("%c[JobSetupContainer] ", "background: #EFDDEF; color: #1976D2", props);
  const { match } = props;

  const [isDirty, setIsDirty] = useState(false);
  const isAuthorized = useAuthorization();
  const authorized = isAuthorized({ adminOnly: true });

  if (!authorized) {
    return null;
  }

  return (
    <>
      <Helmet title="Integrations" />
      <Styled.Container>
        <Styled.Sidebar>
          <NavItem to={`${match.url}/googleanalytics`} label="Google Analytics" chevron />
          <NavItem to={`${match.url}/googlejobs`} label="Google Jobs" chevron />
          <FeatureFlipper feature="LINKEDIN_INTEGRATION">
            <NavItem to={`${match.url}/linkedin`} label="LinkedIn" chevron />
          </FeatureFlipper>
          <FeatureFlipper feature="X_HIRING_INTEGRATION">
            <NavItem to={`${match.url}/xhiring`} label="X Hiring" chevron />
          </FeatureFlipper>
          <NavItem to={`${match.url}/webflow`} label="Webflow" chevron />
          <FeatureFlipper feature="SLACK_INTEGRATION">
            <NavItem to={`${match.url}/slack`} label="Slack" chevron />
          </FeatureFlipper>
          <FeatureFlipper feature="DISCORD_INTEGRATION">
            <NavItem to={`${match.url}/discord`} label="Discord" chevron />
          </FeatureFlipper>
          <FeatureFlipper feature="ZAPIER_INTEGRATION">
            <NavItem to={`${match.url}/zapier`} label="Zapier" chevron />
          </FeatureFlipper>
          <NavItem to={`${match.url}/polymerwebhooks`} label="Polymer Webhooks" chevron />
        </Styled.Sidebar>

        <UnsavedChangesGuard
          hasUnsavedChanges={isDirty}
          navigate={(pathname) => {
            window.logger(
              "%c[AccountIntegrationsContainer] UnsavedChangesGuard navigate",
              "color: #1976D2",
              pathname,
            );
            setIsDirty(false);
            props.history.push(pathname);
          }}
        />

        <Styled.Content>
          <Switch>
            <Route
              path={`${match.path}/googleanalytics`}
              render={(renderProps) => (
                <AccountIntegrationsGoogleAnalytics
                  {...props}
                  {...renderProps}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/googlejobs`}
              render={(renderProps) => (
                <AccountIntegrationsGoogleJobs
                  {...props}
                  {...renderProps}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Route
              path={`${match.path}/linkedin`}
              render={(renderProps) => (
                <AccountIntegrationsLinkedIn {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/xhiring`}
              render={(renderProps) => (
                <AccountIntegrationsXHiring {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/webflow`}
              render={(renderProps) => (
                <AccountIntegrationsWebflow {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/slack`}
              render={(renderProps) => (
                <AccountIntegrationsSlack {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/discord`}
              render={(renderProps) => (
                <AccountIntegrationsDiscord {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/zapier`}
              render={(renderProps) => (
                <AccountIntegrationsZapier {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/polymerwebhooks`}
              render={(renderProps) => (
                <AccountIntegrationsPolymerWebhooks
                  {...props}
                  {...renderProps}
                  setIsDirty={setIsDirty}
                />
              )}
            />
            <Redirect to={`${match.url}/googleanalytics`} />
          </Switch>
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default AccountIntegrationsContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: AccountIntegrationsContainer;
  display: flex;
  height: 100%;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    padding-top: 0.375rem;
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    flex-shrink: 0;
    width: 40vw;

    ${t.mq["lg"]} {
      width: 33.333%;
      flex-shrink: 1;
    }
  `;
});

Styled.Content = styled.div`
  label: AccountIntegrationsContainer_Content;
  width: 66.666%;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

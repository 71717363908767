import React, { useState } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FullModal from "@ats/src/components/modals/FullModal";
import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";

import ProseMirrorEditor from "@shared/ProseMirror/Editor";

import { useModalContext } from "@shared/context/ModalContext";

import {
  useCreateCommentTemplate,
  useUpdateCommentTemplate,
} from "@shared/queryHooks/useCommentTemplate";

import { validateCommentTemplate } from "@shared/lib/validateWithYup";

type Props = {
  onCancel: () => void;
  isEditing: boolean;
  commentTemplate: { id; name; body };
};

function CommentTemplateModal(props: Props) {
  const { isEditing, onCancel } = props;
  const { dismissModalWithAnimation } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  // const [selection, setSelection] = React.useState<
  //   { start: any; end: any; textarea: any } | undefined
  // >();
  const {
    mutate: createCommentTemplate,
    error: errorOnCreate,
    isLoading: isLoadingCreate,
  } = useCreateCommentTemplate();
  const {
    mutate: updateCommentTemplate,
    error: errorOnUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateCommentTemplate();

  const [commentTemplate, setCommentTemplate] = useState(props.commentTemplate);

  const { name, body } = commentTemplate;

  const editorRef = React.useRef(undefined);

  const [errors, setErrors] = useState([]);

  const handleChangeCommentName = (name, value) => {
    setCommentTemplate({ ...commentTemplate, [name]: value });
    setErrors([]);
    setIsDirty(true);
  };

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  // window.logger("%c[CommentTemplateModal] render", "color: #1976D2", {
  //   props,
  //   errorOnCreate,
  //   errorOnUpdate,
  // });

  const handleCancel = () => {
    dismissModalWithAnimation(() => onCancel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = commentTemplate;
    const [isValid, validationErrors] = await validateCommentTemplate({
      name,
      body: editorRef.current.serializedState(),
    });

    if (isValid) {
      isEditing ? handleUpdate() : handleCreate();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCreate = async () => {
    try {
      await createCommentTemplate({
        ...commentTemplate,
        body: editorRef.current.serializedState(),
      });
      // jobCommentTemplate was successfully created
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      window.logger(
        "%c[CommentTemplateModal] handle error when CREATE CommentTemplate",
        "color: #FF7602",
        {
          error,
          // error: error.response.data,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  const handleUpdate = async () => {
    try {
      await updateCommentTemplate({
        ...commentTemplate,
        body: editorRef.current.serializedState(),
      });
      // jobCommentTemplate was successfully updated
      // onCancel(); // this closes the modal
      dismissModalWithAnimation(() => onCancel);
    } catch (error) {
      // Uh oh, something went wrong
      console.error(
        "%c[CommentTemplateModal] handle error when updating Job CommentTemplate",
        "color: #FF7602",
        {
          error,
        },
      );
      if (error.response && error.response.data) setErrors(error.response.data.errors);
    }
  };

  return (
    <FullModal onCancel={handleCancel} hasUnsavedChanges={isDirty}>
      <Styled.ModalContent>
        <Styled.FormHeader>
          <h2>{isEditing ? "Edit template" : "Create new template"}</h2>
        </Styled.FormHeader>

        <Styled.Container errors={errors} fullWidthForm modal onSubmit={handleSubmit}>
          <FormInput
            onChange={handleChangeCommentName}
            name="name"
            placeholder=""
            label="Template name"
            value={name || ""}
            errors={errors}
            autoFocus
          />
          <ProseMirrorEditor
            ref={editorRef}
            editorId="review-templates-editor"
            enableLabel
            enableStaticMenuBar
            enableCheckboxes={false}
            label="Template content"
            onChange={handleChangeEditorState}
            defaultValue={body || ""}
          />
        </Styled.Container>
        <Styled.ButtonContainer>
          <Button
            name="createJobButton"
            type="submit"
            onClick={handleSubmit}
            loading={isLoadingCreate || isLoadingUpdate}
          >
            {isEditing ? "Update template" : "Create template"}
          </Button>
          <Button styleType="secondary" onClick={handleCancel}>
            {/* <Button styleType="secondary" onClick={props.onCancel}> */}
            <span>Cancel</span>
          </Button>
        </Styled.ButtonContainer>
      </Styled.ModalContent>
    </FullModal>
  );
}

CommentTemplateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default CommentTemplateModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.FormHeader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateModal_FormHeader;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.ModalContent = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateModal_ModalContent;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.Container = styled(FormContainer)((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateModal_Container;
    ${[t.px(4), t.py(5)]}
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CommentTemplateModal_ButtonContainer;
    ${t.p(4)}
    position: sticky;
    bottom: 0;
    display: flex;
    border-bottom-left-radius: 0.4375rem;
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { jobOverrideSocialShareImageTypeOptions } from "@ats/src/lib/lookups";
import { useToastContext } from "@shared/context/ToastContext";
import { useJob, useUpdateJob, useUpdateJobOptimistic } from "@shared/queryHooks/useJob";

import FormSelect from "@ats/src/components/forms/FormSelect";
import FormUploader from "@ats/src/components/forms/FormUploader";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobSocialShareImageUploader(props: {
  jobId: number;
  // onError: (errors: any) => void;
  errors: any;
}) {
  // useWhyDidYouUpdate("JobSocialShareImageUploader", props);
  const {
    jobId,
    // onError,
    errors: passedErrors,
  } = props;
  const addToast = useToastContext();
  const [errors, setErrors] = React.useState(passedErrors);
  const { data: job, isFetching: isFetchingJob } = useJob({ jobId, refetchOnWindowFocus: false });
  // const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const {
    mutate: updateJobOptimistic,
    isLoading: isLoadingUpdateJobOptimistic,
  } = useUpdateJobOptimistic(job);

  if (job == undefined) {
    return null;
  }

  window.logger("%c[JobSocialShareImageUploader] render", "color: #1976D2", { job });

  const { socialShareImageUrl, socialShareImageFilename, hasSocialShareImage, settings } = job;
  const { socialShareImageType } = settings;
  const hasFile = socialShareImageType == "default" || hasSocialShareImage;

  const onCompleteDirectUpload = (blob) => {
    const { signed_id: socialShareImage } = blob;
    const { id } = job;

    if (socialShareImage) {
      updateJobOptimistic(
        { id, socialShareImage },
        {
          onSuccess: () => {
            addToast({ title: "Image uploaded", kind: "success" });
          },
          onError: (data) => {
            addToast({
              title: `Could not upload`,
              kind: "warning",
            });
            setErrors(data);
          },
        },
      );
    }

    return Promise.resolve(true); // need the true becuase the callback looks for data !== undefined in FormUploader
  };

  const onInvalidFile = () => {
    addToast({ title: `Invalid file`, kind: "warning" });
  };

  const updateJobSettings = (name, value) => {
    updateJobOptimistic({ id: jobId, settings: { [name]: value } });
  };

  return (
    <>
      <FormSelect
        onChange={updateJobSettings}
        name="socialShareImageType"
        label="Job post social media image"
        description="You can provide a custom social media image that will be specific to this job. By default, the social media image set for your job board will be used. "
        value={socialShareImageType}
        options={jobOverrideSocialShareImageTypeOptions}
        errors={errors}
      />

      {socialShareImageType === "custom" && (
        <FormConditionalFields>
          <FormUploader
            allowAutomaticUploading
            onCompleteDirectUpload={onCompleteDirectUpload}
            onInvalidFile={onInvalidFile}
            name="socialShareImage"
            label="Custom social media image"
            description="Provide the custom image to use. It should be an 1.9:1 ratio image with dimensions of 1200 x 630. PNG and JPG file types are acceptable. If no image is provided, the default will be used."
            htmlFor="socialShareImageUploader"
            fileIsAlreadyUploaded={hasFile}
            fileName={socialShareImageFilename}
            placeholder="Upload"
            errors={errors}
            loading={isLoadingUpdateJobOptimistic}
            expectedFileType="image"
          />
          {hasFile ? (
            <Styled.CardPreview>
              <img src={socialShareImageUrl} />
            </Styled.CardPreview>
          ) : null}
        </FormConditionalFields>
      )}
    </>
  );
}

JobSocialShareImageUploader.propTypes = {};

JobSocialShareImageUploader.defaultProps = {};

export default JobSocialShareImageUploader;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CardPreview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJob_CardPreview;
    ${[t.mt(-2), t.p(2), t.rounded.sm]}
    display: inline-block;
    vertical-align: top;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    > img {
      ${[t.rounded.md]}
      max-width: 32rem;
      overflow: hidden;
    }
  `;
});

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import persistState, { mergePersistedState } from "redux-localstorage";
import adapter from "redux-localstorage/lib/adapters/localStorage";
import filter from "redux-localstorage-filter";

import { composeWithDevTools } from "redux-devtools-extension";

// import merge from "lodash/merge";

const middleware = [thunk];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({});

const reducer = compose(
  // This merges localStorage with what is returned by the inflowReducers as the default state.
  // mergePersistedState((initialState, persistedState) => {
  //   return merge({}, initialState, persistedState);
  // }),
  mergePersistedState(),
)(rootReducer);

const storage = compose(
  filter("session"), // only save session and appData.drafts in localStorage
  // filter(['session', 'appData.drafts']) // only save session and appData.drafts in localStorage
)(adapter(window.localStorage));

const enhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  persistState(storage, "inflow"), // persisteState must be right after applyMiddleware and before other middleware
);

const store = createStore(
  // rootReducer,
  reducer,
  // initialState,
  enhancer,
);

window.logger("[store/index.js] store.getState()", store.getState());

export default store;

import find from "lodash/find";
import isArray from "lodash/isArray";

import countriesData from "@ats/src/lib/countries.json";
import usStatesData from "@ats/src/lib/usStates.json";
import currencyCodes from "@ats/src/lib/currencyCodes.json";

export const findSelectOptionByValue = (options, value) => {
  if (isArray(value)) {
    return value.map((val) => find(options, (option) => option.value === val));
  }
  return find(options, (option) => option.value === value);
};

export const findSelectOptionByLabel = (options, name) => {
  return find(options, (o) => o.label === name);
};

export const getOptionLabel = (options, value) => {
  const option = findSelectOptionByValue(options, value);
  return option ? option.label : "---";
};

// Generic - Yes Or No
export const yesOrNoOptions = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

// Generic = Enabled or Disabled
export const enabledOrDisabledOptions = [
  { value: false, label: "Disabled" },
  { value: true, label: "Enabled" }
]

/* Questions
--===================================================-- */
export const questionKindOptions = [
  { value: "text_short", label: "Short answer" },
  { value: "text_long", label: "Long answer" },
  { value: "single_select", label: "Multiple-choice (single select)" },
  { value: "multi_select", label: "Multiple-choice (multi select)" },
  { value: "file_upload", label: "File upload" },
];

export const questionKindNames = (name) => {
  const option = findSelectOptionByValue(questionKindOptions, name);
  return option ? option.label : "---";
};

/* Default Job Form Settings
--===================================================-- */
export const defaultJobSettings = {
  firstName: "required",
  lastName: "required",
  email: "required",
  phone: "optional",
  location: "optional",
  coverLetter: "optional",
  linkedinUrl: "hidden",
  githubUrl: "hidden",
  twitterUrl: "hidden",
  dribbbleUrl: "hidden",
  websiteUrl: "hidden",
  resume: "required",
};

export const jobSettingOptions = [
  {
    value: "required",
    label: "Required",
    note: "Candidates must fill this out",
  },
  { value: "optional", label: "Optional", note: "This field is optional" },
  {
    value: "hidden",
    label: "Hidden",
    note: "Candidates will not see this field",
  },
];

export const questionVisibilityOptions = [
  {
    value: "visibility_public",
    label: "Entire hiring team",
    note: "Everyone on the hiring team and assigned interviewers can view the answer to this question",
  },
  {
    value: "visibility_private",
    label: "Admin only",
    note: "Only admins can view the answer to this question",
  },
];

export const jobSettingNames = (name) => {
  const option = findSelectOptionByValue(jobSettingOptions, name);
  return option ? option.label : "---";
};

/* careerNavigationOptions
--===================================================-- */
export const careerNavigationOptions = [
  { value: false, label: "Default" },
  { value: true, label: "Custom URL" },
];

export const careerNavigationNames = (name) => {
  const option = findSelectOptionByValue(careerNavigationOptions, name);
  return option ? option.label : "---";
};

/* jobBoardBrandingOptions
--===================================================-- */

export const jobBoardLogoVisibilityOptions = [
  { value: "logo_visibility_both_logo_and_name", label: "Show logo and name" },
  { value: "logo_visibility_logo_only", label: "Show logo only" },
  { value: "logo_visibility_name_only", label: "Show name only" },
];

export const socialShareImageTypeOptions = [
  { value: "social_share_image_type_default", label: "Default" },
  { value: "social_share_image_type_custom", label: "Custom" },
  { value: "social_share_image_type_none", label: "None" },
];

export const jobOverrideSocialShareImageTypeOptions = [
  { value: "default", label: "Default" },
  { value: "custom", label: "Custom" },
  { value: "none", label: "None" },
];

export const billboardMediaTypeOptions = [
  { value: "billboard_media_type_none", label: "None" },
  { value: "billboard_media_type_image", label: "Image" },
  { value: "billboard_media_type_video", label: "Video" },
];

// User - Roles
export const userRoleOptions = [
  {
    value: "org_admin",
    label: "Admin",
    roleAbilities:
      "Admins can access all jobs, create jobs, assign members to jobs, invite new members, and change member roles.",
  },
  {
    value: "org_user",
    label: "Member",
    roleAbilities:
      "Members can only access jobs they have been assigned to. They cannot create jobs or invite new members.",
  },
  {
    value: "org_interviewer",
    label: "Interviewer",
    roleAbilities:
      "Interviewers can only submit candidate reviews. They can only access candidate information via the Review Kit",
  },
];

export const userRoleNames = (name) => {
  const option = findSelectOptionByValue(userRoleOptions, name);
  return option ? option.label : "---";
};

// Job - Kind
export const jobKindOptions = [
  { value: "full_time", label: "Full-time" },
  { value: "part_time", label: "Part-time" },
  { value: "temporary", label: "Temporary" },
  { value: "contract", label: "Contract" },
  { value: "internship", label: "Internship" },
  { value: "volunteer", label: "Volunteer" },
  { value: "other", label: "Other" },
];

export const jobKindNames = (name) => {
  const option = findSelectOptionByValue(jobKindOptions, name);
  return option ? option.label : "---";
};

// Job - Category
export const jobCategoryOptions = [
  { value: "category_sales_and_marketing", label: "Sales & Marketing" },
  { value: "category_design_and_ux", label: "Design & User Experience" },
  { value: "category_product_management", label: "Product Management" },
  { value: "category_customer_and_community", label: "Customer & Community" },
  { value: "category_content_and_copywriting", label: "Content & Copywriting" },
  { value: "category_software_development", label: "Software Development" },
  { value: "category_devops_and_sysadmin", label: "Dev Ops & Sysadmin" },
  { value: "category_operations_and_finance", label: "Operations & Finance" },
  { value: "category_quality_assurance", label: "Quality Assurance" },
  { value: "category_hr_and_recruiting", label: "HR & Recruiting" },
  { value: "category_security", label: "Security" },
  { value: "category_other", label: "Other" },
];

export const jobCategoryNames = (name) => {
  const option = findSelectOptionByValue(jobCategoryOptions, name);
  return option ? option.label : "---";
};

// Job - Status
export const jobStatusOptions = [
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
  { value: "in_review", label: "In review" },
  { value: "status_archived", label: "Archived" },
];

export const jobStatusNames = (name) => {
  const option = findSelectOptionByValue(jobStatusOptions, name);
  return option ? option.label : "---";
};

// Organization - Kind
export const organizationKindOptions = [
  { value: "kind_claimed", label: "Claimed" },
  { value: "kind_wrk_managed", label: "Polymer Managed" },
  { value: "kind_scrapable", label: "Scrapable" },
];

export const organizationKindNames = (name) => {
  const option = findSelectOptionByValue(organizationKindOptions, name);
  return option ? option.label : "---";
};

// Organization - Plan
export const organizationPlanOptions = [
  { value: "plan_no_plan", label: "No Plan" },
  { value: "plan_simple_ats_free", label: "Free" },
  { value: "plan_simple_ats_paid", label: "Paid Unlimited Monthly" },
  { value: "plan_simple_ats_per_job", label: "Paid - Per Job" },
  { value: "plan_ats_tier_gemini", label: "Paid - Gemini" },
  { value: "plan_ats_tier_apollo", label: "Paid - Apollo" },
  { value: "plan_ats_tier_artemis", label: "Paid - Artemis" },
];

export const organizationPlanNames = (name) => {
  const option = findSelectOptionByValue(organizationPlanOptions, name);
  return option ? option.label : "---";
};

export const adminOrganizationFlipperGroupOptions = [
  { value: "flipper_group_all", label: "Default" },
  { value: "flipper_group_beta_testers", label: "Beta Testers" },
];

export const adminBooleanOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

/* Interview - Venue
--===================================================-- */
export const interviewVenueOptions = [
  { value: "phone", label: "Phone" },
  { value: "video", label: "Video" },
  { value: "in_person", label: "In person" },
];

/* Interview - Kind
--===================================================-- */
export const interviewSynchronicityOptions = [
  { value: "synchronous", label: "Live" }, // Synchronous
  { value: "asynchronous", label: "Take home" }, // Asynchronous
];

// JobApplication - Stage
export const jobApplicationStageOptions = [
  { value: "inbox", label: "Inbox" },
  { value: "application", label: "Application" },
  { value: "screen", label: "Screen" },
  { value: "interview", label: "Interview" },
  { value: "decide", label: "Decide" },
  { value: "offer", label: "Offer" },
];

export const interviewableJobApplicationStageOptions = [
  { value: "screen", label: "Screen" },
  { value: "interview", label: "Interview" },
];

export const jobApplicationStageNames = (name) => {
  const option = findSelectOptionByValue(jobApplicationStageOptions, name);
  return option ? option.label : "---";
};

// JobApplication - Status
export const jobApplicationStatusOptions = [
  { value: "status_in_process", label: "In process" },
  { value: "status_archived", label: "Archived" },
  { value: "status_hired", label: "Hired" },
];

export const jobApplicationStatusNames = (name) => {
  const option = findSelectOptionByValue(jobApplicationStatusOptions, name);
  return option ? option.label : "---";
};

// JobApplication - Archive Reason
export const jobApplicationArchiveReasonOptions = [
  { value: "archive_position_filled", label: "Position filled" },
  { value: "archive_duplicate", label: "Duplicate" },
  { value: "archive_underqualified", label: "Underqualified" },
  { value: "archive_accepted_another_offer", label: "Accepted another offer" },
  { value: "archive_not_responding", label: "Not responding" },
  { value: "archive_not_interested", label: "Not interested" },
  { value: "archive_compensation", label: "Compensation" },
  { value: "archive_other", label: "Other" },
];

export const jobApplicationArchiveReasonNames = (name) => {
  const option = findSelectOptionByValue(jobApplicationArchiveReasonOptions, name);
  return option ? option.label : "---";
};

// Scorecard
export const scorecardRatingOptions = [
  { value: "strong_yes", label: "Strong yes" },
  { value: "weak_yes", label: "Weak yes" },
  { value: "weak_no", label: "Weak no" },
  { value: "strong_no", label: "Strong no" },
];

export const scorecardRatingNames = (name) => {
  const option = findSelectOptionByValue(scorecardRatingOptions, name);
  return option ? option.label : "---";
};

// Interview
export const interviewStatusOptions = [
  { value: "inactive", label: "Inactive" },
  { value: "active", label: "Open" },
  { value: "closed", label: "Closed" },
];

export const interviewStatusNames = (name) => {
  const option = findSelectOptionByValue(interviewStatusOptions, name);
  return option ? option.label : "---";
};

/* COUNTRY AND REGION DATA
--===================================================-- */
/* Country Data
--===================================================-- */
export const countryOptions = () => {
  return countriesData.map((country) => {
    return { value: country.code, label: country.name };
  });
};

/* State/Region Data
--===================================================-- */
export const unitedStatesOptions = () => {
  return usStatesData.map((state) => {
    return { value: state.code, label: state.name };
  });
};

// Organization - Remoteness
export const organizationRemotenessOptions = [
  // { value: "remoteness_none", label: "No Remote" },
  { value: "remoteness_some", label: "Some remote" },
  { value: "remoteness_mostly", label: "Mostly remote" },
  { value: "remoteness_fully", label: "Fully distributed" },
];

export const organizationRemotenessNames = (name) => {
  const option = findSelectOptionByValue(organizationRemotenessOptions, name);
  return option ? option.label : "---";
};

export const remoteRestrictionCountryResidencyIsRequiredNames = (name) => {
  const option = findSelectOptionByValue(yesOrNoOptions, name);
  return option ? option.label : "---";
};

export const remoteRestrictionOverlapHoursIsRequiredNames = (name) => {
  const option = findSelectOptionByValue(yesOrNoOptions, name);
  return option ? option.label : "---";
};

// Job - Remoteness
export const jobRemotenessOptions = [
  { value: "remoteness_none", label: "No remote" },
  { value: "remoteness_optional", label: "Remote friendly" },
  { value: "remoteness_hybrid", label: "Hybrid" },
  { value: "remoteness_only", label: "Remote (only)" },
];

export const jobRemotenessNames = (name) => {
  const option = findSelectOptionByValue(jobRemotenessOptions, name);
  return option ? option.label : "---";
};

// Job - applyThrough
export const applyThroughOptions = [
  { value: "apply_through_hire", label: "Through a Polymer posting" },
  { value: "apply_through_third_party_ats", label: "Through your own link" },
];

export const applyThroughNames = (name) => {
  const option = findSelectOptionByValue(applyThroughOptions, name);
  return option ? option.label : "---";
};

// Job - remoteRestrictionOverlapHours
export const remoteRestrictionOverlapHoursOptions = [
  { value: 1, label: "1 hour" },
  { value: 2, label: "2 hours" },
  { value: 3, label: "3 hours" },
  { value: 4, label: "4 hours" },
  { value: 5, label: "5 hours" },
  { value: 6, label: "6 hours" },
  { value: 7, label: "7 hours" },
  { value: 8, label: "8 hours" },
];

export const remoteRestrictionOverlapHoursNames = (name) => {
  const option = findSelectOptionByValue(remoteRestrictionOverlapHoursOptions, name);
  return option ? option.label : "---";
};

// Stripe

export const cardBrand = (brand) => {
  const cards = {
    amex: "American Express",
    diners: "Diners",
    discover: "Discover",
    jcb: "JCB",
    mastercard: "Mastercard",
    unionpay: "Unionpay",
    visa: "Visa",
  };

  return cards[brand] || "Unknown";
};

/* We Work Remotely WWR
--===================================================-- */
export const wwrCategoryOptions = [
  { value: "category_design", label: "Design" },
  // { value: "category_programming", label: "Programming" },
  { value: "category_fullstack_programming", label: "Full-Stack Programming" },
  { value: "category_frontend_programming", label: "Front-End Programming" },
  { value: "category_backend_programming", label: "Back-End Programming" },
  { value: "category_customer_support", label: "Customer Support" },
  // { value: "category_copywriting", label: "Copywriting" },
  { value: "category_devops_and_sysadmin", label: "DevOps and Sysadmin" },
  { value: "category_sales_and_marketing", label: "Sales and Marketing" },
  {
    value: "category_business_and_management",
    label: "Business, Management and Finance",
  },
  { value: "category_product", label: "Product" },
  { value: "category_all_other", label: "All Other Remote" },
  //  { value: "category_finance_and_legal", label: "Finance & Legal" },
  //  { value: "category_administrative", label: "Administrative" },
  //  { value: "category_education", label: "Education" },
  //  {
  //    value: "category_translation_and_transcription",
  //    label: "Translation & Transcription",
  //  },
  //  { value: "category_medical_and_health", label: "Medical/Health" },
];

export const wwrUpgradeCosts = {
  good: { price: "59", total: "358" },
  better: { price: "99", total: "398" },
  best: { price: "149", total: "448" },
};

/* Polymer Job Fields
--===================================================-- */
export const webflowJobFieldMappingOptions = [
  { value: "title", label: "Title", type: "PlainText" },
  { value: "description", label: "Description", type: "RichText" },
  { value: "kind", label: "Employment type", type: "PlainText" },
  { value: "category", label: "Category", type: "PlainText" },
  // { value: "department", label: "Department" },
  { value: "location", label: "Location", type: "PlainText" },
  { value: "remoteness", label: "Remote friendliness", type: "PlainText" },
  { value: "publishedAt", label: "Published at", type: "DateTime" },
  { value: "applicationUrl", label: "Application form URL", type: "Link" },
];

/* Review Rating Options
--===================================================-- */
export const reviewRatingOptions = [
  { label: "Strong yes", value: "strong_yes" },
  { label: "Weak yes", value: "weak_yes" },
  { label: "Weak no", value: "weak_no" },
  { label: "Strong no", value: "strong_no" },
  { label: "No rating", value: "opt_out" },
];

export const salaryUnitOptions = [
  { label: "Hourly", value: "HOUR" },
  { label: "Daily", value: "DAY" },
  { label: "Weekly", value: "WEEK" },
  { label: "Monthly", value: "MONTH" },
  { label: "Yearly", value: "YEAR" },
];

export const salaryCurrencyCodeOptions = currencyCodes.map((code) => ({
  label: code.code,
  value: code.code,
}));

export const localeCodeOptions = [
  { label: "English (en)", value: "en" },
  { label: "Dutch (de)", value: "de" },
  { label: "Swedish (se)", value: "se"}, 
];

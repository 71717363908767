import React, { Component } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { removeHttpPrefix, removeKnownUrlPrefix } from "@shared/lib/utils";

import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSection from "@ats/src/components/forms/FormSection";
import FormContainer from "@ats/src/components/forms/FormContainer";
import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import { ShallowCandidate } from "@shared/types/candidate";
import { JobApplication } from "@shared/types/jobApplication";
import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";

type Props = {
  formTitle: string;
  candidate: Partial<ShallowCandidate>;
  jobApplication: Partial<JobApplication>;
  errors?: object;
  uploadResume?: () => {};
  onSubmit: () => void;
  onCancel: () => void;
  onCandidateChange: (candidate) => void;
  onApplicationChange: (application) => void;
  isEditing: boolean;
  loading: boolean;
};

function CandidateForm(props: Props) {
  // window.logger("%c[CandidateForm] props", "background: pink; color: #1976D2", props);
  const authorized = useAuthorization();
  const { formTitle, errors, isEditing, candidate, loading, jobApplication } = props;
  const {
    firstName,
    lastName,
    email,
    phone,
    location,
    linkedinUrl,
    githubUrl,
    twitterUrl,
    dribbbleUrl,
    websiteUrl,
  } = candidate;

  const {
    source,
    desiredCompensation,
    settings: { messages, overview, compensation },
  } = jobApplication;

  const onSubmit = (event) => {
    event.preventDefault();
    props.onSubmit();
  };

  const handleCandidateChange = (name, value) => {
    let val = value;

    if (["linkedinUrl", "githubUrl", "twitterUrl", "dribbbleUrl"].includes(name)) {
      val = removeKnownUrlPrefix(val);
    } else if (name === "websiteUrl") {
      val = removeHttpPrefix(val);
    }

    props.onCandidateChange({ [name]: val });
  };

  const handleApplicationChange = (name, value) => {
    if (["messages", "overview", "compensation"].includes(name)) {
      props.onApplicationChange({
        settings: {
          ...jobApplication.settings,
          [name]: value,
        },
      });
    } else {
      props.onApplicationChange({ [name]: value });
    }
  };

  const submitButtonTitle = isEditing ? "Update candidate" : "Create candidate";

  const isAuthorized = authorized({
    minimumRole: "org_user",
    adminOnly: jobApplication.settings.compensation === "private",
  });

  const jobApplicationSettingsOptions = [
    {
      label: "Entire hiring team",
      value: "public",
    },
    {
      label: "Admin only",
      value: "private",
    },
  ].map((option) => ({ value: option.value, label: option.label }));

  return (
    <>
      <Styled.FormHeader>
        <h2>{formTitle}</h2>
      </Styled.FormHeader>

      <SettingsContainer fullWidthForm>
        <FormContainer
          id="connect-external-button-to-candidate-form"
          onSubmit={onSubmit}
          errors={errors}
        >
          <FormSection title="Basic information">
            <FormInput
              onChange={handleCandidateChange}
              name="firstName"
              placeholder=""
              label="First name"
              className=""
              value={firstName}
              isRequired={true}
              errors={errors}
              autoFocus
            />

            <FormInput
              onChange={handleCandidateChange}
              name="lastName"
              placeholder=""
              label="Last name"
              className=""
              value={lastName || ""}
              isRequired={false}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              inputType="email"
              name="email"
              placeholder=""
              label="Email address"
              className=""
              value={email || ""}
              // isRequired={true}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="phone"
              placeholder=""
              label="Phone number"
              className=""
              value={phone || ""}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="location"
              placeholder="City, state, etc."
              label="Location"
              className=""
              value={location || ""}
              errors={errors}
            />
          </FormSection>

          <FormSection title="Links">
            <FormInput
              onChange={handleCandidateChange}
              name="linkedinUrl"
              placeholder="..."
              label="LinkedIn profile"
              pretext="https://linkedin.com/in/"
              // value={linkedinUrl.replace(/(^\w+:|^)\/\//, "") || ""}
              value={removeKnownUrlPrefix(linkedinUrl) || ""}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="twitterUrl"
              placeholder="..."
              label="Twitter profile"
              pretext="https://twitter.com/"
              value={removeKnownUrlPrefix(twitterUrl) || ""}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="githubUrl"
              placeholder="..."
              label="GitHub profile"
              pretext="https://github.com/"
              value={removeKnownUrlPrefix(githubUrl) || ""}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="dribbbleUrl"
              placeholder="..."
              label="Dribbble profile"
              pretext="https://dribbble.com/"
              value={removeKnownUrlPrefix(dribbbleUrl) || ""}
              errors={errors}
            />

            <FormInput
              onChange={handleCandidateChange}
              name="websiteUrl"
              placeholder="..."
              pretext="https://"
              label="Website URL"
              value={removeHttpPrefix(websiteUrl) || ""}
              errors={errors}
            />
          </FormSection>
          <FormSection title="Application information">
            <FormInput
              onChange={handleApplicationChange}
              name="source"
              placeholder=""
              label="Source"
              className=""
              value={source || ""}
              errors={errors}
            />
            {isAuthorized && (
              <FormInput
                onChange={handleApplicationChange}
                name="desiredCompensation"
                placeholder=""
                label="Desired compensation"
                className=""
                value={desiredCompensation || ""}
                errors={errors}
              />
            )}
          </FormSection>
          {authorized({ minimumRole: "org_admin" }) && (
            <FormSection title="Application settings">
              <FormSelect
                onChange={handleApplicationChange}
                name="overview"
                label="Comments and reviews visibility"
                className=""
                description="Set who can see comments and reviews that have been published to the overview. Individual hiring team members can always still create, view, and edit their own comments and reviews."
                value={overview || ""}
                options={jobApplicationSettingsOptions}
                errors={errors}
              />
              <FormSelect
                onChange={handleApplicationChange}
                name="messages"
                label="Messages visibility"
                className=""
                description="Set who has access to the candidate message feed. Only those with access can create and view messages."
                value={messages || ""}
                options={jobApplicationSettingsOptions}
                errors={errors}
              />
              <FormSelect
                onChange={handleApplicationChange}
                name="compensation"
                label="Desired compensation visibility"
                className=""
                description="Set who has access to the candidate's desired compensation. Only those with access can edit and view desired compensation."
                value={compensation || ""}
                options={jobApplicationSettingsOptions}
                errors={errors}
              />
            </FormSection>
          )}
        </FormContainer>
      </SettingsContainer>
      <Styled.ButtonContainer>
        <Button
          type="submit"
          loading={loading}
          onClick={onSubmit}
          form="connect-external-button-to-candidate-form" // must be same as id on form
        >
          {submitButtonTitle}
        </Button>
        <Button styleType="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      </Styled.ButtonContainer>
    </>
  );
}

export default CandidateForm;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.FormHeader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CandidateForm_FormHeader;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CandidateForm_ButtonContainer;
    ${t.p(4)}
    position: sticky;
    bottom: 0;
    display: flex;
    border-bottom-left-radius: 0.4375rem;
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

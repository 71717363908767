import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut } from "@shared/queryHooks/api";


const searchUsers = async (userEmail) => {
  return await apiPost({
    path: `/admin/users/search`,
    variables: { searchTerm: userEmail },
  });
};

/* Hooks
--===================================================-- */

export function useSearchUsers(): {
  mutate: any;
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useMutation(searchUsers);
}
/* eslint-disable @getify/proper-arrows/return */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import FormUploader from "@ats/src/components/forms/FormUploader";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Tooltip from "@ats/src/components/shared/Tooltip";
import Icon from "@ats/src/components/shared/Icon";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";

import { useToastContext } from "@shared/context/ToastContext";
import {
  useJobApplicationFiles,
  useAttachFileToJobApplication,
  useDeleteFileFromJobApplication,
} from "@shared/queryHooks/useJobApplicationFiles";
import { MEGABYTE_IN_BYTES } from "@ats/src/lib/utils/fileUploads";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { VisibilitySetting } from "@shared/types/jobApplication";

export default function JobApplicationFiles(props) {
  const addToast = useToastContext();
  const { currentOrganizationUser } = useCurrentSession();
  const { jobApplication } = props;
  const [uploading, setUploading] = React.useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [errors, setErrors] = useState(null);

  const {
    data: jobApplicationFilesData,
    isLoading: isLoadingJobApplicationFiles,
  } = useJobApplicationFiles({
    jobApplicationId: jobApplication?.id,
  });

  const {
    mutate: attachFileToJobApplication,
    error: errorOnAttachment,
    isLoading: isLoadingAttachment,
  } = useAttachFileToJobApplication();

  const {
    mutate: deleteFileFromJobApplication,
    error: errorOnDeleteAttachment,
    isLoading: isLoadingDeleteAttachment,
  } = useDeleteFileFromJobApplication();

  window.logger("%c[JobApplicationFiles] render", "background-color: #19F0F2", {
    isLoadingAttachment,
    uploading,
  });

  const onChangeUploader = ({ fileToUpload }) => {
    setFileToUpload(fileToUpload);
  };

  /* Once file is uploaded to S3 go ahead and update the JobApplication with the file blob's signed_id
  --===================================================-- */
  const onCompleteDirectUpload = async (blob) => {
    const { signed_id: uploadedFile } = blob;

    window.logger("%c[JobApplicationFiles] onCompleteDirectUpload", "background-color: #F9FFD2", {
      uploadedFile,
      blob,
    });

    if (uploadedFile) {
      setErrors(null);

      try {
        return await attachFileToJobApplication(
          {
            id: jobApplication.id,
            attachment: uploadedFile,
          },
          {
            onSuccess: () => {
              setUploading(false);
            },
            onError: (error: any) => {
              setUploading(false);
              if (error?.data?.errors?.documents) {
                addToast({ title: error?.data?.errors?.documents[0], kind: "warning" });
              } else {
                addToast({ title: `Could not upload file`, kind: "warning" });
              }
            },
          },
        );
      } catch (error) {
        // Uh oh, something went wrong
        console.error(
          "%c[JobApplicationFiles] handle error when updating JobApplication",
          "color: #FF7602",
          {
            error,
          },
        );
        // if (error.response && error.response.data) setErrors(error.response.data.errors);
        return Promise.resolve();
      }
    } else {
      addToast({ title: `Could not upload file`, kind: "warning" });
      return Promise.resolve();
    }
  };

  const onStartDirectUpload = (loading) => {
    setUploading(loading);
  };

  const onInvalidFile = () => {
    addToast({ title: `File can not exceed 10MB`, kind: "warning" });
  };

  const handleDeleteDocument = async (e, attachment) => {
    e.preventDefault();

    return await deleteFileFromJobApplication(
      {
        id: jobApplication.id,
        attachment,
      },
      {
        onSuccess: () => {
          setUploading(false);
        },
        onError: (error: any) => {
          setUploading(false);
          if (error?.data?.errors?.documents) {
            addToast({ title: error?.data?.errors?.documents[0], kind: "warning" });
          } else {
            addToast({ title: `Could not delete file`, kind: "warning" });
          }
        },
      },
    );
  };

  // const actionButton = () => {
  //   return (
  //     <FormUploader
  //       name="file"
  //       allowAutomaticUploading={true}
  //       onChange={onChangeUploader}
  //       onCompleteDirectUpload={onCompleteDirectUpload}
  //       onStartDirectUpload={onStartDirectUpload}
  //       onInvalidFile={onInvalidFile}
  //       fileIsAlreadyUploaded={false}
  //       file={fileToUpload}
  //       placeholder="Upload"
  //       expectedFileType="any"
  //       maxFileSize={MEGABYTE_IN_BYTES * 10}
  //       errors={errors}
  //       displayType="button"
  //       loading={uploading}
  //       isRequired={false}
  //     />
  //   );
  //   // }
  // };

  const renderFileList = (files, canDelete = true, privateResponseFileIds = []) => {
    return (
      <Styled.List>
        {files.map((file) => {
          return (
            <Styled.ListItem key={file.id}>
              <div>
                <Styled.DocumentName>
                  <Icon name="file" />
                  {file.filename}
                </Styled.DocumentName>
                {privateResponseFileIds.includes(file.id) && (
                  <Tooltip label="Members cannot view this file. The candidate uploaded this file in response to a question set to 'Admin only' visibility.">
                    <Styled.Label key="draft">Admin only</Styled.Label>
                  </Tooltip>
                )}
              </div>
              <div>
                <Styled.DocumentLink href={file.url} target="_blank" rel="noreferrer noopener">
                  Download
                  {/* <Icon name="external-link" /> */}
                </Styled.DocumentLink>
                {canDelete && (
                  <DropdownMenu label="Options">
                    <a href="" onClick={(e) => handleDeleteDocument(e, file.id)}>
                      Delete
                    </a>
                  </DropdownMenu>
                )}
              </div>
            </Styled.ListItem>
          );
        })}
      </Styled.List>
    );
  };

  const resumeFile = (jobApplicationFilesData || []).filter(
    (file) => file.attachmentType === "resume" && file.recordType === "JobApplication",
  );
  const questionResponseFiles = (jobApplicationFilesData || []).filter(
    (file) => file.attachmentType === "custom_file" && file.recordType === "QuestionResponse",
  );
  const applicationFiles = [...resumeFile,...questionResponseFiles];
  const messageFiles = (jobApplicationFilesData || []).filter(
    (file) => file.attachmentType === "files" && file.recordType === "ChannelMessage",
  );
  const additionalFiles = (jobApplicationFilesData || []).filter(
    (file) => file.attachmentType === "additional_files" && file.recordType === "JobApplication",
  );
  const canViewMessageFiles =
    jobApplication.settings.messages === "public" || currentOrganizationUser.isAdmin;

  const privateResponseFileIds = (questionResponseFiles || []).filter((file) =>
    jobApplication?.questionResponses?.some((response) =>
      response.id === file.recordId && response.question?.visibilitySetting === VisibilitySetting.PRIVATE
  )).map((file) => file.id);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Subtitle>Files</Styled.Subtitle>
        {/* <Styled.Actions>{actionButton()}</Styled.Actions> */}
      </Styled.Header>
      <Styled.Content>
        <Styled.Section>
          <Styled.SectionLabel>Application attachments</Styled.SectionLabel>
          {jobApplicationFilesData && applicationFiles.length > 0 ? (
            renderFileList(applicationFiles, false, privateResponseFileIds)
          ) : (
            <Styled.NoFiles>No files were included in the candidates application</Styled.NoFiles>
          )}
        </Styled.Section>

        <Styled.Section>
          <Styled.SectionLabel>Message attachments</Styled.SectionLabel>
          {canViewMessageFiles ? (
            jobApplicationFilesData && messageFiles.length > 0 ? (
              renderFileList(messageFiles, false)
            ) : (
              <Styled.NoFiles>
                No file attachments have been received from this candidate
              </Styled.NoFiles>
            )
          ) : (
            <Styled.NoFiles>
              Access to view message attachments is currently limited to admins
            </Styled.NoFiles>
          )}
        </Styled.Section>

        <Styled.Section>
          <Styled.SectionLabel>Additional file storage</Styled.SectionLabel>
          {
            jobApplicationFilesData && additionalFiles.length > 0 ? (
              renderFileList(additionalFiles)
            ) : (
              <Styled.NoFiles>
                No additional files have been uploaded to this job application
              </Styled.NoFiles>
            )
            // <EmptyState
            //   borderless={false}
            //   icon="save"
            //   title="No files to show"
            //   message="No files have been uploaded to this job application"
            // />
          }
          <Styled.Actions>
            {uploading || isLoadingAttachment ? (
              <Styled.Processing>
                <LoadingIndicator label="Uploading..." />
              </Styled.Processing>
            ) : (
              <FormUploader
                name="file"
                allowAutomaticUploading={true}
                onChange={onChangeUploader}
                onCompleteDirectUpload={onCompleteDirectUpload}
                onStartDirectUpload={onStartDirectUpload}
                onInvalidFile={onInvalidFile}
                fileIsAlreadyUploaded={false}
                file={fileToUpload}
                placeholder="Upload file"
                expectedFileType="any"
                maxFileSize={MEGABYTE_IN_BYTES * 10}
                errors={errors}
                displayType="button"
                loading={uploading}
                isRequired={false}
              />
            )}
          </Styled.Actions>
        </Styled.Section>
      </Styled.Content>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Content;
    ${[t.p(4), t.pb(0)]}
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;
});

Styled.Section = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Section;
    ${[t.mb(4)]}
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${t.p(4)}
    label: JobApplicationFiles_Header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
  `;
});

Styled.Subtitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Subtitle;
    ${t.text.h2}
  `;
});

Styled.Processing = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Processing;
    ${[t.mb(8), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    width: 100%;
    min-height: 6.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};

    > div {
      ${t.m(0)}
    }
  `;
});

Styled.List = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_List;
    ${[t.mt(3), t.mb(4)]}
  `;
});

Styled.ListItem = styled.li((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_ListItem;
    ${[t.px(4), t.pr(2), t.mb("-px")]}
    min-height: ${t.spacing[12]};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
    div {
      display: flex;
      align-items: center;
    }
  `;
});

Styled.NoFiles = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_List;
    ${[t.mt(3), t.mb(4), t.px(4), t.rounded.md, t.text.xs]}
    min-height: ${t.spacing[12]};
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.DocumentLink = styled.a((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_DocumentLink;
    ${[t.mr(2), t.text.medium]}
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    &:link,
    &:visited {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    &:hover {
      text-decoration: underline;
    }
    > svg {
      margin-left: 0.375rem;
      height: 1.125em;
      width: 1.125em;
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.DocumentName = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_DocumentLinkName;
    > svg {
      ${t.mr(2)}
    }
  `;
});

Styled.SectionLabel = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Event;
    ${[t.ml(1), t.mb(3), t.text.bold]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  `;
});

Styled.Icon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationFiles_Icon;
    ${[t.mr(2), t.ml(3), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Actions;
    ${[t.mt(8)]}

    > div {
      margin-bottom: 0;
    }
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Label;
    ${[t.mt("-px"), t.mx(2), t.h(6), t.px(2), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
  `;
});

import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";
// import queryString from "query-string";

const becomeUser = async ({ userId }) => {
  return await apiPost({ path: `/admin/sessions/become`, variables: { userId } });
};

export function useBecomeUser(): {
  mutate: any;
  isLoading: boolean;
} {
  const queryClient = useQueryClient();
  return useMutation(becomeUser, {
    onSuccess: (data) => {
      window.logger("%c[useBecome] useBecomeUser onSuccess", "color: #1976D2", {
        data,
      });
      // queryClient.setQueryData("me", data);
    },
    // throwOnError: true,
  });
}

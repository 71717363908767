import { JobApplication } from "./jobApplication";

export interface ShallowCandidate {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    location: string;
    linkedinUrl: string;
    githubUrl: string;
    twitterUrl: string;
    dribbbleUrl: string;
    websiteUrl: string;
};

export interface Candidate extends ShallowCandidate {
    jobApplications: JobApplication[];
}

export interface ConnectMember extends ShallowCandidate {
    hasValidEmail: boolean;
    jobApplicationsCount: number;
    jobApplications: JobApplication[];
};

export enum CandidatePrivacyStatus {
    PUBLIC = "public",
    NEEDS_ANONYMIZATION = "needs_anonymization",
    ANONYMIZED = "anonymized"
}
import React from "react";
import { Prompt } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  hasUnsavedChanges: any;
  navigate?: (pathname) => void;
  location?: any;
};

function UnsavedChangesGuard(props: RouteComponentProps<Props>) {
  const { openModal, removeModal } = useModalContext();
  const [discardChanges, setDiscardChanges] = React.useState(false);
  const [desiredLocation, setDesiredLocation] = React.useState(undefined);
  const { hasUnsavedChanges, navigate } = props;

  // window.logger("%c[UnsavedChangesGuard] RENDER ", "color: #207c3f", {
  //   discardChanges,
  //   desiredLocation,
  //   propsLocation: props.location,
  // });

  React.useEffect(() => {
    if (discardChanges) {
      if (navigate) {
        navigate(desiredLocation.pathname);
      } else {
        props.history.push(desiredLocation.pathname);
      }
    }
  }, [discardChanges, desiredLocation, navigate]);

  const handleConfirmNavigation = (event) => {
    event.preventDefault();
    setDiscardChanges(true);
  };

  const handleCancelNavigation = () => {
    removeModal();
    setDiscardChanges(false);
  };

  const customPrompt = (location, action) => {
    const modal = (
      <CenterModal headerTitleText="You have unsaved changes" onCancel={handleCancelNavigation}>
        <Styled.Instructions>
          Your changes have not been saved. Discard the changes to continue or select Cancel to let
          you save your work.
        </Styled.Instructions>
        <Styled.ButtonContainer>
          <Button onClick={handleConfirmNavigation}>Discard changes</Button>
          <Button styleType="secondary" onClick={handleCancelNavigation}>
            Cancel
          </Button>
        </Styled.ButtonContainer>
      </CenterModal>
    );

    if (discardChanges) {
      removeModal();
      setDesiredLocation(undefined);
      setDiscardChanges(false);
      return true;
    }

    // Only show the modal if you are navigating away from current route
    if (props?.location?.pathname !== location.pathname) {
      setDesiredLocation(location);
      openModal(modal);
    }

    return false;
  };

  return <Prompt when={hasUnsavedChanges} message={customPrompt} />;
}

export default withRouter(UnsavedChangesGuard);

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CandidateForm_ButtonContainer;
    ${t.mt(6)}
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: UnsavedChangesGuard_Instructions;
    ${[t.mb(5)]}
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import UserAvatar from "@ats/src/components/shared/UserAvatar";

import { useAuthorization } from "@ats/src/components/shared/AuthorizationManager";

/*
--===================================================-- */

export default function TeamMemberListItem(props) {
  const { removeTeamMember, teamMember } = props;
  const authorized = useAuthorization();

  const handleRemoveTeamMember = (e) => {
    e.preventDefault();
    removeTeamMember(teamMember.id);
  };

  return (
    <Styled.Container>
      <Styled.Member>
        <UserAvatar user={teamMember.user} size="lg" />
        <Styled.MemberInfo>
          <Styled.MemberWrapper>
            <h3 data-testid="hiring-team-member-fullname">{teamMember.user.fullName}</h3>
            {teamMember.isAdmin && <Styled.Label>Admin</Styled.Label>}
          </Styled.MemberWrapper>
          <span>{teamMember.user.email}</span>
        </Styled.MemberInfo>
      </Styled.Member>
      {authorized({ minimumRole: "org_admin" }) ? (
        <DropdownMenu label="Options">
          <a href="" onClick={handleRemoveTeamMember}>
            Remove from team
          </a>
        </DropdownMenu>
      ) : null}
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem;
    ${[t.pl(4), t.pr(3), t.py(3), t.mb("-px")]}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }
    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  `;
});

Styled.Member = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem_Member;
    display: flex;
    align-items: center;
  `;
});

Styled.MemberInfo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem_MemberInfo;
    ${t.ml(3)}

    h3 {
      ${t.mb(1)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    span {
      ${[t.text.xs]}
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
  `;
});

Styled.MemberWrapper = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: TeamMemberListItem_MemberWrapper;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Label;
    ${[t.mb(1), t.ml(2), t.h(4), t.px(1), t.rounded.xs, t.text.normal]}
    font-size: 0.625rem;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1;
  `;
});

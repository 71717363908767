import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPost, apiPut, apiGet, apiDelete } from "@shared/queryHooks/api";
import { RegisteredWebhook } from "@shared/types/registeredWebhooks";

const getRegisteredWebhooks = async () => {
  return await apiGet({ path: `/registered_webhooks` });
};

const createRegisteredWebhook = async ({ url }: RegisteredWebhook) => {
  return await apiPost({
    path: `/registered_webhooks`,
    variables: { url },
  });
};

const updateRegisteredWebhook = async ({ url, id }: RegisteredWebhook) => {
  return await apiPut({
    path: `/registered_webhooks/${id}`,
    variables: { url },
  });
};

const deleteRegisteredWebhook = async ({ id }: RegisteredWebhook) => {
  return await apiDelete({
    path: `/registered_webhooks/${id}`,
    variables: {},
  });
};

export function useRegisteredWebhooks(): {
  status: any;
  data: { items: RegisteredWebhook[] };
  error: any;
  isLoading: boolean;
} {
  return useQuery(["registered_webhooks"], () => getRegisteredWebhooks());
}

export function useCreateRegisteredWebhook() {
  return useMutation(createRegisteredWebhook, {
    // throwOnError: true,
  });
}

export function useUpdateRegisteredWebhook() {
  return useMutation(updateRegisteredWebhook, {
    // throwOnError: true,
  });
}

export function useDeleteRegisteredWebhook() {
  return useMutation(deleteRegisteredWebhook, {
    // throwOnError: true,
  });
}

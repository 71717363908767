import { useRef, useState, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export default function useMeasure() {
  const ref = useRef(null);
  const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0 });
  
  const [resizeObserver] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        requestAnimationFrame(() => {
          setBounds(entry.contentRect);
        });
      }),
  );

  useEffect(() => {
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return [ref, bounds as any];
}
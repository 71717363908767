import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut } from "@shared/queryHooks/api";

const getJobApplicationFiles = async ({ jobApplicationId }) => {
  if (jobApplicationId) {
    return await apiGet({ path: `/job_applications/${jobApplicationId}/files` });
  }
};

const attachFileToJobApplication = async ({ id, attachment }) => {
  window.logger(
    "%c[useJobApplication] attachFileToJobApplication\n\n\n\n",
    "background-color: #FF76D2",
    {
      id,
      attachment,
    },
  );
  return await apiPut({
    path: `/job_applications/${id}/files/attach_file`,
    variables: { attachment },
  });
};

const deleteFiletFromJobApplication = async ({ id, attachment }) => {
  window.logger(
    "%c[useJobApplication] deleteFiletFromJobApplication\n\n\n\n",
    "background-color: #FF76D2",
    {
      id,
      attachment,
    },
  );
  return await apiPut({
    path: `/job_applications/${id}/files/remove_file`,
    variables: { attachment },
  });
};

export function useJobApplicationFiles({
  jobApplicationId,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["jobApplicationFiles", jobApplicationId], () =>
    getJobApplicationFiles({ jobApplicationId }),
  );
}

function useAttachFileToJobApplication() {
  const queryClient = useQueryClient();
  return useMutation(attachFileToJobApplication, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["jobApplicationFiles", mutationVariables.id]);
    },
  });
}

function useDeleteFileFromJobApplication() {
  const queryClient = useQueryClient();
  return useMutation(deleteFiletFromJobApplication, {
    onSuccess: (data, mutationVariables) => {
      queryClient.invalidateQueries(["jobApplicationFiles", mutationVariables.id]);
    },
  });
}

export { useAttachFileToJobApplication, useDeleteFileFromJobApplication };

import colors from "./colors";
import theme from "./theme";

const poly = {
  color: {
    canvas: colors.white,
    border: colors.gray[200],
    borderHover: colors.gray[400],
    loudText: colors.black,
    primaryText: colors.black,
    secondaryText: colors.gray[600],
    placeholderText: colors.gray[500],

    subtleHover: colors.gray[100],
    loudHover: colors.gray[200],

    wellCanvas: colors.gray[100],

    calloutCanvas: colors.gray[100],

    dropdownCanvas: colors.white,
    dropdownBorder: colors.gray[400],
    dropdownDivider: colors.gray[200],
    dropdownHover: colors.gray[200],
    dropdownTextHover: colors.black,

    cardCanvas: colors.white,
    cardBorder: colors.gray[200],
    cardDivider: colors.gray[200],

    chipBorder: colors.gray[300],
  },
  ...theme,
};

export default poly;

import { useMutation, useQueryClient } from "react-query";
import { apiPost } from "@shared/queryHooks/api";

const createInterviewerRequest = async ({
  jobApplicationId,
  organizationUserId,
}: {
  jobApplicationId: number;
  organizationUserId: number;
}) => {
  if (jobApplicationId && organizationUserId) {
    return await apiPost({
      path: `/job_applications/${jobApplicationId}/interviewer_requests`,
      variables: { organizationUserId },
    });
  }
};

/* Hooks
--===================================================-- */

export function useCreateInterviewerRequest() {
  const queryClient = useQueryClient();
  return useMutation(createInterviewerRequest, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useInterviewerRequest] useCreateInterviewerRequest onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries(["InterviewerRequests", data.jobApplicationId]);
      queryClient.invalidateQueries("jobApplicationActivities");
    },
    // throwOnError: true,
  });
}

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import EmptyState from "@ats/src/components/shared/EmptyState";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import useMeasure from "@shared/hooks/useMeasure";

function Resume({ jobApplication }) {
  const [numPages, setNumPages] = React.useState(1);
  const [resizeRef, { height, width }] = useMeasure();

  window.logger("%c[Resume] RENDER", "color: #1976D2", { width });

  const onDocumentLoadSuccess = (documentData) => {
    window.logger("%c[JobApplicationResume] onDocumentLoadSuccess", "color: #1976D2", {
      documentData,
    });
    setNumPages(documentData.numPages);
  };

  const handleLoadError = (data) => {
    const status = data.status;
    window.logger("%c[JobApplicationResume] handleLoadError", "color: #1976D2", {
      data,
      status,
    });

    // Extra fallback in case the URL has expired, shouldn't be needed anymore since we switched to using the Rails urls
    if (data.status === 403) {
      // TODO: refetch Job Application
    }
  };

  const handlePageRenderSuccess = (data) => {
    window.logger("%c[JobApplicatioResume] the first Page is now Rendered", "color: #1976D2", {
      data,
    });
  };

  const microsoftViewer = () => (
    <Styled.DocumentWrapper>
      <div>
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${jobApplication.resumeEncodedS3Url}`}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </div>
    </Styled.DocumentWrapper>
  );

  const pages = Array.from(new Array(numPages), (el, index) => {
    // window.logger("%c[JAR] pages", "color: #1976D2", { el, index });

    // using the classes from imported (required) stylesheet to override the yellow hightlighting of links on hover
    // .annotationLayer .linkAnnotation & .annotationLayer .buttonWidgetAnnotation.pushButton 
    return (
      <Page
        className="page"
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        loading={null}
        onRenderSuccess={handlePageRenderSuccess}
        renderTextLayer={false}
        renderAnnotationLayer={true}
        width={width}
        externalLinkTarget="_blank"
      />
    );
  });

  const pdfRenderer = () => (
    <Styled.ResumeContainer ref={resizeRef}>
      <Document
        file={jobApplication?.resumeUrl}
        onLoadError={handleLoadError}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
        loading={
          <Styled.Loading>
            <LoadingIndicator label="Loading..." />
          </Styled.Loading>
        }
        error={
          <EmptyState
            borderless={true}
            icon="cloud-lightning"
            title="Resume can't be displayed"
            message="Something prevented the resume from loading correctly."
          />
        }
        noData={
          <EmptyState
            borderless={true}
            icon="file"
            title="No resume to show"
            message="A resume has not been uploaded for this candidate"
          />
        }
      >
        {pages}
      </Document>
    </Styled.ResumeContainer>
  );

  return <div>{pdfRenderer()}</div>;
  //   <>
  //     {jobApplication?.shouldUseMicrosoftDocxViewer ? (
  //       <div>Microsoft</div>
  //     ) : (
  //       <div>{pdfRenderer()}</div>
  //     )}
  //   </>
  // ); // microsoftViewer() : pdfRenderer()
}

Resume.defaultProps = {};

export default Resume;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.ResumeContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationResume_ResumeContainer;

    > div {
      ${t.rounded.md}
      border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      background-color ${t.dark ? t.color.gray[800] : t.color.white};
      overflow: hidden;
      min-height: 7rem;

      .page {
        border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      }

      .page:last-child {
        border-bottom: none;
      }

      .annotationLayer .linkAnnotation > a:hover,
      .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
        opacity: 0;
        box-shadow: none;
      }
    }
  `;
});

Styled.Loading = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationResume_Loading;
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    height: 6.875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      ${t.m(0)}
    }
  `;
});

Styled.DocumentWrapper = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationResume_DocumentWrapper;
    ${t.rounded.md}
    flex: 1;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    overflow: hidden;
    > div {
      ${[t.mt("-px"), t.ml("-px")]}
      height: 100%;
      width: 100%;
    }
  `;
});

import React from "react";
import { timeAgoInWordsLong, prettyDate } from "@shared/lib/time";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import PromoCodeCenterModal from "@ats/src/components/modals/PromoCodeCenterModal";
import { Styled } from "@ats/src/views/accountAdmin/AccountBillingNew";

import Icon from "@ats/src/components/shared/Icon";
import Button from "@ats/src/components/shared/Button";

import {
  Subtitle,
  PricingOptions,
  Pricing,
  PricingTitle,
  PricingPrice,
  ButtonWrapper,
  Badge,
  Promo,
  CurrentSubscription,
} from "@ats/src/views/accountAdmin/accountBilling/AccountBillingNewPlanChoices";

import {
  useCreateStripeCustomerPortalSession,
  useStripeCustomerSubscription,
  useChangeSubscriptionViaStripePortal,
} from "@shared/queryHooks/useBilling";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { prettyBillingPrice } from "@ats/src/lib/utils/helpers";

const AccountBillingCurrentPlan: React.FC<{
  currentOrganization: any;
  hasActiveSubscription: boolean;
  billingPrices: any[];
}> = ({ currentOrganization, hasActiveSubscription, billingPrices }) => {
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();

  /* Create Stripe Customer Portal Session
  --===================================================-- */
  const { mutate: createStripeCustomerPortalSession } = useCreateStripeCustomerPortalSession();

  /* Create Custom Stripe Customer Portal Session for Changing Subscription
  --===================================================-- */
  const {
    mutate: changeSubscriptionViaStripePortal,
    isLoading: isLoadingChangeSubscriptionViaStripePortal,
  } = useChangeSubscriptionViaStripePortal();

  /* Get Subscription
  --===================================================-- */
  const {
    data: stripeCustomerSubscriptionData,
    isFetching: isFetchingStripeCustomerSubscription,
  } = useStripeCustomerSubscription({
    refetchOnWindowFocus: false,
  });

  const currentSubscription = stripeCustomerSubscriptionData
    ? stripeCustomerSubscriptionData.subscription
    : null;

  const currentPriceObject = currentSubscription && currentSubscription.items.data[0].price;
  const currentSubscriptionItemId = currentSubscription && currentSubscription.items.data[0].id;
  const currentProductPrice =
    currentPriceObject != undefined
      ? currentPriceObject.billingScheme === "tiered"
        ? currentPriceObject.tiers[0].unitAmount / 100.0
        : currentPriceObject.unitAmount / 100.0
      : null;

  const isOnLegacyPlan = ["plan_simple_ats_per_job", "plan_simple_ats_paid"].includes(
    currentOrganization.plan,
  );

  const isTrialing =
    currentSubscription &&
    currentSubscription.status === "trialing" &&
    currentSubscription.discount != undefined;
  // const trialEndDays = isTrialing && formatMDY(currentSubscription.trialEnd);
  const trialEndDays = isTrialing && timeAgoInWordsLong(currentSubscription.trialEnd);

  const hasCoupon =
    currentSubscription &&
    currentSubscription.discount &&
    currentSubscription.discount.coupon != undefined;
  const discount = hasCoupon && currentSubscription.discount;
  const coupon = discount && discount.coupon;

  // Billing Prices
  const geminiBillingPrices = billingPrices.filter((price) => price.lookupKey?.includes("gemini"));
  const apolloBillingPrices = billingPrices
    .filter((price) => price.lookupKey?.includes("apollo"))
    .sort((a, b) => a.unitAmount - b.unitAmount); // Sorting by unitAmount so that the Monthly Plan shows up first in the list

  window.logger("%c[AccountBillingCurrentPlan] render", "background: #ed79ed; color: #b5d0eb", {
    location,
    currentOrganization,
    currentSubscription,
    currentPriceObject,
    currentProductPrice,
    isTrialing,
    trialEndDays,
    hasCoupon,
    discount,
    coupon,
    billingPrices,
    isLoadingChangeSubscriptionViaStripePortal,
  });

  const handleOnClickUsePromoCode = (event) => {
    event.preventDefault();

    const modal = <PromoCodeCenterModal onCancel={() => removeModal()} />;

    openModal(modal);
  };

  /**
   * USED IF A CUSTOMER WANTS TO MANAGE THEIR BILLING USING THE STRIPE CUSTOMER PORTAL
   * This function handles the creation of a Stripe Customer Portal Session.
   * It calls the `createStripeCustomerPortalSession` function with specific parameters,
   * including the return URL. Upon successful creation of the session, it redirects the user
   * to the Stripe Customer Portal page. If the redirection fails, it logs the error message.
   */
  const handleCreateBillingPortalSession = () => {
    createStripeCustomerPortalSession(
      { returnUrl: "/hire/settings/billing" },
      {
        onSuccess: (data) => {
          window.logger("%c[AccountContainer] ", "color: #1976D2", { data });
          window.location.href = data.redirectUrl;
        },
      },
    );
  };

  /**
   * USED IF A CUSTOMER WANTS TO CHANGE THEIR SUBSCRIPTION
   */
  const handleChangeSubscriptionViaStripePortal = ({
    priceId,
    subscriptionItemId,
  }: {
    priceId: string;
    subscriptionItemId: string;
  }) => {
    changeSubscriptionViaStripePortal(
      {
        priceId,
        subscriptionItemId,
        returnUrl: "/hire/settings/billing",
      },
      {
        onSuccess: (data) => {
          window.logger(
            "%c[AccountBilling] created session for updating Subscription",
            "background: orange; color: #1976D2",
            data,
          );
          window.location.href = data.redirectUrl;
        },
        onError: (error: any) => {
          const errorMessage =
            error?.data?.errors?.general?.[0] || "Error while opening the Stripe portal.";
          window.logger(
            "%c[AccountBilling] ERROR creating Custom Stripe Billing Portal session",
            "background: orange; color: white",
            error,
          );
          addToast({ title: errorMessage, kind: "error" });
        },
      },
    );
  };

  return (
    <>
      <Subtitle>Subscription options</Subtitle>
      <PricingOptions>
        {apolloBillingPrices.map((price) => {
          const currentPlan = currentSubscription?.plan?.id === price.id;
          return (
            <Pricing key={price.id}>
              <PricingTitle>
                <h4>{price.interval || price.recurring.interval}ly plan </h4>
                {currentPlan && <Badge>Current plan</Badge>}
              </PricingTitle>

              <PricingPrice>
                <p>
                  ${prettyBillingPrice(price)}
                  <span>
                    per month <br />
                    billed {price.interval || price.recurring.interval}ly
                  </span>
                </p>
              </PricingPrice>
              <ButtonWrapper>
                {/* <Button styleType="secondary" onClick={handleCreateBillingPortalSession}>
              Manage billing
            </Button> */}
                {/* {canRenewSubscription ? (
                <Button
                  onClick={() => handleCreateSubscription(price.id)}
                  loading={isLoadingCreateSubscription}
                >
                  Restart subscription
                </Button>
              ) : (
                <Button
                  styleType="secondary"
                  onClick={() =>
                    handleCreateCheckoutSubscriptionSession({
                      priceId: price.id,
                      planType: "unlimited",
                    })
                  }
                >
                  Start subscription
                </Button>
              )} */}

                {currentPlan ? (
                  <Styled.SubscripionActions>
                    <Button onClick={handleCreateBillingPortalSession}>Manage billing</Button>

                    {hasActiveSubscription &&
                      !hasCoupon &&
                      currentOrganization.stripePromoCode == undefined && (
                        <Styled.SubscriptionMenu>
                          <DropdownMenu>
                            <Button onClick={handleOnClickUsePromoCode} styleType="text">
                              Use promo code
                            </Button>
                          </DropdownMenu>
                        </Styled.SubscriptionMenu>
                      )}
                  </Styled.SubscripionActions>
                ) : (
                  <Button
                    styleType="secondary"
                    onClick={() =>
                      handleChangeSubscriptionViaStripePortal({
                        priceId: price.id,
                        subscriptionItemId: currentSubscriptionItemId,
                      })
                    }
                    loading={isLoadingChangeSubscriptionViaStripePortal && !currentPlan}
                  >
                    Change subscription
                  </Button>
                )}
              </ButtonWrapper>
            </Pricing>
          );
        })}
      </PricingOptions>

      {isOnLegacyPlan && (
        <CurrentSubscription>
          <p>
            <Icon name="check" />
            <span>
              You're currently subscribed to{" "}
              {isOnLegacyPlan
                ? "a legacy per-job pricing plan"
                : `the ${
                    currentPriceObject?.interval || currentPriceObject?.recurring?.interval
                  }ly plan.`}{" "}
              {isTrialing && !hasCoupon && `Free for ${trialEndDays}.`}
            </span>
          </p>
          <Button styleType="text" onClick={handleCreateBillingPortalSession}>
            Manage billing
          </Button>
        </CurrentSubscription>
      )}

      {/* {isOnLegacyPlan ? (
        <Styled.Subscription>
          <Styled.SubscriptionRow>
            <div>
              {currentOrganization.plan === "plan_simple_ats_per_job" ? (
                <>
                  <h3>
                    {currentSubscription?.quantity} published job{" "}
                    {pluralize("post", currentSubscription?.quantity)}
                  </h3>
                  <span>
                    {isTrialing && !hasCoupon && `Free for ${trialEndDays} then `}$
                    {currentProductPrice * (currentSubscription?.quantity || 0)} per month ($
                    {currentProductPrice} each)
                  </span>
                </>
              ) : currentOrganization.plan === "plan_simple_ats_paid" ? (
                <>
                  <h3>Unlimited published job posts</h3>
                  <span>
                    ${currentProductPrice} per {currentPriceObject?.recurring.interval}
                  </span>
                </>
              ) : null}
            </div>

            {isOnLegacyPlan && <Badge>Current plan</Badge>}
          </Styled.SubscriptionRow>
        </Styled.Subscription>
      ) : null} */}

      {hasCoupon ? (
        <Promo>
          {/* <h4>{coupon.name}</h4> */}
          <h4>Promo code applied</h4>
          <span>
            {coupon.percentOff}% off until {prettyDate(discount.end)}
          </span>
        </Promo>
      ) : null}
    </>
  );
};

export default AccountBillingCurrentPlan;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Tooltip from "@ats/src/components/shared/Tooltip";

// import { QueryClientProvider, QueryClient } from "react-query";

import ChannelMessageList from "@ats/src/views/jobApplications/channelMessages/ChannelMessageList";
import ChannelMessageNew from "@ats/src/views/jobApplications/channelMessages/ChannelMessageNew";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

function JobApplicationMessages(props) {
  // window.logger("%c[JobApplicationMessages] ", "background: #EFDDEF; color: #1976D2", props);
  const { currentUser, currentOrganizationUser } = useCurrentSession();
  const { jobApplication, candidate, setIsDirty } = props;

  const messagesRef = React.useRef(null);
  const allChannel = jobApplication.channels.find((channel) => channel.kind === "kind_all");

  const [hasMessages, setHasMessages] = React.useState(
    allChannel != undefined && allChannel.channelMessagesCount > 0,
  );

  const canSendChannelMessages =
    currentOrganizationUser.isAdmin || jobApplication.settings.messages === "public";

  const displayAdminOnly =
    currentOrganizationUser.isAdmin && jobApplication.settings.messages === "private";

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  if (allChannel == undefined) {
    return null;
  }

  return (
    <Styled.Container>
      <Styled.Title
        messagesExist={
          hasMessages &&
          !(jobApplication.settings.messages === "private" && !currentOrganizationUser.isAdmin)
        }
      >
        <Styled.TitleWrapper>
          <h2>Messages</h2>
          {displayAdminOnly && (
            <Tooltip label="Members are not able to see the candidate message feed">
              <Styled.Label key="draft">Admin only</Styled.Label>
            </Tooltip>
          )}
        </Styled.TitleWrapper>
      </Styled.Title>
      <Styled.MessageFeed ref={messagesRef} aria-label="List of messages with candidate">
        <ChannelMessageList
          channel={allChannel}
          candidate={candidate}
          scrollToBottom={scrollToBottom}
          jobId={jobApplication.jobId}
          visibility={jobApplication.settings.messages}
        />
      </Styled.MessageFeed>

      {candidate && candidate.email && canSendChannelMessages && (
        <ChannelMessageNew
          jobApplication={jobApplication}
          setIsDirty={setIsDirty}
          allChannel={allChannel}
        />
      )}
    </Styled.Container>
  );
}

JobApplicationMessages.propTypes = {};
JobApplicationMessages.defaultProps = {};

export default function JobApplicationMessagesWrapper(props) {
  window.logger("%c[JobApplicationMessagesWrapper] props", "color: #1976D2", props);
  // const queryClient = new QueryClient({
  //   defaultOptions: {
  //     queries: { refetchOnWindowFocus: false },
  //   },
  // });
  return (
    // <QueryClientProvider client={queryClient}>
    props.jobApplication.channels != undefined && <JobApplicationMessages {...props} />
    // </QueryClientProvider>
  );
}

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.Title = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Title;
    ${[t.pt(4), t.pb(4), t.px(4), t.text.h2]}
    border-bottom: ${props.messagesExist
      ? `1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]}`
      : `none`};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.TitleWrapper = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_TitleWrapper;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  `;
});

Styled.MessageFeed = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_MessageFeed;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Label;
    ${[t.mx(3), t.h(6), t.px(2), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
  `;
});

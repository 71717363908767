import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormTextArea from "@ats/src/components/forms/FormTextarea";

import { validateSupportMessage } from "@shared/lib/validateWithYup";

import { useCreateSupportMessage } from "@shared/queryHooks/useSupportMessage";
import { useToastContext } from "@shared/context/ToastContext";

function SupportMessageModal(props) {
  const { onCancel } = props;

  const addToast = useToastContext();


  const [errors, setErrors] = React.useState(null);
  const [isDirty, setIsDirty] = React.useState(false);
  const [supportMessage, setSupportMessage] = React.useState({
    // "replyToEmail": "",
    "subject": "",
    "body": "",
  })

  const { subject, body } = supportMessage

  const {
    mutate: createSupportMessage,
    isLoading: isLoadingCreate,
  } = useCreateSupportMessage();

  window.logger("%c[SupportMessageModal] render", "color: #1976D2", {
    props,
    errors,
  });

  const handleFormInputChange = (name, value) => {
    setErrors({});
    setIsDirty(true);
    setSupportMessage({...supportMessage, [name]: value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const [isValid, validationErrors] = await validateSupportMessage({
      subject: subject,
      body: body,
    });

    if (isValid) {
      handleSendSupportMessage();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSendSupportMessage = async () => {
    setIsDirty(false);
    createSupportMessage(
      {
        // replyToEmail: replyToEmail,
        subject: subject,
        body: body,
      },
      {
        onSuccess: () => {
          addToast({
            title: "Message sent",
            kind: "success",
          });
        },
        onSettled: () => {
          // SupportMessage was successfully created
          onCancel(); // this closes the modal
        },
      },
    );
  };

const submitButton = (
  <Styled.ButtonContainer>
    <Button
      name="createSupportMessageButton"
      type="submit"
      onClick={handleSubmit}
      loading={isLoadingCreate}
      disabled={isLoadingCreate}
    >
      Send message
    </Button>
    <Button styleType="secondary" onClick={onCancel}>
      <span>Cancel</span>
    </Button>
  </Styled.ButtonContainer>
);

return (
  <CenterModal headerTitleText={"Contact Polymer"} onCancel={onCancel} hasUnsavedChanges={isDirty}>
    <Styled.Instructions>
      {
        "Get help with an issue, give your feedback, or share your ideas."
      }
    </Styled.Instructions>
    <FormContainer errors={errors} buttons={submitButton}>
      {/* <FormSelect
          onChange={handleFormInputChange}
          name="supportMessageKind"
          label="Feedback Type"
          // description="Choose your type of feedback."
          className=""
          value={kind}
          options={[
            { value: "issue", label: "Issue" },
            { value: "idea", label: "Idea" },
            { value: "other", label: "Other" },
          ]}
          // errors={errors}
          isClearable
        /> */}
      {/* <FormInput
        onChange={handleFormInputChange}
        name="replyToEmail"
        placeholder=""
        label="Email"
        value={replyToEmail}
        errors={errors}
        autoFocus
      /> */}
      <FormInput
        onChange={handleFormInputChange}
        name="subject"
        placeholder=""
        label="Subject"
        value={subject}
        errors={errors}
        autoFocus
      />
      <Styled.TemplateInput>
        <FormTextArea
          name="body"
          onChange={handleFormInputChange}
          placeholder=""
          label="Message"
          value={body || ""}
          errors={errors}
        />
      </Styled.TemplateInput>
    </FormContainer>
  </CenterModal>
);
}


export default SupportMessageModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: SupportMessageModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.TemplateInput = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SupportMessageModal_TemplateInput;
    > div {
      ${[t.mb(0)]}
    }
    textarea {
      min-height: 12rem;
    }
  `;
});

Styled.VariableButtons = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SupportMessageModal_VariableButtons;
    transition: box-shadow 0.2s ease;
    button {
      ${[t.h(6), t.px(2), t.text.xs, t.mr(1), t.mt(1)]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: SupportMessageModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

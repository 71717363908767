import {
  joinUp,
  lift,
  setBlockType,
  toggleMark,
  wrapIn,
  chainCommands,
} from "prosemirror-commands";
// import { redo, undo } from "prosemirror-history";
import { wrapInList, liftListItem } from "prosemirror-schema-list";

import schema from "./schema";
import icons from "./icons";

export const markActive = (type) => (state) => {
  const { from, $from, to, empty } = state.selection;

  return empty
    ? type.isInSet(state.storedMarks || $from.marks())
    : state.doc.rangeHasMark(from, to, type);
};

const blockActive = (type, attrs = {}) => (state) => {
  const { $from, to, node } = state.selection;

  // console.log("%c[ProseMirror][menu] blockActive", "color: #1976D2", {
  //   state,
  //   from: $from,
  //   end: $from.end(),
  //   to,
  //   node,
  //   type,
  //   attrs,
  //   selection: state.selection,
  //   // nodeHasMarkup: $from.hasMarkup(type, attrs),
  //   parent: $from.parent,
  //   nodeUpOne: $from.node(1),
  //   nodeUpOneHasMarkup: $from.node(1).hasMarkup(type, attrs),
  //   nodeUpTwo: $from.node(2),
  //   nodeUpThre: $from.node(3),
  //   parentHasMarkup: $from.parent.hasMarkup(type, attrs),
  // });

  if (node) {
    return node.hasMarkup(type, attrs);
  }

  return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
};

const isBlockquote = (type, attrs = {}) => (state) => {
  const { $from, to } = state.selection;

  return to <= $from.end() && $from.node(1).hasMarkup(type, attrs);
};

export const currentActiveTextLevel = (state) => {
  const blocks = [
    { type: schema.nodes.paragraph, attrs: null },
    { type: schema.nodes.heading, attrs: { level: 1 } },
    { type: schema.nodes.heading, attrs: { level: 2 } },
    { type: schema.nodes.heading, attrs: { level: 3 } },
    { type: schema.nodes.heading, attrs: { level: 4 } },
    { type: schema.nodes.heading, attrs: { level: 5 } },
    { type: schema.nodes.heading, attrs: { level: 6 } },
  ];

  return blocks.find((block) => {
    return blockActive(block.type, block.attrs)(state);
  });
};

const canInsert = (type) => (state) => {
  const { $from } = state.selection;

  for (let d = $from.depth; d >= 0; d--) {
    const index = $from.index(d);

    if ($from.node(d).canReplaceWith(index, index, type)) {
      return true;
    }
  }

  return false;
};

// const promptForURL = () => {
//   let url = window && window.prompt("Enter the URL", "https://");

//   if (url && !/^https?:\/\//i.test(url)) {
//     url = "http://" + url;
//   }

//   return url;
// };

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
const mod = isMac ? "⌘" : "Ctrl";

export default {
  marks: {
    strong: {
      title: "Toggle strong",
      content: icons.strong,
      active: markActive(schema.marks.strong),
      run: toggleMark(schema.marks.strong),
      keys: [mod, "B"],
    },
    em: {
      title: "Toggle emphasis",
      content: icons.em,
      active: markActive(schema.marks.em),
      run: toggleMark(schema.marks.em),
      keys: [mod, "I"],
    },
    // code: {
    //   title: "Toggle code",
    //   content: icons.code,
    //   active: markActive(schema.marks.code),
    //   run: toggleMark(schema.marks.code),
    // },
    // subscript: {
    //   title: "Toggle subscript",
    //   content: icons.subscript,
    //   active: markActive(schema.marks.subscript),
    //   run: toggleMark(schema.marks.subscript),
    // },
    // superscript: {
    //   title: "Toggle superscript",
    //   content: icons.superscript,
    //   active: markActive(schema.marks.superscript),
    //   run: toggleMark(schema.marks.superscript),
    // },
    underline: {
      title: "Toggle underline",
      content: icons.underline,
      active: markActive(schema.marks.underline),
      run: toggleMark(schema.marks.underline),
      keys: [mod, "U"],
    },
    // strikethrough: {
    //   title: "Toggle strikethrough",
    //   content: icons.strikethrough,
    //   active: markActive(schema.marks.strikethrough),
    //   run: toggleMark(schema.marks.strikethrough),
    // },

    /* This is not really used anymore since we created a custom Link Plugin
    --===================================================-- */
    link: {
      title: "Add or remove link",
      content: icons.link,
      active: markActive(schema.marks.link),
      enable: (state) => !state.selection.empty,
      run(state, dispatch) {
        // if (markActive(schema.marks.link)(state)) {
        //   toggleMark(schema.marks.link)(state, dispatch);
        //   return true;
        // }
        // const href = promptForURL();
        // if (!href) return false;
        // toggleMark(schema.marks.link, { href })(state, dispatch);
        // // view.focus()
      },
      keys: [mod, "K"],
    },
  },
  blocks: {
    paragraph: {
      title: "Change to paragraph",
      content: icons.paragraph,
      display: false,
      active: blockActive(schema.nodes.paragraph),
      enable: setBlockType(schema.nodes.paragraph),
      run: setBlockType(schema.nodes.paragraph),
    },
    h1: {
      title: "Change to heading level 1",
      content: icons.h1,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 1 }),
      enable: setBlockType(schema.nodes.heading, { level: 1 }),
      run: setBlockType(schema.nodes.heading, { level: 1 }),
    },
    h2: {
      title: "Change to heading level 2",
      content: icons.h2,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 2 }),
      enable: setBlockType(schema.nodes.heading, { level: 2 }),
      run: setBlockType(schema.nodes.heading, { level: 2 }),
    },
    h3: {
      title: "Change to heading level 3",
      content: icons.h3,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 3 }),
      enable: setBlockType(schema.nodes.heading, { level: 3 }),
      run: setBlockType(schema.nodes.heading, { level: 3 }),
    },
    h4: {
      title: "Change to heading level 4",
      content: icons.h4,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 4 }),
      enable: setBlockType(schema.nodes.heading, { level: 4 }),
      run: setBlockType(schema.nodes.heading, { level: 4 }),
    },
    h5: {
      title: "Change to heading level 5",
      content: icons.h5,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 5 }),
      enable: setBlockType(schema.nodes.heading, { level: 5 }),
      run: setBlockType(schema.nodes.heading, { level: 5 }),
    },
    h6: {
      title: "Change to heading level 6",
      content: icons.h6,
      display: false,
      active: blockActive(schema.nodes.heading, { level: 6 }),
      enable: setBlockType(schema.nodes.heading, { level: 6 }),
      run: setBlockType(schema.nodes.heading, { level: 6 }),
    },

    blockquote: {
      title: "Wrap in block quote",
      content: icons.blockquote,
      display: true,
      active: blockActive(schema.nodes.blockquote),
      enable: wrapIn(schema.nodes.blockquote),
      // run: wrapIn(schema.nodes.blockquote),
      run(state, dispatch) {
        console.log("%c[ProseMirror][menu] blockquote run", "background-color: #19FFD2", {
          state,
          isBlockquote: isBlockquote(schema.nodes.blockquote)(state),
        });
        if (!isBlockquote(schema.nodes.blockquote)(state)) {
          wrapIn(schema.nodes.blockquote)(state, dispatch);
          return true;
        }

        lift(state, dispatch);
      },
    },

    bullet_list: {
      title: "Wrap in bullet list",
      content: icons.bullet_list,
      display: true,
      active: blockActive(schema.nodes.bullet_list),
      enable: wrapInList(schema.nodes.bullet_list),
      // run: wrapInList(schema.nodes.bullet_list),
      run(state, dispatch) {
        if (wrapInList(schema.nodes.bullet_list)(state)) {
          wrapInList(schema.nodes.bullet_list)(state, dispatch);
          return true;
        }

        liftListItem(schema.nodes.list_item)(state, dispatch);
      },
    },
    ordered_list: {
      title: "Wrap in ordered list",
      content: icons.ordered_list,
      display: true,
      active: blockActive(schema.nodes.ordered_list),
      enable: wrapInList(schema.nodes.ordered_list),
      // run: wrapInList(schema.nodes.ordered_list),
      run(state, dispatch) {
        if (wrapInList(schema.nodes.ordered_list)(state)) {
          wrapInList(schema.nodes.ordered_list)(state, dispatch);
          return true;
        }

        liftListItem(schema.nodes.list_item)(state, dispatch);
      },
    },
    checkbox_list: {
      title: "Wrap in check list",
      content: icons.check_list,
      display: true,
      active: blockActive(schema.nodes.checkbox_list),
      enable: wrapInList(schema.nodes.checkbox_list),
      // run: wrapInList(schema.nodes.checkbox_list),
      run(state, dispatch) {
        if (wrapInList(schema.nodes.checkbox_list)(state)) {
          wrapInList(schema.nodes.checkbox_list)(state, dispatch);
          return true;
        }

        liftListItem(schema.nodes.list_item)(state, dispatch);
      },
    },
    // code_block: {
    //   title: "Change to code block",
    //   content: icons.code_block,
    //   active: blockActive(schema.nodes.code_block),
    //   enable: setBlockType(schema.nodes.code_block),
    //   run: setBlockType(schema.nodes.code_block),
    // },
    // lift: {
    //   title: "Lift out of enclosing block",
    //   content: icons.lift,
    //   enable: lift,
    //   run: lift,
    // },
    // join_up: {
    //   title: "Join with above block",
    //   content: icons.join_up,
    //   enable: joinUp,
    //   run: joinUp,
    // },
  },
  insert: {
    image: {
      title: "Insert image",
      content: icons.image,
      enable: canInsert(schema.nodes.image),
      run: (state, dispatch) => {
        // const src = promptForURL();
        // if (!src) return false;
        // const img = schema.nodes.image.createAndFill({ src });
        // dispatch(state.tr.replaceSelectionWith(img));
      },
    },
    iframe: {
      title: "Insert iframe",
      content: icons.iframe,
      enable: canInsert(schema.nodes.iframe),
      run: (state, dispatch) => {
        // const src = promptForURL();
        // if (!src) return false;
        // const img = schema.nodes.image.createAndFill({ src });
        // dispatch(state.tr.replaceSelectionWith(img));
      },
    },
    //   footnote: {
    //     title: "Insert footnote",
    //     content: icons.footnote,
    //     enable: canInsert(schema.nodes.footnote),
    //     run: (state, dispatch) => {
    //       const footnote = schema.nodes.footnote.create();
    //       dispatch(state.tr.replaceSelectionWith(footnote));
    //     },
    //   },
    //   // hr: {
    //   //   title: 'Insert horizontal rule',
    //   //   content: 'HR',
    //   //   enable: canInsert(schema.nodes.horizontal_rule),
    //   //   run: (state, dispatch) => {
    //   //     const hr = schema.nodes.horizontal_rule.create()
    //   //     dispatch(state.tr.replaceSelectionWith(hr))
    //   //   }
    //   // },
    //   //   table: {
    //   //     title: "Insert table",
    //   //     content: icons.table,
    //   //     enable: canInsert(schema.nodes.table),
    //   //     run: (state, dispatch) => {
    //   //       // const { from } = state.selection
    //   //       let rowCount = window && window.prompt("How many rows?", 2);
    //   //       let colCount = window && window.prompt("How many columns?", 2);
    //   //       const cells = [];
    //   //       while (colCount--) {
    //   //         cells.push(schema.nodes.table_cell.createAndFill());
    //   //       }
    //   //       const rows = [];
    //   //       while (rowCount--) {
    //   //         rows.push(schema.nodes.table_row.createAndFill(null, cells));
    //   //       }
    //   //       const table = schema.nodes.table.createAndFill(null, rows);
    //   //       dispatch(state.tr.replaceSelectionWith(table));
    //   //       // const tr = state.tr.replaceSelectionWith(table)
    //   //       // tr.setSelection(Selection.near(tr.doc.resolve(from)))
    //   //       // dispatch(tr.scrollIntoView())
    //   //       // view.focus()
    //   //     },
    //   //   },
  },
  // history: {
  //   undo: {
  //     title: "Undo last change",
  //     content: icons.undo,
  //     enable: undo,
  //     run: undo,
  //   },
  //   redo: {
  //     title: "Redo last undone change",
  //     content: icons.redo,
  //     enable: redo,
  //     run: redo,
  //   },
  // },
  // table: {
  //   addColumnBefore: {
  //     title: 'Insert column before',
  //     content: icons.after,
  //     active: addColumnBefore, // TOOD: active -> select
  //     run: addColumnBefore
  //   },
  //   addColumnAfter: {
  //     title: 'Insert column before',
  //     content: icons.before,
  //     active: addColumnAfter, // TOOD: active -> select
  //     run: addColumnAfter
  //   }
  // }
};

import { Schema } from "prosemirror-model";
import { orderedList, bulletList, listItem } from "prosemirror-schema-list";
import { marks, nodes } from "prosemirror-schema-basic";
import { tableNodes } from "prosemirror-tables";

const subscript = {
  excludes: "superscript",
  parseDOM: [{ tag: "sub" }, { style: "vertical-align=sub" }],
  toDOM: () => ["sub"],
};

const superscript = {
  excludes: "subscript",
  parseDOM: [{ tag: "sup" }, { style: "vertical-align=super" }],
  toDOM: () => ["sup"],
};

const strikethrough = {
  parseDOM: [
    { tag: "strike" },
    { style: "text-decoration=line-through" },
    { style: "text-decoration-line=line-through" },
  ],
  toDOM: () => [
    "span",
    {
      style: "text-decoration-line:line-through",
    },
  ],
};

const underline = {
  parseDOM: [{ tag: "u" }, { style: "text-decoration=underline" }],
  toDOM: () => [
    "span",
    {
      style: "text-decoration:underline",
    },
  ],
};

// export default {
//   ...marks,
//   subscript,
//   superscript,
//   strikethrough,
//   underline,
// };

/* CHECKBOXES
--===================================================-- */
const checkboxItem = {
  attrs: {
    done: { default: false },
  },
  content: "paragraph block*",
  toDOM(node) {
    const { done } = node.attrs;
    // console.log("%c[ProseMirror][schema] checkboxItem toDOM", "color: #1976D2", { node });
    return [
      "li",
      {
        "data-type": "checkbox_item",
        "data-done": done.toString(),
      },
      ["span", { class: "polymer-checkbox polymer-checkbox-unchecked", contenteditable: "false" }],
      ["span", { class: "polymer-checkbox polymer-checkbox-checked", contenteditable: "false" }],
      ["div", { class: "polymer-checkbox-content" }, 0],
    ];
  },
  parseDOM: [
    {
      priority: 51, // Needs higher priority than other nodes that use a "li" tag
      tag: '[data-type="checkbox_item"]',
      getAttrs(dom) {
        // console.log("%c[ProseMirror][schema] checkboxItem parseDOM", "color: #1976D2", { dom });
        return {
          done: dom.getAttribute("data-done") === "true",
        };
      },
    },
    // {
    //   priority: 51, // Needs higher priority than other nodes that use a "li" tag
    //   tag: '[data-type="checkbox_item"] > span',
    //   getAttrs(dom) {
    //     // console.log("%c[ProseMirror][schema] checkboxItem parseDOM", "color: #1976D2", { dom });
    //     return {
    //       // done: dom.getAttribute("data-done") === "true",
    //       class: dom.getAttribute("class"),
    //     };
    //   },
    // },
  ],
};

const checkboxList = {
  group: "block",
  content: "checkbox_item+ | list_item+",
  toDOM() {
    return [
      "ul",
      {
        "data-type": "checkbox_list",
      },
      0,
    ];
  },
  parseDOM: [
    {
      priority: 51, // Needs higher priority than other nodes that use a "ul" tag
      tag: '[data-type="checkbox_list"]',
    },
  ],
};

const listNodes = {
  ordered_list: {
    ...orderedList,
    content: "list_item+",
    group: "block",
  },
  bullet_list: {
    ...bulletList,
    content: "list_item+",
    group: "block",
  },
  list_item: {
    ...listItem,
    content: "paragraph block*",
    // content: "paragraph (ordered_list|bullet_list)?",
  },
  checkbox_item: checkboxItem,
  checkbox_list: checkboxList,
};

// export default {
//   ...nodes,
//   ...listNodes,
//   ...tableNodes({
//     tableGroup: "block",
//     cellContent: "block+",
//   }),
//   ...footnoteNodes,
// };

export default new Schema({
  nodes: {
    ...nodes,
    ...listNodes,
    // ...tableNodes({
    //   tableGroup: "block",
    //   cellContent: "block+",
    //   cellAttributes: null,
    // }),
    iframe: {
      inline: true,
      attrs: {
        src: {},
        frameborder: { default: null },
        allowfullscreen: { default: "" },
        tabindex: { default: 0 },
        // title: { default: null },
      },
      group: "inline",
      // draggable: true,
      parseDOM: [
        {
          tag: "iframe[src]",
          getAttrs(dom: any) {
            return {
              src: dom.getAttribute("src"),
              frameborder: dom.getAttribute("frameborder"),
              // style: dom.getAttribute("style"),
            };
          },
        },
      ],
      toDOM(node) {
        let { src, frameborder, allowfullscreen, tabindex } = node.attrs;
        // return ["iframe", { src, frameborder, allowfullscreen, tabindex }];
        return [
          "span",
          { class: "wrk-iframe-container" },
          ["iframe", { src, frameborder, allowfullscreen, tabindex }],
        ];
      },
    },
  },
  marks: {
    ...marks,
    subscript,
    superscript,
    strikethrough,
    underline,
  },
});

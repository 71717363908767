import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost, apiDelete } from "@shared/queryHooks/api";

const getFeatureFlippers = async () => {
  return await apiGet({ path: `/flipper/features` });
};

//Create a Feature
const createFeatureFlag = async ({ name }: { name: string }) => {
  return await apiPost({
    path: `/flipper/features`,
    variables: { name },
  });
};

//Enable a Feature
const enableFeature = async ({ featureFlag }) => {
  return await apiPost({
    path: `/flipper/features/${featureFlag}/boolean`,
    variables: {},
  });
};

//Disable a Feature
const disableFeature = async ({ featureFlag }) => {
  return await apiDelete({
    path: `/flipper/features/${featureFlag}/boolean`,
    variables: {},
  });
};

//Delete a feature flag (DANGER)
const deleteFeatureFlag = async ({ featureFlag }) => {
  return await apiDelete({
    path: `/flipper/features/${featureFlag}`,
    variables: {},
  });
};

/* Hooks
--===================================================-- */

function useFeatureFlippers(): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
} {
  return useQuery(["featureFlippers"], () => getFeatureFlippers());
}

function useCreateFeatureFlag() {
  const queryClient = useQueryClient();
  return useMutation(createFeatureFlag, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCreateFeatureFlag] useCreateFeatureFlag onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries("featureFlippers");
    },
  });
}

function useEnableFeature() {
  const queryClient = useQueryClient();
  return useMutation(enableFeature, {
    onSuccess: () => {
      queryClient.invalidateQueries("featureFlippers");
    },
  });
}

function useDisableFeature() {
  const queryClient = useQueryClient();
  return useMutation(disableFeature, {
    onSuccess: () => {
      queryClient.invalidateQueries("featureFlippers");
    },
  });
}

function useDeleteFeatureFlag() {
  const queryClient = useQueryClient();
  return useMutation(deleteFeatureFlag, {
    onSuccess: () => {
      queryClient.invalidateQueries("featureFlippers");
    },
    // throwOnError: true,
  });
}

export {
  useFeatureFlippers,
  useCreateFeatureFlag,
  useEnableFeature,
  useDisableFeature,
  useDeleteFeatureFlag,
};

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import useOnClickOutside from "use-onclickoutside";
import { useSpring, animated } from "react-spring";

import Icon from "@ats/src/components/shared/Icon";

import { HiringStageAutomation } from "@ats/types/job";

interface Props {
  stageAutomation: HiringStageAutomation | null;
  stageName: string;
  bulk: boolean;
}

export default function JobStageAutomationInfo(props: Props) {
  const { stageAutomation, stageName, bulk } = props;
  const [dropdownIsVisible, setDropdownIsVisible] = React.useState(false);
  const ref = React.useRef(null);

  window.logger("%c[JobStageAutomationInfo] RENDER", "color: #FF5F02", {
    props,
  });

  useOnClickOutside(ref, () => {
    handleClickOutside();
  });

  const handleClickOutside = () => {
    if (dropdownIsVisible) {
      setDropdownIsVisible(false);
    }
  };

  const toggleDropdownVisibility = (e) => {
    e.preventDefault();
    setDropdownIsVisible(!dropdownIsVisible);
  };

  const closeDropdown = (e) => {
    e.stopPropagation();
    handleClickOutside();
  };

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.95)",
      pointerEvents: "none",
    },
    to: {
      opacity: dropdownIsVisible ? 1 : 0,
      transform: dropdownIsVisible ? "scale(1)" : "scale(0.95)",
      pointerEvents: dropdownIsVisible ? "all" : "none",
    },
    config: {
      tension: 480,
      clamp: true,
    },
  });

  const iconName = stageAutomation ? "zap" : "zap-off";

  const infoMessage = stageAutomation ? (
    <Styled.DropdownText>
      <>
        Move to <span>{stageName}</span> triggers an automated email to
        {bulk ? " each " : " the "} candidate using the{" "}
        <span>"{stageAutomation.channelMessageTemplateName}"</span> template, sent
        <span>{stageAutomation.frequency === "once" ? "  once" : " every time"}</span>.
      </>
    </Styled.DropdownText>
  ) : (
    <Styled.DropdownText>
      Move to <span>{stageName}</span> triggers no automations.
    </Styled.DropdownText>
  );

  return (
    <Styled.Container ref={ref}>
      <div onClick={toggleDropdownVisibility}>
        <Styled.Details>
          <Icon name={iconName} />
          {stageAutomation ? (
            <span>1 automation will run</span>
          ) : (
            <span>No automations will run</span>
          )}
          <Icon name={dropdownIsVisible ? "chevron-up" : "chevron-down"} />
        </Styled.Details>
      </div>
      <Styled.Dropdown style={spring} onClick={closeDropdown}>
        {infoMessage}
      </Styled.Dropdown>
    </Styled.Container>
  );
}

/* Styled Components
======================================================= */

let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: JobStageAutomationInfo_Container;
  width: 100%;
  position: relative;
`;

Styled.Details = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobStageAutomationInfo_Details;
    ${[t.px(1), t.pt(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;

    &:hover {
      cursor: pointer;
    }

    span {
      font-weight: 400;
      font-size: 0.75rem;
      ${t.pr(1)}
    }

    svg {
      flex-shrink: 0;
      height: 1.125em;
      width: 1.125em;
      ${t.mr(1)};
    }
  `;
});

Styled.DropdownText = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobStageAutomationInfo_TooltipText;
    padding: 0.125rem;

    span {
      font-weight: bold;
    }
  `;
});

Styled.Dropdown = styled(animated.div)((props) => {
  const t: any = props.theme;
  const modeStyles = t.dark
    ? css`
        color: ${t.color.gray[200]};
        background-color: ${t.color.gray[700]};
        border: 1px solid ${t.color.gray[600]};
      `
    : css`
        color: ${t.color.black};
        background-color: ${t.color.white};
        border: 1px solid ${t.color.gray[400]};
      `;

  return css`
    label: JobStageAutomationInfo_Dropdown;
    ${[t.my(2), t.px(2), t.r(2), t.rounded.sm]}
    font-size: 0.75rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    top: 1.75rem;
    left: 0;
    overflow: hidden;
    z-index: 20;
    position: absolute;
    transform-origin: top left;
    width: 100%;
    align-self: center;
    ${modeStyles}
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  title: string;
  subcopy?: string;
  onConfirm: ({}) => void;
  onCancel: ({}) => void;
  confirmText: string;
  cancelText: string;
  isDestructiveConfirm: boolean;
};

function ConfirmationModal(props: Props) {
  const {
    title,
    subcopy,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    isDestructiveConfirm,
  } = props;

  const { dismissModalWithAnimation } = useModalContext();

  return (
    <CenterModal headerTitleText={title} onCancel={onCancel}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <Styled.ButtonContainer>
        <Button
          dangerous={isDestructiveConfirm}
          className="ConfirmationModal-accept"
          // onClick={onConfirm}
          onClick={() => dismissModalWithAnimation(() => onConfirm)}
          data-testid="modal-confirm-button"
        >
          {confirmText}
        </Button>
        <Button
          styleType="secondary"
          className="ConfirmationModal-cancel"
          onClick={() => dismissModalWithAnimation(() => onCancel)}
          // onClick={onCancel}
        >
          {cancelText}
        </Button>
      </Styled.ButtonContainer>
    </CenterModal>
  );
}

ConfirmationModal.deafultProps = {
  isDesctructiveConfirm: false,
};

export default ConfirmationModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ConfirmationModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ConfirmationModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

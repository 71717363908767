import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut, apiDelete } from "@shared/queryHooks/api";

const getChannelMessageTemplates = async () => {
  return await apiGet({ path: "/channel_message_templates" });
};

const createChannelMessageTemplate = async (variables) => {
  return await apiPost({ path: "/channel_message_templates", variables });
};

const updateChannelMessageTemplate = async (variables) => {
  return await apiPut({ path: `/channel_message_templates/${variables.id}`, variables });
};

const deleteChannelMessageTemplate = async (variables) => {
  return await apiDelete({ path: `/channel_message_templates/${variables.id}`, variables });
};

const getMailMerge = async ({ jobApplicationId, channelMessageTemplateId }) => {
  return await apiGet({
    path: `/job_applications/${jobApplicationId}/channel_message_templates_mail_merge/${channelMessageTemplateId}`,
  });
};

/* Hooks
--===================================================-- */
export function useChannelMessageTemplates(): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
} {
  return useQuery("channelMessageTemplates", getChannelMessageTemplates);
}

export function useCreateChannelMessageTemplate() {
  const queryClient = useQueryClient();
  return useMutation(createChannelMessageTemplate, {
    // After success or failure, refetch the channelMessageTemplates query
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

export function useUpdateChannelMessageTemplate() {
  const queryClient = useQueryClient();
  return useMutation(updateChannelMessageTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

export function useDeleteChannelMessageTemplate() {
  const queryClient = useQueryClient();
  return useMutation(deleteChannelMessageTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries("channelMessageTemplates");
    },
    // throwOnError: true,
  });
}

/* Mail Merged Template
--===================================================-- */
export function useMailMerge({
  jobApplicationId,
  channelMessageTemplateId,
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useQuery(
    ["mailmerge", { jobApplicationId, channelMessageTemplateId }],
    () => getMailMerge({ jobApplicationId, channelMessageTemplateId }),
    {
      enabled: channelMessageTemplateId != undefined,
    },
  );
}

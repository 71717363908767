import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut, apiDelete } from "@shared/queryHooks/api";
import { HiringStageAutomation } from "@ats/types/job";

const getHiringStageAutomations = async ({ jobId }: { jobId: number }) => {
  return await apiGet({ path: `/jobs/${jobId}/hiring_stage_message_automations` });
};

const getHiringStageAutomation = async ({ id, jobId }: { id: number; jobId: number }) => {
  return await apiGet({
    path: `/jobs/${jobId}/hiring_stage_message_automations/${id}`,
  });
};

const createHiringStageAutomation = async ({
  jobId,
  channelMessageTemplateId,
  hiringStageId,
  name,
  frequency,
  enabled = true,
}: HiringStageAutomation) => {
  return await apiPost({
    path: `/jobs/${jobId}/hiring_stage_message_automations`,
    variables: { channelMessageTemplateId, hiringStageId, name: "", frequency, enabled },
  });
};

const updateHiringStageAutomation = async ({
  id,
  jobId,
  ...hiringStageAutomation
}: HiringStageAutomation) => {
  return await apiPut({
    path: `/jobs/${jobId}/hiring_stage_message_automations/${id}`,
    variables: { hiringStageMessageAutomation: hiringStageAutomation },
  });
};

const deleteHiringStageAutomation = async ({ jobId, id }: { jobId: number; id: number }) => {
  return await apiDelete({
    path: `/jobs/${jobId}/hiring_stage_message_automations/${id}`,
    variables: null,
  });
};

/* Hooks
--===================================================-- */
export function useHiringStageAutomations({
  jobId,
}: {
  jobId: number;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery("hiringStageAutomations", () => getHiringStageAutomations({ jobId }));
}

export function useHiringStageAutomation({
  id,
  jobId,
}: {
  id: number;
  jobId: number;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["hiringStageAutomations", id], () => getHiringStageAutomation({ id, jobId }));
}

export function useCreateHiringStageAutomation() {
  const queryClient = useQueryClient();
  return useMutation(createHiringStageAutomation, {
    // After success or failure, refetch the hiringStageAutomations query
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringStageAutomation] useCreateHiringStageAutomation onSuccess",
        "color: #a05195",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("hiringStageAutomations");
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
  });
}

export function useUpdateHiringStageAutomation() {
  const queryClient = useQueryClient();
  return useMutation(updateHiringStageAutomation, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[useHiringStageAutomation] useUpdateHiringStageAutomation onSuccess",
        "color: #a05195",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("hiringStageAutomations");
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
  });
}

export function useDeleteHiringStageAutomation() {
  const queryClient = useQueryClient();
  return useMutation(deleteHiringStageAutomation, {
    onSuccess: (data) => {
      window.logger(
        "%c[useHiringStageAutomation] useDeleteHiringStageAutomation onSuccess",
        "color: #a05195",
        {
          data,
        },
      );
      queryClient.invalidateQueries("hiringStageAutomations");
      queryClient.invalidateQueries(["hiringStages", data.jobId]);
      queryClient.invalidateQueries(["jobs", data.jobId]);
    },
  });
}

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useBasicReport } from "@shared/queryHooks/useAnalytics";
import FormSelect from "@ats/src/components/forms/FormSelect";
import Tooltip from "@ats/src/components/shared/Tooltip";
import JobMetricsSourceChart from "./components/JobMetricsSourceChart";
import JobMetricsStageFunnelChart from "./components/JobMetricsStageFunnelChart";
import JobMetricsTimeInStageChart from "./components/JobMetricsTimeInStageChart";
import JobMetricsTimeToHire from "./components/JobMetricsTimeToHire";
import JobMetricsApplicationsByDate from "./components/JobMetricsApplicationsByDate";
import JobMetricsStageTarget from "./components/JobMetricsStageTargetPercents";
import EmptyState from "@ats/src/components/shared/EmptyState";

function JobMetricsContainer(props) {
  const queryStringParams = new URLSearchParams(props.location.search);
  const { job } = props;
  const [startDate, setStartDate] = useState(queryStringParams.get("startDate") || "");
  const [endDate, setEndDate] = useState(queryStringParams.get("endDate") || "");
  const [source, setSource] = useState(queryStringParams.get("source") || "");
  const { data: basicReport, refetch: refetchBasicReport } = useBasicReport({
    jobId: job.id,
    startDate,
    endDate,
    source,
  });

  console.log(job);

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleSourceChange = (name, value) => {
    setSource(value);
  };

  const onApplyFilters = () => {
    updateUrl();
    refetchBasicReport();
  };

  const updateUrl = () => {
    const newQueryStringParams = new URLSearchParams();
    if (startDate) {
      newQueryStringParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryStringParams.set("endDate", endDate);
    }
    if (source) {
      newQueryStringParams.set("source", source);
    }
    props.history.push(`/jobs/${job.id}/metrics?${newQueryStringParams.toString()}`);
  };

  if (!basicReport) {
    return null;
  }

  const {
    totalApplications,
    sourceList,
    activityData: { sourceData, stageData, timeToHire, applicationsAddedByDate },
  } = basicReport;

  const sourceOptions = [
    { label: "All", value: "" },
    ...sourceList.filter((source) => !!source).map((source) => ({ label: source, value: source })),
    { label: "No source", value: "none" },
  ];

  return (
    <Styled.Container>
      <Helmet title="Job metrics" />

      <Styled.Page>

        <Styled.TitleWrapper>
          <h1>Metrics</h1>
          <Tooltip label="This feature is curently in beta">
            <Styled.Label key="draft">βeta</Styled.Label>
          </Tooltip>
        </Styled.TitleWrapper>
        <p>
          This page shows hiring metrics for this job. These metrics are gathered from candidate and hiring team activity over the lifetime of the hiring effort.
        </p>

        <Styled.ChartRow>
          <div>
            <Styled.Chart>
              <h2>Hiring stage traffic</h2>
              <JobMetricsStageFunnelChart stageData={stageData} totalApplications={totalApplications} />
            </Styled.Chart>
            <Styled.Chart>
              <h2>Hiring stage results</h2>
              <JobMetricsStageTarget stageData={basicReport.activityData.stageData} />
            </Styled.Chart>
          </div>
          <div>
            <Styled.Chart>
              <h2>Applicants by source</h2>
              {job.jobApplicationsCount === 0 ? (
                <Styled.Empty>No candidates have applied to this job yet</Styled.Empty>
              ) : (
                <JobMetricsSourceChart sourceData={sourceData} />
              )}
            </Styled.Chart>
          </div>
        </Styled.ChartRow>
        {/* 
              <Styled.Filters>
                <Styled.Field>
                  <label htmlFor="start">Start date:</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={onChangeStartDate}
                  />
                </Styled.Field>
                <Styled.Field>
                  <label htmlFor="end">End date:</label>
                  <input type="date" id="endDate" name="endDate" value={endDate} onChange={onChangeEndDate} />
                </Styled.Field>
                <FormSelect
                  onChange={handleSourceChange}
                  name="sourceFilter"
                  label="Source"
                  value={source}
                  options={sourceOptions}
                />
                <button onClick={onApplyFilters}>Apply filter</button>  
              </Styled.Filters> 

              <Styled.Chart>
                <h2>Time in stage</h2>
                <JobMetricsTimeInStageChart stageData={stageData} />
              </Styled.Chart>
              <Styled.Chart>
                <h2>Time to hire</h2>
                <JobMetricsTimeToHire timeToHireData={timeToHire} />
              </Styled.Chart>
              <Styled.Chart>
                <h2>Applications by date</h2>
                <JobMetricsApplicationsByDate applicationsAddedByDate={applicationsAddedByDate} />
              </Styled.Chart>
            */}
      </Styled.Page>
    </Styled.Container>
  );
}

JobMetricsContainer.propTypes = {};

JobMetricsContainer.defaultProps = {};

export default JobMetricsContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    width: 100vw;
    height: 100%;
    overflow-y: auto;

    ${t.mq["lg"]} {
      width: auto;
    }
  `;
});

Styled.Filters = styled.div((props) => {
  const t: any = props.theme;
  return css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  `;
});

Styled.Field = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mb(5)]};
    display: flex;
    flex-direction: column;
  `;
});

Styled.Page = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.pt(4), t.px(4), t.pb(0)]};
    flex: 1;
    > p {
      ${[t.py(1), t.mb(6)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 40rem;
      line-height: 1.6;
    }
  `;
});

Styled.TitleWrapper = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_TitleWrapper;
    ${[t.mb(1)]}
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    h1 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Label;
    ${[t.mx(3), t.h(6), t.px(2), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
  `;
});

Styled.Empty = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.text.xs, t.mb(4)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    text-align: center;
  `;
});

Styled.ChartRow = styled.div((props) => {
  const t: any = props.theme;
  return css`

    ${t.mq['lg']} {
      display: flex;
      > div {
        width: 50%;
        &:first-of-type {
          ${t.mr(4)}
        }
      }
    }
  `;
});

Styled.Chart = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.p(4), t.mb(4), t.rounded.md]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : 'transparent'};
    position: relative;

    h2 {
      ${[t.text.base, t.text.bold, t.mb(5)]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }

    .recharts-text {
      ${[t.text.xs]}
    }

    .recharts-legend-item {
      ${[t.mr(0), t.ml(2)]}
      position: relative;
      right: -10px;
    }

    .recharts-legend-item-text {
      ${[t.text.xs]}
    }
  `;
});
import React from "react"; // eslint-disable-line
import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { useTheme } from "@emotion/react";
import { useTransition, animated } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import Button from "@ats/src/components/shared/Button";
import ModalBackdrop from "@ats/src/components/modals/ModalBackdrop";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  headerTitleText?: string;
  onCancel: (event?: any) => void;
  onCancelWithBackgroundClick?: (event?: any) => void;
  children: any;
  isVisible?: boolean;
  hasUnsavedChanges?: boolean;
};

export default function FullModal(props: Props) {
  // const [isVisible, setIsVisible] = React.useState(true);
  const {
    children,
    onCancel,
    headerTitleText,
    onCancelWithBackgroundClick,
    hasUnsavedChanges,
  } = props;
  const { animationCompleteCallback, isVisible } = useModalContext();

  // const theme = useTheme();

  const checkUnsavedChanges = React.useCallback(() => {
    if (!hasUnsavedChanges) {
      onCancel();
    }
  }, [hasUnsavedChanges])

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "esc",
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      checkUnsavedChanges();
      return false;
    },
    {},
    [hasUnsavedChanges],
  );

  const handleBackgroundClick = (event) => {
    event.stopPropagation();
    // window.logger("%c[FullModal] handleBackgroundClick", "color: #1976D2", { hasUnsavedChanges });
    checkUnsavedChanges();

    // if (onCancelWithBackgroundClick != undefined) {
    //   onCancelWithBackgroundClick(event);
    // } else {
    //   onCancel();
    // }
  };

  const handleAnimationDone = () => {
    window.logger("%c[CenterModal] handleAnimationDone", "background-color: #1976D2; color: red", {
      animationCompleteCallback,
    });
    if (animationCompleteCallback != undefined) {
      animationCompleteCallback();
    }
  };

  const transitions = useTransition(isVisible, {
    from: {
      opacity: 0,
      right: -56,
    },
    enter: {
      opacity: 1,
      right: 0,
    },
    leave: {
      opacity: 0,
      right: -56,
    },
    config: {
      tension: 480,
      clamp: true,
    },
    onDestroyed: handleAnimationDone,
  });

  return (
    <Styled.Container>
      <ModalBackdrop whenClicked={handleBackgroundClick} isVisible={isVisible} />

      {transitions(
        (props, item) =>
          item && (
            <Styled.Content style={props}>
              {headerTitleText && (
                <Styled.Header>
                  <h2>{headerTitleText}</h2>
                  <Button styleType="secondary" onClick={onCancel}>
                    Dismiss
                  </Button>
                </Styled.Header>
              )}
              {children}
            </Styled.Content>
          ),
      )}
    </Styled.Container>
  );
}

FullModal.defaultProps = {
  isVisible: true,
  hasUnsavedChanges: false,
};

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: FullModal;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  justify-content: flex-end;
  align-items: center;
`;

Styled.Content = styled(animated.div)((props) => {
  const t: any = props.theme;
  return css`
    label: FullModal_Content;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    height: 100%;
    z-index: 20;
    overflow-y: auto;
    border-top-left-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem;
    border-bottom-right-radius: 0.4375rem;
    position: absolute;
    width: 100%;

    ${t.mq["lg"]} {
      width: 50%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OriginalEmailModal_Header;
    ${[t.px(4), t.pt(4), t.pb(3)]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Styled: any = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    background-color: ${t.dark ? t.color.gray[900] : t.color.white};

    ${t.mq["lg"]} {
      ${t.py(0)};
    }
  `;
});

Styled.UI = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session_UI;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    ${t.mq["lg"]} {
      ${t.my(20)}
    align-items: center;
    }
  `;
});

Styled.Form = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session_Form;
    ${[t.px(4), t.py(6)]};
    width: 100%;
    border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    h2 {
      ${[t.text.h2, t.mb(6), t.mt("-px")]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }

    ${t.mq["lg"]} {
      ${[t.p(6), t.rounded.md]}
      border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      width: 25rem;
    }
  `;
});

Styled.Directions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session_Directions;
    ${t.mt(-2)};
    p {
      ${[t.mt(4), t.mb(2)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      a {
        ${[t.text.medium]};
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `;
});

Styled.Links = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: Session_Links;
    ${[t.text.sm, t.mt(2), t.px(4)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    width: 100%;
    display: flex;
    justify-content: space-between;
    button,
    a {
      ${[t.py(2), t.px(0), t.text.normal]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      background: none;
      border: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    ${t.mq["lg"]} {
      ${[t.text.xs, t.mt(1), t.px(0)]}
      width: 25rem;
      button,
      a {
        ${t.px(1)}
      }
    }
  `;
});

Styled.RecaptchaContainer = styled.div`
  label: AuthForm_RecaptchaContainer;

  .grecaptcha-badge {
    visibility: hidden;
  }
`;

Styled.Recaptcha = styled.p((props: any) => {
  const t: any = props.theme;
  return css`
    label: Auth_RecaptchaStatement;
    ${[t.text.xs, t.mt(8), t.px(4)]}
    position: fixed;
    bottom: 16px;
    left: 0;
    right: 0;
    text-align: center;
    color: ${t.color.gray[600]};
    a:hover {
      color: ${t.color.black};
    }
  `;
});

export default Styled;

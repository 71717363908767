import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPost, apiPut, apiGet, apiDelete } from "@shared/queryHooks/api";

const getComments = async ({ jobApplicationId }) => {
  if (jobApplicationId) {
    return await apiGet({ path: `/job_applications/${jobApplicationId}/comments` });
  }
};

const getComment = async ({ jobApplicationId, commentId }) => {
  if (jobApplicationId && commentId) {
    return await apiGet({ path: `/job_applications/${jobApplicationId}/comments/${commentId}` });
  }
};

const getReviewDraft = async ({ jobApplicationId }) => {
  if (jobApplicationId) {
    return await apiGet({ path: `/job_applications/${jobApplicationId}/comments/new_review` });
  }
};

// const getPrivateNote = async ({ jobApplicationId }) => {
//   if (jobApplicationId) {
//     return await apiGet({ path: `/job_applications/${jobApplicationId}/comments/private_note` });
//   }
// };

const createComment = async ({
  jobApplicationId,
  body,
  kind,
  rating,
}: {
  jobApplicationId: number;
  body: string;
  kind?: string;
  rating?: string;
}) => {
  if (jobApplicationId != undefined) {
    return await apiPost({
      path: `/job_applications/${jobApplicationId}/comments`,
      variables: { body, kind, status: "published" },
    });
  }
};

const updateComment = async ({
  jobApplicationId,
  id: commentId,
  body,
  rating,
  status,
}: {
  id: number;
  jobApplicationId: number;
  body: string;
  kind?: string;
  rating?: string;
  status?: string;
}) => {
  if (jobApplicationId != undefined && commentId != undefined) {
    return await apiPut({
      path: `/job_applications/${jobApplicationId}/comments/${commentId}`,
      variables: { body, rating, status },
    });
  }
};

const deleteComment = async ({
  jobApplicationId,
  id: commentId,
  body,
  rating,
  status
}: {
  jobApplicationId: number;
  id: number;
  body: string;
  kind?: string;
  rating?: string;
  status?: string;
}) => {
  if (jobApplicationId != undefined && commentId != undefined) {
    return await apiDelete({
      path: `/job_applications/${jobApplicationId}/comments/${commentId}`,
      variables: { body, rating, status }
    });
  }
};

/* HOOKS
--===================================================-- */
export function useComments({
  jobApplicationId,
}: {
  jobApplicationId?: number | string;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["comments", jobApplicationId], () => getComments({ jobApplicationId }));
}

export function useComment({
  jobApplicationId,
  commentId,
  enabled = false,
}: {
  jobApplicationId?: number | string;
  commentId?: number | string;
  enabled: boolean;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["comment", commentId], () => getComment({ jobApplicationId, commentId }), {
    enabled,
  });
}

export function useReviewDraft({
  jobApplicationId,
  enabled = false,
}: {
  jobApplicationId?: number | string;
  enabled: boolean;
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["reviewDrafts", jobApplicationId], () => getReviewDraft({ jobApplicationId }), {
    enabled,
  });
}

// export function usePrivateNote({
//   jobApplicationId,
//   enabled = false,
// }: {
//   jobApplicationId?: number | string;
//   enabled: boolean;
// }): {
//   status: any;
//   data: any;
//   error: any;
//   isLoading: boolean;
// } {
//   return useQuery(["privateNotes", jobApplicationId], () => getPrivateNote({ jobApplicationId }), {
//     enabled,
//   });
// }

export function useCreateComment() {
  const queryClient = useQueryClient();
  return useMutation(createComment, {
    // After success or failure, refetch the jobCategories query
    onSuccess: (
      data,
      mutationVariables: {
        jobApplicationId: number | string;
        body: string;
        rating?: string;
        kind?: string;
      },
    ) => {
      // window.logger("[useComment] useCreateComment", {
      //   data,
      //   mutationVariables,
      //   queryClient,
      // });
      queryClient.invalidateQueries(["comments", mutationVariables.jobApplicationId]);
      queryClient.invalidateQueries("comment");
    },
    // throwOnError: true,
  });
}

export function useUpdateComment() {
  const queryClient = useQueryClient();
  return useMutation(updateComment, {
    // After success or failure, refetch the jobCategories query
    onSuccess: (
      data,
      mutationVariables: {
        jobApplicationId: number | string;
        id: number | string;
        body: string;
        rating?: string;
        kind?: string;
        status?: string;
      },
    ) => {
      window.logger("[useComment] useUpdateComment", {
        data,
        mutationVariables,
        queryClient,
      });

      queryClient.invalidateQueries(["comments", mutationVariables.jobApplicationId]);
      queryClient.invalidateQueries("comment");
      queryClient.invalidateQueries("reviewDrafts");
    },
    // throwOnError: true,
  });
}

export function useDeleteComment() {
  const queryClient = useQueryClient();
  return useMutation(deleteComment, {
    onSettled: (
      mutationVariables: {
        jobApplicationId: number | string;
        id: number | string;
        body: string;
        rating?: string;
        kind?: string;
        status?: string;
      },
    ) => {
      window.logger("[useComment] useDeleteComment", {
        mutationVariables,
        queryClient,
      });

      queryClient.invalidateQueries(["comments", mutationVariables.jobApplicationId]);
      queryClient.invalidateQueries("comment");
      queryClient.invalidateQueries("reviewDrafts");
    },
    // throwOnError: true,
  });
}

// export function useUpdatePrivateNote() {
//   const queryClient = useQueryClient();
//   return useMutation(updateComment, {
//     // After success or failure, refetch the jobCategories query
//     onSuccess: (
//       data,
//       mutationVariables: {
//         jobApplicationId: number | string;
//         id: number | string;
//         body: string;
//       },
//     ) => {
//       window.logger("[useComment] useUpdatePrivateNote", {
//         data,
//         mutationVariables,
//         queryClient,
//       });

//       // queryClient.invalidateQueries(["comments", mutationVariables.jobApplicationId]);
//       queryClient.invalidateQueries("privateNotes");
//     },
//     // throwOnError: true,
//   });
// }

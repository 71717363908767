import React from "react";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";
import { findSelectOptionByValue } from "@ats/src/lib/lookups";

import Select, { Creatable } from "react-select";
import FormLabel from "@ats/src/components/forms/FormLabel";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

const handleChange = (props) => (selectedOption, actionObj) => {
  // Use the parent's scope to setState
  if (props.scope !== undefined) {
    if (actionObj.action === "clear") {
      props.scope.setState({ [props.name]: null, errors: null });
    } else {
      props.scope.setState({
        [props.name]: isArray(selectedOption) ? selectedOption : selectedOption.value,
        errors: null,
      });
    }
  } else if (props.onChange !== undefined) {
    if (actionObj.action === "clear") {
      props.onChange(props.name, null);
    } else {
      props.onChange(props.name, isArray(selectedOption) ? selectedOption : selectedOption.value);
    }
  } else {
    console.error("[FormSelect] scope or onChange must be defined");
  }
};

const handleInputChange = (props) => (selectedOption, actionObj) => {
  if (actionObj.action === "clear") {
    props.onInputChange(props.name, null);
  } else {
    props.onInputChange(
      props.name,
      isArray(selectedOption) ? selectedOption : selectedOption.value,
    );
  }
};

const FormSelect = (props) => {
  const {
    errors,
    options,
    name,
    placeholder,
    value,
    label,
    description,
    onChange,
    isRequired,
    isDisabled,
    isClearable,
    isCreatable,
    isMulti,
    requiredLabel,
    ...rest
  } = props;
  let errorText = errorTextViaYupOrRailsOrJoi(props);

  const selectedOption = findSelectOptionByValue(options, value);
  const [selectRef, setSelectRef] = React.useState(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "Escape") {
      selectRef.blur();
    }
  };

  // window.logger("%c[FormSelect] errorText", "color: #1976D2", {
  //   errorText,
  //   errors,
  //   selectedOption,
  //   rest,
  // });

  return isCreatable ? (
    <Styled.Container>
      {label && <FormLabel label={label} isRequired={isRequired} error={errorText} />}
      <Styled.Creatable
        hasError={errorText}
        isMulti={isMulti}
        classNamePrefix="form-select-ui"
        name={name}
        value={selectedOption || ""}
        onChange={handleChange(props)}
        onInputChange={handleInputChange(props)}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={isClearable}
        {...rest}
        ref={(ref) => {
          if (ref != undefined) {
            setSelectRef(ref);
          }
        }}
      />
      {props.children}
      {/* For displaying Joi rendered errors. See NewInviteForm example. */}
    </Styled.Container>
  ) : (
    <Styled.Container data-testid="form-select-dropdown">
      {label && (
        <FormLabel
          label={label}
          isRequired={isRequired}
          requiredLabel={requiredLabel}
          error={errorText}
        />
      )}
      {description && <p>{description}</p>}
      <Styled.Select
        hasError={errorText}
        // hasError={errorText || hasJoiErrors}
        isMulti={isMulti}
        classNamePrefix="form-select-ui"
        name={name}
        value={selectedOption || ""}
        onChange={handleChange(props)}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={isClearable}
        {...rest}
        ref={(ref) => {
          if (ref != undefined) {
            setSelectRef(ref);
          }
        }}
        onKeyDown={handleKeyDown}
        // data-testid="doesthiswork"
      />
      {props.children}
      {/* For displaying Joi rendered errors. See NewInviteForm example. */}
    </Styled.Container>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.any,
  scope: PropTypes.object, // typically set to "this" from the parent so we can use setState
  onChange: PropTypes.func, //.isRequired, // This get's used as a callback if scope is not set
  className: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.any.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requiredLabel: PropTypes.string,
};

FormSelect.defaultProps = {
  isRequired: false,
  isDisabled: false,
  isClearable: false,
  isMulti: false,
  placeholder: "Select...",
  requiredLabel: "required",
};

export default FormSelect;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Container = styled.div((props) => {
  const t = props.theme;
  return css`
    label: FormSelect;
    ${t.mb(5)}
    > p {
      ${[t.text.xs, t.mt(-1), t.mb(2)]}
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      max-width: 32rem;

      a {
        ${[t.text.medium]}
        color: ${t.dark ? t.color.gray[200] : t.color.black};

        &:hover {
          text-decoration: underline;
        }
      }

      ${t.mq["md"]} {
        ${t.text.xs}
      }
    }

    ${t.mq["md"]} {
      ${t.mb(5)}
    }
  `;
});

Styled.Select = styled(Select)((props) => {
  const t = props.theme;
  return css`
    label: FormSelect_UI;
    .form-select-ui {
      &__clear-indicator {
        cursor: pointer;
      }
      &__control {
        ${[t.h(10), t.rounded.sm, t.text.base]}
        min-height: ${t.spacing[10]};
        width: 100%;
        appearance: none;
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
        border-width: 1px;
        border-style: solid;
        border-color: ${props.hasError
          ? t.dark
            ? t.color.red[400]
            : t.color.red[500]
          : t.dark
          ? "transparent"
          : t.color.gray[300]};
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        &:hover {
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[600]
            : t.color.gray[400]};
        }
        &:focus {
          outline: none;
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[500]
            : t.color.gray[400]};
          ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &:focus:hover,
        &--is-focused,
        &--is-focused:hover {
          outline: none;
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[500]
            : t.color.gray[400]};
          ${t.dark ? "box-shadow: none;" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &--is-disabled {
          background-color: ${t.dark ? "transparent" : t.color.gray[100]};
          .form-select-ui__single-value {
            color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
          }
          ${t.dark && `border: 1px solid ${t.color.gray[800]};`}

          .form-select-ui__indicator svg {
            fill: ${t.color.gray[500]};
            stroke: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
          }
        }

        ${t.mq["md"]} {
          ${[t.h(8), t.text.sm]}
          min-height: ${t.spacing[8]};
        }
      }
      &__option {
        ${[t.h(10), t.px(2), t.text.base]}
        transition: color 0.2s ease, background-color 0.2s ease;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[700] : t.color.white};
        color: ${t.dark ? t.color.gray[200] : t.color.gray[600]};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &--is-focused {
          background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &--is-selected {
          background-color: ${t.dark ? t.color.gray[600] : t.color.gray[100]};
          color: ${t.dark ? t.color.white : t.color.black};
        }

        ${t.mq["md"]} {
          ${[t.h(8), t.text.sm]}
        }
      }
      &__placeholder {
        ${[t.mx(0)]}
        color: ${t.color.gray[500]};
      }
      &__indicators {
        ${t.h(8)}
        align-items: center;
        height: 100%;
        span {
          display: none;
        }
      }
      &__indicator svg {
        height: 1.125rem;
        width: 1.125rem;
        stroke-width: 1px;
        fill: ${t.dark ? t.color.gray[300] : t.color.black};
        stroke: ${t.dark ? t.color.gray[800] : t.color.white};
      }
      &__dropdown-indicator {
        ${t.mr("px")}
        padding: 0.3125rem;
      }
      &__clear-indicator svg {
        ${[t.rounded.xs]}
        position: relative;
        right: -${t.spacing[2]};
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
      }
      &__clear-indicator:hover svg {
        background-color: ${t.dark ? t.color.orange[200] : t.color.gray[300]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.orange[200] : t.color.gray[300]};
        fill: ${t.color.black};
      }
      &__menu {
        ${[t.my("-px"), t.rounded.sm]};
        border: 1px solid ${t.dark ? t.color.gray[500] : t.color.gray[400]};
        box-shadow: none;
        z-index: 20;
      }
      &__menu-list {
        ${[t.rounded.xs]};
        padding: 0;
      }
      &__value-container {
        ${[t.mx(0)]};
        height: 100%;
        > div {
          ${t.m(0)}
        }
      }
      &__input,
      &__single-value {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        ${t.m(0)};
      }
      &__multi-value {
        ${[t.m("px"), t.rounded.xs]};
        margin-right: 0.25rem !important;
        height: 1.375rem;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
        transition: background-color 0.2s ease;
        &__label {
          ${[t.text.sm, t.py("px"), t.pr(0), t.pl(2)]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &__remove {
          height: 1.375rem;
          color: ${t.dark ? t.color.gray[400] : t.color.gray[500]};
          &:hover {
            background: none;
            color: ${t.dark ? t.color.white : t.color.black};
            cursor: pointer;
          }
        }
      }
      &__menu-notice,
      &__menu-notice--no-options {
        color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
        background-color: ${t.dark ? t.color.gray[800] : t.color.white};
      }
    }
  `;
});

Styled.Creatable = styled(Creatable)((props) => {
  const t = props.theme;
  return css`
    label: FormSelect_UI;
    .form-select-ui {
      &__clear-indicator {
        cursor: pointer;
      }
      &__control {
        ${[t.h(10), t.rounded.sm, t.text.base]}
        min-height: ${t.spacing[10]};
        width: 100%;
        appearance: none;
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        background-color: ${t.dark ? "rgba(255,255,255,0.07)" : t.color.white};
        border-width: 1px;
        border-style: solid;
        border-color: ${props.hasError
          ? t.dark
            ? t.color.red[400]
            : t.color.red[500]
          : t.dark
          ? "transparent"
          : t.color.gray[300]};
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        &:hover {
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[600]
            : t.color.gray[400]};
        }
        &:focus {
          outline: none;
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[500]
            : t.color.gray[400]};
          ${t.dark ? "" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &:focus:hover,
        &--is-focused,
        &--is-focused:hover {
          outline: none;
          border-color: ${props.hasError
            ? t.dark
              ? t.color.red[300]
              : t.color.red[600]
            : t.dark
            ? t.color.gray[500]
            : t.color.gray[400]};
          ${t.dark ? "box-shadow: none;" : `box-shadow: 0 0 0 2px ${t.color.gray[300]};`}
        }
        &--is-disabled {
          background-color: ${t.dark ? "transparent" : t.color.gray[100]};
          .form-select-ui__single-value {
            color: ${t.dark ? t.color.gray[500] : t.color.gray[600]};
          }
          ${t.dark && `border: 1px solid ${t.color.gray[800]};`}

          .form-select-ui__indicator svg {
            fill: ${t.color.gray[500]};
            stroke: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
          }
        }

        ${t.mq["md"]} {
          ${[t.h(8), t.text.sm]}
          min-height: ${t.spacing[8]};
        }
      }
      &__option {
        ${[t.h(10), t.px(2), t.text.base]}
        transition: color 0.2s ease, background-color 0.2s ease;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[700] : t.color.white};
        color: ${t.dark ? t.color.gray[200] : t.color.gray[600]};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &--is-focused {
          background-color: ${t.dark ? t.color.gray[500] : t.color.gray[200]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &--is-selected {
          background-color: ${t.dark ? t.color.gray[600] : t.color.gray[100]};
          color: ${t.dark ? t.color.white : t.color.black};
        }

        ${t.mq["md"]} {
          ${[t.h(8), t.text.sm]}
        }
      }
      &__placeholder {
        ${[t.mx(0)]}
        color: ${t.color.gray[500]};
      }
      &__indicators {
        ${t.h(8)}
        align-items: center;
        height: 100%;
        span {
          display: none;
        }
      }
      &__indicator svg {
        height: 1.125rem;
        width: 1.125rem;
        stroke-width: 1px;
        fill: ${t.dark ? t.color.gray[300] : t.color.black};
        stroke: ${t.dark ? t.color.gray[800] : t.color.white};
      }
      &__dropdown-indicator {
        ${t.mr("px")}
        padding: 0.3125rem;
      }
      &__clear-indicator svg {
        ${[t.rounded.xs]}
        position: relative;
        right: -${t.spacing[2]};
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
      }
      &__clear-indicator:hover svg {
        background-color: ${t.dark ? t.color.orange[200] : t.color.gray[300]} !important;
        transition: background-color 0.2s ease;
        stroke: ${t.dark ? t.color.orange[200] : t.color.gray[300]};
        fill: ${t.color.black};
      }
      &__menu {
        ${[t.my("-px"), t.rounded.sm]};
        border: 1px solid ${t.dark ? t.color.gray[500] : t.color.gray[400]};
        box-shadow: none;
        z-index: 20;
      }
      &__menu-list {
        ${[t.rounded.xs]};
        padding: 0;
      }
      &__value-container {
        ${[t.mx(0)]};
        height: 100%;
        > div {
          ${t.m(0)}
        }
      }
      &__input,
      &__single-value {
        color: ${t.dark ? t.color.gray[200] : t.color.black};
        ${t.m(0)};
      }
      &__multi-value {
        ${[t.m("px"), t.rounded.xs]};
        margin-right: 0.25rem !important;
        height: 1.375rem;
        display: flex;
        align-items: center;
        background-color: ${t.dark ? t.color.gray[600] : t.color.gray[200]};
        transition: background-color 0.2s ease;
        &__label {
          ${[t.text.sm, t.py("px"), t.pr(0), t.pl(2)]};
          color: ${t.dark ? t.color.white : t.color.black};
        }
        &__remove {
          height: 1.375rem;
          color: ${t.dark ? t.color.gray[400] : t.color.gray[500]};
          &:hover {
            background: none;
            color: ${t.dark ? t.color.white : t.color.black};
            cursor: pointer;
          }
        }
      }
      &__menu-notice,
      &__menu-notice--no-options {
        color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
        background-color: ${t.dark ? t.color.gray[800] : t.color.white};
      }
    }
  `;
});

import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";
import JobListItemMenu from "@ats/src/views/jobs/components/JobListItemMenu";
import SubscriptionRequiredModal from "@ats/src/components/modals/SubscriptionRequiredModal";
import SubscriptionRequiredModalNew from "@ats/src/components/modals/SubscriptionRequiredModalNew";
import { prettyDate } from "@shared/lib/time";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";

import { useCreateSubscription } from "@shared/queryHooks/useBilling";
import { usePublishJob } from "@shared/queryHooks/useJob";
import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";

function JobListItem(props) {
  const { job, currentOrganization, currentUser, location } = props;
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();

  const planBasedBillingEnabled = useFeatureFlipper()({ feature: Features.PLAN_BASED_BILLING });

  const {
    mutate: createSubscription,
    isLoading: isLoadingCreateSubscription,
  } = useCreateSubscription();
  const { mutate: publishJob, isLoading: isLoadingPublishJob } = usePublishJob();

  // window.logger("%c[JobListItem] render", "color: #19d219", {
  //   ...props,
  // });

  if (!currentOrganization) {
    return null;
  }

  const handleClickOnPublishJob = () => {
    if (currentOrganization.stripeSubscriptionInGoodStanding) {
      handlePublishJob();
    } else if (!planBasedBillingEnabled && currentOrganization.stripeDefaultPaymentMethodOnFile) {
      handleCreateSubscriptionAndPublishJob();
    } else {
      const modal = planBasedBillingEnabled ? (
        <SubscriptionRequiredModalNew
          onCancel={() => removeModal()}
          isAdmin={currentUser.currentOrganizationUser.isAdmin}
          jobId={job.id}
          location={location}
        />
      ) : (
        <SubscriptionRequiredModal
          onCancel={() => removeModal()}
          isAdmin={currentUser.currentOrganizationUser.isAdmin}
          jobId={job.id}
          location={location}
        />
      );

      openModal(modal);
    }
  };

  const handleCreateSubscriptionAndPublishJob = () => {
    createSubscription(
      {},
      {
        onSuccess: (data) => {
          window.logger("%c[JobListItem] createSubscription SUCCESS", "color: #19d219", {
            data,
          });
          addToast({
            title: "Started subscription",
            kind: "success",
          });

          handlePublishJob();
        },
        onError: (response) => {
          window.logger("%c[JobListItem] createSubscription FAILURE", "color: #990f6b", {
            response,
          });
          addToast({
            title: "Unable to create subscription",
            kind: "warning",
          });
        },
      },
    );
  };

  const handlePublishJob = () => {
    window.logger("%c[JobListItem] handlePublishJob", "color: #197000", {
      job,
      currentOrganization,
    });

    const jobData = { id: job.id, status: "status_published" };
    publishJob(jobData, {
      onSuccess: (data) => {
        window.logger("%c[JobListItem] handlePublishJob onSuccess", "background-color: #29c282", {
          data,
          // redirectUrl,
        });
        addToast({
          title: `Published ${data.title}`,
          kind: "success",
          delay: 10000,
          externalLink: {
            href: data.jobPostUrl,
            text: "View post",
          },
        });
      },
      onError: (response) => {
        window.logger("%c[JobListItem] handlePublishJob onError", "color: #FF5002", {
          response,
          // redirectUrl,
        });
        addToast({
          title:
            "There was an unknown error when trying to publish please contact support@polymer.co",
          kind: "warning",
        });
      },
    });
  };

  const navigateToJob = (e) => {
    e.preventDefault();
    if (job.status === "draft") {
      props.history.push({
        pathname: `/jobs/${job.id}/setup`,
        state: { orgAdminJobsListUrl: location.pathname }, //informs JobContainer of orgAdminJobsListUrl's value
      });
    } else {
      props.history.push({
        pathname: `/jobs/${job.id}/stages`,
        state: { orgAdminJobsListUrl: location.pathname }, //informs JobContainer of orgAdminJobsListUrl's value
      });
    }
  };

  // window.logger("%c[JobListItem] render", "color: #FFF000", { props: props });
  let labelsNode = [];

  const unarchivedCount = job && job.unarchivedCount ? job.unarchivedCount : 0;

  const notVisibleLabel = <Styled.Label key="not-visible">Currently hidden</Styled.Label>;

  const draftLabel = <Styled.Label key="draft">Not yet published</Styled.Label>;

  const inReviewLabel = <Styled.Label key="in-review">In review</Styled.Label>;

  const archivedDateLabel = (
    <Styled.Label key="status_archived">
      Archived on {prettyDate(job.archivedAtTimestamp)}
    </Styled.Label>
  );

  if (!job.visible && job.status !== "status_archived") {
    labelsNode.push(notVisibleLabel);
  }

  if (job.status === "draft") {
    labelsNode.push(draftLabel);
  }

  if (job.status === "in_review") {
    labelsNode.push(inReviewLabel);
  }

  if (job.status === "status_archived") {
    labelsNode.push(archivedDateLabel);
  }

  const detailsNode = (
    <Styled.JobDetails>
      {labelsNode}
      {job.jobCategoryName && <Styled.JobDetailNode>{job.jobCategoryName}</Styled.JobDetailNode>}
      <Styled.JobDetailNode>
        {job.kindPretty ? job.kindPretty : "Employment type not specified"}
      </Styled.JobDetailNode>
      {job.isAllowedRemote && job.displayLocation === "" ? null : (
        <Styled.JobDetailNode>
          {job.displayLocation || "Location not specified"}
        </Styled.JobDetailNode>
      )}
      {job.isAllowedRemote && <Styled.JobDetailNode>{job.remotenessPretty}</Styled.JobDetailNode>}
    </Styled.JobDetails>
  );

  const applicantCounts = (
    <Styled.ApplicantCounts>
      <ul>
        <li>
          <div>
            <Icon name="inbox" />
            <span>Inbox</span>
          </div>
          <div>{job.inboxCount}</div>
        </li>
        <li>
          <div>
            <Icon name="user" />
            <span>Total candidates</span>
          </div>
          <div>{unarchivedCount}</div>
        </li>
      </ul>
    </Styled.ApplicantCounts>
  );

  return (
    <Styled.Container onClick={navigateToJob} data-testid={job.title}>
      <Styled.JobInfo>
        <Styled.JobTitle data-filter-by="text">
          <h2>{job.title}</h2>
        </Styled.JobTitle>
        {detailsNode}
      </Styled.JobInfo>

      <Styled.JobControls>
        <Styled.Buttons>
          <Tooltip label="Job setup">
            <Styled.Link
              to={{
                pathname: `/jobs/${job.id}/setup/details`,
                state: { orgAdminJobsListUrl: location.pathname }, //informs JobContainer of orgAdminJobsListUrl's value
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Icon name="settings" />
            </Styled.Link>
          </Tooltip>
          <JobListItemMenu {...props} publishJob={handleClickOnPublishJob} />
        </Styled.Buttons>
        {applicantCounts}
      </Styled.JobControls>
    </Styled.Container>
  );
}

export default JobListItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem;
    ${[t.mb(4), t.rounded.md]}
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    transition: border-color 0.2s ease;
    &:hover {
      border-color: ${t.dark ? t.color.gray[600] : t.color.gray[400]};
    }

    ${t.mq["lg"]} {
      ${t.mb(5)}
      flex-direction: row;
    }
  `;
});

Styled.JobInfo = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_JobInfo;
    ${[t.px(4), t.pt(4), t.pb(2), t.mr(20)]}

    ${t.mq["lg"]} {
      ${[t.px(5), t.mr(0)]}
      width: 70%;
    }
  `;
});

Styled.JobTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_JobTitle;
    display: flex;
    align-items: center;
    h2 {
      ${[t.text.h3]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;
});

Styled.JobDetails = styled.div((props) => {
  const t: any = props.theme;
  return css`
    ${[t.mt(2), t.text.xs]}
    label: JobListItem_JobDetails;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `;
});

Styled.JobDetailNode = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_JobDetailNode;
    ${[t.mt("-px"), t.mr(2), t.px(2), t.mb(2), t.rounded.xs]}
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    line-height: 1.4;
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    min-height: 1.25rem;

    &:last-of-type {
      ${t.mr(0)}
    }
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_Label;
    ${[t.mt("-px"), t.mr(2), t.px(2), t.mb(2), t.rounded.xs]}
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    line-height: 1.4;
    label: JobListItem_JobDetailNode;
    display: flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[600] : t.color.orange[200]};
    background: ${t.dark
      ? t.color.gray[600]
      : // "linear-gradient(120deg, #D6D7E5 0%, #F3E2CB 100%)"
        "linear-gradient(120deg, #FFD8D8 -40%, #FFDEC1 80%)"};
    color: ${t.dark ? t.color.white : t.color.black};
  `;
});

Styled.JobControls = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_JobControls;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;

    ${t.mq["lg"]} {
      display: flex;
      width: 30%;
    }
  `;
});

Styled.Buttons = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_Buttons;
    ${t.p(2)}
    margin-top: 0.125rem;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${t.mq["lg"]} {
      position: static;
    }
  `;
});

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    ${[t.h(8), t.w(8), t.rounded.sm]}
    label: JobListItem_Link;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;

    &:visited,
    &:link {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }

    &:hover {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    }
  `;
});

Styled.ApplicantCounts = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobListItem_ApplicantCounts;
    ${[t.pl(4), t.pr(5)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    align-self: stretch;
    flex-direction: column;
    border-top: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};

    ${t.mq["lg"]} {
      ${[t.w(64), t.pl(5)]}
      display: flex;
      border-top: none;
      border-left: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    }

    svg {
      flex-shrink: 0;
    }

    ul {
      ${t.my(2)}
      display: flex;
      flex: 1;
      flex-direction: column;

      ${t.mq["lg"]} {
        ${t.mb(0)}
        padding-top: 0.125rem;
      }
    }
    li {
      ${t.h(8)}
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div {
      display: flex;
      span {
        ${[t.ml(2), t.mr(4)]}
        white-space: nowrap;
      }
    }
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Seperator;
    ${t.mx(1)}
  `;
});

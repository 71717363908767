import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  onConfirm: ({}) => void;
  onCancel: ({}) => void;
};

function HideJobModal(props: Props) {
  const { onConfirm, onCancel } = props;

  const { dismissModalWithAnimation } = useModalContext();

  const title = "Are you sure you want to hide this job?";

  return (
    <CenterModal headerTitleText={title} onCancel={onCancel}>
      <Styled.Instructions>
        Hidden jobs are not displayed on your public job board, but remain accessible to hiring team
        members for ongoing candidate management.
        <span> Hidden jobs continue to incur billing charges.</span>
      </Styled.Instructions>
      <Styled.Instructions>
        If you are finished with this job, you may want to archive it instead.
        <span> Once archived, it will no longer incur billing charges.</span>
      </Styled.Instructions>
      <Styled.ButtonContainer>
        <Button
          className="HideJobModal-accept"
          onClick={() => dismissModalWithAnimation(() => onConfirm)}
          data-testid="hide-job-confirm-button"
        >
          Hide job post
        </Button>
        <Button
          styleType="secondary"
          className="HideJobModal-cancel"
          onClick={() => dismissModalWithAnimation(() => onCancel)}
        >
          Cancel
        </Button>
      </Styled.ButtonContainer>
    </CenterModal>
  );
}

export default HideJobModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: HideJobModal_Instructions;
    ${[t.mb(5)]}

    span {
      ${[t.text.bold]}
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: HideJobModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

import React from "react";
import { Helmet } from "react-helmet";
import Joi from "joi-browser";

import { removeHttpPrefix } from "@shared/lib/utils";

import { organizationRemotenessOptions } from "@ats/src/lib/lookups";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import Button from "@ats/src/components/shared/Button";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";

function AccountOrganizationDetails(props) {
  const addToast = useToastContext();
  const { currentOrganization } = useCurrentSession();
  const [state, setState] = React.useState(currentOrganization);
  const [errors, setErrors] = React.useState(null);
  window.logger("%c[AccountOrganizationDetails] render state", "color: #1976D2", {
    currentOrganization,
    state,
  });

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  React.useEffect(() => {
    if (currentOrganization != undefined) {
      setState(currentOrganization);
    }
  }, [currentOrganization]);

  if (currentOrganization == undefined || state == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const { name, websiteUrl, remoteness } = state;

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const schema = Joi.object().keys({
      name: Joi.string().min(2).required(),
      websiteUrl: Joi.string(),
      // /\A[a-z0-9_-.]+\z/i
    });

    Joi.validate({ name }, schema, (err) => {
      let errors;
      if (err !== null) {
        errors = {};

        err.details.forEach((index) => {
          let key = index.context.key;
          let message = index.message.replace(`"${key}" `, "");
          errors[key] = [message];
        });

        window.logger("%c[OrganizationForm] handleSubmit errors", "color: #1976D2", err.details);
      } else {
        updateOrganization(
          { id: currentOrganization.id, name, websiteUrl, remoteness },
          {
            onSuccess: () => {
              props.setIsDirty(false);
              addToast({ title: `Successfully updated organization`, kind: "success" });
              setErrors(null);
            },
            onError: (response: any) => {
              addToast({
                title: `Failed to update organization`,
                kind: "danger",
              });
              setErrors(response.data.errors);
            },
          },
        );
      }

      setErrors(errors);
    });
  };

  const onFormInputChange = (name, value) => {
    // window.logger("%c[AccountOrganizationDetails] onFormInputChange", "color: #1976D2", { name, value });
    props.setIsDirty(true);
    setState({ ...state, [name]: value });
  };

  const includeNoRemoteInRemotenessOptions = () => {
    return [
      ...organizationRemotenessOptions,
      {
        value: "remoteness_none",
        label: "No remote",
      },
    ];
  };

  const expandedOrganizationRemotenessOptions = includeNoRemoteInRemotenessOptions();

  const bottomBarContent = (
    <Button
      className="submit-button"
      size="small"
      styleType="primary"
      onClick={onClickSubmit}
      loading={isLoadingUpdateOrganization}
    >
      Save changes
    </Button>
  );

  return (
    <>
      <Helmet title="Organization" />
      <SettingsContainer
        title="Organization management"
        description="Here's where you can edit and update your organization's information."
        bottomBar={bottomBarContent}
      >
        <form onSubmit={handleSubmit}>
          <FormSection title="Basic information">
            <FormInput
              name="name"
              onChange={onFormInputChange}
              placeholder="Organization name"
              label="Organization name"
              value={name}
              errors={errors}
            />

            <FormInput
              name="websiteUrl"
              onChange={onFormInputChange}
              placeholder="..."
              label="Organization website URL"
              description="If you supply a website URL, it will appear on your job board as a link."
              pretext="https://"
              value={removeHttpPrefix(websiteUrl) || ""}
              errors={errors}
            />

            <FormSelect
              onChange={onFormInputChange}
              name="remoteness"
              label="How remote is your organization?"
              description=""
              value={remoteness}
              options={expandedOrganizationRemotenessOptions}
              errors={errors}
            // isClearable
            />
          </FormSection>
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountOrganizationDetails;

import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost, apiPut } from "@shared/queryHooks/api";
import queryString from "query-string";

const getOrganizations = async (filters) => {
  let queryParams;
  if (filters) {
    queryParams = `?${queryString.stringify(filters)}`;
  }
  return await apiGet({ path: `/admin/organizations${queryParams}` });
};

const getOrganization = async (organization) => {
  if (organization && organization.id != undefined) {
    return await apiGet({ path: `/admin/organizations/${organization.id}` });
  }
};

const searchOrganizations = async (organizationName) => {
  return await apiPost({
    path: `/admin/organizations/search`,
    variables: { searchTerm: organizationName },
  });
};

const updateOrganization = async ({ id, ...organization }) => {
  window.logger(
    "%c[ADMIN][useOrganization] updateOrganization\n\n\n\n",
    "background-color: #FF76D2",
    {
      organization,
    },
  );
  return await apiPut({
    path: `/admin/organizations/${id}`,
    variables: { organization },
  });
};

const transferOrganizationOwnership = async ({ id, organizationUserId }) => {
  window.logger(
    "%c[ADMIN][useOrganization] transferOrganizationOwnership\n\n\n\n",
    "background-color: #FF76D2",
    {
      id,
      organizationUserId,
    },
  );
  return await apiPut({
    path: `/admin/organizations/${id}/transfer_ownership`,
    variables: { organizationUserId },
  });
};

const takeoverOrganization = async ({ id, email }) => {
  window.logger(
    "%c[ADMIN][useOrganization] takeoverOrganization\n\n\n\n",
    "background-color: #FF76D2",
    {
      id,
      email,
    },
  );
  return await apiPut({
    path: `/admin/organizations/${id}/takeover_organization`,
    variables: { email },
  });
};

/* Hooks
--===================================================-- */
function useOrganizations({
  filters = { order_by: "created_at_desc", is_claimed: true, customers: true },
}: {
  filters: { order_by: string; is_claimed?: boolean; customers?: boolean };
}): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isSuccess: boolean;
} {
  return useQuery(["organizations", filters], () => getOrganizations(filters));
}

function useOrganization(
  organization,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useQuery(["organization", organization], () => getOrganization(organization));
}

function useSearchOrganizations(): {
  mutate: any;
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isSuccess: boolean;
} {
  return useMutation(searchOrganizations);
}

function useUpdateOrganization() {
  const queryClient = useQueryClient();
  return useMutation(updateOrganization, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[ADMIN][useOrganization] useUpdateOrganization onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("organizations");
      queryClient.invalidateQueries("organization");
    },
    // throwOnError: true,
  });
}

function useTransferOrganizationOwnership() {
  const queryClient = useQueryClient();
  return useMutation(transferOrganizationOwnership, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[ADMIN][useOrganization] useTransferOrganizationOwnership onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("organizations");
      queryClient.invalidateQueries("organization");
    },
    // throwOnError: true,
  });
}

function useTakeoverOrganization() {
  const queryClient = useQueryClient();
  return useMutation(takeoverOrganization, {
    onSuccess: (data, mutationVariables) => {
      window.logger(
        "%c[ADMIN][useOrganization] useTakeoverOrganization onSuccess",
        "color: #1976D2",
        {
          data,
          mutationVariables,
        },
      );
      queryClient.invalidateQueries("organizations"); // Invalidate cache for organizations
      queryClient.invalidateQueries("organization"); // Invalidate cache for specific organization
    },
    onError: (error) => {
      console.error("Failed to takeover organization:", error);
    },
  });
}

export {
  useOrganizations,
  useOrganization,
  useSearchOrganizations,
  useUpdateOrganization,
  useTransferOrganizationOwnership,
  useTakeoverOrganization
};

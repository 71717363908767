import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPut, apiGet, apiDelete } from "@shared/queryHooks/api";

/* API
--===================================================-- */
const updateOrganizationUser = async ({ id, ...variables }) => {
  return await apiPut({ path: `/organization_users/${id}`, variables });
};

const deactivateOrganizationUser = async ({ id, ...variables }) => {
  return await apiDelete({ path: `/organization_users/${id}`, variables });
};

/* Hooks
--===================================================-- */

export function useUpdateOrganizationUser() {
  const queryClient = useQueryClient();
  return useMutation(updateOrganizationUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", data.userId]);
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

export function useDeactivateOrganizationUser() {
  const queryClient = useQueryClient();
  return useMutation(deactivateOrganizationUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", data.userId]);
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("me");
    },
    // throwOnError: true,
  });
}

import React from "react";
import Icon from "@ats/src/components/shared/Icon";

export default {
  em: <Icon name="italic" />,
  italic: <Icon name="italic" />,
  strong: <Icon name="bold" />,
  bold: <Icon name="bold" />,
  // code: <FontAwesomeIcon icon={faCode} />,
  // subscript: <FontAwesomeIcon icon={faSubscript} />,
  // superscript: <FontAwesomeIcon icon={faSuperscript} />,
  // strikethrough: <FontAwesomeIcon icon={faStrikethrough} />,
  underline: <Icon name="underline" />,
  link: <Icon name="link" />,
  paragraph: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 9.5H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 4.5H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 14.5H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 19.5H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  h1: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 3.5V12M5.625 20.5V12M5.625 12H18.375M18.375 12V3.5M18.375 12V20.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  ),
  h2: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.75 5V12M6.75 19V12M6.75 12H17.25M17.25 12V5M17.25 12V19"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  ),
  blockquote: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.25 5.25C9.25 5.25 4.25 5.25 4.25 12.25"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M19.25 5.25C19.25 5.25 14.25 5.25 14.25 12.25"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <rect x="4.25" y="12.25" width="5.5" height="6.5" rx="0.5" stroke="currentColor" />
      <rect x="14.25" y="12.25" width="5.5" height="6.5" rx="0.5" stroke="currentColor" />
    </svg>
  ),
  // code_block: <FontAwesomeIcon icon={faCode} />,
  ordered_list: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 6H21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12H21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 18H21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.02557 2.90912H2.75284L1.5 3.67474V4.84804L2.61364 4.18185H2.64347V8.00003H4.02557V2.90912Z"
        fill="currentColor"
      />
      <path
        d="M1.09944 14.2605H5.00711V13.1568H3.01847V13.127L3.49574 12.7292C4.65661 11.7623 4.95242 11.2601 4.95242 10.671C4.95242 9.72142 4.17437 9.09998 2.95384 9.09998C1.76812 9.09998 0.99752 9.75871 1.00001 10.8301H2.31251C2.31002 10.4025 2.57103 10.1639 2.95384 10.1639C3.33417 10.1639 3.60512 10.4 3.60512 10.7903C3.60512 11.1508 3.37394 11.3844 2.99859 11.6951L1.09944 13.2662V14.2605Z"
        fill="currentColor"
      />
      <path
        d="M3.06818 20.9301C4.31357 20.9301 5.19105 20.2962 5.18608 19.3989C5.19105 18.7749 4.77344 18.335 3.95313 18.2455V18.2057C4.55966 18.1411 5.00213 17.7508 4.99716 17.1815C5.00213 16.3239 4.20668 15.7 3.07813 15.7C1.92472 15.7 1.1044 16.3587 1.09943 17.2909H2.42188C2.42685 16.9827 2.6978 16.7639 3.07813 16.7639C3.42614 16.7639 3.66974 16.9752 3.66477 17.271C3.66974 17.5793 3.38139 17.798 2.96875 17.798H2.4517V18.7526H2.96875C3.41619 18.7526 3.7294 18.9713 3.72443 19.2796C3.7294 19.5953 3.46094 19.8165 3.07813 19.8165C2.6804 19.8165 2.39702 19.6052 2.39205 19.3094H1C1.00497 20.259 1.86257 20.9301 3.06818 20.9301Z"
        fill="currentColor"
      />
    </svg>
  ),
  bullet_list: <Icon name="list" />,
  check_list: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6H22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 12H22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 18H22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6 4L3.25 6.75L2 5.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.5L3.25 13.25L2 12"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17L3.25 19.75L2 18.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  image: (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M19.3291 3.29248H5.3291C4.22453 3.29248 3.3291 4.18791 3.3291 5.29248V19.2925C3.3291 20.3971 4.22453 21.2925 5.3291 21.2925H19.3291C20.4337 21.2925 21.3291 20.3971 21.3291 19.2925V5.29248C21.3291 4.18791 20.4337 3.29248 19.3291 3.29248Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.8291 10.2925C9.65753 10.2925 10.3291 9.62091 10.3291 8.79248C10.3291 7.96405 9.65753 7.29248 8.8291 7.29248C8.00067 7.29248 7.3291 7.96405 7.3291 8.79248C7.3291 9.62091 8.00067 10.2925 8.8291 10.2925Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3291 15.2925L16.3291 10.2925L5.3291 21.2925"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  iframe: (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M19.7917 3.125H5.20833C4.05774 3.125 3.125 4.05774 3.125 5.20833V19.7917C3.125 20.9423 4.05774 21.875 5.20833 21.875H19.7917C20.9423 21.875 21.875 20.9423 21.875 19.7917V5.20833C21.875 4.05774 20.9423 3.125 19.7917 3.125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.583 15.625L17.708 12.5L14.583 9.375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.417 9.375L7.29199 12.5L10.417 15.625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  // table: <FontAwesomeIcon icon={faTable} />,
  // footnote: <FontAwesomeIcon icon={faAsterisk} />,
  // undo: <FontAwesomeIcon icon={faUndo} />,
  // redo: <FontAwesomeIcon icon={faRedo} />,
  // lift: <FontAwesomeIcon icon={faOutdent} />,
  // join_up: <FontAwesomeIcon icon={faAngleUp} />,
};

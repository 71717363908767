import React, { useState } from "react";
import find from "lodash/find";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import {
  jobKindOptions,
  countryOptions,
  unitedStatesOptions,
  remoteRestrictionOverlapHoursOptions,
  yesOrNoOptions,
  jobRemotenessOptions,
  salaryUnitOptions,
  salaryCurrencyCodeOptions,
} from "@ats/src/lib/lookups";

import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSelectCity from "@ats/src/components/forms/FormSelectCity";
import FormSelectGoogleLocation from "@ats/src/components/forms/FormSelectGoogleLocation";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";
import Button from "@ats/src/components/shared/Button";
import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import JobSocialShareImageUploader from "@ats/src/views/jobApplications/jobSetup/components/JobSocialShareImageUploader";

import { useToastContext } from "@shared/context/ToastContext";
import { removeHttpPrefix } from "@shared/lib/utils";
import useJobCategories from "@shared/queryHooks/useJobCategories";
import { useUpdateJob } from "@shared/queryHooks/useJob";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";

import { validateSalaryAmounts } from '@shared/lib/validateWithYup';

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";

function JobSettingsDetails(props) {
  // useWhyDidYouUpdate("JobSetupDetails", props);
  const { setIsDirty, job: passedJob } = props;
  const { id: jobId, ...restJob } = passedJob;
  const [job, setJob] = useState({
    ...restJob,
    salaryUnit: restJob.salaryUnit || "YEAR",
    salaryCurrency: restJob.salaryCurrency || "USD",
  });
  const [salaryType, setSalaryType] = useState("");
  const [errors, setErrors] = useState(null);
  const { data } = useJobCategories();
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const jobCategories = data ? data.items : [];
  const addToast = useToastContext();

  const [jobCategory, setJobCategory] = useState(job.jobCategoryName);

  const {
    title,
    country,
    stateRegion,
    city,
    mapLocation,
    kind,
    remoteness,
    videoUrl,
    remoteRestrictionOverlapHoursIsRequired,
    remoteRestrictionCity,
    remoteRestrictionCountryResidencyIsRequired,
    remoteRestrictionCountryList,
    remoteRestrictionOverlapHours,
    salaryMin,
    salaryMax,
    salaryUnit,
    salaryCurrency,
    externalListingsEnabled,
  } = job;

  const countrySelectOptions = countryOptions();
  const regionSelectOptions = unitedStatesOptions();
  const showRegionSelect = country === "US";

  const jobCategorySelectOptions = jobCategories.map((cat) => {
    return { value: cat.name, label: cat.name };
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    job.salaryMin && job.salaryMax ? "Range" : "Fixed";
    if (restJob.salaryMin && restJob.salaryMax) {
      setSalaryType("Range");
    } else if (restJob.salaryMin) {
      setSalaryType("Fixed");
    }
  }, []);

  const onFormInputChange = (name, value) => {
    window.logger("%c[JobSetupDetails] onFormInputChange", "background-color: #5eb65b", {
      name,
      value,
    });

    setIsDirty(true);
    if (errors != undefined) {
      setErrors(null);
    }

    let val = value;

    if (name === "videoUrl") {
      val = removeHttpPrefix(val);
    }

    setJob({ ...job, [name]: val });
  };

  const handleCountryChange = (name, value) => {
    setIsDirty(true);
    setJob({
      ...job,
      [name]: value,
      stateRegion: null,
    });
  };

  const handleLocationChange = (location) => {
    window.logger("%c[JobSetupDetails] handleLocationChange", "background-color: #5eb65b", {
      place: location.place,
    });
    setIsDirty(true);
    setJob({
      ...job,
      mapLocation: location.place.description,
    });
  };

  // const urlEncodedLocation = () => {
  //   return location?.place ? encodeURI(location.place.description) : null;
  // };

  // const parsePlaceTermsToLocationData = (place) => {
  //   const { terms } = place;
  //   const values = terms.map((term) => term.value).reverse();
  //   const lastThreeValues = values.slice(0, 2).reverse();
  //   const cityStateCountry =
  //     lastThreeValues.length > 1 ? lastThreeValues.join(", ") : lastThreeValues[0];
  //   const mapLocation = place.description
  //     .replace(cityStateCountry, "")
  //     .trim() // remove leading or trailing white space
  //     .replace(/(^,)|(,$)/g, ""); // remove leading or trailing commas (mostly just trailing)

  //   /*
  // West Avenue, Kingston, Jamaica = [route, geocode]

  // --===================================================-- */

  //   return {
  //     country: values[0],
  //     stateRegion: values[1],
  //     city: values[2],
  //     mapLocation,
  //   };
  // };

  const handleCityChange = (remoteRestrictionCityOption) => {
    setIsDirty(true);
    setJob({
      ...job,
      remoteRestrictionCityOption,
      remoteRestrictionCity: remoteRestrictionCityOption.value,
    });
  };

  const handleCountriesChange = (name, value) => {
    setIsDirty(true);
    setJob({ ...job, [name]: value.map((country) => country.value) });
  };

  const handleCategoriesChange = (name, value) => {
    setIsDirty(true);
    setJobCategory(value);
    window.logger("%c[JobSetupDetails] handleCategoriesChange", "color: #F976D2", {
      name,
      value,
    });
  };

  const handleCategoriesInputChange = (name, value) => {
    window.logger("%c[JobSetupDetails] handleCategoriesInputChange", "color: #1976D2", {
      name,
      value,
    });
  };

  const handleSalaryDropdownChange = (name, value) => {
    setIsDirty(true);
    if (errors != undefined) {
      setErrors(null);
    }
    setJob({ ...job, [name]: value });
  };

  const handleSalaryTypeChange = (name, value) => {
    setIsDirty(true);
    if (errors != undefined) {
      setErrors(null);
    }
    setSalaryType(value);
    if (!value) {
      setJob({ ...job, salaryMax: null, salaryMin: null });
    } else if (value === "Fixed") {
      setJob({ ...job, salaryMax: null });
    }
  };

  /* Social Share Image
  --===================================================-- */
  // const handleSocialShareImageErrors = (errors) => {
  //   window.logger("%c[JobSetupDetails] errors", "color: #1976D2", { errors });
  //   setErrors(errors);
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Submit the form here
    console.warn("[JobSettingsDetails] onSubmit { job }", { job });

    const {
      title,
      category,
      kind,
      country,
      stateRegion,
      city,
      mapLocation,
      remoteness,
      videoUrl,
      remoteRestrictionOverlapHoursIsRequired,
      remoteRestrictionCityOption,
      remoteRestrictionCity,
      remoteRestrictionCityGooglePlaceId,
      remoteRestrictionCountryResidencyIsRequired,
      remoteRestrictionCountryList,
      remoteRestrictionOverlapHours,
      externalListingsEnabled,
    } = job;

    const selectedCategory = find(jobCategories, (cat) => {
      return cat.name === jobCategory;
    });
    const selectedCategoryId = selectedCategory ? selectedCategory.id : null;

    window.logger("%c[JSD] onSubmit", "color: #1976D2", { selectedCategory });
    const [isValid, validationErrors] = await validateSalaryAmounts({
      salaryMin: salaryMin,
      salaryMax: salaryMax,
      salaryType: salaryType
    });

    if (!isValid) {
      setErrors(validationErrors);
    } else { 
      updateJob(
        {
          id: jobId,
          title,
          category,
          jobCategoryId: selectedCategoryId,
          kind,
          country,
          stateRegion,
          city,
          mapLocation,
          remoteness,
          videoUrl,
          remoteRestrictionOverlapHoursIsRequired,
          remoteRestrictionOverlapHours,
          remoteRestrictionCity: remoteRestrictionCityOption
            ? remoteRestrictionCityOption.value
            : remoteRestrictionCity
              ? remoteRestrictionCity
              : null,
          remoteRestrictionCityGooglePlaceId: remoteRestrictionCityOption
            ? remoteRestrictionCityOption.place.place_id
            : remoteRestrictionCityGooglePlaceId
              ? remoteRestrictionCityGooglePlaceId
              : null,
          remoteRestrictionCountryResidencyIsRequired,
          remoteRestrictionCountryList,
          salaryMin,
          salaryMax,
          salaryUnit,
          salaryCurrency,
          externalListingsEnabled,
        },
        {
          onSuccess: () => {
            setIsDirty(false);
            setErrors(null);
            addToast({
              title: `Job updated`,
              kind: "success",
            });
          },
          onError: (response: any) => {
            window.logger("%c[JobSetupDetails] updateJob onError", "color: #F97602", { response });
            setErrors(response.data.errors);
            addToast({
              title: `Could not update job`,
              kind: "warning",
            });
          },
        },
      );
    }
  };

  const SidebarContent = (
    <Styled.Sidebar>
      <h3>Creating a job post</h3>
      <p>
        You can preview your post as you build it using the link in the left column. This will
        create a temporary url you can visit to make sure your post looks exactly the way you want
        it to.
      </p>
    </Styled.Sidebar>
  );

  const BottomBarContent = (
    <Button
      className="submit-button"
      type="button"
      size="small"
      onClick={onSubmit}
      loading={isLoadingUpdateJob}
      aria-label="Save changes"
    >
      Save changes
    </Button>
  );

  const jobCategoriesDescription = (
    <span>
      You can add, remove, and edit job categories in{" "}
      <Link to={{ pathname: `/hire/settings/categories`, state: { orginalPathname: location.pathname }}}>App settings</Link>.
    </span>
  );

  const toggleExternalListingsDescription = (
    <span>
      This job will be distributed to external job boards currently enabled in {" "}<Link to={{ pathname: `/hire/settings/integrations`, state: { orginalPathname: location.pathname }}}>App settings</Link>. Available integrations include LinkedIn and X Hiring. Please disable this option for canddiate pool job posts.
    </span>
  );

  return (
    <>
      <SettingsContainer
        title="Job details"
        description="Here's where you can edit the general information for this job. This includes things such as the job-type, hiring location, and if it's remote-friendly."
        sidebar={job.status != "published" && SidebarContent}
        bottomBar={BottomBarContent}
      >
        <FormContainer errors={errors}>
          <FormSection title="Basic information">
            <FormInput
              onChange={onFormInputChange}
              name="title"
              placeholder="Title"
              label="Title"
              isRequired
              className=""
              value={title}
              errors={errors}
            />

            <FormSelect
              onChange={handleCategoriesChange}
              onInputChange={handleCategoriesInputChange}
              name="jobCategory"
              label="Category"
              description={jobCategoriesDescription}
              className=""
              value={jobCategory}
              options={jobCategorySelectOptions}
              errors={errors}
              isClearable={true}
            />

            <FormSelect
              onChange={onFormInputChange}
              name="kind"
              label="Employment type"
              description=""
              className=""
              value={kind}
              options={jobKindOptions}
              errors={errors}
            />
          </FormSection>

          <FormSection title="Hiring location">
            <FormSelect
              onChange={handleCountryChange}
              name="country"
              label="Country"
              description=""
              className=""
              value={country}
              options={countrySelectOptions}
              errors={errors}
              isClearable={true}
            />
            {showRegionSelect && (
              <FormSelect
                onChange={onFormInputChange}
                name="stateRegion"
                label="State"
                description=""
                className=""
                value={stateRegion}
                options={regionSelectOptions}
                errors={errors}
                isClearable={true}
              />
            )}
            <FormInput
              onChange={onFormInputChange}
              name="city"
              placeholder=""
              label="City"
              className=""
              value={city || ""}
              errors={errors}
            />

            {/* <FormInput
              onChange={onFormInputChange}
              name="mapLocation"
              placeholder=""
              label="Street or place"
              className=""
              value={mapLocation || ""}
              errors={errors}
            /> */}
          </FormSection>

          <FormSection title="Remote options">
            <FormSelect
              onChange={onFormInputChange}
              name="remoteness"
              label="Is this job remote friendly?"
              description=""
              className=""
              value={remoteness}
              options={jobRemotenessOptions}
              errors={errors}
            />

            {/* Working Hours Overlap
          --===================================================-- */}
            {remoteness !== "remoteness_none" && (
              <FormConditionalFields>
                <FormSelect
                  onChange={onFormInputChange}
                  name="remoteRestrictionOverlapHoursIsRequired"
                  label="Working hours overlap"
                  description="Do you require a certain number of working hours overlap with a specific location?"
                  className=""
                  value={remoteRestrictionOverlapHoursIsRequired}
                  options={yesOrNoOptions}
                  errors={errors}
                />

                {remoteRestrictionOverlapHoursIsRequired && (
                  <FormConditionalFields>
                    <FormSelect
                      onChange={onFormInputChange}
                      name="remoteRestrictionOverlapHours"
                      label="How many hours of overlap?"
                      description=""
                      className=""
                      value={remoteRestrictionOverlapHours}
                      options={remoteRestrictionOverlapHoursOptions}
                      errors={errors}
                      isRequired={remoteRestrictionOverlapHoursIsRequired}
                    />

                    <FormSelectCity
                      onChange={handleCityChange}
                      name="remoteRestrictionCity"
                      label="Time zone by city"
                      description="What city best represents your ideal timezone?"
                      className=""
                      value={remoteRestrictionCity}
                      // value={remoteRestrictionCityOption}
                      // value={"Charlotte, NC"}
                      errors={errors}
                      isRequired={remoteRestrictionOverlapHoursIsRequired}
                    />
                  </FormConditionalFields>
                )}

                <FormSelect
                  onChange={onFormInputChange}
                  name="remoteRestrictionCountryResidencyIsRequired"
                  label="Required country residency"
                  description="Must the candidate live in specific countries?"
                  className=""
                  value={remoteRestrictionCountryResidencyIsRequired}
                  options={yesOrNoOptions}
                  errors={errors}
                />

                {remoteRestrictionCountryResidencyIsRequired && (
                  <FormConditionalFields>
                    <FormSelect
                      isMulti={true}
                      onChange={handleCountriesChange}
                      name="remoteRestrictionCountryList"
                      label="Countries"
                      description="Which countries are ideal for residency?"
                      className=""
                      value={remoteRestrictionCountryList}
                      options={countrySelectOptions}
                      errors={errors}
                      isRequired={remoteRestrictionCountryResidencyIsRequired}
                    />
                  </FormConditionalFields>
                )}
              </FormConditionalFields>
            )}
          </FormSection>

          <FormSection title="Salary or wage">
            <FormSelect
              onChange={handleSalaryTypeChange}
              name="salaryType"
              label="Display"
              description=""
              className=""
              value={salaryType}
              options={[
                { label: "Not shown", value: "" },
                { label: "Fixed amount", value: "Fixed" },
                { label: "Range", value: "Range" },
              ]}
              errors={errors}
            />

            {salaryType && (
              <FormConditionalFields>
                <FormInput
                  onChange={onFormInputChange}
                  name="salaryMin"
                  placeholder=""
                  description=""
                  label={salaryType === "Range" ? "Minimum amount" : "Amount"}
                  className=""
                  inputType="number"
                  value={salaryMin}
                  errors={errors}
                  isRequired={!!salaryType}
                />
                {salaryType === "Range" && (
                  <FormInput
                    onChange={onFormInputChange}
                    name="salaryMax"
                    placeholder=""
                    description=""
                    label="Maximum amount"
                    className=""
                    inputType="number"
                    value={salaryMax}
                    errors={errors}
                    isRequired={!!salaryType}
                  />
                )}
                <FormSelect
                  onChange={handleSalaryDropdownChange}
                  name="salaryUnit"
                  label="Timeframe"
                  description=""
                  className=""
                  value={salaryUnit}
                  options={salaryUnitOptions}
                  errors={errors}
                  isRequired={!!salaryType}
                />
                <FormSelect
                  onChange={handleSalaryDropdownChange}
                  name="salaryCurrency"
                  label="Currency"
                  description=""
                  className=""
                  value={salaryCurrency}
                  options={salaryCurrencyCodeOptions}
                  errors={errors}
                  isRequired={!!salaryType}
                />
              </FormConditionalFields>
            )}
          </FormSection>

          <FormSection title="Additional options">
            <FormSelect
              onChange={onFormInputChange}
              name="externalListingsEnabled"
              label="External Listings"
              description={toggleExternalListingsDescription}
              className=""
              value={externalListingsEnabled}
              options={[
                { label: "Disabled", value: false },
                { label: "Enabled", value: true },
              ]}
              errors={errors}
            />
            <FormInput
              onChange={onFormInputChange}
              name="videoUrl"
              placeholder="..."
              label="Video URL"
              pretext="https://"
              description="If you have a relevant video on YouTube or Vimeo, you can provide the web address for it here to have it displayed on the job post."
              className=""
              value={removeHttpPrefix(videoUrl) || ""}
              errors={errors}
            />

            <FormSelectGoogleLocation
              onChange={handleLocationChange}
              name="jobLocation"
              label="Map location"
              description="If the job has a physical location associated with it, you can provide a street address here to have it displayed on a map in the job post."
              className=""
              value={mapLocation}
              errors={errors}
            />

            <JobSocialShareImageUploader
              jobId={jobId}
              errors={errors}
              // onError={handleSocialShareImageErrors}
            />
          </FormSection>
        </FormContainer>
      </SettingsContainer>
    </>
  );
}

export default JobSettingsDetails;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupDetails_Sidebar;
    ${t.px(4)};
    position: sticky;
    top: ${t.spacing[4]};
    h3 {
      ${[t.mt(1), t.text.bold]};
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
    p {
      ${[t.text.xs, t.mt(3)]};
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    }
    b {
      ${[t.text.bold]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

import React, { useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";
import textStyles from "@ats/styles/text";

import {
  useChannelMessageTemplates,
  useMailMerge,
} from "@shared/queryHooks/useChannelMessageTemplate";

type Props = {
  onCancel: () => void;
  handleInsertTemplate: (channelMessageTemplate) => void;
  jobApplicationId: number;
};

function PreviewSelection(props) {
  const { mailMerge, mailMergeIsLoading } = props;
  // const { data, isLoading } = useMailMerge({ jobApplicationId, channelMessageTemplateId });

  window.logger("%c[CMTSM] PreviewSelection", "color: #F900D2", {
    mailMerge,
    // jobApplicationId,
    // channelMessageTemplateId,
  });

  if (mailMergeIsLoading) {
    return <>Loading...</>;
  }

  return (
    <Styled.PreviewContainer>
      <div dangerouslySetInnerHTML={{ __html: mailMerge.templateHtml }} />
    </Styled.PreviewContainer>
  );
}

function ChannelMessageTemplateSelectionModal(props: Props) {
  const { jobApplicationId } = props;
  const { data: channelMessageTemplates } = useChannelMessageTemplates();

  const [channelMessageTemplate, setChannelMessageTemplate] = useState({ id: undefined });
  const [isDirty, setIsDirty] = useState(false);

  const { data: mailMerge, isLoading: mailMergeIsLoading } = useMailMerge({
    jobApplicationId,
    channelMessageTemplateId: channelMessageTemplate?.id,
  });

  const channelMessageTemplateSelectOptions = channelMessageTemplates
    ? channelMessageTemplates.items.map((template) => {
      return { value: template.id, label: template.name };
    })
    : [];

  window.logger("%c[CMTSM] render", "color: #FF0EF3", {
    mailMerge,
    jobApplicationId,
    channelMessageTemplateId: channelMessageTemplate?.id,
    channelMessageTemplateSelectOptions,
    channelMessageTemplates,
  });

  const handleInsertTemplate = (event) => {
    event.preventDefault();

    if (mailMerge != undefined) {
      props.handleInsertTemplate(mailMerge);
      props.onCancel();
    }
  };

  const handleChangeSelectChannelMessageTemplate = (name, value) => {
    if (channelMessageTemplates == undefined) {
      return;
    }

    const channelMessageTemplateId = value;

    const template = channelMessageTemplates.items.find(
      (channelMessageTemplate) => channelMessageTemplate.id === channelMessageTemplateId,
    );

    setChannelMessageTemplate(template);
    setIsDirty(true);

    window.logger(
      "%c[ChannelMessageTemplateSelectionModal] handleChangeSelectChannelMessageTemplate",
      "color: #F976D2",
      {
        name,
        value,
        template,
      },
    );
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button
        name="insertTemplateButton"
        type="submit"
        onClick={handleInsertTemplate}
        disabled={mailMergeIsLoading}
      >
        Select
      </Button>
      <Button styleType="secondary" onClick={props.onCancel}>
        <span>Cancel</span>
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText="Choose template" onCancel={props.onCancel} hasUnsavedChanges={isDirty}>
      <Styled.Instructions>
        {
          "Select which template to use. You will have the ability to review and edit your message before sending."
        }
      </Styled.Instructions>
      <FormContainer buttons={submitButton}>
        <FormSelect
          onChange={handleChangeSelectChannelMessageTemplate}
          name="channelMessageTemplate"
          label="Template"
          // description="Choose a template."
          className=""
          value={channelMessageTemplate?.id}
          options={channelMessageTemplateSelectOptions}
          // errors={errors}
          isClearable
        />

        {/* <div style={{ whiteSpace: "pre-line" }}>
          {channelMessageTemplate != undefined ? channelMessageTemplate.body : null}
        </div> */}

        {channelMessageTemplate?.id != undefined ? (
          <PreviewSelection
            // jobApplicationId={jobApplicationId}
            // channelMessageTemplateId={channelMessageTemplate.id}
            mailMerge={mailMerge}
            mailMergeIsLoading={mailMergeIsLoading}
          />
        ) : null}
      </FormContainer>
    </CenterModal>
  );
}

export default ChannelMessageTemplateSelectionModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateSelectionModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateSelectionModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

Styled.PreviewContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateSelectionModal_PreviewContainer;
    ${[textStyles, t.p(4), t.pb(0), t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[700] : t.color.gray[100]};
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    max-height: 16rem;
    overflow-y: auto;
    ${t.dark &&
    `
      blockquote {
        color: ${t.color.gray[400]};
        border-left: 4px solid ${t.color.gray[600]};
      }
    `}

    p {
      span.mail-merge-brackets {
        color: ${t.dark ? t.color.gray[500] : t.color.gray[500]};
      }
    }
  `;
});

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { useStatistics } from "@shared/queryHooks/admin/useDashboard";

function AdminDashboardStatistics(props) {
  const {
    data: statistics,
    isFetching: isFetchingStatistics,
    isSuccess: isSuccessStatistics,
  } = useStatistics();

  if (statistics == undefined) {
    return null;
  }

  return (
    <Styled.Component>
      <Styled.Title>Dashboard</Styled.Title>
      <Styled.Stats>
        <div>
          <h3>{statistics.totalPaidSubscribersCount}</h3>Paid subscribers
          <br />
          total
        </div>
        <div>
          <h3>{statistics.accountsCreatedThisMonthCount}</h3>New accounts
          <br />
          this month
        </div>
        <div>
          <h3>{statistics.accountsCreatedLastMonthCount}</h3>New accounts
          <br />
          last month
        </div>
        <div>
          <h3>{statistics.accountsCreatedThisWeekCount}</h3>New accounts
          <br />
          this week
        </div>
        <div>
          <h3>{statistics.accountsCreatedLastWeekCount}</h3>New accounts
          <br />
          last week
        </div>
      </Styled.Stats>
    </Styled.Component>
  );
}

AdminDashboardStatistics.propTypes = {};

AdminDashboardStatistics.defaultProps = {};

export default AdminDashboardStatistics;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Component = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationList;
    ${t.p(4)};
    border-bottom: 1px solid ${t.color.gray[200]};
  `;
});

Styled.Title = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminDashboardTitle;
    ${[t.text.h3]}
  `;
})

Styled.Stats = styled.header((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationList_Stats;
    display: flex;
    justify-content: evenly;

    h3 {
      ${[t.text.h3, t.mr(2), t.mt(4)]}
    }

    > div {
      flex: 1;
      align-items: center;
    }
  `;
});

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";

import AuthForm from "@ats/src/views/sessions/components/AuthForm";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";
import Banner from "@ats/src/components/shared/Banner";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";

function Auth(props) {
  const errorParam = queryString.parse(location.search).error;
  const inviteToken = queryString.parse(location.search).invite_token;
  const accountDeleted = queryString.parse(props.location.search).account_deleted;
  const [emailConfirmed, setEmailConfirmed] = React.useState(false);

  React.useEffect(() => {
    showEmailConfirmationBannerIfApplicable();
  }, []);

  const showEmailConfirmationBannerIfApplicable = () => {
    let emailConfirmedParam = queryString.parse(props.location.search).email_confirmed;

    if (emailConfirmedParam != undefined) {
      const emailConfirmed = emailConfirmedParam === "true";
      setEmailConfirmed(emailConfirmed);
    }
  };

  const onComplete = ({ email, needsEmailConfirmation, acceptedAnInvite }) => {
    window.logger("%c[Auth] onComplete Auth - Regular Magic Link", "color: #1976D2", {
      acceptedAnInvite,
      email,
      needsEmailConfirmation,
    });

    if (needsEmailConfirmation) {
      props.history.push("/needs-email-confirmation");
    }
    // else if (acceptedAnInvite) {
    //   window.logger("%c[Auth] onComplete Auth - ACCEPTED AN INVITE", "color: #1976D2", {
    //     acceptedAnInvite,
    //   });
    //   props.history.push("/jobs");
    // }
    else {
      const params = new URLSearchParams({ e: btoa(email) });
      props.history.push(`/verify-email?${params.toString()}`);
    }
  };

  return (
    <Styled.Container>
      <HeaderBar />
      {emailConfirmed ? (
        <Banner message="Email address confirmed. Log in to continue." />
      ) : errorParam === "expired_link" ? (
        <Banner message="Expired. Please try again." />
      ) : errorParam === "invalid_invite" ? (
        <Banner message="Invitation no longer valid." />
      ) : inviteToken != undefined ? (
        <Banner message="Accept your invitation." />
      ) : accountDeleted === "success" ? (
        <Banner message="Your account was successfully deleted." />
      ) : null}
      <Styled.UI>
        {/* <Announcement>
          <p>Looking for <b>Wrk</b>? We recently rebranded to <b>Polymer</b>!</p>
          <Button styleType="secondary" type="externalLink" link="https://polymer.co/blog/hello-polymer" target="_self">
            Read the announcement
          </Button>
        </Announcement> */}
        <Styled.Form>
          <Helmet title="Auth" />
          <h2>{inviteToken == undefined ? "Enter your email to continue" : "Accept invitation"}</h2>
          <div>
            <AuthForm onComplete={onComplete} location={props.location} inviteToken={inviteToken} />
          </div>
        </Styled.Form>
        {/* <Styled.Links>
          <Link to="/register">Don&apos;t have an account?</Link>
          <Link to="/request-password-reset">Forgot your password?</Link>
        </Styled.Links> */}
        <Styled.Recaptcha>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Styled.Recaptcha>
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(Auth);
